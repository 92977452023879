import { IonIcon, IonItem, IonLabel, IonList, IonListHeader } from '@ionic/react';
import { arrowForwardOutline, timeOutline } from 'ionicons/icons';
import styled from 'styled-components';
import { useAppStore } from '../../../store';

interface SearchHistoryProps {
  onSelect: (e: string) => any;
}

const SearchHistory: React.FC<any> = ({ onSelect }: SearchHistoryProps) => {
  const {
    RootStore: { HomeSearchList },
  } = useAppStore();

  return (
    <SearchHistoryWrapper>
      <div className="ion-padding">
        <IonLabel> Recherches récentes</IonLabel>
      </div>
      {HomeSearchList?.map((i: any) => (
        <IonItem key={i} onClick={() => onSelect(i)}>
          <IonIcon icon={timeOutline} slot="start" />
          <IonLabel>{i}</IonLabel>
          <IonIcon icon={arrowForwardOutline} slot="end" />
        </IonItem>
      ))}
    </SearchHistoryWrapper>
  );
};
const SearchHistoryWrapper = styled(IonList)`
  --border-width: 0;
  ion-item {
    --border-width: 0;
    --inner-border-width: 0;
  }
`;
export default SearchHistory;
