import { IonButton, IonIcon, IonSearchbar, IonSpinner } from '@ionic/react';
import './style.css';
import { FC } from 'react';
import arrowDownIcon from '../../../assets/icons/arrowDown.svg';
import arrowUpIcon from '../../../assets/icons/arrowUp.svg';
import closeIcon from '../../../assets/icons/chatSearchClose.svg';
import chatSearchIcon from '../../../assets/icons/chatSearchIcon.svg';

interface IProps {
  loading: boolean;
  handleSearch: (e: any) => void;
  handleArrowUp: () => void;
  handleArrowDown: () => void;
  numberOfResults: number | undefined;
  searchKey: string;
  closeSearch: () => void;
}

const SearchBox: FC<IProps> = ({
  loading,
  handleSearch,
  numberOfResults,
  searchKey,
  handleArrowDown,
  handleArrowUp,
  closeSearch,
}) => {
  const handleBoxSearch = async (event: any) => {
    // search handler
    handleSearch(event);
  };

  const closeSearchBox = () => {
    closeSearch();
  };

  const resultDynamicTest = numberOfResults && numberOfResults > 1 ? 'résultats' : 'résultat';

  return (
    <div className="chat__search-box">
      <div className="chat__search-box__search-label-container">
        {searchKey.trim().length ? (
          <div className="chat__search-box__search-label">
            {numberOfResults} {resultDynamicTest} dans la conversation
          </div>
        ) : (
          <div className="chat__search-box__search-label">Recherche</div>
        )}
        <IonButton onClick={closeSearchBox} fill="clear" className="ion-no-padding ion-no-margin">
          <IonIcon size="large" icon={closeIcon} />
        </IonButton>
      </div>

      <div className="chat__search-box__search-container">
        <div className="chat__search-box__searchbar">
          <IonSearchbar
            searchIcon={chatSearchIcon}
            debounce={700}
            showClearButton="never"
            placeholder=""
            onIonInput={handleBoxSearch}
          />
          {loading && <IonSpinner className="chat__search-box__loading-spinner" name="crescent" color="dark" />}
        </div>
        <IonButton onClick={handleArrowDown} fill="clear" className="ion-no-padding">
          <IonIcon size="large" icon={arrowDownIcon} />
        </IonButton>
        <IonButton onClick={handleArrowUp} fill="clear" className="ion-no-padding">
          <IonIcon size="large" icon={arrowUpIcon} />
        </IonButton>
      </div>
    </div>
  );
};

export default SearchBox;
