import { CalendarEventOptions, CapacitorCalendar } from '@safimaya/capacitor-calendar';

export const syncEvent = async (eventData: ICreateEvent) => {
  const { id, title, location, notes, startDate, endDate, expired, calendar_id } = eventData;
  try {
    let eventData = {
      id: 'tedispo_' + id,
      title: title,
      location: location,
      notes: notes || 'aucun',
      startDate: startDate,
      endDate: endDate,
      calendar_id: calendar_id,
    } as CalendarEventOptions;

    let event = await CapacitorCalendar.findEvent({
      id: 'tedispo_' + id,
      title: title,
      calendarId: calendar_id,
    });

    if (event && event.events && event.events.length) {
      if (expired) {
        event.events.forEach(async (eventData: any) => {
          try {
            await CapacitorCalendar.deleteEvent({ id: eventData.id, calendarId: calendar_id });
          } catch (e) {
            console.log('Erreur suppression', e);
          }
        });
      } else {
        event.events.forEach(async (eventToUpdate: any) => {
          try {
            eventData.id = eventToUpdate.id;
            await CapacitorCalendar.updateEvent(eventData);
          } catch (e) {
            console.log('Erreur maj', e);
          }
        });
      }
    } else {
      if (!expired) {
        try {
          await CapacitorCalendar.createEvent(eventData);
        } catch (e) {
          console.log('Erreur creation', e);
        }
      }
    }
  } catch (e) {
    console.error('Error creating calendar event', e);
  }
};
