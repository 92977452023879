import { graphql } from '../../models/gql';

export const UPLOAD = graphql(/* GraphQL */ `
  mutation upload($file: Upload!) {
    upload(file: $file) {
      data {
        id
        attributes {
          name
          url
          hash
          mime
          provider
        }
      }
    }
  }
`);
