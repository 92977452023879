import { IonButton } from '@ionic/react';

import './style.css';

type fillButtom = 'clear' | 'outline' | 'solid' | 'default' | undefined;

interface IButtonGroup {
  className?: string;
  classNameTop?: string;
  classNameBottom?: string;
  textTop?: string;
  textBottom?: string;
  fillTop?: fillButtom;
  fillBottom?: fillButtom;
  colorTop?: string;
  colorBottom?: string;
  onClickTop?: ((data?: any) => void) | any;
  onClickBottom?: ((data?: any) => void) | any;
  disabled?: boolean;
}

const ButtonGroup: React.FC<IButtonGroup> = ({
  className,
  classNameTop,
  classNameBottom,
  textTop,
  textBottom,
  fillTop,
  fillBottom,
  colorTop,
  colorBottom,
  onClickTop,
  onClickBottom,
  disabled,
}) => {
  const OnClickTopChild = () => {
    onClickTop();
  };
  const OnClickBottomChild = () => {
    onClickBottom();
  };

  return (
    <div className={className}>
      {textTop && (
        <IonButton
          className={`${classNameTop}`}
          expand="block"
          fill={fillTop}
          color={colorTop}
          onClick={OnClickTopChild}
          disabled={disabled}
        >
          {textTop}
        </IonButton>
      )}
      <IonButton
        className={`ion-margin-top ${classNameBottom}`}
        expand="block"
        fill={fillBottom}
        color={colorBottom}
        onClick={OnClickBottomChild}
        disabled={disabled}
      >
        {textBottom}
      </IonButton>
    </div>
  );
};

export default ButtonGroup;
