import { IonSearchbar } from '@ionic/react';
import React, { useState, useRef, useMemo, useEffect, useContext } from 'react';
import { useInstantSearch, useSearchBox, UseSearchBoxProps } from 'react-instantsearch';
import { AppReducerContext, useAppStore } from '../../../store';
import './style.css';

interface CustomSearchBoxProps extends UseSearchBoxProps {
  onFocus?: any;
  onClear?: any;
  initialValue?: string;
  onDone?: any;
}

const CustomSearchBox = (props: CustomSearchBoxProps) => {
  const { status } = useInstantSearch();
  const { onDone, initialValue, onClear } = props;
  const inputRef = useRef<any>(null);
  const {
    RootStore: { HomeSearchAdd },
  } = useAppStore();
  const {
    state: { searchQuery },
    dispatch,
  } = useContext(AppReducerContext);

  function setQuery(newQuery: string) {
    const q = newQuery.trim();
    if (q && q.length > 2) {
      HomeSearchAdd(q);
      dispatch({ type: 'SET_SEARCH_QUERY', payload: newQuery });
      if (onDone) onDone();
    }
  }

  return (
    <IonSearchbar
      onClick={props.onFocus}
      mode="md"
      placeholder="Recherche"
      ref={inputRef}
      value={searchQuery}
      debounce={200}
      onIonChange={(event) => {
        setQuery(event?.detail?.value || '');
      }}
      onIonClear={(e: any) => {
        setQuery('');
        dispatch({ type: 'SET_SEARCH_QUERY', payload: '' });
        onClear(e);
      }}
    />
  );
};

export default CustomSearchBox;
