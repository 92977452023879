import { IonCard, IonCardContent, IonCol, IonIcon, IonItem, IonLabel, IonList, IonRow } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

interface IMenuList {
  listItems: IlistItem[];
  icon?: any;
  hasBorderBottom?: boolean;
  action: any;
  titre?: string;
  color?: string;
  bgColor?: string;
  itemColor?: string;
  iconClassColor?: string;
  borderColor?: string;
}

const MenuList: React.FC<IMenuList> = ({
  listItems,
  icon,
  hasBorderBottom = false,
  action,
  titre,
  color = 'text-blue',
  bgColor,
  itemColor = 'text-gray-400',
  iconClassColor,
  borderColor,
}) => {
  const iconColor = iconClassColor ? iconClassColor : color && color == 'text-green' ? 'green-icon' : 'blue-icon';
  return (
    <MenuListWrapper lines="none">
      {!!titre && <IonItem className={`font-outfit text-bold ${color}`}>{titre}</IonItem>}

      {listItems.map((item, index) => (
        <IonItem
          key={index}
          onClick={() => (item.action ? item.action() : action(item))}
          style={
            hasBorderBottom && index != listItems.length - 1
              ? {
                  borderBottom: `1px solid ${borderColor || '#3D3BEB'}`,
                }
              : {}
          }
          className={`${bgColor ? bgColor : ''}`}
        >
          <IonLabel text-wrap>
            <IonRow>
              <IonCol className={`${itemColor} font-outfit font-weight-400`}>
                <IonRow>
                  <h3>{item.label}</h3>
                </IonRow>
                {!!item.description && <IonRow className="text-size-xs desc">{item.description}</IonRow>}
              </IonCol>
              {item.showIcon && (
                <IonCol className={`${iconColor} margin-r-5 text-size-16`} size="1">
                  <IonIcon icon={icon}></IonIcon>
                </IonCol>
              )}
            </IonRow>
            <IonRow>
              <IonCol className={bgColor}>{item.children ? item.children : ''}</IonCol>
            </IonRow>
          </IonLabel>
        </IonItem>
      ))}
    </MenuListWrapper>
  );
};

const MenuListWrapper = styled(IonList)`
  ion-item {
    h3 {
      font-size: 18px;
      font-weight: 500;
    }
    .desc {
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

export default MenuList;
