import { IonButton, IonContent, IonFooter, IonPage } from '@ionic/react';
import { useHistory } from 'react-router';
import { Header, CardDispo } from '../../../components';
import { useDispo } from '../../../hooks';
import { Enum_Event_Type, EventInput } from '../../../models/gql/graphql';
import { getEventDateData } from '../../DispoDetail/utils';
import '../style.css';
import { useEffect } from 'react';
import { resetObject } from '../../../utils';

const Confirmed: React.FC = () => {
  const { dispoView, dispo, setDispo, setImageSelecteds, setImageUrl } = useDispo();
  const history = useHistory();
  const eventDateData = getEventDateData(dispoView);
  useEffect(() => {
    setImageUrl('');
    setImageSelecteds([]);
  }, []);

  return (
    <IonPage>
      <Header title="Confirmation" noButtonBack={true} />
      <IonContent className="ion-padding">
        <h2 className="ion-text-center margin-auto font-outfit font-title-confirmed">
          Félicitations, votre dispo vient d'être créée
        </h2>
        <CardDispo dispo={dispoView} withTag={true}></CardDispo>
      </IonContent>
      <IonFooter>
        <IonButton
          expand="block"
          fill="solid"
          onClick={() => history.push('/main/dispos/' + dispoView.id + '?noBackButton=true')}
        >
          Afficher
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Confirmed;
