import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import React from 'react';
import danceSvg from './../../assets/icons/interest-dance.svg';

interface IDispoDate {
  dispoDate: {
    debut: string;
    fin: string;
  };
  withShadown?: boolean;
  noWrap?: boolean;
}

const DispoDate: React.FC<IDispoDate> = ({ dispoDate, withShadown = false, noWrap = false }) => {
  return (
    <div className={`text-vw-4 font-outfit full ${withShadown ? ' text-shadow text-bolder' : ''}`}>
      <IonGrid className="ion-no-padding ion-no-margin">
        <IonRow>
          <IonCol className={`${noWrap ? 'wrap-text' : ''}`}>
            {dispoDate.debut} <span className="white-separator"></span> {dispoDate.fin}
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default DispoDate;
