import { IonButton } from '@ionic/react';
import React from 'react';

interface IButtonCreate {
  action: () => void;
  text: string;
  disabled?: boolean;
}
const ButtonCreate: React.FC<IButtonCreate> = ({ action, text, disabled }) => {
  return (
    <IonButton
      expand="block"
      fill="solid"
      color="primary"
      onClick={action}
      className="not-transorm-text"
      disabled={disabled}
    >
      {text}
    </IonButton>
  );
};
export default ButtonCreate;
