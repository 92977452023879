import { useQuery } from '@apollo/client';
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { omit } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import { useHistory } from 'react-router-dom';
import { CardCustom, Header, InterestChip, SegmentCustom, StepperCount } from '../../../components';
import { ComponentModal } from '../../../components/Modals';
import { GET_EVENT } from '../../../graphql/queries/event.graphql';
import { GET_INTERESTS } from '../../../graphql/queries/interest.graphql';
import { GET_ME } from '../../../graphql/queries/user.graphql';
import { useDispo } from '../../../hooks';
import { Enum_Event_Type, EventEntity, EventInput, UserFollowerEntity } from '../../../models/gql/graphql';
import PrivateView from './privateView';
import PublicView from './publicView';
import './style.css';

const StepInvitation: React.FC<IStepProps> = ({ id, duplicate }) => {
  const { data: interestsData, loading: loadingInterest } = useQuery(GET_INTERESTS);
  const [interestsChecked, setInterestsChecked] = useState<string[]>([]);

  const interests = useMemo(() => {
    return !loadingInterest ? interestsData?.interests?.data : [];
  }, [loadingInterest, interestsData]);
  const history = useHistory();
  const methods = useForm<EventInput>();
  const { setValue, handleSubmit, resetField } = methods;
  const [type, setType] = useState<Enum_Event_Type>(Enum_Event_Type.Private);
  const { dispo, setDispo } = useDispo();
  const { data: dataMe } = useQuery(GET_ME);
  const [usersFiltered, setUsersFiltered] = useState<Array<UserFollowerEntity>>([]);
  const [followerChecked, setFollowerChecked] = useState<Array<string>>([]);
  const [marginBottom, setMarginBottom] = useState<number>(0);
  const [collapse, setCollapse] = useState<boolean>(false);
  const [maxInvitedToReset, setMaxInvitedToReset] = useState<number>();
  const [interestData, setInterestData] = useState<Array<string>>();
  const [numberLimit, setNumberLimit] = useState<number>(0);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const btnSubmit = useRef<any>(null);

  const { data: dispoData, loading: loadingEvent } = useQuery(GET_EVENT, {
    variables: { id: id ? id : '0' },
  });

  const dispoDefault = useMemo(() => {
    return !loadingEvent ? dispoData?.event?.data : ([] as EventEntity);
  }, [loadingEvent, dispoData]);

  //HandleChange function into form
  const onSubmit = (data: EventInput) => {
    let dataFiltered = data;
    console.log({ data });
    if (type === Enum_Event_Type.Private) {
      dataFiltered = omit(data, ['interests']);
    }
    if (type === Enum_Event_Type.Public) {
      if (!dataMe?.me?.isPro) {
        dataFiltered['participations'] = dataMe?.me?.followers?.data?.map((follower) => {
          return follower?.attributes?.follower?.data?.id;
        }) as any;
      } else {
        dataFiltered = omit(data, ['participations']);
      }
    }
    setDispo({
      ...dispo,
      ...dataFiltered,
      type,
      maxInvited: parseFloat(data.maxInvited as any),
      interests: data.interests,
    });
    if (!duplicate) {
      resetData();
      history.push('/main/home/create-dispo/step-photo');
    } else {
      history.push({
        pathname: `/main/dispos/${id}/duplicate`,
        search: `?${new URLSearchParams({ page: 'step-photo' }).toString()}`,
      });
    }
  };

  const stepMargin = (valueToStep: number, isIncrement: boolean) => {
    setMarginBottom(isIncrement ? marginBottom + valueToStep : marginBottom - valueToStep);
  };

  const segmentData: IdNameEntity[] = [
    {
      id: Enum_Event_Type.Private,
      name: 'Dispo privée',
    },
    {
      id: Enum_Event_Type.Public,
      name: 'Dispo publique',
    },
  ];

  useEffect(() => {
    if (dispoDefault && dispoDefault.attributes && dispoDefault.attributes.type) {
      setType(dispoDefault.attributes.type);
      setValue('type', dispoDefault.attributes.type);
    }
    if (dispoDefault && dispoDefault.attributes?.interests && dispoDefault.attributes?.interests?.data.length) {
      const ids: string[] = dispoDefault?.attributes?.interests?.data?.map((i) => i.id!);
      setInterestsChecked(ids);
      setValue('interests', ids);
    }
  }, [dispoDefault]);

  const handleInterestChange = (idInterest: never) => {
    let checked = [];
    if (interestsChecked.includes(idInterest)) {
      checked = interestsChecked.filter((value) => value !== idInterest);
      setInterestsChecked(checked);
    } else {
      checked = [...interestsChecked, idInterest];
      setInterestsChecked(checked);
    }
    setValue('interests', checked);
  };

  const handleIDChange = (idFollow: string) => {
    const checkeds = followerChecked.filter((value) => value !== idFollow);
    setFollowerChecked(checkeds);
    setValue('participations', checkeds);
    stepMargin(75, false);
  };
  const gePlurals = (number: number, string: string) => {
    return number > 1 ? `${string}s` : string;
  };
  const changeSegment = (value: IdNameEntity) => {
    setType(value.id as Enum_Event_Type);
    setFollowerChecked([]);
  };

  const resetData = () => {
    setFollowerChecked([]);
    setMaxInvitedToReset(null as any);
    setInterestsChecked([]);
    setType(Enum_Event_Type.Private);
    setValue('type', Enum_Event_Type.Private);
    setValue('participations', []);
  };
  useEffect(() => {
    setIsOpenModal(followerChecked && followerChecked.length > 0);
  }, [followerChecked]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonPage>
          <Header title={duplicate ? 'Duplication de ta dispo' : "Création d'une dispo"} noButtonBack={false} />
          <StepperCount length={5} numberStep={2}></StepperCount>
          <IonContent>
            <div style={{ marginBottom: marginBottom }}>
              <CardCustom margin={true}>
                <IonText color="dark" className="font-outfit">
                  <h2>Participants</h2>
                </IonText>
                <SegmentCustom
                  values={segmentData}
                  defaultValue={type}
                  onChange={changeSegment}
                  typeSegment="ios"
                ></SegmentCustom>
                {type === Enum_Event_Type.Private ? (
                  <PrivateView
                    handleChange={(data, isIncrement) => {
                      setFollowerChecked(data);
                      setValue('participations', data);
                      stepMargin(75, isIncrement);
                    }}
                    getFollower={(data: UserFollowerEntity[]) => {
                      setUsersFiltered(data);
                    }}
                    followerCheckedFromParent={followerChecked}
                  />
                ) : (
                  <PublicView
                    handleChangeInterest={(data) => {
                      setValue('interests', data);
                      setInterestData(data);
                    }}
                    userIsPro={dataMe?.me?.isPro}
                    handleChangeLimit={(data: number) => {
                      setValue('maxInvited', data);
                      setNumberLimit(data);
                    }}
                    dispoDefault={dispoDefault as EventEntity}
                    maxInvitedProps={maxInvitedToReset}
                  />
                )}
                <IonText color="dark" className="font-outfit">
                  <h4>Centres d’intérêts</h4>
                  <p>
                    Sélectionne les centres d’intérêts liés à ta dispo ! Cela nous permettra de l’affichage aux membres
                    intéressés{' '}
                  </p>
                </IonText>
                {interests?.map((interest, index) => (
                  <InterestChip
                    handleChange={handleInterestChange}
                    interest={interest}
                    isSelected={interestsChecked.includes(interest?.id as never)}
                    isOther={false}
                    key={index}
                  ></InterestChip>
                ))}
              </CardCustom>
            </div>
          </IonContent>
          <IonFooter className="ion-no-border ion-padding">
            {type === Enum_Event_Type.Private && (
              <IonButton
                disabled={
                  (usersFiltered.length && (!followerChecked || followerChecked.length === 0)) ||
                  !interestsChecked ||
                  interestsChecked.length === 0
                }
                type="submit"
                className="card-bottom-button "
                expand="block"
                color="primary"
                ref={btnSubmit}
              >
                Continuer
              </IonButton>
            )}

            {type === Enum_Event_Type.Public && (
              <IonButton
                disabled={
                  numberLimit === 0 || Number.isNaN(numberLimit) || !interestsChecked || interestsChecked.length === 0
                }
                type="submit"
                className="card-bottom-button "
                expand="block"
                color="primary"
              >
                Continuer
              </IonButton>
            )}
          </IonFooter>
          <ComponentModal
            isOpen={isOpenModal}
            onCancel={() => setIsOpenModal(false)}
            height="100%"
            backdropDismiss={false}
            initialBreakpoint={0.25}
            useBackgroundGreen={true}
            backdropBreakpoint={0.5}
            showHandle={false}
          >
            <div className="ion-padding">
              <IonGrid className="ion-no-padding">
                <IonRow
                  className="flex-container ion-no-margin ion-margin-bottom"
                  onClick={() => setCollapse(!collapse)}
                >
                  <span className="bullet"></span>
                </IonRow>
                <IonRow className="ion-no-padding">
                  <IonCol className="ion-no-padding">
                    <IonText color="light" className="font-outfit">
                      <p>{`${followerChecked.length} ${gePlurals(followerChecked.length, 'invité')} ${gePlurals(
                        followerChecked.length,
                        'sélectionnés'
                      )}  `}</p>
                    </IonText>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      disabled={
                        !followerChecked ||
                        followerChecked.length === 0 ||
                        !interestsChecked ||
                        interestsChecked.length === 0
                      }
                      size="small"
                      className="card-bottom-button btn-white"
                      expand="block"
                      fill="solid"
                      type="submit"
                      onClick={() => {
                        btnSubmit.current?.click() as any;
                      }}
                    >
                      Continuer
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <div className="card-user-viewer">
                {usersFiltered?.map(
                  (follower) =>
                    followerChecked.includes(follower.attributes?.follower?.data?.id as string) && (
                      <IonItem
                        key={follower.attributes?.follower?.data?.id}
                        lines="none"
                        className="ion-no-padding"
                        color="primary"
                      >
                        <IonAvatar slot="start">
                          <img
                            alt="Silhouette of a person's head"
                            src={
                              follower?.attributes?.follower?.data?.attributes?.profilePicture?.data
                                ? follower?.attributes?.follower?.data?.attributes?.profilePicture.data?.attributes?.url
                                : follower?.attributes?.follower?.data?.attributes?.avatar?.data
                                ? follower.attributes.follower?.data?.attributes?.avatar.data?.attributes?.image.data
                                    ?.attributes?.url
                                : 'https://ionicframework.com/docs/img/demos/avatar.svg'
                            }
                          />
                        </IonAvatar>
                        <IonLabel className="font-outfit ion-text-capitalize">
                          {follower?.attributes?.follower?.data?.attributes?.username}
                        </IonLabel>
                        <IonIcon
                          size="small"
                          onClick={() => handleIDChange(follower.attributes?.follower?.data?.id as string)}
                          icon={closeOutline}
                          slot="end"
                        ></IonIcon>
                      </IonItem>
                    )
                )}
              </div>
            </div>
          </ComponentModal>
        </IonPage>
      </form>
    </FormProvider>
  );
};

export default StepInvitation;
