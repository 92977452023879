import { graphql } from '../../models/gql';

export const CREATE_MESSAGE = graphql(/* GraphQL */ `
  mutation createMessage($data: MessageInput!) {
    createMessage(data: $data) {
      data {
        id
        attributes {
          content
        }
      }
    }
  }
`);
