export const translations = {
  'Phone number is already taken': 'Ce numéro de téléphone est déjà pris',
  'Phone not found': 'Numéro de téléphone introuvable',
  'Invalid code': 'Le code renseigné est incorrect.',
  'User not found': 'Utilisateur introuvable',
  'Invalid phone': 'Numéro de téléphone invalide',
  'You already accepted': 'Vous avez déjà accepté',
  'You already refused': 'Vous avez déjà refusé',
  'You are not invited in this event': "Vous n'êtes pas invité à ce dispo",
  'You have not participated in this event': "Vous n'avez pas participé à cet événement",
  'Please provide a valid email address': 'Veuillez fournir une adresse e-mail valide',
  'You have already like this post': 'Vous avez déjà aimé cette publication',
};

export const __ = (value: string) => {
  return (translations as any)[value] || value;
};
