import { graphql } from '../../models/gql';

export const GET_MESSAGE_ITEMS = graphql(/* GraphQL */ `
  query getMessageItems($filters: MessageItemFiltersInput, $sort: [String], $pagination: PaginationArg) {
    messageItems(filters: $filters, sort: $sort, pagination: $pagination) {
      data {
        id
        attributes {
          firebaseItemId
        }
      }
    }
  }
`);
