import { IonAvatar, IonGrid, IonRow } from '@ionic/react';
import { UsersPermissionsMe, UsersPermissionsUser } from '../../models/gql/graphql';

interface IMemberProps {
  user?: Maybe<UsersPermissionsMe> | Maybe<UsersPermissionsUser>;
  avatarStyle?: string;
  nameStyle?: string;
  isComment?: boolean;
}

const UserDetail: React.FC<IMemberProps> = ({ user, avatarStyle, nameStyle, isComment = false }) => {
  const profilPicture =
    user?.profilePicture && 'attributes' in user.profilePicture
      ? user.profilePicture.attributes?.url
      : (user as UsersPermissionsUser)?.profilePicture && (user as UsersPermissionsUser).profilePicture?.data
      ? (user as UsersPermissionsUser).profilePicture?.data?.attributes?.url
      : null;

  const avatar =
    user?.avatar && 'attributes' in user.avatar
      ? user.avatar.attributes?.image.data?.attributes?.url
      : (user as UsersPermissionsUser)?.avatar &&
        (user as UsersPermissionsUser).avatar?.data &&
        (user as UsersPermissionsUser).avatar?.data?.attributes?.image.data
      ? (user as UsersPermissionsUser).avatar?.data?.attributes?.image.data?.attributes?.url
      : null;

  return (
    <IonGrid>
      <IonRow className={`ion-align-items-center`}>
        <IonAvatar className={avatarStyle} style={{ marginRight: isComment ? 10 : '' }}>
          <img alt="avatar" src={profilPicture || avatar || 'https://ionicframework.com/docs/img/demos/avatar.svg'} />
        </IonAvatar>
        <span className={`${nameStyle} text-capitalize`} style={{ fontWeight: isComment ? 500 : 400 }}>
          {user?.isPro ? user?.companyName : user?.username}
        </span>
      </IonRow>
    </IonGrid>
  );
};

export default UserDetail;
