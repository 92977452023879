import { useLazyQuery, useMutation } from '@apollo/client';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { checkmark, close } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { UPDATE_USER_FOLLOWER } from '../../graphql/mutations/updateUser..graphql';
import { GET_RELATION_USER_FOLLOWER } from '../../graphql/queries/userFollower.graphql';
import { Enum_Userfollower_Status } from '../../models/gql/graphql';
import { CustomButton } from '../../pages/DispoDetail/components/Buttons/CustomButton';
import { getUserId } from '../../utils';
interface IConfirmRequestCard {
  idUserFollower: string;
  refetchNotification?: () => void;
}

const ConfirmRequestCard: React.FC<IConfirmRequestCard> = ({ idUserFollower, refetchNotification }) => {
  const history = useHistory();
  const [updateUserFollower, { loading }] = useMutation(UPDATE_USER_FOLLOWER);
  const [getUserFollower, { loading: loadingRelation }] = useLazyQuery(GET_RELATION_USER_FOLLOWER);

  const confirm = async (accept: boolean) => {
    const { data } = await getUserFollower({
      variables: {
        filters: {
          and: [
            {
              follower: {
                id: {
                  eq: idUserFollower,
                },
              },
            },
            {
              user: {
                id: {
                  eq: getUserId()?.toString(),
                },
              },
            },
          ],
        },
        pagination: { limit: 500 },
      },
    });

    if (!data?.userFollowers?.data.length) {
      return;
    }

    let userFollowId = await data?.userFollowers?.data[0].id;
    try {
      const { data } = await updateUserFollower({
        variables: {
          id: userFollowId || '0',
          data: {
            status: accept ? Enum_Userfollower_Status.Accepted : Enum_Userfollower_Status.Refused,
          },
        },
      });
      if (data?.updateUserFollower?.data?.id) {
        setTimeout(() => {
          history.push('/main/profil/' + idUserFollower);
        }, 500);
      }
    } catch (e) {}
  };
  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        <IonCol size="6">
          <CustomButton
            action={async () => {
              await confirm(true);
              refetchNotification && (await refetchNotification());
            }}
            class="ion-no-border text-white button-bg-black-shade no-uppercase"
            text={'Accepter'}
            icon={checkmark}
            loading={loading || loadingRelation}
          />
        </IonCol>
        <IonCol>
          <CustomButton
            action={async () => {
              await confirm(false);
              refetchNotification && (await refetchNotification());
            }}
            class="ion-no-border text-black-shade button-bg-white no-uppercase border-black-shade"
            text={'Refuser'}
            icon={close}
            loading={loading || loadingRelation}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ConfirmRequestCard;
