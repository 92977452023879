import React, { useRef, useState } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { pauseOutline } from 'ionicons/icons';
import playIcon from '../../../../assets/icons/goldPlayIcon.svg';
import './style.css';
import yellowCloseIcon from '../../../../assets/icons/yellowClose.svg';
import VideoView from '../../../VideoView';

interface IVideoView {
  url: string;
  index: number;
  className?: string;
  autoPlay?: boolean;
  onDeleteFile: any;
  file?: any;
}
const VideoItem: React.FC<IVideoView> = ({ url, index, className, autoPlay, onDeleteFile, file }) => {
  const refVideo = useRef(null);
  const [isPlay, setIsPlay] = useState<boolean>(true);

  const isPlayVideo = (e: any) => {
    e.stopPropagation();
    const video: any = refVideo.current;
    setIsPlay(!isPlay);
    if (isPlay) {
      return video.pause();
    }
    return video.play();
  };

  const handleDelete = () => {
    onDeleteFile(index);
  };

  return (
    <div className="upload-item-video-view">
      <VideoView
        className="upload-item-video-view-override"
        url={url}
        autoPlay={false}
        playIcon={playIcon}
        file={file}
        from="uploadmessage"
      />
      {/* <video
        key={url}
        width={'100%'}
        className="upload-item-video-view upload-item-object-fit-cover"
        controls={false}
        autoPlay={autoPlay}
        ref={refVideo}
        playsInline={true}
      >
        <source type="video/mp4" src={url} />
      </video> */}
      <IonButton fill="clear" className="upload-item-btn-video" onClick={isPlayVideo}>
        <IonIcon className="upload-item-icon-btn" icon={isPlay ? pauseOutline : playIcon}></IonIcon>
      </IonButton>
      <IonButton className="upload-item-icon-delete-btn ion-no-padding" onClick={handleDelete} fill="clear">
        <IonIcon size="small" icon={yellowCloseIcon} />
      </IonButton>
    </div>
  );
};

export default VideoItem;
