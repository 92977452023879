import { useMutation } from '@apollo/client';
import { IonButton, IonCol, IonContent, IonPage, IonRow, IonToast } from '@ionic/react';
import { phonePortraitOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import { useHistory } from 'react-router-dom';
import { CardCustom, Header, Loader, PhoneInputCustom, ValidatedIcon } from '../../../../components';
import { LOGIN_QUERY } from '../../../../graphql/mutations/auth.graphql';
import { AuthPhoneInput } from '../../../../models/gql/graphql';

import { ComponentModal } from '../../../../components/Modals';
import { __ } from '../../../../utils/traduction';
import { VerifyAccount } from '../../../MyAccount';
import '../style.css';

const SendPhone: React.FC = () => {
  const history = useHistory();
  const [AuthWithPhone, { loading }] = useMutation(LOGIN_QUERY);
  const methods = useForm<AuthPhoneInput>();
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = methods;
  const [messageError, setMessageError] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openDesactivateModal, setOpenDesactivateModal] = useState(false);
  register('phone', { required: true });

  const onSubmit = async (dataToPost: AuthPhoneInput) => {
    try {
      dataToPost.phone = getValues('phone');
      const { data } = await AuthWithPhone({ variables: { data: dataToPost } });
      if (data?.AuthWithPhone?.message === 'disabled') {
        setOpenDesactivateModal(true);
        return;
      }
      methods.reset();
      history.push(`/signup/verification/${dataToPost.phone}/${data?.AuthWithPhone?.message}/login`);
      setValue('phone', '');
    } catch (err: any) {
      console.log(err.message);
      if (err.message === 'Your account has been blocked by an administrator') {
        setIsOpen(true);
        setMessageError("Votre compte n'est pas actif. Veuillez contacter les administrateurs de Tedispo");
        return;
      }
      if (err.message === 'Your account has been deleted') {
        setIsOpen(true);
        setMessageError('Votre compte a été supprimé.');
        return;
      }
      if (err.message === 'Phone not found') {
        setValue('phone', '');
        history.push('/login/no-phone');
      }
    }
  };

  useEffect(() => {
    methods.reset();
  }, []);

  return (
    <IonPage>
      <Header title="Connexion" />
      {loading && <Loader />}
      <IonContent className="ion-padding">
        <CardCustom>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ValidatedIcon icon={phonePortraitOutline}></ValidatedIcon>
            <p className="ion-text-center margin-auto font-outfit font-description">
              Indique le numéro de téléphone lié à ton compte Tedispo
            </p>
            <IonRow>
              <IonCol>
                <PhoneInputCustom
                  label="Numero de telephone"
                  handleChange={(data: string) => setValue('phone', data)}
                  defaultValue={getValues('phone') || ''}
                ></PhoneInputCustom>
                {errors.phone && <p className="errorDescription">Le numero de téléphone est requis</p>}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-no-padding">
                <IonButton type="submit" className="ion-margin-top" disabled={loading} expand="block" color="primary">
                  Confirmer
                </IonButton>
              </IonCol>
            </IonRow>

            <IonToast
              isOpen={isOpen}
              message={__(messageError)}
              onDidDismiss={() => setIsOpen(false)}
              duration={5000}
              color="danger"
            ></IonToast>
          </form>
        </CardCustom>
      </IonContent>
      <ComponentModal isOpen={openDesactivateModal} onCancel={() => setOpenDesactivateModal(false)} height="400px">
        <VerifyAccount phone={getValues('phone')} closeModal={() => setOpenDesactivateModal(false)} />
      </ComponentModal>
    </IonPage>
  );
};

export default SendPhone;
