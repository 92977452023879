import { IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonPage, IonRow } from '@ionic/react';
import { chevronDownOutline, chevronForwardOutline, chevronUpOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Header, Loader } from '../../../components';
import UserContactList from '../../../components/UserContactList';
import { useUserContact } from '../../../hooks/useUserContact';
import './style.css';

const Contact: React.FC = () => {
  const [invitationsInDb, setInvitations] = useState<any[]>([]);
  const {
    syncContact,
    contactsName,
    userContacts,
    follow,
    contactsNotInscrit,
    loading,
    mySubscriptionData,
    refetch,
    loadingFollow,
    blockedList,
    contactsImageNotInscrit,
  } = useUserContact();
  const history = useHistory();

  useEffect(() => {
    syncContact();
  }, []);

  return (
    <IonPage>
      {loading && <Loader />}
      <Header title="Contacts"></Header>
      <IonContent className="ion-padding font-outfit" forceOverscroll>
        <IonGrid className="ion-justify-content-center ion-align-items-center width-p-95 min-height-200 ion-padding-top padding-x-10 bg-white border-radius height-auto">
          <IonRow className="ion-margin-bottom">
            <IonLabel>
              Retrouve tes contacts déjà inscrits sur Tedispo ou invite ceux qui ne se sont pas encore membres.
            </IonLabel>
          </IonRow>
          <IonRow className="line-separator"></IonRow>
          <DrowpdownComponent title="Déjà inscrits sur l'app">
            <UserContactList
              virtuosoStyle={{ height: '38vh' }}
              userContacts={userContacts}
              contactsName={contactsName}
              follow={follow}
              mySubscriptionData={mySubscriptionData}
              refetch={refetch}
              loadingFollow={loadingFollow}
              blockedList={blockedList}
              type="inscrit"
            />
          </DrowpdownComponent>
          <IonRow className="ion-justify-content-center ion-align-items-center ion-padding-top">
            <IonRow
              className="full"
              onClick={() => {
                history.push('/main/my-account/menu/contact/new');
              }}
            >
              <IonCol size="10">Pas encore inscrits</IonCol>
              <IonCol>
                <IonIcon icon={chevronForwardOutline} size="large" />
              </IonCol>
            </IonRow>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Contact;

interface IDrowpDown {
  children: React.ReactNode;
  title: string;
}
const DrowpdownComponent: React.FC<IDrowpDown> = ({ title, children }) => {
  const [showList, setShowList] = useState(true);
  return (
    <IonRow className="ion-justify-content-center ion-align-items-center ion-padding-top">
      <IonRow className="full">
        <IonCol size="10">{title}</IonCol>
        <IonCol>
          <IonIcon
            icon={showList ? chevronDownOutline : chevronUpOutline}
            size="large"
            onClick={() => {
              setShowList(!showList);
            }}
          />
        </IonCol>
      </IonRow>
      {showList && <IonRow className="full">{children}</IonRow>}
    </IonRow>
  );
};
