import { IonButton, IonContent, IonHeader, IonPage, IonRow, IonText, useIonViewDidEnter } from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';
import { useEffect, useRef, useState } from 'react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import DispoHeader from '../../components/Headers/DispoHeader';
import { useDispoDetail } from '../../hooks/useDispoDetail';
import { CustomDispoFooter } from './components/CustomFooter';
import { EventDescription } from './components/EventDescription';
import { EventDetail } from './components/EventDetail';
import { MemberProgressBar } from './components/MemberProgressBar';
import './style.css';
import { getEventBg } from './utils';
import { useHistory, useLocation } from 'react-router';
import { Loader } from '../../components';

const DispoDetail: React.FC = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const dispoData = useDispoDetail();
  const { isMyDispo, loadingEvent, refetchParticipations, refetchParticipation, eventUserParticipations, event } =
    dispoData;
  const [backgroundColor, setBackgroundColor] = useState('white');
  const ionContentRef = useRef<HTMLIonContentElement | null>(null);
  const history = useHistory();
  const location = useLocation();

  const handleScroll = (event: any) => {
    const currentScroll = event.detail.scrollTop > 0 ? event.detail.scrollTop : 0;

    if (currentScroll >= 300) {
      setBackgroundColor('white');
    } else {
      setBackgroundColor(getEventBg(dispoData));
    }
    setScrollTop(currentScroll);
  };

  const reload = () => {
    refetchParticipation();
    refetchParticipations();
  };

  useEffect(() => {
    if ((!scrollTop || scrollTop < 300) && dispoData) {
      setBackgroundColor(getEventBg(dispoData));
    }
  }, [dispoData]);

  useEffect(() => {
    reload();
  }, [location]);

  useIonViewDidEnter(() => {
    ionContentRef.current?.scrollToTop(120);
  });

  return (
    <IonPage>
      {!loadingEvent && dispoData && (
        <>
          <IonHeader class="ion-no-border bg-white " style={{ backgroundColor }}>
            <DispoHeader
              dispoData={dispoData}
              noButtonBack={false}
              rightButton={!!isMyDispo ? 'edit' : 'signal'}
              action={() => console.log('todo')}
              scrollTop={scrollTop}
              resetBackgroundColor={() => setBackgroundColor('white')}
            ></DispoHeader>
          </IonHeader>

          <IonContent
            scrollEvents={true}
            fullscreen
            onIonScroll={handleScroll}
            ref={(ref) => (ionContentRef.current = ref)}
            style={{ maxHeight: '100vh', overflowY: 'auto', backgroundColor: backgroundColor }}
          >
            {!!dispoData && !dispoData.loadingEvent && (
              <div className="bg-white">
                <EventDetail dispoData={dispoData} scrollTop={scrollTop} />
                <EventDescription dispoData={dispoData} />
                <MemberProgressBar dispoData={dispoData} title="Participants" showIcon={true} />
                {!!(
                  !event?.attributes?.deleted &&
                  eventUserParticipations &&
                  eventUserParticipations.length &&
                  eventUserParticipations[0].attributes.status === 'accepted' &&
                  !event?.attributes?.expired
                ) && (
                  <IonButton
                    className="text-underline text-white ion-no-padding ion-no-margin"
                    disabled={loadingEvent}
                    expand="block"
                    fill="clear"
                    color="dark"
                    onClick={() => {
                      history.push(`/main/dispos/${event.id}/inscription-success`);
                    }}
                  >
                    Accéder au ticket
                  </IonButton>
                )}
              </div>
            )}
          </IonContent>
          <CustomDispoFooter dispoData={dispoData} refresh={reload} />
        </>
      )}
      {loadingEvent && <Loader />}
    </IonPage>
  );
};

export default DispoDetail;
