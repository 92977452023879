import {
  IonCard,
  IonCardContent,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from '@ionic/react';

import '@ionic/react/css/ionic-swiper.css';
import { useHistory } from 'react-router';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Enum_Event_Type, EventEntity } from '../../models/gql/graphql';
import { getEventDateData } from '../../pages/DispoDetail/utils';
import { isComingDate, combineDateTime } from '../../utils';
import AvatarUser from '../AvatarUser';
import './style.css';
import DispoLabel from '../DispoLabel';
import { warning, warningOutline } from 'ionicons/icons';
import DispoDate from '../DispoDate';
import { getIcon } from '../../utils/weatherkit';

interface IDispoProps {
  dispo: EventEntity;
  showCreator?: boolean;
  withTag?: boolean;
  fromProfil?: boolean;
  pending?: boolean;
  marginTop?: boolean;
  noWrap?: boolean;
  weatherInfo?: string;
}

const CardDispo: React.FC<IDispoProps> = ({
  dispo,
  showCreator = false,
  withTag,
  fromProfil,
  pending,
  weatherInfo,
  marginTop = true,
  noWrap = false,
}) => {
  const history = useHistory();
  const eventDateData = getEventDateData(dispo);

  return (
    <IonCard
      className={`card-custom ion-no-margin ${marginTop ? 'ion-margin-top' : ''}  ${
        !fromProfil && dispo.attributes?.expired ? 'grey-mode' : ''
      }`}
      onClick={() => history.push('/main/dispos/' + dispo.id)}
    >
      <div className="container-image">
        {/* {withTag && (
          <IonChip className="tag-position" color="primary">
            {dispo?.attributes?.type === Enum_Event_Type.Private ? 'PRIVÉE' : 'PUBLIQUE'}
          </IonChip>
        )} */}
        <img
          className="image-card-custom"
          alt={
            dispo?.attributes?.coverCustomImage?.data
              ? (dispo.attributes.coverCustomImage.data.attributes?.alternativeText as string)
              : dispo.attributes?.coverTedispoImage?.data
              ? (dispo.attributes.coverTedispoImage.data.attributes?.image?.data?.attributes?.alternativeText as string)
              : 'avatar'
          }
          src={
            dispo?.attributes?.coverCustomImage?.data
              ? dispo.attributes.coverCustomImage.data.attributes?.url
              : dispo.attributes?.coverTedispoImage?.data
              ? dispo.attributes.coverTedispoImage.data.attributes?.image?.data?.attributes?.url
              : 'https://ionicframework.com/docs/img/demos/avatar.svg'
          }
        />
        {/*TODO {dispo.attributes?.status === 'in_progress' && (
          <IonRow className="float-tr ion-padding">
            <DispoLabel label={'En cours'} bgClass="bg-yellow" borderClass="border-white" />
          </IonRow>
        )} */}
        {!showCreator && dispo.attributes?.paymentType !== 'Gratuite' && pending && (
          <IonRow className="float-tr ion-padding">
            <DispoLabel icon={warning} label={'En attente'} bgClass="bg-orange" borderClass="border-white" />
          </IonRow>
        )}

        {dispo.attributes?.type && (
          <IonRow className="float-bl padding-5">
            <DispoLabel label={dispo.attributes?.type === 'private' ? 'privée' : 'publique'} uppercase={true} />
          </IonRow>
        )}
      </div>
      <IonCardContent className="padding-card">
        <IonList className="ion-no-padding">
          <IonItem lines="none" className="ion-no-padding ion-item-min ion-text-capitalize">
            <DispoDate dispoDate={eventDateData} noWrap={noWrap} />
          </IonItem>
          <IonItem lines="none" className="ion-no-padding ion-item-min">
            <IonLabel className="ion-no-margin">
              <h3 className="font-title font-outfit font-text">{dispo.attributes?.name}</h3>
            </IonLabel>
          </IonItem>
          {/* <IonItem lines="none" className="ion-no-padding ion-item-min">
            <IonLabel className="ion-no-margin">
              <h3 className="font-subtitle font-outfit font-text d-flex-title">
                <span>{eventDateData.debut}</span> <span className="point-separator"></span>{' '}
                <span>{eventDateData.fin}</span>
              </h3>
            </IonLabel>
          </IonItem>
          <IonItem lines="none" className="ion-no-padding ion-item-min">
            <IonLabel className="ion-no-margin">
              <h3 className="font-title font-outfit font-text">{dispo.attributes?.name}</h3>
            </IonLabel>
          </IonItem> */}
          <IonGrid
            className="ion-no-padding ion-no-margin margin-top-xs"
            style={{ flexWrap: noWrap ? 'nowrap' : 'wrap', minHeight: '50px' }}
          >
            <IonRow className="ion-no-padding ion-no-margin" style={{ flexWrap: noWrap ? 'nowrap' : 'wrap' }}>
              <IonCol size="auto" className="flex-card-avatar">
                <img alt="pdp" src="images/place.svg" className="avatar-card" />
              </IonCol>
              <IonCol
                style={{ overflow: noWrap ? 'hidden' : 'visible', display: 'flex' }}
                className="ion-align-items-center"
              >
                <p
                  className={`font-subtitle font-outfit font-grey-light margin-left-label ${noWrap ? 'wrap-text' : ''}`}
                >
                  {dispo.attributes?.addressLine}
                </p>
              </IonCol>
              {!showCreator && !showCreator && (
                <div className="weather-container">
                  <IonIcon icon={getIcon(weatherInfo)} />
                </div>
              )}
            </IonRow>
          </IonGrid>
          {showCreator && (
            <IonGrid className="ion-no-padding ion-no-margin margin-top-sm">
              <IonRow className="ion-no-padding ion-no-margin">
                <IonCol size="auto" className="flex-card-avatar ion-align-items-center" style={{ display: 'flex' }}>
                  {/* <img
                    alt="pdp"
                    src={
                      dispo.attributes?.creator?.data?.attributes?.profilePicture?.data
                        ? dispo.attributes?.creator?.data?.attributes?.profilePicture.data?.attributes?.url
                        : dispo.attributes?.creator?.data?.attributes?.avatar?.data
                        ? dispo.attributes?.creator?.data?.attributes.avatar.data?.attributes?.image.data?.attributes
                            ?.url
                        : 'https://ionicframework.com/docs/img/demos/avatar.svg'
                    }
                    className="avatar-card"
                  /> */}
                  <AvatarUser
                    user={dispo.attributes?.creator?.data?.attributes}
                    size="size-sm"
                    classCustom="avatar-image"
                  ></AvatarUser>
                </IonCol>
                <IonCol style={{ display: 'flex' }} className="ion-align-items-center">
                  <p className="font-subtitle font-outfit font-grey-light margin-left-label">
                    {(dispo.attributes?.creator?.data?.attributes?.isPro
                      ? dispo.attributes?.creator?.data?.attributes?.companyName
                      : dispo.attributes?.creator?.data?.attributes?.username) ||
                      `${
                        dispo.attributes?.creator?.data?.attributes?.firstname
                          ? dispo.attributes?.creator?.data?.attributes?.firstname
                          : ''
                      } ${
                        dispo.attributes?.creator?.data?.attributes?.lastname
                          ? dispo.attributes?.creator?.data?.attributes?.lastname
                          : ''
                      }`}
                  </p>
                </IonCol>
                {weatherInfo && (
                  <div className="weather-container">
                    <IonIcon icon={getIcon(weatherInfo)} />
                  </div>
                )}
              </IonRow>
            </IonGrid>
          )}
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

export default CardDispo;
