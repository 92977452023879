import { IonHeader, IonToolbar, IonRow, IonCol, IonIcon, IonTitle } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import { FC } from 'react';

interface IFilter {
  close: () => void;
  title: string;
  color?: string;
}
const FilterHeader: FC<IFilter> = ({ title, color, close }) => {
  return (
    <IonHeader className="ion-no-border padding-t-25" mode="ios">
      <IonToolbar mode="ios">
        <IonRow>
          <IonCol size="1">
            <IonIcon icon={arrowBackOutline} onClick={() => close()}></IonIcon>
          </IonCol>
          <IonCol>
            <IonTitle className={`text-center ' ${color ? color : ''}`}>{title}</IonTitle>
          </IonCol>
        </IonRow>
      </IonToolbar>
    </IonHeader>
  );
};

export default FilterHeader;
