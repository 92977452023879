import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react';
import { EventEntity } from '../../../models/gql/graphql';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CardDispo, CardLoader } from '../../../components';
import { CardDispoPublic } from '../../../components/Algolia';
import { transformEventDate } from '../../DispoDetail/utils';
import React from 'react';

interface ISectionDispo {
  loading?: boolean;
  events?: any[];
  title: string;
  usePagination?: boolean;
  numberView?: number;
  noWrap?: boolean;
  weatherData?: any;
}

const SectionDispoFriends: React.FC<ISectionDispo> = ({
  loading,
  events,
  title,
  usePagination = true,
  numberView = 1,
  noWrap = false,
  weatherData = {},
}) => {
  const [numberSwiper, setNumberSwiper] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const initSwiper = (swiper: any) => {
    setSwiperRef(swiper);
  };
  const scrollContainerRef = useRef(null);

  const [indexInTheMiddle, setIndexInTheMiddle] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        const { scrollLeft, clientWidth, scrollWidth } = scrollContainer;
        // Check if scrolled to the end
        const isEnd = 0 <= scrollWidth - (scrollLeft + clientWidth) && scrollWidth - (scrollLeft + clientWidth) <= 0.5;
        if (isEnd) {
          // PUT FUNCTION HERE
        }
      }
    };

    // Attach the scroll event listener
    const scrollContainer = scrollContainerRef.current as any;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    // Cleanup function
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [events]);

  return (
    <div className="ion-margin-top">
      <IonText color="dark" className="font-outfit">
        <h4>{title}</h4>
      </IonText>
      {!loading && events?.length === 0 ? (
        <IonText>Aucune donnée pour le moment</IonText>
      ) : (
        <>
          <IonGrid
            ref={scrollContainerRef}
            className="hide-scrollBar"
            style={{ overflow: 'auto', padding: 0, paddingBottom: 10 }}
          >
            <IonRow className="col-card" style={{ flexWrap: 'nowrap', padding: 0 }}>
              {loading ? (
                <IonCol size="12" className="col-card">
                  <CardLoader />
                </IonCol>
              ) : (
                <>
                  {events &&
                    events?.map((hit, index: number) => {
                      return (
                        <IonCol size="12" key={index}>
                          <CardDi
                            hit={hit}
                            noWrap={noWrap}
                            index={index}
                            handleIndexMiddle={(index) => setIndexInTheMiddle(index)}
                            scrollContainer={scrollContainerRef}
                            events={events}
                            weatherInfo={weatherData[hit.id]}
                          ></CardDi>
                        </IonCol>
                      );
                    })}
                </>
              )}
            </IonRow>
          </IonGrid>
          <IonGrid>
            <IonRow style={{ justifyContent: 'center' }}>
              {events &&
                events.map((hit: any, index: number) => {
                  return (
                    <IonCol
                      size="auto"
                      key={index}
                      className={`trait-round ${indexInTheMiddle === index ? 'filled' : 'outline'}`}
                    ></IonCol>
                  );
                })}
            </IonRow>
          </IonGrid>
        </>
      )}
    </div>
  );
};

export default SectionDispoFriends;

interface ICard {
  hit: EventEntity;
  index: number;
  noWrap: boolean;
  handleIndexMiddle?: (index: number) => void;
  scrollContainer: any;
  events: EventEntity[];
  weatherInfo: string;
}

const CardDi: React.FC<ICard> = ({ hit, index, noWrap, scrollContainer, handleIndexMiddle, events, weatherInfo }) => {
  const elementRef: any = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const scrollContainerRef = scrollContainer.current;
      if (elementRef.current && scrollContainerRef) {
        const { scrollLeft, clientWidth } = scrollContainerRef;
        const elementRect = elementRef.current.getBoundingClientRect();

        const elementLeft = elementRect.left + scrollLeft;
        const elementRight = elementRect.right + scrollLeft;

        // Calculate the middle of the screen
        const middleOfScreen = scrollLeft + clientWidth / 2;

        // Check if the element is in the middle of the screen

        if (elementLeft <= middleOfScreen && elementRight >= middleOfScreen) {
          if (handleIndexMiddle) handleIndexMiddle(index);
        }
      }
    };

    const scrollContainerRef = scrollContainer.current;
    if (scrollContainerRef) {
      scrollContainerRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainerRef) {
        scrollContainerRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [events]);

  return (
    <>
      <div ref={elementRef} key={index} style={{ width: '100%', textAlign: 'left' }}>
        <CardDispo
          key={index}
          dispo={hit as EventEntity}
          withTag={false}
          showCreator={false}
          marginTop={false}
          noWrap={noWrap}
          weatherInfo={weatherInfo}
        ></CardDispo>
      </div>
    </>
  );
};
