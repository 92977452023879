import { IonButton, IonCol, IonHeader, IonIcon, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import { ReactNode } from 'react';
import { useHistory } from 'react-router';
import { EventEntity } from '../../models/gql/graphql';

interface IHeader {
  title: string;
  noButtonBack?: boolean;
  children?: ReactNode;
  showMenuButton?: boolean;
  action?: () => void;
  event?: EventEntity;
  backButtonColor?: string;
  backAction?: () => void;
  addMarginTop?: boolean;
}

const SimpleHeader: React.FC<IHeader> = ({
  title,
  noButtonBack,
  children,
  showMenuButton = false,
  action,
  event,
  backButtonColor,
  backAction,
  addMarginTop = false,
}) => {
  const history = useHistory();
  const goBack = () => {
    history.go(-1);
  };
  return (
    <IonHeader mode="ios" className={`ion-no-border ${addMarginTop ? 'margin-t-20' : ''}`}>
      <IonToolbar>
        <IonRow>
          <IonCol size={!noButtonBack ? '1.5' : '0'}>
            <IonButton fill="clear" onClick={backAction || goBack}>
              <IonIcon
                slot="start"
                icon={arrowBackOutline}
                className={`${backButtonColor || 'white'} ${noButtonBack ? 'opacity-0' : ''} `}
              ></IonIcon>
            </IonButton>
          </IonCol>
          <IonCol size={!noButtonBack ? '9.5' : ''} className="text-center ">
            <IonTitle className="text-center font-outfit">{title}</IonTitle>
          </IonCol>
        </IonRow>
      </IonToolbar>
    </IonHeader>
  );
};

export default SimpleHeader;
