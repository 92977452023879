import React, { useEffect } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { IonRefresher, RefresherEventDetail, IonRefresherContent, IonSkeletonText } from '@ionic/react';
import { useAppStore } from '../../../store';

const CustomRefresh: React.FC = () => {
  const { status, refresh, setUiState } = useInstantSearch();
  const {
    RootStore: { algoliaCache, shouldRefreshNow, setShouldRefreshNow },
  } = useAppStore();
  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await refreshNow();
    event.detail.complete();
  };
  const refreshNow = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setUiState((state) => {
          Object.keys(state).forEach((algoliaIndex) => {
            state[algoliaIndex].page = 0;
          });
          return state;
        });
        await algoliaCache.invalidate();
        setTimeout(async () => {
          await refresh();
          resolve(true);
        }, 300);
      } catch (e) {
        reject(e);
      }
    });
  };
  useEffect(() => {
    if (shouldRefreshNow) {
      refreshNow();
      setTimeout(() => {
        setShouldRefreshNow(false);
      });
    }
  }, [shouldRefreshNow]);

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      {(status === 'stalled' || status === 'loading') && (
        <div className="ion-padding custom-skeleton">
          <IonSkeletonText animated style={{ width: '60%' }} />
          <IonSkeletonText animated />
          <IonSkeletonText animated style={{ width: '88%' }} />
          <IonSkeletonText animated style={{ width: '70%' }} />
          <IonSkeletonText animated style={{ width: '30%' }} />
          <IonSkeletonText animated style={{ width: '80%' }} />
          <IonSkeletonText animated style={{ width: '40%' }} />
        </div>
      )}
    </>
  );
};

export default CustomRefresh;
