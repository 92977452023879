import { graphql } from '../../models/gql';

export const FOLLOW_USER = graphql(/* GraphQL */ `
  mutation followUser($ids: [ID]) {
    FollowUser(ids: $ids) {
      success
    }
  }
`);

export const INVITE_CONTACT = graphql(/* GraphQL */ `
  mutation inviteContacts($phone: String!) {
    InviteContact(phone: $phone) {
      success
    }
  }
`);
