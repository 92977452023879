import { IonContent, IonPage } from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';
import { useLocation, useParams } from 'react-router';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Preview, StepInfo, StepInvitation, StepOptions, StepPhoto, StepTarif } from '../CreateDispo';

const DuplicateDispo: React.FC = () => {
  const { id } = useParams<IVerifParams>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get('page');

  return (
    <>
      {page && (
        <>
          <>{page === 'step-info' && <StepInfo id={id} duplicate={true} />}</>
          <>{page === 'step-invitation' && <StepInvitation id={id} duplicate={true} />}</>
          <>{page === 'step-photo' && <StepPhoto id={id} duplicate={true} />}</>
          <>{page === 'step-tarif' && <StepTarif id={id} duplicate={true} />}</>
          <>{page === 'step-options' && <StepOptions id={id} duplicate={true} />}</>
          <>{page === 'preview' && <Preview id={id} duplicate={true} />}</>
        </>
      )}
    </>
  );
};

export default DuplicateDispo;
