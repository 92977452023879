// This file is Optional
// Use wording file to define any text and fixed to show on front for readability, editing, localization
// You could use a cms api to fetch to wording value
// No hardcoded value should be defined inside react components

import { IMessengerWording } from '.';

const MessengerWording: IMessengerWording = {
  title: 'Messagerie',
  noMessages: 'Aucun message',
  placeholder: 'Aa',
  acceptedOffer: "Je viens d'accepter votre offre.",
  refusedOffer: 'Désole, votre offre ne me convient pas.',
};

export default MessengerWording;
