import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonText, IonToolbar } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router';
import './style.css';

interface IHeader {
  children?: ReactNode;
  title: string;
}

const DispoHeader: React.FC<IHeader> = ({ children, title }) => {
  const history = useHistory();
  const goBack = () => {
    history.go(-1);
  };

  return (
    <IonRow className="ion-no-border">
      <IonRow
        className="full-width "
        style={{
          backgroundImage:
            'linear-gradient(to bottom, rgba(241, 241, 241, 0), rgba(241, 241, 241, 0%), rgba(241, 241, 241, 0%), rgba(4, 177, 113, 1))',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'absolute',
          backgroundColor: `rgba(4, 177, 113, 1)`,
        }}
      >
        <IonToolbar mode="ios" color="translucent" className="full padding-safearea">
          <IonRow style={{ position: 'relative' }} className="ion-align-items-center ion-justify-context-center">
            <IonCol size="2">
              <IonButton fill="clear" onClick={goBack}>
                <IonIcon slot="start" icon={arrowBackOutline} className="text-white"></IonIcon>
              </IonButton>
            </IonCol>
            <IonCol size="10">
              <IonText className="text-white">{title}</IonText>
            </IonCol>
          </IonRow>
          <IonGrid style={{ height: '50vh' }}>{children}</IonGrid>
        </IonToolbar>
      </IonRow>
      {children}
    </IonRow>
  );
};

export default DispoHeader;
