import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const ChatWrapper = styled(IonPage)`
  &.chat {
    .chat__msg-container {
      --padding-top: 15px;
      --padding-bottom: 15px;
      --padding-start: 15px;
      --padding-end: 15px;
      border-radius: var(--Corner-16, 16px);
      background: #F8F8F8;
    }

    .chat__item-body__container {
      display: flex;
      flex-direction: column;
    }

    .menu-container {
      margin-right: 12px;
    }
    .chat_no-message {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-direction: column;
    }
    .chat_no-message__text {
      color: #313333;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 27px;
    }
    .chat_dispo_no-message__text {
      color: #313333;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 25.2px;
    }
    .chat__avatar {
      width: 32px;
      height: 32px;
      overflow: hidden;
      border-radius: 50%;
      background: #ccc;
    }

    .chat__from {
      color: #313333;
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-left: 44px;
    }

    .chat__respond-to {
      align-self: center;
      color: #313333;
      leading-trim: both;
      text-edge: cap;
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      display: flex;
      align-items: center;
    }

    .chat__respond-to-other {
      align-self: center;
      color: #313333;
      leading-trim: both;
      text-edge: cap;
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      display: flex;
      align-items: center;
    }
    }

    .chat__item {
      display: flex;
      align-items: flex-end;
      margin-bottom: 28px;
      position: relative;
    }
    .chat__item--right {
      justify-content: flex-end;
    }

    .chat__item_gallery-container {
      margin-bottom: 25px;
      position: relative;
    }

    .chat__item-body {
      background: #ffffff;
      border-radius: 8px 8px 8px 0px;
      padding: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: var(--ion-color-dark-shade);
      max-width: 70vw;
    }
    .chat__item-parent-body {
      background: #ffffff;
      border-radius: 8px 8px 0px 0px;
      padding: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: var(--ion-color-dark-shade);
      max-width: 60vw;
    }

    .chat__item-parent-body-other {
      background: #ffffff;
      border-radius: 8px 8px 0px 0px;
      padding: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: var(--ion-color-dark-shade);
      border: 1px solid var(--ion-color-primary);
      border-bottom: none;
      margin-right: 5vw;
      max-width: 60vw;
    }

    .chat__item-body__edited {
      color: #313333 !important;
    }

    .chat__avatar--left {
      margin-right: 10px;
    }

    .chat__avatar--hidden {
      visibility: hidden;
    }

    .chat__item--right .chat__item-body {
      border-radius: 8px 8px 0px 8px;
      background: #ffffff;
      color: var(--ion-color-primary);
      border: 1px solid var(--ion-color-primary);
      z-index: 1;
    }

    .chat__item--right .chat__item-parent-body {
      border-radius: 8px 8px 0px 0px;
      background: #ffffff;
      color: #313333;
      border: 1px solid #313333;
      border-bottom: none;
      margin-right: 5vw;
      margin-top: 3px;
      position: relative;
      top: 5px;
    }

    .chat__item--right .chat__item-parent-body-other {
      border-radius: 8px 8px 0px 0px;
      background: #ffffff;
      color: #313333;
      border: 1px solid var(--ion-color-primary);
      border-bottom: none;
      margin-right: 5vw;
      margin-top: 3px;
      position: relative;
      top: 5px;
    }

    

    .chat__avatar--right {
      margin-left: 10px;
    }

    .chat__item .chat__avatar--right {
      display: none;
    }
    .chat__item--right .chat__avatar--left {
      display: none;
    }
    .chat__item--right .chat__avatar--right {
      display: block;
    }
    .chat__notification {
      margin: 10px;
      ion-icon {
        font-size: 14px !important;
      }
    }

    .chat__edit-indicator {
      background: #313333;
      leading-trim: both;
      text-edge: cap;
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      height: 50px;
      display: flex;
      align-items: center;
      border: 1px solid #313333;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      position: absolute;
      top: -50px;
      z-index:3;
      width: 100%;
    }

    .chat-form {
      width: 343px;
      height: 52px;
      border: 1px solid #d8dcdc;
      border-radius: 26px;
      background: #f4f5f5;
      width: calc(100% - 32px);
      margin: 16px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
    }

    .chat-form input {
      border: none;
      background: none;
      flex: 1;
      height: 100%;
      outline: none;
      color: red;
      &:active,
      &:focus {
        outline: none;
      }
    }
    .chat__submit {
      border: none;
      background: none;
      height: 100%;
      margin-left: 16px;
      align-items: center;
      justify-content: center;
      color: #000000 !important;
      position: absolute;
      right: 40px;
      top: -2px;
      z-index: 999;
    }

    .chat__attach {
      border: none;
      background: none;
    }

    .chat__file-upload-modal_button {
      height: 20px;
      background: red;
    }

    .chat__time {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: #9c9f9f;
      position: absolute;
      left: 40px;
      bottom: -20px;
    }
    .chat__time__deleted {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      margin-top:0;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: #9c9f9f;
    }
    .chat__item--right .chat__time {
      left: unset;
    }
    ion-header ion-title {
      text-transform: unset;
    }
    ion-textarea.custom {
      --color: #000;
        padding-top: 6px;
    }
    ion-textarea.custom .helper-text {
      color: #373737;
    }
    .chat__files-time {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: #9c9f9f;
      position: absolute;
      left: 40px;
      bottom: -20px;
    }

    .chat__audio-container {
      display: flex;
      align-items: center;
    }

    .chat__audio-graph-container {
      background-color: #3D3BEB;
    }

    .chat__audio-graph-timer {
      margin-right: 10px;
      color: #fff;
    }

    .chat__audio-graph-animation-container {
      display: flex;
      align-items: center;
    }

    .chat__audio-red-graph-container {
      background-color: rgba(249, 67, 67, 0.30);
    }

    .chat__audio-red-graph {
      background: #F94343;
    }

    .chat__audio-graph-animation {
      width: 90%;
    }

    .chat__item__files-right {

    }
    .chat__item__files-right .chat__files-time {
      left: unset;
      bottom: unset;
      right: 15px;
    }
    .chat__item {
      &.has_notification {
        margin-left: 42px;
        margin-right: 42px;

        .chat__item-body {
          max-width: 100vw !important;
        }
        .chat__item-parent-body {
          max-width: 100vw !important;
        }
        .chat__time {
          left: 0 !important;
        }
      }
    }
  }

  @media (min-width: 992px) {
    &.chat {
    }
  }
`;
