import { IonLabel, IonRow } from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { UseInfiniteHitsProps, useInfiniteHits, useInstantSearch } from 'react-instantsearch';
import { LogLevel, Virtuoso } from 'react-virtuoso';
import { useMyLike } from '../../../hooks/usePosts';
import { UploadFileEntity } from '../../../models/gql/graphql';
import Posts from '../../../pages/Profil/Posts';
import FullSlider from '../../FullSlider';
import CardPost from '../CardPost/CardPost';

function SearchInfinitePostHits(
  props: UseInfiniteHitsProps & { isProfile?: boolean; locked?: boolean; onRefresh?: () => void }
) {
  const { hits, showMore, isLastPage } = useInfiniteHits(props);
  const { status, refresh, setUiState } = useInstantSearch();
  const sentinelRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState<UploadFileEntity[]>();
  const virtuosoRef = useRef<any>(null);

  const { refetchLike, myLikes } = useMyLike();

  useEffect(() => {
    if (hits.length > 0) {
      setIsLoading(false);
    }
  }, [hits]);

  const refreshList = async () => {
    console.log('refreshList');
    if (props.onRefresh) await props.onRefresh();
    setUiState((state) => {
      Object.keys(state).forEach((algoliaIndex) => {
        state[algoliaIndex].page = 0;
      });
      return state;
    });
    await (props.cache as any).invalidate();
    setTimeout(async () => {
      await refresh();
    }, 300);
  };
  const loadMore = useCallback(() => {
    console.log('isLastPage', isLastPage);
    if (hits.length && !isLastPage && status == 'idle') {
      console.log('show more');
      return showMore();
    } else {
      return false;
    }
  }, [showMore, hits, status, isLastPage]);

  return (
    <>
      {props?.isProfile ? (
        <Posts locked={props.locked} posts={hits} />
      ) : (
        <div>
          <Virtuoso
            increaseViewportBy={200}
            endReached={() => {
              return loadMore();
            }}
            ref={virtuosoRef}
            data={hits}
            style={{ height: 'calc(100vh - 200px)' }}
            logLevel={LogLevel.DEBUG}
            itemContent={(
              index,
              {
                id,
                medias,
                description,
                creator,
                creatorFirstname,
                creatorLastname,
                commentsCount,
                creatorUsername,
                isPro,
                creatorCompanyName,
                creatorPicture,
                nbLikes,
                nbComments,
              }
            ) => (
              <CardPost
                key={id}
                id={id}
                medias={medias}
                description={description}
                creatorId={creator}
                creatorFirstname={creatorFirstname}
                creatorLastname={creatorLastname}
                commentsCount={commentsCount}
                creatorUsername={creatorUsername}
                isPro={isPro}
                creatorCompanyName={creatorCompanyName}
                creatorPicture={creatorPicture}
                nbComments={nbComments}
                nbLikes={nbLikes}
                myLikes={myLikes}
                setFiles={setFiles}
                refetchLike={refetchLike}
                refreshList={refreshList}
                margin
              />
            )}
          />

          {!hits.length && !isLoading && status !== 'stalled' && status !== 'loading' && (
            <IonRow class="ion-justify-content-center font-outfit">
              <IonLabel>Aucune dispo ne correspond à ta recherche.</IonLabel>
            </IonRow>
          )}
          <IonRow className="sentinel" ref={sentinelRef} />
          {!!(files && files.length) && <FullSlider files={files || []} setFiles={setFiles} />}
        </div>
      )}
    </>
  );
}

export default SearchInfinitePostHits;
