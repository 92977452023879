import { EventInput, Event, EventEntity } from '../../../models/gql/graphql';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';

export interface stateType {
  dispo: EventInput;
  imageUrl: string;
  imageSelecteds: handleType[];
  dispoView: EventEntity;
  notifications?: any[];
  searchQuery?: string;
  newMessage?: number;
  toasts: any[];
  db: SQLiteDBConnection | null;
  canceledEvent: boolean;
  redirectHome: Enum_segment_home;
  fullSlider: boolean;
  videoPlayed?: string;
  reloadHome?: boolean;
}

export interface actionType {
  type: String;
  payload?: any;
}

export type handleType = {
  file?: HTMLInputElement | null;
  url?: string | null;
};

export enum Enum_segment_home {
  feed = 'feed',
  dispos = 'dispos',
  users = 'users',
}
