import { IonButton, IonCol, IonIcon, IonRow, IonText, IonToolbar } from '@ionic/react';
import { arrowBackOutline, ellipsisVerticalOutline, warning } from 'ionicons/icons';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import flagSvg from '../../../assets/icons/flag.svg';
import pinSvg from '../../../assets/icons/localisation.svg';
import { Enum_Event_Type, Enum_Report_Type } from '../../../models/gql/graphql';
import { DispoMenu } from '../../../pages/DispoDetail/components/DispoMenu';
import { getEventBg, getEventDateData, verifyIfEventDateHasPassed } from '../../../pages/DispoDetail/utils';
import './style.css';
import DispoDate from '../../DispoDate';
import { REPORTING_LIST } from '../../../constants';
import Reporting from '../../Reporting';
import DispoLabel from '../../DispoLabel';
import { useAppStore } from '../../../store';

interface IHeader {
  noButtonBack?: boolean;
  children?: ReactNode;
  action?: () => void;
  imageUrl?: string;
  eventTypeProps?: Enum_Event_Type;
  eventNameProps?: string;
  dispoData: IDispoDetail;
  rightButton?: string;
  scrollTop: number;
  showDispoDetail?: boolean;
  resetBackgroundColor?: () => void;
}

const DispoHeader: React.FC<IHeader> = ({
  noButtonBack,
  children,
  action,
  imageUrl,
  eventTypeProps,
  eventNameProps,
  dispoData,
  rightButton,
  scrollTop,
  showDispoDetail,
  resetBackgroundColor,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [titleHeight, setTitleHeight] = useState(80);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const history = useHistory();
  const divRef = useRef(null);
  const bgFix = 130;
  const scrollFix = showDispoDetail ? 53 : 120;
  const popoverId = Math.floor(Math.random() * 10000000);
  const { image, eventType, eventName, event, paymentType } = dispoData;
  const dateHasPassed = verifyIfEventDateHasPassed(dispoData.event);
  const eventDateData = getEventDateData(event);
  const location = useLocation();
  const {
    RootStore: { userData },
  } = useAppStore();

  const goBack = async () => {
    resetBackgroundColor && (await resetBackgroundColor());
    const goToMyDispo = new URLSearchParams(location.search).get('goToMyDispo');
    if (goToMyDispo) {
      history.push('/main/dispos');
    } else {
      history.go(-1);
    }
  };
  const closePopover = () => {
    setShowPopover(false);
  };
  const openPopover = () => {
    setShowPopover(true);
  };

  let backgroundColor = getEventBg(dispoData);
  let backgroundColorProps = eventTypeProps
    ? eventTypeProps === Enum_Event_Type.Private
      ? 'rgba(61, 39, 235, 1)'
      : 'rgba(4, 177, 113, 1)'
    : '';

  let color = eventType ? (eventType === Enum_Event_Type.Private ? 'blue' : 'green') : 'green';

  useEffect(() => {
    if (divRef && divRef.current) {
      setTitleHeight((divRef?.current! as HTMLDivElement).clientHeight + 130);
    }
  }, [scrollTop]);

  const showModal = () => {
    if (rightButton === 'edit') {
      openPopover();
    } else {
      setIsOpenModal(true);
    }
  };

  const isOngoing = () => {
    if (!event) {
      return false;
    }
    if (event.attributes?.beginningDateTime && event.attributes.expirationDateTime) {
      const currentDate = new Date();
      const startDate = new Date(event.attributes?.beginningDateTime);
      const endDate = new Date(event.attributes.expirationDateTime);
      return startDate <= currentDate && currentDate <= endDate;
    }
    return false;
  };

  const showAccountNotif =
    userData?.stripeCapabilitiesStatus !== 'active' &&
    paymentType !== 'Gratuite' &&
    dispoData?.creator?.id === userData?.id;

  const hasPayment = useMemo(() => {
    return (
      dispoData.eventParticipations.some((i) => i.attributes.status === 'accepted') &&
      dispoData.paymentType === 'Payante'
    );
  }, [dispoData]);
  return (
    <IonRow className="ion-no-border">
      <IonRow
        className="full-width"
        style={{
          height: `${Math.max(265 - scrollTop, titleHeight)}px`,
          maxHeight: '265px',
          backgroundImage: `${
            scrollTop < bgFix
              ? `linear-gradient(to bottom, rgba(241, 241, 241, 0), rgba(241, 241, 241, 0%), rgba(241, 241, 241, 0%), ${
                  imageUrl ? backgroundColorProps : backgroundColor
                }), url(${imageUrl ? imageUrl : image})`
              : ''
          }`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'absolute',
          backgroundColor: `${backgroundColor}`,
        }}
      >
        <IonToolbar mode="ios" color="translucent" className="full">
          <IonRow className="p-relative z-top">
            <IonCol size="6">
              {!!!(noButtonBack || new URLSearchParams(location.search).get('noBackButton')) && (
                <IonButton fill="clear" onClick={goBack}>
                  <IonIcon slot="start" icon={arrowBackOutline} className="text-white icon-shadow"></IonIcon>
                </IonButton>
              )}
            </IonCol>
            <IonCol size="6">
              <IonButton
                fill="clear"
                onClick={showModal}
                id={rightButton === 'edit' ? popoverId + '' : ''}
                className="float-right"
              >
                <IonIcon icon={rightButton === 'edit' ? flagSvg : flagSvg} className="text-white icon-shadow"></IonIcon>
              </IonButton>
              <DispoMenu
                trigger={popoverId + ''}
                showPopover={showPopover}
                noCancel={event?.attributes.expired || hasPayment}
                closePopover={closePopover}
              />
            </IonCol>
          </IonRow>
          {scrollTop < scrollFix && !!!dateHasPassed && showDispoDetail && (
            <IonRow
              className={`text-outfit float-right margin-r-5 no-margin-t-10 border-${color} padding-5 border-radius-10 text-uppercase text-bold bg-white text-${color} text-size-xs`}
            >
              <IonText>{isOngoing() ? 'en cours' : 'à venir'} </IonText>
            </IonRow>
          )}
          <div
            ref={divRef}
            className={`text-outfit full ${scrollTop < scrollFix && !showDispoDetail ? `flex-end` : ''}`}
            style={{
              position: scrollTop < scrollFix ? 'relative' : undefined,
              marginTop: scrollTop >= scrollFix ? '-15px' : !showDispoDetail ? '0px' : '',
              height:
                scrollTop >= scrollFix
                  ? 'auto'
                  : `calc(${(showAccountNotif ? 140 : 150) - scrollTop}px - var(--ion-safe-area-top, 0))`,
            }}
          >
            <div className="text-capitalize text-white font-outfit margin-inline">
              {showDispoDetail && eventDateData && (
                <DispoDate withShadown={scrollTop < bgFix} dispoDate={eventDateData} />
              )}

              <div className={`text-capitalize text-x text-dispo-detail ${scrollTop < bgFix ? 'text-shadow' : ''} `}>
                {eventNameProps ? eventNameProps : eventName}
              </div>
              {showDispoDetail && (
                <div className={`text-vw-4 ${scrollTop < bgFix ? 'text-shadow text-bolder' : ''}`}>
                  <IonIcon icon={pinSvg} className="text-size-xs margin-r-5"></IonIcon>
                  {dispoData.event.attributes?.addressLine}
                </div>
              )}
            </div>
          </div>
          {showAccountNotif && scrollTop + 20 < scrollFix && (
            <IonRow className="ion-padding">
              <IonCol>
                <DispoLabel
                  bgClass="bg-orange"
                  icon={warning}
                  label="Ta dispo sera en ligne dès que ton compte banciare aura été validé"
                />
              </IonCol>
            </IonRow>
          )}
        </IonToolbar>
        {scrollTop >= scrollFix && (
          <div
            className=" border-radius-top full-width margin-0 padding-0"
            style={{
              position: 'relative',
              top: '-5px',
              boxShadow: `0px -10px 0px ${backgroundColor}`,
              padding: '10px',
            }}
          ></div>
        )}
      </IonRow>
      {children}
      <Reporting
        actionCancel={() => setIsOpenModal(false)}
        listItems={REPORTING_LIST}
        isOpen={isOpenModal}
        contentTypeReported={Enum_Report_Type.Evenement}
        title="Pour quel motif veux-tu effectuer ce signalement ?"
        relatedId={dispoData?.id}
        userId={dispoData?.creator?.id}
      ></Reporting>
    </IonRow>
  );
};

export default DispoHeader;
