import {
  IonPage,
  IonContent,
  IonLabel,
  IonItem,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
  useIonViewDidEnter,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react';
import { Header, Notification, CardCustom, CardLoader } from '../../components';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import { EventEntity, NotificationEntity } from '../../models/gql/graphql';
import { cloneDeep, remove } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getUserId } from '../../utils';
import './style.css';
import { useNotifications } from '../../hooks/useNotifications';
import { MARK_AS_SEEN } from '../../graphql/mutations/notification.graphql';
import { useAppStore } from '../../store';
import { deleteEventToCalendar, editEventToCalendar } from '../../hooks/synchroniseCalendar';
interface IParams {
  id: string;
}
type NotificationNewOld = {
  newNotifications?: NotificationEntity[];
  oldNotifications?: NotificationEntity[];
};

const Notifications: React.FC = () => {
  let { id } = useParams<IParams>();
  if (id === 'mine' || id === 'me') {
    id = getUserId() || '';
  }
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const {
    notifications: notificationsData,
    fetch,
    more,
    meta: paginationMeta,
    setNotifications,
    loading: listLoading,
  } = useNotifications();

  const {
    RootStore: { algoliaCache, setRefreshDispos, refreshDispos },
  } = useAppStore();
  const [markAsSeen] = useMutation(MARK_AS_SEEN);
  const [loading, setLoading] = useState(false);
  const notifications: NotificationNewOld = useMemo(() => {
    const notif: NotificationNewOld = {};
    if (notificationsData) {
      const cloneNotifications: NotificationEntity[] = cloneDeep(notificationsData) as NotificationEntity[];
      notif.oldNotifications = remove(cloneNotifications, (notif) => notif.attributes?.seen);
      notif.newNotifications = cloneNotifications;
    }
    return notif;
  }, [notificationsData]);

  const refetch = async () => {
    await fetch();
  };
  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    // Any calls to load data go here
    setRefreshing(true);
    setNotifications([]);
    await fetch();
    event.detail.complete();
    setRefreshing(false);
  };

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      await algoliaCache.invalidate();
      setRefreshDispos(true);
      await markAsSeen();
      await fetch();
      setLoading(false);
    };
    run();
  }, []);

  const seenProcess = useCallback(async () => {
    if (contentRef.current) {
      console.log('scrolltotop');
      contentRef.current.scrollToTop();
    }
    setTimeout(async () => {
      if (notifications.newNotifications?.length) {
        await algoliaCache.invalidate();
        setRefreshDispos(true);
        await markAsSeen();
        await fetch();
      }
    }, 500);
  }, [notifications, contentRef]);

  useIonViewDidEnter(async () => {
    console.log('always mark as seen');
    await seenProcess();
  });
  const showMore = async () => {
    await more();
  };

  return (
    <IonPage>
      <Header title={`Notifications`} noButtonBack={true}></Header>
      <IonContent ref={contentRef} scrollEvents={true}>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <CardCustom padding={false}>
          {loading ? (
            <div className="ion-padding">
              <CardLoader />
              <CardLoader />
              <CardLoader />
            </div>
          ) : (
            <>
              {notifications.newNotifications?.length! === 0 && (
                <>
                  <IonItem lines="none" className="ion-text-center ion-text-primary">
                    <IonLabel color="success">Vous êtes à jour</IonLabel>
                  </IonItem>
                </>
              )}
              {notifications.newNotifications?.length! > 0 && (
                <>
                  <IonItem lines="none" className="ion-text-center ion-text-primary">
                    <IonLabel className="ion-text-uppercase" color="success">
                      Nouveautés
                    </IonLabel>
                  </IonItem>
                  <span className="separator ion-margin-bottom"></span>
                </>
              )}
              {notifications.newNotifications?.map((notification, index) => {
                let type = notification.attributes?.type;
                let event = notification.attributes?.event?.data;

                if (type === 'dispo_deleted' && event) {
                  deleteEventToCalendar(event as EventEntity);
                }
                if (type === 'dispo_edited' && event) {
                  editEventToCalendar(event as EventEntity);
                }

                return <Notification key={index} notification={notification} refetch={refetch}></Notification>;
              })}
              {notifications.newNotifications?.length! > 0 && (
                <span className="separator ion-margin-top ion-margin-bottom"></span>
              )}

              {notifications.oldNotifications?.map((notification, index) => (
                <Notification key={index} notification={notification} refetch={refetch}></Notification>
              ))}
            </>
          )}
        </CardCustom>
        <IonInfiniteScroll
          disabled={paginationMeta?.page! > paginationMeta?.pageCount! || refreshing}
          onIonInfinite={async (ev) => {
            await showMore();
            setTimeout(() => ev.target.complete(), 100);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
