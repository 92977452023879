import { App } from '@capacitor/app';
import { AppLauncher } from '@capacitor/app-launcher';
import { FirebaseMessaging as FCM } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';

import { getUserData, getUserId, isAuthenticated, StorageUtils } from '../utils';
import { UPDATE_ME } from '../graphql/mutations/auth.graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { clientGraphQL } from '../graphql/apollo-client';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppStore } from '../store';
import { useNotifications } from './useNotifications';
import { useEffect } from 'react';
import { notificationRedirection } from '../constants';
import { uniq } from 'lodash';
import { deleteEventToCalendar, editEventToCalendar } from './synchroniseCalendar';
import { GET_EVENT } from '../graphql/queries/event.graphql';
import { EventEntity } from '../models/gql/graphql';

const usePushNotification = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { fetch, setNbNewMessage } = useNotifications();
  const {
    RootStore: { navigate, userData },
    MessengerStore: { msg, setRefetchMsgList, markAsSeen },
  } = useAppStore();

  const [updateMe] = useMutation(UPDATE_ME, {
    client: clientGraphQL,
  });
  const [getEvent, { loading: loadingEvent }] = useLazyQuery(GET_EVENT);

  useEffect(() => {
    const userId = getUserId()?.toString() || '';
    if (pathname.includes('/main/chat/')) {
      const participantId = pathname.split('/main/chat/').pop();
      const msgObj: any = msg[0];
      console.log(msgObj);
      if (msgObj && msgObj.participantsIds.indexOf(participantId) > -1) {
        markAsSeen({
          msgId: msgObj.id,
          seens: uniq([...msgObj.seens, userId]),
        });
      }
    }
    if (pathname.includes('/main/group/')) {
      // #TODO no comment please
    }
    const countNoSeen = msg.filter(
      (i) => Array.isArray(i?.seens) && !i?.seens.includes(userId) && i.lastSender && i.lastSender.id !== userId
    ).length;
    if (userId) {
      setNbNewMessage(countNoSeen);
    }
    setNbNewMessage(countNoSeen);
  }, [msg, pathname]);

  const init = async () => {
    // we use this to reload push notifications
    document.addEventListener(
      'reloadTedispotNotifications',
      async function (e) {
        await fetch();
      },
      false
    );
    document.addEventListener(
      'checkNewMessage',
      async function (e) {
        // incrementNewMessage();
        // setRefetchMsgList(true)
      },
      false
    );
    if (Capacitor.isNativePlatform()) {
      try {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then(async (result) => {
          if (result.receive === 'prompt') {
            result = await PushNotifications.requestPermissions();
          }
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            await PushNotifications.register();
          } else {
            // Show some error
            throw new Error('User denied permissions!');
          }
        });

        // Events Listeners
        await PushNotifications.addListener('registration', async ({ value }) => {
          const token = value; // Push token for Android
          console.log(`Push notification: We got a token ${token}`);
          // Get FCM token instead the APN one returned by Capacitor
          if (Capacitor.getPlatform() === 'ios') {
            console.log('But platform is IOS we will get the other token ');
            const { token: fcmToken } = await FCM.getToken();
            console.log('the other fcm token is', fcmToken);
            // token = fcmToken
          }
          await saveToken(token);
        });

        await PushNotifications.addListener(
          'pushNotificationReceived',
          async (notification: PushNotificationSchema) => {
            console.log(`Pusoh received: ${JSON.stringify(notification)}`);
            const type = notification?.data?.type || '';
            const event = notification?.data?.event || '';

            if ((type === 'dispo_deleted' || type === 'dispo_edited') && event) {
              const { data: eventDetail } = await getEvent({ variables: { id: event } });
              if (eventDetail?.event && eventDetail?.event.data) {
                if (type === 'dispo_deleted') {
                  deleteEventToCalendar(eventDetail.event.data as EventEntity);
                } else {
                  editEventToCalendar(eventDetail.event.data as EventEntity);
                }
              }
            }
            /*if (id === 'chat') {
            incrementNewMessage();
          }*/
            //: {"id":"0:1695651073575895%f64177cff64177cf","data":{"type":"dispo_invitation","event":"37"},"title":"Nouvelle invitation tedispo","body":"Vous êtes invité à une dispo privée:  "
            /*presentAlert({
            header: 'Notification',
            subHeader: notification.title,
            message: notification.body,
            buttons: [
              {
                text: 'Fermer',
                role: 'cancel',
                handler: () => {
                  console.log('Alert canceled');
                },
              },
              {
                text: 'Voir',
                role: 'confirm',
                handler: () => {
                  const id = notification?.data?.type;
                  let url = '/main/notifications/mine';
                  if (id && notificationRedirection[id]) {
                    url = notificationRedirection[id].replace(':id', notification?.data?.event);
                  }
                  navigate(url);
                  history.push(url);
                },
              },
            ],
          });*/
            console.log('inapp notification');
            await fetch();
          }
        );

        await PushNotifications.addListener(
          'pushNotificationActionPerformed',
          async (notification: ActionPerformed) => {
            console.log(`Push received: ${JSON.stringify(notification)}`);
            const type = notification?.notification?.data?.type || '';
            const event = notification?.notification?.data?.event || '';
            const toUser = notification?.notification?.data?.user || '';
            const post = notification?.notification?.data?.post || '';

            let profilRedirection = [
              'dispo_accepted',
              'dispo_refused',
              'follow_accepted',
              'follow_rejected',
              'new_follower',
            ];
            let dispoRedirection = [
              'dispo_invitation',
              'dispo_remind_1d',
              'dispo_remind_6h',
              'dispo_completed',
              'dispo_to_rate',
              'dispo_created',
              'dispo_edited',
              'dispo_deleted',
              'dispo_accept_waiting_participation',
              'dispo_published',
              'dispo_expired',
            ];
            let postRedirection = ['like', 'new_comment'];

            let appUrl = '';

            if (notification.actionId === 'tap' && type && notificationRedirection[type]) {
              let redirectionTemplate = notificationRedirection[type];
              appUrl = redirectionTemplate;

              if (appUrl.includes(':id')) {
                if (dispoRedirection.includes(type) && event !== '') {
                  appUrl = redirectionTemplate.replace(':id', event);
                } else if (postRedirection.includes(type) && post !== '') {
                  appUrl = redirectionTemplate.replace(':id', post);
                } else if (profilRedirection.includes(type) && toUser !== '') {
                  appUrl = redirectionTemplate.replace(':id', toUser);
                }
              }
            } else {
              appUrl = '/main/home';
            }

            if (appUrl !== '') {
              try {
                await AppLauncher.openUrl({
                  url: new URL(process.env.REACT_APP_GRAPHQL_ENDPOINT as string).hostname + appUrl,
                });
                history.push(appUrl);
              } catch (error) {
                console.error('Error opening the application:', error);
              }
            }

            /*if (id === 'chat') {
            incrementNewMessage();
          }*/
            /*presentAlert({
            header: 'Notification',
            subHeader: notification.notification?.title,
            message: notification.notification?.body,
            buttons: [
              {
                text: 'Fermer',
                role: 'cancel',
                handler: () => {
                  console.log('Alert canceled');
                },
              },
              {
                text: 'Voir',
                role: 'confirm',
                handler: () => {
                  const id = notification?.notification?.data?.type;
                  let url = '/main/notifications/mine';
                  if (id && notificationRedirection[id]) {
                    url = notificationRedirection[id].replace(':id', notification?.notification.data?.event);
                  }
                  navigate(url);
                  history.push(url);
                  /*AppLauncher.openUrl({
                    url: 'https://tedispo-staging.web.app/main/notifications/mine',
                  });
                },
              },
            ],
          });*/
          }
        );
      } catch (e) {
        console.log('Problme during initializing push notification');
        console.error(e);
      }
      // when app restored
      App.addListener('appRestoredResult', (data) => {
        console.log('Restored state:', data);
      });
    }
    // check message notif count
    if (isAuthenticated() && getUserData() && getUserId()) {
      setRefetchMsgList(true);
    }
  };

  const saveToken = async (pushToken: string) => {
    if (isAuthenticated()) {
      const userData = getUserData();
      try {
        if (!(userData && userData.pushToken && userData.pushToken === pushToken)) {
          const { data, errors } = await updateMe({ variables: { data: { pushToken } } });
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      StorageUtils.set('pushToken', pushToken);
    }
  };
  const saveLateToken = async () => {
    const pushToken = StorageUtils.get('pushToken');
    console.log('save late token', pushToken);
    if (pushToken && isAuthenticated()) {
      const userData = getUserData();
      try {
        if (!(userData && userData.pushToken && userData.pushToken === pushToken)) {
          console.log('** update profil with push token');
          const { data, errors } = await updateMe({ variables: { data: { pushToken } } });
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
  const removeListeners = async () => {
    await PushNotifications.removeAllListeners();
  };

  const exit = () => {
    console.log('app exited...');
    PushNotifications.removeAllListeners();
    App.removeAllListeners();
  };

  return {
    init,
    exit,
    saveToken,
    saveLateToken,
    removeListeners,
  };
};

export default usePushNotification;
