import { useContext } from 'react';
import { AppReducerContext } from '../store/';
import { EventInput, EventEntity, EventFiltersInput } from '../models/gql/graphql';
import { useQuery } from '@apollo/client';
import { GET_EVENTS } from '../graphql/queries/events.graphql';
import useMessengerStore from '../store/AppMemoProvider/messenger.store';
import { Enum_segment_home } from '../store/AppReducerProvider/type';

export const useDispo = () => {
  const {
    state: { dispo, imageUrl, imageSelecteds, dispoView, canceledEvent, redirectHome, reloadHome },
    dispatch,
  } = useContext(AppReducerContext);

  const { createChat } = useMessengerStore(true);

  const setDispo = (data: EventInput) => {
    dispatch({ type: 'SET_DISPO', payload: data });
  };

  const setImageUrl = (data: string) => {
    dispatch({ type: 'SET_IMAGE_URL', payload: data });
  };

  const setImageSelecteds = (data: any) => {
    dispatch({ type: 'SET_IMAGE_SELECTEDS', payload: data });
  };

  const setDispoView = (data: EventEntity) => {
    dispatch({ type: 'SET_DISPO_VIEW', payload: data });
  };

  const useEventFiltered = (filters: EventFiltersInput, pageLimit: number = 10) => {
    const { data, loading, refetch } = useQuery(GET_EVENTS, {
      variables: { filters, sort: ['eventDate:desc'], pagination: { limit: pageLimit } },
    });
    return { dataEventFiltered: data, loading, refetch };
  };

  const createDispoChat = (data: any) => {
    createChat(data);
  };

  const setCanceledEvent = (data: boolean) => {
    dispatch({ type: 'SET_CANCELED_EVENT', payload: data });
  };

  const setRedirectHome = (data: Enum_segment_home) => {
    dispatch({ type: 'SET_REDIRECT_HOME', payload: data });
  };
  const setReloadHome = (data: boolean) => {
    dispatch({ type: 'SET_RELOAD_HOME', payload: data });
  };

  return {
    dispo,
    setDispo,
    imageUrl,
    setImageUrl,
    imageSelecteds,
    setImageSelecteds,
    dispoView,
    setDispoView,
    useEventFiltered,
    createDispoChat,
    canceledEvent,
    setCanceledEvent,
    redirectHome,
    setRedirectHome,
    reloadHome,
    setReloadHome,
  };
};
