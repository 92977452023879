import { graphql } from '../../models/gql';
export const GET_EVENT_USER_PARTICIPATIONS = graphql(/* GraphQL */ `
  query eventUserParticipations(
    $filters: EventUserParticipationFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    eventUserParticipations(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          status
          note
          qrCode
          user {
            data {
              id
              attributes {
                username
                isPro
                companyName
                firstname
                lastname
                profilePicture {
                  data {
                    id
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          attributes {
                            name
                            url
                            mime
                            hash
                            size
                            provider
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          event {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`);
