import { IonButton, IonGrid, IonRow } from '@ionic/react';
import { addDays } from 'date-fns/esm';
import { timeOutline } from 'ionicons/icons';
import { FormProvider, useForm } from 'react-hook-form';
import DoubleDatePicker from '../../../components/DoubleDatePicker';
import FilterHeader from '../../../components/FilterHeader';
import { convertDateToSimple } from '../../../utils';

const FilterDate: React.FC<IFilterProps> = ({ onChange, onCloseModal }) => {
  const methods = useForm<TypeBetweenDate>();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;
  register('startDate', { required: 'La date de debut est requise' });
  register('endDate', { required: 'La date de fin est requise' });

  const saveFilter = (data: TypeBetweenDate) => {
    const arrayDate = [
      convertDateToSimple(new Date(data.startDate)),
      convertDateToSimple(addDays(new Date(data.endDate), 1)),
    ];
    onChange(arrayDate);
    onCloseModal(false);
  };

  return (
    <FormProvider {...methods}>
      <FilterHeader title="Filtrer par date" close={() => onCloseModal(false)} />
      <IonGrid className="ion-no-padding ion-margin">
        <form onSubmit={handleSubmit(saveFilter)}>
          <IonRow className="font-outfit">Afficher les dispos entre les dates suivantes :</IonRow>
          <DoubleDatePicker
            onChange1={(value) => {
              setValue('startDate', value.detail.value);
            }}
            onChange2={(value) => {
              setValue('endDate', value.detail.value);
            }}
            errors={errors}
            label1="Début"
            label2="Fin"
            icon={timeOutline}
          />
          <IonButton
            expand="block"
            fill="solid"
            color="primary"
            className="d-absolute-bottom ion-padding-horizontal"
            type="submit"
          >
            Sauvegarder
          </IonButton>
          <IonButton
            expand="block"
            fill="clear"
            className="d-absolute-bottom ion-padding-horizontal text-underligne text-black"
            onClick={() => onCloseModal(false)}
          >
            Annuler
          </IonButton>
        </form>
      </IonGrid>
    </FormProvider>
  );
};

export default FilterDate;
