import { useMutation, useQuery } from '@apollo/client';
import { Contacts, PermissionStatus } from '@capacitor-community/contacts';
import { Capacitor } from '@capacitor/core';
import { SplashScreen as SP } from '@capacitor/splash-screen';
import { IonButton, IonChip, IonCol, IonContent, IonGrid, IonPage, IonRow, IonToast } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import logoSvg from '../../../assets/icons/logo.svg';
import warningSvg from '../../../assets/icons/warning.svg';
import { MenuList } from '../../../components';
import { ConfirmModal } from '../../../components/Modals';
import SimpleHeader from '../../../components/SimpleHeader';
import { UPDATE_EVENT } from '../../../graphql/mutations/update-event.graphql';
import { DELETE_USER, UPDATE_USER } from '../../../graphql/mutations/updateUser..graphql';
import { GET_EVENTS } from '../../../graphql/queries/events.graphql';
import { EventEntity, UsersPermissionsMe } from '../../../models/gql/graphql';
import { StorageUtils, getUserId } from '../../../utils';
import { useUserData } from '../../../utils/auth';
import { ConfidentialityContent } from '../Confidentiality/Confidentiality';
import '../style.css';
import HeaderCompte from './HeaderCompte';
import { __ } from '../../../utils/traduction';

const Menu: React.FC = () => {
  const history = useHistory();
  const [confidentialityModal, setConfidentialityModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateUser, { loading: loadingUser }] = useMutation(UPDATE_USER);
  const [showPauseSuccessMessage, setPauseSuccessMessage] = useState(false);
  const [saveEvent, { loading: loadingUpdateEvent }] = useMutation(UPDATE_EVENT);
  const [deleteUser, { loading: loadingDeleteUser }] = useMutation(DELETE_USER);
  const userId = getUserId();
  const [messageError, setMessageError] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // refteching after edit pdp
  const { userData: userConnected } = useUserData();

  const logout = async () => {
    setShowLogoutConfirm(false);
    await StorageUtils.clear();
    SP.hide();
    history.replace('/on-boarding');
  };

  const goTo = (path: string) => {
    history.push(path);
  };
  const listItems: IlistItem[] = [
    { label: 'Mes informations personnelles', path: '/main/my-account/menu/infos', showIcon: true },
    { label: "Mes centres d'intérêts", path: '/main/my-account/menu/interest', showIcon: true },
    { label: 'Confidentialité de mon feed', path: '', showIcon: true },
    //{ label: 'Informations bancaires', path: '', showIcon: true },
    { label: 'Retrouver mes contacts', path: '/main/my-account/menu/contact', showIcon: true },
    { label: 'Utilisateurs bloqués', path: '/main/my-account/menu/user-bloqued', showIcon: true },
    { label: 'Désactiver mon compte', path: '', showIcon: false },
    { label: 'Contactez-nous', path: '', showIcon: false },
    { label: 'Supprimer mon compte', path: '', showIcon: false },
  ];

  const openPage = async (item: IlistItem) => {
    if (item.label === 'Confidentialité de mon feed') {
      setConfidentialityModal(true);
    } else if (item.label === 'Désactiver mon compte') {
      setShowConfirmModal(true);
    } else if (item.label === 'Supprimer mon compte') {
      setShowDeleteModal(true);
    } else if (item.label === 'Contactez-nous') {
      window.location.href = 'mailto:support@tedispo.com';
    } else if (item.label === 'Retrouver mes contacts') {
      if (Capacitor.isNativePlatform()) {
        let permissionState: PermissionStatus = await Contacts.requestPermissions();
        if (permissionState.contacts !== 'granted') {
          setIsOpen(true);
          setMessageError("Pour accéder aux contacts, veuillez autoriser l'accès dans les paramètres de l'application");
        } else if (permissionState.contacts === 'granted') {
          goTo(item.path);
        }
      } else {
        goTo(item.path);
      }
    } else {
      goTo(item.path);
    }
  };

  const { data: myDispoData, loading: loadingMyDispo } = useQuery(GET_EVENTS, {
    variables: {
      filters: {
        creator: {
          id: {
            eq: userId,
          },
        },
      },
      sort: ['eventDate:desc'],
    },
  });

  const myEvents: EventEntity[] = useMemo(() => {
    return !loadingMyDispo && myDispoData ? (myDispoData?.events?.data as EventEntity[]) : [];
  }, [loadingMyDispo, myDispoData]);

  const deleteAccount = async () => {
    setShowDeleteModal(false);

    try {
      const { data: dataUser } = await deleteUser({
        variables: {
          id: userId ? userId.toString() : '0',
        },
      });
      logout();
    } catch (e) {
      console.log(e);
    }
  };

  const desactivateAccount = async () => {
    setShowConfirmModal(false);

    try {
      //Mark deleted all user event
      if (myEvents && myEvents.length) {
        myEvents.map(async (event) => {
          const { data: dataEvent } = await saveEvent({
            variables: {
              id: event.id ? event.id.toString() : '0',
              data: {
                publishedAt: null,
              },
            },
          });
        });
      }

      //Mark user deleted and Replace email of user deleted
      const { data: dataUser } = await updateUser({
        variables: {
          id: userId ? userId.toString() : '0',
          data: { publishedAt: null },
        },
      });

      history.push('/on-boarding');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <SimpleHeader title="Mon compte" noButtonBack={true} showMenuButton={false}></SimpleHeader>
        <IonGrid className="ion-justify-content-center ion-align-items-center width-p-95 bg-white border-radius">
          <IonRow className="ion-justify-content-center font-outfit">
            <IonCol size="9" className="flex-vertical-center">
              <HeaderCompte userConnected={userConnected as UsersPermissionsMe}></HeaderCompte>
              {userConnected?.isPro && (
                <IonChip className="bg-blue text-white" color="white">
                  Utilisateur professionnel
                </IonChip>
              )}
            </IonCol>
          </IonRow>
          <MenuList
            listItems={listItems}
            icon={arrowForwardOutline}
            hasBorderBottom={true}
            action={(item: IlistItem) => openPage(item)}
          />
          <IonButton
            expand="block"
            fill="outline"
            color="primary"
            onClick={(e: any) => setShowLogoutConfirm(true)}
            className="margin-t-20"
          >
            Deconnexion
          </IonButton>
        </IonGrid>
      </IonContent>
      <ConfidentialityContent close={() => setConfidentialityModal(false)} isOpen={confidentialityModal} />
      <ConfirmModal
        isOpen={showConfirmModal}
        title="Es-tu sûr de vouloir mettre en pause ton compte?"
        text="Pensez à mettre à jour ton numéro de téléphone dans les paramètres de ton profil si tu en changes d'ici la réactivation."
        okText="Confirmer"
        cancelText="Annuler"
        iconPerso={warningSvg}
        onConfirm={() => {
          desactivateAccount();
        }}
        onCancel={() => setShowConfirmModal(false)}
        height="450px"
      />
      <ConfirmModal
        isOpen={showDeleteModal}
        title="Es-tu sûr de vouloir supprimer ton compte?"
        text="Cette action est définitive."
        okText="Confirmer"
        cancelText="Annuler"
        iconPerso={warningSvg}
        onConfirm={() => {
          deleteAccount();
        }}
        onCancel={() => setShowDeleteModal(false)}
        height="400px"
      />
      <ConfirmModal
        isOpen={showPauseSuccessMessage}
        title="Vous avez mis en pause votre compte"
        text="Pour utiliser de nouveau l'application Tedispo, vous devez réactiver votre compte. Souhaitez-vous le réactiver?."
        okText="Je réactive mon compte"
        iconLogo={logoSvg}
        onConfirm={() => console.log('TO DO')}
        onCancel={() => setPauseSuccessMessage(false)}
        height="380px"
      />
      <ConfirmModal
        isOpen={showLogoutConfirm}
        title="Déconnexion"
        text="Voulez-vous vraiment vous déconnecter de l'application?."
        okText="Oui, je me deconnecte"
        iconLogo={logoSvg}
        onConfirm={logout}
        onCancel={() => setShowLogoutConfirm(false)}
        height="380px"
      />
      <IonToast
        isOpen={isOpen}
        message={__(messageError)}
        onDidDismiss={() => setIsOpen(false)}
        duration={5000}
        color="danger"
      ></IonToast>
    </IonPage>
  );
};

export default Menu;
