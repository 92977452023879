import { IonCard, IonIcon, IonRow, IonText } from '@ionic/react';
import lockSvg from '../../../assets/icons/lock.svg';
import '../style.css';

const ProfilLock: React.FC = () => {
  return (
    <>
      <IonCard className="full-width ion-no-margin ion-padding box-blur">
        <IonRow className="ion-justify-content-center text-center ">
          <IonIcon size="large" icon={lockSvg} />
          <IonText>Tu n'as pas accès aux publications de cet utilisateur car son profil est privé.</IonText>
        </IonRow>
      </IonCard>
    </>
  );
};

export default ProfilLock;
