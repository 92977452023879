import { useMutation, useQuery } from '@apollo/client';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import { useMemo, useState } from 'react';
import userBlockedSvg from '../../../assets/icons/userBlocked.svg';
import warningSvg from '../../../assets/icons/warning.svg';
import { Header } from '../../../components';
import { ConfirmModal } from '../../../components/Modals';
import { UNBLOCKED_USER } from '../../../graphql/mutations/updateUser..graphql';
import { GET_USERS } from '../../../graphql/queries/getUsers.graphql';
import { GET_ME } from '../../../graphql/queries/user.graphql';
import { UsersPermissionsUserEntity } from '../../../models/gql/graphql';
import { NotificationSection } from '../../DispoDetail/components/NotificationSection';

const UserBloqued: React.FC = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [actualItem, setActualItem] = useState<UsersPermissionsUserEntity>();
  const [unblockedUser, { loading: loadingUnblocked }] = useMutation(UNBLOCKED_USER);

  const debloqueUser = (item: UsersPermissionsUserEntity) => {
    setActualItem(item);
    setShowConfirmModal(true);
  };
  const { data: dataMe, loading: loadingMe, refetch } = useQuery(GET_ME);
  const {
    data,
    loading: loadingUser,
    refetch: refetchBlocked,
  } = useQuery(GET_USERS, {
    variables: {
      filters: {
        id: {
          in:
            dataMe?.me?.blockedUsers && Array.isArray(dataMe?.me?.blockedUsers) && dataMe?.me?.blockedUsers.length
              ? dataMe?.me?.blockedUsers.map((i) => i?.id?.toString()!)
              : ['0'],
        },
      },
      pagination: { limit: 100 },
    },
  });

  let listUserBlocked = useMemo(() => {
    return (!loadingUser && data ? data.usersPermissionsUsers?.data : []) as UsersPermissionsUserEntity[];
  }, [data, loadingUser]);

  const getUserName = (item?: UsersPermissionsUserEntity) => {
    return (item?.attributes?.isPro ? item.attributes?.companyName : item?.attributes?.username) || '';
  };

  const handleUnblockedUser = () => {
    unblockedUser({
      variables: {
        id: actualItem && actualItem.id ? actualItem.id : '0',
      },
    });
    setShowConfirmModal(false);
    refetch();
    refetchBlocked();
  };

  return (
    <IonPage>
      <Header title="Utilisateurs bloqués"></Header>
      <IonContent className="ion-padding">
        <IonGrid className="ion-justify-content-center ion-align-items-center width-p-95 min-height-200 ion-padding-bottom bg-white border-radius height-auto">
          <IonRow className="ion-justify-content-center ion-align-items-center ">
            <IonText className="ion-padding font-outfit">Voici la liste des utilisateurs que tu as bloqués.</IonText>
            <NotificationSection
              message="Clique sur l'un d'entre eux pour  le débloquer."
              textColor="black"
              icon={userBlockedSvg}
              bgColor="#FFF1CC"
              paddingLeft="0"
            />
          </IonRow>
          <IonList lines="none">
            {listUserBlocked.map((item, index) => (
              <IonItem key={index} button={true} onClick={() => debloqueUser(item)}>
                <IonLabel>
                  <IonGrid>
                    <IonRow>
                      <IonCol className="text-gray-400 font-outfit text-capitalize">{getUserName(item)}</IonCol>
                      <IonCol className="blue-icon margin-r-5" size="1">
                        <IonIcon icon={arrowForwardOutline}></IonIcon>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonGrid>
      </IonContent>
      <ConfirmModal
        isOpen={showConfirmModal}
        title={`Es-tu sûr de vouloir débloquer ${getUserName(actualItem)} ?`}
        text="Cette utilisateur pourra à nouveau consulter ton compte et te contacter."
        okText="Confirmer"
        cancelText="Annuler"
        iconPerso={warningSvg}
        onConfirm={() => handleUnblockedUser()}
        onCancel={() => setShowConfirmModal(false)}
        height="420px"
      />
    </IonPage>
  );
};

export default UserBloqued;
