import { initializeApp } from 'firebase/app';
import { getFirestore, initializeFirestore } from 'firebase/firestore';

// set config here
const config = {
  authRedirectUri: `${process.env.REACT_APP_FRONTEND_URI}/connect/{provider}/redirect/{context}`,
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FRB_APIKEY, // "AIzaSyDPJjRNAvLUjMaTS7-GmBJq7iYQ2YHcycI",
    // authDomain: process.env.REACT_APP_FRB_AUTH_DOMAIN, // "batrecup-staging.firebaseapp.com",
    projectId: process.env.REACT_APP_FRB_PROJECT_ID, // "batrecup-staging",
    storageBucket: process.env.REACT_APP_FRB_STORAGE_BUCKET, // "batrecup-staging.appspot.com",
    databaseUrl: process.env.REACT_APP_FRB_DATABASE_URL, // http://batrecup-staging.firebaseio.com
    // messagingSenderId: process.env.REACT_APP_FRB_APIKEY, // "966170650373",
    appId: process.env.REACT_APP_FRB_APP_ID, // "1:966170650373:web:41ec1cf13a50071bddb96c",
    // measurementId: process.env.REACT_APP_FRB_APIKEY, // "G-QMLJE416DV"
  },
};

const app = initializeApp(config.firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = initializeFirestore(
  app,
  {
    experimentalForceLongPolling: true,
    ignoreUndefinedProperties: true,
  },
  process.env.REACT_APP_FRB_DATABASE_ID || undefined
);
