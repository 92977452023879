import { FC } from 'react';
import './style.css';
import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import downloadIcon from '../../../../assets/icons/download.svg';
import { Browser } from '@capacitor/browser';

interface IProps {
  media: any;
}

const PdfPreview: FC<IProps> = ({ media }) => {
  const openPdfInApp = async () => {
    await Browser.open({
      url: media?.url,
      windowName: '_self',
      presentationStyle: 'popover',
    });
  };

  const downloadPdf = async (e: any) => {
    e.stopPropagation();
    await Browser.open({
      url: media?.url,
    });
  };

  const fileSize = media?.size ? Math.floor(+media?.size / 1024) : 0;

  return (
    <div className="chat__details__pdf-preview" onClick={openPdfInApp}>
      <div className="chat__details__pdf-preview__info-container">
        <div className="chat__details__pdf-preview__info-container__title">{media?.name}</div>
        <IonButton onClick={downloadPdf} fill="clear" className="ion-no-padding">
          <IonIcon size="medium" color="light" className="icon-media" icon={downloadIcon} />
        </IonButton>
      </div>
      {fileSize ? <div className="chat__details__pdf-preview__meta">PDF - {fileSize} Ko.</div> : null}
    </div>
  );
};

export default PdfPreview;
