import { graphql } from '../../models/gql';
export const GET_COMMENTS = graphql(/* GraphQL */ `
  query comments($filters: CommentFiltersInput, $pagination: PaginationArg) {
    comments(filters: $filters, pagination: $pagination) {
      data {
        id
        attributes {
          content
          publishedAt
          subcomments {
            data {
              id
              attributes {
                content
                publishedAt
                deleted
                author {
                  data {
                    id
                    attributes {
                      username
                      firstname
                      lastname
                      isPro
                      companyName
                      profilePicture {
                        data {
                          attributes {
                            name
                            url
                            mime
                            hash
                            size
                            provider
                          }
                        }
                      }
                      avatar {
                        data {
                          id
                          attributes {
                            image {
                              data {
                                attributes {
                                  name
                                  url
                                  mime
                                  hash
                                  size
                                  provider
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          author {
            data {
              id
              attributes {
                username
                firstname
                lastname
                isPro
                companyName
                profilePicture {
                  data {
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          attributes {
                            name
                            url
                            mime
                            hash
                            size
                            provider
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);
