import { IonContent, IonPage, IonButton, IonFooter, IonText, useIonToast } from '@ionic/react';
import { Header, InputCustom, RichEditor, StepperCount, CardCustom, SegmentCustom } from '../../../components';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { EventInput, Enum_Event_Paymenttype, EventEntity } from '../../../models/gql/graphql';
import 'react-phone-number-input/style.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispo } from '../../../hooks';
import '../style.css';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { GET_EVENT } from '../../../graphql/queries/event.graphql';
import { CREATE_STRIPE_ACCOUNT, GET_STRIPE_ACCOUNT } from '../../../graphql/queries/stripe.graphql';
import { useAppStore } from '../../../store';
import { differenceInYears } from 'date-fns';
import { useStripeOnBoarding } from '../../../hooks/useStripeOnBoarding';
import { Browser } from '@capacitor/browser';
import { GET_ME } from '../../../graphql/queries/user.graphql';

const StepTarif: React.FC<IStepProps> = ({ id, duplicate }) => {
  const history = useHistory();
  const { data: userData, refetch: refetchUser } = useQuery(GET_ME, {
    fetchPolicy: 'no-cache',
  });
  const { search } = useLocation();
  const methods = useForm<EventInput>();
  const formRef = useRef<any>();
  const { setValue, handleSubmit, reset } = methods;
  const [type, setType] = useState<Enum_Event_Paymenttype>(Enum_Event_Paymenttype.Gratuite);
  const [price, setPrice] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [moneyToRaise, setMoneyToRaise] = useState<number>();
  const [paymentMessage, setPaymentMessage] = useState<string>('');
  const { dispo, setDispo } = useDispo();
  const [success, setSuccess] = useState(false);
  const [presentToast, dismissToast] = useIonToast();
  const {
    RootStore: { getUserData },
  } = useAppStore();

  const { data: dispoData, loading: loadingEvent } = useQuery(GET_EVENT, {
    variables: { id: id ? id : '0' },
  });
  const {
    data: dataStripe,
    error: stripeError,
    refetch,
  } = useQuery(GET_STRIPE_ACCOUNT, {
    fetchPolicy: 'no-cache',
  });
  const [createStripeAccount] = useMutation(CREATE_STRIPE_ACCOUNT);
  const { openBrowser, navigationDone, closeBrowser } = useStripeOnBoarding();
  /*var watch = async function () {
    const user = await getUserData(true);
    if (user?.stripeAccount && user?.stripeCapabilitiesStatus === 'active' ) {//
      closeBrowser()
    } else {
      console.log('nonono');
      setTimeout(async () => {
        await watch();
      }, 4000);
    }
  };*/
  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.get('stripeAccount') === '0') {
      console.log('ERROR STRIPE ACCOUNT');
    } else if (query.get('stripeAccount') === '1') {
      console.log('SUCCESS STRIPE ACCOUNT');
      setSuccess(true);
      formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true }));
    }
  }, [search, formRef, setSuccess]);

  useEffect(() => {
    const check = async () => {
      const { data } = await refetchUser();
      console.log('got user in check', data);
      if (data?.me?.stripeAccount && data?.me?.stripeCapabilitiesStatus === 'active') {
        // next step
        console.log('next step');
        formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true }));
        console.log('after dispatching');
        history.push('/stripe/account/done');
      }
    };
    if (navigationDone) {
      // submit
      console.log('Browser closed...');
      check();
    }
  }, [navigationDone]);

  const dispoDefault = useMemo(() => {
    return !loadingEvent ? dispoData?.event?.data : ([] as EventEntity);
  }, [loadingEvent, dispoData]);

  //HandleChange function into form
  const onSubmit = async (data: any) => {
    console.log('on submit fired');
    setDispo({
      ...dispo,
      ...data,
      paymentMessage,
      paymentType: type,
      price: parseFloat(data?.price),
      moneyToRaise: parseFloat(data?.moneyToRaise),
    });
    if (type !== Enum_Event_Paymenttype.Gratuite && !success) {
      try {
        setLoading(true);
        const { data: dts } = await refetch();

        // check if stripe does not enough capability
        if (
          !dts?.RetrieveMyStripeAccount ||
          !dts?.RetrieveMyStripeAccount?.data ||
          !dts?.RetrieveMyStripeAccount?.data.capabilities
        ) {
          presentToast({
            duration: 10000,
            message: "Vous n'avez pas terminé la configuration de votre système de paiement",
          });
          //await watch();
          await openBrowser();
          return;
        }
        if (
          dts?.RetrieveMyStripeAccount?.data.capabilities.card_payments !== 'active' ||
          dts?.RetrieveMyStripeAccount?.data.capabilities.transfers !== 'active'
        ) {
          presentToast({
            duration: 10000,
            message: 'Vous devez continuer la configuration de votre comppte de paiement',
          });

          // await watch();
          await openBrowser();
          return;
        }
        setLoading(false);
      } catch (e: any) {
        console.log(e, e.message);
        if (e && e.message === 'User dont have stripe account yet') {
          await createAccount();
          return;
        }
        setLoading(false);
      }
    }
    if (!duplicate) {
      resetData();
      history.push('/main/home/create-dispo/step-options');
    } else {
      history.push({
        pathname: `/main/dispos/${id}/duplicate`,
        search: `?${new URLSearchParams({ page: 'step-options' }).toString()}`,
      });
    }
  };

  const segmentData: IdNameEntity[] = [
    {
      id: Enum_Event_Paymenttype.Gratuite,
      name: Enum_Event_Paymenttype.Gratuite,
    },
    {
      id: Enum_Event_Paymenttype.Payante,
      name: Enum_Event_Paymenttype.Payante,
    },
    {
      id: Enum_Event_Paymenttype.Contributive,
      name: Enum_Event_Paymenttype.Contributive,
    },
  ];

  const handlePriceChange = (value: any) => {
    setPrice(value.detail ? value.detail.value : value);
    setValue('price', value.detail ? value.detail.value : value);
  };

  const handleSetPaymentMessage = (value: string) => {
    setPaymentMessage(value);
    setValue('paymentMessage', value);
  };

  const handleSetPaymentType = (value: IdNameEntity) => {
    setType(value.id as Enum_Event_Paymenttype);
    setLoading(false);
    setValue('paymentType', value.id as Enum_Event_Paymenttype);
  };

  useEffect(() => {
    if (dispoDefault?.attributes?.paymentType) {
      setType(dispoDefault.attributes.paymentType);
    }
  }, [dispoDefault?.attributes?.paymentType]);

  const handleMoneyRiseChange = (value: any) => {
    setMoneyToRaise(value.detail ? value.detail.value : value);
    setValue('moneyToRaise', value.detail ? value.detail.value : value);
  };

  const resetData = () => {
    reset({
      moneyToRaise: undefined,
      price: undefined,
      paymentMessage: '',
      paymentType: Enum_Event_Paymenttype.Gratuite,
    });
    setPaymentMessage('');
    setPrice(undefined);
    setMoneyToRaise(undefined);
    setType(Enum_Event_Paymenttype.Gratuite);
  };
  const createAccount = async () => {
    const userData = await getUserData(true);
    let err;
    if (!userData.isPro) {
      if (!userData.birthDate || !userData.firstname || !userData.lastname) {
        err = 'Veuillez remplir vos informations personnelles';
      }
      const diff = differenceInYears(new Date(userData.birthDate), new Date());

      if (diff > -18) {
        err = 'Vous devez être majeur pour pouvoir continuer';
      }
    }
    if (err) {
      presentToast({
        duration: 10000,
        message: err,
      });
      setLoading(false);
      return;
    }
    console.log('userData0', userData);
    if (userData?.stripeAccount) {
      // account active
      if (userData?.stripeCapabilitiesStatus === 'active') {
        //
        // next step
        formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true }));
        //console.log('after dispatching');
        return;
      }
      // account inactive => not configured
      if (userData?.stripeCapabilitiesStatus === 'inactive') {
        await openBrowser();
        return;
      }
      // account pending => not configured???
      if (userData?.stripeCapabilitiesStatus === 'pending') {
        await openBrowser();
        return;
      }
    } else {
      // create account then configure
      try {
        presentToast({
          duration: 10000,
          message: 'Vous allez être rediriger dans la configuration de votre',
        });
        setLoading(true);
        const stripeAccountData = await createStripeAccount();
        if (stripeAccountData?.data?.CreateStripeAccount?.data) {
          // create link
          //await watch()
          await openBrowser();
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef} noValidate>
        <IonPage>
          <Header title={duplicate ? 'Duplication de ta dispo' : "Création d'une dispo"} noButtonBack={false} />
          <StepperCount length={5} numberStep={4}></StepperCount>

          <IonContent>
            <CardCustom margin={true}>
              <IonText color="dark" className="font-outfit">
                <h3>Tarif</h3>
                <p>
                  Tu peux rendre ta dispo payante ou gratuite, mais aussi proposer aux participants de contribuer
                  librement à une cagnotte pour que la dispo puisse avoir lieu !
                </p>
              </IonText>

              <p className="custom-label font-outfit">Tu souhaites que ta dispo soit...</p>
              <SegmentCustom
                values={segmentData}
                defaultValue={type || dispoDefault?.attributes?.paymentType}
                onChange={(value) => handleSetPaymentType(value)}
                typeSegment="full"
              ></SegmentCustom>
              {type === Enum_Event_Paymenttype.Contributive && (
                <>
                  <InputCustom
                    handleChange={handleMoneyRiseChange}
                    label="Montant à atteindre"
                    placeholder="10€"
                    attr="moneyToRaise"
                    type="number"
                    inputmode="decimal"
                    value={moneyToRaise || dispoDefault?.attributes?.moneyToRaise}
                  />
                  <RichEditor
                    handleChange={(value: string) => handleSetPaymentMessage(value)}
                    value={paymentMessage || dispoDefault?.attributes?.paymentMessage}
                    label="Message"
                    description="Ce texte sera présenté aux personnes souhaitant s’inscrire pour leur expliquer à quoi servira leur contribution."
                  />
                </>
              )}
              {type === Enum_Event_Paymenttype.Payante && (
                <InputCustom
                  handleChange={handlePriceChange}
                  label="Tarif par participant"
                  placeholder="10€"
                  attr="price"
                  type="number"
                  inputmode="decimal"
                  value={price || dispoDefault?.attributes?.price}
                />
              )}
            </CardCustom>
          </IonContent>
          <IonFooter className="ion-no-border ion-padding">
            <IonButton disabled={loading} type="submit" className="" expand="block" color="primary">
              Continuer
            </IonButton>
          </IonFooter>
        </IonPage>
      </form>
    </FormProvider>
  );
};

export default StepTarif;
