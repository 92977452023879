import { graphql } from '../../models/gql';

export const CREATE_COMMENT = graphql(/* GraphQL */ `
  mutation createComment($data: CommentInput!) {
    createComment(data: $data) {
      data {
        id
        attributes {
          content
        }
      }
    }
  }
`);
export const CREATE_SUB_COMMENT = graphql(/* GraphQL */ `
  mutation createSubComment($data: SubcommentInput!) {
    createSubcomment(data: $data) {
      data {
        id
        attributes {
          content
        }
      }
    }
  }
`);

export const DELETE_COMMENT = graphql(/* GraphQL */ `
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id) {
      data {
        id
      }
    }
  }
`);

export const DELETE_SUBCOMMENT = graphql(/* GraphQL */ `
  mutation deleteSubcomment($id: ID!) {
    deleteSubcomment(id: $id) {
      data {
        id
      }
    }
  }
`);
