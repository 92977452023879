import { graphql } from '../../models/gql';
export const GET_EVENT = graphql(/* GraphQL */ `
  query event($id: ID!) {
    event(id: $id) {
      data {
        id
        attributes {
          name
          type
          eventDate
          eventEndDate
          useChat
          useQRCode
          allowUserToShare
          moneyRaised
          moneyToRaise
          maxInvited
          paymentType
          price
          paymentMessage
          addressLine
          addressCity
          addressZipcode
          addressCountry
          addressLat
          addressLng
          description
          createdAt
          updatedAt
          publishedAt
          startHour
          endHour
          expired
          pending
          beginningDateTime
          expirationDateTime
          deleted
          beAccompanied
          participations {
            data {
              id
            }
          }
          interests {
            data {
              id
            }
          }
          secondaryImages {
            data {
              id
              attributes {
                url
                mime
                hash
                size
                previewUrl
              }
            }
          }
          creator {
            data {
              id
              attributes {
                username
                firstname
                lastname
                stripeAccount
                isPro
                companyName
                stripeCapabilitiesStatus
                profilePicture {
                  data {
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          attributes {
                            name
                            url
                            mime
                            hash
                            size
                            provider
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          coverCustomImage {
            data {
              id
              attributes {
                name
                url
                mime
                hash
                size
                provider
              }
            }
          }
          coverTedispoImage {
            data {
              id
              attributes {
                image {
                  data {
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);
