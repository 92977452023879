import { IonCard, IonCardContent, IonRow, IonText } from '@ionic/react';
import { SliderMedia } from '../../../components';

export const EventDescription: React.FC<IDispoData> = ({ dispoData }) => {
  const { description, allUrlImage } = dispoData;

  return (
    <IonRow className="bg-white">
      <IonRow className="margin-auto width-90">
        <IonCard className="padding-10 border-radius-20 box-shadow-custom">
          <IonCardContent>
            <IonRow>
              <IonText className="text-bold text-gray-200 font-outfit">A propos</IonText>
            </IonRow>
            <div className="text-gray-400 font-outfit full">
              <div>{description}</div>
            </div>
            <IonRow className="margin-t-20 border-radius">
              <IonCard className="ion-no-margin full card-no-border">
                <IonCardContent className="ion-no-padding">
                  {!!(allUrlImage && allUrlImage.length) && <SliderMedia items={allUrlImage}></SliderMedia>}
                </IonCardContent>
              </IonCard>
            </IonRow>
          </IonCardContent>
        </IonCard>
      </IonRow>
    </IonRow>
  );
};
