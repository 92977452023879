import { Browser } from '@capacitor/browser';
import { IonAvatar, IonButton, IonIcon, IonImg, IonModal, isPlatform } from '@ionic/react';
import { doc, getDoc } from 'firebase/firestore';
import { arrowBackCircleOutline, arrowForwardCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import greenResponIcon from '../../assets/icons/greenRespond.svg';
import { db } from '../../config';
import useLongPress from '../../hooks/usePressLong';
import { formatDateWithCustomLogic } from '../../utils/dateFormat';
import VideoView from '../VideoView';
import MediaGallery from './MediaGallery';
import AudioWaveAndroid from './MediaGallery/AudioWaveAndroid';
import AudioWaveForm from './MediaGallery/AudioWaveForm';
import PdfViewer from './PdfViewer';
import './style.css';
import playIcon from '../../assets/icons/playIcon.svg';

interface IMessageItem {
  item: any;
  showTime: boolean;
  showAvatar: boolean;
  participants: any;
  currentUser: any;
  handleOpenActionModal: any;
  searchKey: string;
  id: string;
  isHighlighted: boolean;
}

const MessageItem: React.FC<IMessageItem> = forwardRef((props, ref) => {
  const { item, showTime, showAvatar, participants, currentUser, handleOpenActionModal, searchKey, id, isHighlighted } =
    props;
  const [parentMessage, setParentMessage] = useState<any>(undefined);
  const [swiperRef, setSwiperRef] = useState<any>(null);

  const [openFileViewer, setOpenFileViewer] = useState(false);
  const [numberSwiper, setNumberSwiper] = useState<number>(0);

  const isAndroid = isPlatform('android');

  const initSwiper = (swiper: any) => {
    setSwiperRef(swiper);
  };

  const scrollRef = useRef<any>();

  const history = useHistory();

  const [longPressTimeout, setLongPressTimeout] = useState<number>();

  const mouseDown = () => {
    const timeout = setTimeout(onLongPress, 500);
    setLongPressTimeout(timeout);
  };

  const mouseUp = () => {
    clearTimeout(longPressTimeout);
  };

  const touchStart = () => {
    const timeout = setTimeout(onLongPress, 500);
    setLongPressTimeout(timeout);
  };

  const touchEnd = () => {
    clearTimeout(longPressTimeout);
  };

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);

  const goToProfil = (): void => {
    history.push(`/main/profil/${peer.id}`);
  };
  // check if current user is the sender
  const isFromMySelf = item.fromId === currentUser?.id;
  const cls = isFromMySelf ? 'chat__item--right' : '';
  const peer = participants.find((p: any) => item?.fromId === p?.id);

  const photoUrl =
    peer?.attributes?.profilePicture?.data?.attributes?.url ||
    peer?.attributes?.avatar?.data?.attributes?.image?.data?.attributes?.url ||
    peer?.photoUrl;

  const isDeletedByMe = (item?.deleted && item?.deletedBy === currentUser?.id) || false;

  const onLongPress = (event: React.MouseEvent | React.TouchEvent) => {
    console.log('Long press triggered ');
    handleOpenActionModal(item)();
  };

  const onClick = (event: React.MouseEvent | React.TouchEvent) => {
    console.log('Click triggered');
    // handleOpenActionModal(item)();
  };

  const isMessageFile = item?.type === 'file';

  const onMediaClick = (event: any) => {
    console.log('CLICK ON PARENT ');
    const id = event?.target?.id.split('_')[1];
    setOpenFileViewer(true);
    setNumberSwiper(+id);
  };

  const handleFileOpen = (index: number) => {
    // console.log('mandeha')
    setOpenFileViewer(true);
    setNumberSwiper(index);
  };

  const onOpenModal = (id: number) => {
    setOpenFileViewer(true);
    setNumberSwiper(+id);
  };

  const longPressProps = useLongPress(onLongPress);

  const mediasLongPressProps = useLongPress(onLongPress);

  const getMessageParent = async (item: any) => {
    const msgRef = doc(db, 'messages', item.msgId, 'item', item.parentId as any);
    const docSnap = await getDoc(msgRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // docSnap.data() will be undefined in this case
      return undefined;
    }
  };

  const handleSwipeChange = async (swiper: any) => {
    setNumberSwiper(swiper.activeIndex);
    if (item?.files[swiper.activeIndex]?.mime?.includes('pdf')) {
      await Browser.open({
        url: item?.files[swiper.activeIndex]?.url,
        windowName: '_self',
        presentationStyle: 'popover',
      });
    }
  };

  useEffect(() => {
    if (item.isResponse) {
      if (item?.msgId && item?.parentId) {
        getMessageParent(item).then((res: any) => {
          setParentMessage(res);
        });
      }
    }
  }, [item.isResponse]);

  const parentMessageAuthor = participants.find((p: any) => parentMessage?.fromId === p?.id);

  const getMessageBody = () => {
    const escapedSearchKey = searchKey.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const isSearchActive = escapedSearchKey.trim().length;

    let regex: any;
    let parts;
    if (isSearchActive) {
      regex = new RegExp(`(${escapedSearchKey})`, 'gi');
      parts = item?.body?.split(regex);
    }
    const body = isSearchActive
      ? (parts || []).map((part: any, index: number) =>
          regex.test(part) ? (
            <span key={index} style={{ backgroundColor: isHighlighted ? '#FC9632' : '#03B070', color: '#fff' }}>
              {part}
            </span>
          ) : (
            <span key={index}>{part}</span>
          )
        )
      : item?.body;

    return <>{body}</>;
  };

  let touchStartTimestamp = 0;
  const handleTouchStart = (e: any) => {
    // e.stopPropagation();
    touchStartTimestamp = 1;
    setTimeout(() => {
      if (touchStartTimestamp !== 0) {
        longPress();
      }
    }, 1000);
  };
  const longPress = () => {
    console.log({ item });
    handleOpenActionModal(item)();
    touchStartTimestamp = 0;
  };

  return (
    <>
      {!item.deleted ? (
        <ChatItemWrapper ref={scrollRef}>
          {showAvatar && !isFromMySelf && !parentMessage && (
            <span className="chat__from">
              {(peer?.isPro ? peer?.companyName : peer?.username) ||
                (peer?.attributes?.isPro ? peer?.attributes?.companyName : peer?.attributes?.username) ||
                ''}
            </span>
          )}
          <div id={id} ref={ref as any} className={`chat__item ${cls}`}>
            <div className={`chat__avatar chat__avatar--left ${showAvatar ? '' : 'chat__avatar--hidden'}`}>
              {photoUrl && (
                <IonAvatar style={{ width: '32px', height: '32px' }} onClick={goToProfil}>
                  <IonImg src={photoUrl} alt="avatar" />
                </IonAvatar>
              )}
            </div>
            {item?.body?.trim()?.length > 0 ? (
              <>
                <div className="chat__item-body__container" style={{ alignItems: 'flex-end' }}>
                  {parentMessage && (
                    <>
                      <div className={`chat__respond-to ${isFromMySelf ? '' : 'chat__respond-to-other'}`}>
                        <IonIcon size="small" icon={greenResponIcon} />
                        <span>
                          {isFromMySelf
                            ? `Tu as répondu à ${
                                (parentMessageAuthor?.isPro
                                  ? parentMessageAuthor?.companyName
                                  : parentMessageAuthor?.username) ||
                                (parentMessageAuthor?.attributes?.isPro
                                  ? parentMessageAuthor?.attributes?.companyName
                                  : parentMessageAuthor?.attributes?.username)
                              }`
                            : peer?.id === parentMessageAuthor?.id
                            ? `${
                                (peer?.isPro ? peer?.companyName : peer?.username) ||
                                (peer?.attributes?.isPro ? peer?.attributes?.companyName : peer?.attributes?.username)
                              } t'a répondu`
                            : `${
                                (peer?.isPro ? peer?.companyName : peer?.username) ||
                                (peer?.attributes?.isPro ? peer?.attributes?.companyName : peer?.attributes?.username)
                              } a répondu à ${
                                (parentMessageAuthor?.isPro
                                  ? parentMessageAuthor?.companyName
                                  : parentMessageAuthor?.username) ||
                                (parentMessageAuthor?.attributes?.isPro
                                  ? parentMessageAuthor?.attributes?.companyName
                                  : parentMessageAuthor?.attributes?.username)
                              }`}
                        </span>
                      </div>
                      <>
                        {parentMessage?.type === 'file' ? (
                          <>
                            {!parentMessage?.files[0].mime?.includes('audio') && (
                              <MediaGallery
                                onFileOpen={onMediaClick}
                                media={parentMessage?.files}
                                isMine={isFromMySelf}
                              />
                            )}
                            {parentMessage?.files[0].mime?.includes('audio') && (
                              <>
                                {!!isAndroid ? (
                                  <AudioWaveAndroid media={parentMessage?.files[0]} />
                                ) : (
                                  <AudioWaveForm media={parentMessage?.files[0]} />
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <div
                            className={`chat__item-parent-body  ${isFromMySelf ? '' : 'chat__item-parent-body-other'}`}
                          >
                            <div
                              style={{ whiteSpace: 'pre-line' }}
                              dangerouslySetInnerHTML={{ __html: parentMessage?.body }}
                            />
                          </div>
                        )}
                      </>
                    </>
                  )}
                  <div
                    {...longPressProps}
                    className={`chat__item-body ${item.edited ? 'chat__item-body__edited' : ''}`}
                  >
                    <div style={{ whiteSpace: 'pre-line' }}>{getMessageBody()}</div>
                  </div>
                </div>
                {showTime && !isMessageFile && (
                  <span className="chat__time">{formatDateWithCustomLogic(item.createdAt)}</span>
                )}
              </>
            ) : null}
          </div>
          {isMessageFile && item?.files?.length > 0 && item?.files[0].mime?.includes('audio') && (
            <div
              // {...longPressProps}
              onMouseDown={mouseDown}
              onMouseUp={mouseUp}
              onTouchStart={touchStart}
              onTouchEnd={touchEnd}
              className={`chat__item_gallery-container ${isFromMySelf ? 'chat__item__files-right' : ''}`}
            >
              <MediaGallery onFileOpen={handleFileOpen} media={item?.files} isMine={isFromMySelf} />
              {showTime && <span className={`chat__files-time`}>{formatDateWithCustomLogic(item.createdAt)}</span>}
            </div>
          )}
          {isMessageFile && item?.files?.length > 0 && !item?.files[0].mime?.includes('audio') && (
            <div
              onTouchStart={(e) => handleTouchStart(e)}
              onTouchEnd={() => {
                touchStartTimestamp = 0;
              }}
              className={`chat__item_gallery-container ${isFromMySelf ? 'chat__item__files-right' : ''}`}
            >
              <MediaGallery
                onFileOpen={onMediaClick}
                media={item?.files}
                isMine={isFromMySelf}
                onOpenModal={onOpenModal}
              />
              {showTime && <span className={`chat__files-time`}>{formatDateWithCustomLogic(item.createdAt)}</span>}
            </div>
          )}
        </ChatItemWrapper>
      ) : isDeletedByMe ? (
        <>
          <DeletedMesageWrapper>
            <div className="deleted-message-container">Ton message a été supprimé.</div>
          </DeletedMesageWrapper>
          {showTime && (
            <div className="chat__time__deleted">
              <span>{formatDateWithCustomLogic(item.createdAt)}</span>
            </div>
          )}
        </>
      ) : null}
      <IonModal isOpen={openFileViewer} className="full-media padding-safearea">
        <IonButton
          fill="clear"
          color="light"
          className="btn-media"
          onClick={() => {
            setOpenFileViewer(false);
          }}
        >
          <IonIcon color="light" size="lg" className="icon-media" icon={closeCircleOutline}></IonIcon>
        </IonButton>
        <IonButton
          fill="clear"
          color="light"
          className="btn-prev"
          onClick={() => {
            swiperRef?.slidePrev();
          }}
        >
          <IonIcon color="light" className="icon-media" icon={arrowBackCircleOutline}></IonIcon>
        </IonButton>
        <IonButton
          fill="clear"
          color="light"
          className="btn-next"
          onClick={() => {
            swiperRef?.slideNext();
          }}
        >
          <IonIcon color="light" className="icon-media" icon={arrowForwardCircleOutline}></IonIcon>
        </IonButton>
        <Swiper
          modules={[Pagination]}
          zoom={true}
          spaceBetween={20}
          centeredSlidesBounds={true}
          centeredSlides={true}
          setWrapperSize={true}
          pagination={{
            clickable: true,
            type: 'bullets',
            renderBullet(index, className) {
              return `<span class="${className} ${
                item?.files && item?.files?.length <= 3 ? 'pagination-custom' : ''
              }"></span>`;
            },
          }}
          initialSlide={numberSwiper}
          onSlideChange={handleSwipeChange}
          onSwiper={initSwiper}
        >
          {item?.files &&
            item?.files?.map((item: any, index: number) => {
              return (
                <SwiperSlide key={index}>
                  <div>
                    {item && item?.mime.substring(0, 5) === 'image' && (
                      <img alt="Silhouette of mountains" style={{ borderRadius: '7px' }} src={item.url as string} />
                    )}
                    {item?.mime?.includes('pdf') && index === numberSwiper && <PdfViewer file={item} />}
                    {item && item.mime.substring(0, 5) === 'video' && (
                      <VideoView
                        className="ion-border-radius-5"
                        url={item?.url as string}
                        previewUrl={item?.previewUrl}
                        autoPlay={false}
                        from=""
                        playIcon={playIcon}
                      ></VideoView>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </IonModal>
    </>
  );
});

export const DeletedMesageWrapper = styled('div')`
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px;
    max-width: 100vw;
    >.deleted-message-container {
      width: 57vw;
      display: flex;
      justify-content: flex-end;
      border-radius: 10px 2px 10px 10px;
      background: rgba(217, 217, 217, 0.40);
      padding: 16px;
      color: #313333;
      text-align: right;
      leading-trim: both;
      text-edge: cap;
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
  }`;

export const ChatItemWrapper = styled('div')`
    display: block;
    justify-content: center;
  }
`;

export default MessageItem;
