import { IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow } from '@ionic/react';

import '@ionic/react/css/ionic-swiper.css';
import { warning } from 'ionicons/icons';
import { useHistory } from 'react-router';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { getIcon } from '../../../utils/weatherkit';
import DispoDate from '../../DispoDate';
import DispoLabel from '../../DispoLabel';

interface IDispoPublicProps {
  id: string;
  name: string;
  start: number;
  end: number;
  addressCity: string;
  addressLine: string;
  cover: string;
  creatorPicture: string;
  creator: string; // userid
  creatorFirstname: string;
  creatorLastname: string;
  creatorUsername?: string;
  dispoDate: IDate;
  status: string;
  type: string;
  pending?: boolean;
  distance?: number;
  noWrap?: boolean;
  showCreator?: boolean;
  weatherInfo?: string;
}

const CardDispoPublic: React.FC<IDispoPublicProps> = ({
  id,
  name,
  start,
  end,
  addressCity,
  addressLine,
  creatorPicture,
  cover,
  creator,
  creatorFirstname,
  creatorLastname,
  creatorUsername,
  dispoDate,
  status,
  type,
  pending,
  distance,
  noWrap = false,
  showCreator = true,
  weatherInfo,
}) => {
  const history = useHistory();
  // const eventDateData = getEventDateData(dispo);

  return (
    <IonCard className={`card-custom ion-no-margin ion-margin-top`} onClick={() => history.push('/main/dispos/' + id)}>
      <div className="container-image">
        <img
          className="image-card-custom"
          alt="Cover"
          src={cover || 'https://ionicframework.com/docs/img/demos/avatar.svg'}
        />
        {status === 'in_progress' && (
          <IonRow className="float-tr ion-padding">
            <DispoLabel label={'En cours'} bgClass="bg-yellow" borderClass="border-white" />
          </IonRow>
        )}
        {type && (
          <IonRow className="float-bl padding-5">
            <DispoLabel label={type === 'private' ? 'privée' : 'publique'} uppercase={true} />
          </IonRow>
        )}
        {pending && (
          <IonRow className="float-tr padding-5">
            <DispoLabel icon={warning} bgClass="bg-orange" label="En attente" uppercase={true} />
          </IonRow>
        )}
        {distance && (
          <IonRow className="float-br padding-5">
            <DispoLabel bgClass="bg-gray-400 " label={(distance / 1000).toFixed() + 'km'} />
          </IonRow>
        )}
      </div>

      <IonCardContent className="padding-card">
        <IonList className="ion-no-padding">
          <IonItem lines="none" className="ion-no-padding ion-item-min">
            <IonLabel className="ion-no-margin">
              <h3 className="font-subtitle font-outfit font-text d-flex-title">
                {/*<span>{eventDateData.debut}</span> <span className="point-separator"></span>{' '}
                <span>{eventDateData.fin}</span>*/}
              </h3>
            </IonLabel>
          </IonItem>
          <IonItem lines="none" className="ion-no-padding ion-item-min ion-text-capitalize">
            <DispoDate dispoDate={dispoDate} noWrap={noWrap} />
          </IonItem>
          <IonItem lines="none" className="ion-no-padding ion-item-min">
            <IonLabel className="ion-no-margin">
              <h3 className="font-title font-outfit font-text">{name}</h3>
            </IonLabel>
          </IonItem>
          <IonGrid className="ion-no-padding ion-no-margin margin-top-xs">
            <IonRow
              className="ion-no-padding ion-no-margin"
              style={{ flexWrap: noWrap ? 'nowrap' : 'wrap', minHeight: `${!showCreator ? '50px' : ''}` }}
            >
              <IonCol size="auto" className="flex-card-avatar">
                <img alt="pdp" src="images/place.svg" className="avatar-card" />
              </IonCol>
              <IonCol
                style={{ overflow: noWrap ? 'hidden' : 'visible', display: 'flex' }}
                className="ion-align-items-center"
              >
                <p
                  className={`font-subtitle font-outfit font-grey-light margin-left-label ${noWrap ? 'wrap-text' : ''}`}
                >
                  {addressLine}
                </p>
              </IonCol>
              {weatherInfo && !showCreator && (
                <div className="weather-container">
                  <IonIcon src={getIcon(weatherInfo)} />
                </div>
              )}
            </IonRow>
          </IonGrid>
          {showCreator && creator && (
            <IonGrid className="ion-no-padding ion-no-margin margin-top-sm">
              <IonRow className="ion-no-padding ion-no-margin">
                <IonCol size="auto" className="flex-card-avatar">
                  <img
                    alt="pdp"
                    src={creatorPicture || 'https://ionicframework.com/docs/img/demos/avatar.svg'}
                    className="avatar-card"
                  />
                </IonCol>
                <IonCol className="ion-align-items-center d-flex">
                  <p className="font-subtitle font-outfit font-grey-light margin-left-label">
                    {creatorUsername
                      ? creatorUsername
                      : `${creatorFirstname ? creatorFirstname : ''} ${creatorLastname ? creatorLastname : ''}`}
                  </p>
                </IonCol>
                {weatherInfo && (
                  <div className="weather-container">
                    <IonIcon icon={getIcon(weatherInfo)} />
                  </div>
                )}
              </IonRow>
            </IonGrid>
          )}
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

export default CardDispoPublic;
