import { IonContent, IonPage, IonButton } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { Header, ValidatedIcon, CardCustom } from '../../../../components';
import { useHistory } from 'react-router-dom';
import '../style.css';

const NoCompte: React.FC = () => {
  const history = useHistory();
  const goTo = (path: string) => {
    history.push(path);
  };
  return (
    <IonPage>
      <Header title="Connexion" />
      <IonContent className="ion-padding">
        <CardCustom>
          <ValidatedIcon icon={closeOutline}></ValidatedIcon>
          <p className="ion-text-center margin-auto font-outfit font-description">
            Désolée, nous n’arrivons pas à t’identifier
          </p>
          <IonButton
            type="submit"
            className="long-text-btn ion-margin-top"
            expand="block"
            color="primary"
            onClick={() => goTo('/login/send-phone')}
          >
            Recevoir le code de connexion par SMS
          </IonButton>
          <p className="ion-text-center margin-auto font-description-2">Je n’ai pas de compte Tedispo</p>
          <IonButton
            type="submit"
            className="btn-link"
            expand="block"
            color="dark"
            fill="clear"
            onClick={() => goTo('/signup/choose')}
          >
            M'inscrire
          </IonButton>
        </CardCustom>
      </IonContent>
    </IonPage>
  );
};

export default NoCompte;
