import { useQuery } from '@apollo/client';
import { IonContent, IonPage, IonSkeletonText, useIonViewWillEnter } from '@ionic/react';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { FeedList, FullSlider } from '../../../components';
import SimpleHeader from '../../../components/SimpleHeader';
import { GET_USER } from '../../../graphql/queries/getUser.graphql';
import { usePosts } from '../../../hooks/usePosts';
import { UploadFileEntity, UsersPermissionsUser } from '../../../models/gql/graphql';
import { getUserId } from '../../../utils';
import '../style.css';

const UserPosts: React.FC = () => {
  const { id } = useParams<IVerifParams>();
  const { posts, refreshPost, loading } = usePosts(id, 'myPost');
  const [files, setFiles] = useState<UploadFileEntity[]>();
  const { data, loading: loadingUser } = useQuery(GET_USER, {
    variables: { id: id || '0' },
  });

  const userData = useMemo(() => {
    return (data && !loadingUser ? data?.usersPermissionsUser?.data?.attributes : []) as UsersPermissionsUser;
  }, [data, loadingUser]);

  useIonViewWillEnter(() => {
    refreshPost();
  });

  return (
    <IonPage>
      <SimpleHeader
        title={
          !id || id.toString() === getUserId()?.toString()
            ? 'Tes publications'
            : `Posts de ${!loadingUser ? (userData.isPro ? userData.companyName : userData.username) : ''} `
        }
        noButtonBack={false}
        showMenuButton={false}
        backButtonColor="text-black"
      ></SimpleHeader>
      <IonContent className="bg-white">
        {!!loading && (
          <div className="ion-padding custom-skeleton">
            <IonSkeletonText animated style={{ width: '60%' }} />
            <IonSkeletonText animated />
            <IonSkeletonText animated style={{ width: '88%' }} />
            <IonSkeletonText animated style={{ width: '70%' }} />
            <IonSkeletonText animated style={{ width: '30%' }} />
            <IonSkeletonText animated style={{ width: '80%' }} />
            <IonSkeletonText animated style={{ width: '40%' }} />
          </div>
        )}
        <FeedList posts={posts} refreshPost={refreshPost} setFiles={setFiles} loading={loading} />
      </IonContent>
      {!!(files && files.length) && <FullSlider files={files || []} setFiles={setFiles} />}
    </IonPage>
  );
};

export default UserPosts;
