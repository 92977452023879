import { useMutation } from '@apollo/client';
import { PaymentFlowEventsEnum, Stripe, ApplePayEventsEnum, GooglePayEventsEnum } from '@capacitor-community/stripe';
import { Capacitor } from '@capacitor/core';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  useIonToast,
} from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';
import { logoAndroid, logoApple } from 'ionicons/icons';
import styled from 'styled-components';
import { HeaderTypeTwo, PriceBox } from '../../../components';
import { CREATE_PAYMENT_INTENT } from '../../../graphql/mutations/stripe.graphql';
import { useDispoDetail } from '../../../hooks/useDispoDetail';
import { useHistory } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import { defineCustomElements } from 'stripe-pwa-elements/loader';

const PreOrder: React.FC = () => {
  const [createPaymentIntent, { loading }] = useMutation(CREATE_PAYMENT_INTENT);
  const [stripe, setStripe] = useState<any>();
  const [isApplePayAvailable, setApplePayAvailableStatus] = useState(false);
  const [isGooglePayAvailable, setGooglePayAvailableStatus] = useState(false);
  const dispoData = useDispoDetail();
  const { loadingEvent } = dispoData;
  const history = useHistory();
  const [disabling, setDisabling] = useState(false);
  const [presentToast, dismissToast] = useIonToast();

  var watch = useCallback(
    async function () {
      console.log('watch');
      const i: any = await dispoData.refetchParticipation();
      if (i.data?.eventUserParticipations.data && i.data?.eventUserParticipations.data.length) {
        if (i.data?.eventUserParticipations.data[0].attributes.status === 'accepted') {
          //  success
          history.push(`/main/dispos/${dispoData.id}/payment-success`);
        } else if (
          ['payment_failed', 'payment_canceled'].indexOf(i.data?.eventUserParticipations.data[0].attributes.status) > -1
        ) {
          // error
          history.push(`/main/dispos/${dispoData.id}/inscription-failed`);
        } else {
          setTimeout(async () => {
            await watch();
          }, 2000);
        }
      } else {
        console.log('nonono');
        setTimeout(async () => {
          await watch();
        }, 2000);
      }
    },
    [dispoData]
  );

  const getPaymentIntent = async () => {
    const { data } = await createPaymentIntent({
      variables: {
        input: {
          eventId: dispoData.id,
          amount: dispoData?.event?.attributes?.price,
          stripeAccount: dispoData?.creator?.attributes?.stripeAccount || undefined,
        },
      },
    });
    return {
      paymentIntentClientSecret: data?.CreatePaymentIntent?.data?.paymentIntent,
      customerId: data?.CreatePaymentIntent?.data?.customerId,
      customerEphemeralKeySecret: data?.CreatePaymentIntent?.data?.ephemeralKey.secret,
    };
  };

  const processCard = async () => {
    try {
      const { paymentIntentClientSecret, customerId, customerEphemeralKeySecret } = await getPaymentIntent();
      await stripe
        .createPaymentSheet({
          paymentIntentClientSecret,
          customerId,
          customerEphemeralKeySecret,
          merchantDisplayName: 'Tedispo', // or creator?
        })
        .then(() => console.log('1 success'))
        .catch((e: any) => {
          console.log('2 error', e);
          presentToast("Une erreur s'est produite pendant la création de la fenêtre du paiement", 3000);
          throw e;
        });
      try {
        const presentResult = await stripe.presentPaymentSheet();
        if (presentResult.paymentResult === 'paymentSheetCompleted') {
          // success
          console.log('success', presentResult);
          // watch partiicpations

          await watch();
        } else {
          presentToast("Une erreur s'est produite pendant le paiement , veuillez réessayer ultérieurement", 1000);
          console.log({ presentResult });
        }
      } catch (err) {
        presentToast(
          "Une erreur s'est produite pendant la création de votre tunnel de paiement, veuillez nous contacter.",
          3000
        );
        console.error(err);
        throw err;
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const processApple = async () => {
    try {
      const { paymentIntentClientSecret } = await getPaymentIntent();
      await stripe.createApplePay({
        paymentIntentClientSecret,
        paymentSummaryItems: [
          {
            label: 'Tedispo SAS',
            amount: dispoData?.event?.attributes?.price,
          },
        ],
        merchantIdentifier: 'merchant.com.tedispo',
        countryCode: 'FR',
        currency: 'EUR',
      });
      const result = await stripe.presentApplePay();
      console.log({ result });
      if (result.paymentResult === ApplePayEventsEnum.Completed) {
        // Happy path
        console.log('success');
        await watch();
      } else {
        presentToast("Une erreur s'est produite pendant le paiement , veuillez réessayer ultérieurement", 3000);
        console.log({ result });
      }
    } catch (e) {
      presentToast("Une erreur s'est produite pendant la création de la fenêtre du paiement", 3000);
      console.error(e);
    }
  };

  const processAndroid = async () => {
    try {
      const { paymentIntentClientSecret } = await getPaymentIntent();
      await stripe.createGooglePay({
        paymentIntentClientSecret,
        paymentSummaryItems: [
          {
            label: 'Tedispo SAS',
            amount: dispoData?.event?.attributes?.price,
          },
        ],
        merchantIdentifier: 'com.tedispo',
        countryCode: 'FR',
        currency: 'EUR',
      });
      const result = await Stripe.presentGooglePay();
      if (result.paymentResult === GooglePayEventsEnum.Completed) {
        // Happy path
        await watch();
      } else {
        presentToast("Une erreur s'est produite pendant le paiement , veuillez réessayer ultérieurement", 3000);
      }
    } catch (e) {
      presentToast("Une erreur s'est produite pendant la création de la fenêtre du paiement", 3000);
      console.error(e);
    }
  };
  useEffect(() => {
    if (dispoData?.creator?.attributes?.stripeAccount) {
      const prom = Capacitor.isNativePlatform() ? Promise.resolve() : defineCustomElements();
      prom.then(() => {
        console.log({
          stripeAccount: dispoData?.creator?.attributes?.stripeAccount,
          publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
        });
        if (!stripe) {
          Stripe.initialize({
            stripeAccount: dispoData?.creator?.attributes?.stripeAccount,
            publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
          })
            .then(() => {
              return Stripe.isApplePayAvailable()
                .then(() => {
                  setApplePayAvailableStatus(true);
                })
                .catch(() => {
                  setApplePayAvailableStatus(false);
                });
            })
            .then(() => {
              return Stripe.isGooglePayAvailable()
                .then(() => {
                  setGooglePayAvailableStatus(true);
                })
                .catch(() => {
                  setGooglePayAvailableStatus(false);
                });
            })
            .then(() => {
              setStripe(Stripe);
            });
        }
      });
    }
    // set disabled if
    if (
      dispoData &&
      dispoData.event &&
      dispoData?.event?.attributes?.creator?.data?.attributes?.stripeCapabilitiesStatus !== 'active' &&
      dispoData?.event?.attributes?.paymentType !== 'Gratuite'
    ) {
      setDisabling(true);
    } else {
      setDisabling(false);
    }
  }, [dispoData, stripe]);

  return (
    <PreOrderWrapper>
      {!loadingEvent && dispoData && (
        <>
          <IonContent scrollEvents={true} fullscreen>
            <div className="bg-primary full-height">
              <HeaderTypeTwo title="Participer à la dispo">
                <IonRow className="margin-y-20">
                  <IonText className="text-bolder text-size-md margin-l-20 text-white">{dispoData.eventName}</IonText>
                </IonRow>
              </HeaderTypeTwo>
              <IonRow
                style={{
                  marginTop: 80,
                  zIndex: '50',
                  border: 'none',
                  width: '100%',
                }}
              >
                <IonCard className="border-radius font-outfit box-shadow-custom">
                  <IonCardContent>
                    <div className="text">
                      La dispo à laquelle tu souhaites participer est payante, comment souhaites-tu régler ?
                    </div>
                    <div className="price-box">
                      <PriceBox>{dispoData.event?.attributes?.price}€</PriceBox>
                    </div>
                  </IonCardContent>
                </IonCard>
              </IonRow>
            </div>
          </IonContent>
          <IonFooter>
            <div className="footer">
              <IonRow>
                <IonCol>
                  <IonButton
                    disabled={loading || disabling}
                    expand="block"
                    color="primary"
                    onClick={() => processCard()}
                  >
                    Carte bancaire
                  </IonButton>
                </IonCol>
              </IonRow>
              {isApplePayAvailable && (
                <IonRow>
                  <IonCol>
                    <IonButton
                      disabled={loading || disabling}
                      expand="block"
                      fill="outline"
                      color="dark"
                      onClick={() => processApple()}
                    >
                      <IonIcon icon={logoApple} /> pay
                    </IonButton>
                  </IonCol>
                </IonRow>
              )}
              {isGooglePayAvailable && (
                <IonRow>
                  <IonCol>
                    <IonButton
                      disabled={loading || disabling}
                      expand="block"
                      fill="outline"
                      color="dark"
                      onClick={() => processAndroid()}
                    >
                      <IonIcon icon={logoAndroid} /> pay
                    </IonButton>
                  </IonCol>
                </IonRow>
              )}
            </div>
          </IonFooter>
        </>
      )}
    </PreOrderWrapper>
  );
};

export default PreOrder;
const PreOrderWrapper = styled(IonPage)`
  ion-button {
    text-transform: capitalize;
  }
  ion-card.box-shadow-custom {
    height: calc(100vh - 200px);
    margin: 0;
    width: 100%;
    ion-card-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      align-items: center;
      div {
        &.text {
          color: var(--text, #313333);
          text-align: center;
          leading-trim: both;
          text-edge: cap;

          /* P/16px Med. */
          font-family: Outfit;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
        }
        &.price-box {
          display: flex;
          width: 109px;
          padding: var(--corner-16, 16px);
          justify-content: center;
          align-items: center;
          gap: var(--corner-8, 8px);
        }
        &.footer {
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }
    }
  }
`;
