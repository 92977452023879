import React, { useEffect, useState } from 'react';
import { IonModal } from '@ionic/react';
import styled from 'styled-components';

export interface IComponentModalProps {
  isOpen: boolean;
  title?: string;
  onCancel: () => void;
  children: React.ReactNode;
  height?: string;
  initialBreakpoint?: number;
  noBorderRadius?: boolean;
  useBackgroundGrey?: boolean;
  backdropDismiss?: boolean;
  backdropBreakpoint?: number;
  useBackgroundGreen?: boolean;
  showHandle?: boolean;
}

const ComponentModal: React.FC<IComponentModalProps> = ({
  isOpen,
  title,
  onCancel,
  children,
  height,
  initialBreakpoint,
  noBorderRadius = false,
  useBackgroundGrey = false,
  backdropDismiss = true,
  backdropBreakpoint = 0,
  useBackgroundGreen = false,
  showHandle,
}) => {
  const [showModal, setShowModal] = useState(isOpen);
  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen, setShowModal]);
  const handleCancel = () => {
    setShowModal(false);
    onCancel();
  };
  return (
    <ComponentModalWrapper
      mode="ios"
      isOpen={showModal}
      initialBreakpoint={initialBreakpoint || 1}
      style={{ '--height': height || '60%', '--bottom': '0', '--overflowY': 'hidden' }}
      className={`${noBorderRadius ? 'button-no-border-radius' : ''} ${useBackgroundGrey ? 'background-grey' : ''} ${
        useBackgroundGreen ? 'background-green' : ''
      }`}
      onDidDismiss={() => handleCancel()}
      breakpoints={[0, 0.25, 0.5, 0.7, 0.75, 0.9, 1]}
      backdropDismiss={backdropDismiss}
      backdropBreakpoint={backdropBreakpoint}
      handle={showHandle}
    >
      <ModalContentWrapper>{children}</ModalContentWrapper>
    </ComponentModalWrapper>
  );
};
const ComponentModalWrapper = styled(IonModal)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
`;

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
`;

export default ComponentModal;
