import { IonIcon, IonItem, IonLabel, IonList, IonRow } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { UseInfiniteHitsProps, useInfiniteHits, useInstantSearch } from 'react-instantsearch';
import { useHistory } from 'react-router';
import { AlgoliaUserAvatar } from '../AlgoliaUser';

function SearchInfiniteHits(props: UseInfiniteHitsProps) {
  const { hits, showMore, isLastPage } = useInfiniteHits(props);
  const { status } = useInstantSearch();
  const sentinelRef = useRef<any>(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (sentinelRef.current !== null && hits.length) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage && status == 'idle') {
            console.log('showMore()');
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore, hits, status]);

  useEffect(() => {
    if (hits.length > 0) {
      setIsLoading(false);
    }
  }, [hits]);

  return (
    <IonList>
      {hits.length > 0 && (
        <div className="ion-padding">
          <IonLabel>Résultats de recherche</IonLabel>
        </div>
      )}
      {hits.map((hit: any) => (
        <IonItem
          key={hit.objectID}
          style={{
            '--inner-border-width': 0,
          }}
          onClick={() => {
            history.push('/main/profil/' + hit.id);
          }}
        >
          <AlgoliaUserAvatar imgUrl={hit.picture} slot="start" />
          <IonLabel>{hit.username}</IonLabel>
          <IonIcon icon={arrowForwardOutline} slot="end" />
        </IonItem>
      ))}
      {!hits.length && !isLoading && status !== 'stalled' && status !== 'loading' && (
        <IonRow class="ion-justify-content-center font-outfit">Aucune dispo ne correspond à ta recherche.</IonRow>
      )}
      <IonRow className="sentinel" ref={sentinelRef} />
    </IonList>
  );
}
export default SearchInfiniteHits;
