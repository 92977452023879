import { Camera } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { VideoEditor, MediaFileResult } from '@whiteguru/capacitor-plugin-video-editor';
import { cloneDeep, map } from 'lodash';
import { base64ToFile, compressVideo } from '../../utils/file';
import { checkForbiddenWordInputted, stripTag } from '../../utils/word';

export const handleDescriptionChange = (
  value: string,
  forbiddenWords: any,
  setDescription: (value: string) => void,
  presentToast: any
) => {
  let text = value;
  if (text) {
    const forbidden = checkForbiddenWordInputted(forbiddenWords, text);
    if (forbidden) {
      // remove
      var lastIndex = stripTag(text).trim().lastIndexOf(' ');
      text = stripTag(text).substring(0, lastIndex) + ' ';
      // set error msg
      presentToast('Un mot interdit a été détécté et a été supprimé de votre entrée', 1000);
    }
  }
  setDescription(text);
};

export const clearImage = (value: number, selectedMedia: IImage[], setSelectedMedia: (image: IImage[]) => void) => {
  const cloneMediaArray = cloneDeep(selectedMedia);
  cloneMediaArray.splice(value, 1);
  setSelectedMedia(cloneMediaArray);
};

export const pickVideos = async (
  selectedMedia: IImage[],
  setSelectedMedia: (image: IImage[]) => void,
  setIsOpen: (value: boolean) => void,
  setMessageError: (value: string) => void,
  setProcessing: (value: boolean) => void
) => {
  console.log('pickVideos');
  const result = await FilePicker.pickVideos({
    multiple: true,
  });
  console.log('result', result);
  if (result.files.length) {
    if (selectedMedia.length + result.files.length <= 8) {
      if (setProcessing) setProcessing(true);
      const cloneMediaArray = cloneDeep(selectedMedia);
      const files: any = [];
      for (let video of result.files) {
        if (video.path) {
          // mean ios or android
          // need resize
          const { data: dataFirst } = await Filesystem.readFile({ path: video.path });
          const { uri } = await Filesystem.writeFile({ data: dataFirst, path: 'temp.mp4', directory: Directory.Data });

          const newPath = await compressVideo(uri);

          if (newPath) {
            const { data } = await Filesystem.readFile({ path: newPath });
            console.log('file readed', data);
            const f = base64ToFile(data as string, 'video/mp4', 'name');
            files.push(f);
          }
        } else if (video.data) {
          const f = base64ToFile(video.data, 'video/mp4', 'name');
          files.push(f);
        }
      }
      console.log('jkhkhkh, fi', files);
      const images = formatFile(files);
      images.forEach((image) => {
        cloneMediaArray.push(image);
      });
      setSelectedMedia(cloneMediaArray);
      if (setProcessing) setProcessing(false);
    } else {
      setIsOpen(true);
      setMessageError('Pas plus de 8 médias');
      if (setProcessing) setProcessing(false);
    }
  }
};

export const onSelectFile = (
  { target: { validity, files } }: any,
  selectedMedia: IImage[],
  setSelectedMedia: (image: IImage[]) => void,
  setIsOpen: (value: boolean) => void,
  setMessageError: (value: string) => void
) => {
  const cloneMediaArray = cloneDeep(selectedMedia);
  if (files.length) {
    if (selectedMedia.length + files.length <= 8) {
      if (validity.valid) {
        const images = formatFile(files);
        images.forEach((image) => {
          cloneMediaArray.push(image);
        });
        setSelectedMedia(cloneMediaArray);
      }
    } else {
      setIsOpen(true);
      setMessageError('Pas plus de 8 médias');
    }
  }
};

export const formatFile = (files: FileList) => {
  const imageFormated = map(files, (image: any) => {
    return {
      file: image,
      url: URL.createObjectURL(image),
      idImage: null,
      type: 'image',
    };
  });
  return imageFormated;
};
const openFile = async (refBtnPick: React.RefObject<HTMLInputElement>) => {
  if (refBtnPick.current) {
    refBtnPick.current.click();
  }
};

export const handlePickPhotos = async (
  refBtnPick: React.RefObject<HTMLInputElement>,
  selectedMedia: IImage[],
  setSelectedMedia: (image: IImage[]) => void,
  setIsOpen: (value: boolean) => void,
  setMessageError: (value: string) => void
) => {
  if (Capacitor.isNativePlatform()) {
    const { photos } = await Camera.pickImages({ quality: 80, width: 1024, height: 1024 });
    if (photos[0] && photos[0].path) {
      if (selectedMedia.length + photos.length <= 8) {
        const files: any = [];
        const cloneMediaArray = cloneDeep(selectedMedia);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < photos.length; ++i) {
          const photo: any = photos[i];
          const { path } = photo;
          // eslint-disable-next-line no-await-in-loop
          const { data } = await Filesystem.readFile({ path });
          const f = base64ToFile(data as string, 'image/jpg', 'name');
          files.push(f);
        }
        const images = formatFile(files);
        images.forEach((image) => {
          cloneMediaArray.push(image);
        });
        setSelectedMedia(cloneMediaArray);
      } else {
        setIsOpen(true);
        setMessageError('Pas plus de 8 médias');
      }
    }
  } else {
    openFile(refBtnPick);
  }
};

export const uploadFiles = (imagesToUpload: IImage[], upload: any) => {
  return imagesToUpload.map(async (img: any) => {
    if (img && img?.file && img.file.name) {
      try {
        const { data: dataUpload } = await upload({ variables: { file: img.file } });
        return dataUpload?.upload.data?.id;
      } catch (e) {
        console.log(e);
      }
    } else if (img && img?.file && img.file.attributes) {
      return img.id;
    }
    return;
  });
};
