import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_COMMENTS } from '../graphql/queries/comments.graphql';
import { CommentEntity } from '../models/gql/graphql';

export const useComments = (idPost: string) => {
  const { data, loading, refetch } = useQuery(GET_COMMENTS, {
    variables: {
      filters: {
        and: [
          {
            post: {
              id: {
                eq: idPost,
              },
            },
          },
          { or: [{ deleted: { eq: null } }, { deleted: { eq: false } }] },
        ],
      },
      pagination: { limit: 100 },
    },
  });

  const comments = useMemo(() => {
    return (data && !loading ? data.comments?.data : []) as CommentEntity[];
  }, [data, loading]);

  return {
    comments,
    refreshComment: refetch,
  };
};
