import { IonButton, IonCheckbox, IonContent, IonLabel, IonPage, IonText, IonToast, useIonToast } from '@ionic/react';
import { calendarOutline, locationOutline, timeOutline } from 'ionicons/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import { useHistory } from 'react-router-dom';
import {
  AppDatepicker,
  AppSearchAddressInput,
  CardCustom,
  DoubleTimePicker,
  Header,
  InputCustom,
  RichEditor,
  StepperCount,
} from '../../../components';
import { EventEntity, EventInput } from '../../../models/gql/graphql';

import { useMutation, useQuery } from '@apollo/client';
import { forEach } from 'lodash';
import { UPDATE_EVENT } from '../../../graphql/mutations/update-event.graphql';
import { GET_EVENT } from '../../../graphql/queries/event.graphql';
import { GET_ME } from '../../../graphql/queries/user.graphql';
import { useDispo } from '../../../hooks';
import {
  convertDate,
  convertDateToSimple,
  convertTimeForGraphql,
  initDate,
  initTime,
  isComingDate,
} from '../../../utils';

import { addDays, formatISO } from 'date-fns';
import { useAppStore } from '../../../store';
import { checkForbiddenWordInputted, checkForbiddenWordPasted, stripTag } from '../../../utils/word';
import succesIcon from '../../../assets/icons/circle-check.svg';
import {
  addHourToDate,
  convertISO8601UTCtoLocalwZ,
  prepareDateForSave,
  isSameDate,
  containsMilliseconds,
} from '../../../utils/dateFormat';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../config';
import { editEventToCalendar } from '../../../hooks/synchroniseCalendar';

const StepInfo: React.FC<IStepProps> = ({ id, duplicate }) => {
  const history = useHistory();
  const methods = useForm<EventInput>();
  const [presentToast] = useIonToast();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    resetField,
  } = methods;
  const defautlHour = initTime(new Date(), 1, 0);
  const defautlHour2 = initTime(new Date(), 2, 0);
  const {
    RootStore: { forbiddenWords },
  } = useAppStore();

  const [description, setDescription] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [date, setDate] = useState<string>(initDate(new Date(), 0));
  const [dateFin, setDateFin] = useState<string>('');
  const [dateEnd, setDateEnd] = useState<string>(date);
  const [address, setAddress] = useState<IAddress>();
  const [timeStart, setTimeStart] = useState<string>(defautlHour);
  const [timeEnd, setTimeEnd] = useState<string>(defautlHour2);
  const [isSeveralDay, setIsSeveralDay] = useState<boolean>(false);
  const { data: dataMe } = useQuery(GET_ME);
  const { setDispo } = useDispo();
  const yearsValues = [new Date().getFullYear(), new Date().getFullYear() + 1];
  const minutesValues = [0, 15, 30, 45];
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [titleChanged, setTitleChanged] = useState<boolean>(false);
  const [errorDateBefore, setErrorDateBefore] = useState<boolean>(false);
  const [errorDatePassed, setErrorDatePassed] = useState<boolean>(false);
  const [errorHourBefore, setErrorHourBefore] = useState<boolean>(false);

  const { data: dispoData, loading: loadingEvent } = useQuery(GET_EVENT, {
    variables: { id: id ? id : '0' },
  });
  const dispo = useMemo<EventEntity>(() => {
    if (!loadingEvent) {
      setDescription(dispoData?.event?.data?.attributes?.description as string);
      setTitle(dispoData?.event?.data?.attributes?.name as string);
      const dateStartEvent = dispoData?.event?.data?.attributes?.eventDate
        ? convertDate(dispoData?.event.data.attributes?.eventDate)
        : '';
      const dateEndEvent = dispoData?.event?.data?.attributes?.eventEndDate
        ? convertDate(dispoData?.event?.data?.attributes?.eventEndDate)
        : '';
      if (dateStartEvent && dateEndEvent && dateStartEvent !== dateEndEvent) {
        setIsSeveralDay(true);
      }

      return dispoData?.event?.data;
    }
    return [] as any;
  }, [loadingEvent, dispoData]);

  if (!id) {
    register('description', { required: true });
    register('addressCity', { required: false });
    register('addressCountry', { required: true });
    register('addressLine', { required: true });
    register('addressLat', { required: true });
    register('addressLng', { required: true });
    register('eventDate', {
      value: initDate(new Date(), 1),
      required: 'La date est requise',
    });
    register('startHour', {
      value: initTime(new Date(), 1, 0),
      required: 'heure de début requise',
    });
    register('endHour', {
      value: initTime(new Date(), 2, 0),
      required: 'heure de fin requise',
    });
  }

  useEffect(() => {
    if (!isSeveralDay) {
      register('eventEndDate', { required: false });
      setDateEnd('');
      setDateFin('');
    } else {
      register('eventEndDate', {
        value: initDate(new Date(), 1),
        required: 'La date de fin est requise',
      });
      setDateEnd(initDate(new Date(), 1));
      setDateFin(initDate(new Date(), 1));
    }
  }, [isSeveralDay]);
  const [saveEvent, { loading: loadingSaveEvent }] = useMutation(UPDATE_EVENT);

  const updateEvent = async (dispoId: string) => {
    try {
      const d = new Date(dateEnd);
      if (timeEnd) {
        const hs = timeEnd.split('T')[1]?.split(':');
        if (Array.isArray(hs)) {
          d.setHours(parseInt(hs[0], 10));
          d.setMinutes(parseInt(hs[1], 10));
          d.setSeconds(0);
        }
      }

      let eventData = await saveEvent({
        variables: {
          id: dispoId,
          data: {
            name: title,
            description: description,
            eventDate: convertDateToSimple(new Date(date)),
            eventEndDate: convertDateToSimple(new Date(!!!dateEnd ? date : d)),
            startHour: convertTimeForGraphql(prepareDateForSave(timeStart)),
            endHour: convertTimeForGraphql(prepareDateForSave(timeEnd)),
            ...address,
          },
        },
      });

      if (eventData?.data?.updateEvent?.data) {
        editEventToCalendar(eventData.data.updateEvent.data);
      }
      setOpenToast(true);
      await updateMessage();
      history.push(`/main/dispos/${dispoId}/edit`);
    } catch (e) {
      console.log(e);
    }
  };

  //HandleChange function into form
  const onSubmit = (data: EventInput) => {
    console.log({ errors, data });
    const d = new Date(dateEnd);
    if (timeEnd) {
      const hs = timeEnd.split('T')[1]?.split(':');
      if (Array.isArray(hs)) {
        d.setHours(parseInt(hs[0], 10));
        d.setMinutes(parseInt(hs[1], 10));
        d.setSeconds(0);
      }
    }
    const newData = {
      ...data,
      endHour: prepareDateForSave(timeEnd),
      startHour: prepareDateForSave(timeStart),
    };
    setDispo({ ...newData, creator: dataMe?.me?.id });
    if (duplicate) {
      history.push({
        pathname: `/main/dispos/${id}/duplicate`,
        search: `?${new URLSearchParams({
          page: 'step-invitation',
        }).toString()}`,
      });
    } else {
      if (!id) {
        resetData();
        history.push('/main/home/create-dispo/step-invitation');
      } else {
        updateEvent(id);
      }
    }
  };
  const handleDescriptionChange = (value: string) => {
    let text = value;
    if (text) {
      const forbidden = checkForbiddenWordInputted(forbiddenWords, text);
      if (forbidden) {
        // remove
        var lastIndex = stripTag(text).trim().lastIndexOf(' ');
        text = stripTag(text).substring(0, lastIndex) + ' ';
        // set error msg
        presentToast('Un mot interdit a été détécté et a été supprimé de votre entrée', 1000);
      }
    }
    setDescription(text);
    setValue('description', text);
  };

  const handleTitleChange = (value: any) => {
    let text = value.detail.value;
    const forbidden = checkForbiddenWordInputted(forbiddenWords, text);
    if (forbidden) {
      // remove
      var lastIndex = text.trim().lastIndexOf(' ');
      text = text.trim().substring(0, lastIndex) + ' ';
      // set error msg
      presentToast('Un mot interdit a été détécté et a été supprimé de votre entrée', 1000);
    }
    setTitleChanged(true);
    setTitle(text);
    setValue('name', text);
  };

  const handleSelectAddress = (val: IAddress) => {
    setAddress({
      addressLine: val.addressLine,
      addressCity: val.addressCity,
      addressZipcode: val.addressZipcode,
      addressCountry: val.addressCountry,
      addressLat: val.addressLat,
      addressLng: val.addressLng,
    });
    forEach(val, (value, key: any) => {
      setValue(key, value);
    });
  };
  const handleChangeDate = (val: any) => {
    const dateEvent = addHourToDate(val.detail ? val.detail.value : val);
    if (isComingDate(dateEvent, false)) {
      setValue('eventDate', dateEvent.toString());
      setDate(dateEvent.toISOString());
      if (!isSeveralDay) {
        setValue('eventEndDate', dateEvent.toString());
        setDateEnd(val);
        clearErrors('eventEndDate');
        setErrorDateBefore(false);
        setErrorDatePassed(false);
      }
      clearErrors('eventDate');
      setErrorDatePassed(false);
    } else {
      setError('eventDate', {
        type: 'customEventDate',
        message: 'Veuillez indiquer une date future',
      });
      setErrorDatePassed(true);
    }
  };

  const handleChangeDateSecond = (val: any) => {
    const dateEvent = new Date(val);
    const dateSecond = new Date(dateEnd);
    dateEvent.setHours(0, 0, 0, 0);
    dateSecond.setHours(0, 0, 0, 0);
    setValue('eventDate', val);
    setDate(val);
    if (isComingDate(dateEvent, false)) {
      if (dateEvent.getTime() <= dateSecond.getTime()) {
        clearErrors('eventDate');
        clearErrors('eventEndDate');
        setErrorDateBefore(false);
        if (isSameDate(dateEvent, dateSecond) && isSeveralDay) {
          setTimeEnd(addOnHour(timeStart));
        }
        if (dateEvent.getTime() < dateSecond.getTime()) {
          clearErrors('endHour');
          setErrorHourBefore(false);
        }
      } else if (isSeveralDay) {
        setError('eventDate', {
          type: 'customEventDate',
          message: 'La date de début devrait être avant la date de fin',
        });
        setErrorDateBefore(true);
      }
      setErrorDatePassed(false);
    } else if (isSeveralDay) {
      setError('eventDate', {
        type: 'customEventDateFuture',
        message: 'Veuillez indiquer une date future',
      });
      setErrorDatePassed(true);
    }
  };

  const handleChangeDateEnd = (val: any) => {
    if (val) {
      const dateEvent = new Date(val);
      const dateFirst = new Date(date);
      dateEvent.setHours(0, 0, 0, 0);
      dateFirst.setHours(0, 0, 0, 0);
      setValue('eventEndDate', val);
      setDateEnd(val);
      setDateFin(val);
      if (isComingDate(dateEvent, false) && !isSameDate(dateEvent, dateFirst)) {
        if (dateEvent.getTime() >= dateFirst.getTime()) {
          clearErrors('eventDate');
          clearErrors('eventEndDate');
          setErrorDateBefore(false);
          if (
            isSameDate(dateEvent, dateFirst) &&
            isSeveralDay &&
            convertDateToLocalZ(timeStart) >= convertDateToLocalZ(timeEnd)
          ) {
            setTimeEnd(addOnHour(timeStart));
          }
          if (dateEvent.getTime() > dateFirst.getTime()) {
            clearErrors('endHour');
            setErrorHourBefore(false);
          }
        } else if (isSeveralDay) {
          setError('eventEndDate', {
            type: 'customEventDateEnd',
            message: 'La date de fin devrait être après la date de début',
          });
          setErrorDateBefore(true);
        }
        setErrorDatePassed(false);
      } else if (isSeveralDay) {
        setError('eventEndDate', {
          type: 'customEventDateEndFuture',
          message: 'Veuillez indiquer une date future',
        });
        setErrorDatePassed(true);
      }
    } else {
      setValue('eventEndDate', '');
      setDateEnd('');
    }
  };

  useEffect(() => {
    handleChangeDateEnd(dateFin);
  }, [dateFin]);

  const handleChangeDay = (isChecked: boolean) => {
    if (!isChecked) {
      setTimeEnd(defautlHour2);
      setTimeStart(defautlHour);
      handleChangeDateEnd('');
    } else {
      setDateEnd(date);
    }
    setIsSeveralDay(isChecked);
  };

  const setErrorEndTime = ({ dateS, dateE, timeS, timeE }: any) => {
    const dateSWithoutHour = new Date(dateS);
    const dateEWithoutHour = new Date(dateE);
    dateSWithoutHour.setHours(0, 0, 0, 0);
    dateEWithoutHour.setHours(0, 0, 0, 0);
    console.log({
      dateSWithoutHour,
      dateEWithoutHour,
      timeS: { timeS, iso: new Date(timeS).getTime() },
      timeE: { timeE, iso: new Date(timeE).getTime() },
    });
    if (dateSWithoutHour.getTime() === dateEWithoutHour.getTime()) {
      console.log({
        timeS: { timeS, iso: new Date(timeS).getTime() },
        timeE: { timeE, iso: new Date(timeE).getTime() },
      });
      if (
        (!id && new Date(timeE).getTime() < new Date(timeS).getTime()) ||
        (id && convertDateToLocalZ(timeE) < convertDateToLocalZ(timeS))
      ) {
        console.log('errr');
        setError('endHour', {
          type: 'custom1',
          message: "L'heure de fin devrait être après l'heure de début",
        });
        setErrorHourBefore(true);
      } else {
        clearErrors('endHour');
        setErrorHourBefore(false);
      }
    }
  };

  const headerTitle = duplicate ? 'Duplication de ta dispo' : !id ? `Création d'une dispo` : 'Editer les informations';

  const resetData = () => {
    setTitle('');
    setDescription('');
    setDate(initDate(new Date(), 1));
    setDateFin('');
    setDateEnd(date);
    setAddress(undefined);
    setTimeStart(defautlHour);
    setTimeEnd(defautlHour2);
    setIsSeveralDay(false);
    reset();
    resetField('description');
  };

  const addTimeToDate = (time?: string) => {
    const timeAdd = time?.split(':');
    console.log({ time, timeAdd });
    const date = new Date();
    date.setHours(parseInt(timeAdd ? timeAdd[0] : ''));
    date.setMinutes(parseInt(timeAdd ? timeAdd[1] : ''));
    return date instanceof Date && !isNaN(date as any) ? formatISO(date) : '00:00';
  };

  useEffect(() => {
    if (id && dispo) {
      setTimeout(() => {
        if (dispo.attributes?.beginningDateTime) {
          setTimeStart(convertISO8601UTCtoLocalwZ(dispo.attributes?.beginningDateTime));
        }

        if (dispo.attributes?.expirationDateTime) {
          setTimeEnd(convertISO8601UTCtoLocalwZ(dispo.attributes?.expirationDateTime));
          setDateEnd(dispo.attributes?.expirationDateTime);
          setDateFin(dispo.attributes?.expirationDateTime);
        }
        if (dispo.attributes?.eventDate) {
          setDate(convertISO8601UTCtoLocalwZ(dispo.attributes?.eventDate));
        }
        setValue('name', dispo.attributes?.name);
        register('startHour', {
          value: addTimeToDate(
            dispo && 'attributes' in dispo && dispo?.attributes ? dispo.attributes.startHour : ''
          ).toString(),
          required: 'heure de début requise',
        });
        register('endHour', {
          value: addTimeToDate(
            dispo && 'attributes' in dispo && dispo?.attributes ? dispo.attributes.endHour : ''
          ).toString(),
          required: 'heure de fin requise',
        });
      }, 1000);
    }
  }, [id, dispo]);

  const handlePastedEvent = useCallback(
    (field: any, e: any) => {
      let pasted: string;
      if (!e?.clipboardData) {
        if (e.target.value) {
          pasted = e.target.value; // whole text
        } else {
          pasted = e.target.textContent;
        }
      } else {
        pasted = stripTag(e?.clipboardData?.getData('text'));
      }
      const newPastedArr = stripTag(pasted)
        .split(' ')
        .filter((text: string) => {
          return !checkForbiddenWordPasted(forbiddenWords, text);
        });
      if (stripTag(pasted).split(' ').length !== newPastedArr.length) {
        const changed = newPastedArr.join(' ');
        setTimeout(() => {
          setValue(field, methods.getValues(field)?.replace(pasted, changed));
          if (field === 'description') {
            setDescription(methods.getValues(field)?.replace(pasted, changed));
          }
          presentToast('Des mots interdits ont été détéctés et a été supprimé de votre entrée', 1000);
        }, 100);
      }
    },
    [forbiddenWords, setDescription]
  );

  const initialiseDateError = () => {
    setErrorDatePassed(false);
    setErrorDateBefore(false);
    setErrorHourBefore(false);
  };

  useEffect(() => {
    if (!isSeveralDay && isSameDate(date, new Date()) && !isComingDate(new Date(timeStart), true)) {
      setTimeStart(defautlHour);
      initialiseDateError();
    } else if (isSeveralDay && isSameDate(date, dateEnd) && !isComingDate(new Date(timeStart), false)) {
      setTimeStart(defautlHour);
      initialiseDateError();
    }
    if (isSeveralDay && new Date(date).getTime() > new Date(dateEnd).getTime()) {
      setDateEnd(addDays(new Date(date), 1).toISOString());
      setDateFin(addDays(new Date(date), 1).toISOString());
      clearErrors('eventEndDate');
      initialiseDateError();
    }

    if (
      (isSameDate(date, dateFin) || !isSeveralDay) &&
      convertDateToLocalZ(timeStart) >= convertDateToLocalZ(timeEnd)
    ) {
      clearErrors('endHour');
      initialiseDateError();
      setTimeEnd(addOnHour(timeStart));
      if (!isSeveralDay) {
        if (id) {
          setDateFin(date);
          setDateEnd(date);
        } else {
          setDateFin(addOnHour(timeStart));
          setDateEnd(addOnHour(timeStart));
        }
      }
    }
  }, [date, dateEnd, timeEnd, dateFin, timeStart, isSeveralDay]);

  const generateUniqueId = () => {
    return Math.floor(Math.random() * 1000000).toString();
  };

  const addOnHour = (date: any) => {
    const originalDate = new Date(date);
    const newDate = new Date(originalDate.getTime() + 60 * 60 * 1000);
    return convertISO8601UTCtoLocalwZ(newDate.toISOString());
  };

  const convertDateToLocalZ = (date: any) => {
    if (isISO8601DateString(date)) {
      return new Date(date).getTime();
    } else {
      return new Date(containsMilliseconds(date) ? date : date + '.000Z').getTime();
    }
  };

  const isISO8601DateString = (dateString: any) => {
    try {
      const date = new Date(dateString);
      return !isNaN(date.getTime()) && date.toISOString() === dateString;
    } catch (error) {
      return false;
    }
  };

  const updateMessage = async () => {
    if (dispo) {
      try {
        const docRef = doc(db, 'messages', `dispo_${dispo.id}`);
        await updateDoc(docRef, {
          dispoName: title,
        });
      } catch (e: any) {
        console.log('Error: ', e.message);
      }
    }
  };
  const checkingValue = (valueStart: any, valueEnd: any) => {
    const dateStart = new Date(valueStart);
    const dateEnd = new Date(valueEnd);
    dateEnd.setHours(0, 0, 0, 0);
    dateStart.setHours(0, 0, 0, 0);
    return dateStart.getTime() <= dateEnd.getTime();
  };

  return (
    <FormProvider {...methods}>
      <IonPage>
        <Header title={headerTitle} />
        {(!id || duplicate) && <StepperCount length={5} numberStep={1}></StepperCount>}
        <IonContent>
          <CardCustom margin={true}>
            {!id && (
              <IonText color="dark" className="font-outfit">
                <h2>Infos sur ta dispo</h2>
              </IonText>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              <InputCustom
                onPaste={(e: any) => handlePastedEvent('name', e)}
                handleChange={handleTitleChange}
                label="Titre"
                placeholder="Soirée patinoire"
                attr="name"
                value={
                  title
                    ? title
                    : !titleChanged && dispo && 'attributes' in dispo && dispo?.attributes
                    ? dispo.attributes.name
                    : ''
                }
              />
              <RichEditor
                onPaste={(e: any) => handlePastedEvent('description', e)}
                handleChange={(value: string) => handleDescriptionChange(value)}
                value={
                  description
                    ? description
                    : dispo && 'attributes' in dispo && dispo?.attributes
                    ? dispo.attributes.description
                    : ''
                }
                label="Description"
                placeholder="Présente ta dispo"
              />
              {errors.description && <p className="errorDescription">La description est requise</p>}

              {!isSeveralDay && (
                <>
                  <AppDatepicker
                    displayFormat="dd/MM/yyyy"
                    label="Date"
                    presentation="date"
                    min={new Date()}
                    name="dateDisponibility"
                    initialValue={
                      date || (dispo && 'attributes' in dispo && dispo?.attributes ? dispo.attributes.eventDate : '')
                    }
                    onChange={handleChangeDate}
                    icon={{ icon: calendarOutline }}
                    showValidateButton={true}
                    yearValues={yearsValues}
                  />
                  {errors.eventDate && <p className="errorDescription">{errors.eventDate.message as string}</p>}
                </>
              )}

              <IonCheckbox
                mode="md"
                labelPlacement="end"
                onIonChange={(e: any) => handleChangeDay(e.detail.checked)}
                className="ion-margin-top"
                color="secondary"
                checked={isSeveralDay}
              >
                <IonLabel className="font-outfit label-checkbox">Ma dispo dure plusieurs jours</IonLabel>
              </IonCheckbox>

              {!isSeveralDay && (
                <DoubleTimePicker
                  typeFirst="time"
                  typeSecond="time"
                  min={isSameDate(date, new Date()) ? convertISO8601UTCtoLocalwZ(new Date().toISOString()) : undefined}
                  handleChangeStart={(value) => {
                    setTimeStart(value);
                    setValue('startHour', value);
                    const dateE = date;
                    setErrorEndTime({
                      dateS: date,
                      dateE,
                      timeS: value,
                      timeE: timeEnd,
                    });
                  }}
                  handleChangeEnd={(value) => {
                    setTimeEnd(value);
                    setValue('endHour', value);
                    const dateE = date;
                    setErrorEndTime({
                      dateS: date,
                      dateE,
                      timeS: timeStart,
                      timeE: value,
                    });
                  }}
                  valueStart={timeStart}
                  valueEnd={timeEnd}
                  labelStart="Début"
                  labelEnd="Fin"
                  idStart={generateUniqueId()}
                  idEnd={generateUniqueId()}
                  isErrorStart={errors.startHour}
                  isErrorEnd={!isSeveralDay && errors.endHour}
                  iconFirst={calendarOutline}
                  iconSecond={timeOutline}
                  label="Créneau"
                  yearsValues={yearsValues}
                  minutesValues={minutesValues}
                  oneDay={!isSeveralDay}
                />
              )}

              {isSeveralDay && (
                <DoubleTimePicker
                  typeFirst="date"
                  typeSecond="time"
                  min={convertISO8601UTCtoLocalwZ(new Date().toISOString())}
                  handleChangeStart={handleChangeDateSecond}
                  handleChangeEnd={(value) => {
                    setTimeStart(value);
                    setValue('startHour', value);
                    setErrorEndTime({
                      dateS: date,
                      dateE: dateEnd,
                      timeS: value,
                      timeE: timeEnd,
                    });
                  }}
                  valueStart={date}
                  valueEnd={timeStart}
                  labelStart="Date"
                  labelEnd="Heure"
                  idStart={generateUniqueId()}
                  idEnd={generateUniqueId()}
                  isErrorStart={errors.eventDate}
                  isErrorEnd={errors.startHour}
                  iconFirst={calendarOutline}
                  iconSecond={timeOutline}
                  label="Début"
                  yearsValues={yearsValues}
                  minutesValues={minutesValues}
                />
              )}

              {isSeveralDay && (
                <DoubleTimePicker
                  typeFirst="date"
                  typeSecond="time"
                  min={convertISO8601UTCtoLocalwZ(new Date(date).toISOString())}
                  handleChangeStart={handleChangeDateEnd}
                  handleChangeEnd={(value) => {
                    setTimeEnd(value);
                    setValue('endHour', value);
                    setErrorEndTime({
                      dateS: date,
                      dateE: dateEnd,
                      timeS: timeStart,
                      timeE: value,
                    });
                  }}
                  valueStart={dateEnd}
                  valueEnd={timeEnd}
                  labelStart="Date"
                  labelEnd="Heure"
                  idStart={generateUniqueId()}
                  idEnd={generateUniqueId()}
                  isErrorStart={errors.eventEndDate}
                  isErrorEnd={isSeveralDay && errors.endHour}
                  iconFirst={calendarOutline}
                  iconSecond={timeOutline}
                  label="Fin"
                  yearsValues={yearsValues}
                  minutesValues={minutesValues}
                />
              )}

              <AppSearchAddressInput
                autocompletionRequest={{
                  types: [],
                  componentRestrictions: { country: ['fr'] },
                }}
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                label="Adresse"
                name="address"
                onSelect={(selectedAddress: IAddress) => handleSelectAddress(selectedAddress)}
                placeholder="7 rue de la libération, 75011 Paris"
                initialAddress={
                  address
                    ? address
                    : dispo && 'attributes' in dispo && dispo?.attributes
                    ? {
                        addressLine: dispo?.attributes.addressLine,
                        addressCity: dispo?.attributes.addressCity,
                        addressZipcode: dispo?.attributes.addressZipcode,
                        addressCountry: dispo?.attributes.addressCountry,
                        addressLat: dispo?.attributes.addressLat,
                        addressLng: dispo?.attributes.addressLng,
                      }
                    : undefined
                }
                icon={{ icon: locationOutline }}
                address={address}
              />
              {errors.addressLine && <p className="errorDescription">L'adresse est requise</p>}

              <IonButton
                type="submit"
                className="ion-margin-top not-transorm-text text-vw-4"
                expand="block"
                color="primary"
                disabled={loadingSaveEvent || errorDateBefore || errorDatePassed || errorHourBefore}
              >
                {!id || duplicate ? 'Continuer' : 'Sauvegarder les modifications'}
              </IonButton>
            </form>
          </CardCustom>
        </IonContent>
        <IonToast
          isOpen={openToast}
          message="Modifications effectuées avec succès"
          duration={5000}
          icon={succesIcon}
        ></IonToast>
      </IonPage>
    </FormProvider>
  );
};

export default StepInfo;
