import { useContext } from 'react';
import { AppReducerContext } from '../store/';

export const useVideo = () => {
  const {
    state: { videoPlayed },
    dispatch,
  } = useContext(AppReducerContext);

  const setVideoPlayed = (videoId: string) => {
    dispatch({ type: 'SET_VIDEO', payload: videoId });
  };

  return {
    videoPlayed,
    setVideoPlayed,
  };
};
