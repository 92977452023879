import { useMutation, useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { LIKE_POST, UN_LIKE_POST } from '../graphql/mutations/post.graphql';
import { GET_POST, GET_POSTS } from '../graphql/queries/getPosts.graphql';
import { GET_MY_LIKE } from '../graphql/queries/user.graphql';
import { PostEntity, PostLike } from '../models/gql/graphql';
import { getUserId } from '../utils';
import { useUsersIdSubscription } from './useUserFollower';

export const usePosts = (userId?: string | null, type?: string) => {
  const myFollowIds = useUsersIdSubscription(userId || '0');

  const makeAuthorFilters = (data: any) => {
    const filterType = Array.isArray(data) ? 'in' : 'eq';
    return {
      author: {
        id: {
          [filterType]: data,
        },
      },
    };
  };

  let filters = type === 'myPost' ? makeAuthorFilters(userId) : makeAuthorFilters(myFollowIds);

  const { data, loading, refetch } = useQuery(GET_POSTS, {
    variables: { filters, pagination: { limit: 100 }, sort: ['createdAt:desc'] },
  });

  const postList: PostEntity[] = useMemo(() => {
    return (!loading && data ? data.posts?.data || [] : []) as PostEntity[];
  }, [data, loading]);

  return {
    posts: postList,
    refreshPost: refetch,
    loading: loading,
  };
};

export const usePost = (postId: string) => {
  const { data, loading, refetch } = useQuery(GET_POST, {
    variables: {
      id: postId,
    },
  });

  const post: PostEntity = useMemo(() => {
    return (!loading && data ? data.post?.data : {}) as PostEntity;
  }, [data, loading]);

  return {
    post: post,
    refreshPost: refetch,
    loading,
  };
};

export const useLikePost = (id?: string, refetchLike?: () => void) => {
  const [likePost, { loading: loadingLike }] = useMutation(LIKE_POST);
  const [unlikePost, { loading: loadingUnlike }] = useMutation(UN_LIKE_POST);
  const [messageError, setMessageError] = useState<string>('');

  const like = async (postLike?: PostLike) => {
    try {
      if (!postLike) {
        await likePost({
          variables: {
            data: {
              publication: id,
              user: getUserId(),
              publishedAt: new Date(),
            },
          },
        });
      } else {
        await unlikePost({
          variables: { id: postLike.idReaction || '0' },
        });
      }
      refetchLike && refetchLike();
    } catch (err: any) {
      setMessageError(err.message);
      throw err;
      return;
    }
  };

  return { like, messageError, loadingLike, loadingUnlike };
};

export const useMyLike = () => {
  const { data: likes, loading: loadingGetLike, refetch: refetchLike } = useQuery(GET_MY_LIKE);
  const myLikes = useMemo(() => {
    return likes && !loadingGetLike ? likes.myLikes?.posts : [];
  }, [likes, loadingGetLike]);

  return { refetchLike, myLikes };
};
