import { IonPage } from '@ionic/react';
import { SplashScreen as SP } from '@capacitor/splash-screen';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { isAuthenticated } from '../../utils/auth';
import './style.css';

const SplashScreen: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    if (!isAuthenticated()) {
      history.replace('/on-boarding');
    } else {
      history.replace('/signup/verify-config');
    }
    setTimeout(() => {
      SP.hide({
        fadeOutDuration: 3000,
      });
      SP.hide(); // hack
    }, 5000);
  }, []);
  return (
    <IonPage className="">
      <div className="container">
        <img src="images/logo.svg" alt="Logo tedispo" />
      </div>
    </IonPage>
  );
};

export default SplashScreen;
