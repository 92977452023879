import {
  RefresherEventDetail,
  IonRefresher,
  IonRefresherContent,
  useIonViewDidEnter,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react';
import { CardDispo, CardLoader, SegmentCustom } from '../../../../components';
import {
  EventEntity,
  Enum_Eventuserparticipation_Status,
  EventUserParticipationEntity,
  Enum_Event_Type,
} from '../../../../models/gql/graphql';
import { GET_EVENTS } from '../../../../graphql/queries/events.graphql';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppStore } from '../../../../store';
interface IParams {
  id: string;
}
enum Enum_filterType {
  future = 'future',
  past = 'past',
  waiting = 'waiting',
}

interface IEventData {
  future?: {
    page?: number;
    nbPages?: number;
    data: any[];
  };
  waiting?: {
    page?: number;
    nbPages?: number;
    data: any[];
  };
  past?: {
    page?: number;
    nbPages?: number;
    data: any[];
  };
}
const NB_EVENT_PER_PAGE = 20;

const ListDispoAccepted: React.FC<IParams> = ({ id }) => {
  const {
    RootStore: { weatherInfo, loadWeatherQuery },
  } = useAppStore();
  const [dispos, setDispos] = useState<IEventData>({
    future: {
      page: 1,
      nbPages: 0,
      data: [],
    },
    past: {
      page: 1,
      nbPages: 0,
      data: [],
    },
    waiting: {
      page: 1,
      nbPages: 0,
      data: [],
    },
  });

  const getTypeStatus: any = (type: Enum_Eventuserparticipation_Status) => {
    return {
      variables: {
        filters: {
          participations: {
            user: {
              id: {
                eq: id,
              },
            },
            status: {
              eq: type,
            },
          },
        },
        sort: ['eventDate:desc'],
      },
    };
  };
  const [loadAcceptedEventsQuery, { loading, refetch: refetchAccepted }] = useLazyQuery(
    GET_EVENTS,
    getTypeStatus(Enum_Eventuserparticipation_Status.Accepted)
  );

  const [loadWaitingEventsQuery, { loading: loadingWaiting, refetch: refetchWaiting }] = useLazyQuery(
    GET_EVENTS,
    getTypeStatus(Enum_Eventuserparticipation_Status.Waiting)
  );

  const loadAcceptedEvents = async (page = 1, expired: boolean) => {
    const expiredQuery = expired
      ? { expired: { eq: true } }
      : { or: [{ expired: { null: true } }, { expired: { eq: false } }] };
    let { variables } = getTypeStatus(Enum_Eventuserparticipation_Status.Accepted);
    variables.filters = { ...variables.filters, ...expiredQuery };

    const { data } = await loadAcceptedEventsQuery({
      variables: {
        ...variables,
        pagination: {
          pageSize: NB_EVENT_PER_PAGE,
          page,
        },
      },
    });
    if (data?.events?.data.length) {
      if (!expired) {
        // weather
        loadWeatherQuery({
          variables: {
            filters: {
              dispo_id: { in: data.events?.data.map((i: any) => parseInt(i.id, 10)) },
            },
          },
        });
      }
      setDispos((d: any) => {
        if (expired) {
          // past
          return {
            ...d,
            past: {
              page: data?.events?.meta?.pagination?.page,
              nbPages: data?.events?.meta?.pagination?.pageCount,
              data: page <= 1 ? data?.events?.data : [...d?.past?.data!, ...data?.events?.data!],
            },
          };
        }
        return {
          ...d,
          future: {
            page: data?.events?.meta?.pagination?.page,
            nbPages: data?.events?.meta?.pagination?.pageCount,
            data: page <= 1 ? data?.events?.data : [...d.future?.data!, ...data?.events?.data!],
          },
        };
      });
    }
  };
  const loadWaitingEvents = async (page = 1) => {
    const { variables } = getTypeStatus(Enum_Eventuserparticipation_Status.Waiting);
    const { data } = await loadWaitingEventsQuery({
      variables: {
        ...variables,
        pagination: {
          pageSize: NB_EVENT_PER_PAGE,
          page,
        },
      },
    });
    if (data?.events?.data.length) {
      setDispos((d: any) => {
        return {
          ...d,
          waiting: {
            page: data?.events?.meta?.pagination?.page,
            nbPages: data?.events?.meta?.pagination?.pageCount,
            data: page === 1 ? data?.events?.data : [...d?.waiting?.data!, ...data?.events?.data!],
          },
        };
      });
    }
  };

  const [segmentValue, setSegmentValue] = useState<Enum_filterType>(Enum_filterType.future);
  const segmentData: IdNameEntity[] = [
    {
      id: Enum_filterType.future,
      name: 'A venir',
    },
    {
      id: Enum_filterType.waiting,
      name: 'En attente',
    },
    {
      id: Enum_filterType.past,
      name: 'Passés',
    },
  ];

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    console.log('handleRefresh');
    setDispos((d: any) => {
      d[segmentValue] = {
        page: 1,
        nbPages: 0,
        data: [],
      };
      return d;
    });
    if (segmentValue === 'future') {
      await loadAcceptedEvents(1, false); // future
      await refetchAccepted();
    } else if (segmentValue === 'past') {
      await loadAcceptedEvents(1, true); // past
      await refetchAccepted();
    } else if (segmentValue === 'waiting') {
      await loadWaitingEvents(1);
      await refetchWaiting();
    }
    event.detail.complete();
  };
  const loadMore = useCallback(
    async (e: any) => {
      const currentPage = dispos[segmentValue]?.page;
      //const currentPage = dispos[segmentValue].page;
      setDispos((d: any) => ({
        ...d,
        [segmentValue]: {
          ...d[segmentValue],
          page: (currentPage || 0) + 1,
        },
      }));
      if (segmentValue === Enum_filterType.future) {
        await loadAcceptedEvents((currentPage || 0) + 1, false); // future
      } else if (segmentValue === Enum_filterType.past) {
        await loadAcceptedEvents((currentPage || 0) + 1, true);
      } else if (segmentValue === Enum_filterType.waiting) {
        await loadWaitingEvents((currentPage || 0) + 1);
      }
      e.target.complete();
      //loadAcceptedEvents()
    },
    [dispos, segmentValue]
  );

  useEffect(() => {
    if (segmentValue === Enum_filterType.future) {
      if (!dispos.future?.data?.length) {
        loadAcceptedEvents(1, false);
      }
    } else if (segmentValue === Enum_filterType.past) {
      if (!dispos.past?.data?.length) {
        loadAcceptedEvents(1, true);
      }
    } else if (segmentValue === Enum_filterType.waiting) {
      if (!dispos.waiting?.data?.length) {
        loadWaitingEvents(1);
      }
    }
  }, [segmentValue]);

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <SegmentCustom
        values={segmentData}
        defaultValue={segmentValue}
        onChange={(value) => setSegmentValue(value.id as Enum_filterType)}
      ></SegmentCustom>

      {segmentValue === Enum_filterType.future && (
        <>
          {loading && dispos?.future?.page && dispos?.future?.page <= 1 ? (
            <CardLoader />
          ) : (
            dispos?.future?.data?.map((event, index) => (
              <CardDispo
                key={index}
                dispo={event}
                withTag={true}
                weatherInfo={weatherInfo[event.id]}
                showCreator={true}
              ></CardDispo>
            ))
          )}
        </>
      )}
      {segmentValue === Enum_filterType.past && (
        <>
          {loading && dispos?.past?.page && dispos?.past?.page <= 1 ? (
            <CardLoader />
          ) : (
            dispos?.past?.data?.map((event, index) => (
              <CardDispo key={index} dispo={event} withTag={true} showCreator={true}></CardDispo>
            ))
          )}
        </>
      )}
      {segmentValue === Enum_filterType.waiting && (
        <>
          {loadingWaiting && dispos?.waiting?.page && dispos?.waiting?.page <= 1 ? (
            <CardLoader />
          ) : (
            dispos?.waiting?.data?.map((event, index) => (
              <CardDispo
                key={index}
                dispo={event}
                weatherInfo={weatherInfo}
                withTag={true}
                showCreator={true}
              ></CardDispo>
            ))
          )}
        </>
      )}
      <IonInfiniteScroll
        onIonInfinite={(ev) => {
          console.log('load more', ev);
          loadMore(ev);
        }}
      >
        <IonInfiniteScrollContent></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </>
  );
};

export default ListDispoAccepted;
