import { IonSelect, IonSelectOption } from '@ionic/react';
import { useFormContext } from 'react-hook-form';
import './style.css';

type OptionSelection = {
  value: string;
  label: string;
};

interface IInput {
  label: string;
  placeholder?: string;
  options: OptionSelection[];
  attr?: string;
  defaultValue?: string;
  handleChange?: (e: any) => void;
}

const SelectCustom: React.FC<IInput> = ({ label, placeholder, options, attr, defaultValue, handleChange }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="ion-margin-bottom">
      <p className="custom-label font-outfit">{label}</p>
      <IonSelect
        {...register(attr as string, { required: true })}
        className="custom-input font-outfit"
        label-placement="floating"
        interface="action-sheet"
        placeholder={placeholder}
        value={defaultValue}
        onIonChange={handleChange}
      >
        {options.map(({ value, label }, index) => {
          return (
            <IonSelectOption className="font-outfit" key={index} value={value}>
              {label}
            </IonSelectOption>
          );
        })}
      </IonSelect>
      {errors[attr as string] && <p className="errorDescription">{label} est requis</p>}
    </div>
  );
};

export default SelectCustom;
