import { StorageUtils } from '../../../utils';
import { Enum_segment_home, stateType } from '../type';

export const initialstate: stateType = {
  dispo: StorageUtils.get('dispoDraft') ? JSON.parse(StorageUtils.get('dispoDraft')!) : {},
  imageUrl: '',
  imageSelecteds: [],
  dispoView: {},
  notifications: [],
  searchQuery: '',
  newMessage: 0,
  toasts: [],
  db: null,
  canceledEvent: false,
  redirectHome: Enum_segment_home.dispos,
  fullSlider: false,
  videoPlayed: '',
  reloadHome: false,
};
