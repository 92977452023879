import { IonInput } from '@ionic/react';
import './style.css';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type dateType =
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'month'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';
type inputMode = 'decimal' | 'email' | 'none' | 'numeric' | 'search' | 'tel' | 'text' | 'url' | undefined;
interface IInput {
  handleChange?: (e: any) => void;
  label?: string;
  placeholder?: string;
  value?: string | null | number;
  type?: dateType;
  attr?: string;
  onIonInput?: boolean;
  inputmode?: inputMode;
  onPaste?: any;
  isName?: boolean;
  validation?: any;
}

const InputCustom: React.FC<IInput> = ({
  label,
  placeholder,
  value,
  type,
  attr = 'default',
  handleChange,
  onIonInput,
  inputmode,
  onPaste,
  isName = false,
  validation = {},
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // useEffect(() => {
  //   if (valueEmptyNotUndefiened(value) && handleChange) {
  //     handleChange(value);
  //   }
  // }, [value]);

  const patternEmail: IParamsRegister = {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Adresse email invalide',
    },
  };

  const pattern: IParamsRegister = {
    pattern: {
      value: /^[a-zA-Z0-9À-ÿ\-\'\.@_ ]*$/i,
      message: 'Seules les chiffres, lettres et - _ @ . sont autorisés',
    },
  };

  const patternAlphabet: IParamsRegister = {
    pattern: {
      value: /^[a-zA-ZÀ-ÖØ-öø-ÿ]*$/i,
      message: 'Seuls les caractères alphabétiques sont autorisés',
    },
  };
  const patternDecimal: IParamsRegister = {
    pattern: {
      value: /^(\d+(?:[\.\,]\d{1,2})?)$/i,
      message: 'Des chiffres avec un ou deux après virgules sont autorisés',
    },
  };

  const patternToUse = () => {
    if (isName) {
      return patternAlphabet;
    }
    if (inputmode === 'decimal') {
      return patternDecimal;
    }
    return type !== 'email' ? pattern : patternEmail;
  };

  let requirement: any = { required: 'Ce champ est requis', ...patternToUse(), ...validation };
  const registering = register(attr as string, requirement);

  return (
    <div className="ion-margin-bottom">
      <p className="custom-label font-outfit">{label}</p>
      <IonInput
        {...registering}
        className={`custom-input font-outfit bold-input`}
        placeholder={placeholder}
        type={type === 'number' ? type : 'text'}
        value={value}
        onIonInput={handleChange}
        inputmode={inputmode}
        onIonBlur={(e) => {
          registering.onBlur(e);
          if (onPaste) onPaste(e);
        }}
        onPaste={(e) => {
          if (onPaste) onPaste(e);
        }}
      ></IonInput>
      {errors[attr] && <p className="errorDescription">{errors[attr]?.message as string}</p>}
    </div>
  );
};

export default InputCustom;
