import { useMutation } from '@apollo/client';
import { DELETE_POST } from '../../graphql/mutations/delete-post.graphql';
import { ConfirmModal } from '../Modals';
import warningSvg from '../../assets/icons/warning.svg';

interface IDeletePost {
  showModal: boolean;
  handleCancel: () => void;
  selectedPost: string;
}

const DeletePost: React.FC<IDeletePost> = ({ showModal, handleCancel, selectedPost }) => {
  const [deletePost, { loading }] = useMutation(DELETE_POST);
  const handleDeletePost = async () => {
    const { data: dataEvent } = await deletePost({ variables: { id: selectedPost } });
    handleCancel();
  };

  return (
    <ConfirmModal
      isOpen={showModal}
      title="Es-tu sûr de vouloir supprimer cette publication ?"
      text="Cette action est définitive"
      okText="Confirmer"
      cancelText="Annuler"
      iconPerso={warningSvg}
      onConfirm={handleDeletePost}
      onCancel={handleCancel}
      height="425px"
      loading={loading}
    />
  );
};

export default DeletePost;
