import { FC } from 'react';
import './style.css';
import { IonButton, IonIcon, IonImg, IonText, IonTitle } from '@ionic/react';
import { closeSharp } from 'ionicons/icons';
import ImageItem from './ImageItem';
import FileItem from './FileItem';
import ImageIconSvg from '../../../assets/icons/imageIcon.svg';
import VideoItem from './VideoItem';

interface IProps {
  files: any[];
  handleFileDelete: (index: number) => void;
  cancelUpload: () => void;
}

const UploadedFilesPreview: FC<IProps> = ({ files, handleFileDelete, cancelUpload }) => {
  const deleteFile = (index: number) => {
    handleFileDelete(index);
  };

  const cancelFileUpload = () => {
    cancelUpload();
  };

  return (
    <div className="msg-box__file-uploaded-container">
      <div className="msg-box__file-uploaded-container__title-container">
        <div className="msg-box__file-uploaded-container__title-container">
          <IonIcon icon={ImageIconSvg} size="medium" />
          <span className="msg-box__file-uploaded-container__title-container__title">Pièces jointes</span>
        </div>
        <IonButton
          onClick={cancelFileUpload}
          className="msg-box__file-uploaded-container__close-btn ion-no-padding ion-no-margin"
          fill="clear"
        >
          <IonIcon size="small" icon={closeSharp} />
        </IonButton>
      </div>
      <div className="msg-box__file-container-wrapper">
        {files.map((file: any, index: number) => {
          if (file?.file?.type?.includes('image')) {
            return <ImageItem key={index} fileUrl={file.url} index={index} onDeleteFile={deleteFile} />;
          }
          if (file?.file?.type?.includes('video')) {
            return <VideoItem index={index} key={index} url={file.url} onDeleteFile={deleteFile} file={file.file} />;
          }
          return <FileItem key={index} file={file} index={index} onDeleteFile={deleteFile} />;
        })}
      </div>
    </div>
  );
};

export default UploadedFilesPreview;
