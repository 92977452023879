import { stateType, actionType } from '../type';

export const reducer = (state: stateType, action: actionType) => {
  switch (action.type) {
    case 'SET_DISPO':
      return {
        ...state,
        dispo: action.payload,
      };
    case 'SET_IMAGE_URL':
      return {
        ...state,
        imageUrl: action.payload,
      };
    case 'SET_IMAGE_SELECTEDS':
      return {
        ...state,
        imageSelecteds: action.payload,
      };
    case 'SET_DISPO_VIEW':
      return {
        ...state,
        dispoView: action.payload,
      };
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.payload,
      };
    case 'INCREMENT_NEW_MESSAGE':
      return {
        ...state,
        newMessage: (state.newMessage || 0) + 1,
      };
    case 'DECREMENT_NEW_MESSAGE':
      return {
        ...state,
        newMessage: (state.newMessage || 1) - 1,
      };
    case 'SET_NB_NEW_MESSAGE':
      return {
        ...state,
        newMessage: action.payload,
      };
    case 'PUSH_NOTIFICATIONS':
      return {
        ...state,
        notifications: [...(state.notifications || []), ...(action.payload || [])],
      };
    case 'SET_SEARCH_QUERY':
      return {
        ...state,
        searchQuery: action.payload,
      };
    case 'SET_DB':
      return {
        ...state,
        db: action.payload,
      };
    case 'SET_FULL_SLIDER':
      return {
        ...state,
        fullSlider: action.payload,
      };
    case 'ADD_TOAST':
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    case 'DELETE_TOAST':
      const updatedToasts = state.toasts.filter((toast) => toast.id !== action.payload);
      return {
        ...state,
        toasts: updatedToasts,
      };
    case 'SET_CANCELED_EVENT':
      return {
        ...state,
        canceledEvent: action.payload,
      };
    case 'SET_REDIRECT_HOME':
      return {
        ...state,
        redirectHome: action.payload,
      };
    case 'SET_VIDEO':
      return {
        ...state,
        videoPlayed: action.payload,
      };
    case 'SET_RELOAD_HOME':
      return {
        ...state,
        reloadHome: action.payload,
      };
    default:
      return state;
  }
};
