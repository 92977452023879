import { IonCol, IonGrid, IonImg, IonRow, isPlatform } from '@ionic/react';
import { FC, useCallback } from 'react';
import { useLongPress } from 'use-long-press';
import fileIcon from '../../../assets/icons/attachFile.svg';
import playIcon from '../../../assets/icons/playIcon.svg';
import VideoView from '../../VideoView';
import AudioWaveAndroid from './AudioWaveAndroid';
import AudioWaveForm from './AudioWaveForm';
import PdfPreview from './PdfPreview';
import './style.css';

interface IProps {
  media: FilePickedType[];
  onFileOpen: any;
  isMine?: boolean;
  onOpenModal?: any;
}

const MediaGallery: FC<IProps> = ({ media, onFileOpen, isMine = true, onOpenModal }) => {
  const callback = useCallback((event: any) => {
    console.log('Open image');
  }, []);
  const isAndroid = isPlatform('android');

  const bind = useLongPress(callback, {
    onStart: () => {
      console.log('START');
    },
    onFinish: () => {
      console.log('FINISH');
    },
    onCancel: (event: any) => {
      const id = event?.target?.id.split('_')[1];
      console.log('CANCEL ');
      // onFileOpen(+id);
    },
    onMove: () => {
      console.log('MOVE');
    },
    filterEvents: (event: any) => true,
    threshold: 250,
    captureEvent: true,
    cancelOnMovement: false,
    cancelOutsideElement: false,
  });

  const getViewByMediaType = (media: FilePickedType, index: number, count: number, isMultiple = false) => {
    const imgClassName = `chat__message-item__img-${count >= 4 ? 4 : count}`;
    const videoClassName = `chat__details__video-view-${count >= 4 ? 4 : count}`;

    if (media?.mime?.substring(0, 5) === 'video' || media?.mimeType?.substring(0, 5) === 'video') {
      return (
        <div id={`imgId_${index}`}>
          <VideoView
            className={`${videoClassName} chat__message-item__video ion-border-radius-5`}
            url={media?.url as string}
            autoPlay={false}
            playIcon={playIcon}
            from={count === 1 ? 'media1' : ''}
          />
        </div>
      );
    }

    if (media?.mime?.startsWith('audio')) {
      return <>{!!!isAndroid ? <AudioWaveForm media={media} /> : <AudioWaveAndroid media={media} />}</>;
    }

    if (media?.mime?.startsWith('image') || media?.mimeType?.startsWith('image')) {
      let imgBorderClass = '';
      if (count === 1) {
        imgBorderClass = 'chat__message-item__img-border-single-elt';
      }
      if (count === 3) {
        if (index === 0) {
          imgBorderClass = 'chat__message-item__img-border-first-elt';
        }
        if (index === 2) {
          imgBorderClass = 'chat__message-item__img-border-third-elt';
        }
      }
      if (count === 2 || count > 3) {
        if (index === 0) {
          imgBorderClass = 'chat__message-item__pair__img-border-first-elt';
        }
        if (index === 1) {
          imgBorderClass = 'chat__message-item__pair__img-border-second-elt';
        }
        if (index === 2) {
          imgBorderClass = 'chat__message-item__pair__img-border-third-elt';
        }
        if (index === 3) {
          imgBorderClass = 'chat__message-item__pair__img-border-fourth-elt';
        }
      }
      return (
        <div>
          <IonImg
            onClick={onFileOpen}
            className={`${imgClassName} ${imgBorderClass}`}
            src={media?.url}
            id={`imgId_${index}`}
          />
        </div>
      );
    }
    if (!isMultiple) {
      return (
        <div id={`imgId_${index}`}>
          <PdfPreview media={media} />
        </div>
      );
    }
    return (
      <div style={{ backgroundColor: 'gray' }}>
        <IonImg onClick={onFileOpen} className="chat__message-item__file-icon" src={fileIcon} id={`imgId_${index}`} />
      </div>
    );
  };

  const renderMedia = () => {
    const mediaCount = media.length;

    if (mediaCount === 1) {
      return (
        <IonRow className={`${isMine ? 'chat__message-item__img__mine' : 'chat__message-item__img__not-mine'}`}>
          <IonCol size="12">{getViewByMediaType(media[0], 0, 1)}</IonCol>
        </IonRow>
      );
    } else if (mediaCount >= 2 && mediaCount <= 3) {
      return (
        <IonRow className={`${isMine ? 'chat__message-item__img__mine' : 'chat__message-item__img__not-mine'}`}>
          {media.map((item: any, index: number) => (
            <IonCol
              style={{ background: item?.mime?.includes('pdf') || item?.mimeType?.includes('pdf') ? 'gray' : '' }}
              key={index}
              size={mediaCount === 3 ? '4' : '6'}
            >
              {getViewByMediaType(item, index, mediaCount, true)}
            </IonCol>
          ))}
        </IonRow>
      );
    } else {
      return (
        <IonRow className={`${isMine ? 'chat__message-item__img__mine' : 'chat__message-item__img__not-mine'}`}>
          {media?.slice(0, 4)?.map((item: any, index: number) => (
            <IonCol
              style={{
                background: item?.mime?.includes('pdf') || item?.mimeType?.includes('pdf') ? 'gray' : '',
                display: item?.mime?.includes('video') ? 'flex' : '',
                alignItems: item?.mime?.includes('video') ? 'center' : '',
              }}
              size="6"
              key={index}
            >
              {getViewByMediaType(item, index, mediaCount, true)}
              {index === 3 && mediaCount > 4 && (
                <span className="chat__message-item__img__plusplus" onClick={() => onOpenModal(index)}>
                  +{mediaCount - 4}
                </span>
              )}
            </IonCol>
          ))}
        </IonRow>
      );
    }
  };

  return (
    <IonGrid
      className={`chat__message-item__file__grid-container ${
        isMine ? 'chat__message-item__file__grid-container-end' : 'chat__message-item__file__grid-container-start'
      } ion-no-padding`}
    >
      {renderMedia()}
    </IonGrid>
  );
};

export default MediaGallery;
