import { IonButton, IonContent, IonList, IonItem, IonLabel, IonRow, IonCol, IonIcon } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';
import { ComponentModal } from '../Modals';
import { arrowForwardOutline } from 'ionicons/icons';
import { Enum_Report_Type } from '../../models/gql/graphql';
import './style.css';

interface IMenuList {
  listItems: ILabeling[];
  isOpen?: boolean;
  actionCancel?: () => void;
  contentTypeReported: Enum_Report_Type;
  relatedId?: string | null;
  title: string;
  userId?: string;
  callBack?: () => void;
  postCommentId?: string;
  postUserId?: string;
}

const Reporting: React.FC<IMenuList> = ({
  listItems,
  isOpen = false,
  actionCancel,
  title,
  contentTypeReported,
  relatedId,
  userId,
  callBack,
  postCommentId,
  postUserId,
}) => {
  const history = useHistory();

  return (
    <>
      <ComponentModal onCancel={() => actionCancel && actionCancel()} isOpen={isOpen} height="670px">
        <IonContent className="ion-no-padding ion-padding-horizontal">
          <IonList lines="none">
            <IonItem className="font-outfit text-bold title-modal-reporting ">{title}</IonItem>
            {listItems.map((item, index) => (
              <IonItem
                key={index}
                style={
                  index !== listItems.length - 1
                    ? {
                        borderBottom: '2px solid #D9D9D9',
                      }
                    : {}
                }
                onClick={() => {
                  history.push(
                    `/main/reporting/${item.value}/${contentTypeReported}/${relatedId}/${userId ? userId : ''}${
                      postCommentId ? '/' + postCommentId : ''
                    }${postUserId ? '/' + postUserId : ''}`
                  );
                  if (actionCancel) actionCancel();
                  if (callBack) callBack();
                }}
              >
                <IonLabel text-wrap>
                  <IonRow>
                    <IonCol>
                      <IonRow className="label-reporting font-outfit">{item.label}</IonRow>
                    </IonCol>

                    <IonCol className={`blue-icon margin-r-5 text-size-16`} size="1">
                      <IonIcon icon={arrowForwardOutline}></IonIcon>
                    </IonCol>
                  </IonRow>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
          <IonButton
            expand="block"
            fill="clear"
            className="d-absolute-bottom ion-padding-horizontal text-underligne text-black"
            onClick={actionCancel}
          >
            Annuler
          </IonButton>
        </IonContent>
      </ComponentModal>
    </>
  );
};

export default Reporting;
