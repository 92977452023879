import { useMutation } from '@apollo/client';
import {
  IonButton,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import '../..';
import { Loader, RichEditor } from '../../components';
import { MutliImageForm } from '../../components/PostForm';
import { UPDATE_POST } from '../../graphql/mutations/post.graphql';
import { UPLOAD } from '../../graphql/mutations/upload.graphql';
import { useToast } from '../../hooks';
import { usePost } from '../../hooks/usePosts';
import { PostInput } from '../../models/gql/graphql';
import { useAppStore } from '../../store';
import { getUserId } from '../../utils';
import { __ } from '../../utils/traduction';
import { checkForbiddenWordPasted, stripTag } from '../../utils/word';
import { handleDescriptionChange, handlePickPhotos, onSelectFile, pickVideos, uploadFiles } from './utils';

interface IEditPost {
  id: string;
  close: () => void;
}
const EditPost: React.FC<IEditPost> = ({ id, close }) => {
  const { post: postDefault, refreshPost } = usePost(id);
  const [loadSpinner, setLoading] = useState<boolean>(false);
  const [loadPub, setPub] = useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] = useState<IImage[]>([]);
  const clearImage = (value: number) => {
    const cloneMediaArray = cloneDeep(selectedMedia);
    cloneMediaArray.splice(value, 1);
    setSelectedMedia(cloneMediaArray);
  };
  const [description, setDescription] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const {
    RootStore: { algoliaCache, forbiddenWords },
  } = useAppStore();
  const [presentToast] = useIonToast();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const refBtnPick = useRef<HTMLInputElement>(null);
  const [updatePost, { loading: loadingPost }] = useMutation(UPDATE_POST);
  const [upload, { loading: loadingUpload }] = useMutation(UPLOAD);
  const history = useHistory();
  const toast = useToast();

  const post = async () => {
    setPub(true);
    const uploadedFile = uploadFiles(selectedMedia, upload);
    const postData = {
      medias: await Promise.all(uploadedFile),
      description: description,
      author: getUserId(),
      publishedAt: new Date(),
    };
    const { data: postResponse } = await updatePost({
      variables: { id: postDefault.id || '', data: postData as PostInput },
    });
    refreshPost();
    setPub(false);
    if (postResponse) {
      toast.success('Publication modifiée avec succès!', 7000);
      setSelectedMedia([]);
      close();
      history.push('/main/posts/' + getUserId() + '/' + postResponse.updatePost?.data?.id);
      return;
    }
  };

  useEffect(() => {
    if (postDefault) {
      setDescription(postDefault.attributes?.description || '');
      if (postDefault.attributes?.medias?.data.length) {
        let listDefault: IImage[] = [];
        postDefault.attributes?.medias.data.forEach((file) => {
          listDefault.push({
            url: file.attributes?.url,
            id: file.id,
            file: file,
            type: file.attributes?.mime.startsWith('image') ? 'image' : 'video',
          });
        });
        setSelectedMedia(listDefault);
      }
      setPub(false);
    }
  }, [postDefault]);

  const init = async () => {
    setPub(true);
    await refreshPost();
  };
  useEffect(() => {
    init();
  }, []);
  const handlePastedEvent = useCallback(
    (field: any, e: any) => {
      let pasted: string;
      if (!e?.clipboardData) {
        if (e.target.value) {
          pasted = e.target.value; // whole text
        } else {
          pasted = e.target.textContent;
        }
      } else {
        pasted = stripTag(e?.clipboardData?.getData('text'));
      }
      const newPastedArr = stripTag(pasted)
        .split(' ')
        .filter((text: string) => {
          return !checkForbiddenWordPasted(forbiddenWords, text);
        });
      if (stripTag(pasted).split(' ').length !== newPastedArr.length) {
        const changed = newPastedArr.join(' ');
        setTimeout(() => {
          setDescription(`${description} ${changed}`);
          presentToast('Des mots interdits ont été détéctés et a été supprimé de votre entrée', 1000);
        }, 100);
      }
    },
    [forbiddenWords, description]
  );

  return (
    <div className={'gallery-container'}>
      {loadPub && <Loader label="Nous préparons ta publication ..." width={150} height={150} />}
      <IonHeader className="bg-light-shade">
        <IonToolbar className={'relative margin-t-15'}>
          <IonTitle className="text-center margin-t-15 ion-text-capitalize font-outfit">Modifier publication</IonTitle>
          <IonLabel
            onClick={() => {
              setSelectedMedia([]);
              close();
            }}
            className={'ion-btn-clear ion-center margin-t-25'}
          >
            <IonIcon icon={closeOutline} className="text-black text-size-23 text-bolder"></IonIcon>
          </IonLabel>
        </IonToolbar>
      </IonHeader>
      <IonContent className="bg-light-shade gallery-container">
        <IonGrid className="ion-justify-content-center ion-align-items-center width-p-95 min-height-200 ion-padding bg-white border-radius height-auto ion-margin">
          <MutliImageForm selectedMedia={selectedMedia} clearImage={clearImage} />
        </IonGrid>
        <IonGrid className="post-form ion-justify-content-center ion-align-items-center width-p-95 min-height-200 bg-white border-radius height-auto ion-margin">
          <RichEditor
            onPaste={(e: any) => handlePastedEvent('description', e)}
            handleChange={(value: string) =>
              handleDescriptionChange(value, forbiddenWords, setDescription, presentToast)
            }
            value={description}
            label=""
            placeholder="Rédige ta publication"
          />
        </IonGrid>
        <div style={{ display: 'none' }}>
          <input
            type="file"
            accept="image/*"
            ref={refBtnPick}
            onChange={(f: any) => onSelectFile(f, selectedMedia, setSelectedMedia, setIsOpen, setMessageError)}
            className="ion-hide"
            multiple={true}
          />
        </div>
        <IonToast
          isOpen={isOpen}
          message={__(messageError)}
          onDidDismiss={() => setIsOpen(false)}
          duration={5000}
          color="danger"
        ></IonToast>
      </IonContent>
      <IonRow className="relative ion-justify-content-center gallery-btn-action">
        <IonButton
          expand="block"
          color="primary"
          onClick={() => pickVideos(selectedMedia, setSelectedMedia, setIsOpen, setMessageError, setLoading)}
          className="ion-margin-top width-p-90"
          disabled={loadingPost || loadingUpload || loadSpinner}
        >
          Acceder à ma galerie video
        </IonButton>
        <IonButton
          expand="block"
          color="primary"
          onClick={() => handlePickPhotos(refBtnPick, selectedMedia, setSelectedMedia, setIsOpen, setMessageError)}
          className="width-p-90"
          disabled={loadingPost || loadingUpload || loadSpinner}
        >
          Acceder à ma galerie photo
        </IonButton>
        <IonButton
          expand="block"
          fill="solid"
          color="primary"
          className="width-p-90 "
          onClick={() => {
            post();
          }}
          disabled={loadingPost || !selectedMedia.length || loadingUpload || loadSpinner}
        >
          Modifier
        </IonButton>
        {loadSpinner && <IonSpinner color="dark" className={'custom-loader'}></IonSpinner>}
      </IonRow>
    </div>
  );
};

export default EditPost;
