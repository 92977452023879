import { IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow, IonText } from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { CardDispo, Header, PriceBox } from '../../../components';
import { useDispoDetail } from '../../../hooks/useDispoDetail';
import { CustomButton } from '../components/Buttons/CustomButton';
import { getEventDateData, getFullAddress } from '../utils';
import { insertEventToCalendar } from '../../../hooks/synchroniseCalendar';

const PaymentSuccess: React.FC = () => {
  const { id: idDispo } = useParams<IVerifParams>();
  const history = useHistory();

  const { eventUserParticipations, event } = useDispoDetail();

  useEffect(() => {
    if (event) {
      //Add event to calendar
      insertEventToCalendar(event);
    }
  }, [event]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <Header title="Confirmation de paiement" noButtonBack />
        <IonGrid>
          <IonRow>
            <IonCol>
              <CardDispo dispo={event}></CardDispo>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="d-flex ion-justify-content-center ion-align-ites-ms-center">
              <div className="price-box">
                <PriceBox>{event?.attributes?.price || '0'}€</PriceBox>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText className="ion-text-center">
                <h4>Nous avons reçu ton paiement</h4>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter className={`ion-no-border`} mode="ios">
        <IonRow className="ion-justify-content-center font-outfit">
          <CustomButton
            action={() => {
              history.push(`/main/dispos/${idDispo}/inscription-success`);
              // window.location.reload();
            }}
            style={{
              position: 'fixed',
              bottom: 0,
              zIndex: '70',
            }}
            class="ion-no-border text-white min-width-p-80 min-height-p-100 ion-border-radius-10 button-bg-green no-uppercase margin-b-10"
            text="Acceder à ton ticket"
          />
        </IonRow>
      </IonFooter>
    </IonPage>
  );
};

export default PaymentSuccess;

interface IDescriptionDetail {
  title: string;
  value?: string;
  textColor: string;
}

const DescriptionDetail: React.FC<IDescriptionDetail> = ({ title, value, textColor }) => {
  return (
    <div>
      <IonRow className="margin-b-15">
        <IonText style={{ color: textColor }} className="text-bolder text-capitalize">
          {title}
        </IonText>
      </IonRow>
      <IonRow>
        <IonText className="text-capitalize">{value || ''}</IonText>
      </IonRow>
    </div>
  );
};
