/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation } from '@apollo/client';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSearchbar,
  IonText,
  IonToast,
} from '@ionic/react';

import { defineCustomElements as jeepSqlite, applyPolyfills, JSX as LocalJSX } from 'jeep-sqlite/loader';
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso';
import { groupBy } from 'lodash';
import inviteGoodSvg from '../../assets/icons/inviteGood.svg';
import inviteWaitSvg from '../../assets/icons/inviteWait.svg';
import inviteSvg from '../../assets/icons/invitenew.svg';
import { INVITE_CONTACT } from '../../graphql/mutations/user-follow.graphql';
import { UsersPermissionsUserEntity } from '../../models/gql/graphql';
import AvatarUser from '../AvatarUser';
import { SQLiteConnection, CapacitorSQLite } from '@capacitor-community/sqlite';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getUserId } from '../../utils';
import { AppReducerContext, useAppStore } from '../../store';
import { sortBy } from 'lodash';
import { checkingIfNumIsSimilar, extractPhoneNumberAndCode } from '../../utils';
import { Capacitor } from '@capacitor/core';

applyPolyfills().then(() => {
  jeepSqlite(window);
});

const userSorter = (a: Array<string>, b: Array<string>) => {
  if (a[1].toLowerCase() < b[1].toLowerCase()) {
    return -1;
  }
  if (a[1].toLowerCase() > b[1].toLowerCase()) {
    return 1;
  }
  return 0;
};
function generateGroupedUsers(data: Array<Array<string>>, query = '') {
  const alpha = data.filter((i) => !/^[^a-z]/i.test(i[1].toLowerCase()));
  const beta = data
    .filter((i) => /^[^a-z]/i.test(i[1].toLowerCase()))
    .map((i) => {
      i[1] = i[1].replace(/[^a-zA-Z ]/g, '');
      return i;
    })
    .filter((i) => i[1]);
  let arrayUnique = [
    ...Array.from(new Map(alpha.map((item) => [item[1] ? item[1].toLowerCase() : item[0], item])).values()),
    ...Array.from(new Map(beta.map((item) => [item[1] ? item[1].toLowerCase() : item[0], item])).values()),
  ];
  if (query && query !== '') {
    arrayUnique = arrayUnique.filter((i) => i[1].toLocaleLowerCase().startsWith(query.toLocaleLowerCase()));
  }

  const users = arrayUnique.sort(userSorter);
  const groupedUsers = groupBy(users, (user) => (user[1][0] || '').toUpperCase());
  const groupCounts = Object.values(groupedUsers).map((users) => users.length);
  const groups = Object.keys(groupedUsers);

  return { users, groupCounts, groups };
}
interface IUserContactList {
  userContacts: UsersPermissionsUserEntity[];
  contactsName: {};
  follow: (value: string[]) => void;
  mySubscriptionData?: any;
  refetch?: () => void;
  loadingFollow: boolean;
  blockedList?: any[];
  type: string;
  loading?: boolean;
  invitationsInDb?: any[];
  setInvitations?: Dispatch<SetStateAction<any[]>>;
  contactsImageNotInscrit?: any;
  virtuosoStyle?: any;
}

const UserContactList: React.FC<IUserContactList> = ({
  userContacts,
  contactsName,
  follow,
  mySubscriptionData,
  refetch,
  loadingFollow,
  blockedList,
  type,
  loading,
  invitationsInDb,
  setInvitations,
  contactsImageNotInscrit,
  virtuosoStyle = {},
}) => {
  const [visibleContacts, setVisibleContacts] = useState(10000);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>('');
  const [search, setSearch] = useState('');

  const {
    state: { db },
    dispatch,
  } = useContext(AppReducerContext);
  const virtuoso = useRef<any>(null);

  const initializeDatabase = async () => {
    const sqlite = new SQLiteConnection(CapacitorSQLite);
    const platform = Capacitor.getPlatform();
    if (platform === 'web') {
      // add 'jeep-sqlite' Stencil component to the DOM
      const jeepEl = document.createElement('jeep-sqlite');
      document.body.appendChild(jeepEl);
      await customElements.whenDefined('jeep-sqlite');
      // initialize the web store
      await sqlite.initWebStore();
    }
    if (!db) {
      await sqlite.checkConnectionsConsistency();
      try {
        let newDb = await sqlite.createConnection('tedispo', false, 'no-encryption', 1, false);
        await newDb.open();

        dispatch({ type: 'SET_DB', payload: newDb });

        const createTableQuery = `
            CREATE TABLE IF NOT EXISTS invitation (
              id INTEGER PRIMARY KEY AUTOINCREMENT,
              inviter_id TEXT,
              invited_contact TEXT
            );
          `;
        await newDb.execute(createTableQuery);
        return newDb;
      } catch (e) {
        console.log('error on CREATE CONNECTION', e);
      }
    }

    return db;
  };
  const selectInvitations = async (userId: string) => {
    let dbInstance = db;
    if (!dbInstance) {
      dbInstance = await initializeDatabase();
    }
    try {
      const invitations = dbInstance && (await dbInstance.query(`SELECT * FROM invitation WHERE inviter_id=${userId}`));
      console.log({ invitations, userContacts, contactsName });
      setInvitations && setInvitations(invitations?.values || []);
    } catch (e) {
      console.log('error on select invitation', e);
    }
  };

  const getInvitations = async () => {
    await selectInvitations(getUserId() || '');
  };

  const insertInvitation = async (contact: string) => {
    let dbInstance = db;
    if (!dbInstance) {
      dbInstance = await initializeDatabase();
    }
    try {
      const insertInvitationQuery = `INSERT INTO invitation (inviter_id, invited_contact) VALUES ('${getUserId()}', '${contact}')`;
      dbInstance && (await dbInstance.query(insertInvitationQuery));
      selectInvitations(getUserId() || '');
    } catch (e) {
      setIsOpen(true);
      setMessageError('Invitation non envoyée.');
    }
  };

  const handleShowMore = () => {
    setVisibleContacts((prevVisibleContacts) => prevVisibleContacts + 30);
  };

  useEffect(() => {
    if (!!Object.keys(contactsName).length && !loading) {
      getInvitations();
    }
  }, [contactsName, loading]);

  const getList = useCallback(() => {
    if (!!userContacts.length && type === 'inscrit') {
      console.log('1 -->', userContacts);
      return (
        <Virtuoso
          style={virtuosoStyle}
          data={userContacts}
          itemContent={(index, contact) => (
            <ContactItem
              userContact={contact}
              contactsName={contactsName}
              follow={follow}
              mySubscriptionData={mySubscriptionData}
              refetch={refetch}
              key={index}
              loading={loadingFollow}
            />
          )}
        />
      );
    } else if (!!Object.keys(contactsName).length && type === 'notinscrit') {
      const items: any = Object.entries(contactsName);

      const { users, groupCounts, groups } = generateGroupedUsers(items, search);
      return (
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <IonSearchbar
              mode="ios"
              color="light"
              placeholder="Recherche"
              value={search}
              debounce={200}
              onIonInput={(event) => {
                setSearch(event?.detail?.value || '');
              }}
              onIonClear={(e: any) => {
                setSearch('');
              }}
            />
            <GroupedVirtuoso
              ref={virtuoso}
              groupCounts={groupCounts}
              style={{ height: 'calc(100vh - 300px)', marginBottom: '150px' }}
              groupContent={(index) => {
                return (
                  <div
                    style={{
                      backgroundColor: 'white',
                      paddingTop: '1rem',
                      paddingBottom: '1rem',
                      fontSize: '18px',
                      paddingLeft: '10px',
                      zIndex: 999,
                      fontWeight: 'bold',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    {groups[index]}
                  </div>
                );
              }}
              itemContent={(index) => {
                return (
                  <ContactItem
                    userContact={{}}
                    phone={users[index][0].toString()}
                    contactsName={users[index][1].toString()}
                    mySubscriptionData={mySubscriptionData}
                    refetch={refetch}
                    key={index}
                    loading={loadingFollow}
                    invitationsInDb={invitationsInDb}
                    insertInvitation={insertInvitation}
                    contactsImageNotInscrit={contactsImageNotInscrit}
                  />
                );
              }}
            />
          </div>

          <ul
            style={{
              marginLeft: '0.5rem',
              paddingLeft: '0',
              listStyle: 'none',
              fontSize: '0.8rem',
            }}
          >
            {groupCounts
              .reduce(
                ({ firstItemsIndexes, offset }: any, count) => {
                  return {
                    firstItemsIndexes: [...firstItemsIndexes, offset],
                    offset: offset + count,
                  };
                },
                { firstItemsIndexes: [], offset: 0 }
              )
              .firstItemsIndexes.map((itemIndex: any, index: any) => (
                <li key={index} style={{ paddingBottom: '2px', paddingTop: '2px' }}>
                  <a
                    style={{ fontSize: '14px', textDecoration: 'none' }}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      virtuoso?.current?.scrollToIndex({
                        index: itemIndex,
                      });
                    }}
                  >
                    {groups[index]}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      );
    }
    return '';
  }, [
    contactsImageNotInscrit,
    contactsName,
    follow,
    insertInvitation,
    invitationsInDb,
    loadingFollow,
    mySubscriptionData,
    refetch,
    type,
    userContacts,
    search,
  ]);

  return (
    <IonGrid>
      {getList()}
      <IonToast
        isOpen={isOpen}
        message={messageError}
        onDidDismiss={() => setIsOpen(false)}
        duration={5000}
        color="danger"
      ></IonToast>
    </IonGrid>
  );
};

export default UserContactList;

interface IContactItem {
  userContact: any;
  contactsName: any;
  follow?: (value: string[]) => void;
  phone?: string;
  mySubscriptionData?: any;
  refetch?: () => void;
  loading: boolean;
  invitationsInDb?: any[];
  insertInvitation?: (contact: string) => void;
  contactsImageNotInscrit?: any;
}
const ContactItem: React.FC<IContactItem> = ({
  userContact,
  contactsName,
  follow,
  phone,
  mySubscriptionData,
  refetch,
  loading,
  invitationsInDb,
  insertInvitation,
  contactsImageNotInscrit,
}) => {
  const [InviteContacts, { loading: loadingInvite }] = useMutation(INVITE_CONTACT);
  const inviteContact = async (phone: string) => {
    try {
      console.log('start invite contact');
      await InviteContacts({
        variables: {
          phone: phone,
        },
      });
      if (insertInvitation) {
        insertInvitation(phone);
      }
    } catch (err: any) {
      console.log(err);
    }
  };
  let relation =
    'id' in userContact && userContact.id
      ? mySubscriptionData.find((user: ISubcribtion) => user.idUser === userContact.id)
      : null;

  return (
    <IonItem button={false} className="ion-no-padding ion-text-wrap">
      {
        /* avatar */
        !contactsImageNotInscrit ? (
          <AvatarUser user={userContact.attributes} classCustom="avatar-image"></AvatarUser>
        ) : (
          <AvatarUser
            base64Url={(contactsImageNotInscrit as any)[phone as any]}
            classCustom="avatar-image"
          ></AvatarUser>
        )
      }
      <IonLabel>
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-align-items-center">
            <IonCol
              className="text-gray-400 font-outfit text-capitalize margin-l-5"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {'attributes' in userContact && userContact.attributes?.phone
                ? (checkingIfNumIsSimilar(contactsName, userContact.attributes?.phone) === 'deleteDirect' &&
                    (contactsName as any)[userContact.attributes.phone]) ||
                  (checkingIfNumIsSimilar(contactsName, userContact.attributes?.phone) === 'deleteNotDirect' &&
                    (contactsName as any)[extractPhoneNumberAndCode(userContact.attributes.phone).number])
                : contactsName}
              <div style={{ color: '#323232', fontSize: 14 }}>
                {'attributes' in userContact && userContact.attributes?.phone
                  ? !userContact.attributes?.isPro
                    ? userContact.attributes?.username
                    : userContact.attributes?.companyName
                  : ''}
              </div>
            </IonCol>
            {/*<IonCol className="blue-icon margin-r-5 ion-justify-content-end" size="2">
            
      </IonCol>*/}
          </IonRow>
        </IonGrid>
      </IonLabel>
      {
        /* Button */
        'id' in userContact && userContact.id && follow ? (
          <InviteIcon
            relation={relation}
            follow={follow}
            userContact={userContact}
            refetch={refetch}
            loading={loading}
          />
        ) : (
          <>
            {invitationsInDb && invitationsInDb?.some((item) => item.invited_contact === phone) ? (
              <IonButton fill="clear" disabled={true} className="ion-no-margin ion-no-padding">
                <IonLabel style={{ whiteSpace: 'normal', textAlign: 'center' }}>
                  <IonText className="text-green text-size-11">Invitation envoyée</IonText>
                </IonLabel>
              </IonButton>
            ) : (
              <IonButton
                fill="clear"
                onClick={() => phone && inviteContact(phone)}
                disabled={loadingInvite}
                className="ion-no-margin ion-no-padding"
              >
                <IonText className="text-blue text-size-11">INVITER</IonText>
              </IonButton>
            )}
          </>
        )
      }
    </IonItem>
  );
};

interface IInviteIcon {
  relation?: ISubcribtion | null;
  userContact: any;
  follow: (value: string[]) => void;
  refetch?: () => void;
  loading: boolean;
}
const InviteIcon: React.FC<IInviteIcon> = ({ relation, userContact, follow, refetch, loading }) => {
  return (
    <>
      {!relation && (
        <IonButton
          className="ion-no-margin ion-no-padding"
          fill="clear"
          onClick={async () => {
            await follow([userContact.id]);
            refetch && refetch();
          }}
          disabled={loading}
        >
          <IonIcon icon={inviteSvg} className="text-size-x ion-justify-content-end"></IonIcon>
        </IonButton>
      )}
      {relation && relation.status === 'invited' && <IonIcon icon={inviteWaitSvg} className="text-size-x"></IonIcon>}
      {(relation && relation.status) === 'accepted' && <IonIcon icon={inviteGoodSvg} className="text-size-x"></IonIcon>}
    </>
  );
};
