/* eslint-disable max-classes-per-file */
import {
  collection,
  where,
  orderBy,
  query,
  addDoc,
  updateDoc,
  Timestamp,
  onSnapshot,
  getDocs,
  doc,
  getDoc,
  setDoc,
  limit,
  startAfter,
} from 'firebase/firestore';
import _ from 'lodash';

import { db } from '../config';

export const useMessenger = () => {
  const GenerateMessageId = (ids: string[]) => {
    return ids
      .map((id) => btoa(id))
      .sort((a, b) => (a < b ? -1 : 1))
      .join('');
  };
  const GetTime = (time: any, withDate?: boolean) => {
    const dt = new Date(time.seconds * 1000 + time.nanoseconds / 1000000);
    //
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (withDate)
      return dt < today
        ? `${dt.toLocaleDateString()}, ${dt.toLocaleTimeString().slice(0, 5)}`
        : dt.toLocaleTimeString().slice(0, 5);
    return dt.toLocaleDateString();
  };
  const Trunc = (text: string, max: number) => {
    return text.length > max ? `${text.substr(0, max - 1).trim()} ...` : text;
  };

  const Send = async (Msg: any) => {
    try {
      let create = false;
      const date = Timestamp.fromDate(new Date());
      const pickedMsg = _.pick(Msg, [
        'fromId',
        'toId',
        'body',
        'participantsIds',
        'msgId',
        'parentId',
        'isResponse',
        'files',
        'type',
      ]);
      const itemMsg = await addDoc(collection(db, 'messages', Msg.id, 'item'), {
        ...pickedMsg,
        createdAt: date,
      });
      if (!itemMsg) throw new Error('Sent Error');
      const msgRef = doc(db, 'messages', Msg.id);
      const msgDoc = await getDoc(msgRef);

      const omittedMsg = _.omit(Msg, ['body', 'fromId', 'toId', 'msgId', 'parentId', 'isResponse', 'files', 'type']);

      if (msgDoc.exists()) {
        await updateDoc(msgRef, {
          ...omittedMsg,
          last: date,
          lastMsgItemId: itemMsg.id,
        });
      } else {
        await setDoc(msgRef, {
          ...omittedMsg,
          last: date,
          lastMsgItemId: itemMsg.id,
          createdAt: Timestamp.fromDate(new Date()),
        });
        create = true;
      }
      return { ...Msg, chatId: Msg.id, messageId: itemMsg.id, create };
    } catch (err: any) {
      console.log('Sending msg error', err);
    }
  };
  const UpdateParticipants = async (Msg: any) => {
    const msgRef = doc(db, 'messages', Msg.id);
    const msgDoc = await getDoc(msgRef);
    if (msgDoc.exists()) {
      const dataDoc = msgDoc.data();
      if (!dataDoc?.participantsIds.includes(Msg.idUser)) {
        const addNewMembers = [...dataDoc?.participantsIds, Msg.idUser];
        console.log('not includes so we must to add it', { addNewMembers });
        await updateDoc(msgRef, {
          participantsIds: addNewMembers,
        });
      } else {
        console.log('includes do nothing');
      }
    }
  };

  const CreateChat = async (Msg: any) => {
    const msgRef = doc(db, 'messages', Msg.id);
    const msgDoc = await getDoc(msgRef);
    if (!msgDoc.exists()) {
      await setDoc(msgRef, {
        ...Msg,
        last: Timestamp.fromDate(new Date()),
        createdAt: Timestamp.fromDate(new Date()),
      });
      return { ...Msg, chatId: Msg.id };
    }
  };

  /**
   * Read available conversations
   * @param options           option parameters
   * @returns
   */
  const ReadMsgList = (options: IMsgListOptions) => {
    const msgRef = collection(db, 'messages');
    const msgQuery = query(msgRef, orderBy('last', 'desc'), where('participantsIds', 'array-contains', options.user));
    const unsub = onSnapshot(msgQuery, (querySnapshot) => {
      try {
        const msgs: IMsg[] = [];
        querySnapshot.forEach((docRef) => {
          msgs.push({
            ...(docRef.data() as any),
            id: docRef.id,
            last: GetTime(docRef.data()?.last),
            lastbody: docRef.data()?.lastbody,
          } as IMsg);
        });
        if (options.onResolve) {
          options.onResolve(msgs);
        }
      } catch (err: any) {
        console.log('Selected read msg error', err);
        if (options.onError) options.onError(err);
      }
    });
    return unsub;
  };

  /**
   * Read current conversation data
   * @param options         option parameters
   * @returns
   */
  const ReadSelectedMsg = (options: IMsgReadOptions, size?: number) => {
    const msgRef = collection(db, 'messages', options.msgId, 'item');
    let msgQuery;
    if (size) {
      msgQuery = query(msgRef, orderBy('createdAt', 'desc'), limit(size));
    } else {
      msgQuery = query(msgRef, orderBy('createdAt', 'desc'));
    }
    const unsub = onSnapshot(msgQuery, (querySnapshot) => {
      try {
        const msgs: IMsgOptions[] = [];
        querySnapshot.forEach((docRef) => {
          msgs.push({
            ...docRef.data(),
            id: docRef.id,
            createdAt: GetTime(docRef.data()?.createdAt, true),
          } as IMsgOptions);
        });
        if (options.onResolve) {
          options.onResolve(msgs.reverse(), querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
      } catch (err: any) {
        if (options.onError) {
          console.log('Read  selected error', err);
          options.onError(err);
        }
      }
    });
    return unsub;
  };

  const GetAllMessages = (options: IMsgReadOptions) => {
    const msgRef = collection(db, 'messages', options.msgId, 'item');
    const msgQuery = query(msgRef, orderBy('createdAt', 'desc'));
    onSnapshot(msgQuery, (querySnapshot) => {
      try {
        const msgs: IMsgOptions[] = [];
        querySnapshot.forEach((docRef) => {
          msgs.push({
            ...docRef.data(),
            id: docRef.id,
            createdAt: GetTime(docRef.data()?.createdAt, true),
          } as IMsgOptions);
        });
        if (options.onResolve) {
          options.onResolve(msgs.reverse(), querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
      } catch (err: any) {
        if (options.onError) {
          console.log('Read  selected error', err);
          options.onError(err);
        }
      }
    });
  };

  /**
   * load more in current conversation data
   * @param options         option parameters
   * @returns
   */
  const LoadMore = (options: IMsgReadOptions, size: number, lastItem: any) => {
    const msgRef = collection(db, 'messages', options.msgId, 'item');

    return new Promise((resolve, reject) => {
      if (!lastItem) {
        if (options.onResolve) {
          options.onResolve([], undefined);
        }
        resolve(null);
        return;
      }
      const msgQuery = query(msgRef, orderBy('createdAt', 'desc'), limit(size), startAfter(lastItem));
      getDocs(msgQuery).then((querySnapshot) => {
        try {
          const msgs: IMsgOptions[] = [];
          querySnapshot.docs.forEach((docRef) => {
            msgs.push({
              ...docRef.data(),
              id: docRef.id,
              createdAt: GetTime(docRef.data()?.createdAt, true),
            } as IMsgOptions);
          });
          if (options.onResolve) {
            if (size) {
              options.onResolve(msgs.reverse(), querySnapshot.docs[querySnapshot.docs.length - 1]);
            } else {
              options.onResolve(msgs, querySnapshot.docs[querySnapshot.docs.length - 1]);
            }
          }
          resolve(msgs.reverse());
        } catch (err: any) {
          if (options.onError) {
            options.onError(err);
            reject(err);
          }
        }
      });
    });
  };

  // Get id from chat users id
  const GetId = (user1: string, user2: string) => {
    return GenerateMessageId([user1, user2]);
  };

  // mark update a item message as seen
  const MarkAsSeen = async (options: any) => {
    try {
      const msgRef = doc(db, 'messages', options.msgId);
      await updateDoc(msgRef, {
        seens: options?.seens,
      });
    } catch (err: any) {
      console.log('Mark as seen error', err);
    }
  };

  const MarkAsUnseenFromUser = async (messageId: string, userId: string) => {
    try {
      const msgRef = doc(db, 'messages', messageId);
      await updateDoc(msgRef, {
        seen: false,
        lastSender: userId,
      });
    } catch (err: any) {
      console.log('Mark as unseen error', err);
    }
  };

  const EditMessage = async (options: any) => {
    try {
      const msgRef = doc(db, 'messages', options.msgId, 'item', options.msgItemId);
      await updateDoc(msgRef, {
        edited: true,
        body: options.body,
        updatedAt: Timestamp.fromDate(new Date()),
      });
      // check if message to edit is last message
      const isLastMsgItem = options.msgItemId === options.lastMsgItemId;
      if (isLastMsgItem) {
        const msgCollectionRef = doc(db, 'messages', options.msgId);
        const msgDoc = await getDoc(msgCollectionRef);
        if (msgDoc.exists()) {
          await updateDoc(msgCollectionRef, {
            lastbody: options.body,
          });
        }
      }
      return { ...options, edited: true };
    } catch (err: any) {
      console.log('Error on message edition ', err);
    }
  };

  const DeleteMessage = async (options: any) => {
    try {
      // check if message to delete is last message
      const isLastMsgItem = options.msgItemId === options.lastMsgItemId;
      const msgCollectionRef = doc(db, 'messages', options.msgId);
      const msgDoc = await getDoc(msgCollectionRef);
      const msgRef = doc(db, 'messages', options.msgId, 'item', options.msgItemId);
      await updateDoc(msgRef, {
        deleted: true,
        deletedBy: options.userId,
      });
      const notDeletedMsgs = options?.msgs?.filter((m: any) => !m.deleted);
      const updateLastMsgItem = isLastMsgItem ? notDeletedMsgs[notDeletedMsgs?.length - 2]?.id : '';
      if (msgDoc.exists() && updateLastMsgItem?.trim()?.length) {
        await updateDoc(msgCollectionRef, {
          lastMsgItemId: updateLastMsgItem,
          lastbody: notDeletedMsgs[notDeletedMsgs.length - 2]?.body,
        });
      }
    } catch (err: any) {
      console.log('Error on message edition ', err);
    }
  };

  return {
    // utils
    Trunc,
    GetTime,
    GenerateMessageId,
    GetId,
    // msg
    Send,
    ReadMsgList,
    ReadSelectedMsg,
    LoadMore,
    MarkAsSeen,
    MarkAsUnseenFromUser,
    EditMessage,
    DeleteMessage,
    GetAllMessages,
    CreateChat,
    UpdateParticipants,
  };
};
