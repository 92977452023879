const legalStatusOptions = [
  {
    label: 'EI',
    value: 'EI',
  },
  {
    label: 'EURL',
    value: 'EURL',
  },
  {
    label: 'SARL',
    value: 'SARL',
  },
  {
    label: 'SASU',
    value: 'SASU',
  },
  {
    label: 'SAS',
    value: 'SAS',
  },
  {
    label: 'SA',
    value: 'SA',
  },
  {
    label: 'SNC',
    value: 'SNC',
  },
  {
    label: 'SCA',
    value: 'SCA',
  },
];
export default legalStatusOptions;
