import { IonSearchbar } from '@ionic/react';
import React from 'react';
import './style.css';

interface AppSearchFilterProps {
  placeholder: string;
  filterKey: string;
  parentFilterKey?: string;
  items: Array<any>;
  rest?: any;
  onChange?: (e: any) => unknown;
  onDone?: (e: any) => unknown;
  isLongSearch?: boolean;
  useOnInput?: boolean;
  isUser?: boolean;
}
const normalizeText = (text: string) =>
  text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
export const AppSearchFilter: React.FC<AppSearchFilterProps> = ({
  placeholder,
  items,
  filterKey,
  parentFilterKey,
  onChange,
  onDone,
  rest = {},
  isLongSearch = false,
  useOnInput = false,
  isUser = false,
}: AppSearchFilterProps) => {
  const filtering = (val: string) => {
    const filteredItems = [...items].filter((item) => {
      const valueOfKey = parentFilterKey
        ? !isLongSearch
          ? item[parentFilterKey][filterKey]
          : item['attributes'][isUser ? 'user' : 'follower']['data'][parentFilterKey][filterKey]
        : item[filterKey];
      return normalizeText(valueOfKey).includes(normalizeText(val));
    });
    if (onDone) onDone(filteredItems);
  };
  const handleOnChange = (e: any) => {
    filtering(e.detail.value);
    if (onChange) onChange(e);
  };
  return (
    <IonSearchbar
      className="padding-search search-bar-custom"
      placeholder={placeholder}
      {...rest}
      onIonChange={(e) => handleOnChange(e)}
      onIonInput={(e) => {
        if (useOnInput) handleOnChange(e);
      }}
    />
  );
};
export default AppSearchFilter;
