import { useQuery } from '@apollo/client';
import { IonButton } from '@ionic/react';
import { useMemo, useState } from 'react';
import { InterestChip } from '../../../components';
import FilterHeader from '../../../components/FilterHeader';
import { GET_INTERESTS } from '../../../graphql/queries/interest.graphql';
import './style.css';

const FilterInterest: React.FC<IFilterProps> = ({ onChange, onCloseModal, filterInterestIds }) => {
  const { data: interestsData, loading: loadingInterest } = useQuery(GET_INTERESTS);
  const [interestsChecked, setInterestsChecked] = useState(filterInterestIds || []);

  const interests = useMemo(() => {
    return !loadingInterest ? interestsData?.interests?.data : [];
  }, [loadingInterest, interestsData]);

  const handleIDChange = (idInterest: never) => {
    if (interestsChecked.includes(idInterest)) {
      setInterestsChecked(interestsChecked.filter((value) => value !== idInterest));
    } else {
      setInterestsChecked([...interestsChecked, idInterest]);
    }
  };

  const saveFilter = () => {
    onChange(interestsChecked);
    onCloseModal(false);
  };

  return (
    <>
      <FilterHeader title="Filtrer par centre d'intérêts" close={() => onCloseModal(false)} color="text-blue" />
      <div className="ion-padding" style={{ maxHeight: '250px', overflowY: 'auto' }}>
        {interests?.map((interest, index) => (
          <InterestChip
            handleChange={handleIDChange}
            interest={interest}
            isSelected={interestsChecked.includes(interest?.id as never)}
            isOther={false}
            key={index}
            canCancel={interestsChecked.includes(interest?.id as never)}
          ></InterestChip>
        ))}
      </div>
      <IonButton
        expand="block"
        fill="solid"
        color="primary"
        className="d-absolute-bottom ion-padding-horizontal"
        onClick={saveFilter}
      >
        Sauvegarder
      </IonButton>
      <IonButton
        expand="block"
        fill="clear"
        className="d-absolute-bottom ion-padding-horizontal text-underligne text-black"
        onClick={() => onCloseModal(false)}
      >
        Annuler
      </IonButton>
    </>
  );
};

export default FilterInterest;
