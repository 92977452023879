import { IonAvatar } from '@ionic/react';

const AlgoliaUserAvatar: React.FC<any> = ({ imgUrl, classCustom, ...props }) => {
  const DEFAULT_AVATAR = 'https://ionicframework.com/docs/img/demos/avatar.svg';

  return (
    <IonAvatar {...props} className={`${classCustom} avatar-image`}>
      <img alt="" src={imgUrl || DEFAULT_AVATAR} />
    </IonAvatar>
  );
};

export default AlgoliaUserAvatar;
