import { FC, useCallback, useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import './style.css';
import { IonButton, IonIcon } from '@ionic/react';
import playAudioIcon from '../../../../assets/icons/playAudio.svg';
import { pauseOutline } from 'ionicons/icons';
import { useLongPress } from 'use-long-press';

interface IProps {
  media: any;
}

const AudioWaveForm: FC<IProps> = ({ media }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const waveformRef: any = useRef(null);
  const wavesurfer: any = useRef(null);

  const callback = useCallback((event: any) => {
    console.log('Open image');
  }, []);

  const bind = useLongPress(callback, {
    onStart: () => {
      console.log('START');
    },
    onFinish: () => {
      console.log('FINISH');
    },
    onCancel: (event: any) => {
      handlePlayPause();
    },
    onMove: () => {
      console.log('MOVE');
    },
    filterEvents: (event: any) => true,
    threshold: 250,
    captureEvent: true,
    cancelOnMovement: false,
    cancelOutsideElement: false,
  });

  useEffect(() => {
    wavesurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: '#03B070',
      progressColor: '#075e3e',
      height: 50,
      cursorWidth: 1,
      barWidth: 3,
      normalize: true,
    });

    const parts = media.url.split('/');
    const lastPart = parts[parts.length - 1];
    const fileUrl = `${process.env.REACT_APP_CDN_URL}/${lastPart}`;
    wavesurfer.current.load(fileUrl);

    wavesurfer.current.on('finish', () => {
      setIsPlaying(false);
    });

    return () => wavesurfer.current.destroy();
  }, [media.url]);

  const handlePlayPause = () => {
    if (!isReady) {
      const parts = media.url.split('/');
      const lastPart = parts[parts.length - 1];
      const fileUrl = `${process.env.REACT_APP_CDN_URL}/${lastPart}`;
      wavesurfer.current.load(fileUrl);
      wavesurfer.current.on('ready', () => {
        wavesurfer.current.play();
        setIsPlaying(true);
        setIsReady(true);
      });
    } else {
      const isPlayingT = wavesurfer.current.isPlaying();
      wavesurfer.current.playPause();
      setIsPlaying(!isPlayingT);
    }
  };

  return (
    <div className="chat__audio-container">
      <IonButton {...bind()} fill="clear">
        <IonIcon size="large" src={isPlaying ? pauseOutline : playAudioIcon} />
      </IonButton>
      <div ref={waveformRef} style={{ width: '100%', height: '50px' }} />
      {!!media?.duration && <div className="chat__audio-container__duration">{media.duration}s</div>}
    </div>
  );
};

export default AudioWaveForm;
