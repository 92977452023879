import { IonCol, IonSkeletonText } from '@ionic/react';
interface IInterestLoader {
  number?: number;
}

const InterestLoader: React.FC<IInterestLoader> = ({ number = 1 }) => {
  return (
    <>
      {Array.from({ length: number }, (_, index) => (
        <IonCol size="auto" key={index}>
          <div className="container-interest">
            <div className="container-picto-interest">
              <IonSkeletonText animated style={{ width: '100%' }} />
            </div>
            <IonSkeletonText animated style={{ width: '100%' }} />
          </div>
        </IonCol>
      ))}
    </>
  );
};

export default InterestLoader;
