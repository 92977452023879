import { IonButton, IonCol, IonHeader, IonIcon, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { arrowBackOutline, ellipsisVerticalOutline } from 'ionicons/icons';
import { ReactNode, useState } from 'react';
import { useHistory } from 'react-router';
import { DispoMenu } from '../../../pages/DispoDetail/components/DispoMenu';
import './style.css';

interface IHeader {
  title?: string;
  noButtonBack?: boolean;
  children?: ReactNode;
  toolbarChildren?: ReactNode;
  showMenuButton?: boolean;
  background?: string;
  action?: () => void;
}

const Header: React.FC<IHeader> = ({
  title,
  noButtonBack,
  children,
  toolbarChildren,
  showMenuButton = false,
  background,
  action,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const history = useHistory();
  const goBack = () => {
    if (!noButtonBack) {
      action ? action() : history.go(-1);
    }
  };

  const closePopover = () => {
    setShowPopover(false);
  };

  const openPopover = () => {
    setShowPopover(false);
  };

  return (
    <IonHeader mode="ios" style={{ background: background }}>
      <DispoMenu showPopover={showPopover} closePopover={closePopover} />
      <>
        {title && (
          <IonToolbar mode="ios">
            <IonRow>
              <IonCol size={!noButtonBack ? '1.5' : '0'}>
                <IonButton fill="clear" onClick={goBack}>
                  <IonIcon
                    slot="start"
                    className={noButtonBack ? 'opacity-0' : ''}
                    color={'dark'}
                    icon={arrowBackOutline}
                  ></IonIcon>
                </IonButton>
              </IonCol>
              <IonCol size={!noButtonBack ? '9.5' : ''} className="text-center">
                <IonTitle color="dark text-center">
                  <span className="title-only font-outfit">{title || ''}</span>
                </IonTitle>
                {!!showMenuButton && (
                  <IonIcon
                    icon={ellipsisVerticalOutline}
                    onClick={() => {
                      openPopover();
                    }}
                    id="dispo-context-menu"
                  ></IonIcon>
                )}
              </IonCol>
            </IonRow>
            {toolbarChildren}
          </IonToolbar>
        )}
        {children}
      </>
    </IonHeader>
  );
};

export default Header;
