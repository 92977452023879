import { graphql } from '../../models/gql';
export const GET_RELATION_USER_FOLLOWER = graphql(/* GraphQL */ `
  query userFollowers($filters: UserFollowerFiltersInput, $pagination: PaginationArg) {
    userFollowers(filters: $filters, pagination: $pagination) {
      data {
        id
        attributes {
          status
          user {
            data {
              id
              attributes {
                feedType
                firstname
                lastname
                username
                isPro
                companyName
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          attributes {
                            name
                            url
                            mime
                            hash
                            size
                            provider
                          }
                        }
                      }
                    }
                  }
                }
                profilePicture {
                  data {
                    id
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
                blockedUsers {
                  data {
                    id
                  }
                }
              }
            }
          }
          follower {
            data {
              id
              attributes {
                firstname
                lastname
                username
                isPro
                companyName
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          attributes {
                            name
                            url
                            mime
                            hash
                            size
                            provider
                          }
                        }
                      }
                    }
                  }
                }
                profilePicture {
                  data {
                    id
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const GET_RESTRICT_RELATION_USER_FOLLOWER = graphql(/* GraphQL */ `
  query countFollowers($filters: UserFollowerFiltersInput, $pagination: PaginationArg) {
    userFollowers(filters: $filters, pagination: $pagination) {
      data {
        id
        attributes {
          status
          user {
            data {
              id
              attributes {
                username
                isPro
                companyName
              }
            }
          }
          follower {
            data {
              id
              attributes {
                username
                isPro
                companyName
              }
            }
          }
        }
      }
    }
  }
`);
