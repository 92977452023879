import { graphql } from '../../models/gql';

export const INVITE_USER = graphql(/* GraphQL */ `
  mutation invite($input: ParticipationInput) {
    InviteToEvent(input: $input) {
      success
    }
  }
`);

export const ACCEPT_INVITATION = graphql(/* GraphQL */ `
  mutation accept($id: ID) {
    AcceptInvitation(id: $id) {
      success
    }
  }
`);

export const REFUSE_INVITATION = graphql(/* GraphQL */ `
  mutation refuse($id: ID) {
    RefuseInvitation(id: $id) {
      success
    }
  }
`);

export const NOTE_EVENT = graphql(/* GraphQL */ `
  mutation noteEvent($id: ID, $note: Int) {
    NoteEvent(id: $id, note: $note) {
      success
    }
  }
`);

export const PARTICIPE_TO_EVENT = graphql(/* GraphQL */ `
  mutation participeToEvent($id: ID) {
    ParticipeToEvent(id: $id) {
      success
    }
  }
`);

export const DELETE_USER_PARTICIPATION = graphql(/* GraphQL */ `
  mutation deleteEventUserParticipation($id: ID!) {
    deleteEventUserParticipation(id: $id) {
      data {
        id
      }
    }
  }
`);

export const CANCEL_PARTICIPATION = graphql(/* GraphQL */ `
  mutation CancelParticipation($id: ID) {
    CancelParticipation(id: $id) {
      success
    }
  }
`);
