import { IonButton, IonCol, IonHeader, IonIcon, IonRow, IonText, IonToolbar } from '@ionic/react';
import { arrowBackOutline, ellipsisVerticalOutline } from 'ionicons/icons';
import React, { ReactNode, useState } from 'react';
import { useHistory } from 'react-router';
import { Enum_Event_Type } from '../../models/gql/graphql';
import { DispoMenu } from '../../pages/DispoDetail/components/DispoMenu';
import './style.css';

interface IHeader {
  noButtonBack?: boolean;
  children?: ReactNode;
  showMenuButton?: boolean;
  action?: () => void;
  imageUrl?: string;
  eventTypeProps?: Enum_Event_Type;
  eventNameProps?: string;
}

const DispoHeaderProps: React.FC<IHeader> = ({
  noButtonBack,
  children,
  showMenuButton = false,
  action,
  imageUrl,
  eventTypeProps,
  eventNameProps,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const history = useHistory();
  const goBack = () => {
    history.go(-1);
  };

  let backgroundColorProps = eventTypeProps
    ? eventTypeProps === Enum_Event_Type.Private
      ? 'linear-gradient(to bottom, rgba(61, 39, 235, 0) 0%, rgba(61, 39, 235, 0.01) 30%, rgba(61, 39, 235, 0.02) 40%, rgba(61, 39, 235, 0.03) 50%, rgba(61, 39, 235, 1) 95%)'
      : 'linear-gradient(to bottom, rgba(4, 177, 113, 0) 0%, rgba(4, 177, 113, 0.01) 30%, rgba(4, 177, 113, 0.02) 40%, rgba(4, 177, 113, 0.03) 50%, rgba(4, 177, 113, 1) 95%)'
    : '';

  const closePopover = () => {
    setShowPopover(false);
  };

  const openPopover = () => {
    setShowPopover(false);
  };

  return (
    <div>
      <IonHeader mode="ios">
        <DispoMenu showPopover={showPopover} closePopover={closePopover} />
        <div
          className="full"
          style={{
            backgroundImage: `${backgroundColorProps}, url(${imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '332px',
          }}
        >
          <IonToolbar mode="ios" color="translucent" className="border-width-none">
            <IonCol size="6">
              {noButtonBack || (
                <IonButton fill="clear" onClick={goBack}>
                  <IonIcon slot="start" icon={arrowBackOutline} size="large" className="text-white"></IonIcon>
                </IonButton>
              )}
            </IonCol>
            <IonCol size="6">
              {!!showMenuButton && (
                <IonButton fill="clear" onClick={openPopover} id="dispo-context-menu" className="float-right">
                  <IonIcon icon={ellipsisVerticalOutline} className="text-white text-size-xxl"></IonIcon>
                </IonButton>
              )}
            </IonCol>
          </IonToolbar>
          <IonText className="font-outfit text-floating" color="light">
            <h2>{eventNameProps}</h2>
          </IonText>
        </div>
      </IonHeader>
    </div>
  );
};

export default DispoHeaderProps;
