import algoliasearch from 'algoliasearch';
import { useCallback, useMemo } from 'react';

export const useAlgolia = () => {
  const init: any = useMemo(() => {
    console.log('INIT');
    return algoliasearch(
      process.env.REACT_APP_ALGOLIA_APPLICATION_ID || '',
      process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY || ''
    );
  }, [algoliasearch]);

  return {
    init,
  };
};
