import { format, formatDistanceToNow, formatISO, setMinutes, addHours, addDays, isToday, isYesterday } from 'date-fns';
import { fr } from 'date-fns/locale';
import { removeLastColon } from './word';

export const convertTime = (timeString: string) => {
  const time = new Date(timeString);
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };
  return time.toLocaleTimeString('fr-FR', options);
};

export const convertDate = (dateString: string) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  };
  const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('fr-FR', options);
  return formatter.format(date);
};

export const splitDateTime = (dateString: string) => {
  const utcDate = new Date(dateString);
  let heure = format(utcDate, 'HH:mm');

  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('fr-FR', options);
  return {
    date: formatter.format(date),
    heure: heure,
  };
};

export const convertTimeForGraphql = (dateString: string, reduced = false) => {
  console.log(dateString, '54128754128754');
  let timeString;
  // dateString = XX:XX:XX
  if (dateString.length === 8) {
    const sp = dateString.split(':');
    if (sp.length === 3) {
      const d = new Date();
      d.setHours(parseInt(sp[0], 10));
      d.setMinutes(parseInt(sp[1], 10));
      timeString = d.toISOString();
    }
  }
  // dateString = XX:XX
  if (dateString.length === 5) {
    const sp = dateString.split(':');
    if (sp.length === 2) {
      const d = new Date();
      d.setHours(parseInt(sp[0], 10));
      d.setMinutes(parseInt(sp[1], 10));
      timeString = d.toISOString();
    }
  }
  if (!timeString) {
    timeString = new Date(dateString).toISOString();
  }
  if (timeString.split('T')[1]) {
    return timeString.split('T')[1].split('.')[0];
  } else if (timeString.split('.')[1]) {
    return timeString.split('.')[0];
  }
  return timeString;
};

export const convertLongTimeToShort = (timeString: string) => {
  const date = new Date().toISOString().split('T').shift();
  const virtual = new Date(`${date}T${timeString}Z`);

  if (virtual) {
    const tsWithLocalTz = virtual.toLocaleTimeString('fr', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return tsWithLocalTz.slice(0, -3);
  }
  return '';
};

export const convertDateToSimple = (dateISO: Date) => {
  const day = dateISO.getUTCDate().toString().padStart(2, '0');
  const month = (dateISO.getUTCMonth() + 1).toString().padStart(2, '0'); // Month value is zero-based (0-11)
  const year = dateISO.getUTCFullYear().toString();

  return `${year}-${month}-${day}`;
};

export const convertDateWithYear = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('fr-FR', options);
  return formatter.format(date);
};
export const isComingDate = (date: Date, strict: boolean = true) => {
  const dateNow = new Date();
  if (!strict) {
    dateNow.setHours(0, 0, 0, 0);
    return dateNow.getTime() <= date.getTime();
  }
  return dateNow.getTime() < date.getTime();
};
export const relativeTime = (timestamp: Date) => {
  const now = new Date();
  const targetDate = new Date(timestamp);
  const timeDiff = now.getTime() - targetDate.getTime();
  const local = { locale: fr };

  if (Math.floor(timeDiff / 1000) < 60) {
    return formatDistanceToNow(targetDate, local);
  }

  return format(targetDate, 'PPPp', local);
};

export const formatMininalWithoutYear = (timestamp: Date) => {
  return format(timestamp, 'dd/MM', { locale: fr });
};

export const initTime = (date: Date, hour: number, minutes = 0) => {
  return formatISO(setMinutes(addHours(date, hour), minutes));
};
export const initDate = (date: Date, day: number, minutes = 0) => {
  return formatISO(addDays(date, day));
};

export const formatDateWithCustomLogic = (dateString: string) => {
  if (!dateString) {
    return dateString;
  }

  if (dateString && !dateString.includes('/')) {
    return dateString.endsWith(':') ? removeLastColon(dateString) : dateString;
  }

  const date = new Date(dateString);
  try {
    if (isYesterday(date)) {
      // Si c'est hier, afficher "Hier" + l'heure
      return `Hier à ${format(date, 'HH:mm')}`;
    } else {
      // Pour toutes les autres dates, afficher la date complète + l'heure
      return `${format(date, 'dd/MM/yyyy à HH:mm')}`;
    }
  } catch (error) {
    return '';
  }
};

export const combineDateTime = (dateString: string, timeString: string) => {
  const dateParts = dateString.split('-');
  const timeParts = timeString.split(':');
  const millisecondsPart = timeParts[2].split('.');

  const combinedDate = new Date(
    parseInt(dateParts[0]), // Year
    parseInt(dateParts[1]) - 1, // Month
    parseInt(dateParts[2]), // Day
    parseInt(timeParts[0]), // Hours
    parseInt(timeParts[1]), // Minutes
    parseInt(millisecondsPart[0]), // Seconds
    parseInt(millisecondsPart[1]) || 0 // Milliseconds (default to 0 if not provided)
  );
  return combinedDate;
};
export const addTimeToDate = (time?: string) => {
  const timeAdd = time?.split(':');
  console.log({ time, timeAdd });
  const date = new Date();
  date.setHours(parseInt(timeAdd ? timeAdd[0] : ''));
  date.setMinutes(parseInt(timeAdd ? timeAdd[1] : ''));
  return date instanceof Date && !isNaN(date as any) ? formatISO(date) : '00:00';
};

/* Convert a real ISO 8601 UTC date stringto a BOGUS ISO 8601 local date string (with a Z).
 * utcDateString should be of format:  YYYY-MM-DDTHH-mm-ss.sssZ
 */
export const convertISO8601UTCtoLocalwZ = (utcDateString: string) => {
  const ISO_8601_UTC_REGEXP = /^(\d{4})(-\d{2})(-\d{2})T(\d{2})(\:\d{2}(\:\d{2}(\.\d{3})?)?)?Z$/;
  try {
    if (utcDateString.match(ISO_8601_UTC_REGEXP)) {
      let localDateString: string;
      let utcDate: Date = new Date(utcDateString);
      let tzOffset: number = new Date().getTimezoneOffset() * 60 * 1000;
      let newTime: number = utcDate.getTime() - tzOffset;
      let localDate: Date = new Date(newTime);
      localDateString = localDate.toJSON();
      return localDateString;
    } else {
      return utcDateString;
    }
  } catch (err) {
    alert('Date string is formatted incorrectly: \n' + err);
    return utcDateString;
  }
};

/* Convert a BOGUS ISO 8601 Local date string (with a Z) to a real ISO 8601 UTC date string.
 * localDateString should be of format:  YYYY-MM-DDTHH-mm-ss.sssZ
 */
export const convertISO8601LocalwZtoUTC = (localDateString: string) => {
  const ISO_8601_UTC_REGEXP = /^(\d{4})(-\d{2})(-\d{2})T(\d{2})(\:\d{2}(\:\d{2}(\.\d{3})?)?)?Z$/;
  try {
    if (localDateString.match(ISO_8601_UTC_REGEXP)) {
      let utcDateString: string;
      let localDate: Date = new Date(localDateString);
      let tzOffset: number = new Date().getTimezoneOffset() * 60 * 1000;
      let newTime: number = localDate.getTime() + tzOffset;
      let utcDate: Date = new Date(newTime);
      utcDateString = utcDate.toJSON();
      return utcDateString;
    } else {
      return localDateString;
    }
  } catch (err) {
    return localDateString;
  }
};

export const addHourToDate = (dateString: string) => {
  const date = new Date(dateString);
  date.setHours(new Date().getHours());
  date.setMinutes(new Date().getMinutes());
  date.setSeconds(new Date().getSeconds());
  return date;
};

export const isSameDate = (dateString1: any, dateString2: any) => {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
export const containsMilliseconds = (dateString: string) => {
  const millisecondsRegex = /\.000Z$/;
  return millisecondsRegex.test(dateString);
};

export const prepareDateForSave = (dateString: string) => {
  if (containsMilliseconds(dateString)) {
    return convertISO8601LocalwZtoUTC(dateString);
  } else {
    return new Date(dateString).toISOString();
  }
};
