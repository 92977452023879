import { graphql } from '../../models/gql';

export const UPDATE_USER = graphql(/* GraphQL */ `
  mutation updateUsersPermissionsUser($id: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`);

export const DELETE_USER = graphql(/* GraphQL */ `
  mutation deleteUsersPermissionsUser($id: ID!) {
    deleteUsersPermissionsUser(id: $id) {
      data {
        id
      }
    }
  }
`);

export const UPDATE_FEED = graphql(/* GraphQL */ `
  mutation updateFeedType($data: UsersPermissionsUserInput!) {
    updateMe(data: $data) {
      data {
        id
      }
    }
  }
`);

export const ADD_INTEREST_TO_USER = graphql(/* GraphQL */ `
  mutation addInterestToUser($id: ID!) {
    addInterestToUser(id: $id) {
      myInterests {
        id
        attributes {
          name
        }
      }
      otherInterests {
        id
        attributes {
          name
        }
      }
    }
  }
`);

export const REMOVE_INTEREST_TO_USER = graphql(/* GraphQL */ `
  mutation removeInterestToUser($id: ID!) {
    removeInterestToUser(id: $id) {
      myInterests {
        id
        attributes {
          name
        }
      }
      otherInterests {
        id
        attributes {
          name
        }
      }
    }
  }
`);

export const SET_USER_INTERESTS = graphql(/* GraphQL */ `
  mutation setUserInterests($ids: [ID]!) {
    setUserInterests(ids: $ids) {
      success
      message
    }
  }
`);

export const BLOCKED_USER = graphql(/* GraphQL */ `
  mutation blockedUser($id: ID!) {
    blockedUser(id: $id) {
      success
      message
    }
  }
`);

export const UNBLOCKED_USER = graphql(/* GraphQL */ `
  mutation unblockedUser($id: ID!) {
    unblockedUser(id: $id) {
      success
      message
    }
  }
`);

export const CREATE_USER_FOLLOWER = graphql(/* GraphQL */ `
  mutation createUserFollower($data: UserFollowerInput!) {
    createUserFollower(data: $data) {
      data {
        id
      }
    }
  }
`);

export const UPDATE_USER_FOLLOWER = graphql(/* GraphQL */ `
  mutation updateUserFollower($id: ID!, $data: UserFollowerInput!) {
    updateUserFollower(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`);

export const DELETE_USER_FOLLOWER = graphql(/* GraphQL */ `
  mutation deleteUserFollower($id: ID!) {
    deleteUserFollower(id: $id) {
      data {
        id
      }
    }
  }
`);
