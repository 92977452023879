import { IonAvatar, IonCol, IonIcon, IonLabel, IonRow, IonText } from '@ionic/react';
import { formatDistanceToNowStrict } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Dispatch, Fragment, SetStateAction, useEffect } from 'react';
import commentSvg from '../../../assets/icons/comment.svg';
import { CommentEntity, SubcommentEntity } from '../../../models/gql/graphql';
import { getUserId } from '../../../utils';
import './style.css';
interface IUserComment {
  comment: CommentEntity | SubcommentEntity;
  setSelectedComment: Dispatch<SetStateAction<CommentEntity | SubcommentEntity>>;
  setCommentToDelete?: Dispatch<SetStateAction<CommentEntity | SubcommentEntity>>;
  setToReport?: Dispatch<SetStateAction<CommentEntity | SubcommentEntity | null>>;
  onClick: () => void;
  onClickReporting?: () => void;
  isSubComment?: boolean;
}
export const UserComment: React.FC<IUserComment> = ({
  comment,
  setSelectedComment,
  onClick,
  setCommentToDelete,
  onClickReporting,
  setToReport,
  isSubComment = false,
}) => {
  const authorAttributes = comment.attributes?.author?.data?.attributes;
  const subcomments =
    comment.attributes && 'subcomments' in comment.attributes && comment.attributes.subcomments?.data
      ? comment.attributes.subcomments.data
      : [];

  const avatarUrl =
    authorAttributes?.profilePicture?.data?.attributes?.url ||
    (comment.attributes?.author?.data?.attributes?.avatar?.data?.attributes?.image.data?.attributes?.url
      ? comment.attributes?.author?.data?.attributes?.avatar.data?.attributes?.image.data?.attributes?.url
      : 'https://ionicframework.com/docs/img/demos/avatar.svg');

  const showReplyText = comment.attributes && 'subcomments' in comment.attributes;
  const isComment = comment.attributes && 'subcomments' in comment.attributes;
  const textClass = isComment ? 'text-size-11 ' : 'text-size-xxs';
  const publishedAt = comment.attributes?.publishedAt;

  let touchStartTimestamp = 0;
  const handleTouchStart = (e: any) => {
    e.stopPropagation();
    touchStartTimestamp = 1;
    setTimeout(() => {
      if (touchStartTimestamp !== 0) {
        onLongPress();
      }
    }, 1000);
  };

  const onLongPress = () => {
    if (comment.attributes?.author?.data?.id === getUserId()?.toString()) {
      onClick();
    }
    if (comment.attributes?.author?.data?.id !== getUserId()?.toString()) {
      if (onClickReporting) onClickReporting();
    }
    touchStartTimestamp = 0;
  };

  const nbSubComments = () => {
    if (subcomments.length === 0) {
      return 0;
    }
    return subcomments.filter((sub) => !sub.attributes?.deleted).length;
  };

  useEffect(() => {
    console.log({ authorAttributes });
  }, [authorAttributes]);

  return (
    <IonLabel text-wrap className={`full-width ${isSubComment ? '' : 'user-comment'}`} id={comment.id || ''}>
      <div style={{ marginBottom: isSubComment ? 0 : 5 }}>
        <IonRow
          className={`full-width row-comment-hover font-outfit ${textClass}`}
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchEnd={() => {
            touchStartTimestamp = 0;
          }}
        >
          <IonCol size="auto">
            <IonAvatar className={isComment ? 'width-25 height-25' : 'width-20 height-20'}>
              <img alt="avatar" src={avatarUrl} />
            </IonAvatar>
          </IonCol>
          <IonCol>
            <IonRow>
              <span
                className={'margin-r-5 text-capitalize text-overflow-ellipsis text-comment-post'}
                style={{ whiteSpace: 'pre-line', fontSize: isSubComment ? 16 : 16, lineHeight: '18px' }}
                dangerouslySetInnerHTML={{
                  __html:
                    '<span class="text-bolder">' +
                      (!authorAttributes?.isPro ? authorAttributes?.username : authorAttributes?.companyName) +
                      ' : </span>' +
                      comment.attributes?.content || '',
                }}
              />
            </IonRow>
            <IonRow className="ion-align-items-center" style={{ marginTop: 2 }}>
              <IonText className="text-capitalize text-gray-200" style={{ fontSize: 10 }}>
                {publishedAt ? formatDistanceToNowStrict(new Date(publishedAt), { addSuffix: true, locale: fr }) : ''}
              </IonText>
              {showReplyText && (
                <IonText
                  class={`ion-margin-start text-blue ${textClass}`}
                  onClick={() => {
                    setSelectedComment(comment);
                  }}
                  style={{ fontWeight: 500, marginLeft: 10, fontSize: 10 }}
                >
                  Répondre
                </IonText>
              )}
            </IonRow>
          </IonCol>
          {!!(isComment && nbSubComments()) && (
            <IonCol className="text-blue" size="auto">
              <IonRow className="text-bold ion-align-items-center">
                {nbSubComments()}
                <IonIcon icon={commentSvg} size="small" />
              </IonRow>
            </IonCol>
          )}
        </IonRow>
        {nbSubComments() > 0 && (
          <IonRow className="full-width">
            <div className="full-width ion-margin-start ion-padding-start">
              {subcomments
                .filter((sub) => !sub.attributes?.deleted)
                .map((comment, index) => (
                  <Fragment key={index}>
                    <UserComment
                      comment={comment}
                      setSelectedComment={setSelectedComment}
                      onClick={() => {
                        setCommentToDelete && setCommentToDelete(comment);
                      }}
                      onClickReporting={() => {
                        setToReport && setToReport(comment);
                      }}
                      isSubComment={true}
                    />
                  </Fragment>
                ))}
            </div>
          </IonRow>
        )}
      </div>
    </IonLabel>
  );
};
