/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  GraphQLJSONObject: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** A time string with format HH:mm:ss.SSS */
  Time: { input: any; output: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
};

export type AuthPhoneInput = {
  idUser?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  reactivate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AuthRegisterInput = {
  acceptCGU?: InputMaybe<Scalars['Boolean']['input']>;
  acceptNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  identityDocument?: InputMaybe<Scalars['ID']['input']>;
  isPro?: InputMaybe<Scalars['Boolean']['input']>;
  job?: InputMaybe<Scalars['String']['input']>;
  kbis?: InputMaybe<Scalars['ID']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  legalStatus?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
};

export type AuthResponsePayload = {
  __typename?: 'AuthResponsePayload';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AuthVerifyCodeInput = {
  phone: Scalars['String']['input'];
  smsCode: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type Avatar = {
  __typename?: 'Avatar';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  image: UploadFileEntityResponse;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AvatarEntity = {
  __typename?: 'AvatarEntity';
  attributes?: Maybe<Avatar>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type AvatarEntityResponse = {
  __typename?: 'AvatarEntityResponse';
  data?: Maybe<AvatarEntity>;
};

export type AvatarEntityResponseCollection = {
  __typename?: 'AvatarEntityResponseCollection';
  data: Array<AvatarEntity>;
  meta: ResponseCollectionMeta;
};

export type AvatarFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<AvatarFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<AvatarFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<AvatarFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type AvatarInput = {
  image?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  contains?: InputMaybe<Scalars['Boolean']['input']>;
  containsi?: InputMaybe<Scalars['Boolean']['input']>;
  endsWith?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  eqi?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']['input']>;
  notContainsi?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Comment = {
  __typename?: 'Comment';
  author?: Maybe<UsersPermissionsUserEntityResponse>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  post?: Maybe<PostEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  subcomments?: Maybe<SubcommentRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CommentSubcommentsArgs = {
  filters?: InputMaybe<SubcommentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CommentEntity = {
  __typename?: 'CommentEntity';
  attributes?: Maybe<Comment>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CommentEntityResponse = {
  __typename?: 'CommentEntityResponse';
  data?: Maybe<CommentEntity>;
};

export type CommentEntityResponseCollection = {
  __typename?: 'CommentEntityResponseCollection';
  data: Array<CommentEntity>;
  meta: ResponseCollectionMeta;
};

export type CommentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CommentFiltersInput>>>;
  author?: InputMaybe<UsersPermissionsUserFiltersInput>;
  content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  deleted?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CommentFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CommentFiltersInput>>>;
  post?: InputMaybe<PostFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  subcomments?: InputMaybe<SubcommentFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CommentInput = {
  author?: InputMaybe<Scalars['ID']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  post?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  subcomments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CommentRelationResponseCollection = {
  __typename?: 'CommentRelationResponseCollection';
  data: Array<CommentEntity>;
};

export type ComponentNotificationSetting = {
  __typename?: 'ComponentNotificationSetting';
  duration?: Maybe<Enum_Componentnotificationsetting_Duration>;
  id: Scalars['ID']['output'];
  message?: Maybe<MessageEntityResponse>;
  startDatetime?: Maybe<Scalars['DateTime']['output']>;
};

export type ComponentNotificationSettingFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentNotificationSettingFiltersInput>>>;
  duration?: InputMaybe<StringFilterInput>;
  message?: InputMaybe<MessageFiltersInput>;
  not?: InputMaybe<ComponentNotificationSettingFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentNotificationSettingFiltersInput>>>;
  startDatetime?: InputMaybe<DateTimeFilterInput>;
};

export type ComponentNotificationSettingInput = {
  duration?: InputMaybe<Enum_Componentnotificationsetting_Duration>;
  id?: InputMaybe<Scalars['ID']['input']>;
  message?: InputMaybe<Scalars['ID']['input']>;
  startDatetime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ComponentOnBoardOnBoard = {
  __typename?: 'ComponentOnBoardOnBoard';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  media?: Maybe<UploadFileEntityResponse>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentOnBoardOnBoardFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentOnBoardOnBoardFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentOnBoardOnBoardFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentOnBoardOnBoardFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentOnBoardOnBoardInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  media?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePaymentIntentInput = {
  amount: Scalars['Float']['input'];
  eventId?: InputMaybe<Scalars['String']['input']>;
  stripeAccount?: InputMaybe<Scalars['String']['input']>;
};

export type DateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  contains?: InputMaybe<Scalars['Date']['input']>;
  containsi?: InputMaybe<Scalars['Date']['input']>;
  endsWith?: InputMaybe<Scalars['Date']['input']>;
  eq?: InputMaybe<Scalars['Date']['input']>;
  eqi?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  ne?: InputMaybe<Scalars['Date']['input']>;
  not?: InputMaybe<DateFilterInput>;
  notContains?: InputMaybe<Scalars['Date']['input']>;
  notContainsi?: InputMaybe<Scalars['Date']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  startsWith?: InputMaybe<Scalars['Date']['input']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  contains?: InputMaybe<Scalars['DateTime']['input']>;
  containsi?: InputMaybe<Scalars['DateTime']['input']>;
  endsWith?: InputMaybe<Scalars['DateTime']['input']>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  eqi?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']['input']>;
  notContainsi?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum Enum_Componentnotificationsetting_Duration {
  Day = 'day',
  Forever = 'forever',
  Week = 'week',
}

export enum Enum_Eventuserparticipation_Status {
  Accepted = 'accepted',
  Canceled = 'canceled',
  Invited = 'invited',
  PaymentCanceled = 'payment_canceled',
  PaymentCreated = 'payment_created',
  PaymentFailed = 'payment_failed',
  Refused = 'refused',
  Waiting = 'waiting',
}

export enum Enum_Event_Paymenttype {
  Contributive = 'Contributive',
  Gratuite = 'Gratuite',
  Payante = 'Payante',
}

export enum Enum_Event_Type {
  Private = 'private',
  Public = 'public',
}

export enum Enum_Mayasquadstripeintegrationssale_Status {
  Canceled = 'canceled',
  Charged = 'charged',
  Failed = 'failed',
  PiCreated = 'pi_created',
  PiSucceded = 'pi_succeded',
}

export enum Enum_Notification_Type {
  AskToFollow = 'ask_to_follow',
  DispoAcceptWaitingParticipation = 'dispo_accept_waiting_participation',
  DispoAccepted = 'dispo_accepted',
  DispoCompleted = 'dispo_completed',
  DispoCreated = 'dispo_created',
  DispoDeleted = 'dispo_deleted',
  DispoEdited = 'dispo_edited',
  DispoExpired = 'dispo_expired',
  DispoInvitation = 'dispo_invitation',
  DispoInvitationAccepted = 'dispo_invitation_accepted',
  DispoInvitationRefused = 'dispo_invitation_refused',
  DispoPublished = 'dispo_published',
  DispoRefused = 'dispo_refused',
  DispoRemind_1d = 'dispo_remind_1d',
  DispoRemind_6h = 'dispo_remind_6h',
  DispoRemindWaiting = 'dispo_remind_waiting',
  DispoToRate = 'dispo_to_rate',
  DispoUnpublished = 'dispo_unpublished',
  FollowAccepted = 'follow_accepted',
  FollowRefused = 'follow_refused',
  FollowRequest = 'follow_request',
  Like = 'like',
  NewComment = 'new_comment',
  NewFollower = 'new_follower',
  PostDeleted = 'post_deleted',
}

export enum Enum_Report_Category {
  ArnaqueOuFraude = 'Arnaque_ou_fraude',
  Autre = 'Autre',
  DiscoursOuSymbolesHaineux = 'Discours_ou_symboles_haineux',
  FaussesInformations = 'Fausses_informations',
  NuditeOuActesSexuels = 'Nudite_ou_actes_sexuels',
  Spam = 'Spam',
  VenteDeProduitsIllegauxOuReglementes = 'Vente_de_produits_illegaux_ou_reglementes',
  ViolenceOuOrganisationsDangereuses = 'Violence_ou_organisations_dangereuses',
}

export enum Enum_Report_Status {
  ToDo = 'To_do',
  Done = 'done',
}

export enum Enum_Report_Type {
  Commentaire = 'Commentaire',
  Evenement = 'Evenement',
  Publication = 'Publication',
  Utilisateur = 'Utilisateur',
}

export enum Enum_Userfollower_Status {
  Accepted = 'accepted',
  Asked = 'asked',
  Blocked = 'blocked',
  Refused = 'refused',
}

export enum Enum_Userspermissionsuser_Feedtype {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export enum Enum_Userspermissionsuser_Legalstatus {
  Ei = 'EI',
  Eurl = 'EURL',
  Sa = 'SA',
  Sarl = 'SARL',
  Sas = 'SAS',
  Sasu = 'SASU',
  Sca = 'SCA',
  Snc = 'SNC',
}

export enum Enum_Userspermissionsuser_Stripecapabilitiesstatus {
  Active = 'active',
  Inactive = 'inactive',
  PayoutDisabled = 'payout_disabled',
  Pending = 'pending',
  Rejected = 'rejected',
}

export type Event = {
  __typename?: 'Event';
  addressCity?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressLat?: Maybe<Scalars['Float']['output']>;
  addressLine?: Maybe<Scalars['String']['output']>;
  addressLng?: Maybe<Scalars['Float']['output']>;
  addressZipcode?: Maybe<Scalars['String']['output']>;
  allowUserToShare?: Maybe<Scalars['Boolean']['output']>;
  beAccompanied?: Maybe<Scalars['Boolean']['output']>;
  beginningDateTime?: Maybe<Scalars['DateTime']['output']>;
  chatRemoved?: Maybe<Scalars['Boolean']['output']>;
  coverCustomImage?: Maybe<UploadFileEntityResponse>;
  coverTedispoImage?: Maybe<ImageEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<UsersPermissionsUserEntityResponse>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endHour?: Maybe<Scalars['Time']['output']>;
  eventDate?: Maybe<Scalars['Date']['output']>;
  eventEndDate?: Maybe<Scalars['Date']['output']>;
  expirationDateTime?: Maybe<Scalars['DateTime']['output']>;
  expired?: Maybe<Scalars['Boolean']['output']>;
  indexRemoved?: Maybe<Scalars['Boolean']['output']>;
  interests?: Maybe<InterestRelationResponseCollection>;
  maxInvited?: Maybe<Scalars['Int']['output']>;
  moneyRaised?: Maybe<Scalars['Float']['output']>;
  moneyToRaise?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  participations?: Maybe<EventUserParticipationRelationResponseCollection>;
  paymentMessage?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<Enum_Event_Paymenttype>;
  pending?: Maybe<Scalars['Boolean']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  secondaryImages?: Maybe<UploadFileRelationResponseCollection>;
  startHour?: Maybe<Scalars['Time']['output']>;
  type?: Maybe<Enum_Event_Type>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  useChat?: Maybe<Scalars['Boolean']['output']>;
  useQRCode?: Maybe<Scalars['Boolean']['output']>;
};

export type EventInterestsArgs = {
  filters?: InputMaybe<InterestFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type EventParticipationsArgs = {
  filters?: InputMaybe<EventUserParticipationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type EventSecondaryImagesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type EventEntity = {
  __typename?: 'EventEntity';
  attributes?: Maybe<Event>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type EventEntityResponse = {
  __typename?: 'EventEntityResponse';
  data?: Maybe<EventEntity>;
};

export type EventEntityResponseCollection = {
  __typename?: 'EventEntityResponseCollection';
  data: Array<EventEntity>;
  meta: ResponseCollectionMeta;
};

export type EventFiltersInput = {
  addressCity?: InputMaybe<StringFilterInput>;
  addressCountry?: InputMaybe<StringFilterInput>;
  addressLat?: InputMaybe<FloatFilterInput>;
  addressLine?: InputMaybe<StringFilterInput>;
  addressLng?: InputMaybe<FloatFilterInput>;
  addressZipcode?: InputMaybe<StringFilterInput>;
  allowUserToShare?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<EventFiltersInput>>>;
  beAccompanied?: InputMaybe<BooleanFilterInput>;
  beginningDateTime?: InputMaybe<DateTimeFilterInput>;
  chatRemoved?: InputMaybe<BooleanFilterInput>;
  coverTedispoImage?: InputMaybe<ImageFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  creator?: InputMaybe<UsersPermissionsUserFiltersInput>;
  deleted?: InputMaybe<BooleanFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  endHour?: InputMaybe<TimeFilterInput>;
  eventDate?: InputMaybe<DateFilterInput>;
  eventEndDate?: InputMaybe<DateFilterInput>;
  expirationDateTime?: InputMaybe<DateTimeFilterInput>;
  expirationNotificationSent?: InputMaybe<BooleanFilterInput>;
  expired?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  indexRemoved?: InputMaybe<BooleanFilterInput>;
  interests?: InputMaybe<InterestFiltersInput>;
  maxInvited?: InputMaybe<IntFilterInput>;
  moneyRaised?: InputMaybe<FloatFilterInput>;
  moneyToRaise?: InputMaybe<FloatFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<EventFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EventFiltersInput>>>;
  participationNotificationSentOneDay?: InputMaybe<BooleanFilterInput>;
  participationNotificationSentSixHours?: InputMaybe<BooleanFilterInput>;
  participations?: InputMaybe<EventUserParticipationFiltersInput>;
  paymentMessage?: InputMaybe<StringFilterInput>;
  paymentType?: InputMaybe<StringFilterInput>;
  pending?: InputMaybe<BooleanFilterInput>;
  price?: InputMaybe<FloatFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  startHour?: InputMaybe<TimeFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  useChat?: InputMaybe<BooleanFilterInput>;
  useQRCode?: InputMaybe<BooleanFilterInput>;
};

export type EventInput = {
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressCountry?: InputMaybe<Scalars['String']['input']>;
  addressLat?: InputMaybe<Scalars['Float']['input']>;
  addressLine?: InputMaybe<Scalars['String']['input']>;
  addressLng?: InputMaybe<Scalars['Float']['input']>;
  addressZipcode?: InputMaybe<Scalars['String']['input']>;
  allowUserToShare?: InputMaybe<Scalars['Boolean']['input']>;
  beAccompanied?: InputMaybe<Scalars['Boolean']['input']>;
  beginningDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  chatRemoved?: InputMaybe<Scalars['Boolean']['input']>;
  coverCustomImage?: InputMaybe<Scalars['ID']['input']>;
  coverTedispoImage?: InputMaybe<Scalars['ID']['input']>;
  creator?: InputMaybe<Scalars['ID']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endHour?: InputMaybe<Scalars['Time']['input']>;
  eventDate?: InputMaybe<Scalars['Date']['input']>;
  eventEndDate?: InputMaybe<Scalars['Date']['input']>;
  expirationDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  expirationNotificationSent?: InputMaybe<Scalars['Boolean']['input']>;
  expired?: InputMaybe<Scalars['Boolean']['input']>;
  indexRemoved?: InputMaybe<Scalars['Boolean']['input']>;
  interests?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxInvited?: InputMaybe<Scalars['Int']['input']>;
  moneyRaised?: InputMaybe<Scalars['Float']['input']>;
  moneyToRaise?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  participationNotificationSentOneDay?: InputMaybe<Scalars['Boolean']['input']>;
  participationNotificationSentSixHours?: InputMaybe<Scalars['Boolean']['input']>;
  participations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  paymentMessage?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<Enum_Event_Paymenttype>;
  pending?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  secondaryImages?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startHour?: InputMaybe<Scalars['Time']['input']>;
  type?: InputMaybe<Enum_Event_Type>;
  useChat?: InputMaybe<Scalars['Boolean']['input']>;
  useQRCode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventRelationResponseCollection = {
  __typename?: 'EventRelationResponseCollection';
  data: Array<EventEntity>;
};

export type EventUserParticipation = {
  __typename?: 'EventUserParticipation';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  event?: Maybe<EventEntityResponse>;
  note?: Maybe<Scalars['Int']['output']>;
  qrCode?: Maybe<Scalars['String']['output']>;
  qrCodeUsed?: Maybe<Scalars['DateTime']['output']>;
  sale?: Maybe<MayasquadStripeIntegrationsSaleEntityResponse>;
  status?: Maybe<Enum_Eventuserparticipation_Status>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type EventUserParticipationEntity = {
  __typename?: 'EventUserParticipationEntity';
  attributes?: Maybe<EventUserParticipation>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type EventUserParticipationEntityResponse = {
  __typename?: 'EventUserParticipationEntityResponse';
  data?: Maybe<EventUserParticipationEntity>;
};

export type EventUserParticipationEntityResponseCollection = {
  __typename?: 'EventUserParticipationEntityResponseCollection';
  data: Array<EventUserParticipationEntity>;
  meta: ResponseCollectionMeta;
};

export type EventUserParticipationFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<EventUserParticipationFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  deleted?: InputMaybe<BooleanFilterInput>;
  event?: InputMaybe<EventFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<EventUserParticipationFiltersInput>;
  note?: InputMaybe<IntFilterInput>;
  or?: InputMaybe<Array<InputMaybe<EventUserParticipationFiltersInput>>>;
  qrCode?: InputMaybe<StringFilterInput>;
  qrCodeUsed?: InputMaybe<DateTimeFilterInput>;
  sale?: InputMaybe<MayasquadStripeIntegrationsSaleFiltersInput>;
  status?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type EventUserParticipationInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  event?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['Int']['input']>;
  qrCode?: InputMaybe<Scalars['String']['input']>;
  qrCodeUsed?: InputMaybe<Scalars['DateTime']['input']>;
  sale?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Enum_Eventuserparticipation_Status>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type EventUserParticipationRelationResponseCollection = {
  __typename?: 'EventUserParticipationRelationResponseCollection';
  data: Array<EventUserParticipationEntity>;
};

export type EventWeather = {
  __typename?: 'EventWeather';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
  dispo_id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weatherCondition?: Maybe<Scalars['String']['output']>;
};

export type EventWeatherEntity = {
  __typename?: 'EventWeatherEntity';
  attributes?: Maybe<EventWeather>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type EventWeatherEntityResponse = {
  __typename?: 'EventWeatherEntityResponse';
  data?: Maybe<EventWeatherEntity>;
};

export type EventWeatherEntityResponseCollection = {
  __typename?: 'EventWeatherEntityResponseCollection';
  data: Array<EventWeatherEntity>;
  meta: ResponseCollectionMeta;
};

export type EventWeatherFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<EventWeatherFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  data?: InputMaybe<JsonFilterInput>;
  date?: InputMaybe<DateFilterInput>;
  dispo_id?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<EventWeatherFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EventWeatherFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  weatherCondition?: InputMaybe<StringFilterInput>;
};

export type EventWeatherInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  dispo_id?: InputMaybe<Scalars['Int']['input']>;
  weatherCondition?: InputMaybe<Scalars['String']['input']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contains?: InputMaybe<Scalars['Float']['input']>;
  containsi?: InputMaybe<Scalars['Float']['input']>;
  endsWith?: InputMaybe<Scalars['Float']['input']>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  eqi?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']['input']>;
  notContainsi?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startsWith?: InputMaybe<Scalars['Float']['input']>;
};

export type ForbiddenWord = {
  __typename?: 'ForbiddenWord';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  formatted?: Maybe<Scalars['JSON']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ForbiddenWordEntity = {
  __typename?: 'ForbiddenWordEntity';
  attributes?: Maybe<ForbiddenWord>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ForbiddenWordEntityResponse = {
  __typename?: 'ForbiddenWordEntityResponse';
  data?: Maybe<ForbiddenWordEntity>;
};

export type ForbiddenWordInput = {
  formatted?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GenericMorph =
  | Avatar
  | Comment
  | ComponentNotificationSetting
  | ComponentOnBoardOnBoard
  | Event
  | EventUserParticipation
  | EventWeather
  | ForbiddenWord
  | I18NLocale
  | Image
  | Interest
  | MayasquadStripeIntegrationsSale
  | Message
  | MessageItem
  | Notification
  | NotificationAdmin
  | NotificationsEmailTemplate
  | NotificationsPushTemplate
  | OnBoarding
  | Post
  | Reaction
  | Report
  | Subcomment
  | UploadFile
  | UploadFolder
  | UserFollower
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsUser;

export type GooglePassData = {
  __typename?: 'GooglePassData';
  token?: Maybe<Scalars['String']['output']>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  containsi?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  eqi?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notContainsi?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
};

export type Image = {
  __typename?: 'Image';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  image?: Maybe<UploadFileEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ImageEntity = {
  __typename?: 'ImageEntity';
  attributes?: Maybe<Image>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ImageEntityResponse = {
  __typename?: 'ImageEntityResponse';
  data?: Maybe<ImageEntity>;
};

export type ImageEntityResponseCollection = {
  __typename?: 'ImageEntityResponseCollection';
  data: Array<ImageEntity>;
  meta: ResponseCollectionMeta;
};

export type ImageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ImageFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<ImageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ImageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ImageInput = {
  image?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains?: InputMaybe<Scalars['Int']['input']>;
  containsi?: InputMaybe<Scalars['Int']['input']>;
  endsWith?: InputMaybe<Scalars['Int']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  eqi?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']['input']>;
  notContainsi?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startsWith?: InputMaybe<Scalars['Int']['input']>;
};

export type Interest = {
  __typename?: 'Interest';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  picto?: Maybe<UploadFileEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type InterestEntity = {
  __typename?: 'InterestEntity';
  attributes?: Maybe<Interest>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type InterestEntityResponse = {
  __typename?: 'InterestEntityResponse';
  data?: Maybe<InterestEntity>;
};

export type InterestEntityResponseCollection = {
  __typename?: 'InterestEntityResponseCollection';
  data: Array<InterestEntity>;
  meta: ResponseCollectionMeta;
};

export type InterestFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<InterestFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<InterestFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<InterestFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type InterestInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  picto?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InterestRelationResponseCollection = {
  __typename?: 'InterestRelationResponseCollection';
  data: Array<InterestEntity>;
};

export type InterestsMe = {
  __typename?: 'InterestsMe';
  myInterests?: Maybe<Array<Maybe<InterestEntity>>>;
  otherInterests?: Maybe<Array<Maybe<InterestEntity>>>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  contains?: InputMaybe<Scalars['JSON']['input']>;
  containsi?: InputMaybe<Scalars['JSON']['input']>;
  endsWith?: InputMaybe<Scalars['JSON']['input']>;
  eq?: InputMaybe<Scalars['JSON']['input']>;
  eqi?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  ne?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']['input']>;
  notContainsi?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  startsWith?: InputMaybe<Scalars['JSON']['input']>;
};

export type MayasquadStripeIntegrationsSale = {
  __typename?: 'MayasquadStripeIntegrationsSale';
  buyer?: Maybe<UsersPermissionsUserEntityResponse>;
  commissionPrice?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  eventUserParticipation?: Maybe<EventUserParticipationEntityResponse>;
  info?: Maybe<Scalars['JSON']['output']>;
  paymentIntentId?: Maybe<Scalars['String']['output']>;
  saleDate?: Maybe<Scalars['DateTime']['output']>;
  salePrice?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Enum_Mayasquadstripeintegrationssale_Status>;
  stripeAccount?: Maybe<Scalars['String']['output']>;
  stripeStatus?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MayasquadStripeIntegrationsSaleEntity = {
  __typename?: 'MayasquadStripeIntegrationsSaleEntity';
  attributes?: Maybe<MayasquadStripeIntegrationsSale>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type MayasquadStripeIntegrationsSaleEntityResponse = {
  __typename?: 'MayasquadStripeIntegrationsSaleEntityResponse';
  data?: Maybe<MayasquadStripeIntegrationsSaleEntity>;
};

export type MayasquadStripeIntegrationsSaleEntityResponseCollection = {
  __typename?: 'MayasquadStripeIntegrationsSaleEntityResponseCollection';
  data: Array<MayasquadStripeIntegrationsSaleEntity>;
  meta: ResponseCollectionMeta;
};

export type MayasquadStripeIntegrationsSaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<MayasquadStripeIntegrationsSaleFiltersInput>>>;
  buyer?: InputMaybe<UsersPermissionsUserFiltersInput>;
  commissionPrice?: InputMaybe<FloatFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  currency?: InputMaybe<StringFilterInput>;
  eventUserParticipation?: InputMaybe<EventUserParticipationFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  info?: InputMaybe<JsonFilterInput>;
  not?: InputMaybe<MayasquadStripeIntegrationsSaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<MayasquadStripeIntegrationsSaleFiltersInput>>>;
  paymentIntentId?: InputMaybe<StringFilterInput>;
  saleDate?: InputMaybe<DateTimeFilterInput>;
  salePrice?: InputMaybe<FloatFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  stripeAccount?: InputMaybe<StringFilterInput>;
  stripeStatus?: InputMaybe<StringFilterInput>;
  totalPrice?: InputMaybe<FloatFilterInput>;
  transactionId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type MayasquadStripeIntegrationsSaleInput = {
  buyer?: InputMaybe<Scalars['ID']['input']>;
  commissionPrice?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  eventUserParticipation?: InputMaybe<Scalars['ID']['input']>;
  info?: InputMaybe<Scalars['JSON']['input']>;
  paymentIntentId?: InputMaybe<Scalars['String']['input']>;
  saleDate?: InputMaybe<Scalars['DateTime']['input']>;
  salePrice?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Enum_Mayasquadstripeintegrationssale_Status>;
  stripeAccount?: InputMaybe<Scalars['String']['input']>;
  stripeStatus?: InputMaybe<Scalars['String']['input']>;
  totalPrice?: InputMaybe<Scalars['Float']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export type Message = {
  __typename?: 'Message';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dispoName?: Maybe<Scalars['String']['output']>;
  firebaseId: Scalars['String']['output'];
  participants?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MessageParticipantsArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MessageEntity = {
  __typename?: 'MessageEntity';
  attributes?: Maybe<Message>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type MessageEntityResponse = {
  __typename?: 'MessageEntityResponse';
  data?: Maybe<MessageEntity>;
};

export type MessageEntityResponseCollection = {
  __typename?: 'MessageEntityResponseCollection';
  data: Array<MessageEntity>;
  meta: ResponseCollectionMeta;
};

export type MessageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<MessageFiltersInput>>>;
  content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  dispoName?: InputMaybe<StringFilterInput>;
  firebaseId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<MessageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<MessageFiltersInput>>>;
  participants?: InputMaybe<UsersPermissionsUserFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type MessageInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  dispoName?: InputMaybe<Scalars['String']['input']>;
  firebaseId?: InputMaybe<Scalars['String']['input']>;
  participants?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MessageItem = {
  __typename?: 'MessageItem';
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  firebaseDocId?: Maybe<Scalars['String']['output']>;
  firebaseItemId?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MessageItemEntity = {
  __typename?: 'MessageItemEntity';
  attributes?: Maybe<MessageItem>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type MessageItemEntityResponse = {
  __typename?: 'MessageItemEntityResponse';
  data?: Maybe<MessageItemEntity>;
};

export type MessageItemEntityResponseCollection = {
  __typename?: 'MessageItemEntityResponseCollection';
  data: Array<MessageItemEntity>;
  meta: ResponseCollectionMeta;
};

export type MessageItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<MessageItemFiltersInput>>>;
  body?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  firebaseDocId?: InputMaybe<StringFilterInput>;
  firebaseItemId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<MessageItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<MessageItemFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type MessageItemInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  firebaseDocId?: InputMaybe<Scalars['String']['input']>;
  firebaseItemId?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept invitation */
  AcceptInvitation: SuccessData;
  /** register with phone */
  AuthRegister?: Maybe<AuthResponsePayload>;
  /** verify the code */
  AuthVerifySmsCode?: Maybe<UsersPermissionsRefreshTokenPayload>;
  /** auth with phone */
  AuthWithPhone?: Maybe<AuthResponsePayload>;
  /** cancel invitation */
  CancelParticipation: SuccessData;
  /** check chat message */
  CheckMessage: SuccessData;
  CreateGooglePass?: Maybe<GooglePassData>;
  /** create a payment intent */
  CreatePaymentIntent?: Maybe<StripeDataResponse>;
  /** create an account  */
  CreateStripeAccount?: Maybe<StripeDataResponse>;
  /** create an account links */
  CreateStripeAccountLinks?: Maybe<StripeDataResponse>;
  DownloadTicket?: Maybe<PdfData>;
  /** Follow user */
  FollowUser: SuccessData;
  GeneratePass?: Maybe<PassData>;
  /** Invite contact */
  InviteContact: SuccessData;
  /** Invite users to event */
  InviteToEvent: SuccessData;
  /** Mark all notifications as seen */
  MarkAsSeen: SuccessData;
  /** note event */
  NoteEvent: SuccessData;
  /** Participe to event */
  ParticipeToEvent: SuccessData;
  /** refuse invitation */
  RefuseInvitation: SuccessData;
  /** verify phone */
  VerifyPhone?: Maybe<AuthResponsePayload>;
  /** Add interest to user */
  addInterestToUser?: Maybe<InterestsMe>;
  /** Add user to blockedList */
  blockedUser?: Maybe<SuccessData>;
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createAvatar?: Maybe<AvatarEntityResponse>;
  createComment?: Maybe<CommentEntityResponse>;
  createEvent?: Maybe<EventEntityResponse>;
  createEventUserParticipation?: Maybe<EventUserParticipationEntityResponse>;
  createEventWeather?: Maybe<EventWeatherEntityResponse>;
  createImage?: Maybe<ImageEntityResponse>;
  createInterest?: Maybe<InterestEntityResponse>;
  createMayasquadStripeIntegrationsSale?: Maybe<MayasquadStripeIntegrationsSaleEntityResponse>;
  createMessage?: Maybe<MessageEntityResponse>;
  createMessageItem?: Maybe<MessageItemEntityResponse>;
  createNotification?: Maybe<NotificationEntityResponse>;
  createNotificationsEmailTemplate?: Maybe<NotificationsEmailTemplateEntityResponse>;
  createNotificationsPushTemplate?: Maybe<NotificationsPushTemplateEntityResponse>;
  createPost?: Maybe<PostEntityResponse>;
  createReaction?: Maybe<ReactionEntityResponse>;
  createReport?: Maybe<ReportEntityResponse>;
  createSubcomment?: Maybe<SubcommentEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  createUserFollower?: Maybe<UserFollowerEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteAvatar?: Maybe<AvatarEntityResponse>;
  deleteComment?: Maybe<CommentEntityResponse>;
  deleteEvent?: Maybe<EventEntityResponse>;
  deleteEventUserParticipation?: Maybe<EventUserParticipationEntityResponse>;
  deleteEventWeather?: Maybe<EventWeatherEntityResponse>;
  deleteForbiddenWord?: Maybe<ForbiddenWordEntityResponse>;
  deleteImage?: Maybe<ImageEntityResponse>;
  deleteInterest?: Maybe<InterestEntityResponse>;
  deleteMayasquadStripeIntegrationsSale?: Maybe<MayasquadStripeIntegrationsSaleEntityResponse>;
  deleteMessage?: Maybe<MessageEntityResponse>;
  deleteMessageItem?: Maybe<MessageItemEntityResponse>;
  deleteNotification?: Maybe<NotificationEntityResponse>;
  deleteNotificationAdmin?: Maybe<NotificationAdminEntityResponse>;
  deleteNotificationsEmailTemplate?: Maybe<NotificationsEmailTemplateEntityResponse>;
  deleteNotificationsPushTemplate?: Maybe<NotificationsPushTemplateEntityResponse>;
  deleteOnBoarding?: Maybe<OnBoardingEntityResponse>;
  deletePost?: Maybe<PostEntityResponse>;
  deleteReaction?: Maybe<ReactionEntityResponse>;
  deleteReport?: Maybe<ReportEntityResponse>;
  deleteSubcomment?: Maybe<SubcommentEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  deleteUserFollower?: Maybe<UserFollowerEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Refresh token */
  refreshToken: UsersPermissionsRefreshTokenPayload;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Remove interest to user */
  removeInterestToUser?: Maybe<InterestsMe>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  /** update all interest to user */
  setUserInterests?: Maybe<SuccessData>;
  /** remove user to blockedList */
  unblockedUser?: Maybe<SuccessData>;
  updateAvatar?: Maybe<AvatarEntityResponse>;
  updateComment?: Maybe<CommentEntityResponse>;
  updateEvent?: Maybe<EventEntityResponse>;
  updateEventUserParticipation?: Maybe<EventUserParticipationEntityResponse>;
  updateEventWeather?: Maybe<EventWeatherEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateForbiddenWord?: Maybe<ForbiddenWordEntityResponse>;
  updateImage?: Maybe<ImageEntityResponse>;
  updateInterest?: Maybe<InterestEntityResponse>;
  updateMayasquadStripeIntegrationsSale?: Maybe<MayasquadStripeIntegrationsSaleEntityResponse>;
  /** Update my profil */
  updateMe: UsersPermissionsUserEntityResponse;
  updateMessage?: Maybe<MessageEntityResponse>;
  updateMessageItem?: Maybe<MessageItemEntityResponse>;
  updateNotification?: Maybe<NotificationEntityResponse>;
  updateNotificationAdmin?: Maybe<NotificationAdminEntityResponse>;
  updateNotificationsEmailTemplate?: Maybe<NotificationsEmailTemplateEntityResponse>;
  updateNotificationsPushTemplate?: Maybe<NotificationsPushTemplateEntityResponse>;
  updateOnBoarding?: Maybe<OnBoardingEntityResponse>;
  updatePost?: Maybe<PostEntityResponse>;
  updateReaction?: Maybe<ReactionEntityResponse>;
  updateReport?: Maybe<ReportEntityResponse>;
  updateSubcomment?: Maybe<SubcommentEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  updateUserFollower?: Maybe<UserFollowerEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  upload: UploadFileEntityResponse;
};

export type MutationAcceptInvitationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAuthRegisterArgs = {
  input?: InputMaybe<AuthRegisterInput>;
};

export type MutationAuthVerifySmsCodeArgs = {
  data?: InputMaybe<AuthVerifyCodeInput>;
};

export type MutationAuthWithPhoneArgs = {
  data?: InputMaybe<AuthPhoneInput>;
};

export type MutationCancelParticipationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCheckMessageArgs = {
  firebaseDocId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateGooglePassArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  beginningDateTime?: InputMaybe<Scalars['String']['input']>;
  coverCustomImage?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  endHour?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  qrCode?: InputMaybe<Scalars['String']['input']>;
  startHour?: InputMaybe<Scalars['String']['input']>;
  zip_code?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreatePaymentIntentArgs = {
  input?: InputMaybe<CreatePaymentIntentInput>;
};

export type MutationDownloadTicketArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  qrCodeImage?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type MutationFollowUserArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type MutationGeneratePassArgs = {
  date?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  qrCode?: InputMaybe<Scalars['String']['input']>;
  startHour?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type MutationInviteContactArgs = {
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type MutationInviteToEventArgs = {
  input?: InputMaybe<ParticipationInput>;
};

export type MutationNoteEventArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationParticipeToEventArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRefuseInvitationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationVerifyPhoneArgs = {
  data?: InputMaybe<AuthPhoneInput>;
};

export type MutationAddInterestToUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationBlockedUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationCreateAvatarArgs = {
  data: AvatarInput;
};

export type MutationCreateCommentArgs = {
  data: CommentInput;
};

export type MutationCreateEventArgs = {
  data: EventInput;
};

export type MutationCreateEventUserParticipationArgs = {
  data: EventUserParticipationInput;
};

export type MutationCreateEventWeatherArgs = {
  data: EventWeatherInput;
};

export type MutationCreateImageArgs = {
  data: ImageInput;
};

export type MutationCreateInterestArgs = {
  data: InterestInput;
};

export type MutationCreateMayasquadStripeIntegrationsSaleArgs = {
  data: MayasquadStripeIntegrationsSaleInput;
};

export type MutationCreateMessageArgs = {
  data: MessageInput;
};

export type MutationCreateMessageItemArgs = {
  data: MessageItemInput;
};

export type MutationCreateNotificationArgs = {
  data: NotificationInput;
};

export type MutationCreateNotificationsEmailTemplateArgs = {
  data: NotificationsEmailTemplateInput;
};

export type MutationCreateNotificationsPushTemplateArgs = {
  data: NotificationsPushTemplateInput;
};

export type MutationCreatePostArgs = {
  data: PostInput;
};

export type MutationCreateReactionArgs = {
  data: ReactionInput;
};

export type MutationCreateReportArgs = {
  data: ReportInput;
};

export type MutationCreateSubcommentArgs = {
  data: SubcommentInput;
};

export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};

export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};

export type MutationCreateUserFollowerArgs = {
  data: UserFollowerInput;
};

export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};

export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};

export type MutationDeleteAvatarArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteCommentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteEventArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteEventUserParticipationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteEventWeatherArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteImageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteInterestArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteMayasquadStripeIntegrationsSaleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteMessageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteMessageItemArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteNotificationsEmailTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteNotificationsPushTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePostArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteReactionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteReportArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSubcommentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUserFollowerArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRefreshTokenArgs = {
  input: UsersPermissionsRefreshTokenInput;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationRemoveFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveInterestToUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationResetPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationSetUserInterestsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type MutationUnblockedUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUpdateAvatarArgs = {
  data: AvatarInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateCommentArgs = {
  data: CommentInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateEventArgs = {
  data: EventInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateEventUserParticipationArgs = {
  data: EventUserParticipationInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateEventWeatherArgs = {
  data: EventWeatherInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID']['input'];
  info?: InputMaybe<FileInfoInput>;
};

export type MutationUpdateForbiddenWordArgs = {
  data: ForbiddenWordInput;
};

export type MutationUpdateImageArgs = {
  data: ImageInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateInterestArgs = {
  data: InterestInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateMayasquadStripeIntegrationsSaleArgs = {
  data: MayasquadStripeIntegrationsSaleInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateMeArgs = {
  data: UsersPermissionsUserInput;
};

export type MutationUpdateMessageArgs = {
  data: MessageInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateMessageItemArgs = {
  data: MessageItemInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateNotificationArgs = {
  data: NotificationInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateNotificationsEmailTemplateArgs = {
  data: NotificationsEmailTemplateInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateNotificationsPushTemplateArgs = {
  data: NotificationsPushTemplateInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateOnBoardingArgs = {
  data: OnBoardingInput;
};

export type MutationUpdatePostArgs = {
  data: PostInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateReactionArgs = {
  data: ReactionInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateReportArgs = {
  data: ReportInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSubcommentArgs = {
  data: SubcommentInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUserFollowerArgs = {
  data: UserFollowerInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID']['input'];
};

export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type MyLikes = {
  __typename?: 'MyLikes';
  posts?: Maybe<Array<Maybe<PostLike>>>;
};

export type Notification = {
  __typename?: 'Notification';
  actionFrom?: Maybe<UsersPermissionsUserEntityResponse>;
  actionTo?: Maybe<UsersPermissionsUserEntityResponse>;
  additionalData?: Maybe<Scalars['JSON']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  event?: Maybe<EventEntityResponse>;
  publication?: Maybe<PostEntityResponse>;
  seen?: Maybe<Scalars['Boolean']['output']>;
  sent?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Enum_Notification_Type>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationAdmin = {
  __typename?: 'NotificationAdmin';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationAdminEntity = {
  __typename?: 'NotificationAdminEntity';
  attributes?: Maybe<NotificationAdmin>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type NotificationAdminEntityResponse = {
  __typename?: 'NotificationAdminEntityResponse';
  data?: Maybe<NotificationAdminEntity>;
};

export type NotificationEntity = {
  __typename?: 'NotificationEntity';
  attributes?: Maybe<Notification>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type NotificationEntityResponse = {
  __typename?: 'NotificationEntityResponse';
  data?: Maybe<NotificationEntity>;
};

export type NotificationEntityResponseCollection = {
  __typename?: 'NotificationEntityResponseCollection';
  data: Array<NotificationEntity>;
  meta: ResponseCollectionMeta;
};

export type NotificationFiltersInput = {
  actionFrom?: InputMaybe<UsersPermissionsUserFiltersInput>;
  actionTo?: InputMaybe<UsersPermissionsUserFiltersInput>;
  additionalData?: InputMaybe<JsonFilterInput>;
  and?: InputMaybe<Array<InputMaybe<NotificationFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  event?: InputMaybe<EventFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<NotificationFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<NotificationFiltersInput>>>;
  publication?: InputMaybe<PostFiltersInput>;
  seen?: InputMaybe<BooleanFilterInput>;
  sent?: InputMaybe<BooleanFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type NotificationInput = {
  actionFrom?: InputMaybe<Scalars['ID']['input']>;
  actionTo?: InputMaybe<Scalars['ID']['input']>;
  additionalData?: InputMaybe<Scalars['JSON']['input']>;
  event?: InputMaybe<Scalars['ID']['input']>;
  publication?: InputMaybe<Scalars['ID']['input']>;
  seen?: InputMaybe<Scalars['Boolean']['input']>;
  sent?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Enum_Notification_Type>;
};

export type NotificationsEmailTemplate = {
  __typename?: 'NotificationsEmailTemplate';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationsEmailTemplateEntity = {
  __typename?: 'NotificationsEmailTemplateEntity';
  attributes?: Maybe<NotificationsEmailTemplate>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type NotificationsEmailTemplateEntityResponse = {
  __typename?: 'NotificationsEmailTemplateEntityResponse';
  data?: Maybe<NotificationsEmailTemplateEntity>;
};

export type NotificationsEmailTemplateEntityResponseCollection = {
  __typename?: 'NotificationsEmailTemplateEntityResponseCollection';
  data: Array<NotificationsEmailTemplateEntity>;
  meta: ResponseCollectionMeta;
};

export type NotificationsEmailTemplateFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<NotificationsEmailTemplateFiltersInput>>>;
  content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  identifier?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<NotificationsEmailTemplateFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<NotificationsEmailTemplateFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type NotificationsEmailTemplateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationsPushTemplate = {
  __typename?: 'NotificationsPushTemplate';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationsPushTemplateEntity = {
  __typename?: 'NotificationsPushTemplateEntity';
  attributes?: Maybe<NotificationsPushTemplate>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type NotificationsPushTemplateEntityResponse = {
  __typename?: 'NotificationsPushTemplateEntityResponse';
  data?: Maybe<NotificationsPushTemplateEntity>;
};

export type NotificationsPushTemplateEntityResponseCollection = {
  __typename?: 'NotificationsPushTemplateEntityResponseCollection';
  data: Array<NotificationsPushTemplateEntity>;
  meta: ResponseCollectionMeta;
};

export type NotificationsPushTemplateFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<NotificationsPushTemplateFiltersInput>>>;
  content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  identifier?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<NotificationsPushTemplateFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<NotificationsPushTemplateFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type NotificationsPushTemplateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type OnBoarding = {
  __typename?: 'OnBoarding';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  items?: Maybe<Array<Maybe<ComponentOnBoardOnBoard>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OnBoardingItemsArgs = {
  filters?: InputMaybe<ComponentOnBoardOnBoardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OnBoardingEntity = {
  __typename?: 'OnBoardingEntity';
  attributes?: Maybe<OnBoarding>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type OnBoardingEntityResponse = {
  __typename?: 'OnBoardingEntityResponse';
  data?: Maybe<OnBoardingEntity>;
};

export type OnBoardingInput = {
  items?: InputMaybe<Array<InputMaybe<ComponentOnBoardOnBoardInput>>>;
};

export type PdfData = {
  __typename?: 'PDFData';
  filePath?: Maybe<Scalars['String']['output']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type ParticipationInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type PassData = {
  __typename?: 'PassData';
  data?: Maybe<Scalars['String']['output']>;
};

export type Post = {
  __typename?: 'Post';
  author?: Maybe<UsersPermissionsUserEntityResponse>;
  comments?: Maybe<CommentRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  medias?: Maybe<UploadFileRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  reactions?: Maybe<ReactionRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PostCommentsArgs = {
  filters?: InputMaybe<CommentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PostMediasArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PostReactionsArgs = {
  filters?: InputMaybe<ReactionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PostEntity = {
  __typename?: 'PostEntity';
  attributes?: Maybe<Post>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PostEntityResponse = {
  __typename?: 'PostEntityResponse';
  data?: Maybe<PostEntity>;
};

export type PostEntityResponseCollection = {
  __typename?: 'PostEntityResponseCollection';
  data: Array<PostEntity>;
  meta: ResponseCollectionMeta;
};

export type PostFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PostFiltersInput>>>;
  author?: InputMaybe<UsersPermissionsUserFiltersInput>;
  comments?: InputMaybe<CommentFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  deleted?: InputMaybe<BooleanFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<PostFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PostFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  reactions?: InputMaybe<ReactionFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PostInput = {
  author?: InputMaybe<Scalars['ID']['input']>;
  comments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  medias?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  reactions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type PostLike = {
  __typename?: 'PostLike';
  idPost?: Maybe<Scalars['String']['output']>;
  idReaction?: Maybe<Scalars['String']['output']>;
};

export type PostRelationResponseCollection = {
  __typename?: 'PostRelationResponseCollection';
  data: Array<PostEntity>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW',
}

export type Query = {
  __typename?: 'Query';
  DispoSales?: Maybe<MayasquadStripeIntegrationsSaleEntityResponseCollection>;
  RetrieveMyStripeAccount?: Maybe<StripeDataResponse>;
  avatar?: Maybe<AvatarEntityResponse>;
  avatars?: Maybe<AvatarEntityResponseCollection>;
  comment?: Maybe<CommentEntityResponse>;
  comments?: Maybe<CommentEntityResponseCollection>;
  event?: Maybe<EventEntityResponse>;
  eventUserParticipation?: Maybe<EventUserParticipationEntityResponse>;
  eventUserParticipations?: Maybe<EventUserParticipationEntityResponseCollection>;
  eventWeather?: Maybe<EventWeatherEntityResponse>;
  eventWeathers?: Maybe<EventWeatherEntityResponseCollection>;
  events?: Maybe<EventEntityResponseCollection>;
  forbiddenWord?: Maybe<ForbiddenWordEntityResponse>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  image?: Maybe<ImageEntityResponse>;
  images?: Maybe<ImageEntityResponseCollection>;
  interest?: Maybe<InterestEntityResponse>;
  interests?: Maybe<InterestEntityResponseCollection>;
  mayasquadStripeIntegrationsSale?: Maybe<MayasquadStripeIntegrationsSaleEntityResponse>;
  mayasquadStripeIntegrationsSales?: Maybe<MayasquadStripeIntegrationsSaleEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  message?: Maybe<MessageEntityResponse>;
  messageItem?: Maybe<MessageItemEntityResponse>;
  messageItems?: Maybe<MessageItemEntityResponseCollection>;
  messages?: Maybe<MessageEntityResponseCollection>;
  /** get my interest */
  myInterest?: Maybe<InterestsMe>;
  /** get user like */
  myLikes?: Maybe<MyLikes>;
  notification?: Maybe<NotificationEntityResponse>;
  notificationAdmin?: Maybe<NotificationAdminEntityResponse>;
  notifications?: Maybe<NotificationEntityResponseCollection>;
  notificationsEmailTemplate?: Maybe<NotificationsEmailTemplateEntityResponse>;
  notificationsEmailTemplates?: Maybe<NotificationsEmailTemplateEntityResponseCollection>;
  notificationsPushTemplate?: Maybe<NotificationsPushTemplateEntityResponse>;
  notificationsPushTemplates?: Maybe<NotificationsPushTemplateEntityResponseCollection>;
  onBoarding?: Maybe<OnBoardingEntityResponse>;
  post?: Maybe<PostEntityResponse>;
  posts?: Maybe<PostEntityResponseCollection>;
  reaction?: Maybe<ReactionEntityResponse>;
  reactions?: Maybe<ReactionEntityResponseCollection>;
  report?: Maybe<ReportEntityResponse>;
  reports?: Maybe<ReportEntityResponseCollection>;
  subcomment?: Maybe<SubcommentEntityResponse>;
  subcomments?: Maybe<SubcommentEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  userFollower?: Maybe<UserFollowerEntityResponse>;
  userFollowers?: Maybe<UserFollowerEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
};

export type QueryDispoSalesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAvatarArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAvatarsArgs = {
  filters?: InputMaybe<AvatarFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryCommentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCommentsArgs = {
  filters?: InputMaybe<CommentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryEventArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEventUserParticipationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEventUserParticipationsArgs = {
  filters?: InputMaybe<EventUserParticipationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryEventWeatherArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEventWeathersArgs = {
  filters?: InputMaybe<EventWeatherFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryEventsArgs = {
  filters?: InputMaybe<EventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryImageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryImagesArgs = {
  filters?: InputMaybe<ImageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryInterestArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryInterestsArgs = {
  filters?: InputMaybe<InterestFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryMayasquadStripeIntegrationsSaleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryMayasquadStripeIntegrationsSalesArgs = {
  filters?: InputMaybe<MayasquadStripeIntegrationsSaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryMessageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryMessageItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryMessageItemsArgs = {
  filters?: InputMaybe<MessageItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryMessagesArgs = {
  filters?: InputMaybe<MessageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryNotificationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryNotificationsArgs = {
  filters?: InputMaybe<NotificationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryNotificationsEmailTemplateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryNotificationsEmailTemplatesArgs = {
  filters?: InputMaybe<NotificationsEmailTemplateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryNotificationsPushTemplateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryNotificationsPushTemplatesArgs = {
  filters?: InputMaybe<NotificationsPushTemplateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryPostArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryPostsArgs = {
  filters?: InputMaybe<PostFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryReactionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryReactionsArgs = {
  filters?: InputMaybe<ReactionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryReportArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryReportsArgs = {
  filters?: InputMaybe<ReportFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySubcommentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySubcommentsArgs = {
  filters?: InputMaybe<SubcommentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUserFollowerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserFollowersArgs = {
  filters?: InputMaybe<UserFollowerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Reaction = {
  __typename?: 'Reaction';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  publication?: Maybe<PostEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type ReactionEntity = {
  __typename?: 'ReactionEntity';
  attributes?: Maybe<Reaction>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ReactionEntityResponse = {
  __typename?: 'ReactionEntityResponse';
  data?: Maybe<ReactionEntity>;
};

export type ReactionEntityResponseCollection = {
  __typename?: 'ReactionEntityResponseCollection';
  data: Array<ReactionEntity>;
  meta: ResponseCollectionMeta;
};

export type ReactionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ReactionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<ReactionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ReactionFiltersInput>>>;
  publication?: InputMaybe<PostFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type ReactionInput = {
  publication?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type ReactionRelationResponseCollection = {
  __typename?: 'ReactionRelationResponseCollection';
  data: Array<ReactionEntity>;
};

export type Report = {
  __typename?: 'Report';
  category?: Maybe<Enum_Report_Category>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  reportedBy?: Maybe<UsersPermissionsUserEntityResponse>;
  status?: Maybe<Enum_Report_Status>;
  type?: Maybe<Enum_Report_Type>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReportEntity = {
  __typename?: 'ReportEntity';
  attributes?: Maybe<Report>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ReportEntityResponse = {
  __typename?: 'ReportEntityResponse';
  data?: Maybe<ReportEntity>;
};

export type ReportEntityResponseCollection = {
  __typename?: 'ReportEntityResponseCollection';
  data: Array<ReportEntity>;
  meta: ResponseCollectionMeta;
};

export type ReportFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ReportFiltersInput>>>;
  category?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<ReportFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ReportFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  related_id?: InputMaybe<StringFilterInput>;
  reportedBy?: InputMaybe<UsersPermissionsUserFiltersInput>;
  status?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ReportInput = {
  category?: InputMaybe<Enum_Report_Category>;
  description?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  related_id?: InputMaybe<Scalars['String']['input']>;
  reportedBy?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Enum_Report_Status>;
  type?: InputMaybe<Enum_Report_Type>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  containsi?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  eqi?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notContainsi?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StripeDataResponse = {
  __typename?: 'StripeDataResponse';
  data?: Maybe<Scalars['GraphQLJSONObject']['output']>;
};

export type Subcomment = {
  __typename?: 'Subcomment';
  author?: Maybe<UsersPermissionsUserEntityResponse>;
  comment?: Maybe<CommentEntityResponse>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubcommentEntity = {
  __typename?: 'SubcommentEntity';
  attributes?: Maybe<Subcomment>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SubcommentEntityResponse = {
  __typename?: 'SubcommentEntityResponse';
  data?: Maybe<SubcommentEntity>;
};

export type SubcommentEntityResponseCollection = {
  __typename?: 'SubcommentEntityResponseCollection';
  data: Array<SubcommentEntity>;
  meta: ResponseCollectionMeta;
};

export type SubcommentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SubcommentFiltersInput>>>;
  author?: InputMaybe<UsersPermissionsUserFiltersInput>;
  comment?: InputMaybe<CommentFiltersInput>;
  content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  deleted?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<SubcommentFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SubcommentFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SubcommentInput = {
  author?: InputMaybe<Scalars['ID']['input']>;
  comment?: InputMaybe<Scalars['ID']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubcommentRelationResponseCollection = {
  __typename?: 'SubcommentRelationResponseCollection';
  data: Array<SubcommentEntity>;
};

export type SuccessData = {
  __typename?: 'SuccessData';
  message?: Maybe<Scalars['GraphQLJSONObject']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Time']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Time']['input']>>>;
  contains?: InputMaybe<Scalars['Time']['input']>;
  containsi?: InputMaybe<Scalars['Time']['input']>;
  endsWith?: InputMaybe<Scalars['Time']['input']>;
  eq?: InputMaybe<Scalars['Time']['input']>;
  eqi?: InputMaybe<Scalars['Time']['input']>;
  gt?: InputMaybe<Scalars['Time']['input']>;
  gte?: InputMaybe<Scalars['Time']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Time']['input']>>>;
  lt?: InputMaybe<Scalars['Time']['input']>;
  lte?: InputMaybe<Scalars['Time']['input']>;
  ne?: InputMaybe<Scalars['Time']['input']>;
  not?: InputMaybe<TimeFilterInput>;
  notContains?: InputMaybe<Scalars['Time']['input']>;
  notContainsi?: InputMaybe<Scalars['Time']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Time']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Time']['input']>>>;
  startsWith?: InputMaybe<Scalars['Time']['input']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['JSON']['output']>;
  hash: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  provider_metadata?: Maybe<Scalars['JSON']['output']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  folder?: InputMaybe<Scalars['ID']['input']>;
  folderPath?: InputMaybe<Scalars['String']['input']>;
  formats?: InputMaybe<Scalars['JSON']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_metadata?: InputMaybe<Scalars['JSON']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String']['output'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String']['output'];
  pathId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  pathId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UserFollower = {
  __typename?: 'UserFollower';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  follower?: Maybe<UsersPermissionsUserEntityResponse>;
  status?: Maybe<Enum_Userfollower_Status>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type UserFollowerEntity = {
  __typename?: 'UserFollowerEntity';
  attributes?: Maybe<UserFollower>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UserFollowerEntityResponse = {
  __typename?: 'UserFollowerEntityResponse';
  data?: Maybe<UserFollowerEntity>;
};

export type UserFollowerEntityResponseCollection = {
  __typename?: 'UserFollowerEntityResponseCollection';
  data: Array<UserFollowerEntity>;
  meta: ResponseCollectionMeta;
};

export type UserFollowerFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UserFollowerFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  follower?: InputMaybe<UsersPermissionsUserFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UserFollowerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UserFollowerFiltersInput>>>;
  status?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UserFollowerInput = {
  follower?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Enum_Userfollower_Status>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type UserFollowerRelationResponseCollection = {
  __typename?: 'UserFollowerRelationResponseCollection';
  data: Array<UserFollowerEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider?: Scalars['String']['input'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']['output']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  acceptCGU?: Maybe<Scalars['Boolean']['output']>;
  acceptNewsletter?: Maybe<Scalars['Boolean']['output']>;
  avatar?: Maybe<AvatarEntity>;
  birthDate?: Maybe<Scalars['Date']['output']>;
  blocked?: Maybe<Scalars['Boolean']['output']>;
  blockedByUsers?: Maybe<Array<Maybe<UsersPermissionsUserEntity>>>;
  blockedUserIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  blockedUsers?: Maybe<Array<Maybe<UsersPermissionsUserEntity>>>;
  companyName?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  events?: Maybe<EventEntityResponseCollection>;
  feedType?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  followers?: Maybe<UserFollowerEntityResponseCollection>;
  followups?: Maybe<UserFollowerEntityResponseCollection>;
  id: Scalars['ID']['output'];
  identityDocument?: Maybe<UploadFileEntity>;
  interests?: Maybe<Array<Maybe<InterestEntity>>>;
  isPro?: Maybe<Scalars['Boolean']['output']>;
  job?: Maybe<Scalars['String']['output']>;
  kbis?: Maybe<UploadFileEntity>;
  lastname?: Maybe<Scalars['String']['output']>;
  legalStatus?: Maybe<Scalars['String']['output']>;
  minor?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<UploadFileEntity>;
  publishedAt?: Maybe<Scalars['String']['output']>;
  pushToken?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UsersPermissionsMeRole>;
  siret?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stripeAccount?: Maybe<Scalars['String']['output']>;
  stripeCapabilitiesStatus?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRefreshTokenInput = {
  jwt: Scalars['String']['input'];
};

export type UsersPermissionsRefreshTokenPayload = {
  __typename?: 'UsersPermissionsRefreshTokenPayload';
  jwt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UsersPermissionsMe>;
};

export type UsersPermissionsRegisterInput = {
  acceptCGU?: InputMaybe<Scalars['Boolean']['input']>;
  acceptNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  isPro?: InputMaybe<Scalars['Boolean']['input']>;
  job?: InputMaybe<Scalars['String']['input']>;
  kbis?: InputMaybe<Scalars['ID']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  legalStatus?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};

export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  avatar?: Maybe<AvatarEntityResponse>;
  birthDate?: Maybe<Scalars['Date']['output']>;
  blocked?: Maybe<Scalars['Boolean']['output']>;
  blockedByUsers?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  blockedUserIds?: Maybe<Scalars['JSON']['output']>;
  blockedUsers?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  chatNotificationMutes?: Maybe<Array<Maybe<ComponentNotificationSetting>>>;
  companyName?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  events?: Maybe<EventRelationResponseCollection>;
  feedType?: Maybe<Enum_Userspermissionsuser_Feedtype>;
  firstname?: Maybe<Scalars['String']['output']>;
  followers?: Maybe<UserFollowerRelationResponseCollection>;
  followups?: Maybe<UserFollowerRelationResponseCollection>;
  identityDocument?: Maybe<UploadFileEntityResponse>;
  interests?: Maybe<InterestRelationResponseCollection>;
  isPro?: Maybe<Scalars['Boolean']['output']>;
  job?: Maybe<Scalars['String']['output']>;
  kbis?: Maybe<UploadFileEntityResponse>;
  lastname?: Maybe<Scalars['String']['output']>;
  legalStatus?: Maybe<Enum_Userspermissionsuser_Legalstatus>;
  phone: Scalars['String']['output'];
  posts?: Maybe<PostRelationResponseCollection>;
  profilePicture?: Maybe<UploadFileEntityResponse>;
  provider?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  reactions?: Maybe<ReactionRelationResponseCollection>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  siret?: Maybe<Scalars['String']['output']>;
  stripeAccount?: Maybe<Scalars['String']['output']>;
  stripeCapabilitiesStatus?: Maybe<Enum_Userspermissionsuser_Stripecapabilitiesstatus>;
  stripeCustomerIds?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UsersPermissionsUserBlockedByUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsUserBlockedUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsUserChatNotificationMutesArgs = {
  filters?: InputMaybe<ComponentNotificationSettingFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsUserEventsArgs = {
  filters?: InputMaybe<EventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsUserFollowersArgs = {
  filters?: InputMaybe<UserFollowerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsUserFollowupsArgs = {
  filters?: InputMaybe<UserFollowerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsUserInterestsArgs = {
  filters?: InputMaybe<InterestFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsUserPostsArgs = {
  filters?: InputMaybe<PostFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsUserReactionsArgs = {
  filters?: InputMaybe<ReactionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  acceptNewsletter?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  avatar?: InputMaybe<AvatarFiltersInput>;
  birthDate?: InputMaybe<DateFilterInput>;
  blocked?: InputMaybe<BooleanFilterInput>;
  blockedByUsers?: InputMaybe<UsersPermissionsUserFiltersInput>;
  blockedUserIds?: InputMaybe<JsonFilterInput>;
  blockedUsers?: InputMaybe<UsersPermissionsUserFiltersInput>;
  chatNotificationMutes?: InputMaybe<ComponentNotificationSettingFiltersInput>;
  companyName?: InputMaybe<StringFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  deleted?: InputMaybe<BooleanFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  events?: InputMaybe<EventFiltersInput>;
  feedType?: InputMaybe<StringFilterInput>;
  firstname?: InputMaybe<StringFilterInput>;
  followers?: InputMaybe<UserFollowerFiltersInput>;
  followups?: InputMaybe<UserFollowerFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  interests?: InputMaybe<InterestFiltersInput>;
  isPro?: InputMaybe<BooleanFilterInput>;
  job?: InputMaybe<StringFilterInput>;
  lastname?: InputMaybe<StringFilterInput>;
  legalStatus?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  phone?: InputMaybe<StringFilterInput>;
  posts?: InputMaybe<PostFiltersInput>;
  provider?: InputMaybe<StringFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  pushToken?: InputMaybe<StringFilterInput>;
  reactions?: InputMaybe<ReactionFiltersInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  siret?: InputMaybe<StringFilterInput>;
  smsCode?: InputMaybe<StringFilterInput>;
  smsSentAt?: InputMaybe<DateTimeFilterInput>;
  stripeAccount?: InputMaybe<StringFilterInput>;
  stripeCapabilitiesStatus?: InputMaybe<StringFilterInput>;
  stripeCustomerId?: InputMaybe<StringFilterInput>;
  stripeCustomerIds?: InputMaybe<JsonFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  acceptNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['ID']['input']>;
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  blockedByUsers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  blockedUserIds?: InputMaybe<Scalars['JSON']['input']>;
  blockedUsers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  chatNotificationMutes?: InputMaybe<Array<InputMaybe<ComponentNotificationSettingInput>>>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  feedType?: InputMaybe<Enum_Userspermissionsuser_Feedtype>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  followers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  followups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  identityDocument?: InputMaybe<Scalars['ID']['input']>;
  interests?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isPro?: InputMaybe<Scalars['Boolean']['input']>;
  job?: InputMaybe<Scalars['String']['input']>;
  kbis?: InputMaybe<Scalars['ID']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  legalStatus?: InputMaybe<Enum_Userspermissionsuser_Legalstatus>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  posts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  profilePicture?: InputMaybe<Scalars['ID']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pushToken?: InputMaybe<Scalars['String']['input']>;
  reactions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['ID']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
  smsCode?: InputMaybe<Scalars['String']['input']>;
  smsSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  stripeAccount?: InputMaybe<Scalars['String']['input']>;
  stripeCapabilitiesStatus?: InputMaybe<Enum_Userspermissionsuser_Stripecapabilitiesstatus>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerIds?: InputMaybe<Scalars['JSON']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type AuthRegisterMutationVariables = Exact<{
  input: AuthRegisterInput;
}>;

export type AuthRegisterMutation = {
  __typename?: 'Mutation';
  AuthRegister?: { __typename?: 'AuthResponsePayload'; success?: boolean | null; message?: string | null } | null;
};

export type AuthWithPhoneMutationVariables = Exact<{
  data: AuthPhoneInput;
}>;

export type AuthWithPhoneMutation = {
  __typename?: 'Mutation';
  AuthWithPhone?: { __typename?: 'AuthResponsePayload'; success?: boolean | null; message?: string | null } | null;
};

export type AuthVerifySmsCodeMutationVariables = Exact<{
  data: AuthVerifyCodeInput;
}>;

export type AuthVerifySmsCodeMutation = {
  __typename?: 'Mutation';
  AuthVerifySmsCode?: {
    __typename?: 'UsersPermissionsRefreshTokenPayload';
    jwt?: string | null;
    user?: {
      __typename?: 'UsersPermissionsMe';
      id: string;
      username: string;
      email?: string | null;
      phone?: string | null;
      confirmed?: boolean | null;
      blocked?: boolean | null;
      publishedAt?: string | null;
      interests?: Array<{ __typename?: 'InterestEntity'; id?: string | null } | null> | null;
      profilePicture?: { __typename?: 'UploadFileEntity'; id?: string | null } | null;
      avatar?: { __typename?: 'AvatarEntity'; id?: string | null } | null;
    } | null;
  } | null;
};

export type UpdateMeMutationVariables = Exact<{
  data: UsersPermissionsUserInput;
}>;

export type UpdateMeMutation = {
  __typename?: 'Mutation';
  updateMe: {
    __typename?: 'UsersPermissionsUserEntityResponse';
    data?: {
      __typename?: 'UsersPermissionsUserEntity';
      id?: string | null;
      attributes?: { __typename?: 'UsersPermissionsUser'; phone: string } | null;
    } | null;
  };
};

export type VerifyPhoneMutationVariables = Exact<{
  data: AuthPhoneInput;
}>;

export type VerifyPhoneMutation = {
  __typename?: 'Mutation';
  VerifyPhone?: { __typename?: 'AuthResponsePayload'; success?: boolean | null; message?: string | null } | null;
};

export type CreateCommentMutationVariables = Exact<{
  data: CommentInput;
}>;

export type CreateCommentMutation = {
  __typename?: 'Mutation';
  createComment?: {
    __typename?: 'CommentEntityResponse';
    data?: {
      __typename?: 'CommentEntity';
      id?: string | null;
      attributes?: { __typename?: 'Comment'; content?: string | null } | null;
    } | null;
  } | null;
};

export type CreateSubCommentMutationVariables = Exact<{
  data: SubcommentInput;
}>;

export type CreateSubCommentMutation = {
  __typename?: 'Mutation';
  createSubcomment?: {
    __typename?: 'SubcommentEntityResponse';
    data?: {
      __typename?: 'SubcommentEntity';
      id?: string | null;
      attributes?: { __typename?: 'Subcomment'; content?: string | null } | null;
    } | null;
  } | null;
};

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCommentMutation = {
  __typename?: 'Mutation';
  deleteComment?: {
    __typename?: 'CommentEntityResponse';
    data?: { __typename?: 'CommentEntity'; id?: string | null } | null;
  } | null;
};

export type DeleteSubcommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteSubcommentMutation = {
  __typename?: 'Mutation';
  deleteSubcomment?: {
    __typename?: 'SubcommentEntityResponse';
    data?: { __typename?: 'SubcommentEntity'; id?: string | null } | null;
  } | null;
};

export type DeleteEventMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteEventMutation = {
  __typename?: 'Mutation';
  deleteEvent?: {
    __typename?: 'EventEntityResponse';
    data?: {
      __typename?: 'EventEntity';
      id?: string | null;
      attributes?: { __typename?: 'Event'; name?: string | null } | null;
    } | null;
  } | null;
};

export type DeletePostMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePostMutation = {
  __typename?: 'Mutation';
  deletePost?: {
    __typename?: 'PostEntityResponse';
    data?: { __typename?: 'PostEntity'; id?: string | null } | null;
  } | null;
};

export type CreateEventMutationVariables = Exact<{
  data: EventInput;
}>;

export type CreateEventMutation = {
  __typename?: 'Mutation';
  createEvent?: {
    __typename?: 'EventEntityResponse';
    data?: {
      __typename?: 'EventEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Event';
        name?: string | null;
        type?: Enum_Event_Type | null;
        eventDate?: any | null;
        eventEndDate?: any | null;
        addressLine?: string | null;
        startHour?: any | null;
        endHour?: any | null;
        expired?: boolean | null;
        coverCustomImage?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            attributes?: {
              __typename?: 'UploadFile';
              name: string;
              url: string;
              mime: string;
              hash: string;
              size: number;
              provider: string;
            } | null;
          } | null;
        } | null;
        coverTedispoImage?: {
          __typename?: 'ImageEntityResponse';
          data?: {
            __typename?: 'ImageEntity';
            attributes?: {
              __typename?: 'Image';
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type InviteToEventMutationVariables = Exact<{
  input: ParticipationInput;
}>;

export type InviteToEventMutation = {
  __typename?: 'Mutation';
  InviteToEvent: { __typename?: 'SuccessData'; success?: boolean | null };
};

export type CreateMessageMutationVariables = Exact<{
  data: MessageInput;
}>;

export type CreateMessageMutation = {
  __typename?: 'Mutation';
  createMessage?: {
    __typename?: 'MessageEntityResponse';
    data?: {
      __typename?: 'MessageEntity';
      id?: string | null;
      attributes?: { __typename?: 'Message'; content?: string | null } | null;
    } | null;
  } | null;
};

export type CreateMessageItemMutationVariables = Exact<{
  data: MessageItemInput;
}>;

export type CreateMessageItemMutation = {
  __typename?: 'Mutation';
  createMessageItem?: {
    __typename?: 'MessageItemEntityResponse';
    data?: {
      __typename?: 'MessageItemEntity';
      id?: string | null;
      attributes?: { __typename?: 'MessageItem'; body?: string | null } | null;
    } | null;
  } | null;
};

export type CheckMessageMutationVariables = Exact<{
  firebaseDocId: Scalars['String']['input'];
}>;

export type CheckMessageMutation = {
  __typename?: 'Mutation';
  CheckMessage: { __typename?: 'SuccessData'; success?: boolean | null };
};

export type UpdateNotificationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: NotificationInput;
}>;

export type UpdateNotificationMutation = {
  __typename?: 'Mutation';
  updateNotification?: {
    __typename?: 'NotificationEntityResponse';
    data?: { __typename?: 'NotificationEntity'; id?: string | null } | null;
  } | null;
};

export type MarkAsSeenMutationVariables = Exact<{ [key: string]: never }>;

export type MarkAsSeenMutation = {
  __typename?: 'Mutation';
  MarkAsSeen: { __typename?: 'SuccessData'; success?: boolean | null; message?: any | null };
};

export type CreatePostMutationVariables = Exact<{
  data: PostInput;
}>;

export type CreatePostMutation = {
  __typename?: 'Mutation';
  createPost?: {
    __typename?: 'PostEntityResponse';
    data?: {
      __typename?: 'PostEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Post';
        description?: string | null;
        medias?: {
          __typename?: 'UploadFileRelationResponseCollection';
          data: Array<{
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: { __typename?: 'UploadFile'; name: string; size: number; previewUrl?: string | null } | null;
          }>;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdatePostMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: PostInput;
}>;

export type UpdatePostMutation = {
  __typename?: 'Mutation';
  updatePost?: {
    __typename?: 'PostEntityResponse';
    data?: {
      __typename?: 'PostEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Post';
        description?: string | null;
        medias?: {
          __typename?: 'UploadFileRelationResponseCollection';
          data: Array<{
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: { __typename?: 'UploadFile'; name: string; size: number; previewUrl?: string | null } | null;
          }>;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CreateReactionMutationVariables = Exact<{
  data: ReactionInput;
}>;

export type CreateReactionMutation = {
  __typename?: 'Mutation';
  createReaction?: {
    __typename?: 'ReactionEntityResponse';
    data?: { __typename?: 'ReactionEntity'; id?: string | null } | null;
  } | null;
};

export type DeleteReactionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteReactionMutation = {
  __typename?: 'Mutation';
  deleteReaction?: {
    __typename?: 'ReactionEntityResponse';
    data?: { __typename?: 'ReactionEntity'; id?: string | null } | null;
  } | null;
};

export type CreateReportMutationVariables = Exact<{
  data: ReportInput;
}>;

export type CreateReportMutation = {
  __typename?: 'Mutation';
  createReport?: {
    __typename?: 'ReportEntityResponse';
    data?: {
      __typename?: 'ReportEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Report';
        type?: Enum_Report_Type | null;
        status?: Enum_Report_Status | null;
        category?: Enum_Report_Category | null;
        reportedBy?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: { __typename?: 'UsersPermissionsUserEntity'; id?: string | null } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CreatePaymentIntentMutationVariables = Exact<{
  input?: InputMaybe<CreatePaymentIntentInput>;
}>;

export type CreatePaymentIntentMutation = {
  __typename?: 'Mutation';
  CreatePaymentIntent?: { __typename?: 'StripeDataResponse'; data?: any | null } | null;
};

export type DownloadTicketMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  qrCodeImage?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
}>;

export type DownloadTicketMutation = {
  __typename?: 'Mutation';
  DownloadTicket?: { __typename?: 'PDFData'; filePath?: string | null } | null;
};

export type CreateGooglePassMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  startHour: Scalars['String']['input'];
  endHour: Scalars['String']['input'];
  address: Scalars['String']['input'];
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  zip_code?: InputMaybe<Scalars['String']['input']>;
  beginningDateTime?: InputMaybe<Scalars['String']['input']>;
  coverCustomImage?: InputMaybe<Scalars['String']['input']>;
  qrCode: Scalars['String']['input'];
}>;

export type CreateGooglePassMutation = {
  __typename?: 'Mutation';
  CreateGooglePass?: { __typename?: 'GooglePassData'; token?: string | null } | null;
};

export type GeneratePassMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  startHour?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  qrCode?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}>;

export type GeneratePassMutation = {
  __typename?: 'Mutation';
  GeneratePass?: { __typename?: 'PassData'; data?: string | null } | null;
};

export type UpdateEventMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: EventInput;
}>;

export type UpdateEventMutation = {
  __typename?: 'Mutation';
  updateEvent?: {
    __typename?: 'EventEntityResponse';
    data?: {
      __typename?: 'EventEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Event';
        name?: string | null;
        type?: Enum_Event_Type | null;
        eventDate?: any | null;
        eventEndDate?: any | null;
        addressLine?: string | null;
        description?: string | null;
        startHour?: any | null;
        endHour?: any | null;
        expired?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateEventUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: EventUserParticipationInput;
}>;

export type UpdateEventUserMutation = {
  __typename?: 'Mutation';
  updateEventUserParticipation?: {
    __typename?: 'EventUserParticipationEntityResponse';
    data?: {
      __typename?: 'EventUserParticipationEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'EventUserParticipation';
        status?: Enum_Eventuserparticipation_Status | null;
        event?: {
          __typename?: 'EventEntityResponse';
          data?: {
            __typename?: 'EventEntity';
            id?: string | null;
            attributes?: { __typename?: 'Event'; name?: string | null } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type InviteMutationVariables = Exact<{
  input?: InputMaybe<ParticipationInput>;
}>;

export type InviteMutation = {
  __typename?: 'Mutation';
  InviteToEvent: { __typename?: 'SuccessData'; success?: boolean | null };
};

export type AcceptMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type AcceptMutation = {
  __typename?: 'Mutation';
  AcceptInvitation: { __typename?: 'SuccessData'; success?: boolean | null };
};

export type RefuseMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type RefuseMutation = {
  __typename?: 'Mutation';
  RefuseInvitation: { __typename?: 'SuccessData'; success?: boolean | null };
};

export type NoteEventMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['Int']['input']>;
}>;

export type NoteEventMutation = {
  __typename?: 'Mutation';
  NoteEvent: { __typename?: 'SuccessData'; success?: boolean | null };
};

export type ParticipeToEventMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ParticipeToEventMutation = {
  __typename?: 'Mutation';
  ParticipeToEvent: { __typename?: 'SuccessData'; success?: boolean | null };
};

export type DeleteEventUserParticipationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteEventUserParticipationMutation = {
  __typename?: 'Mutation';
  deleteEventUserParticipation?: {
    __typename?: 'EventUserParticipationEntityResponse';
    data?: { __typename?: 'EventUserParticipationEntity'; id?: string | null } | null;
  } | null;
};

export type CancelParticipationMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CancelParticipationMutation = {
  __typename?: 'Mutation';
  CancelParticipation: { __typename?: 'SuccessData'; success?: boolean | null };
};

export type UpdateUsersPermissionsUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: UsersPermissionsUserInput;
}>;

export type UpdateUsersPermissionsUserMutation = {
  __typename?: 'Mutation';
  updateUsersPermissionsUser: {
    __typename?: 'UsersPermissionsUserEntityResponse';
    data?: { __typename?: 'UsersPermissionsUserEntity'; id?: string | null } | null;
  };
};

export type DeleteUsersPermissionsUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteUsersPermissionsUserMutation = {
  __typename?: 'Mutation';
  deleteUsersPermissionsUser: {
    __typename?: 'UsersPermissionsUserEntityResponse';
    data?: { __typename?: 'UsersPermissionsUserEntity'; id?: string | null } | null;
  };
};

export type UpdateFeedTypeMutationVariables = Exact<{
  data: UsersPermissionsUserInput;
}>;

export type UpdateFeedTypeMutation = {
  __typename?: 'Mutation';
  updateMe: {
    __typename?: 'UsersPermissionsUserEntityResponse';
    data?: { __typename?: 'UsersPermissionsUserEntity'; id?: string | null } | null;
  };
};

export type AddInterestToUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AddInterestToUserMutation = {
  __typename?: 'Mutation';
  addInterestToUser?: {
    __typename?: 'InterestsMe';
    myInterests?: Array<{
      __typename?: 'InterestEntity';
      id?: string | null;
      attributes?: { __typename?: 'Interest'; name?: string | null } | null;
    } | null> | null;
    otherInterests?: Array<{
      __typename?: 'InterestEntity';
      id?: string | null;
      attributes?: { __typename?: 'Interest'; name?: string | null } | null;
    } | null> | null;
  } | null;
};

export type RemoveInterestToUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveInterestToUserMutation = {
  __typename?: 'Mutation';
  removeInterestToUser?: {
    __typename?: 'InterestsMe';
    myInterests?: Array<{
      __typename?: 'InterestEntity';
      id?: string | null;
      attributes?: { __typename?: 'Interest'; name?: string | null } | null;
    } | null> | null;
    otherInterests?: Array<{
      __typename?: 'InterestEntity';
      id?: string | null;
      attributes?: { __typename?: 'Interest'; name?: string | null } | null;
    } | null> | null;
  } | null;
};

export type SetUserInterestsMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type SetUserInterestsMutation = {
  __typename?: 'Mutation';
  setUserInterests?: { __typename?: 'SuccessData'; success?: boolean | null; message?: any | null } | null;
};

export type BlockedUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BlockedUserMutation = {
  __typename?: 'Mutation';
  blockedUser?: { __typename?: 'SuccessData'; success?: boolean | null; message?: any | null } | null;
};

export type UnblockedUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UnblockedUserMutation = {
  __typename?: 'Mutation';
  unblockedUser?: { __typename?: 'SuccessData'; success?: boolean | null; message?: any | null } | null;
};

export type CreateUserFollowerMutationVariables = Exact<{
  data: UserFollowerInput;
}>;

export type CreateUserFollowerMutation = {
  __typename?: 'Mutation';
  createUserFollower?: {
    __typename?: 'UserFollowerEntityResponse';
    data?: { __typename?: 'UserFollowerEntity'; id?: string | null } | null;
  } | null;
};

export type UpdateUserFollowerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: UserFollowerInput;
}>;

export type UpdateUserFollowerMutation = {
  __typename?: 'Mutation';
  updateUserFollower?: {
    __typename?: 'UserFollowerEntityResponse';
    data?: { __typename?: 'UserFollowerEntity'; id?: string | null } | null;
  } | null;
};

export type DeleteUserFollowerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteUserFollowerMutation = {
  __typename?: 'Mutation';
  deleteUserFollower?: {
    __typename?: 'UserFollowerEntityResponse';
    data?: { __typename?: 'UserFollowerEntity'; id?: string | null } | null;
  } | null;
};

export type UploadMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;

export type UploadMutation = {
  __typename?: 'Mutation';
  upload: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        name: string;
        url: string;
        hash: string;
        mime: string;
        provider: string;
      } | null;
    } | null;
  };
};

export type FollowUserMutationVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
}>;

export type FollowUserMutation = {
  __typename?: 'Mutation';
  FollowUser: { __typename?: 'SuccessData'; success?: boolean | null };
};

export type InviteContactsMutationVariables = Exact<{
  phone: Scalars['String']['input'];
}>;

export type InviteContactsMutation = {
  __typename?: 'Mutation';
  InviteContact: { __typename?: 'SuccessData'; success?: boolean | null };
};

export type AvatarsQueryVariables = Exact<{ [key: string]: never }>;

export type AvatarsQuery = {
  __typename?: 'Query';
  avatars?: {
    __typename?: 'AvatarEntityResponseCollection';
    data: Array<{
      __typename?: 'AvatarEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Avatar';
        image: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            attributes?: {
              __typename?: 'UploadFile';
              name: string;
              url: string;
              mime: string;
              hash: string;
              size: number;
              provider: string;
            } | null;
          } | null;
        };
      } | null;
    }>;
  } | null;
};

export type CommentsQueryVariables = Exact<{
  filters?: InputMaybe<CommentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
}>;

export type CommentsQuery = {
  __typename?: 'Query';
  comments?: {
    __typename?: 'CommentEntityResponseCollection';
    data: Array<{
      __typename?: 'CommentEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Comment';
        content?: string | null;
        publishedAt?: any | null;
        subcomments?: {
          __typename?: 'SubcommentRelationResponseCollection';
          data: Array<{
            __typename?: 'SubcommentEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'Subcomment';
              content?: string | null;
              publishedAt?: any | null;
              deleted?: boolean | null;
              author?: {
                __typename?: 'UsersPermissionsUserEntityResponse';
                data?: {
                  __typename?: 'UsersPermissionsUserEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UsersPermissionsUser';
                    username?: string | null;
                    firstname?: string | null;
                    lastname?: string | null;
                    isPro?: boolean | null;
                    companyName?: string | null;
                    profilePicture?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          name: string;
                          url: string;
                          mime: string;
                          hash: string;
                          size: number;
                          provider: string;
                        } | null;
                      } | null;
                    } | null;
                    avatar?: {
                      __typename?: 'AvatarEntityResponse';
                      data?: {
                        __typename?: 'AvatarEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'Avatar';
                          image: {
                            __typename?: 'UploadFileEntityResponse';
                            data?: {
                              __typename?: 'UploadFileEntity';
                              attributes?: {
                                __typename?: 'UploadFile';
                                name: string;
                                url: string;
                                mime: string;
                                hash: string;
                                size: number;
                                provider: string;
                              } | null;
                            } | null;
                          };
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
        author?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              username?: string | null;
              firstname?: string | null;
              lastname?: string | null;
              isPro?: boolean | null;
              companyName?: string | null;
              profilePicture?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
              avatar?: {
                __typename?: 'AvatarEntityResponse';
                data?: {
                  __typename?: 'AvatarEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'Avatar';
                    image: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          name: string;
                          url: string;
                          mime: string;
                          hash: string;
                          size: number;
                          provider: string;
                        } | null;
                      } | null;
                    };
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type EventQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type EventQuery = {
  __typename?: 'Query';
  event?: {
    __typename?: 'EventEntityResponse';
    data?: {
      __typename?: 'EventEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Event';
        name?: string | null;
        type?: Enum_Event_Type | null;
        eventDate?: any | null;
        eventEndDate?: any | null;
        useChat?: boolean | null;
        useQRCode?: boolean | null;
        allowUserToShare?: boolean | null;
        moneyRaised?: number | null;
        moneyToRaise?: number | null;
        maxInvited?: number | null;
        paymentType?: Enum_Event_Paymenttype | null;
        price?: number | null;
        paymentMessage?: string | null;
        addressLine?: string | null;
        addressCity?: string | null;
        addressZipcode?: string | null;
        addressCountry?: string | null;
        addressLat?: number | null;
        addressLng?: number | null;
        description?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        startHour?: any | null;
        endHour?: any | null;
        expired?: boolean | null;
        pending?: boolean | null;
        beginningDateTime?: any | null;
        expirationDateTime?: any | null;
        deleted?: boolean | null;
        beAccompanied?: boolean | null;
        participations?: {
          __typename?: 'EventUserParticipationRelationResponseCollection';
          data: Array<{ __typename?: 'EventUserParticipationEntity'; id?: string | null }>;
        } | null;
        interests?: {
          __typename?: 'InterestRelationResponseCollection';
          data: Array<{ __typename?: 'InterestEntity'; id?: string | null }>;
        } | null;
        secondaryImages?: {
          __typename?: 'UploadFileRelationResponseCollection';
          data: Array<{
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              mime: string;
              hash: string;
              size: number;
              previewUrl?: string | null;
            } | null;
          }>;
        } | null;
        creator?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              username?: string | null;
              firstname?: string | null;
              lastname?: string | null;
              stripeAccount?: string | null;
              isPro?: boolean | null;
              companyName?: string | null;
              stripeCapabilitiesStatus?: Enum_Userspermissionsuser_Stripecapabilitiesstatus | null;
              profilePicture?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
              avatar?: {
                __typename?: 'AvatarEntityResponse';
                data?: {
                  __typename?: 'AvatarEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'Avatar';
                    image: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          name: string;
                          url: string;
                          mime: string;
                          hash: string;
                          size: number;
                          provider: string;
                        } | null;
                      } | null;
                    };
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        coverCustomImage?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              name: string;
              url: string;
              mime: string;
              hash: string;
              size: number;
              provider: string;
            } | null;
          } | null;
        } | null;
        coverTedispoImage?: {
          __typename?: 'ImageEntityResponse';
          data?: {
            __typename?: 'ImageEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'Image';
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type EventUserParticipationsQueryVariables = Exact<{
  filters?: InputMaybe<EventUserParticipationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;

export type EventUserParticipationsQuery = {
  __typename?: 'Query';
  eventUserParticipations?: {
    __typename?: 'EventUserParticipationEntityResponseCollection';
    data: Array<{
      __typename?: 'EventUserParticipationEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'EventUserParticipation';
        status?: Enum_Eventuserparticipation_Status | null;
        note?: number | null;
        qrCode?: string | null;
        user?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              username?: string | null;
              isPro?: boolean | null;
              companyName?: string | null;
              firstname?: string | null;
              lastname?: string | null;
              profilePicture?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
              avatar?: {
                __typename?: 'AvatarEntityResponse';
                data?: {
                  __typename?: 'AvatarEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'Avatar';
                    image: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          name: string;
                          url: string;
                          mime: string;
                          hash: string;
                          size: number;
                          provider: string;
                        } | null;
                      } | null;
                    };
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        event?: {
          __typename?: 'EventEntityResponse';
          data?: {
            __typename?: 'EventEntity';
            id?: string | null;
            attributes?: { __typename?: 'Event'; name?: string | null } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type EventsQueryVariables = Exact<{
  filters?: InputMaybe<EventFiltersInput>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  pagination?: InputMaybe<PaginationArg>;
}>;

export type EventsQuery = {
  __typename?: 'Query';
  events?: {
    __typename?: 'EventEntityResponseCollection';
    meta: {
      __typename?: 'ResponseCollectionMeta';
      pagination: { __typename?: 'Pagination'; total: number; page: number; pageSize: number; pageCount: number };
    };
    data: Array<{
      __typename?: 'EventEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Event';
        name?: string | null;
        eventDate?: any | null;
        eventEndDate?: any | null;
        type?: Enum_Event_Type | null;
        paymentType?: Enum_Event_Paymenttype | null;
        addressCity?: string | null;
        addressLine?: string | null;
        addressLat?: number | null;
        addressLng?: number | null;
        price?: number | null;
        maxInvited?: number | null;
        deleted?: boolean | null;
        startHour?: any | null;
        endHour?: any | null;
        beginningDateTime?: any | null;
        expirationDateTime?: any | null;
        expired?: boolean | null;
        pending?: boolean | null;
        creator?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              username?: string | null;
              isPro?: boolean | null;
              companyName?: string | null;
              avatar?: {
                __typename?: 'AvatarEntityResponse';
                data?: {
                  __typename?: 'AvatarEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'Avatar';
                    image: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          name: string;
                          url: string;
                          mime: string;
                          hash: string;
                          size: number;
                          provider: string;
                        } | null;
                      } | null;
                    };
                  } | null;
                } | null;
              } | null;
              profilePicture?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        participations?: {
          __typename?: 'EventUserParticipationRelationResponseCollection';
          data: Array<{ __typename?: 'EventUserParticipationEntity'; id?: string | null }>;
        } | null;
        coverCustomImage?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            attributes?: {
              __typename?: 'UploadFile';
              name: string;
              url: string;
              mime: string;
              hash: string;
              size: number;
              provider: string;
            } | null;
          } | null;
        } | null;
        coverTedispoImage?: {
          __typename?: 'ImageEntityResponse';
          data?: {
            __typename?: 'ImageEntity';
            attributes?: {
              __typename?: 'Image';
              image?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type ForbiddenWordQueryVariables = Exact<{ [key: string]: never }>;

export type ForbiddenWordQuery = {
  __typename?: 'Query';
  forbiddenWord?: {
    __typename?: 'ForbiddenWordEntityResponse';
    data?: {
      __typename?: 'ForbiddenWordEntity';
      id?: string | null;
      attributes?: { __typename?: 'ForbiddenWord'; name?: string | null; formatted?: any | null } | null;
    } | null;
  } | null;
};

export type GetPostsQueryVariables = Exact<{
  filters?: InputMaybe<PostFiltersInput>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  pagination?: InputMaybe<PaginationArg>;
}>;

export type GetPostsQuery = {
  __typename?: 'Query';
  posts?: {
    __typename?: 'PostEntityResponseCollection';
    data: Array<{
      __typename?: 'PostEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Post';
        description?: string | null;
        medias?: {
          __typename?: 'UploadFileRelationResponseCollection';
          data: Array<{
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              name: string;
              mime: string;
              hash: string;
              size: number;
              provider: string;
              previewUrl?: string | null;
            } | null;
          }>;
        } | null;
        reactions?: {
          __typename?: 'ReactionRelationResponseCollection';
          data: Array<{ __typename?: 'ReactionEntity'; id?: string | null }>;
        } | null;
        comments?: {
          __typename?: 'CommentRelationResponseCollection';
          data: Array<{
            __typename?: 'CommentEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'Comment';
              deleted?: boolean | null;
              subcomments?: {
                __typename?: 'SubcommentRelationResponseCollection';
                data: Array<{
                  __typename?: 'SubcommentEntity';
                  id?: string | null;
                  attributes?: { __typename?: 'Subcomment'; deleted?: boolean | null } | null;
                }>;
              } | null;
            } | null;
          }>;
        } | null;
        author?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              username?: string | null;
              isPro?: boolean | null;
              companyName?: string | null;
              firstname?: string | null;
              lastname?: string | null;
              profilePicture?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
              avatar?: {
                __typename?: 'AvatarEntityResponse';
                data?: {
                  __typename?: 'AvatarEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'Avatar';
                    image: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          name: string;
                          url: string;
                          mime: string;
                          hash: string;
                          size: number;
                          provider: string;
                        } | null;
                      } | null;
                    };
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetPostQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPostQuery = {
  __typename?: 'Query';
  post?: {
    __typename?: 'PostEntityResponse';
    data?: {
      __typename?: 'PostEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Post';
        description?: string | null;
        medias?: {
          __typename?: 'UploadFileRelationResponseCollection';
          data: Array<{
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              name: string;
              mime: string;
              hash: string;
              size: number;
              provider: string;
              previewUrl?: string | null;
            } | null;
          }>;
        } | null;
        reactions?: {
          __typename?: 'ReactionRelationResponseCollection';
          data: Array<{ __typename?: 'ReactionEntity'; id?: string | null }>;
        } | null;
        comments?: {
          __typename?: 'CommentRelationResponseCollection';
          data: Array<{
            __typename?: 'CommentEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'Comment';
              deleted?: boolean | null;
              subcomments?: {
                __typename?: 'SubcommentRelationResponseCollection';
                data: Array<{
                  __typename?: 'SubcommentEntity';
                  id?: string | null;
                  attributes?: { __typename?: 'Subcomment'; deleted?: boolean | null } | null;
                }>;
              } | null;
            } | null;
          }>;
        } | null;
        author?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              username?: string | null;
              isPro?: boolean | null;
              companyName?: string | null;
              firstname?: string | null;
              lastname?: string | null;
              profilePicture?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
              avatar?: {
                __typename?: 'AvatarEntityResponse';
                data?: {
                  __typename?: 'AvatarEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'Avatar';
                    image: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          name: string;
                          url: string;
                          mime: string;
                          hash: string;
                          size: number;
                          provider: string;
                        } | null;
                      } | null;
                    };
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  usersPermissionsUser?: {
    __typename?: 'UsersPermissionsUserEntityResponse';
    data?: {
      __typename?: 'UsersPermissionsUserEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UsersPermissionsUser';
        phone: string;
        username?: string | null;
        isPro?: boolean | null;
        email?: string | null;
        firstname?: string | null;
        lastname?: string | null;
        birthDate?: any | null;
        companyName?: string | null;
        siret?: string | null;
        job?: string | null;
        feedType?: Enum_Userspermissionsuser_Feedtype | null;
        blockedUsers?: {
          __typename?: 'UsersPermissionsUserRelationResponseCollection';
          data: Array<{ __typename?: 'UsersPermissionsUserEntity'; id?: string | null }>;
        } | null;
        blockedByUsers?: {
          __typename?: 'UsersPermissionsUserRelationResponseCollection';
          data: Array<{ __typename?: 'UsersPermissionsUserEntity'; id?: string | null }>;
        } | null;
        avatar?: {
          __typename?: 'AvatarEntityResponse';
          data?: {
            __typename?: 'AvatarEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'Avatar';
              image: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              };
            } | null;
          } | null;
        } | null;
        profilePicture?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              name: string;
              url: string;
              mime: string;
              hash: string;
              size: number;
              provider: string;
            } | null;
          } | null;
        } | null;
        interests?: {
          __typename?: 'InterestRelationResponseCollection';
          data: Array<{
            __typename?: 'InterestEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'Interest';
              name?: string | null;
              picto?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetMyInterestQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyInterestQuery = {
  __typename?: 'Query';
  myInterest?: {
    __typename?: 'InterestsMe';
    myInterests?: Array<{
      __typename?: 'InterestEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Interest';
        name?: string | null;
        picto?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              name: string;
              url: string;
              mime: string;
              hash: string;
              size: number;
              provider: string;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    otherInterests?: Array<{
      __typename?: 'InterestEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Interest';
        name?: string | null;
        picto?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              name: string;
              url: string;
              mime: string;
              hash: string;
              size: number;
              provider: string;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetUserMainInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUserMainInfoQuery = {
  __typename?: 'Query';
  usersPermissionsUser?: {
    __typename?: 'UsersPermissionsUserEntityResponse';
    data?: {
      __typename?: 'UsersPermissionsUserEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UsersPermissionsUser';
        username?: string | null;
        isPro?: boolean | null;
        email?: string | null;
        firstname?: string | null;
        lastname?: string | null;
        companyName?: string | null;
        avatar?: {
          __typename?: 'AvatarEntityResponse';
          data?: {
            __typename?: 'AvatarEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'Avatar';
              image: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: { __typename?: 'UploadFile'; url: string } | null;
                } | null;
              };
            } | null;
          } | null;
        } | null;
        profilePicture?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: { __typename?: 'UploadFile'; url: string } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetUserChatNotificationSettingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUserChatNotificationSettingQuery = {
  __typename?: 'Query';
  usersPermissionsUser?: {
    __typename?: 'UsersPermissionsUserEntityResponse';
    data?: {
      __typename?: 'UsersPermissionsUserEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UsersPermissionsUser';
        chatNotificationMutes?: Array<{
          __typename?: 'ComponentNotificationSetting';
          duration?: Enum_Componentnotificationsetting_Duration | null;
          startDatetime?: any | null;
          message?: {
            __typename?: 'MessageEntityResponse';
            data?: {
              __typename?: 'MessageEntity';
              id?: string | null;
              attributes?: { __typename?: 'Message'; content?: string | null; firebaseId: string } | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type GetUsersPermissionsUsersQueryVariables = Exact<{
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
}>;

export type GetUsersPermissionsUsersQuery = {
  __typename?: 'Query';
  usersPermissionsUsers?: {
    __typename?: 'UsersPermissionsUserEntityResponseCollection';
    data: Array<{
      __typename?: 'UsersPermissionsUserEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UsersPermissionsUser';
        username?: string | null;
        email?: string | null;
        firstname?: string | null;
        lastname?: string | null;
        phone: string;
        isPro?: boolean | null;
        companyName?: string | null;
        avatar?: {
          __typename?: 'AvatarEntityResponse';
          data?: {
            __typename?: 'AvatarEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'Avatar';
              image: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              };
            } | null;
          } | null;
        } | null;
        profilePicture?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              name: string;
              url: string;
              mime: string;
              hash: string;
              size: number;
              provider: string;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type ImagesQueryVariables = Exact<{ [key: string]: never }>;

export type ImagesQuery = {
  __typename?: 'Query';
  images?: {
    __typename?: 'ImageEntityResponseCollection';
    data: Array<{
      __typename?: 'ImageEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Image';
        image?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            attributes?: { __typename?: 'UploadFile'; url: string } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AllImagesQueryVariables = Exact<{ [key: string]: never }>;

export type AllImagesQuery = {
  __typename?: 'Query';
  images?: {
    __typename?: 'ImageEntityResponseCollection';
    data: Array<{
      __typename?: 'ImageEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Image';
        image?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              name: string;
              url: string;
              mime: string;
              hash: string;
              size: number;
              provider: string;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type InterestsQueryVariables = Exact<{ [key: string]: never }>;

export type InterestsQuery = {
  __typename?: 'Query';
  interests?: {
    __typename?: 'InterestEntityResponseCollection';
    data: Array<{
      __typename?: 'InterestEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Interest';
        name?: string | null;
        picto?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              name: string;
              url: string;
              mime: string;
              hash: string;
              size: number;
              provider: string;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetMessagesFirebaseIdsQueryVariables = Exact<{
  filters?: InputMaybe<MessageFiltersInput>;
}>;

export type GetMessagesFirebaseIdsQuery = {
  __typename?: 'Query';
  messages?: {
    __typename?: 'MessageEntityResponseCollection';
    data: Array<{
      __typename?: 'MessageEntity';
      id?: string | null;
      attributes?: { __typename?: 'Message'; firebaseId: string } | null;
    }>;
  } | null;
};

export type GetMessageQueryVariables = Exact<{
  filters?: InputMaybe<MessageFiltersInput>;
}>;

export type GetMessageQuery = {
  __typename?: 'Query';
  messages?: {
    __typename?: 'MessageEntityResponseCollection';
    data: Array<{ __typename?: 'MessageEntity'; id?: string | null }>;
  } | null;
};

export type GetMessageItemsQueryVariables = Exact<{
  filters?: InputMaybe<MessageItemFiltersInput>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  pagination?: InputMaybe<PaginationArg>;
}>;

export type GetMessageItemsQuery = {
  __typename?: 'Query';
  messageItems?: {
    __typename?: 'MessageItemEntityResponseCollection';
    data: Array<{
      __typename?: 'MessageItemEntity';
      id?: string | null;
      attributes?: { __typename?: 'MessageItem'; firebaseItemId?: string | null } | null;
    }>;
  } | null;
};

export type NotificationsQueryVariables = Exact<{
  filters?: InputMaybe<NotificationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;

export type NotificationsQuery = {
  __typename?: 'Query';
  notifications?: {
    __typename?: 'NotificationEntityResponseCollection';
    meta: {
      __typename?: 'ResponseCollectionMeta';
      pagination: { __typename?: 'Pagination'; total: number; page: number; pageSize: number; pageCount: number };
    };
    data: Array<{
      __typename?: 'NotificationEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Notification';
        type?: Enum_Notification_Type | null;
        sent?: boolean | null;
        seen?: boolean | null;
        createdAt?: any | null;
        additionalData?: any | null;
        actionTo?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              username?: string | null;
              isPro?: boolean | null;
              companyName?: string | null;
            } | null;
          } | null;
        } | null;
        actionFrom?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              username?: string | null;
              companyName?: string | null;
              isPro?: boolean | null;
              avatar?: {
                __typename?: 'AvatarEntityResponse';
                data?: {
                  __typename?: 'AvatarEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'Avatar';
                    image: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          name: string;
                          url: string;
                          mime: string;
                          hash: string;
                          size: number;
                          provider: string;
                        } | null;
                      } | null;
                    };
                  } | null;
                } | null;
              } | null;
              profilePicture?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        event?: {
          __typename?: 'EventEntityResponse';
          data?: {
            __typename?: 'EventEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'Event';
              name?: string | null;
              eventDate?: any | null;
              eventEndDate?: any | null;
              startHour?: any | null;
              endHour?: any | null;
              type?: Enum_Event_Type | null;
              addressCity?: string | null;
              addressLine?: string | null;
              description?: string | null;
              expired?: boolean | null;
              coverCustomImage?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
              coverTedispoImage?: {
                __typename?: 'ImageEntityResponse';
                data?: {
                  __typename?: 'ImageEntity';
                  attributes?: {
                    __typename?: 'Image';
                    image?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          name: string;
                          url: string;
                          mime: string;
                          hash: string;
                          size: number;
                          provider: string;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        publication?: {
          __typename?: 'PostEntityResponse';
          data?: {
            __typename?: 'PostEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'Post';
              description?: string | null;
              medias?: {
                __typename?: 'UploadFileRelationResponseCollection';
                data: Array<{
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: { __typename?: 'UploadFile'; url: string; mime: string } | null;
                }>;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetOnBoardingQueryVariables = Exact<{ [key: string]: never }>;

export type GetOnBoardingQuery = {
  __typename?: 'Query';
  onBoarding?: {
    __typename?: 'OnBoardingEntityResponse';
    data?: {
      __typename?: 'OnBoardingEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'OnBoarding';
        items?: Array<{
          __typename?: 'ComponentOnBoardOnBoard';
          title?: string | null;
          description?: string | null;
          media?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: { __typename?: 'UploadFile'; url: string; name: string } | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type DispoSalesQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type DispoSalesQuery = {
  __typename?: 'Query';
  DispoSales?: {
    __typename?: 'MayasquadStripeIntegrationsSaleEntityResponseCollection';
    data: Array<{
      __typename?: 'MayasquadStripeIntegrationsSaleEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'MayasquadStripeIntegrationsSale';
        saleDate?: any | null;
        salePrice?: number | null;
        status?: Enum_Mayasquadstripeintegrationssale_Status | null;
        buyer?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              username?: string | null;
              lastname?: string | null;
              firstname?: string | null;
              isPro?: boolean | null;
              companyName?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type CreateStripeAccountLinksMutationVariables = Exact<{ [key: string]: never }>;

export type CreateStripeAccountLinksMutation = {
  __typename?: 'Mutation';
  CreateStripeAccountLinks?: { __typename?: 'StripeDataResponse'; data?: any | null } | null;
};

export type RetrieveMyStripeAccountQueryVariables = Exact<{ [key: string]: never }>;

export type RetrieveMyStripeAccountQuery = {
  __typename?: 'Query';
  RetrieveMyStripeAccount?: { __typename?: 'StripeDataResponse'; data?: any | null } | null;
};

export type CreateStripeAccountMutationVariables = Exact<{ [key: string]: never }>;

export type CreateStripeAccountMutation = {
  __typename?: 'Mutation';
  CreateStripeAccount?: { __typename?: 'StripeDataResponse'; data?: any | null } | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UsersPermissionsMe';
    id: string;
    phone?: string | null;
    publishedAt?: string | null;
    username: string;
    isPro?: boolean | null;
    email?: string | null;
    firstname?: string | null;
    lastname?: string | null;
    birthDate?: any | null;
    companyName?: string | null;
    feedType?: string | null;
    siret?: string | null;
    job?: string | null;
    legalStatus?: string | null;
    stripeAccount?: string | null;
    stripeCapabilitiesStatus?: string | null;
    blockedUsers?: Array<{ __typename?: 'UsersPermissionsUserEntity'; id?: string | null } | null> | null;
    blockedByUsers?: Array<{ __typename?: 'UsersPermissionsUserEntity'; id?: string | null } | null> | null;
    interests?: Array<{
      __typename?: 'InterestEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Interest';
        name?: string | null;
        picto?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              name: string;
              hash: string;
              mime: string;
              size: number;
              provider: string;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    avatar?: {
      __typename?: 'AvatarEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Avatar';
        image: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            attributes?: {
              __typename?: 'UploadFile';
              name: string;
              url: string;
              mime: string;
              hash: string;
              size: number;
              provider: string;
            } | null;
          } | null;
        };
      } | null;
    } | null;
    profilePicture?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        name: string;
        url: string;
        mime: string;
        hash: string;
        size: number;
        provider: string;
      } | null;
    } | null;
    followups?: {
      __typename?: 'UserFollowerEntityResponseCollection';
      data: Array<{
        __typename?: 'UserFollowerEntity';
        attributes?: {
          __typename?: 'UserFollower';
          status?: Enum_Userfollower_Status | null;
          user?: {
            __typename?: 'UsersPermissionsUserEntityResponse';
            data?: {
              __typename?: 'UsersPermissionsUserEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UsersPermissionsUser';
                username?: string | null;
                isPro?: boolean | null;
                companyName?: string | null;
                profilePicture?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    attributes?: {
                      __typename?: 'UploadFile';
                      name: string;
                      url: string;
                      mime: string;
                      hash: string;
                      size: number;
                      provider: string;
                    } | null;
                  } | null;
                } | null;
                avatar?: {
                  __typename?: 'AvatarEntityResponse';
                  data?: {
                    __typename?: 'AvatarEntity';
                    attributes?: {
                      __typename?: 'Avatar';
                      image: {
                        __typename?: 'UploadFileEntityResponse';
                        data?: {
                          __typename?: 'UploadFileEntity';
                          attributes?: {
                            __typename?: 'UploadFile';
                            name: string;
                            url: string;
                            mime: string;
                            hash: string;
                            size: number;
                            provider: string;
                          } | null;
                        } | null;
                      };
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      }>;
    } | null;
    followers?: {
      __typename?: 'UserFollowerEntityResponseCollection';
      data: Array<{
        __typename?: 'UserFollowerEntity';
        attributes?: {
          __typename?: 'UserFollower';
          status?: Enum_Userfollower_Status | null;
          follower?: {
            __typename?: 'UsersPermissionsUserEntityResponse';
            data?: {
              __typename?: 'UsersPermissionsUserEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UsersPermissionsUser';
                isPro?: boolean | null;
                companyName?: string | null;
                username?: string | null;
                phone: string;
                firstname?: string | null;
                lastname?: string | null;
                deleted?: boolean | null;
                profilePicture?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    attributes?: {
                      __typename?: 'UploadFile';
                      name: string;
                      url: string;
                      mime: string;
                      hash: string;
                      size: number;
                      provider: string;
                    } | null;
                  } | null;
                } | null;
                avatar?: {
                  __typename?: 'AvatarEntityResponse';
                  data?: {
                    __typename?: 'AvatarEntity';
                    attributes?: {
                      __typename?: 'Avatar';
                      image: {
                        __typename?: 'UploadFileEntityResponse';
                        data?: {
                          __typename?: 'UploadFileEntity';
                          attributes?: {
                            __typename?: 'UploadFile';
                            name: string;
                            url: string;
                            mime: string;
                            hash: string;
                            size: number;
                            provider: string;
                          } | null;
                        } | null;
                      };
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type MeMainInfoQueryVariables = Exact<{ [key: string]: never }>;

export type MeMainInfoQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UsersPermissionsMe';
    id: string;
    username: string;
    isPro?: boolean | null;
    email?: string | null;
    firstname?: string | null;
    lastname?: string | null;
    companyName?: string | null;
    avatar?: {
      __typename?: 'AvatarEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Avatar';
        image: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            attributes?: { __typename?: 'UploadFile'; url: string } | null;
          } | null;
        };
      } | null;
    } | null;
    profilePicture?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: { __typename?: 'UploadFile'; url: string } | null;
    } | null;
    blockedUsers?: Array<{ __typename?: 'UsersPermissionsUserEntity'; id?: string | null } | null> | null;
  } | null;
};

export type MyLikesQueryVariables = Exact<{ [key: string]: never }>;

export type MyLikesQuery = {
  __typename?: 'Query';
  myLikes?: {
    __typename?: 'MyLikes';
    posts?: Array<{ __typename?: 'PostLike'; idPost?: string | null; idReaction?: string | null } | null> | null;
  } | null;
};

export type UserFollowersQueryVariables = Exact<{
  filters?: InputMaybe<UserFollowerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
}>;

export type UserFollowersQuery = {
  __typename?: 'Query';
  userFollowers?: {
    __typename?: 'UserFollowerEntityResponseCollection';
    data: Array<{
      __typename?: 'UserFollowerEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UserFollower';
        status?: Enum_Userfollower_Status | null;
        user?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              feedType?: Enum_Userspermissionsuser_Feedtype | null;
              firstname?: string | null;
              lastname?: string | null;
              username?: string | null;
              isPro?: boolean | null;
              companyName?: string | null;
              avatar?: {
                __typename?: 'AvatarEntityResponse';
                data?: {
                  __typename?: 'AvatarEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'Avatar';
                    image: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          name: string;
                          url: string;
                          mime: string;
                          hash: string;
                          size: number;
                          provider: string;
                        } | null;
                      } | null;
                    };
                  } | null;
                } | null;
              } | null;
              profilePicture?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
              blockedUsers?: {
                __typename?: 'UsersPermissionsUserRelationResponseCollection';
                data: Array<{ __typename?: 'UsersPermissionsUserEntity'; id?: string | null }>;
              } | null;
            } | null;
          } | null;
        } | null;
        follower?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              firstname?: string | null;
              lastname?: string | null;
              username?: string | null;
              isPro?: boolean | null;
              companyName?: string | null;
              avatar?: {
                __typename?: 'AvatarEntityResponse';
                data?: {
                  __typename?: 'AvatarEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'Avatar';
                    image: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        attributes?: {
                          __typename?: 'UploadFile';
                          name: string;
                          url: string;
                          mime: string;
                          hash: string;
                          size: number;
                          provider: string;
                        } | null;
                      } | null;
                    };
                  } | null;
                } | null;
              } | null;
              profilePicture?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    name: string;
                    url: string;
                    mime: string;
                    hash: string;
                    size: number;
                    provider: string;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type CountFollowersQueryVariables = Exact<{
  filters?: InputMaybe<UserFollowerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
}>;

export type CountFollowersQuery = {
  __typename?: 'Query';
  userFollowers?: {
    __typename?: 'UserFollowerEntityResponseCollection';
    data: Array<{
      __typename?: 'UserFollowerEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UserFollower';
        status?: Enum_Userfollower_Status | null;
        user?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              username?: string | null;
              isPro?: boolean | null;
              companyName?: string | null;
            } | null;
          } | null;
        } | null;
        follower?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              username?: string | null;
              isPro?: boolean | null;
              companyName?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type EventWeathersQueryVariables = Exact<{
  filters?: InputMaybe<EventWeatherFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
}>;

export type EventWeathersQuery = {
  __typename?: 'Query';
  eventWeathers?: {
    __typename?: 'EventWeatherEntityResponseCollection';
    meta: {
      __typename?: 'ResponseCollectionMeta';
      pagination: { __typename?: 'Pagination'; total: number; page: number; pageSize: number; pageCount: number };
    };
    data: Array<{
      __typename?: 'EventWeatherEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'EventWeather';
        weatherCondition?: string | null;
        dispo_id?: number | null;
        data?: any | null;
        date?: any | null;
      } | null;
    }>;
  } | null;
};

export const AuthRegisterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AuthRegister' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthRegisterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AuthRegister' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthRegisterMutation, AuthRegisterMutationVariables>;
export const AuthWithPhoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AuthWithPhone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthPhoneInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AuthWithPhone' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthWithPhoneMutation, AuthWithPhoneMutationVariables>;
export const AuthVerifySmsCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AuthVerifySmsCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthVerifyCodeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AuthVerifySmsCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jwt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'confirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interests' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthVerifySmsCodeMutation, AuthVerifySmsCodeMutationVariables>;
export const UpdateMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersPermissionsUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'phone' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMeMutation, UpdateMeMutationVariables>;
export const VerifyPhoneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyPhone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthPhoneInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'VerifyPhone' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyPhoneMutation, VerifyPhoneMutationVariables>;
export const CreateCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CommentInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'content' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCommentMutation, CreateCommentMutationVariables>;
export const CreateSubCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSubComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubcommentInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSubcomment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'content' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSubCommentMutation, CreateSubCommentMutationVariables>;
export const DeleteCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const DeleteSubcommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteSubcomment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSubcomment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSubcommentMutation, DeleteSubcommentMutationVariables>;
export const DeleteEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEventMutation, DeleteEventMutationVariables>;
export const DeletePostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePostMutation, DeletePostMutationVariables>;
export const CreateEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EventInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventEndDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startHour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endHour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expired' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coverCustomImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coverTedispoImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEventMutation, CreateEventMutationVariables>;
export const InviteToEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InviteToEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ParticipationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'InviteToEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteToEventMutation, InviteToEventMutationVariables>;
export const CreateMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'content' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMessageMutation, CreateMessageMutationVariables>;
export const CreateMessageItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMessageItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageItemInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMessageItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMessageItemMutation, CreateMessageItemMutationVariables>;
export const CheckMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checkMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firebaseDocId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CheckMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firebaseDocId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firebaseDocId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckMessageMutation, CheckMessageMutationVariables>;
export const UpdateNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateNotificationMutation, UpdateNotificationMutationVariables>;
export const MarkAsSeenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkAsSeen' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MarkAsSeen' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkAsSeenMutation, MarkAsSeenMutationVariables>;
export const CreatePostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'medias' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePostMutation, CreatePostMutationVariables>;
export const UpdatePostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'medias' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePostMutation, UpdatePostMutationVariables>;
export const CreateReactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createReaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReactionInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateReactionMutation, CreateReactionMutationVariables>;
export const DeleteReactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteReaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteReaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteReactionMutation, DeleteReactionMutationVariables>;
export const CreateReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportedBy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateReportMutation, CreateReportMutationVariables>;
export const CreatePaymentIntentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePaymentIntent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePaymentIntentInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CreatePaymentIntent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'data' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;
export const DownloadTicketDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DownloadTicket' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'qrCodeImage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'DownloadTicket' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'qrCodeImage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'qrCodeImage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timezone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'filePath' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DownloadTicketMutation, DownloadTicketMutationVariables>;
export const CreateGooglePassDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateGooglePass' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startHour' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endHour' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lat' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lng' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zip_code' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'beginningDateTime' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'coverCustomImage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'qrCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CreateGooglePass' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startHour' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startHour' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endHour' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endHour' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lat' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lat' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lng' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lng' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zip_code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'zip_code' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'beginningDateTime' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'beginningDateTime' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'coverCustomImage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'coverCustomImage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'qrCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'qrCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'token' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateGooglePassMutation, CreateGooglePassMutationVariables>;
export const GeneratePassDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GeneratePass' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startHour' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'image' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'qrCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'GeneratePass' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startHour' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startHour' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'image' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'image' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'qrCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'qrCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'data' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GeneratePassMutation, GeneratePassMutationVariables>;
export const UpdateEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EventInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventEndDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startHour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endHour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expired' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEventMutation, UpdateEventMutationVariables>;
export const UpdateEventUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEventUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EventUserParticipationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEventUserParticipation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'event' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEventUserMutation, UpdateEventUserMutationVariables>;
export const InviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'invite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ParticipationInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'InviteToEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteMutation, InviteMutationVariables>;
export const AcceptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'accept' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AcceptInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptMutation, AcceptMutationVariables>;
export const RefuseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'refuse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'RefuseInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefuseMutation, RefuseMutationVariables>;
export const NoteEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'noteEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'NoteEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'note' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NoteEventMutation, NoteEventMutationVariables>;
export const ParticipeToEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'participeToEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ParticipeToEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ParticipeToEventMutation, ParticipeToEventMutationVariables>;
export const DeleteEventUserParticipationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEventUserParticipation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEventUserParticipation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEventUserParticipationMutation, DeleteEventUserParticipationMutationVariables>;
export const CancelParticipationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelParticipation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CancelParticipation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelParticipationMutation, CancelParticipationMutationVariables>;
export const UpdateUsersPermissionsUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUsersPermissionsUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersPermissionsUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUsersPermissionsUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUsersPermissionsUserMutation, UpdateUsersPermissionsUserMutationVariables>;
export const DeleteUsersPermissionsUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteUsersPermissionsUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUsersPermissionsUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUsersPermissionsUserMutation, DeleteUsersPermissionsUserMutationVariables>;
export const UpdateFeedTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFeedType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersPermissionsUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFeedTypeMutation, UpdateFeedTypeMutationVariables>;
export const AddInterestToUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addInterestToUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addInterestToUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'myInterests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'otherInterests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddInterestToUserMutation, AddInterestToUserMutationVariables>;
export const RemoveInterestToUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeInterestToUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeInterestToUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'myInterests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'otherInterests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveInterestToUserMutation, RemoveInterestToUserMutationVariables>;
export const SetUserInterestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setUserInterests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setUserInterests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetUserInterestsMutation, SetUserInterestsMutationVariables>;
export const BlockedUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'blockedUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blockedUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlockedUserMutation, BlockedUserMutationVariables>;
export const UnblockedUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'unblockedUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unblockedUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnblockedUserMutation, UnblockedUserMutationVariables>;
export const CreateUserFollowerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUserFollower' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserFollowerInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserFollower' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserFollowerMutation, CreateUserFollowerMutationVariables>;
export const UpdateUserFollowerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserFollower' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserFollowerInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserFollower' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserFollowerMutation, UpdateUserFollowerMutationVariables>;
export const DeleteUserFollowerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteUserFollower' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserFollower' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserFollowerMutation, DeleteUserFollowerMutationVariables>;
export const UploadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upload' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadMutation, UploadMutationVariables>;
export const FollowUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'followUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FollowUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FollowUserMutation, FollowUserMutationVariables>;
export const InviteContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'inviteContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'InviteContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteContactsMutation, InviteContactsMutationVariables>;
export const AvatarsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'avatars' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatars' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AvatarsQuery, AvatarsQueryVariables>;
export const CommentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'comments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CommentFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationArg' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subcomments' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'author' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'username' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'firstname' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'lastname' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'isPro' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'companyName' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'profilePicture' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'data' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: {
                                                                                kind: 'Name',
                                                                                value: 'attributes',
                                                                              },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'name',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'url',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'mime',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'hash',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'size',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'provider',
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'avatar' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'data' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: { kind: 'Name', value: 'id' },
                                                                            },
                                                                            {
                                                                              kind: 'Field',
                                                                              name: {
                                                                                kind: 'Name',
                                                                                value: 'attributes',
                                                                              },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'image',
                                                                                    },
                                                                                    selectionSet: {
                                                                                      kind: 'SelectionSet',
                                                                                      selections: [
                                                                                        {
                                                                                          kind: 'Field',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value: 'data',
                                                                                          },
                                                                                          selectionSet: {
                                                                                            kind: 'SelectionSet',
                                                                                            selections: [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value: 'attributes',
                                                                                                },
                                                                                                selectionSet: {
                                                                                                  kind: 'SelectionSet',
                                                                                                  selections: [
                                                                                                    {
                                                                                                      kind: 'Field',
                                                                                                      name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'name',
                                                                                                      },
                                                                                                    },
                                                                                                    {
                                                                                                      kind: 'Field',
                                                                                                      name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'url',
                                                                                                      },
                                                                                                    },
                                                                                                    {
                                                                                                      kind: 'Field',
                                                                                                      name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'mime',
                                                                                                      },
                                                                                                    },
                                                                                                    {
                                                                                                      kind: 'Field',
                                                                                                      name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'hash',
                                                                                                      },
                                                                                                    },
                                                                                                    {
                                                                                                      kind: 'Field',
                                                                                                      name: {
                                                                                                        kind: 'Name',
                                                                                                        value: 'size',
                                                                                                      },
                                                                                                    },
                                                                                                    {
                                                                                                      kind: 'Field',
                                                                                                      name: {
                                                                                                        kind: 'Name',
                                                                                                        value:
                                                                                                          'provider',
                                                                                                      },
                                                                                                    },
                                                                                                  ],
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                          },
                                                                                        },
                                                                                      ],
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'author' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'profilePicture' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'image' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'data' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: {
                                                                                kind: 'Name',
                                                                                value: 'attributes',
                                                                              },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'name',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'url',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'mime',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'hash',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'size',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'provider',
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CommentsQuery, CommentsQueryVariables>;
export const EventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'event' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'event' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventEndDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'useChat' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'useQRCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'allowUserToShare' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'moneyRaised' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'moneyToRaise' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxInvited' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paymentMessage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressCity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressZipcode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressCountry' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressLat' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressLng' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startHour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endHour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'beginningDateTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expirationDateTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'beAccompanied' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'participations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'interests' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'secondaryImages' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'creator' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'stripeAccount' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'stripeCapabilitiesStatus' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'profilePicture' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'image' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'data' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: {
                                                                                kind: 'Name',
                                                                                value: 'attributes',
                                                                              },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'name',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'url',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'mime',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'hash',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'size',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'provider',
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coverCustomImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coverTedispoImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventQuery, EventQueryVariables>;
export const EventUserParticipationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'eventUserParticipations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EventUserParticipationFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationArg' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventUserParticipations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'qrCode' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'profilePicture' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'image' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'data' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: {
                                                                                kind: 'Name',
                                                                                value: 'attributes',
                                                                              },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'name',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'url',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'mime',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'hash',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'size',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'provider',
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'event' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventUserParticipationsQuery, EventUserParticipationsQueryVariables>;
export const EventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'events' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EventFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationArg' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'events' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pagination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageCount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventEndDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressCity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressLat' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressLng' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxInvited' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startHour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endHour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'beginningDateTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expirationDateTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'creator' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'image' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'data' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: {
                                                                                kind: 'Name',
                                                                                value: 'attributes',
                                                                              },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'name',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'url',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'mime',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'hash',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'size',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'provider',
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'profilePicture' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'participations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coverCustomImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coverTedispoImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventsQuery, EventsQueryVariables>;
export const ForbiddenWordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'forbiddenWord' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forbiddenWord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'formatted' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ForbiddenWordQuery, ForbiddenWordQueryVariables>;
export const GetPostsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPosts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationArg' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'medias' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reactions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comments' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subcomments' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'deleted' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'author' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'profilePicture' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'image' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'data' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: {
                                                                                kind: 'Name',
                                                                                value: 'attributes',
                                                                              },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'name',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'url',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'mime',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'hash',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'size',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'provider',
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPostsQuery, GetPostsQueryVariables>;
export const GetPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'medias' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reactions' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'pagination' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'limit' },
                                        value: { kind: 'IntValue', value: '250' },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comments' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'pagination' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'limit' },
                                        value: { kind: 'IntValue', value: '250' },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subcomments' },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'pagination' },
                                                    value: {
                                                      kind: 'ObjectValue',
                                                      fields: [
                                                        {
                                                          kind: 'ObjectField',
                                                          name: { kind: 'Name', value: 'limit' },
                                                          value: { kind: 'IntValue', value: '250' },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                ],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'deleted' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'author' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'profilePicture' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'image' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'data' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: {
                                                                                kind: 'Name',
                                                                                value: 'attributes',
                                                                              },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'name',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'url',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'mime',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'hash',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'size',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'provider',
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPostQuery, GetPostQueryVariables>;
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersPermissionsUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'siret' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'job' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feedType' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'blockedUsers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'blockedByUsers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'interests' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'picto' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const GetMyInterestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMyInterest' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myInterest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'myInterests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'picto' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'otherInterests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'picto' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMyInterestQuery, GetMyInterestQueryVariables>;
export const GetUserMainInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserMainInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersPermissionsUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserMainInfoQuery, GetUserMainInfoQueryVariables>;
export const GetUserChatNotificationSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserChatNotificationSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersPermissionsUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'chatNotificationMutes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startDatetime' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'message' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'data' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'attributes' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'firebaseId' } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserChatNotificationSettingQuery, GetUserChatNotificationSettingQueryVariables>;
export const GetUsersPermissionsUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUsersPermissionsUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersPermissionsUserFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationArg' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersPermissionsUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profilePicture' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUsersPermissionsUsersQuery, GetUsersPermissionsUsersQueryVariables>;
export const ImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'images' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImagesQuery, ImagesQueryVariables>;
export const AllImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allImages' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllImagesQuery, AllImagesQueryVariables>;
export const InterestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'interests' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'picto' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InterestsQuery, InterestsQueryVariables>;
export const GetMessagesFirebaseIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMessagesFirebaseIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageFiltersInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'firebaseId' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMessagesFirebaseIdsQuery, GetMessagesFirebaseIdsQueryVariables>;
export const GetMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageFiltersInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMessageQuery, GetMessageQueryVariables>;
export const GetMessageItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMessageItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageItemFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationArg' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'firebaseItemId' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMessageItemsQuery, GetMessageItemsQueryVariables>;
export const NotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'notifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationArg' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pagination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageCount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'seen' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalData' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'actionTo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'actionFrom' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'image' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'data' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: {
                                                                                kind: 'Name',
                                                                                value: 'attributes',
                                                                              },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'name',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'url',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'mime',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'hash',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'size',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'provider',
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'profilePicture' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'event' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'eventDate' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'eventEndDate' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'startHour' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'endHour' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'addressCity' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'addressLine' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'expired' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'coverCustomImage' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'coverTedispoImage' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'image' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'data' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: {
                                                                                kind: 'Name',
                                                                                value: 'attributes',
                                                                              },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'name',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'url',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'mime',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'hash',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'size',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'provider',
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'publication' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'medias' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NotificationsQuery, NotificationsQueryVariables>;
export const GetOnBoardingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOnBoarding' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onBoarding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'media' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'data' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'attributes' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOnBoardingQuery, GetOnBoardingQueryVariables>;
export const DispoSalesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dispoSales' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'DispoSales' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'saleDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'salePrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'buyer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DispoSalesQuery, DispoSalesQueryVariables>;
export const CreateStripeAccountLinksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateStripeAccountLinks' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CreateStripeAccountLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'data' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateStripeAccountLinksMutation, CreateStripeAccountLinksMutationVariables>;
export const RetrieveMyStripeAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RetrieveMyStripeAccount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'RetrieveMyStripeAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'data' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RetrieveMyStripeAccountQuery, RetrieveMyStripeAccountQueryVariables>;
export const CreateStripeAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateStripeAccount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CreateStripeAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'data' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateStripeAccountMutation, CreateStripeAccountMutationVariables>;
export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'feedType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'siret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'job' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legalStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blockedUsers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blockedByUsers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'stripeAccount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stripeCapabilitiesStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'interests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'picto' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'data' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'attributes' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'profilePicture' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'attributes' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'name' },
                                                                      },
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'url' },
                                                                      },
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'mime' },
                                                                      },
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'hash' },
                                                                      },
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'size' },
                                                                      },
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'provider' },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'avatar' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'attributes' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'image' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: { kind: 'Name', value: 'data' },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'attributes',
                                                                                    },
                                                                                    selectionSet: {
                                                                                      kind: 'SelectionSet',
                                                                                      selections: [
                                                                                        {
                                                                                          kind: 'Field',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value: 'name',
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          kind: 'Field',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value: 'url',
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          kind: 'Field',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value: 'mime',
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          kind: 'Field',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value: 'hash',
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          kind: 'Field',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value: 'size',
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          kind: 'Field',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value: 'provider',
                                                                                          },
                                                                                        },
                                                                                      ],
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'follower' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'data' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'attributes' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'profilePicture' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'attributes' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'name' },
                                                                      },
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'url' },
                                                                      },
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'mime' },
                                                                      },
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'hash' },
                                                                      },
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'size' },
                                                                      },
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'provider' },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'avatar' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'data' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'attributes' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'image' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: { kind: 'Name', value: 'data' },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'attributes',
                                                                                    },
                                                                                    selectionSet: {
                                                                                      kind: 'SelectionSet',
                                                                                      selections: [
                                                                                        {
                                                                                          kind: 'Field',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value: 'name',
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          kind: 'Field',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value: 'url',
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          kind: 'Field',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value: 'mime',
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          kind: 'Field',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value: 'hash',
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          kind: 'Field',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value: 'size',
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          kind: 'Field',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value: 'provider',
                                                                                          },
                                                                                        },
                                                                                      ],
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
export const MeMainInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'meMainInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePicture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blockedUsers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeMainInfoQuery, MeMainInfoQueryVariables>;
export const MyLikesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myLikes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myLikes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'posts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'idPost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idReaction' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyLikesQuery, MyLikesQueryVariables>;
export const UserFollowersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userFollowers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserFollowerFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationArg' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFollowers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'feedType' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'image' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'data' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: {
                                                                                kind: 'Name',
                                                                                value: 'attributes',
                                                                              },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'name',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'url',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'mime',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'hash',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'size',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'provider',
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'profilePicture' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'blockedUsers' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'follower' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'firstname' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'lastname' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'image' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'data' },
                                                                        selectionSet: {
                                                                          kind: 'SelectionSet',
                                                                          selections: [
                                                                            {
                                                                              kind: 'Field',
                                                                              name: {
                                                                                kind: 'Name',
                                                                                value: 'attributes',
                                                                              },
                                                                              selectionSet: {
                                                                                kind: 'SelectionSet',
                                                                                selections: [
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'name',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'url',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'mime',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'hash',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'size',
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                      kind: 'Name',
                                                                                      value: 'provider',
                                                                                    },
                                                                                  },
                                                                                ],
                                                                              },
                                                                            },
                                                                          ],
                                                                        },
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'profilePicture' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'data' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'attributes' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'name' },
                                                                },
                                                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'mime' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'hash' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'size' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'provider' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFollowersQuery, UserFollowersQueryVariables>;
export const CountFollowersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'countFollowers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserFollowerFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationArg' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFollowers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'follower' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountFollowersQuery, CountFollowersQueryVariables>;
export const EventWeathersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'eventWeathers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EventWeatherFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationArg' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventWeathers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pagination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageCount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'weatherCondition' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dispo_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventWeathersQuery, EventWeathersQueryVariables>;
