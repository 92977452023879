import { VideoEditor } from '@whiteguru/capacitor-plugin-video-editor';
import Compressor from 'compressorjs';

export const base64ToFile = (base64String: string, mime: string, filename: string): File => {
  const bstr = atob(base64String);
  let { length } = bstr;
  const u8arr = new Uint8Array(length);

  // eslint-disable-next-line no-plusplus
  while (length--) {
    u8arr[length] = bstr.charCodeAt(length);
  }

  return new File([u8arr], filename, { type: mime });
};

export const fileToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error: any) => reject(error);
  });
};
export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const removeExtension = (fileName: string) => fileName.substring(0, fileName.lastIndexOf('.')) || fileName;

export const compressImage = (file: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 1024,
      maxHeight: 1024,

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(compressedFile) {
        if (compressedFile instanceof File) return resolve(compressedFile);
        else {
          const compressedFileFromBlob = new File([compressedFile], removeExtension(file.name), {
            type: compressedFile.type,
          });
          return resolve(compressedFileFromBlob);
        }
      },
      error(err) {
        console.log(err.message);
        reject(err);
      },
    });
  });
};

export const compressVideo = async (path: string) => {
  try {
    console.log('path ', path);
    const mediaFileResult = await VideoEditor.edit({
      path,
      transcode: {
        width: 1080,
        height: 1080,
        keepAspectRatio: true,
      },
    });
    console.log('mediaPath', mediaFileResult.file.path);
    return mediaFileResult.file.path as string;
  } catch (e) {
    console.error(e);
  }
};
