import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { GET_ME } from '../../../graphql/queries/user.graphql';
import { useDispo } from '../../../hooks';
import { useStripeOnBoarding } from '../../../hooks/useStripeOnBoarding';
import { StorageUtils } from '../../../utils';

export const AccountDone: React.FC<any> = (props) => {
  console.log('SHOULD BACK ');
  const [fetchUser, { data: userData, refetch: refetchUser }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'no-cache',
  });
  const { setDispo } = useDispo();
  const history = useHistory();

  useEffect(() => {
    const check = async () => {
      const { data } = await fetchUser();
      const ev = StorageUtils.get('dispoDraft');
      if (ev) {
        const tedispo = JSON.parse(ev);
        if (tedispo && tedispo.name) {
          setDispo(tedispo);
          if (!data?.me?.stripeAccount) {
            // no account yet
            history.replace('/main/home/create-dispo/step-tarif?stripeAccount=0');
            return;
          }
          if (data?.me?.stripeCapabilitiesStatus === 'active') {
            // success
            history.replace('/main/home/create-dispo/step-tarif?stripeAccount=1');
            return;
          }
          if (data?.me?.stripeCapabilitiesStatus !== 'active') {
            // error
            return;
          }
        }
      }

      history.replace('/signup/verify-config');
    };
    check();
  }, [fetchUser]);
  return <div />;
};

export default AccountDone;
