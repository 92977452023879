import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';

interface ICustomButton {
  action?: () => any;
  class?: string;
  style?: {};
  text?: string;
  loading?: boolean;
  icon?: any;
}

export const CustomButton: React.FC<ICustomButton> = ({
  action,
  class: className,
  style,
  text,
  loading = false,
  icon,
}) => {
  return (
    <IonButton mode="ios" expand="block" onClick={action} style={style} className={className} disabled={loading}>
      <IonRow>
        <IonCol>{text}</IonCol>
        {icon && <IonIcon icon={icon} />}
      </IonRow>
    </IonButton>
  );
};
