import { IonCol, IonIcon, IonRow, IonText } from '@ionic/react';
import { shareOutline, linkOutline } from 'ionicons/icons';

interface IListWithImage {
  date?: Maybe<string>;
  time?: Maybe<string>;
  dateEnd?: Maybe<string>;
  timeEnd?: Maybe<string>;
  image: any;
  action?: () => void;
  isOneDay?: boolean;
}

const ListWithImageTime: React.FC<IListWithImage> = ({
  date,
  dateEnd,
  time,
  timeEnd,
  image,
  action,
  isOneDay = false,
}) => {
  console.log({ timeEnd, dateEnd, date, time });
  return (
    <IonRow className="ion-justify-content-center ion-align-items-center bg-blue-light border-radius-10 margin-bottom-5 text-black">
      <IonCol size="auto">
        <IonIcon icon={image} size="large" className="ion-icon-blue"></IonIcon>
      </IonCol>

      {isOneDay ? (
        <IonCol onClick={action} style={action ? { cursor: 'pointer' } : undefined}>
          <IonText>
            <span className="blue-text text-bold text-capitalize">{date}</span>
            <span className="blue-on-hover text-capitalize font-outfit text-size-xs">
              {' '}
              de {time} à {timeEnd}
            </span>
          </IonText>
        </IonCol>
      ) : (
        <IonCol onClick={action} style={action ? { cursor: 'pointer' } : undefined}>
          <IonRow className="blue-on-hover text-size-lg font-outfit">
            <IonText>
              <span className="blue-text text-bold text-capitalize">{date}</span> {' à '}
              <span className="blue-on-hover text-capitalize font-outfit text-size-xs">{time}</span>
            </IonText>
          </IonRow>
          <IonRow className="blue-on-hover text-size-lg font-outfit">
            <IonText>
              <span className="blue-text text-bold text-capitalize">{dateEnd}</span> {" jusqu'à "}
              <span className="blue-on-hover text-capitalize font-outfit text-size-xs">{timeEnd}</span>
            </IonText>
          </IonRow>
        </IonCol>
      )}
    </IonRow>
  );
};

export default ListWithImageTime;
