import { useQuery } from '@apollo/client';
import { IonAvatar, IonCheckbox, IonItem, IonLabel, IonText } from '@ionic/react';
import { filter, sortBy, uniqWith } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { AppSearchFilter, InputCustom } from '../../../components';
import { GET_ME } from '../../../graphql/queries/user.graphql';
import { UserFollowerEntity } from '../../../models/gql/graphql';
import './style.css';

interface IProps {
  handleChange: (data: Array<string>, isIncrement: boolean) => void;
  getFollower: (data: Array<UserFollowerEntity>) => void;
  followerCheckedFromParent?: Array<string>;
}

const PrivateView: React.FC<IProps> = ({ handleChange, getFollower, followerCheckedFromParent }) => {
  const { data, loading: followersLoading } = useQuery(GET_ME);
  const [followerChecked, setFollowerChecked] = useState<Array<string>>([]);
  const [usersFiltered, setUsersFiltered] = useState<Array<UserFollowerEntity>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const followers = useMemo(() => {
    const fol = !followersLoading
      ? (sortBy(data?.me?.followers?.data, (elem: UserFollowerEntity) =>
          elem.attributes?.follower?.data?.attributes?.username?.toLowerCase()
        ) as UserFollowerEntity[])
      : [];
    const blockedUser = !followersLoading ? data?.me?.blockedUsers : [];
    const blockedByUser = !followersLoading ? data?.me?.blockedByUsers : [];

    const folNotDeleted = filter(fol, (f) => !f.attributes?.follower?.data?.attributes?.deleted);
    let folUnique = uniqWith(
      folNotDeleted,
      (arrVal, othVal) => arrVal.attributes?.follower?.data?.id === othVal.attributes?.follower?.data?.id
    );

    folUnique = folUnique.filter((data) => {
      const followerId = data.attributes?.follower?.data?.id;

      const isNotBlocked =
        !blockedUser?.some((blockedId) => blockedId?.id === followerId) &&
        !blockedByUser?.some((blockedByUserId) => blockedByUserId?.id === followerId);

      return isNotBlocked;
    });

    setUsersFiltered(folUnique);
    getFollower(folUnique);
    return folUnique;
  }, [followersLoading, data]);

  const handleIDChange = (isChecked: boolean, idFollow: string) => {
    if (followerChecked.includes(idFollow)) {
      if (!isChecked) {
        const checkeds = followerChecked.filter((value) => value !== idFollow);
        setFollowerChecked(checkeds);
        handleChange(checkeds, false);
      }
    } else {
      if (isChecked) {
        const checkeds = [...followerChecked, idFollow];
        setFollowerChecked(checkeds);
        handleChange(checkeds, true);
      }
    }
    setIsOpen(true);
  };

  const onDone = (dataFiltered: UserFollowerEntity[]) => {
    setUsersFiltered(dataFiltered);
    getFollower(dataFiltered);
  };

  useEffect(() => {
    setFollowerChecked(followerCheckedFromParent as any);
  }, [followerCheckedFromParent]);

  return (
    <>
      <AppSearchFilter
        placeholder="Recherche"
        parentFilterKey="attributes"
        filterKey="username"
        items={followers as any}
        onChange={(e) => console.log(e.detail.value)}
        onDone={onDone}
        isLongSearch={true}
        useOnInput={true}
      ></AppSearchFilter>

      <IonText color="dark" className="font-outfit">
        <h4>
          {usersFiltered.length < followers.length
            ? usersFiltered.length + ' résultat' + (usersFiltered.length > 1 ? 's' : '')
            : 'Mes abonnés'}
        </h4>
      </IonText>

      {usersFiltered?.map((follower) => (
        <IonCheckbox
          className="display-block color-checkbox-custom"
          key={follower?.id}
          onIonChange={(e) => handleIDChange(e.detail.checked, follower?.attributes?.follower?.data?.id as string)}
          checked={followerChecked.includes(follower?.attributes?.follower?.data?.id as string)}
          mode="ios"
        >
          <IonItem lines="none" className="ion-no-padding">
            <IonAvatar slot="start">
              <img
                alt="Silhouette of a person's head"
                src={
                  follower?.attributes?.follower?.data?.attributes?.profilePicture?.data
                    ? follower?.attributes?.follower?.data?.attributes?.profilePicture.data?.attributes?.url
                    : follower?.attributes?.follower?.data?.attributes?.avatar?.data
                    ? follower.attributes.follower?.data?.attributes?.avatar.data?.attributes?.image.data?.attributes
                        ?.url
                    : 'https://ionicframework.com/docs/img/demos/avatar.svg'
                }
              />
            </IonAvatar>
            <IonLabel className="font-outfit ion-text-capitalize">
              {follower?.attributes?.follower?.data?.attributes?.username}
            </IonLabel>
          </IonItem>
        </IonCheckbox>
      ))}
    </>
  );
};

export default PrivateView;
