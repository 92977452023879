import { Browser } from '@capacitor/browser';
import { FC, useEffect } from 'react';

interface IProps {
  file: any;
}

const PdfViewer: FC<IProps> = ({ file }) => {
  const openCapacitorSite = async () => {
    await Browser.open({ url: file.url });
  };
  useEffect(() => {
    openCapacitorSite();
  }, []);
  return <div>{file.name}</div>;
};

export default PdfViewer;
