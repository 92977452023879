import { IonRow } from '@ionic/react';
import { Fragment } from 'react';
import { useHistory, useParams } from 'react-router';
import { ProfilLock, VideoView } from '../../../components';
import { getUserId } from '../../../utils';
import '../style.css';

interface IPosts {
  locked?: boolean;
  posts: any[];
}
const Posts: React.FC<IPosts> = ({ locked = false, posts }) => {
  const { id: userId } = useParams<IVerifParams>();
  const history = useHistory();
  const isImage = (post: any) => {
    return post.medias && post.medias.length > 0 && post.medias[0] && post.medias[0].mime.substring(0, 5) === 'image';
  };

  return (
    <IonRow className="ion-padding-top">
      <IonRow className={`image-container ${locked ? 'content-blur' : ''}`}>
        {!!posts.length &&
          posts.map((post: any, index) => (
            <Fragment key={index}>
              {post.medias.length > 0 && (
                <div
                  className="image-wrapper"
                  onClick={(e: any) => {
                    // const playButton = document.getElementById(`btn-video-${index}`);
                    // if (playButton && playButton.contains(e.target)) {
                    //   return;
                    // } else if (isImage(post) || !playButton || (playButton && !playButton.contains(e.target))) {
                    //   history.push(`/main/posts/${userId || getUserId()}/${post.id}`);
                    // }
                    history.push(`/main/posts/${userId || getUserId()}/${post.id}`);
                  }}
                >
                  {isImage(post) ? (
                    <img src={post.medias[0]?.url} alt={''} />
                  ) : (
                    <VideoView
                      url={post.medias[0]?.url || ''}
                      previewUrl={post.medias[0]?.previewUrl || ''}
                      autoPlay={false}
                      index={index}
                      hideIcon={true}
                    ></VideoView>
                  )}
                </div>
              )}
            </Fragment>
          ))}
      </IonRow>
      {locked && <ProfilLock />}
    </IonRow>
  );
};

export default Posts;
