import {
  IonButton,
  IonChip,
  IonCol,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { arrowForwardOutline, close, closeCircle } from 'ionicons/icons';
import { ComponentModal } from '../../../components/Modals';
import { Enum_Event_Type } from '../../../models/gql/graphql';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { GET_ME } from '../../../graphql/queries/user.graphql';
import FilterInterest from './FilterInterest';
import FilterDate from './FilterDate';
import FilterAdress from './FilterAdress';
import { formatMininalWithoutYear } from '../../../utils';
import { omit } from 'lodash';
import filterSvg from './../../../assets/icons/filter.svg';
import { addDays } from 'date-fns';

enum Enum_filter_type {
  interests = "Centre d'intérêts",
  dates = 'Dates',
  address = 'Distance',
}

type Type_filter = {
  name: Enum_filter_type;
  message?: string;
  clear?: (e: any) => void;
};
export type IFilterData = {
  interests?: string[];
};
interface IFilterModalProps {
  onFilterChanged: (e: IFilterData) => any;
  onPositionChanged: (e?: ILocation) => any;
  onRadiusChanged: (e: number) => any;
}

const FilterModal: React.FC<IFilterModalProps> = ({ onFilterChanged, onPositionChanged, onRadiusChanged }) => {
  const { data: dataMe, loading: loadingMe } = useQuery(GET_ME);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenModalContent, setIsOpenModalContent] = useState<boolean>(false);
  const [categorieContent, setCategorieContent] = useState<Enum_filter_type>();
  const [filterInterestId, setFilterInterestId] = useState([]);
  const [filterAddress, setFilterAddress] = useState<TypeAddressAround>();
  const [filterDate, setFilterDate] = useState([]);

  // init filter by user interests
  const filtersInitial = {
    interests: {
      id: {
        in: dataMe?.me?.interests?.map((int) => int?.id) as any,
      },
    },
    type: {
      eq: Enum_Event_Type.Public,
    },
  };

  if (dataMe?.me?.blockedUsers && dataMe?.me?.blockedUsers.length) {
    (omit(filtersInitial, ['interests']) as any).creator = {
      id: {
        notIn: dataMe?.me?.blockedUsers.map((i) => i?.id),
      },
    };
  }

  const filterCategories: Type_filter[] = [
    {
      name: Enum_filter_type.interests,
      message: filterInterestId.length > 0 ? `${filterInterestId.length} actifs` : '',
      clear: (e) => {
        e.stopPropagation();
        setFilterInterestId([]);
      },
    },
    {
      name: Enum_filter_type.dates,
      message:
        filterDate.length > 0
          ? `${formatMininalWithoutYear(new Date(filterDate[0]))} au ${formatMininalWithoutYear(
              addDays(new Date(filterDate[1]), -1)
            )}`
          : '',
      clear: (e) => {
        e.stopPropagation();
        setFilterDate([]);
      },
    },
    {
      name: Enum_filter_type.address,
      message: filterAddress ? `${filterAddress?.distance} Km autour de ${filterAddress?.addressCity}` : '',
      clear: (e) => {
        e.stopPropagation();
        setFilterAddress(undefined);
      },
    },
  ];

  const openFilter = (filter: Type_filter) => {
    setCategorieContent(filter.name);
    setIsOpenModalContent(true);
  };

  const filterDataInterest = (filterSelected: any) => {
    setFilterInterestId(filterSelected);
  };

  const filterDataAddress = (filterSelected: TypeAddressAround) => {
    setFilterAddress(filterSelected);
  };

  const filterDataDate = (filterSelected: any) => {
    setFilterDate(filterSelected);
  };
  const submitFilter = () => {
    const filterData: any = {};
    if (filterInterestId && filterInterestId.length) {
      filterData.interests = filterInterestId;
    }
    if (filterDate && filterDate.length) {
      filterData.start = filterDate.map((i) => new Date(i).getTime() / 1000);
    }
    if (filterAddress && filterAddress.position) {
      onPositionChanged({
        lat: filterAddress.position.latitude,
        lng: filterAddress.position.longitude,
      });
    } else {
      onPositionChanged(undefined);
    }
    if (filterAddress && filterAddress.distance) {
      onRadiusChanged(filterAddress.distance * 1000);
    }
    onFilterChanged(filterData);
    setIsOpenModal(false);
  };
  const yellowImg = {
    filter: 'invert(79%) sepia(12%) saturate(3580%) hue-rotate(0deg) brightness(105%) contrast(118%)',
  };
  return (
    <>
      <IonButton
        style={{
          '--padding-start': 0,
        }}
        fill="clear"
        size="small"
        onClick={() => setIsOpenModal(true)}
      >
        <IonIcon icon={filterSvg}></IonIcon>
      </IonButton>

      <ComponentModal
        height={
          categorieContent === Enum_filter_type.interests
            ? '550px'
            : categorieContent === Enum_filter_type.address
            ? '420px'
            : '380px'
        }
        onCancel={() => setIsOpenModal(false)}
        isOpen={isOpenModal}
      >
        <IonHeader className="ion-no-border padding-t-25" mode="ios">
          <IonToolbar mode="ios">
            <IonRow>
              <IonCol size="1">
                <IonIcon icon={close} onClick={() => setIsOpenModal(false)}></IonIcon>
              </IonCol>
              <IonCol>
                <IonTitle className="text-center">Filtrer par...</IonTitle>
              </IonCol>
            </IonRow>
          </IonToolbar>
        </IonHeader>
        <IonList lines="none">
          {filterCategories.map((categorie, index) => (
            <IonItem
              key={`filter-${index}`}
              button={false}
              style={index != filterCategories.length - 1 ? { borderBottom: '2px solid #D9D9D9' } : {}}
              onClick={() => openFilter(categorie)}
            >
              <IonLabel text-wrap>
                <IonRow>
                  <IonCol className="text-gray-400">
                    <span className="text-bold">{categorie.name}</span>
                  </IonCol>
                  {categorie.message !== '' && (
                    <IonCol>
                      <IonChip className={`chip-filter text-yellow`}>
                        <IonLabel>{categorie.message}</IonLabel>
                        <IonIcon icon={closeCircle} onClick={categorie.clear} style={yellowImg}></IonIcon>
                      </IonChip>
                    </IonCol>
                  )}
                  <IonCol className={`text-blue margin-r-5 text-size-16`} size="1">
                    <IonIcon icon={arrowForwardOutline}></IonIcon>
                  </IonCol>
                </IonRow>
              </IonLabel>
            </IonItem>
          ))}
          <IonButton
            expand="block"
            fill="solid"
            color="primary"
            className="d-absolute-bottom ion-padding-horizontal"
            onClick={submitFilter}
          >
            Appliquer
          </IonButton>
          <IonButton
            expand="block"
            fill="clear"
            className="d-absolute-bottom ion-padding-horizontal text-underligne text-black"
            onClick={() => setIsOpenModal(false)}
          >
            Annuler
          </IonButton>
        </IonList>
      </ComponentModal>
      <ComponentModal
        onCancel={() => setIsOpenModalContent(false)}
        isOpen={isOpenModalContent}
        height={
          categorieContent === Enum_filter_type.interests
            ? '550px'
            : categorieContent === Enum_filter_type.address
            ? '420px'
            : '380px'
        }
      >
        {categorieContent === Enum_filter_type.interests && (
          <FilterInterest
            onChange={(data) => filterDataInterest(data)}
            onCloseModal={(data) => setIsOpenModalContent(data)}
            filterInterestIds={filterInterestId}
          ></FilterInterest>
        )}
        {categorieContent === Enum_filter_type.dates && (
          <FilterDate
            onChange={(data) => filterDataDate(data)}
            onCloseModal={(data) => setIsOpenModalContent(data)}
          ></FilterDate>
        )}
        {categorieContent === Enum_filter_type.address && (
          <FilterAdress
            onChange={(data) => filterDataAddress(data)}
            onCloseModal={(data) => setIsOpenModalContent(data)}
          ></FilterAdress>
        )}
      </ComponentModal>
    </>
  );
};

export default FilterModal;
