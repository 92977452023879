import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import { Enum_Notification_Type, EventEntity } from '../../models/gql/graphql';
import { getEventDateData } from '../../pages/DispoDetail/utils';
import { useHistory } from 'react-router';
import './style.css';
interface IDispoProps {
  dispo?: EventEntity;
  withTag?: boolean;
  type: Enum_Notification_Type | null | undefined;
  userId?: number | string | null;
}

const CardDispoMinimal: React.FC<IDispoProps> = ({ dispo, withTag, type, userId }) => {
  const eventDateData = dispo ? getEventDateData(dispo) : null;
  const history = useHistory();
  const handleNotification = () => {
    if (
      type &&
      (type === Enum_Notification_Type.DispoAccepted || type === Enum_Notification_Type.DispoRefused) &&
      userId
    ) {
      history.push('/main/profil/' + userId);
    } else {
      history.push('/main/dispos/' + dispo?.id);
    }
    return;
  };
  return (
    <IonCard className="ion-no-margin ion-no-padding border-card-minimal" onClick={handleNotification}>
      {withTag && (
        <IonChip className="tag-position font-outfit" color="primary">
          {dispo?.attributes?.type}
        </IonChip>
      )}
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol className="d-flex-notification ion-no-padding border-right-image" size="4">
            <img
              alt={
                dispo?.attributes?.coverCustomImage?.data
                  ? dispo.attributes.coverCustomImage.data.attributes?.alternativeText!
                  : dispo?.attributes?.coverTedispoImage?.data
                  ? dispo.attributes.coverTedispoImage.data.attributes?.image?.data?.attributes?.alternativeText!
                  : 'avatar'
              }
              src={
                dispo?.attributes?.coverCustomImage?.data
                  ? dispo.attributes.coverCustomImage.data.attributes?.url
                  : dispo?.attributes?.coverTedispoImage?.data
                  ? dispo.attributes.coverTedispoImage.data.attributes?.image?.data?.attributes?.url
                  : 'https://ionicframework.com/docs/img/demos/avatar.svg'
              }
              className="fill-image"
            />
          </IonCol>
          <IonCol size="8" className="padding-card-minimal">
            <IonCardHeader className="ion-no-padding header-title-style">
              <IonCardTitle className="font-title-card-minimal font-outfit">{dispo?.attributes?.name}</IonCardTitle>
              <IonCardSubtitle className="font-card-minimal font-outfit font-bolder">Emplacement</IonCardSubtitle>
              <IonCardSubtitle className="font-card-minimal font-outfit margin-card-city-sm">
                {dispo?.attributes?.addressLine}
              </IonCardSubtitle>
              {/* <IonCardSubtitle className="font-card-minimal">
                Createur : {dispo?.attributes?.creator?.data?.attributes?.username}
              </IonCardSubtitle> */}
            </IonCardHeader>

            <IonCardContent className="ion-no-padding margin-top-minimal">
              <IonGrid className="ion-no-padding">
                <IonRow className="ion-justify-content-between ion-no-padding">
                  <IonCol size="auto" className="ion-no-padding">
                    <IonCardSubtitle className="font-card-minimal font-outfit font-bolder">Date</IonCardSubtitle>
                    <IonCardSubtitle className="font-card-minimal font-outfit">
                      {eventDateData && eventDateData.debut} {eventDateData && eventDateData.fin}
                    </IonCardSubtitle>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default CardDispoMinimal;
