import { useIonLoading, useIonModal, useIonToast } from '@ionic/react';
import { Geolocation } from '@capacitor/geolocation';
import React, { useEffect, useMemo, useState } from 'react';
import * as isEqual from 'instantsearch.js/es/lib/utils/isEqual';
import { useAlgolia } from '../../hooks';
import { useHistory } from 'react-router';
import { StorageUtils } from '../../utils';
import {
  EventWeatherFiltersInput,
  EventWeathersQuery,
  Exact,
  ForbiddenWordEntity,
  ForbiddenWordEntityResponse,
  InputMaybe,
  PaginationArg,
  UsersPermissionsMe,
} from '../../models/gql/graphql';
import { LazyQueryExecFunction, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_ME } from '../../graphql/queries/user.graphql';
import { GET_FORBIDDEN_WORDS } from '../../graphql/queries/forbidden-word.graphql';
import { DELETE_USER_FOLLOWER } from '../../graphql/mutations/updateUser..graphql';
import { GET_RELATION_USER_FOLLOWER } from '../../graphql/queries/userFollower.graphql';
import { GET_EVENTS_WEATHERS } from '../../graphql/queries/weather.graphql';
const HISTORY_LIMIT = 10;
export interface IRootStore {
  link: string;
  setLink: (link: string) => void;
  nextUrl: string;
  setNextUrl: (url: string) => void;
  restoredData: any;
  setRestoredData: (data: any) => void;
  setLoading: React.Dispatch<unknown>;
  loading: boolean;
  setError: React.Dispatch<unknown>;
  setSuccess: React.Dispatch<unknown>;
  setShouldRefreshNow: React.Dispatch<unknown>;
  shouldRefreshNow: boolean;
  getMyPosition: () => Promise<{ lat: number; lng: number }>;
  getAlgoliaClient: () => any;
  algoliaCache: any;
  navigate: (url: string) => void;
  url: string;
  HomeSearchAdd: (query: string) => any;
  HomeSearchList: any;
  userData: UsersPermissionsMe;
  forbiddenWords: ForbiddenWordEntityResponse;
  getUserData: (forceReload?: boolean) => Promise<UsersPermissionsMe>;
  refreshDispos: boolean;
  setRefreshDispos: (refresh: boolean) => void;
  unfollowUser: (userId: string, followerId: string) => Promise<any>;
  mainWeatherInfos: any;
  setMainWeatherInfos: (data: any) => void;
  weatherInfo: any;
  loadWeatherQuery: LazyQueryExecFunction<
    EventWeathersQuery,
    Exact<{
      filters?: InputMaybe<EventWeatherFiltersInput> | undefined;
      pagination?: InputMaybe<PaginationArg> | undefined;
    }>
  >;
}

const useRootStore = () => {
  const { init } = useAlgolia();
  const [present, dismiss] = useIonLoading();
  const { data: forbiddenWords } = useQuery(GET_FORBIDDEN_WORDS);
  const [presentToast, dismissToast] = useIonToast();
  const [url, navigate] = useState<string>();
  const [shouldRefreshNow, setShouldRefreshNow] = useState<boolean>();
  const [mainWeatherInfos, setMainWeatherInfos] = useState<any[]>([]);
  const [userData, setUserData] = useState<UsersPermissionsMe>(() => {
    const ud = StorageUtils.get('userData');
    if (!ud) {
      return;
    }
    return JSON.parse(ud);
  });
  const [deleteFollowerUser] = useMutation(DELETE_USER_FOLLOWER);
  const [getMyFollowing] = useLazyQuery(GET_RELATION_USER_FOLLOWER);
  const [link, setLink] = React.useState<string>('/');
  const [restoredData, setRestoredData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [refreshDispos, setRefreshDispos] = React.useState<boolean>(false);
  const history = useHistory();
  const [getMe] = useLazyQuery(GET_ME);

  useEffect(() => {
    if (userData) {
      StorageUtils.set('userData', userData);
    }
  }, [userData]);

  const initialSearchHistoryValue = () => {
    const vals = StorageUtils.get('searchValues');
    if (!vals) return [];
    return JSON.parse(vals);
  };
  const [HomeSearchList, setHomeSearchList] = useState(initialSearchHistoryValue);
  useEffect(() => {
    if (loading) {
      present();
    } else {
      dismiss();
    }
  }, [loading, dismiss, present]);

  const [error, setError] = React.useState<string | null>(null);

  useEffect(() => {
    if (error) {
      presentToast({
        duration: 10000,
        message: error,
      });
      setTimeout(() => {
        setError(null);
      }, 3000);
    } else {
      dismissToast();
    }
  }, [error, dismissToast, presentToast]);
  const [nextUrl, setNextUrl] = React.useState<string>('/');

  const [success, setSuccess] = React.useState<string | null>(null);
  useEffect(() => {
    if (success) {
      presentToast({
        duration: 10000,
        message: success,
      });
      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    } else {
      dismissToast();
    }
  }, [success, dismissToast, presentToast]);

  const getMyPosition = async () => {
    try {
      const { coords } = await Geolocation.getCurrentPosition({
        enableHighAccuracy: false,
        timeout: 10000,
      });
      if (!coords) {
        return null;
      }
      return { lat: coords.latitude, lng: coords.longitude };
    } catch (err) {
      console.log('unable to get position', err);
      throw err;
    }
  };

  const getAlgoliaClient = () => {
    return init;
  };

  function getStateWithoutPage(state: any) {
    const { page, ...rest } = state || {};
    return rest;
  }

  function getInMemoryCache() {
    let cachedHits: any = null;
    let cachedState: any = null;
    return {
      read({ state }: any) {
        return isEqual.isEqual(cachedState, getStateWithoutPage(state)) ? cachedHits : null;
      },
      write({ state, hits }: any) {
        cachedState = getStateWithoutPage(state);
        cachedHits = hits;
      },
      invalidate() {
        cachedHits = null;
        cachedState = null;
      },
    };
  }
  useEffect(() => {
    if (url && history) history.push(url);
  }, [url, history]);

  const algoliaCache = getInMemoryCache();

  const HomeSearchAdd = (query: string) => {
    setHomeSearchList(((prevItems: any) => {
      const queryIndexed = prevItems.indexOf(query);
      if (queryIndexed > -1) {
        // move to first array
        const items = prevItems.splice(queryIndexed, 1);
        prevItems.splice(0, 0, ...items);
        StorageUtils.set('searchValues', JSON.stringify(prevItems));
        return prevItems;
      }
      const newItems = [query, ...prevItems].slice(0, HISTORY_LIMIT);
      StorageUtils.set('searchValues', JSON.stringify(newItems));
      return newItems;
    }) as any);
  };
  const getUserData = async (forceReload?: boolean) => {
    return new Promise(async (resolve, reject) => {
      if (userData && !forceReload) resolve(userData);
      const { data } = await getMe();
      setUserData(data?.me as UsersPermissionsMe);
      resolve(data?.me as UsersPermissionsMe);
    });
  };
  const unfollowUser = async (userId: any, followerId: any) => {
    const { data } = await getMyFollowing({
      variables: {
        filters: {
          user: {
            id: { eq: userId },
          },
          follower: {
            id: { eq: followerId },
          },
        },
        pagination: { limit: 1 },
      },
      fetchPolicy: 'network-only',
    });
    console.log('aziaoziamzia', data);

    if (data?.userFollowers?.data?.length) {
      for (let item of data?.userFollowers?.data) {
        await deleteFollowerUser({
          variables: {
            id: item?.id as string,
          },
        });
      }
    }
  };
  // weather
  const weatherInfo: any = useMemo(() => {
    const c: any = {};
    for (let w of mainWeatherInfos) {
      if (w?.attributes?.dispo_id) {
        c[w?.attributes?.dispo_id?.toString()] = w.attributes?.weatherCondition;
      }
    }
    return c;
  }, [mainWeatherInfos]);

  const [loadWeatherQuery, { data: eventWeathers }] = useLazyQuery(GET_EVENTS_WEATHERS);
  useEffect(() => {
    if (eventWeathers?.eventWeathers?.data) {
      setMainWeatherInfos((i: any) => [...i, ...eventWeathers?.eventWeathers?.data!]);
    }
  }, [eventWeathers]);

  return {
    link,
    setLink,
    nextUrl,
    setNextUrl,
    restoredData,
    setRestoredData,
    setLoading,
    loading,
    setError,
    setSuccess,
    getMyPosition,
    getAlgoliaClient,
    algoliaCache,
    navigate,
    url,
    HomeSearchAdd,
    HomeSearchList,
    getUserData,
    userData,
    forbiddenWords,
    refreshDispos,
    setRefreshDispos,
    unfollowUser,
    mainWeatherInfos,
    setMainWeatherInfos,
    weatherInfo,
    loadWeatherQuery,
    shouldRefreshNow,
    setShouldRefreshNow,
  } as IRootStore;
};

export default useRootStore;
