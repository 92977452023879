import { IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';
import 'react-phone-number-input/style.css';
import { useState, useMemo, useEffect } from 'react';
import './style.css';

type Type_segment = 'full' | 'out' | 'ios';
interface ISegmentCustom {
  defaultValue: string;
  values: IdNameEntity[];
  typeSegment?: Type_segment;
  onChange: (value: IdNameEntity) => void;
  buttonStyle?: string;
}

const SegmentCustom: React.FC<ISegmentCustom> = ({
  values,
  onChange,
  typeSegment = 'full',
  defaultValue,
  buttonStyle,
}) => {
  const [value, setValue] = useState<string>(defaultValue);

  const handleCLick = (valueClicked: IdNameEntity) => {
    setValue(valueClicked.id);
    onChange(valueClicked);
  };
  const containerStyle = useMemo(() => {
    let classToUse = '';
    if (typeSegment === 'full') {
      classToUse = 'grid-container';
    }
    if (typeSegment === 'ios') {
      classToUse = 'grid-container-ios';
    }
    return classToUse;
  }, [typeSegment]);

  useEffect(() => {
    if (defaultValue) {
      onChange({
        id: defaultValue,
        name: defaultValue,
      });
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <IonGrid className={`ion-no-padding ${containerStyle}`}>
      <IonRow className="ion-no-padding">
        {values.map((val, index) => (
          <IonCol className="ion-no-padding" key={index}>
            <IonButton
              className={`font-outfit ion-no-margin ${buttonStyle || ''} ${typeSegment + '-width-button'}  ${
                val.id === value ? typeSegment + '-line-selected' : ''
              }`}
              size="small"
              fill="clear"
              onClick={() => handleCLick(val)}
            >
              {val.name}
            </IonButton>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default SegmentCustom;
