import { useQuery } from '@apollo/client';
import { IonContent, IonImg, IonLoading, IonPage, IonRow, IonText } from '@ionic/react';
import { useMemo, useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ButtonGroup, CardCustom, Loader, Header } from '../../components';
import { GET_ONBOARDINGS } from '../../graphql/queries/onBoarding.graphql';

import '@ionic/react/css/ionic-swiper.css';
import { useHistory } from 'react-router';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './style.css';

const OnBoarding: React.FC = () => {
  const { data, loading } = useQuery(GET_ONBOARDINGS);
  const [swp, setSwiper] = useState<any>();
  const [showBtnContinue, setshowBtnContinue] = useState<boolean>(false);
  const history = useHistory();

  const initSwiper = (swiper: any) => {
    setSwiper(swiper);
  };
  const goNext = () => {
    swp.slideNext();
  };

  const goPrev = () => {
    swp.slidePrev();
  };
  const items = useMemo(() => {
    return !loading ? data?.onBoarding?.data?.attributes?.items : [];
  }, [loading, data]);

  const handleChangeSlide = (swiper: any) => {
    setshowBtnContinue(items?.length === swiper.activeIndex + 1);
  };
  const goToHome = () => {
    history.replace('main/home');
  };

  const goTo = (path: string) => {
    history.push(path);
  };

  return (
    <IonPage className="page-blue">
      {loading && <Loader />}
      <IonContent scrollY={false}>
        <div className="boarding-container">
          <div className="bording-content">
            <CardCustom padding={false} margin={true}>
              <Swiper
                onSwiper={initSwiper}
                modules={[Pagination]}
                onSlideChange={handleChangeSlide}
                pagination={{
                  clickable: true,
                  type: 'bullets',
                  renderBullet(index, className) {
                    return `<span class="${className} pagination-custom"></span>`;
                  },
                }}
              >
                {items &&
                  items.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                      <div>
                        <IonText color="dark" className="font-outfit ion-padding">
                          <h2 className="title-onboarding">{item.title}</h2>
                          <p className="description-onboarding">{item.description}</p>
                        </IonText>
                        <IonImg
                          className="image-onboarding"
                          src={item?.media?.data ? item?.media?.data?.attributes?.url : 'images/picture.svg'}
                          alt="image pdp"
                        ></IonImg>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
              <ButtonGroup
                className="ion-margin"
                textTop="Inscription"
                textBottom="Connexion"
                fillTop="solid"
                fillBottom="clear"
                colorTop="primary"
                colorBottom="dark"
                onClickTop={() => goTo('/signup/choose')}
                onClickBottom={() => goTo('/login/send-phone')}
                classNameBottom="btn-link"
              />
            </CardCustom>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default OnBoarding;
