import { graphql } from '../../models/gql';
export const GET_POSTS = graphql(/* GraphQL */ `
  query getPosts($filters: PostFiltersInput, $sort: [String], $pagination: PaginationArg) {
    posts(filters: $filters, sort: $sort, pagination: $pagination) {
      data {
        id
        attributes {
          medias {
            data {
              attributes {
                url
                name
                url
                mime
                hash
                size
                provider
                previewUrl
              }
              id
            }
          }
          reactions {
            data {
              id
            }
          }
          comments {
            data {
              id
              attributes {
                deleted
                subcomments {
                  data {
                    id
                    attributes {
                      deleted
                    }
                  }
                }
              }
            }
          }
          description
          author {
            data {
              id
              attributes {
                username
                isPro
                companyName
                firstname
                lastname
                profilePicture {
                  data {
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          attributes {
                            name
                            url
                            mime
                            hash
                            size
                            provider
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const GET_POST = graphql(/* GraphQL */ `
  query getPost($id: ID!) {
    post(id: $id) {
      data {
        id
        attributes {
          medias {
            data {
              attributes {
                url
                name
                url
                mime
                hash
                size
                provider
                previewUrl
              }
              id
            }
          }
          reactions(pagination: { limit: 250 }) {
            data {
              id
            }
          }
          comments(pagination: { limit: 250 }) {
            data {
              id
              attributes {
                deleted
                subcomments(pagination: { limit: 250 }) {
                  data {
                    id
                    attributes {
                      deleted
                    }
                  }
                }
              }
            }
          }
          description
          author {
            data {
              id
              attributes {
                username
                isPro
                companyName
                firstname
                lastname
                profilePicture {
                  data {
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          attributes {
                            name
                            url
                            mime
                            hash
                            size
                            provider
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);
