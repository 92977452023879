import { IonText } from '@ionic/react';
import { FC, useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.css';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
var Clipboard = Quill.import('modules/clipboard');

interface IProps {
  handleChange: (e: any) => void;
  value: any;
  label?: string;
  placeholder?: string;
  description?: string;
  onPaste?: (e: any) => void;
}
const RichEditor: FC<IProps> = ({
  handleChange,
  value,
  label,
  placeholder = 'Renseignez le titre',
  description,
  onPaste,
}) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (handleChange) {
      handleChange(value);
    }
  }, [value]);

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.setResizeMode({ mode: KeyboardResize.Ionic });
    }
  }, []);

  return (
    <div
      ref={editorRef}
      className="ion-margin-bottom"
      onBlur={(e: any) => {
        if (onPaste) onPaste(e);
      }}
      onPaste={(e: any) => {
        if (onPaste) onPaste(e);
      }}
    >
      <IonText color="dark">
        <p className="custom-label font-outfit">{label}</p>
        {description && <p className="font-outfit description-message">{description}</p>}
      </IonText>
      <ReactQuill modules={{}} theme="snow" value={value} onChange={handleChange} placeholder={placeholder} />
    </div>
  );
};

export default RichEditor;
