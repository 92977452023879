import { FC, useEffect, useRef, useState } from 'react';
import {
  createGesture,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
} from '@ionic/react';
import './style.css';
import { Keyboard } from '@capacitor/keyboard';
import isSameDay from 'date-fns/fp/isSameDay/index';

type formatType = 'date' | 'date-time' | 'month' | 'month-year' | 'time' | 'time-date' | 'year';
interface IProps {
  handleChangeStart: (e: any) => void;
  handleChangeEnd: (e: any) => void;
  valueStart?: any;
  valueEnd?: any;
  labelStart?: string;
  min?: any;
  labelEnd?: string;
  idStart?: string;
  idEnd?: string;
  typeFirst?: formatType;
  typeSecond?: formatType;
  isErrorStart?: any;
  isErrorEnd?: any;
  iconFirst?: any;
  iconSecond?: any;
  label?: string;
  useOne?: boolean;
  minutesValues?: Array<number>;
  yearsValues?: Array<number>;
  oneDay?: boolean;
}
const DoubleTimePicker: FC<IProps> = ({
  handleChangeStart,
  handleChangeEnd,
  valueStart,
  valueEnd,
  labelStart,
  labelEnd,
  idStart,
  idEnd,
  typeFirst,
  typeSecond,
  isErrorStart,
  isErrorEnd,
  iconFirst,
  iconSecond,
  label,
  useOne = false,
  minutesValues,
  yearsValues,
  min,
  oneDay,
}) => {
  const [timeStart, setTimeStart] = useState<any>(valueStart);
  const [timeEnd, setTimeEnd] = useState<any>(valueEnd);
  const dateTime1 = useRef<HTMLIonDatetimeElement | null>(null);
  const dateTime2 = useRef<HTMLIonDatetimeElement | null>(null);
  const startModal = useRef<HTMLIonModalElement | null>(null);
  const endModal = useRef<HTMLIonModalElement | null>(null);
  const maxCurrentDate = new Date();
  maxCurrentDate.setUTCHours(22, 0, 0, 0);

  const initDefaultValue = async (valueStart: string, valueEnd: string) => {
    await handleChangeEnd(valueEnd);
    await handleChangeStart(valueStart);
  };
  useEffect(() => {
    initDefaultValue(valueStart, valueEnd);
  }, [valueStart, valueEnd]);

  useEffect(() => {
    setTimeEnd(valueEnd);
    setTimeStart(valueStart);
  }, [valueStart, valueEnd]);

  const handleInputChange = (event: CustomEvent<any>) => {
    event.preventDefault();
    const selectedValue = event.detail && event.detail.value;
    if (selectedValue) {
      setTimeStart(selectedValue);
      handleChangeStart(selectedValue);
    }
  };
  const handleInputChangeEnd = (event: CustomEvent<any>) => {
    event.preventDefault();
    const selectedValue = event.detail && event.detail.value;
    if (selectedValue) {
      setTimeEnd(selectedValue);
      handleChangeEnd(selectedValue);
    }
  };
  useEffect(() => {
    if (dateTime1.current) {
      const gesture = createGesture({
        el: dateTime1.current,
        threshold: 0,
        onStart: () => {
          Keyboard.hide();
        },
        gestureName: 'double-click',
      });
      gesture.enable();
    }
    if (dateTime2.current) {
      const gesture = createGesture({
        el: dateTime2.current,
        threshold: 0,
        onStart: () => {
          Keyboard.hide();
        },
        gestureName: 'double-click',
      });
      gesture.enable();
    }
  });

  const openModal = (modalType?: string) => {
    const modalRef = modalType === 'start' ? startModal : endModal;
    if (modalRef.current) {
      modalRef.current.present();
    }
  };
  return (
    <IonGrid className="ion-no-padding">
      <p className="custom-label main-label font-outfit">{label}</p>
      <IonRow className="ion-no-padding">
        <IonCol>
          <p className="custom-label font-outfit second-label">{labelStart}</p>
          <div className="position-parent-relative">
            <IonDatetimeButton
              className={`ion-justify-content-start date-time-button-custom border-radius-left ${
                !useOne ? 'useOne' : ''
              }`}
              datetime={idStart}
            ></IonDatetimeButton>
            {idStart !== 'start1' && (
              <IonIcon
                className="margin-auto-container icon-date-size position-icon-first"
                icon={iconFirst}
                onClick={() => openModal('start')}
              />
            )}
            <IonModal keepContentsMounted={true} ref={startModal}>
              <IonDatetime
                locale={'fr-FR'}
                id={idStart}
                min={min ? min : undefined}
                presentation={typeFirst}
                value={timeStart || undefined}
                max={oneDay ? maxCurrentDate.toJSON() : undefined}
                onIonChange={(e) => handleInputChange(e)}
                minuteValues={typeFirst === 'time' ? minutesValues : undefined}
                yearValues={typeFirst === 'date' ? yearsValues : undefined}
                ref={dateTime1}
              ></IonDatetime>
            </IonModal>
          </div>
          <p className="errorDescription">{!!isErrorStart && isErrorStart.message}</p>
        </IonCol>
        {!useOne && (
          <IonCol>
            <p className="custom-label font-outfit second-label">{labelEnd}</p>
            <div className="position-parent-relative">
              <IonDatetimeButton
                className="ion-justify-content-start date-time-button-custom border-radius-right"
                datetime={idEnd}
              ></IonDatetimeButton>
              <IonIcon
                className="margin-auto-container icon-date-size position-icon"
                icon={iconSecond}
                onClick={() => openModal('end')}
              />
              <IonModal keepContentsMounted={true} ref={endModal}>
                <IonDatetime
                  id={idEnd}
                  locale={'fr-FR'}
                  min={oneDay ? timeStart : undefined}
                  presentation={typeSecond}
                  value={timeEnd || undefined}
                  onIonChange={(e) => handleInputChangeEnd(e)}
                  minuteValues={typeSecond === 'time' ? minutesValues : undefined}
                  yearValues={typeSecond === 'date' ? yearsValues : undefined}
                  ref={dateTime2}
                ></IonDatetime>
              </IonModal>
            </div>
            <p className="errorDescription position-description">{!!isErrorEnd && isErrorEnd.message}</p>
          </IonCol>
        )}
      </IonRow>
    </IonGrid>
  );
};

export default DoubleTimePicker;
