import { useQuery } from '@apollo/client';
import { IonPage } from '@ionic/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Loader } from '../../../../components';
import { GET_ME } from '../../../../graphql/queries/user.graphql';
import { useDispo } from '../../../../hooks';

const VerifyConfig: React.FC = () => {
  const { data: dataMe, loading } = useQuery(GET_ME, { fetchPolicy: 'network-only' });
  const history = useHistory();
  const { dispo } = useDispo();

  useEffect(() => {
    if (dataMe && !loading) {
      if (dataMe?.me?.isPro) {
        // pro
        if (!dataMe?.me?.publishedAt) {
          history.push('/signup/confirmation-pro');
        } else {
          if (
            (window.location &&
              (window.location.pathname === '/main/my-account/menu' ||
                window.location.pathname === '/main/my-account/infos' ||
                window.location.pathname.includes('/main/profil') ||
                window.location.pathname === '/main/my-account/menu/contact')) ||
            window.location.pathname.includes('/main/dispos') ||
            window.location.pathname.includes('/main/post') ||
            window.location.pathname.includes('/main/profil') ||
            window.location.pathname.includes('/main/notifications/mine') ||
            window.location.pathname.includes('/main/my-account/menu/infos') ||
            window.location.pathname.includes('/main/chat') ||
            window.location.pathname.includes('/main/my-account/menu/interest')
          ) {
            return;
          }
          // here should refetch notifications
          const event = new CustomEvent('reloadTedispotNotifications', { detail: '' });
          document.dispatchEvent(event);
          history.push('/main/home');
          console.log('redirect home');
        }
      } else {
        // particulier
        if (!dataMe?.me?.username || dataMe?.me?.username === dataMe?.me?.phone) {
          history.push('/signup/config');
          return;
        }
        if (!dataMe?.me?.interests || dataMe?.me?.interests?.length === 0) {
          history.push('/signup/config-interest');
          return;
        }
        if (!dataMe?.me?.profilePicture && !dataMe?.me?.avatar) {
          history.push('/signup/config-pdp');
          return;
        }

        if (
          (window.location &&
            (window.location.pathname === '/main/my-account/menu' ||
              window.location.pathname === '/main/my-account/infos' ||
              window.location.pathname.includes('/main/profil') ||
              window.location.pathname === '/main/my-account/menu/contact' ||
              window.location.pathname.includes('/main/dispos') ||
              window.location.pathname.includes('/main/post') ||
              window.location.pathname.includes('/main/profil') ||
              window.location.pathname.includes('/main/notifications/mine'))) ||
          window.location.pathname.includes('/main/my-account/menu/infos') ||
          window.location.pathname.includes('/main/chat') ||
          window.location.pathname.includes('/main/my-account/menu/interest')
        ) {
          return;
        }
        if (dispo && dispo.name) {
          // #TODO
        }
        // here should refetch notifications
        const event = new CustomEvent('reloadTedispotNotifications', { detail: '' });
        document.dispatchEvent(event);
        history.push('/main/home');
      }
    }
  }, [loading, dataMe]);

  return <IonPage>{loading && <Loader />}</IonPage>;
};

export default VerifyConfig;
