import { useEffect, useState } from 'react';

export interface INotificationStore {
  numberMsg: number;
  setCount: (item: any) => void;
}

/**
 * Messenger hooks handler
 * @param auth            Authentication data
 * @returns
 */
const useNotificationStore = () => {
  // Message array container
  const [count, setCount] = useState<number>(0);
  const [numberMsg, setNmberMsg] = useState<number>(0);
  useEffect(() => {
    setNmberMsg(count);
  }, [count, setNmberMsg]);
  return {
    setCount,
    numberMsg,
  } as INotificationStore;
};

export default useNotificationStore;
