import { useMutation } from '@apollo/client';
import {
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonToast,
} from '@ionic/react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { AvatarUser, ButtonCreate, AppSearchFilter } from '../../../components';
import SimpleHeader from '../../../components/SimpleHeader';
import { INVITE_USER } from '../../../graphql/mutations/updateEventUserParticipation.graphql';
import { GetAllParticipations } from '../../../hooks/useDispoDetail';
import { useSubscriptions } from '../../../hooks/useUserFollower';
import { UsersPermissionsUserEntity, UserFollowerEntity } from '../../../models/gql/graphql';
import { getUserId } from '../../../utils';
import { __ } from '../../../utils/traduction';
import './style.css';

const NewInvitations: React.FC = () => {
  const { id: idDispo } = useParams<IVerifParams>();
  const { participations, loading } = GetAllParticipations(idDispo);
  const [userSelected, setUserSelected] = useState<string[]>([]);
  const userId = getUserId();
  const [Invite, { loading: loadingInvite }] = useMutation(INVITE_USER);
  const history = useHistory();
  const [messageError, setMessageError] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [notInvited, setNotInvited] = useState<Array<UserFollowerEntity>>([]);
  const [usersFiltered, setUsersFiltered] = useState<Array<UserFollowerEntity>>([]);

  const userIdsParticipations = useMemo(() => {
    let userIds: string[] = [];
    if (participations && participations.length) {
      participations.forEach((participation) => {
        userIds.push(participation.attributes?.user?.data?.id || '0');
      });
    }
    return userIds;
  }, [participations]);

  const { mySubscription } = useSubscriptions(userId || '0');

  // const notInvited = mySubscription.filter((subscription) => {
  //   const userId = subscription.attributes?.user?.data?.id;
  //   return userId !== undefined && !userIdsParticipations.includes(userId || '0');
  // });

  useEffect(() => {
    const initFilter = mySubscription.filter((subscription) => {
      const userId = subscription.attributes?.user?.data?.id;
      return userId !== undefined && !userIdsParticipations.includes(userId || '0');
    });
    setNotInvited(initFilter);
    setUsersFiltered(initFilter);
    console.log({ initFilter });
  }, [mySubscription]);

  const invite = async () => {
    try {
      await Invite({
        variables: {
          input: {
            id: idDispo,
            users: userSelected,
          },
        },
      });

      history.push(`/main/dispos/${idDispo}/edit`);
    } catch (err: any) {
      setIsOpen(true);
      setMessageError(err.message);
    }
  };
  const onDone = (dataFiltered: UserFollowerEntity[]) => {
    setUsersFiltered(dataFiltered);
  };

  return (
    <IonPage>
      <SimpleHeader
        title="Ajouter des participants"
        noButtonBack={false}
        showMenuButton={false}
        backButtonColor="text-black"
      ></SimpleHeader>
      <IonContent className="ion-padding font-outfit">
        <IonGrid className="ion-justify-content-center ion-align-items-center width-p-95 bg-white border-radius">
          <IonRow>
            <IonCol size="12">
              <AppSearchFilter
                placeholder="Recherche"
                parentFilterKey="attributes"
                filterKey="username"
                items={notInvited as any}
                onChange={(e) => console.log(e.detail.value)}
                onDone={onDone}
                isLongSearch={true}
                useOnInput={true}
                isUser={true}
              ></AppSearchFilter>
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding">
            <IonCol className="text-bold" size="11">
              Invités
            </IonCol>
            <IonCol className="text-bold text-size-xl" size="1">
              {userSelected.length}
            </IonCol>
          </IonRow>
          <IonRow>
            {!!usersFiltered.length &&
              usersFiltered.map((subscription, index) => {
                return (
                  <SelectUser
                    user={subscription.attributes?.user?.data}
                    userSelected={userSelected}
                    setUserSelected={setUserSelected}
                    key={index}
                  />
                );
              })}
          </IonRow>
        </IonGrid>
        <IonToast
          isOpen={isOpen}
          message={__(messageError)}
          onDidDismiss={() => setIsOpen(false)}
          duration={5000}
          color="danger"
        ></IonToast>
      </IonContent>
      <IonFooter className="ion-no-border ion-padding">
        <ButtonCreate action={() => invite()} text="Confirmer" disabled={loadingInvite || userSelected.length === 0} />
      </IonFooter>
    </IonPage>
  );
};

export default NewInvitations;

interface ISelectUser {
  user?: Maybe<UsersPermissionsUserEntity>;
  setUserSelected: (value: any) => void;
  userSelected: string[];
}
const SelectUser: React.FC<ISelectUser> = ({ user, setUserSelected, userSelected }) => {
  const history = useHistory();
  const goTo = (path: string) => {
    history.push(path);
  };

  const addUserId = (checked: boolean, userId?: string) => {
    if (checked && userId) {
      setUserSelected([...userSelected, userId]);
    } else if (!checked && userId) {
      setUserSelected(userSelected.filter((id) => id !== userId));
    }
  };
  return (
    <IonList lines="none" className="ion-no-padding full">
      <IonItem>
        <IonGrid className="ion-no-margin ion-no-padding">
          <IonRow className={`ion-align-items-center font-outfit`}>
            <IonCol size="auto" onClick={() => goTo(`/main/profil/${user?.id}`)}>
              <AvatarUser user={user?.attributes} size="size-md" classCustom="width-30 height-30"></AvatarUser>
            </IonCol>
            <IonCol
              onClick={() => goTo(`/main/profil/${user?.id}`)}
              style={{ paddingLeft: '10px' }}
              className="ion-text-capitalize"
            >
              <span className="text-gray-400 text-size-lg padding-2">{user?.attributes?.username}</span>
            </IonCol>
            <IonCol size="1.5" className="margin-r-5">
              <IonCheckbox
                className="custom-checkbox"
                mode="ios"
                onIonChange={(e) => addUserId(e.detail.checked, user?.id || '0')}
              ></IonCheckbox>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    </IonList>
  );
};
