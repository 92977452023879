import { createContext } from 'react';
import { stateType, actionType } from './type';
import { initialstate } from './state';

type Dispatch = ({ type, payload }: actionType) => void;

export const AppReducerContext = createContext<{ state: stateType; dispatch: Dispatch }>({
  state: initialstate,
  dispatch: () => null,
});
