import { useMutation, useQuery } from '@apollo/client';
import { ContactPayload, Contacts, PhonePayload } from '@capacitor-community/contacts';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FOLLOW_USER } from '../graphql/mutations/user-follow.graphql';
import { GET_USERS } from '../graphql/queries/getUsers.graphql';
import { GET_RELATION_USER_FOLLOWER } from '../graphql/queries/userFollower.graphql';
import { Enum_Userfollower_Status, UsersPermissionsUserEntity } from '../models/gql/graphql';
import { useAppStore } from '../store';
import { getUserData, getUserId } from '../utils';
import { extractPhoneNumberAndCode, addCodeCountry, checkingIfNumIsSimilar } from '../utils';

export const useUserContact = () => {
  const {
    RootStore: { setError, userData },
  } = useAppStore();
  const [listContact, setListContact] = useState<string[]>([]);
  const [contactsName, setContactsName] = useState([]);
  const [contactsNotInscrit, setContactsNotInscrit] = useState({});
  const [FollowUser, { loading: loadingFollow }] = useMutation(FOLLOW_USER);
  const [loading, setLoading] = useState<boolean>(false);
  const [contactsImage, setContactsImage] = useState([]);
  const [contactsImageNotInscrit, setContactsImageNotInscrit] = useState({});

  const { data, loading: loadingUser } = useQuery(GET_USERS, {
    variables: {
      filters: {
        phone: {
          in: listContact,
        },
      },
      pagination: { limit: 100 },
    },
  });

  let filters = {
    and: [
      {
        follower: {
          id: {
            eq: getUserId() || '0',
          },
        },
      },
      {
        or: [
          {
            status: {
              eq: Enum_Userfollower_Status.Accepted,
            },
          },
          {
            status: {
              eq: Enum_Userfollower_Status.Asked,
            },
          },
          {
            status: {
              eq: Enum_Userfollower_Status.Blocked,
            },
          },
        ],
      },
    ],
  };

  const {
    data: dataUF,
    loading: loadingUF,
    refetch,
  } = useQuery(GET_RELATION_USER_FOLLOWER, {
    variables: {
      filters: filters,
      pagination: { limit: 500 },
    },
  });

  const mySubscriptionData = useMemo(() => {
    let notblockeds: ISubcribtion[] = [];
    let blockeds: any[] = [];
    if (dataUF && !loadingUF && dataUF.userFollowers?.data.length) {
      dataUF.userFollowers?.data.forEach((data) => {
        if (data.attributes?.status !== 'blocked') {
          notblockeds.push({
            idUser: data.attributes?.user?.data?.id,
            status: data.attributes?.status,
          });
        } else {
          if (!blockeds.includes(data.attributes?.user?.data?.id)) {
            blockeds.push(data.attributes?.user?.data?.id);
          }
          if (!blockeds.includes(data.attributes?.follower?.data?.id)) {
            blockeds.push(data.attributes?.follower?.data?.id);
          }
        }
      });
    }
    return {
      blockeds: blockeds,
      notblockeds: notblockeds,
    };
  }, [dataUF, loading]);

  const addContact = (phoneNumber: string, name: string) => {
    setContactsName((prevContacts) => {
      return { ...prevContacts, [phoneNumber]: name || phoneNumber };
    });
  };
  const addContactImage = (phoneNumber: string, base64String: string) => {
    setContactsImage((prevContacts) => {
      return { ...prevContacts, [phoneNumber]: base64String };
    });
  };

  const adaptPhoneNumber = (phoneNumber: string) => {
    const replacements = { '0033': '+33', '06': '+336', '01': '+3301', '03': '+2613' };
    for (const prefix in replacements) {
      if (phoneNumber.startsWith(prefix)) {
        return phoneNumber.replace(new RegExp(`^${prefix}`), (replacements as any)[prefix]);
      }
    }
    return phoneNumber;
  };

  const getContact = async () => {
    let contacts;
    try {
      const data = await Contacts.getContacts({
        projection: {
          name: true,
          phones: true,
          image: true,
        },
      });
      contacts = data.contacts;
      console.log(JSON.stringify(contacts));
    } catch (e) {
      console.log('impossible to get contact');
      if (localStorage.getItem('debug_contacts')) {
        contacts = JSON.parse(localStorage.getItem('debug_contacts')!);
        console.log(contacts);
      } else {
        contacts = [];
      }
    }

    console.log(JSON.stringify(contacts));
    let phoneNumbers: string[] = [];
    if (contacts.length) {
      contacts.forEach((contact: ContactPayload) => {
        if (contact.phones?.length) {
          contact.phones.forEach((phone: PhonePayload) => {
            if (phone.number) {
              // let phoneNumber = adaptPhoneNumber((phone.number as string).replace(/ /g, ''));
              phoneNumbers.push(...addCodeCountry(phone.number.replace(/ /g, '')));
              addContact(phone.number.replace(/ /g, ''), contact.name?.display || '');
              addContactImage(phone.number.replace(/ /g, ''), contact.image?.base64String || '');
            }
          });
        }
      });
    }
    return phoneNumbers;
  };

  const syncContact = async () => {
    setLoading(true);
    try {
      const contacts = await getContact();
      setListContact(contacts || []);
    } catch (e) {
      console.log('no contact');
    }
  };

  const userContacts = useMemo<UsersPermissionsUserEntity[]>(() => {
    return (
      !loadingUser && data
        ? data.usersPermissionsUsers?.data?.filter((i) => i.attributes?.phone !== userData?.phone)
        : []
    ) as UsersPermissionsUserEntity[];
  }, [data, loadingUser, userData]);

  const follow = async (idsUser?: string[]) => {
    let allIds: string[] = [];
    if (!idsUser && userContacts.length) {
      userContacts.forEach((user) => {
        allIds.push(user.id || '0');
      });
    }
    let ud = userData;
    if (!ud) {
      ud = getUserData();
    }
    const ids = idsUser || allIds || ['0'];
    if (ids.includes(ud?.id)) {
      setError('Vous ne pouvez pas vous suivre');
      return;
    }
    try {
      await FollowUser({
        variables: {
          ids,
        },
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  const formatContactList = () => {
    let contactsNameTmp = cloneDeep(contactsName);
    let contactsImgTmp = cloneDeep(contactsImage);
    console.log('----------', userData);
    if (userData && userData?.phone && checkingIfNumIsSimilar(contactsNameTmp, userData?.phone) === 'deleteDirect') {
      // remove current user
      delete (contactsNameTmp as any)[userData?.phone as any];
      delete (contactsImgTmp as any)[userData?.phone as any];
    }
    if (userContacts.length) {
      userContacts.forEach((userContact) => {
        if (checkingIfNumIsSimilar(contactsNameTmp, userContact.attributes?.phone) === 'deleteDirect') {
          delete (contactsNameTmp as any)[userContact.attributes?.phone as any];
          delete (contactsImgTmp as any)[userContact.attributes?.phone as any];
        }
        if (checkingIfNumIsSimilar(contactsNameTmp, userContact.attributes?.phone) === 'deleteNotDirect') {
          delete (contactsNameTmp as any)[extractPhoneNumberAndCode(userContact.attributes?.phone as any).number];
          delete (contactsImgTmp as any)[extractPhoneNumberAndCode(userContact.attributes?.phone as any).number];
        }
      });
    }
    console.log({ contactsNameTmp, contactsImgTmp });
    setContactsNotInscrit(contactsNameTmp);
    setContactsImageNotInscrit(contactsImgTmp);
    setLoading(false);
  };

  useEffect(() => {
    formatContactList();
  }, [userContacts]);

  return {
    syncContact,
    listContact,
    contactsName,
    setContactsName,
    userContacts,
    follow,
    contactsNotInscrit,
    loading,
    loadingFollow,
    mySubscriptionData: mySubscriptionData.notblockeds,
    blockedList: mySubscriptionData.blockeds,
    refetch,
    checkingIfNumIsSimilar,
    contactsImageNotInscrit,
  };
};
