import { Share } from '@capacitor/share';
import { useIonActionSheet, IonButton, IonCol, IonIcon, IonLabel, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import calendarSvg from '../../../assets/icons/calendar.svg';
import pinSvg from '../../../assets/icons/pin.svg';
import ShareSvg from '../../../assets/icons/share.svg';
import DispoDetailContainer from '../../../components/DispoDetailContainer';
import { useDispo } from '../../../hooks';
import { Enum_Event_Type, EventEntity } from '../../../models/gql/graphql';
import { getEventDateData, getFullAddress, getNotificationData, verifyIfEventDateHasPassed } from '../utils';
import { ListWithImage } from './ListWithImage';
import { NotificationSection } from './NotificationSection';
import { UserDetail } from './UserDetail';
import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';
import { useAppStore } from '../../../store';

interface IEventDetail {
  dispoData: IDispoDetail;
  scrollTop: number;
}
interface IMapState {
  apple: boolean;
  google: boolean;
  waze: boolean;
}

export const EventDetail: React.FC<IEventDetail> = ({ dispoData, scrollTop }) => {
  const { id: idDispo } = useParams<IVerifParams>();
  const [stars, setStars] = useState<number>(0);
  const [mapState, setMapState] = useState<IMapState>({
    apple: false,
    google: false,
    waze: true,
  });
  const {
    RootStore: { weatherInfo },
  } = useAppStore();
  const [present] = useIonActionSheet();
  const {
    event,
    creator,
    creatorName: name,
    eventParticipations,
    userEventStatus,
    maxInvited,
    eventType,
    eventUserParticipations,
    eventName,
    isMyDispo,
    refetchParticipation,
  } = dispoData;
  const dateHasPassed = verifyIfEventDateHasPassed(event as EventEntity);
  useEffect(() => {
    console.log('************', weatherInfo);
  }, [weatherInfo]);
  let fullAdress = getFullAddress(event);
  const eventDateData = getEventDateData(event);
  const { bgColor, textColor, text, icon } = getNotificationData(
    dispoData.eventType === Enum_Event_Type.Public
      ? maxInvited <= eventParticipations.length && userEventStatus !== 'waiting'
        ? 'full'
        : userEventStatus
      : userEventStatus
  );
  let bkColor = eventType ? (eventType === Enum_Event_Type.Private ? 'rgba(61, 39, 235)' : 'rgba(4, 177, 113)') : '';

  const { canceledEvent, setCanceledEvent } = useDispo();
  const history = useHistory();
  const openMap = async (type: string) => {
    let url;
    console.log({ type });
    if (Capacitor.getPlatform() === 'ios') {
      if (type === 'apple') {
        url = `https://maps.apple.com/?ll=${event?.attributes?.addressLat},${event?.attributes?.addressLng}&t=m&q=${event?.attributes?.addressLine}`;
      } else if (type === 'google') {
        url = `comgooglemaps://?q=${event?.attributes?.addressLine}&center=${event?.attributes?.addressLat},${event?.attributes?.addressLng}`;
      } else if (type === 'waze') {
        url = `https://waze.com/ul?ll=${event?.attributes?.addressLat},${event?.attributes?.addressLng}&q=${event?.attributes?.addressLine}&zz=10&navigate=yes`;
      } else {
        url = `geo:${event?.attributes?.addressLat},{event?.attributes?.addressLng}&q=${event?.attributes?.addressCity}`;
      }
    } else if (Capacitor.getPlatform() === 'android') {
      if (type === 'waze') {
        url = `https://waze.com/ul?ll=${event?.attributes?.addressLat},${event?.attributes?.addressLng}&q=${event?.attributes?.addressLine}&zz=10&navigate=yes`;
      } else {
        url = `https://www.google.com/maps/search/?api=1&center=${event?.attributes?.addressLat},${event?.attributes?.addressLng}&query=${event?.attributes?.addressLine}`;
        // url = `geo://?center=${event?.attributes?.addressLat},${event?.attributes?.addressLng}&zoom=25&q=${event?.attributes?.addressCity}`;
      }
    } else {
      url = `https://www.google.com/maps/search/?api=1&center=${event?.attributes?.addressLat},${event?.attributes?.addressLng}&query=${event?.attributes?.addressLine}`;
      url = `https://maps.apple.com/?ll=${event?.attributes?.addressLat},${event?.attributes?.addressLng}&t=m&q=${event?.attributes?.addressLine}`;
      url = `https://waze.com/ul?q=${encodeURIComponent(event?.attributes?.addressLine)}&zz=10&ll=${
        event?.attributes?.addressLat
      },${event?.attributes?.addressLng}`;
    }
    console.log(url, 'URL');
    await AppLauncher.openUrl({
      url,
    });
  };
  useEffect(() => {
    if (eventUserParticipations.length) {
      if (eventUserParticipations[0].attributes?.note) {
        setStars(eventUserParticipations[0].attributes.note);
      }
    }
    console.log({ eventUserParticipations });
  }, [eventUserParticipations]);
  useEffect(() => {
    const unlisten = history.listen(() => {
      setCanceledEvent(false);
    });
    return () => {
      console.log('Component is unmounting');
      unlisten();
    };
  }, [history]);
  useEffect(() => {
    console.log({ canceledEvent });
  }, [canceledEvent]);
  const shareEvent = async () => {
    await Share.share({
      title: eventName,
      text: `Jette un coup d'oeil à cet événement`,
      url: `${process.env.REACT_APP_MOBILE_APP_URI}/main/dispos/${idDispo}`,
    });
  };
  useEffect(() => {
    // check available map
    const checkAvailableMap = async () => {
      if (Capacitor.getPlatform() === 'ios') {
        const { value: google } = await AppLauncher.canOpenUrl({ url: 'comgooglemaps://' });
        const { value: apple } = await AppLauncher.canOpenUrl({ url: 'mapitem://' });
        const { value: waze } = await AppLauncher.canOpenUrl({ url: 'waze://' });
        console.log({ google, apple, waze });
        if (google) {
          setMapState((v) => ({ ...v, google: true }));
        }
        if (apple) {
          setMapState((v) => ({ ...v, apple: true }));
        }
        /*if (waze) {
          setMapState((v) => ({ ...v, waze: true }));
        }*/
      } else {
        const { value: apple } = await AppLauncher.canOpenUrl({ url: 'mapitem://' });
        const { value: waze } = await AppLauncher.canOpenUrl({ url: 'waze://' });
        console.log({ apple, waze });
        setMapState((v) => ({ ...v, google: true }));
        if (apple) {
          setMapState((v) => ({ ...v, apple: true }));
        }
        /*if (waze) {
          setMapState((v) => ({ ...v, waze: true }));
        }*/
      }
    };
    checkAvailableMap();
  }, []);
  return (
    <DispoDetailContainer
      bkColor={bkColor}
      scrollTop={scrollTop}
      dateHasPassed={dateHasPassed}
      eventUserParticipations={eventUserParticipations}
      refetchParticipation={refetchParticipation}
      scrollFix={130}
      isDeleted={event?.attributes?.deleted}
    >
      {!event?.attributes?.deleted && !dateHasPassed && !!text && !isMyDispo && (
        <NotificationSection message={text} icon={icon} bgColor={bgColor} textColor={textColor} />
      )}
      {!event?.attributes?.deleted && !text && canceledEvent && (
        <NotificationSection
          message={getNotificationData('canceled').text}
          icon={getNotificationData('canceled').icon}
          bgColor={getNotificationData('canceled').bgColor}
          textColor={getNotificationData('canceled').textColor}
        />
      )}
      {creator && !!creator.attributes && (
        <UserDetail
          name={name}
          member={creator.attributes}
          avatarStyle="width-30 height-30"
          nameStyle="text-gray-400 text-size-lg padding-2"
          idMember={creator.id}
        />
      )}
      <ListWithImage
        description={eventDateData.debut}
        description2={eventDateData.fin}
        image={calendarSvg}
        weatherInfo={weatherInfo[event?.id]}
      />

      <ListWithImage
        description={event?.attributes?.addressCity}
        description2={fullAdress}
        image={pinSvg}
        // type="adresse" // previously "adresse" to activate copytoclipboard
        action={() => {
          present({
            header: "Choisir l'action",
            buttons: [
              {
                text: 'Ouvrir sur Map',
                data: {
                  action: 'apple',
                },
              },
              {
                text: 'Ouvrir avec Google Maps',
                data: {
                  action: 'google',
                },
              },
              {
                text: 'Ouvrir avec Waze',
                data: {
                  action: 'waze',
                },
              },
              {
                text: 'Annuler',
                role: 'cancel',
                data: {
                  action: 'cancel',
                },
              },
            ].filter((i) => (mapState as any)[i.data.action]),
            onDidDismiss: ({ detail }) => {
              if (detail.data?.action) openMap(detail.data?.action as string);
            },
          });
        }}
      />
      {!event?.attributes?.deleted && !dateHasPassed && (
        <IonRow className="width-90">
          <IonButton className="button-event-detail" expand="block" onClick={shareEvent}>
            <IonLabel text-wrap className="full-width">
              <IonRow className="full-width ion-justify-content-center ion-align-items-center">
                <IonCol size="1">
                  <IonIcon icon={ShareSvg}></IonIcon>
                </IonCol>
                <IonCol className="text-underline margin-l-10">
                  <span>Partager l'évènement</span>
                </IonCol>
              </IonRow>
            </IonLabel>
          </IonButton>
        </IonRow>
      )}
    </DispoDetailContainer>
  );
};
