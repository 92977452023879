import isBefore from 'date-fns/isBefore';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_ME } from '../graphql/queries/user.graphql';
import { UsersPermissionsMe } from '../models/gql/graphql';
import StorageUtils from './storage';

export const isAuthenticated = (): boolean => {
  const authToken = StorageUtils.get('authToken');
  if (!authToken) {
    return false;
  }

  const decoded = jwtDecode<JwtPayload>(authToken);
  if (!decoded.exp) {
    // error decoding
    return false;
  }
  const expiration = new Date(decoded.exp * 1000);
  if (isBefore(expiration, new Date())) {
    // expired = clear
    StorageUtils.clear();
    return false;
  }
  return true;
};

export const getUserData: any = () => {
  const userData = StorageUtils.get('userData');
  if (!userData) {
    return null;
  }

  return JSON.parse(userData);
};

export const getUserId = (): string | undefined | null => {
  const authToken = StorageUtils.get('authToken');
  if (!authToken) {
    return null;
  }

  const decoded = jwtDecode<any>(authToken);
  if (!decoded.exp) {
    // error decoding
    return null;
  }
  return decoded.id;
};

export const useUserData = () => {
  const { data, loading: loadingMe, refetch } = useQuery(GET_ME);
  const [userData, setUserData] = useState<UsersPermissionsMe>(getUserData());

  const setStorageUserData = async (userData: UsersPermissionsMe) => {
    await StorageUtils.set('userData', userData);
  };

  useEffect(() => {
    const userConnected = !loadingMe ? data?.me : null;
    if (userConnected) {
      setStorageUserData(userConnected as UsersPermissionsMe);
      setUserData(userConnected as UsersPermissionsMe);
    }
  }, [loadingMe, data]);

  return {
    refetch,
    userData,
  };
};
