import { IonButton } from '@ionic/react';

import './style.css';

type fill = 'clear' | 'outline' | 'solid' | 'default' | undefined;

interface IButtonLarge {
  className?: string;
  text: string;
  fill?: fill;
  color?: string;
  onClick: ((data?: any) => void) | any;
  disabled?: boolean;
}

const ButtonLarge: React.FC<IButtonLarge> = ({ className, text, fill, color, onClick, disabled }) => {
  const OnClickTopChild = () => {
    onClick();
  };

  return (
    <IonButton
      className={`custom-button-large font-nunito ${className}`}
      expand="block"
      fill={fill}
      color={color}
      onClick={OnClickTopChild}
      disabled={disabled}
    >
      {text}
    </IonButton>
  );
};

export default ButtonLarge;
