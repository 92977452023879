/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { usePushNotification } from '../../hooks';

const AppUrlListener: React.FC<any> = () => {
  const { init, removeListeners } = usePushNotification();
  const history = useHistory();
  useEffect(() => {
    const launch = async () => {
      await init();
      await App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        console.log('[event] appUrlOpen', event);
        const pathArray = event.url.split(`web.app`); // for all firebase
        const slug = pathArray.pop();
        console.log({ slug });
        if (slug) {
          if (slug.includes('http')) {
            const url = new URL(slug);
            const path = url.pathname + url.search;
            history.push(path);
          } else {
            history.push(slug);
          }
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    };
    launch().then();
    return () => {
      removeListeners();
    };
  }, []);

  return null;
};

export default AppUrlListener;
