import { useMutation, useQuery } from '@apollo/client';
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { map } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import editSvg from '../../../../assets/icons/edit.svg';
import { RoundIcon, AvatarUser } from '../../../../components';
import { ComponentModal } from '../../../../components/Modals';
import { UPDATE_ME } from '../../../../graphql/mutations/auth.graphql';
import { UPLOAD } from '../../../../graphql/mutations/upload.graphql';
import { GET_AVATARS } from '../../../../graphql/queries/avatars.graphql';
import { UsersPermissionsMe } from '../../../../models/gql/graphql';
import { StorageUtils } from '../../../../utils';
import { useUserData } from '../../../../utils/auth';
interface IProps {
  refetch?: () => void;
  userMe?: UsersPermissionsMe;
}

const SelectAvatar: React.FC<IProps> = ({ userMe }) => {
  const { data: imagesData, loading: loadingImages } = useQuery(GET_AVATARS);
  const [updateMe, { loading }] = useMutation(UPDATE_ME);
  const [upload, { loading: loadingUpload }] = useMutation(UPLOAD);
  const [imageSelected, setImageSelected] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openFileModal, setOpenFileModal] = useState<boolean>(false);
  const [openComponentModal, setOpenComponentModal] = useState<boolean>(false);
  const refBtnPick = useRef<HTMLInputElement>(null);
  const [errorsMessage, setErrorsMessage] = useState<string>();
  const history = useHistory();

  const avatars = useMemo(() => {
    return !loadingImages ? imagesData?.avatars?.data : [];
  }, [loadingImages, imagesData]);

  const { refetch: refetchUserData } = useUserData();
  const changeFile = async (files: FileList) => {
    const imageMapped = map(files, (image: any) => {
      return {
        file: image,
        url: URL.createObjectURL(image),
      };
    })[0];
    const { data: dataUpload } = await upload({ variables: { file: imageMapped.file } });
    const { data } = await updateMe({
      variables: {
        data: {
          profilePicture: dataUpload?.upload.data?.id,
          avatar: null,
        },
      },
    });
    if (refetchUserData) {
      refetchUserData();
      dismiss();
    }
  };

  const handleSelectImage = (img: any) => {
    setImageSelected({ url: img?.attributes?.image?.data?.attributes?.url, idImage: img.id });
  };

  const chooseImage = async () => {
    try {
      const { data } = await updateMe({
        variables: {
          data: {
            avatar: imageSelected.idImage,
            profilePicture: null,
          },
        },
      });
      if (refetchUserData) {
        await refetchUserData();
        setIsOpen(false);
        dismiss();
      }
    } catch (err: any) {
      setIsOpen(true);
      setErrorsMessage(err.message);
    }
  };

  const cancel = () => {
    setIsOpen(false);
  };

  const openFileUpload = (e: any) => {
    setOpenComponentModal(true);
  };

  const onSelectFile = ({ target: { validity, files } }: any) => {
    if (validity.valid) {
      if (changeFile) changeFile(files);
    }
  };
  const dismiss = () => {
    setOpenComponentModal(false);
    setOpenFileModal(false);
    setIsOpen(false);
  };

  return (
    <>
      {/* <IonAvatar onClick={openFileUpload} className="width-100 height-100 margin-20 avatar-image">
        <img
          alt="Silhouette of a person's head"
          className="image-position"
          src={avatar ? avatar : 'images/picture.svg'}
          style={avatar ? { objectFit: 'cover' } : {}}
        />
        <div style={{ position: 'relative', top: '-30px', left: '30px' }}>
          <RoundIcon icon={editSvg} bgColor="bg-yellow" iconColor="text-white" />
        </div>
      </IonAvatar> */}

      <AvatarUser
        openFileUpload={openFileUpload}
        isEdit={true}
        userMe={userMe}
        size="size-lg"
        classCustom="margin-20 avatar-image"
      ></AvatarUser>

      {/* <ChooseFileModal
        isOpen={openFileModal}
        onCancel={() => setOpenFileModal(false)}
        onChangeFile={changeFile}
        textThirdBtn="Utiliser une image Tedispo"
        onClickThirdBtn={(data: boolean) => setIsOpen(data)}
      /> */}
      <input type="file" ref={refBtnPick} onChange={onSelectFile} className="ion-hide" />
      <IonRow className="border-radius-20">
        <ComponentModal isOpen={openComponentModal} onCancel={() => setOpenComponentModal(false)} height="305px">
          <IonHeader className="ion-no-border padding-t-25" mode="ios">
            <IonToolbar mode="ios">
              <IonTitle className="text-center">Modifie ta photo de profil</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-no-padding ion-padding-horizontal">
            <IonButton
              onClick={() => {
                history.push('/main/my-account/pdp/edit');
                setOpenComponentModal(false);
              }}
              className="ion-margin-top ion-border-radius-10"
              expand="block"
              color="primary"
            >
              Remplacer ma photo
            </IonButton>
            <IonButton
              onClick={() => setIsOpen(true)}
              fill="outline"
              className="ion-border-radius-10 ion-margin-top"
              expand="block"
              color="primary"
            >
              Utiliser un avatar
            </IonButton>
            <IonButton
              onClick={dismiss}
              fill="clear"
              className="text-underligne text-black ion-margin-top"
              expand="block"
            >
              Annuler
            </IonButton>
          </IonContent>
        </ComponentModal>
      </IonRow>
      <ComponentModal isOpen={isOpen} onCancel={() => setIsOpen(false)} height="530px">
        <IonHeader className="ion-no-border padding-t-25" mode="ios">
          <IonToolbar>
            <IonTitle className="text-center">Sélectionner ton avatar</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow className="overflow-auto max-height-300">
              {avatars?.map((img) => (
                <IonCol
                  key={img.id}
                  size="4"
                  onClick={() => handleSelectImage(img)}
                  className={`container-row ${imageSelected?.idImage === img.id ? 'border-image' : ''}`}
                >
                  <img
                    className="full-width full-height border-radius-20"
                    src={img.attributes?.image?.data?.attributes?.url}
                    alt="pdp"
                  ></img>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
          <IonButton
            onClick={chooseImage}
            fill="solid"
            className="border-radius-20 margin-x-20"
            expand="block"
            color="primary"
            disabled={loadingUpload}
          >
            Confirmer
          </IonButton>
          <IonButton
            onClick={cancel}
            fill="clear"
            className="d-absolute-bottom text-underligne text-black"
            expand="block"
          >
            Annuler
          </IonButton>
        </IonContent>
      </ComponentModal>
    </>
  );
};

export default SelectAvatar;
