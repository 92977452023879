import { graphql } from '../../models/gql';

export const GET_MESSAGES_FIREBASE_IDS = graphql(/* GraphQL */ `
  query getMessagesFirebaseIds($filters: MessageFiltersInput) {
    messages(filters: $filters) {
      data {
        id
        attributes {
          firebaseId
        }
      }
    }
  }
`);

export const GET_MESSAGE = graphql(/* GraphQL */ `
  query getMessage($filters: MessageFiltersInput) {
    messages(filters: $filters) {
      data {
        id
      }
    }
  }
`);
