import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { MenuPopover } from '../../../components';
import { DeleteEvent } from './DeleteEvent';

interface IDispoMenu {
  showPopover: boolean;
  closePopover: any;
  trigger?: string;
  noCancel?: boolean;
}
export const DispoMenu: React.FC<IDispoMenu> = ({
  showPopover,
  closePopover,
  trigger = 'dispo-context-menu',
  noCancel = false,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [menuItems, setMenuItems] = useState<IPopoverItem[]>([
    {
      text: 'Dupliquer',
      class: 'border-b-1 font-outfit',
      action: () => {
        closePopover();
        duplicate();
      },
    },
  ]);
  const { id } = useParams<IVerifParams>();
  const handleCancel = () => {
    setShowModal(false);
  };
  const history = useHistory();

  const duplicate = () => {
    history.push({
      pathname: `/main/dispos/${id}/duplicate`,
      search: `?${new URLSearchParams({ page: 'step-info' }).toString()}`,
    });
  };
  useEffect(() => {
    if (!noCancel) {
      setMenuItems((i) => [
        ...i,
        {
          text: 'Annuler',
          class: 'border-b-1 font-outfit',
          action: () => {
            closePopover();
            setShowModal(true);
          },
        },
      ]);
    } else {
      setMenuItems([
        {
          text: 'Dupliquer',
          class: 'border-b-1 font-outfit',
          action: () => {
            closePopover();
            duplicate();
          },
        },
      ]);
    }
  }, [noCancel]);

  return (
    <>
      <MenuPopover showPopover={showPopover} closePopover={closePopover} trigger={trigger} items={menuItems} />
      <DeleteEvent showModal={showModal} handleCancel={handleCancel} />
    </>
  );
};
