export const createIonElement = (tag: string, attributes: { [key: string]: string } = {}): HTMLElement => {
  const element = document.createElement(tag);
  for (const [key, value] of Object.entries(attributes)) {
    element.setAttribute(key, value);
  }
  return element;
};

export const appendClasses = (element: HTMLElement, classes: string[]): void => {
  classes.forEach((className) => element.classList.add(className));
};

export const appendChild = (parent: HTMLElement | null, child: HTMLElement): void => {
  if (parent && child) {
    parent.appendChild(child);
  }
};
export const createCommentElement = (
  commentText: string,
  isSubComment: boolean,
  username: string,
  avatarUrl: string
): HTMLElement => {
  const ionRow = createIonElement('ion-row', { class: 'full-width row-comment-hover font-outfit' });

  const ionCol1 = createIonElement('ion-col', { size: 'auto' });
  const ionAvatar = createIonElement('ion-avatar');
  appendClasses(ionAvatar, isSubComment ? ['width-20', 'height-20'] : ['width-25', 'height-25']);
  const img = createIonElement('img', { src: avatarUrl });
  appendChild(ionAvatar, img);
  appendChild(ionCol1, ionAvatar);

  const ionCol2 = createIonElement('ion-col');
  const spanContent = createIonElement('span', {
    class: 'margin-r-5 text-capitalize text-overflow-ellipsis text-comment-post',
    style: `white-space: pre-line; font-size: ${isSubComment ? '12px' : '13px'};`,
  });
  spanContent.innerHTML = `<span class="text-bolder">${username}</span> : ${
    commentText ? `<span>${commentText}</span>` : ''
  }`;
  appendChild(ionCol2, spanContent);

  const ionCol3 = createIonElement('ion-col');
  const spin = createIonElement('ion-spinner', {
    color: 'dark',
    class: 'custom-loader',
    style: 'width:10px; height:10px;',
  });
  appendChild(ionCol3, spin);

  appendChild(ionRow, ionCol1);
  appendChild(ionRow, ionCol2);
  appendChild(ionRow, ionCol3);

  return ionRow;
};

export const removeElement = (elClass: string) => {
  const tmpCommentElement = document.getElementById(elClass);
  if (tmpCommentElement) {
    tmpCommentElement.parentNode?.removeChild(tmpCommentElement);
  }
};
