import React from 'react';
import HyperDX from '@hyperdx/browser';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import { clientGraphQL } from './graphql/apollo-client';
import { SplashScreen as SP } from '@capacitor/splash-screen';
import { AppMemoProvider, AppReducerProvider } from './store';

const container = document.getElementById('root');
const root = createRoot(container!);
SP.show({
  autoHide: false,
});
const { hostname } = new URL(process.env.REACT_APP_GRAPHQL_ENDPOINT!);
console.log({
  apiKey: process.env.REACT_APP_HYPERDX_API_KEY!,
  service: `${process.env.REACT_APP_LOCALSTORAGE_PREFIX || ''}-frontend`,
  tracePropagationTargets: [new RegExp(hostname, 'i')], // Set to link traces from frontend to backend requests
  consoleCapture: true, // Capture console logs (default false)
  advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
});
HyperDX.init({
  apiKey: process.env.REACT_APP_HYPERDX_API_KEY!,
  service: `${process.env.REACT_APP_LOCALSTORAGE_PREFIX || ''}-frontend`,
  tracePropagationTargets: [new RegExp(hostname, 'i')], // Set to link traces from frontend to backend requests
  consoleCapture: true, // Capture console logs (default false)
  advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
});
HyperDX.setGlobalAttributes({
  appId: process.env.REACT_APP_LOCALSTORAGE_PREFIX!,
});

root.render(
  <ApolloProvider client={clientGraphQL}>
    <AppMemoProvider>
      <AppReducerProvider>
        <App />
      </AppReducerProvider>
    </AppMemoProvider>
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
