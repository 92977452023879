import { IonAvatar } from '@ionic/react';
import editSvg from '../../assets/icons/edit.svg';
import RoundIcon from '../../components/RoundIcon';
import { UsersPermissionsUser, UsersPermissionsMe } from '../../models/gql/graphql';
import { useMemo } from 'react';
import './style.css';

type SizeAvatar = 'size-md' | 'size-sm' | 'size-lg';

interface IAvatarUserProps {
  user?: Maybe<UsersPermissionsUser>;
  userMe?: UsersPermissionsMe;
  openFileUpload?: (e: any) => void;
  isEdit?: boolean;
  size?: SizeAvatar;
  positionAvatarY?: Maybe<number>;
  positionAvatarX?: Maybe<number>;
  zoomAvatar?: Maybe<number>;
  classCustom?: string;
  base64Url?: any;
}

const AvatarUser: React.FC<IAvatarUserProps> = ({
  user,
  userMe,
  openFileUpload,
  isEdit = false,
  size = 'size-md',
  zoomAvatar = 100,
  positionAvatarX = 0,
  positionAvatarY = 0,
  classCustom,
  base64Url,
}) => {
  const DEFAULT_AVATAR = 'https://ionicframework.com/docs/img/demos/avatar.svg';

  const avatar = useMemo(() => {
    if (user) {
      return user.profilePicture?.data
        ? user.profilePicture.data?.attributes?.url
        : user.avatar?.data
        ? user.avatar.data?.attributes?.image.data?.attributes?.url
        : DEFAULT_AVATAR;
    }
    if (userMe) {
      return userMe.profilePicture
        ? userMe.profilePicture.attributes?.url
        : userMe.avatar
        ? userMe.avatar.attributes?.image.data?.attributes?.url
        : DEFAULT_AVATAR;
    }
    if (!!base64Url) {
      return base64Url;
    }
  }, [user, userMe, base64Url]);

  return (
    <IonAvatar onClick={openFileUpload} className={`${size} ${classCustom} avatar-image`}>
      <div
        className="img image-position border-radius-round"
        style={{
          backgroundImage: `url(${avatar ? avatar : DEFAULT_AVATAR}`,
          backgroundSize: `${zoomAvatar}% ${zoomAvatar}%`,
          backgroundPosition: `${positionAvatarX}% ${positionAvatarY}%`,
        }}
      ></div>
      {isEdit && (
        <div style={{ position: 'relative', top: '-30px', left: '30px' }}>
          <RoundIcon icon={editSvg} bgColor="bg-yellow" iconColor="text-white" />
        </div>
      )}
    </IonAvatar>
  );
};

export default AvatarUser;
