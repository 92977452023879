import { IonContent, IonFooter, IonGrid, IonPage, IonRow, IonText, useIonViewDidEnter } from '@ionic/react';
import { filter } from 'lodash';
import { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ButtonCreate, SegmentCustom } from '../../../components';
import SimpleHeader from '../../../components/SimpleHeader';
import { GetAllParticipations, useDispoDetail } from '../../../hooks/useDispoDetail';
import { Enum_Eventuserparticipation_Status, EventUserParticipationEntity } from '../../../models/gql/graphql';
import { useAppStore } from '../../../store';
import { CardMember } from '../../DispoDetail/components/CardMember';

enum Enum_segment_invitations {
  toutes = 'toutes',
  acceptees = 'acceptees',
  attente = 'attente',
  declinees = 'declinees',
}

interface ParticipationsFilter {
  toutes?: EventUserParticipationEntity[];
  acceptees?: EventUserParticipationEntity[];
  attente?: EventUserParticipationEntity[];
  declinees?: EventUserParticipationEntity[];
}

const Invitations: React.FC = () => {
  const [segmentValue, setSegmentValue] = useState(Enum_segment_invitations.toutes);
  const { id: idDispo } = useParams<IVerifParams>();
  const {
    RootStore: { userData, getUserData },
  } = useAppStore();
  const dispoData = useDispoDetail();
  const history = useHistory();

  const segmentData: IdNameEntity[] = [
    {
      id: Enum_segment_invitations.toutes,
      name: 'Toutes',
    },
    {
      id: Enum_segment_invitations.attente,
      name: 'En attente',
    },
    {
      id: Enum_segment_invitations.acceptees,
      name: 'Acceptées',
    },
    {
      id: Enum_segment_invitations.declinees,
      name: 'Déclinées',
    },
  ];

  const { participations, loading, refetchParticipation } = GetAllParticipations(idDispo, [
    'payment_failed',
    'payment_created',
    'payment_canceled',
  ]);
  const filteredParticipations = useMemo<ParticipationsFilter>(() => {
    const val: ParticipationsFilter = {};
    if (!loading && participations) {
      val['toutes'] = participations;
      val['acceptees'] = filter(
        participations,
        (participation: EventUserParticipationEntity) =>
          participation.attributes?.status === Enum_Eventuserparticipation_Status.Accepted
      );
      val['attente'] = filter(
        participations,
        (participation: EventUserParticipationEntity) =>
          participation.attributes?.status === Enum_Eventuserparticipation_Status.Invited
      );
      val['declinees'] = filter(
        participations,
        (participation: EventUserParticipationEntity) =>
          participation.attributes?.status === Enum_Eventuserparticipation_Status.Refused
      );
    }
    return val;
  }, [loading, participations]);

  useIonViewDidEnter(() => {
    refetchParticipation();
    // reinitialize
  });

  return (
    <IonPage>
      <SimpleHeader
        title="Gestion des invitations"
        noButtonBack={false}
        showMenuButton={false}
        backButtonColor="text-black"
      ></SimpleHeader>
      <IonContent className="ion-padding">
        <IonGrid className="ion-justify-content-center ion-align-items-center width-p-95 bg-white border-radius ion-padding">
          <IonRow>
            <SegmentCustom
              values={segmentData}
              defaultValue={segmentValue}
              onChange={(value) => setSegmentValue(value.id as Enum_segment_invitations)}
              buttonStyle="ion-no-padding text-size-xxs not-transorm-text"
            ></SegmentCustom>
          </IonRow>
          <IonRow className="full font-outfit">
            {segmentValue === Enum_segment_invitations.toutes && (
              <>
                <IonText className="ion-padding-vertical text-bold">
                  {filteredParticipations.toutes?.length} invitations envoyées
                </IonText>
                {filteredParticipations.toutes?.map((participation, index) => (
                  <CardMember
                    key={index}
                    participation={participation}
                    eventType={'private'}
                    from="invitation"
                  ></CardMember>
                ))}
              </>
            )}
            {segmentValue === Enum_segment_invitations.acceptees && (
              <>
                <IonText className="ion-padding-vertical text-bold">
                  {filteredParticipations.acceptees?.length} invitations acceptées
                </IonText>
                {filteredParticipations.acceptees?.map((participation, index) => (
                  <CardMember
                    key={index}
                    participation={participation}
                    eventType={'private'}
                    from="invitation"
                  ></CardMember>
                ))}
              </>
            )}
            {segmentValue === Enum_segment_invitations.attente && (
              <>
                <IonText className="ion-padding-vertical text-bold">
                  {filteredParticipations.attente?.length} invitations en attente
                </IonText>
                {filteredParticipations.attente?.map((participation, index) => (
                  <CardMember
                    key={index}
                    participation={participation}
                    eventType={'private'}
                    from="invitation"
                  ></CardMember>
                ))}
              </>
            )}
            {segmentValue === Enum_segment_invitations.declinees && (
              <>
                <IonText className="ion-padding-vertical text-bold">
                  {filteredParticipations.declinees?.length} invitations déclinées
                </IonText>
                {filteredParticipations.declinees?.map((participation, index) => (
                  <CardMember
                    key={index}
                    participation={participation}
                    eventType={'private'}
                    from="invitation"
                  ></CardMember>
                ))}
              </>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter className="ion-no-border ion-padding">
        <ButtonCreate
          disabled={
            userData?.stripeCapabilitiesStatus !== 'active' &&
            dispoData?.event?.attributes?.paymentType !== 'Gratuite' &&
            dispoData?.creator?.id === userData?.id
          }
          action={() => history.replace(`/main/dispos/${idDispo}/new-invitations`)}
          text="Inviter d'autres participants"
        />
      </IonFooter>
    </IonPage>
  );
};

export default Invitations;
