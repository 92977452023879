import { useMutation } from '@apollo/client';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { IonButton, IonRow } from '@ionic/react';
import { CapacitorPassToWallet } from 'capacitor-pass-to-wallet';
import html2canvas from 'html2canvas';
import React, { useEffect, useMemo, useRef } from 'react';
import QRCode from 'react-qr-code';
import { CREATE_GOOGLE_PASS, DOWNLOAD_TICKET, GENERATE_PASS } from '../../../graphql/mutations/ticket.graphql';
import { EventUserParticipationEntity } from '../../../models/gql/graphql';
import { convertISO8601UTCtoLocalwZ } from '../../../utils/dateFormat';

export const DownloadTicket: React.FC<ITicketDispoPDF> = ({ userParticipation, event, setLoadingGeneratePass }) => {
  const qrCodeRef = useRef<any>(null);
  const [generatePDF, { loading }] = useMutation(DOWNLOAD_TICKET);
  const [generatePass, { loading: loadingPass }] = useMutation(GENERATE_PASS);
  const [createPass, { loading: loadingGooglePass }] = useMutation(CREATE_GOOGLE_PASS);

  const userParticipationData = useMemo<EventUserParticipationEntity>(() => {
    if (userParticipation && userParticipation.length) {
      return userParticipation[0];
    }
  }, [userParticipation]);

  let backendUrl = (process.env.REACT_APP_GRAPHQL_ENDPOINT as string).replace('graphql', '');

  const handleDownloadPDF = () => {
    if (qrCodeRef.current) {
      html2canvas(qrCodeRef.current).then(async (canvas) => {
        const qrCodeImage = canvas.toDataURL('image/png');

        const { data: dataPdf } = await generatePDF({
          variables: {
            id: userParticipationData.id || '',
            qrCodeImage,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });

        if (dataPdf?.DownloadTicket?.filePath) {
          const pdfUrl = dataPdf.DownloadTicket.filePath;
          await Browser.open({ url: pdfUrl });
        }
      });
    }
  };

  const extractTime = (date: string) => {
    return date ? new Date(date).toTimeString().split(' ')[0] : '';
  };

  const addToGoogleWallet = async () => {
    console.log(event);
    const creatorData = event.attributes?.creator?.data?.attributes;
    let eventData = {
      id: event.id,
      creator: creatorData.isPro ? creatorData.companyName : creatorData.username,
      name: event.attributes?.name,
      description: event.attributes?.description,
      date: event.attributes?.eventDate,
      endDate: event.attributes?.eventEndDate,
      startHour: extractTime(event.attributes?.beginningDateTime),
      endHour: extractTime(event.attributes?.expirationDateTime),
      address: event.attributes?.addressLine,
      lat: event.attributes?.addressLat,
      lng: event.attributes?.addressLng,
      zip_code: event.attributes?.addressZipcode,
      beginningDateTime: convertISO8601UTCtoLocalwZ(event.attributes?.beginningDateTime),
      coverCustomImage:
        event.attributes?.coverCustomImage?.data?.attributes?.url ??
        event.attributes?.coverTedispoImage?.data?.attributes?.image?.data?.attributes?.url,
      qrCode: `${backendUrl}api/event/${event.id}/verify/${userParticipationData.attributes?.qrCode}`,
    };
    const { data: passToken } = await createPass({
      variables: eventData,
    });
    const token = passToken?.CreateGooglePass?.token;
    const url = `https://pay.google.com/gp/v/save/${token}`;
    await Browser.open({ url });
  };

  const addTicketToWallet = async () => {
    if (Capacitor.isNativePlatform()) {
      if (Capacitor.getPlatform() === 'ios') {
        let firstname = userParticipationData.attributes?.user?.data?.attributes?.firstname;
        let lastname = userParticipationData.attributes?.user?.data?.attributes?.lastname;
        let username = userParticipationData.attributes?.user?.data?.attributes?.isPro
          ? userParticipationData.attributes?.user?.data?.attributes?.companyName
          : userParticipationData.attributes?.user?.data?.attributes?.username;
        const { data: dataPass } = await generatePass({
          variables: {
            name: event.attributes?.name,
            date: event.attributes?.eventDate,
            startHour: extractTime(event.attributes?.beginningDateTime),
            image:
              event.attributes?.coverCustomImage?.data?.attributes?.url ||
              event.attributes?.coverTedispoImage?.data?.attributes?.image?.data?.attributes?.url ||
              '',
            qrCode: `${backendUrl}api/event/${event.id}/verify/${userParticipationData.attributes?.qrCode}`,
            userName: username || '',
            type: event.attributes?.type,
          },
        });

        CapacitorPassToWallet.addToWallet({ base64: dataPass?.GeneratePass?.data || '' });
      } else {
        await addToGoogleWallet();
      }
    }
  };

  useEffect(() => {
    setLoadingGeneratePass && setLoadingGeneratePass(loadingPass || loadingGooglePass);
  }, [loadingPass, loadingGooglePass]);

  return (
    <IonRow className="ion-justify-content-center">
      {!!(userParticipationData && userParticipationData.attributes) && event && event.attributes && (
        <>
          <div ref={qrCodeRef}>
            <QRCode
              value={`${backendUrl}api/event/${event.id}/verify/${userParticipationData.attributes.qrCode}`}
              className="qr-code-schema"
            />
          </div>
          <IonButton
            className="ion-no-border text-white min-width-p-80 min-height-p-100 ion-border-radius-10 button-bg-black no-uppercase margin-b-10"
            onClick={handleDownloadPDF}
            disabled={loading}
          >
            Télécharger le ticket
          </IonButton>
          <IonButton
            className="text-underline"
            disabled={loadingPass || loadingGooglePass}
            expand="block"
            fill="clear"
            color="dark"
            onClick={() => {
              addTicketToWallet();
            }}
          >
            Ajouter au wallet
          </IonButton>
        </>
      )}
    </IonRow>
  );
};
