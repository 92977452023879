import { graphql } from '../../models/gql';

export const GENERATE_STRIPE_LINKS = graphql(/* GraphQL */ `
  mutation CreateStripeAccountLinks {
    CreateStripeAccountLinks {
      data
    }
  }
`);

export const GET_STRIPE_ACCOUNT = graphql(/* GraphQL */ `
  query RetrieveMyStripeAccount {
    RetrieveMyStripeAccount {
      data
    }
  }
`);

export const CREATE_STRIPE_ACCOUNT = graphql(/* GraphQL */ `
  mutation CreateStripeAccount {
    CreateStripeAccount {
      data
    }
  }
`);
