import { useMutation, useQuery } from '@apollo/client';
import { IonButton, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonTitle, IonToolbar } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import editSvg from '../../../../assets/icons/edit3.svg';
import succesSvg from '../../../../assets/icons/success.svg';
import { InputCustom } from '../../../../components';
import { ComponentModal } from '../../../../components/Modals';
import { UPDATE_ME } from '../../../../graphql/mutations/auth.graphql';
import { GET_USERS } from '../../../../graphql/queries/getUsers.graphql';
import { UsersPermissionsMe, UsersPermissionsUserInput } from '../../../../models/gql/graphql';
import { NotificationSection } from '../../../DispoDetail/components/NotificationSection';
import SelectAvatar from './selectAvatar';
import { useUserData } from '../../../../utils/auth';

interface IHeaderCompteProps {
  userConnected?: UsersPermissionsMe;
  refetch?: () => void;
}

const HeaderCompte: React.FC<IHeaderCompteProps> = ({ userConnected, refetch }) => {
  const [updateMe, { loading }] = useMutation(UPDATE_ME);
  const [errorsMessage, setErrorsMessage] = useState<string>();
  const methods = useForm<UsersPermissionsUserInput>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenToast, setIsOpenToast] = useState<boolean>(false);
  const [userName, setUsername] = useState<string>('');
  const { handleSubmit, formState, getValues } = methods;
  const [height, setHeight] = useState<string>('310px');

  const { data, loading: loadingUser } = useQuery(GET_USERS, {
    variables: {
      filters: {
        username: {
          eq: userName,
        },
      },
      pagination: { limit: 10 },
    },
  });

  const userNameAlreadyExist = useMemo(() => {
    return !loadingUser && data ? !!data.usersPermissionsUsers?.data.length : false;
  }, [data, loadingUser]);

  const { refetch: refetchUserData } = useUserData();

  const onSubmit = async (dataToPost: any) => {
    try {
      const { data, errors } = await updateMe({ variables: { data: dataToPost } });
      setIsOpenModal(false);
      await refetchUserData();
    } catch (err: any) {
      setIsOpenToast(true);
      setErrorsMessage(err.message);
    }
  };

  useEffect(() => {
    if (userName) {
      setHeight('367px');
    } else {
      setHeight('310px');
    }
  }, [userName]);

  return (
    <FormProvider {...methods}>
      <SelectAvatar refetch={refetch} userMe={userConnected}></SelectAvatar>
      <IonItem
        className="ion-text-center"
        // detail={true}
        onClick={() => setIsOpenModal(true)}
        lines="none"
      >
        <IonLabel className="text-bold text-size-x">{userConnected?.username}</IonLabel>
        <IonIcon src={editSvg} className="margin-l-5 icon-s-16" />
      </IonItem>

      <ComponentModal onCancel={() => setIsOpenModal(false)} isOpen={isOpenModal} height={height}>
        <IonHeader className="ion-no-border padding-t-25" mode="ios">
          <IonToolbar mode="ios">
            <IonTitle className="text-center">Choisis ton nouveau pseudo</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-no-padding ion-padding-horizontal">
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputCustom
              placeholder="Bob"
              attr="username"
              onIonInput={true}
              handleChange={(value: any) => {
                setUsername(
                  value.detail && (value.detail.value || value.detail.value === '') ? value.detail.value : value
                );
              }}
              value={userName || ''}
            />
            <div className="margin-b-5">
              {userName &&
                (/^[a-zA-Z0-9À-ÿ\-\'\.@_ ]*$/i.test(userName) ? (
                  <NotificationSection
                    message={userNameAlreadyExist ? "Ce pseudo n'est pas disponible." : 'Ce pseudo est disponible !'}
                    icon={userNameAlreadyExist ? closeCircleOutline : succesSvg}
                    textColor={userNameAlreadyExist ? 'red' : 'green'}
                    paddingLeft="0px"
                  />
                ) : (
                  <NotificationSection
                    message="Seules les chiffres, lettres et - _ @ . sont autorisés"
                    icon={closeCircleOutline}
                    textColor="red"
                    paddingLeft="0px"
                  />
                ))}
            </div>
            <IonButton
              type="submit"
              expand="block"
              fill="solid"
              color="primary"
              disabled={loading || userNameAlreadyExist || userName === ''}
              className="d-absolute-bottom ion-padding-horizontal ion-border-radius-10"
            >
              Enregistrer
            </IonButton>
            <IonButton
              expand="block"
              fill="clear"
              disabled={loading}
              className="d-absolute-bottom ion-padding-horizontal text-underligne text-black"
              onClick={() => setIsOpenModal(false)}
            >
              Annuler
            </IonButton>
          </form>
        </IonContent>
      </ComponentModal>
    </FormProvider>
  );
};

export default HeaderCompte;
