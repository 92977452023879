import { IonText, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';
import { useEffect } from 'react';
import { InterestLoader, InterestPicto } from '../../../components';
import { useDispo } from '../../../hooks';
import { InterestEntity } from '../../../models/gql/graphql';

interface ISectionInterest {
  loading?: boolean;
  interestsMe?: (InterestEntity | null | undefined)[] | null | undefined;
  editBlack: any;
  onOpenModal: () => void;
}

const SectionInterest: React.FC<ISectionInterest> = ({ loading, interestsMe, editBlack, onOpenModal }) => {
  const { setReloadHome } = useDispo();
  useEffect(() => {
    console.log('home');
    setReloadHome(true);
    setTimeout(() => {
      setReloadHome(false);
    }, 200);
  }, []);
  return (
    <div>
      <IonText color="dark" className="font-outfit">
        <h4>Tes centres d’intérêts</h4>
      </IonText>
      <IonGrid style={{ overflow: 'auto' }}>
        <IonRow style={{ flexWrap: 'nowrap' }}>
          {loading ? (
            <InterestLoader number={3} />
          ) : (
            <>
              {interestsMe &&
                interestsMe.map((interest) => (
                  <IonCol size="auto" key={interest?.id}>
                    <InterestPicto key={interest?.id} interest={interest as InterestEntity} />
                  </IonCol>
                ))}
              <IonCol size="auto">
                <div className="container-interest" onClick={onOpenModal}>
                  <div className="container-picto-interest container-picto-interest-border">
                    <IonIcon src={editBlack} className="edit-btn-picto" />
                  </div>
                  <IonText className="font-interest-picto font-outfit font-edit-picto">Editer</IonText>
                </div>
              </IonCol>
            </>
          )}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default SectionInterest;
