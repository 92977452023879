import Toast from './index';
import './style.css';
const ToastsContainer = ({ toasts, position = 'top-right' }: { toasts: any; position?: string }) => {
  return (
    <div className={'toasts-container ' + position}>
      {toasts.map((toast: any) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>
  );
};

export default ToastsContainer;
