import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonText,
} from '@ionic/react';
import SimpleHeader from '../../../components/SimpleHeader';
import { GetAllParticipations, useDispoDetail } from '../../../hooks/useDispoDetail';
import { getNotificationData } from '../utils';
import { CardMember } from './CardMember';
import { MemberProgressBar } from './MemberProgressBar';
import { Share } from '@capacitor/share';
import ShareSvg from '../../../assets/icons/share.svg';
import { useEffect } from 'react';
interface MemberInterface {
  type: string;
}

export const DispoMember: React.FC<MemberInterface> = ({ type }) => {
  const dispoData = useDispoDetail();
  const {
    eventUserParticipations: participations,
    eventType,
    maxInvited,
    eventName,
    refetchParticipation,
    loadingEvent,
  } = dispoData;

  const { participations: eventParticipations, loading } = GetAllParticipations(dispoData.id, [
    'payment_failed',
    'payment_created',
    'payment_canceled',
    'invited',
    'refused',
    'waiting',
    'canceled',
  ]);
  const title = eventParticipations.length ? eventParticipations.length + ' participant.e.s' : '';

  const isFull = maxInvited && maxInvited <= eventParticipations.length;
  const notifData = isFull ? getNotificationData('full') : ({} as INotificationDetail);
  const shareEvent = async () => {
    await Share.share({
      title: eventName,
      dialogTitle: eventName,
      text: `Jette un coup d'oeil à cet événement`,
      url: `${process.env.REACT_APP_MOBILE_APP_URI}/main/dispos/${dispoData.id}`,
    });
  };
  useEffect(() => {
    refetchParticipation();
  });
  return (
    <IonPage>
      <SimpleHeader
        title={`${eventType === 'private' ? 'Liste des invités' : 'Liste des participant.e.s'}`}
        noButtonBack={false}
        showMenuButton={true}
        backButtonColor="text-black"
      ></SimpleHeader>
      <IonContent>
        {loading || loadingEvent ? (
          <div className="ion-padding custom-skeleton">
            <IonSkeletonText animated style={{ width: '60%' }} />
            <IonSkeletonText animated />
            <IonSkeletonText animated style={{ width: '88%' }} />
            <IonSkeletonText animated style={{ width: '70%' }} />
            <IonSkeletonText animated style={{ width: '30%' }} />
            <IonSkeletonText animated style={{ width: '80%' }} />
            <IonSkeletonText animated style={{ width: '40%' }} />
          </div>
        ) : (
          <div className="bg-primary full-height">
            <IonGrid
              className="margin-auto width-p-95 full-height"
              style={{
                color: '#313333',
                fontFamily: 'Outfit',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%',
              }}
            >
              {eventType === 'private' ? (
                <IonRow className="margin-y-20  bg-white border-radius-top-20">
                  {eventParticipations.length ? (
                    <IonText className="text-bolder text-size-md margin-l-20">{`${eventParticipations.length} ${
                      eventParticipations.length === 1 ? 'participant(e)' : 'participant(e)s'
                    }`}</IonText>
                  ) : (
                    <IonText className="text-bolder text-size-md margin-l-20">Aucun inscrit</IonText>
                  )}
                </IonRow>
              ) : eventParticipations.length ? (
                <MemberProgressBar type="member" dispoData={dispoData} title={title} noBorder={true} />
              ) : (
                <IonRow className="margin-y-20   bg-white border-radius-top-20">
                  <IonText className="text-bolder text-size-md margin-l-20">Aucun inscrit</IonText>
                </IonRow>
              )}
              {eventParticipations?.map((participation: any, index: any) => (
                <CardMember key={index} participation={participation} eventType={eventType}></CardMember>
              ))}

              {
                /** sharing if no participents */
                eventParticipations.length === 0 && (
                  <IonCard
                    className="ion-no-padding ion-no-margin "
                    style={{
                      boxShadow: 'none',
                      marginTop: '10px',
                    }}
                  >
                    <IonCardContent
                      className="ion-text-center"
                      style={{
                        color: '#313333',
                        fontFamily: 'Outfit',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '140%',
                      }}
                    >
                      <IonText>
                        Personne n'est encore inscrit à ta dispo mais pas de panique, cela ne va pas durer!
                        <br />
                      </IonText>
                      <IonButton className="button-event-detail" expand="block" onClick={shareEvent}>
                        <IonLabel text-wrap className="full-width">
                          <IonRow className="full-width ion-justify-content-center ion-align-items-center">
                            <IonCol size="1">
                              <IonIcon icon={ShareSvg}></IonIcon>
                            </IonCol>
                            <IonCol className="text-underline margin-l-10">
                              <span>Partager l'évènement</span>
                            </IonCol>
                          </IonRow>
                        </IonLabel>
                      </IonButton>
                    </IonCardContent>
                  </IonCard>
                )
              }
            </IonGrid>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default DispoMember;
