import { useMutation, useQuery } from '@apollo/client';
import { IonButton, IonFooter, IonPage } from '@ionic/react';
import { omit } from 'lodash';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { CardEventDetail, Header } from '../../../components';
import { CREATE_EVENT, INVITE_USERS } from '../../../graphql/mutations/event.graphql';
import { CREATE_MESSAGE } from '../../../graphql/mutations/message.graphql';
import { GET_ME } from '../../../graphql/queries/user.graphql';
import { useDispo } from '../../../hooks';
import { insertEventToCalendar } from '../../../hooks/synchroniseCalendar';
import { useMessenger } from '../../../hooks/useMessenger';
import { Enum_Event_Type, EventEntity, MessageInput, UsersPermissionsMe } from '../../../models/gql/graphql';
import { useAppStore } from '../../../store';
import { convertDateToSimple, convertTimeForGraphql } from '../../../utils';

const Preview: React.FC<IStepProps> = ({ duplicate }) => {
  const { dispo, imageUrl, imageSelecteds, setDispo, setImageSelecteds, setDispoView } = useDispo();
  const { CreateChat } = useMessenger();
  const { data, loading, refetch } = useQuery(GET_ME);
  const [createEvent, { loading: loadingEvent }] = useMutation(CREATE_EVENT);
  const [InviteToEvent, { loading: loadingInviteToEvent }] = useMutation(INVITE_USERS);
  const [createMessage, { loading: loadingMessage }] = useMutation(CREATE_MESSAGE);

  const {
    RootStore: { algoliaCache, setRefreshDispos },
  } = useAppStore();

  const history = useHistory();

  const publishEvent = async () => {
    const dataEventToPost = omit(
      {
        ...dispo,
        eventDate: convertDateToSimple(new Date(dispo.eventDate)),
        startHour: convertTimeForGraphql(dispo.startHour),
        endHour: convertTimeForGraphql(dispo.endHour),
        //  eventDate: convertDateToSimple(new Date(dispo.eventDate)),
        // startHour: convertTimeForGraphql(dispo.startHour),
        // endHour: convertTimeForGraphql(dispo.endHour),
        // secondaryImages: []
        beginningDateTime: new Date(dispo.eventDate).toISOString(),
      },
      ['place', 'participations', 'eventEndDate']
    );
    if (dispo.eventEndDate) {
      dataEventToPost['eventEndDate'] = convertDateToSimple(new Date(dispo.eventEndDate));
    }

    try {
      const { data: dataEvent } = await createEvent({ variables: { data: dataEventToPost } });
      await algoliaCache.invalidate();
      setRefreshDispos(true);
      if (dataEventToPost.type === Enum_Event_Type.Private) {
        const { data: dataInviteEvent } = await InviteToEvent({
          variables: { input: { id: dataEvent?.createEvent?.data?.id, users: dispo.participations } },
        });
      }

      // create dispo chat
      if (dispo.useChat) {
        const participants =
          dispo.type === Enum_Event_Type.Private
            ? [dispo.creator, ...(dispo?.participations as any)]
            : [dispo?.creator];
        console.log({ participants });
        CreateChat({
          id: `dispo_${dataEvent?.createEvent?.data?.id}`,
          dispoName: dispo.name,
          dispoImageUrl: dataEvent?.createEvent?.data?.attributes?.coverCustomImage?.data
            ? dataEvent?.createEvent?.data?.attributes?.coverCustomImage?.data?.attributes?.url
            : dataEvent?.createEvent?.data?.attributes?.coverTedispoImage?.data?.attributes?.image?.data?.attributes
                ?.url,
          participantsIds: participants,
          isDispo: true,
          seens: [dispo?.creator],
        })
          .then(async (result: any) => {
            // create message
            const messageData = {
              participants: participants,
              publishedAt: new Date(),
              content: dispo.name,
              firebaseId: result?.chatId,
              dispoName: dispo.name,
            };
            await createMessage({ variables: { data: messageData as MessageInput } });
          })
          .catch((err: any) => {
            console.error('Error while creating chat', err);
          });
      }

      setDispoView(dataEvent?.createEvent?.data as EventEntity);
      setDispo({});

      //Add event to calendar
      let eventData = dataEvent?.createEvent?.data;
      if (eventData) {
        insertEventToCalendar(eventData);
      }

      history.push('/main/home/create-dispo/confirmed');
    } catch (e) {
      console.log(e);
    }
  };

  const userConnected = useMemo(() => {
    return !loading ? (data?.me as UsersPermissionsMe) : null;
  }, [loading, data]);

  return (
    <IonPage>
      <Header title="Aperçu de la dispo" noButtonBack={false} />
      <CardEventDetail
        creator={userConnected}
        event={dispo}
        imageUrl={imageUrl}
        imageSelecteds={imageSelecteds}
      ></CardEventDetail>
      {/* <IonContent className="ion-padding">
        <h3>Aperçu de ta dispo</h3>
        <IonCard>
          <img alt="Silhouette of mountains" src={imageUrl} />
          <IonCardHeader>
            <IonCardTitle>{dispo.name}</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <IonList>
              <IonItem className="ion-no-padding">
                <IonIcon icon={calendarOutline} size="large"></IonIcon>
                <IonLabel className="ion-margin-start">
                  <h3>{eventDateData.debut}</h3>
                  <p>{eventDateData.fin}</p>
                </IonLabel>
              </IonItem>
              <IonItem className="ion-no-padding">
                <IonIcon icon={pinOutline} size="large"></IonIcon>
                <IonLabel className="ion-margin-start">
                  <h3>{dispo.addressCity}</h3>
                  <p>{dispo.addressLine}</p>
                </IonLabel>
              </IonItem>
              <IonItem className="ion-no-padding">
                <IonIcon icon={ticketOutline} size="large"></IonIcon>
                <IonLabel className="ion-margin-start">
                  <h3>{dispo.paymentType === Enum_Event_Paymenttype.Gratuite && Enum_Event_Paymenttype.Gratuite}</h3>
                  <h3>{dispo.paymentType === Enum_Event_Paymenttype.Payante && `${dispo.price}€ par parcicipant`}</h3>
                  <h3>
                    {dispo.paymentType === Enum_Event_Paymenttype.Contributive && `${dispo.moneyRaised}€ à atteindre`}
                  </h3>
                </IonLabel>
              </IonItem>
              <IonItem className="ion-no-padding">
                <IonIcon icon={diamondOutline} size="large"></IonIcon>
                <IonLabel className="ion-margin-start">
                  <h3>Dispo {dispo.type}</h3>
                </IonLabel>
              </IonItem>
              {!isNaN(dispo.maxInvited as number) && (
                <IonItem className="ion-no-padding">
                  <IonIcon icon={peopleOutline} size="large"></IonIcon>
                  <IonLabel className="ion-margin-start">
                    <h3>{dispo.maxInvited} places disponibles</h3>
                  </IonLabel>
                </IonItem>
              )}
              <IonItem className="ion-no-padding">
                <Swiper
                  pagination={{
                    clickable: true,
                    type: 'bullets',
                    renderBullet(index, className) {
                      return `<span class="${className} pagination-custom"></span>`;
                    },
                  }}
                >
                  {imageSelecteds &&
                    imageSelecteds.map((item: handleType, index: number) => (
                      <SwiperSlide key={index}>
                        <IonCard>{item && <img alt="Silhouette of mountains" src={item.url as string} />}</IonCard>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </IonItem>
              <IonItem className="ion-no-padding">
                <IonLabel className="ion-margin-start">
                  <h3>A propos</h3>
                  <p dangerouslySetInnerHTML={{ __html: dispo.description as any }}></p>
                </IonLabel>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
        <IonButton
          onClick={publishEvent}
          disabled={loadingEvent || loadingInviteToEvent}
          className="custom-button ion-margin-top"
          expand="block"
          color="primary"
        >
          Publier
        </IonButton>
      </IonContent> */}
      <IonFooter color="transparent" className="ion-no-border ion-padding">
        <IonButton
          onClick={publishEvent}
          disabled={loadingEvent || loadingInviteToEvent}
          className="ion-margin-top"
          expand="block"
          color="primary"
        >
          Publier
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Preview;
