import { graphql } from '../../models/gql';

export const GET_ONBOARDINGS = graphql(/* GraphQL */ `
  query GetOnBoarding {
    onBoarding {
      data {
        id
        attributes {
          items {
            title
            description
            media {
              data {
                id
                attributes {
                  url
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`);
