import { CommentEntity } from '../models/gql/graphql';

export const getNbComments = (comments: CommentEntity[]): number => {
  return comments.reduce((count, comment) => {
    if (!comment || comment.attributes?.deleted) {
      return count;
    }

    count++; // Increment for the main comment

    if (comment.attributes?.subcomments && comment.attributes?.subcomments?.data?.length > 0) {
      count += comment.attributes.subcomments.data.reduce((subCount, subComment) => {
        return subComment && !subComment.attributes?.deleted ? subCount + 1 : subCount;
      }, 0);
    }
    return count;
  }, 0);
};
