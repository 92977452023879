import { useMutation } from '@apollo/client';
import { IonCol, IonRow, IonSpinner, IonToast } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import warningSvg from '../../../assets/icons/warning.svg';
import { ConfirmModal } from '../../../components/Modals';
import {
  ACCEPT_INVITATION,
  CANCEL_PARTICIPATION,
  DELETE_USER_PARTICIPATION,
  PARTICIPE_TO_EVENT,
  REFUSE_INVITATION,
} from '../../../graphql/mutations/updateEventUserParticipation.graphql';
import { UPDATE_EVENT_USER } from '../../../graphql/mutations/update-event.graphql';
import { useDispoDetail } from '../../../hooks/useDispoDetail';
import { __ } from '../../../utils/traduction';
import { DispoParticipationFooter } from './DispoParticipationFooter';
import { Enum_Event_Type, Enum_Eventuserparticipation_Status, EventEntity } from '../../../models/gql/graphql';
import { deleteEventToCalendar, insertEventToCalendar } from '../../../hooks/synchroniseCalendar';
import { useDispo } from '../../../hooks';
import { useMessenger } from '../../../hooks/useMessenger';
import { getUserId } from '../../../utils';
import { verifyIfEventDateHasPassed } from '../utils';

export const ParticipeButton: React.FC<IDispoData> = ({ dispoData, refresh, colorShadow }) => {
  const {
    event,
    eventParticipations,
    eventUserParticipations,
    userEventStatus,
    maxInvited,
    loadingEvent,
    loadingParticipation,
  } = useDispoDetail();
  const { setCanceledEvent } = useDispo();
  const location = useLocation();

  const { id: idDispo } = useParams<IVerifParams>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showLeaveConfirm, setShowLeaveConfirm] = useState(false);
  const [messageError, setMessageError] = useState<string>('');
  const [participationProps, setParticipationProps] = useState<IDispoParticipationFooter>(
    {} as IDispoParticipationFooter
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [AcceptInvitation, { loading: loadingAccept }] = useMutation(ACCEPT_INVITATION);
  const [RefuseInvitation, { loading: loadingRefuse }] = useMutation(REFUSE_INVITATION);
  const [CancelPartition, { loading: loadingCancel }] = useMutation(CANCEL_PARTICIPATION);
  const [deleteParticipation, { loading: loadingDelete }] = useMutation(DELETE_USER_PARTICIPATION);
  const [ParticipeToEvent, { loading: loadingParticipe }] = useMutation(PARTICIPE_TO_EVENT);
  const [UpdateEventUser, { loading: loadingUpdate }] = useMutation(UPDATE_EVENT_USER);
  const dateHasPassed = verifyIfEventDateHasPassed(event as EventEntity);
  const { UpdateParticipants } = useMessenger();

  const history = useHistory();
  const handleCancel = () => {
    setShowModal(false);
  };

  const acceptEvent = async (idDispo: string) => {
    try {
      await AcceptInvitation({
        variables: {
          id: idDispo,
        },
      });

      refresh && refresh();
      history.push(`/main/dispos/${idDispo}/inscription-success`);
    } catch (err: any) {
      setIsOpen(true);
      setMessageError(err.message);
    }
  };

  const participeToEvent = async (idDispo: string, redirect: boolean = true) => {
    if (dispoData.paymentType === 'Payante') {
      history.push(`/main/dispos/${idDispo}/pre-order`);
    } else if (dispoData.paymentType === 'Contributive') {
      history.push(`/main/dispos/${idDispo}/contribute`);
    } else {
      try {
        await ParticipeToEvent({
          variables: {
            id: idDispo,
          },
        });
        refresh && refresh();
        if (redirect) {
          history.push(`/main/dispos/${idDispo}/inscription-success`);
        }
      } catch (err: any) {
        setIsOpen(true);
        setMessageError(err.message);
      }
    }
  };

  const leaveParticipation = async (idParticipation: string, isCanceled: boolean = false) => {
    try {
      await deleteParticipation({
        variables: {
          id: idParticipation,
        },
      });
      if (isCanceled) {
        setCanceledEvent(true);
      }
      refresh && refresh();
    } catch (err: any) {
      setIsOpen(true);
      setMessageError(err.message);
    }
  };
  const cancelParticipation = async (idParticipation: string) => {
    try {
      await CancelPartition({
        variables: {
          id: idDispo,
        },
      });

      await deleteEventToCalendar(event);

      const noBackButton = new URLSearchParams(location.search).get('noBackButton');
      if (noBackButton) {
        history.replace(location.pathname + '?goToMyDispo=true');
      }
      refresh && refresh();
    } catch (err: any) {
      setIsOpen(true);
      setMessageError(err.message);
    }
  };

  const declineEvent = async () => {
    try {
      console.log({ idDispo });
      await RefuseInvitation({
        variables: {
          id: idDispo,
        },
      });
      handleCancel();
      refresh && refresh();
    } catch (err: any) {
      handleCancel();
      setIsOpen(true);
      setMessageError(err.message);
    }
  };

  const goToChat = () => {
    if (!!dispoData.event.attributes.useChat) {
      UpdateParticipants({ id: `dispo_${idDispo}`, idUser: getUserId()?.toString() });
      history.push(`/main/group/${idDispo}`);
    } else {
      setIsOpen(true);
      setMessageError("Le chat sur ce dispo n'est pas disponible");
    }
  };

  const makeParticipationData = () => {
    let textButton1 = '';
    let textButton2 = '';
    let actionButton1 = null as any;
    let actionButton2 = null as any;
    let isDispoFull = false;

    if (dispoData.eventType === Enum_Event_Type.Public) {
      if (
        userEventStatus !== 'waiting' &&
        userEventStatus !== 'invited' &&
        ((maxInvited && maxInvited <= eventParticipations.length) || !maxInvited) &&
        userEventStatus !== 'accepted'
      ) {
        textButton1 = "Rejoindre la liste d'attente";
        actionButton1 = () => participeToEvent(idDispo, false);
        isDispoFull = true;
      } else {
        if (userEventStatus === 'accepted') {
          textButton1 = 'Accéder au chat';
          textButton2 = 'Annuler ma participation';
          actionButton1 = () => goToChat();
          actionButton2 = () => setShowLeaveConfirm(true);
        } else if (userEventStatus === 'invited') {
          textButton1 = 'Accepter la dispo';
          textButton2 = 'Refuser la dispo';
          actionButton1 = () => acceptEvent(idDispo);
          actionButton2 = () => setShowModal(true);
        } else if (userEventStatus === 'waiting') {
          textButton1 = "Quitter la liste d'attente";
          actionButton1 = () => leaveParticipation(eventUserParticipations[0].id);
        } else if (userEventStatus !== 'invited' && userEventStatus !== 'waiting' && userEventStatus !== 'accepted') {
          if (dispoData.paymentType === 'Contributive') {
            textButton1 = 'Contribuer';
          } else {
            textButton1 = 'Je participe';
          }
          actionButton1 = () => participeToEvent(idDispo);
        }
      }
    }
    if (dispoData.eventType === Enum_Event_Type.Private) {
      if (userEventStatus === 'accepted') {
        textButton1 = 'Accéder au chat';
        textButton2 = 'Annuler ma participation';
        actionButton1 = () => goToChat();
        actionButton2 = () => setShowLeaveConfirm(true);
      } else {
        if (dispoData.paymentType === 'Contributive') {
          textButton1 = 'Contribuer';
          textButton2 = '';
          actionButton1 = () => {
            if (dispoData.paymentType === 'Payante' || dispoData.paymentType === 'Contributive') {
              participeToEvent(idDispo);
            } else {
              acceptEvent(idDispo);
            }
          };
        } else {
          if (userEventStatus === 'invited') {
            textButton1 = 'Accepter la dispo';
            textButton2 = 'Refuser la dispo';
            actionButton1 = () => {
              if (dispoData.paymentType === 'Payante' || dispoData.paymentType === 'Contributive') {
                participeToEvent(idDispo);
              } else {
                acceptEvent(idDispo);
              }
            };
            actionButton2 = () => setShowModal(true);
          }
        }
      }
    }

    let price = event && event.attributes ? event.attributes?.price : '';
    let type = event && event.attributes ? event.attributes.paymentType : '';
    console.log({
      type: type,
      isDispoFull: isDispoFull,
      textButton1: textButton1,
      ...(textButton2 !== '' && { textButton2: textButton2 }),
      ...(price && { price: price }),
      ...(actionButton2 && { actionButton2: actionButton2 }),
      ...(actionButton1 && { actionButton1: actionButton1 }),
      dateHasPassed: dateHasPassed,
    });
    setParticipationProps({
      type: type,
      isDispoFull: isDispoFull,
      textButton1: textButton1,
      ...(textButton2 !== '' && { textButton2: textButton2 }),
      ...(price && { price: price }),
      ...(actionButton2 && { actionButton2: actionButton2 }),
      ...(actionButton1 && { actionButton1: actionButton1 }),
      dateHasPassed: dateHasPassed,
    });
  };

  useEffect(() => {
    if (!loadingParticipation && !loadingEvent) {
      (async () => {
        await makeParticipationData();
        setLoading(false);
      })();
    }
  }, [dispoData, event]);

  return (
    <>
      {!loading ? (
        <DispoParticipationFooter
          {...participationProps}
          deleted={event?.attributes?.deleted}
          loading={loadingAccept || loadingDelete || loadingParticipe || loadingRefuse || loadingUpdate}
          colorShadow={colorShadow}
        />
      ) : (
        <IonRow className={`full-width ion-no-padding bg-white h-40`}>
          <IonCol>
            <IonSpinner color="dark" className={'custom-loader'}></IonSpinner>
          </IonCol>
        </IonRow>
      )}

      <ConfirmModal
        isOpen={showModal}
        text="Es-tu sûr de vouloir décliner l'invitation à cette dispo?"
        okText="Confirmer"
        cancelText="Annuler"
        iconPerso={warningSvg}
        onConfirm={() => declineEvent()}
        onCancel={handleCancel}
        height="365px"
      />
      <ConfirmModal
        isOpen={showLeaveConfirm}
        text="Es-tu sûr de vouloir annuler la participation à cette dispo?"
        okText="Confirmer"
        cancelText="Annuler"
        iconPerso={warningSvg}
        onConfirm={() => {
          // leaveParticipation(eventUserParticipations[0].id, true);
          // declineEvent();
          cancelParticipation(eventUserParticipations[0].id);
          setShowLeaveConfirm(false);
        }}
        onCancel={handleCancel}
        height="365px"
      />
      <IonToast
        isOpen={isOpen}
        message={__(messageError)}
        onDidDismiss={() => setIsOpen(false)}
        duration={5000}
        color="danger"
      ></IonToast>
    </>
  );
};
