import { IonInput, IonIcon } from '@ionic/react';
import React, { useRef, useState } from 'react';
import './style.css';

interface IInput {
  handleChange?: (e: any) => void;
  label: string;
  placeholder?: string;
  icon?: any;
}

const FilePickerCustom: React.FC<IInput> = ({ label, placeholder, icon, handleChange }) => {
  const [file, setFile] = useState<string>('');
  const fileInput = useRef(null);

  const changeFile = (files: FileList) => {
    console.log({ files });
    if (files[0]) {
      setFile(files[0].name);
      if (handleChange) handleChange(files[0]);
    }
  };

  const onSelectFile = ({ target: { validity, files } }: any) => {
    if (validity.valid) {
      if (changeFile) changeFile(files);
    }
  };
  const openFilePicker = () => {
    (fileInput as any)?.current?.click();
  };

  return (
    <div className="ion-margin-bottom container-relative">
      <p className="custom-label font-outfit">{label}</p>
      <IonInput
        readonly
        value={file}
        className={`custom-input`}
        placeholder={placeholder}
        onClick={openFilePicker}
      ></IonInput>
      <IonIcon className="icon-position-file" icon={icon}></IonIcon>
      <input ref={fileInput} hidden type="file" multiple onChange={onSelectFile} />
    </div>
  );
};

export default FilePickerCustom;
