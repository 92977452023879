import { useMutation } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { IonButton, IonContent, IonIcon, IonPage, IonText } from '@ionic/react';
import html2canvas from 'html2canvas';
import { closeCircleOutline } from 'ionicons/icons';
import { map } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonGroup, CardCustom, Header, Loader, StepperCount } from '../../../../components';
import { ChooseFileModal } from '../../../../components/Modals';
import { UPDATE_ME } from '../../../../graphql/mutations/auth.graphql';
import { UPLOAD } from '../../../../graphql/mutations/upload.graphql';
import { UsersPermissionsUserInput } from '../../../../models/gql/graphql';
import { useUserData } from '../../../../utils/auth';
import './style.css';
interface IPDPParams {
  edit?: string;
}
const ConfigPDP: React.FC = () => {
  const { edit } = useParams<IPDPParams>();
  const [image, setImage] = useState<any>();
  const [openFileModal, setOpenFileModal] = useState<boolean>(false);
  const modal = useRef<HTMLIonModalElement>(null);
  const page = useRef(null);
  const fileInput = useRef(null);
  const imgRef = useRef(null);
  const history = useHistory();
  const [updateMe, { data, loading }] = useMutation(UPDATE_ME);
  const transformWrapperRef = useRef(null);
  const curentRef = useRef(null);
  const [upload, { data: dataUpload, loading: loadingUpload }] = useMutation(UPLOAD);
  const [uploadFileLocal, setUploadFileLocal] = useState<boolean>(false);
  const { refetch: refetchUserData } = useUserData();
  const uploadFile = async () => {
    let imgFile = await getTransformedImage();
    upload({ variables: { file: imgFile ? imgFile : image.file } });
  };

  const loadingPDP = useMemo(() => {
    return loading || uploadFileLocal || loadingUpload;
  }, [loadingUpload, loading, uploadFileLocal]);

  useEffect(() => {
    if (!loadingUpload && dataUpload) {
      const data: UsersPermissionsUserInput = {
        profilePicture: dataUpload?.upload?.data?.id,
        avatar: null,
      };
      updateMe({ variables: { data: data } });
      refetchUserData();
      history.replace(!edit ? '/signup/config-contact' : '/main/my-account/menu');
    }
  }, [dataUpload, loadingUpload]);

  const changeFile = (files: FileList) => {
    setImage(
      map(files, (image: any) => {
        return {
          file: image,
          url: URL.createObjectURL(image),
        };
      })[0]
    );
    modal.current?.dismiss();
    setUploadFileLocal(false);
    setOpenFileModal(false);
  };

  const goToAvatar = () => {
    history.push('/signup/config-avatar');
  };
  const openFileUpload = (e: any) => {
    console.log('openfileupload');
    if (Capacitor.isNativePlatform()) {
      setOpenFileModal(true);
    } else {
      (fileInput as any)?.current?.click();
    }
  };
  const onSelectFile = ({ target: { validity, files } }: any) => {
    setUploadFileLocal(true);
    if (validity.valid) {
      if (changeFile) changeFile(files);
    }
  };

  // To override shadow-root (open)
  document
    ?.querySelector('.wrap-text')
    ?.shadowRoot?.querySelector('.label-text-wrapper')
    ?.setAttribute('style', 'white-space:normal');

  const getTransformedImage = async () => {
    if (curentRef && curentRef.current && image) {
      const canvas = await html2canvas(curentRef.current);
      const transformedImageURL = canvas.toDataURL(image.type);
      const filename = image.name;
      const transformedFileList = fileListFromImageBase64(transformedImageURL, filename);
      return transformedFileList as File;
    }
    return null;
  };

  const fileListFromImageBase64 = (imageBase64: string, filename: string) => {
    const file = dataURLtoFile(imageBase64, filename);
    return file;
  };

  const dataURLtoFile = (dataURL: string, filename: string) => {
    var arr = dataURL.split(',');
    var mime = (arr as any)[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleTransform = () => {
    if (transformWrapperRef.current) {
      curentRef.current = transformWrapperRef.current;
    }
  };

  const onUpdate = useCallback(({ x, y, scale }: any) => {
    const img: any = imgRef.current;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty('transform', value);
    }
  }, []);

  return (
    <IonPage ref={page}>
      {loadingPDP && <Loader />}
      <Header title={edit ? 'Remplacer ma photo' : 'Configuration de compte'} noButtonBack={false} />
      {!edit && <StepperCount length={3} numberStep={3}></StepperCount>}
      <IonContent className="ion-text-left ion-padding">
        <CardCustom>
          <IonText className="font-outfit ion-no-margin" color="dark">
            <h3>Ta photo de profil</h3>
          </IonText>
          <div className={`position-image-container`} id="open-modal" onClick={openFileUpload}>
            {/* {image && image.url ? (
              <>
                <TransformWrapper
                  initialScale={1}
                  initialPositionX={0}
                  initialPositionY={0}
                  maxScale={5}
                  onTransformed={handleTransform}
                >
                  <div className="container-image-pdp" ref={transformWrapperRef}>
                    <TransformComponent>
                      <img src={image ? image.url : 'images/picture.svg'} className="transform-component-img" />
                    </TransformComponent>
                  </div>
                </TransformWrapper>
              </>
            ) : (
              <div className="container-image-pdp" onClick={openFileUpload}>
                <img src="images/picture.svg" className="transform-component-img min-h-100 min-w-150" />
              </div>
            )} */}
            {image && image.url ? (
              <div className="container-image-pdp">
                <div ref={curentRef}>
                  <QuickPinchZoom onUpdate={onUpdate} inertia={false}>
                    <img ref={imgRef} src={image ? image.url : 'images/picture.svg'} alt="pdp pic" />
                  </QuickPinchZoom>
                </div>
              </div>
            ) : (
              <div className="container-image-pdp-default imgPDP">
                <img
                  src="images/picture.svg"
                  className="transform-component-img min-h-100 min-w-150"
                  alt="pdp pic default"
                />
              </div>
            )}

            {image && (
              <IonButton
                className="ion-no-padding button-reset-image"
                mode="ios"
                color="dark"
                size="small"
                shape="round"
                onClick={(e) => {
                  e.stopPropagation();
                  setImage(null);
                }}
              >
                <IonIcon slot="icon-only" className="ion-no-padding" icon={closeCircleOutline}></IonIcon>
              </IonButton>
            )}
          </div>

          <ChooseFileModal
            isOpen={openFileModal}
            onCancel={() => setOpenFileModal(false)}
            onChangeFile={changeFile}
            title="Photo de profil"
            limit={true}
          />
          <input ref={fileInput} hidden type="file" multiple accept="image/*" onChange={onSelectFile} />
          {/* <ButtonGroup
            textBottom="Continuer"
            disabled={loading || loadingUpload}
            onClickBottom={uploadFile}
            onClickTop={goToAvatar}
            textTop={!edit ? 'Utiliser un avatar' : undefined}
            fillTop="outline"
            classNameTop="ion-margin-top"
          /> */}
          {!edit && (
            <IonButton expand="block" fill="outline" onClick={goToAvatar} className="ion-margin-top">
              {!edit ? 'Utiliser un avatar' : undefined}
            </IonButton>
          )}
          <IonButton
            className="ion-margin-top"
            expand="block"
            onClick={uploadFile}
            disabled={loading || loadingUpload || !image}
          >
            Continuer
          </IonButton>
        </CardCustom>
      </IonContent>
    </IonPage>
  );
};

export default ConfigPDP;
