import { graphql } from '../../models/gql';

export const DELETE_POST = graphql(/* GraphQL */ `
  mutation deletePost($id: ID!) {
    deletePost(id: $id) {
      data {
        id
      }
    }
  }
`);
