export const notificationRedirection: any = {
  // detai dispo
  dispo_invitation: '/main/dispos/:id',
  dispo_remind_1d: '/main/dispos/:id',
  dispo_remind_6h: '/main/dispos/:id',
  dispo_completed: '/main/dispos/:id',
  dispo_to_rate: '/main/dispos/:id',
  dispo_created: '/main/dispos/:id',
  dispo_edited: '/main/dispos/:id',
  dispo_deleted: '/main/dispos/:id',
  dispo_accept_waiting_participation: '/main/dispos/:id',
  dispo_published: '/main/dispos/:id',
  dispo_expired: '/main/dispos/:id',

  //post
  like: '/main/post/:id?open=:id',
  new_comment: '/main/post/:id?open=:id',

  // notif
  follow_request: '/main/notifications/mine',

  //user
  dispo_accepted: '/main/profil/:id',
  dispo_refused: '/main/profil/:id',
  follow_accepted: '/main/profil/:id',
  follow_rejected: '/main/profil/:id',
  new_follower: '/main/profil/:id',

  //chat
  chat: '/main/chat',
};
