import { IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonPage, IonRow } from '@ionic/react';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Header, Loader } from '../../../components';
import UserContactList from '../../../components/UserContactList';
import { useUserContact } from '../../../hooks/useUserContact';
import './style.css';

const NewContact: React.FC = () => {
  const [invitationsInDb, setInvitations] = useState<any[]>([]);
  const {
    syncContact,
    contactsName,
    userContacts,
    follow,
    contactsNotInscrit,
    loading,
    mySubscriptionData,
    refetch,
    loadingFollow,
    blockedList,
    contactsImageNotInscrit,
  } = useUserContact();

  useEffect(() => {
    syncContact();
  }, []);

  return (
    <IonPage>
      {loading && <Loader />}
      <Header title="Pas encore inscrits"></Header>
      <IonContent scrollY={false} className="ion-padding font-outfit" forceOverscroll>
        <IonGrid
          style={{ height: '100vh' }}
          className="ion-justify-content-center ion-align-items-center width-p-95 ion-padding-top padding-x-10 bg-white border-radius"
        >
          <UserContactList
            virtuosoStyle={{ height: '90vh', border: '1px solid red' }}
            userContacts={[]}
            contactsName={contactsNotInscrit}
            follow={follow}
            mySubscriptionData={mySubscriptionData}
            refetch={refetch}
            loadingFollow={loadingFollow}
            type="notinscrit"
            loading={loading}
            invitationsInDb={invitationsInDb}
            setInvitations={setInvitations}
            contactsImageNotInscrit={contactsImageNotInscrit}
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default NewContact;
