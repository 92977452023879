import { IonCheckbox, IonLabel, IonPage, IonContent, IonButton, IonFooter, IonText, IonToast } from '@ionic/react';
import { useDispo } from '../../../hooks';
import { Header, StepperCount, CardCustom } from '../../../components';
import { useHistory } from 'react-router';
import { Enum_Event_Type, EventEntity, EventInput } from '../../../models/gql/graphql';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_EVENT } from '../../../graphql/queries/event.graphql';
import { UPDATE_EVENT } from '../../../graphql/mutations/update-event.graphql';
import './style.css';
import { FormProvider, useForm } from 'react-hook-form';
import succesIcon from '../../../assets/icons/circle-check.svg';

const StepOptions: React.FC<IStepProps> = ({ id, duplicate }) => {
  const { dispo, setDispo } = useDispo();
  const history = useHistory();
  const [defaultChecked, setChecked] = useState({
    allowUserToShare: false,
    useChat: false,
    beAccompanied: false,
  });
  const [openToast, setOpenToast] = useState<boolean>(false);
  const methods = useForm<EventInput>();
  const { setValue, handleSubmit } = methods;

  const handleChange = (checked: boolean, configOptions: string) => {
    setChecked({ ...defaultChecked, [configOptions]: checked });
    setDispo({ ...dispo, [configOptions]: checked });
  };

  const [saveEvent, { loading: loadingUpdateEvent }] = useMutation(UPDATE_EVENT);

  const updateEvent = async (dispoId: string) => {
    try {
      const { data: dataEvent } = await saveEvent({
        variables: {
          id: dispoId,
          data: {
            allowUserToShare:
              defaultChecked.allowUserToShare !== null
                ? defaultChecked.allowUserToShare
                : dispoDefault && dispoDefault.attributes
                ? !!dispoDefault.attributes.allowUserToShare
                : false,
            useChat:
              defaultChecked.useChat !== null
                ? defaultChecked.useChat
                : dispoDefault && dispoDefault.attributes
                ? !!dispoDefault.attributes.useChat
                : false,
            beAccompanied:
              defaultChecked.beAccompanied !== null
                ? defaultChecked.beAccompanied
                : dispoDefault && dispoDefault?.attributes
                ? !!dispoDefault.attributes.beAccompanied
                : false,
          },
        },
      });
      setOpenToast(true);
      history.push(`/main/dispos/${dispoId}/edit`);
    } catch (e) {
      console.log(e);
    }
  };

  const submitChange = () => {
    if (duplicate) {
      setDispo({
        ...dispo,
        allowUserToShare: defaultChecked.allowUserToShare,
        useChat: defaultChecked.useChat,
        beAccompanied: defaultChecked.beAccompanied,
      });
      setValue('allowUserToShare', defaultChecked.allowUserToShare);
      setValue('useChat', defaultChecked.useChat);
      history.push({
        pathname: `/main/home/create-dispo/preview`,
        search: `?${new URLSearchParams({ page: 'preview' }).toString()}`,
      });
    } else {
      if (!id) {
        setChecked({
          allowUserToShare: false,
          useChat: false,
          beAccompanied: false,
        });
        history.push('/main/home/create-dispo/preview');
        // setChecked({
        //   allowUserToShare: false,
        //   useChat: false
        // })
      } else {
        updateEvent(id);
      }
    }
  };

  const { data: dispoData, loading: loadingEvent } = useQuery(GET_EVENT, {
    variables: { id: id ? id : '0' },
  });

  const dispoDefault = useMemo(() => {
    return !loadingEvent ? dispoData?.event?.data : ([] as EventEntity);
  }, [loadingEvent, dispoData]);

  const beAccompanied = useMemo(() => {
    const typeDispo = dispo.type;
    return (
      (typeDispo === 'private' && dispo.participations && dispo.participations?.length > 50) ||
      (typeDispo === 'public' && dispo.maxInvited && dispo.maxInvited > 50) ||
      (id &&
        !duplicate &&
        ((dispoDefault?.attributes?.participations?.data.length &&
          dispoDefault?.attributes?.participations?.data.length > 50) ||
          (dispoDefault?.attributes?.maxInvited && dispoDefault?.attributes?.maxInvited > 50)))
    );
  }, [dispo, dispoDefault, id, duplicate]);

  useEffect(() => {
    if (dispoDefault && dispoDefault.attributes) {
      setChecked({
        ['useChat']: !!dispoDefault.attributes.useChat,
        ['allowUserToShare']: !!dispoDefault.attributes.allowUserToShare,
        ['beAccompanied']: !!dispoDefault.attributes.beAccompanied,
      });
    }
  }, [dispoDefault]);

  const headerTitle = duplicate ? 'Duplication de ta dispo' : !id ? 'Création de dispo' : 'Editer les options';
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitChange)}>
        <IonPage>
          <Header title={headerTitle} noButtonBack={false} />
          {(!id || duplicate) && <StepperCount length={5} numberStep={5}></StepperCount>}
          <IonContent>
            <CardCustom margin={true}>
              <IonText className="font-outfit" color="dark">
                {(!id || duplicate) && <h3>Options</h3>}
              </IonText>

              <div className="check-box-custom">
                {(dispo.type === Enum_Event_Type.Private ||
                  (dispoDefault &&
                    dispoDefault.attributes &&
                    dispoDefault.attributes.type === Enum_Event_Type.Private)) && (
                  <IonCheckbox
                    mode="md"
                    labelPlacement="end"
                    className="ion-margin-bottom"
                    checked={
                      defaultChecked.allowUserToShare !== null
                        ? defaultChecked.allowUserToShare
                        : dispoDefault && dispoDefault.attributes
                        ? !!dispoDefault.attributes.allowUserToShare
                        : false
                    }
                    onIonChange={(e) => handleChange(e.detail.checked, 'allowUserToShare')}
                  >
                    <IonLabel className="font-outfit label-checkbox" text-wrap>
                      Autoriser les invités à partager l'événement
                    </IonLabel>
                  </IonCheckbox>
                )}
                {!id && (
                  <IonCheckbox
                    mode="md"
                    labelPlacement="end"
                    onIonChange={(e) => handleChange(e.detail.checked, 'useChat')}
                    checked={
                      defaultChecked.useChat !== null
                        ? defaultChecked.useChat
                        : dispoDefault && dispoDefault.attributes
                        ? !!dispoDefault.attributes.useChat
                        : false
                    }
                  >
                    <IonLabel className="font-outfit label-checkbox">Activer le chat</IonLabel>
                  </IonCheckbox>
                )}
                {!!beAccompanied && (
                  <IonCheckbox
                    mode="md"
                    labelPlacement="end"
                    className="ion-margin-bottom"
                    checked={!!defaultChecked.beAccompanied}
                    onIonChange={(e) => handleChange(e.detail.checked, 'beAccompanied')}
                  >
                    <IonLabel className="font-outfit label-checkbox" text-wrap>
                      Je souhaite être accompagné pour la création de votre évènement
                    </IonLabel>
                  </IonCheckbox>
                )}
              </div>
            </CardCustom>
          </IonContent>
          <IonFooter className="ion-no-border ion-padding">
            <IonButton type="submit" expand="block" color="primary" className="not-transorm-text text-vw-4">
              {!id || duplicate ? 'Continuer' : 'Sauvegarder les modifications'}
            </IonButton>
          </IonFooter>
          <IonToast
            isOpen={openToast}
            message="Modifications effectuées avec succès"
            duration={5000}
            icon={succesIcon}
          ></IonToast>
        </IonPage>
      </form>
    </FormProvider>
  );
};

export default StepOptions;
