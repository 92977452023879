import { graphql } from '../../models/gql';
export const GET_NOTIFICATIONS = graphql(/* GraphQL */ `
  query notifications($filters: NotificationFiltersInput, $pagination: PaginationArg, $sort: [String]) {
    notifications(filters: $filters, sort: $sort, pagination: $pagination) {
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
      data {
        id
        attributes {
          type
          sent
          seen
          createdAt
          additionalData
          actionTo {
            data {
              id
              attributes {
                username
                isPro
                companyName
              }
            }
          }
          actionFrom {
            data {
              id
              attributes {
                username
                companyName
                isPro
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          attributes {
                            name
                            url
                            mime
                            hash
                            size
                            provider
                          }
                        }
                      }
                    }
                  }
                }
                profilePicture {
                  data {
                    id
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
              }
            }
          }
          event {
            data {
              id
              attributes {
                name
                eventDate
                eventEndDate
                startHour
                endHour
                type
                addressCity
                addressLine
                description
                expired
                coverCustomImage {
                  data {
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
                coverTedispoImage {
                  data {
                    attributes {
                      image {
                        data {
                          attributes {
                            name
                            url
                            mime
                            hash
                            size
                            provider
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          publication {
            data {
              id
              attributes {
                description
                medias {
                  data {
                    id
                    attributes {
                      url
                      mime
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);
