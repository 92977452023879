import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/react';
import { Enum_Notification_Type, EventEntity, PostEntity } from '../../models/gql/graphql';
import { getEventDateData } from '../../pages/DispoDetail/utils';
import { useHistory } from 'react-router';
import './style.css';
import { StringToHtml } from '../Algolia/CardPost/StringToHtml';
import { useState } from 'react';
interface IDispoProps {
  post?: PostEntity;
  withTag?: boolean;
  type: Enum_Notification_Type | null | undefined;
}

const CardPostMinimal: React.FC<IDispoProps> = ({ post, withTag, type }) => {
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);
  const media = post?.attributes?.medias?.data.length ? post?.attributes?.medias?.data[0] : null;
  const handleNotificationRedirection = () => {
    if (type && type === Enum_Notification_Type.NewComment) {
      history.push('/main/post/' + post?.id + '?open=' + post?.id);
    } else if (type !== 'post_deleted') {
      history.push('/main/post/' + post?.id);
    }
    return;
  };
  return (
    <IonCard className="ion-no-margin ion-no-padding border-card-minimal" onClick={handleNotificationRedirection}>
      <IonGrid className="ion-no-padding">
        <IonRow>
          {media && media.attributes?.mime.includes('image') && (
            <IonCol className="d-flex-notification ion-no-padding border-right-image" size="4">
              <img alt={post?.id!} src={media.attributes?.url} className="fill-image" />
            </IonCol>
          )}
          <IonCol size="8" className="padding-card-minimal">
            <IonCardHeader className="ion-no-padding header-title-style">
              <IonCardSubtitle className="font-card-minimal font-outfit margin-card-city-sm">
                <IonText className="text-capitalize">
                  {StringToHtml(post?.attributes?.description || '', showMore, setShowMore)}
                </IonText>
              </IonCardSubtitle>
              {/* <IonCardSubtitle className="font-card-minimal">
                Createur : {dispo?.attributes?.creator?.data?.attributes?.username}
              </IonCardSubtitle> */}
            </IonCardHeader>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default CardPostMinimal;
