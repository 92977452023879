import { graphql } from '../../models/gql';
export const GET_EVENTS_WEATHERS = graphql(/* GraphQL */ `
  query eventWeathers($filters: EventWeatherFiltersInput, $pagination: PaginationArg) {
    eventWeathers(filters: $filters, pagination: $pagination) {
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
      data {
        id
        attributes {
          weatherCondition
          dispo_id
          data
          date
        }
      }
    }
  }
`);
