import {
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSearchbar,
  SearchbarInputEventDetail,
} from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import SimpleHeader from '../../../components/SimpleHeader';
import { useSubscriptions } from '../../../hooks/useUserFollower';
import { UserFollowerEntity } from '../../../models/gql/graphql';
import { getUserId } from '../../../utils';
import { UserDetail } from '../../DispoDetail/components/UserDetail';
import '../style.css';
import { IonSearchbarCustomEvent } from '@ionic/core/dist/types/components';
import { NativePageTransitions } from '@awesome-cordova-plugins/native-page-transitions';

const SubscriptionsFollowers: React.FC = () => {
  const { state } = useLocation<any>();
  const history = useHistory();
  const id = getUserId();
  const [username, setUserName] = useState<string>('');
  const userSubscriptions = useSubscriptions(id || '0', username);

  const listSubscriptions = useMemo(() => {
    if (!state?.data) {
      return [];
    }
    if (username) {
      return state.data.filter((i: any) =>
        i.attributes.follower.data.attributes.username.toLowerCase().includes(username.toLowerCase())
      );
    }
    return state?.data || [];
  }, [state, username]);

  const getUserName = (subscription: UserFollowerEntity) => {
    const userData = subscription.attributes?.follower?.data?.attributes;
    return userData?.username ? userData?.username : '';
  };

  const handleSearch = (event: IonSearchbarCustomEvent<SearchbarInputEventDetail>) => {
    if (event && event.target.value) {
      setUserName(event.target.value);
    } else {
      setUserName('');
    }
    return;
  };

  return (
    <IonPage>
      <SimpleHeader
        title={`${'Tes abonnés'}`}
        noButtonBack={false}
        showMenuButton={true}
        backAction={async () => {
          history.goBack();
          const stack = await NativePageTransitions.slide({
            direction: 'right',
            duration: 0,
            androiddelay: 0,
          });
        }}
        backButtonColor="text-black"
      ></SimpleHeader>
      <IonContent>
        <div className="bg-primary full-height">
          <IonGrid className="margin-auto width-p-95 full-height bg-white border-radius ion-no-padding">
            <IonSearchbar
              debounce={700}
              mode="md"
              placeholder="Recherche"
              className={'margin-t-5'}
              onIonInput={handleSearch}
            />
            {userSubscriptions &&
              listSubscriptions &&
              listSubscriptions.length > 0 &&
              listSubscriptions?.map((subscription: any, index: any) => (
                <IonList lines="none" className="ion-no-padding padding-b-10" key={index}>
                  <IonItem>
                    <UserDetail
                      name={getUserName(subscription)}
                      member={subscription.attributes.follower.data.attributes}
                      avatarStyle="width-30 height-30"
                      idMember={subscription.attributes.follower.data.id}
                      icon={arrowForwardOutline}
                      iconClass="text-blue"
                    />
                  </IonItem>
                </IonList>
              ))}
            {(!listSubscriptions || listSubscriptions.length === 0) && !userSubscriptions.isLoading && (
              <IonRow class="ion-justify-content-center font-outfit margin-t-5">
                <IonLabel>Aucun utilisateur ne correspond à votre recherche.</IonLabel>
              </IonRow>
            )}
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SubscriptionsFollowers;
