import { IonLabel, IonRow } from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { UseInfiniteHitsProps, useInfiniteHits, useInstantSearch } from 'react-instantsearch';
import { transformEventDate } from '../../../pages/DispoDetail/utils';
import { useAppStore } from '../../../store';
import CardDispoPublic from '../CardDispoPublic';

interface SearchInfiniteHitsProps extends UseInfiniteHitsProps {
  from?: string;
}
function SearchInfiniteHits(props: SearchInfiniteHitsProps) {
  const { from } = props;
  const { hits, showMore, isLastPage } = useInfiniteHits(props);
  const {
    RootStore: { loadWeatherQuery, weatherInfo },
  } = useAppStore();
  const { status } = useInstantSearch();
  const sentinelRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  /*useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            console.log('showMore(SearchInfiniteHits)');
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);*/

  useEffect(() => {
    if (hits.length > 0 && isLoading) {
      loadWeatherQuery({
        variables: {
          filters: {
            dispo_id: { in: hits.map((i: any) => parseInt(i.id, 10)) },
          },
        },
      });
      setIsLoading(false);
    }
  }, [hits, isLoading]);

  return (
    <div>
      {hits.map((hit: any) => (
        <CardDispoPublic
          key={hit.objectID}
          name={hit.name}
          start={hit.start}
          end={hit.end}
          addressCity={hit.addressCity}
          addressLine={hit.addressLine}
          cover={hit.cover}
          creatorPicture={hit.creatorPicture}
          creator={hit.creator}
          id={hit.id}
          creatorFirstname={hit.creatorFirstname}
          creatorLastname={hit.creatorLastname}
          creatorUsername={hit.creatorUsername}
          status={hit.status}
          dispoDate={transformEventDate(hit.start, hit.end) as IDate}
          type={hit.type}
          pending={hit.pending}
          weatherInfo={weatherInfo ? weatherInfo[hit.id] : ''}
        />
      ))}
      {!hits.length && !isLoading && status !== 'stalled' && status !== 'loading' && from !== 'profil' && (
        <IonRow class="ion-justify-content-center font-outfit">
          <IonLabel>Aucune dispo ne correspond à ta recherche.</IonLabel>
        </IonRow>
      )}
      <IonRow className="sentinel" ref={sentinelRef} />
    </div>
  );
}
export default SearchInfiniteHits;
