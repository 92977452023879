export const createLastSenderData = (user: any) => {
  let lastSender: any = {};
  lastSender.id = user.id;
  lastSender.firstname = user?.firstname || user?.attributes?.firstname || '';
  lastSender.lastname = user?.lastname || user?.attributes?.lastname || '';
  lastSender.username =
    (user.attributes
      ? user?.attributes?.isPro
        ? user?.attributes?.companyName
        : user?.attributes?.username
      : user?.isPro
      ? user?.companyName
      : user?.username) || '';
  lastSender.photoUrl =
    user?.profilePicture?.attributes?.url ||
    user?.profilePicture?.data?.attributes?.url ||
    user?.avatar?.attributes?.image?.data?.attributes?.url;
  return lastSender;
};

export const getDispoImageUrl = (dispo: any) => {
  const imageUrl = dispo?.attributes?.coverCustomImage?.data
    ? dispo?.attributes?.coverCustomImage?.data?.attributes?.url
    : dispo?.attributes?.coverTedispoImage?.data
    ? dispo?.attributes?.coverTedispoImage?.data?.attributes?.image?.data?.attributes?.url
    : 'https://ionicframework.com/docs/img/demos/avatar.svg';
  return imageUrl;
};

export const getChatUserData = (user: any) => {
  const data = {
    id: user.id,
    firstname: user?.firstname || user?.attributes?.firstname,
    lastname: user?.lastname || user?.attributes?.lastname,
    username:
      (user.attributes
        ? user?.attributes?.isPro
          ? user?.attributes?.companyName
          : user?.attributes?.username
        : user?.isPro
        ? user?.companyName
        : user?.username) || '',
    photoUrl:
      user?.attributes?.profilePicture?.data?.attributes?.url ||
      user?.attributes?.avatar?.data?.attributes?.image?.data?.attributes?.url,
  };
  return data;
};
