import { Keyboard } from '@capacitor/keyboard';
import { IonCol, IonGrid, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import Input, {
  Country,
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input/input';
import fr from 'react-phone-number-input/locale/fr.json';
import 'react-phone-number-input/style.css';
import './style.css';

interface IInput {
  handleChange?: (e: any) => void;
  label?: string;
  defaultValue?: string;
  setValidate?: (isValid: boolean) => void;
}

const InputCustom: React.FC<IInput> = ({ label, handleChange, defaultValue, setValidate }) => {
  const [countrySelected, setCountrySelected] = useState<Country>('FR');
  const [phone, setPhone] = useState<string>('');
  const selectInput = useRef(null);
  const clickSelect = () => {
    (selectInput as any)?.current?.select!;
    console.log('click');
  };

  useEffect(() => {
    setCountrySelected('FR');
    setPhone('');
    Keyboard.show();
  }, []);

  return (
    <>
      {!!label && <p className="custom-label font-outfit">{label}</p>}
      <IonGrid className="ion-no-padding">
        <IonRow className="ion-no-padding">
          <IonCol size="auto" className="ion-no-padding code-parent-custom">
            <div className="d-flex" onClick={clickSelect}>
              <img
                className="flag-icon"
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countrySelected}.svg`}
                width="21"
                alt=""
              />
              <p className="country-code font-outfit">+{getCountryCallingCode(countrySelected)}</p>
              <IonSelect
                className="select-hidden"
                aria-label="country"
                interface="action-sheet"
                placeholder="Select country"
                mode="ios"
                interfaceOptions={{ cssClass: 'select-data' }}
                onIonChange={(e) => setCountrySelected(e.detail.value)}
              >
                {getCountries().map((country, index) => (
                  <IonSelectOption className="select" key={index} value={country}>
                    {fr[country]} +{getCountryCallingCode(country)}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
          </IonCol>
          <IonCol className="d-flex input-parent-custom">
            <Input
              international
              defaultCountry="FR"
              country={countrySelected}
              onChange={(phone: string) => {
                setPhone(phone);
                if (handleChange) handleChange(phone);
                if (setValidate) setValidate(isValidPhoneNumber(phone));
              }}
              value={defaultValue}
              className="input-phone-custom font-outfit"
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default InputCustom;
