import { graphql } from '../../models/gql';
export const GET_INTERESTS = graphql(/* GraphQL */ `
  query interests {
    interests(pagination: { limit: 100 }) {
      data {
        id
        attributes {
          name
          picto {
            data {
              id
              attributes {
                name
                url
                mime
                hash
                size
                provider
              }
            }
          }
        }
      }
    }
  }
`);
