import { IonContent, IonPage, IonButton } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { Header, CardCustom, ValidatedIcon } from '../../../../components';
import { useHistory } from 'react-router-dom';
import '../style.css';

const NoPhone: React.FC = () => {
  const history = useHistory();
  const goTo = (path: string) => {
    history.push(path);
  };
  return (
    <IonPage>
      <Header title="Connexion" />
      <IonContent className="ion-padding">
        <CardCustom>
          <ValidatedIcon icon={closeOutline}></ValidatedIcon>
          <p className="ion-text-center margin-auto font-outfit font-description">
            Le numéro de téléphone renseigné correspond a aucun compte Tedispo
          </p>
          <IonButton
            type="submit"
            className="ion-margin-top"
            expand="block"
            color="primary"
            onClick={() => goTo('/signup/choose')}
          >
            Creer un compte
          </IonButton>
        </CardCustom>
      </IonContent>
    </IonPage>
  );
};

export default NoPhone;
