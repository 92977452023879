import flagSvg from '../../assets/icons/circle-check.svg';
import userCloseSvg from '../../assets/icons/user-close.svg';
import './style.css';
import { IonIcon, IonLabel, IonProgressBar } from '@ionic/react';
import { useToast } from '../../hooks';
import { ReactNode, useEffect, useState } from 'react';

const toastTypes: any = {
  success: {
    icon: flagSvg,
    iconClass: 'success-icon',
    progressBar: 'secondary',
  },
  info: {
    icon: userCloseSvg,
    iconClass: 'success-icon',
    progressBar: 'secondary',
  },
  //TODO: update icon
  // warning: {
  //     icon: flagSvg,
  //     iconClass: "warning-icon",
  //     progressBar: "warning",
  // },
  // info: {
  //     icon: flagSvg,
  //     iconClass: "info-icon",
  //     progressBar: "info",
  // },
  // error: {
  //     icon: flagSvg,
  //     iconClass: "error-icon",
  //     progressBar: "danger",
  // },
};

const Toast = ({
  message,
  type,
  id,
  duration,
  customIcon,
}: {
  message: string;
  type: string;
  id: string;
  duration: number;
  customIcon?: ReactNode;
}) => {
  const toast = useToast();
  const [progressValue, setProgressValue] = useState(100);
  const handleDismiss = () => {
    toast.remove(id);
  };

  useEffect(() => {
    const startTime = Date.now();
    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.max(0, 1 - elapsedTime / duration);
      setProgressValue(progress);
      if (progress <= 0) {
        handleDismiss();
        clearInterval(interval);
      }
    }, 100);
  }, [duration]);
  if (!toastTypes[type as any]) {
    type = 'info';
  }
  const { icon, progressBar } = toastTypes[type as any];
  return (
    <div className="toast">
      <IonIcon size={'large'} icon={customIcon ? customIcon : icon}></IonIcon>
      <IonLabel className="toast-message">{message}</IonLabel>
      {/* Toast Progress Bar */}
      <div className="toast-progress">
        <IonProgressBar value={progressValue} color={progressBar} />
      </div>
    </div>
  );
};

export default Toast;
