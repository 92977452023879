import { SafeArea } from 'capacitor-plugin-safe-area';

export const setSafeArea = async () => {
  const eventListener = await SafeArea.addListener('safeAreaChanged', (data) => {
    const { insets } = data;
    for (const [key, value] of Object.entries(insets)) {
      const valueToAdd: number = window.location.pathname && window.location.pathname == '/on-boarding' ? 50 : 20;
      if (key === 'top') {
        document.documentElement.style.setProperty(`--ion-safe-area-top`, `${value + valueToAdd}px`);
      }
      if (key === 'bottom') {
        document.documentElement.style.setProperty(`--ion-safe-area-bottom`, `${value + valueToAdd}px`);
      }
    }
  });
  eventListener.remove();
};
