/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Capacitor, Plugins } from '@capacitor/core';
import { IonButton, IonDatetime, IonDatetimeButton, IonIcon, IonInput, IonModal } from '@ionic/react';
import { format, parse } from 'date-fns';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import './AppDatepicker.css';
import { addHourToDate, convertISO8601LocalwZtoUTC, convertISO8601UTCtoLocalwZ } from '../../utils/dateFormat';

export interface IAppDatepickerProps {
  name: string;
  label: string;
  initialValue?: any;
  icon?: any;
  min?: Date;
  max?: Date;
  presentation?: 'month-year' | 'date' | 'date-time' | 'month' | 'time' | 'time-date' | 'year';
  displayFormat: string; // eg: 'MMM dd yyyy'
  preferWheel?: boolean;
  disabled?: boolean;
  onChange?: (e: any) => void;
  showValidateButton?: boolean;
  yearValues?: Array<number>;
  type?: string;
}

const DatePicker = Plugins.DatePickerPlugin as any;
const selectedTheme = 'light';

const AppDatepicker: FC<IAppDatepickerProps> = ({
  name,
  label,
  preferWheel,
  initialValue,
  icon,
  min,
  max,
  presentation,
  disabled,
  displayFormat = 'dd/MM/yyyy',
  onChange,
  showValidateButton = false,
  yearValues,
  type,
}: IAppDatepickerProps) => {
  const [popoverDate, setPopoverDate] = useState('');
  const [value, setValue] = useState<string | undefined>(new Date().toISOString());
  const modalRef = useRef<HTMLIonModalElement>(null);
  const [changed, setChanged] = useState<boolean>(false);

  // format for popoverDate
  const formatDate = useCallback(
    (val: string) => {
      const parsedDate = parse(val, displayFormat, new Date());
      if (displayFormat === 'MM/yyyy') {
        setValue(val);
        return format(new Date(val), displayFormat);
      }
      if (new Date(val) instanceof Date) {
        return format(new Date(val), displayFormat);
      }
      const newVal = format(new Date(parsedDate), displayFormat);
      return format(new Date(newVal), displayFormat);
    },
    [displayFormat, displayFormat]
  );

  const updateDateValue = (e: any) => {
    setChanged(true);
    const date = addHourToDate(e.target.value);
    setValue(date.toISOString());
    setPopoverDate(formatDate(e.target.value));
    if (onChange) onChange(e);
  };

  useEffect(() => {
    if (initialValue) {
      setPopoverDate(formatDate(initialValue));
      if (onChange) onChange(initialValue);
    }
    return () => {
      setPopoverDate('');
    };
  }, [initialValue, formatDate, name]);

  const openNativePicker = async () => {
    if (Capacitor.isNativePlatform() && presentation === 'date') {
      const date = await DatePicker.present({
        mode: 'date',
        // min: min ? min.toISOString() : undefined,
        // max: max ? max.toISOString() : undefined,
        locale: 'fr_FR',
        format: displayFormat,
        date: popoverDate || undefined,
        theme: selectedTheme,
      });

      if (date) {
        const parsedDate = parse(date.value, displayFormat, new Date());
        const val = format(new Date(parsedDate), displayFormat);
        setPopoverDate(formatDate(parsedDate.toISOString()));
        setValue(parsedDate.toISOString());
      }
    }
  };

  const openModal = () => {
    if (modalRef.current) {
      modalRef.current.present();
    }
  };

  const id = Math.floor(Math.random() * 1000000).toString();

  return (
    <>
      <p className="custom-label font-inter">{label}</p>
      <div id={name} className={type ? 'position-parent-relative' : 'd-flex-container ionic_custom_input custom-input'}>
        <IonDatetimeButton
          datetime={id}
          className={
            type
              ? type === 'first'
                ? 'ion-justify-content-start date-time-button-custom border-radius-left useOne'
                : 'ion-justify-content-start date-time-button-custom border-radius-right'
              : 'col-3 ion-justify-content-start'
          }
          disabled={disabled}
        >
          <IonInput
            placeholder={'01/01/1990'}
            disabled={disabled}
            slot="date-target"
            mode="md"
            readonly
            value={popoverDate}
            type="text"
            className="ion-text-left font-outfit"
          />
        </IonDatetimeButton>
        {icon ? (
          <IonIcon
            className={`margin-auto-container icon-date-size ${type ? ' position-icon' : ''}`}
            slot={icon?.slot}
            icon={icon?.icon}
            onClick={(e) => (icon?.onClick ? icon?.onClick(e) : openModal())}
          />
        ) : null}
        <IonModal ref={modalRef} keepContentsMounted>
          <IonDatetime
            id={id}
            preferWheel={preferWheel}
            mode="ios"
            size="fixed"
            name={name}
            min={min ? min?.toISOString() : undefined}
            value={initialValue && !changed ? new Date(initialValue).toISOString() : value}
            max={max ? max?.toISOString() : undefined}
            color="primary"
            presentation={presentation || 'month-year'}
            onIonChange={(e) => updateDateValue(e)}
            yearValues={yearValues}
          />
          {showValidateButton && (
            <IonButton
              disabled={!!!value}
              className="ion-margin-top"
              expand="block"
              color="primary"
              onClick={() => {
                setChanged(false);
                if (modalRef.current) {
                  modalRef.current.dismiss();
                }
              }}
            >
              Valider
            </IonButton>
          )}
        </IonModal>
      </div>
      {/* errors && errors[name] && <IonText className="ion-padding" color="danger"><small>{errors[name]?.message}</small></IonText> */}
    </>
  );
};

export default AppDatepicker;
