import { graphql } from '../../models/gql';

export const CREATE_REPORT = graphql(/* GraphQL */ `
  mutation createReport($data: ReportInput!) {
    createReport(data: $data) {
      data {
        id
        attributes {
          type
          status
          category
          reportedBy {
            data {
              id
            }
          }
        }
      }
    }
  }
`);
