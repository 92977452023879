import { FC } from 'react';
import './style.css';
import { IonButton, IonIcon, IonImg } from '@ionic/react';
import yellowCloseIcon from '../../../../assets/icons/yellowClose.svg';
import { PickedFile } from '@capawesome/capacitor-file-picker';

interface IProps {
  fileUrl: string;
  onDeleteFile: any;
  index: number;
}

const ImageItem: FC<IProps> = ({ fileUrl, onDeleteFile, index }) => {
  const handleDelete = () => {
    onDeleteFile(index);
  };
  return (
    <div className="chat__message-box__files__img-container">
      <IonImg className="chat__message-box__files__img-container__img" src={fileUrl} alt="Uploaded file" />
      <IonButton
        className="chat__msg-file__image-close-btn-container ion-no-padding"
        onClick={handleDelete}
        fill="clear"
      >
        <IonIcon size="small" icon={yellowCloseIcon} />
      </IonButton>
    </div>
  );
};

export default ImageItem;
