/* Use this component as reference and guide line when creating components or pages */
import React, { useEffect, useState } from 'react';
import { IonContent, IonLabel } from '@ionic/react';
import styled from 'styled-components';
import { RefinementList, Configure, useInstantSearch, Index } from 'react-instantsearch';
import { CustomRefresh, SearchInfiniteHits } from '../../../components/Algolia';
import { getUserId, isAuthenticated } from '../../../utils';
import { buildMainFilter } from './utils';
import { useAppStore } from '../../../store';
import NoResultsBoundary from '../../../components/Algolia/NoResultBoundary';
import NbResultsBoundary from '../../../components/Algolia/NbResultBoundary';
import { useDispo } from '../../../hooks';
import { SortBy } from 'react-instantsearch';

const HITS_PER_PAGE = 20;

/**
 * SearchResult page component
 * @param {string} className            (Optional) Override className internal configuration
 * @returns                             SearchResult react component
 */
const SearchResult: React.FC<any> = ({
  className,
  filterData: initialFilterData,
  position,
  radius,
  query,
  noResultComponent,
  followups,
  isProfil = false,
  from = '',
}) => {
  const [aroundLatLng, setAroundLatLng] = useState<string>();
  const {
    RootStore: { algoliaCache, refreshDispos, setRefreshDispos, userData, getUserData },
  } = useAppStore();
  const { status, refresh } = useInstantSearch();
  const { setReloadHome, reloadHome } = useDispo();
  const [mainFilters, setMainFilters] = useState<any>(() => {
    const filterData: any = {
      expired: false,
      disabled: false,
      deleted: false,
      pending: false,
      custom: `(type:public OR  targets:${getUserId()})`,
    };
    if (followups && followups.length) {
      filterData.creator = followups;
    }
    return buildMainFilter(filterData);
  });

  useEffect(() => {
    const subData: any = {};
    if (initialFilterData.creator && isProfil) {
      subData.creator = initialFilterData.creator;
    }
    if (followups && followups.length) {
      subData.creator = followups;
    }
    const data: any = {
      ...initialFilterData,
      expired: false,
      disabled: false,
      pending: false,
      deleted: false,
      custom: `(type:public OR targets:${getUserId()})`,
    };
    if (isAuthenticated() && userData) {
      const blocked = [
        ...(userData?.blockedUsers?.map((i: any) => i?.id) || []),
        ...(userData?.blockedByUsers?.map((i: any) => i?.id) || []),
      ];
      if (blocked.length) {
        data.blockedCreator = blocked;
      }
    }

    if (initialFilterData.creator && initialFilterData.creator.toString() === getUserId()?.toString()) {
      data.custom = undefined;
      delete data.custom;
    }

    setMainFilters(buildMainFilter(data));
  }, []);

  useEffect(() => {
    if (position && position.lat) {
      setAroundLatLng(`${position.lat}, ${position.lng}`);
    } else {
      setAroundLatLng(undefined);
    }
  }, [position]);

  useEffect(() => {
    if (refreshDispos) {
      refresh();
      setRefreshDispos(false);
      getUserData(true);
    }
  }, [refreshDispos]);

  useEffect(() => {
    if (reloadHome) {
      refresh();
      setReloadHome(false);
      getUserData(true);
    }
  }, [reloadHome]);

  return (
    <SearchResultWrapper className={`${className || ''}`}>
      <Index indexName={process.env.REACT_APP_ALGOLIA_INDEX + '_replicas_date_asc'}>
        <CustomRefresh />
        <Configure
          filters={mainFilters}
          aroundLatLng={aroundLatLng}
          aroundRadius={radius}
          hitsPerPage={HITS_PER_PAGE}
          page={0}
          query={query}
          analytics
          getRankingInfo
          distinct
        />
        {/*<SortBy
        items={[
          { label: 'Date (asc)', value: process.env.REACT_APP_ALGOLIA_INDEX + '_replicas_date_asc' },
          { label: 'date (desc)', value: process.env.REACT_APP_ALGOLIA_INDEX + '_replicas_date_desc' },
        ]}
      /> */}

        <NoResultsBoundary
          fallback={
            noResultComponent ? noResultComponent : <IonLabel>Aucune dispo ne correspond à ta recherche.</IonLabel>
          }
        ></NoResultsBoundary>
        <SearchInfiniteHits cache={algoliaCache} from={from} />
      </Index>
    </SearchResultWrapper>
  );
};

export const SearchResultWrapper = styled('div')``;

export default SearchResult;
