import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonMenu, IonRow, IonSpinner } from '@ionic/react';
import { menuController } from '@ionic/core/components';

import chatSearchIcon from '../../../assets/icons/chatSearch.svg';
import bellIcon from '../../../assets/icons/bell.svg';
import './style.css';
import CardChatDispo from '../../CardChatDispo';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_CHAT_NOTIFICATION_SETTING } from '../../../graphql/queries/getUser.graphql';
import { UPDATE_USER } from '../../../graphql/mutations/updateUser..graphql';
import { useHistory } from 'react-router';
import { useEffect, useRef, useState } from 'react';

interface IHeader {
  openSearchBox: () => void;
  openNotificationModal: () => void;
  reactiveNotification: () => any;
  dispo?: any;
  userId: string;
  chatId: string;
  mutedChats?: any;
  participants: any[];
}

const ChatHeader: React.FC<IHeader> = ({
  openSearchBox,
  openNotificationModal,
  dispo,
  userId,
  chatId,
  mutedChats,
  reactiveNotification,
  participants,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();
  const menuRef = useRef<HTMLIonMenuElement>(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    const menuElement = menuRef.current;
    if (menuElement && !menuElement.contains(event.target as Node)) {
      closeMenu();
    }
  };

  const { data: userData, loading: loadingEvent } = useQuery(GET_USER_CHAT_NOTIFICATION_SETTING, {
    variables: { id: userId || 0 },
    skip: !userId,
  });

  const [updateUser, { loading: loadingMessage }] = useMutation(UPDATE_USER);

  const muteData = mutedChats?.find((item: any) => item?.message?.data?.attributes?.firebaseId === chatId);

  const handleSearch = async () => {
    await closeMenu();
    openSearchBox();
  };

  const handleOpenNotificationModal = async () => {
    // await closeMenu();
    if (muteData) {
      setLoading(true);
      reactiveNotification().finally(() => {
        setLoading(false);
      });
    } else {
      openNotificationModal();
    }
  };

  const closeMenu = async () => {
    await menuController.close('chat-menu');
  };

  const goToDispo = async () => {
    await menuController.close('chat-menu');
    history.push(`/main/dispos/${dispo.id}`);
  };

  const participantText = participants?.length > 1 ? 'participants' : 'participant';

  return (
    <IonMenu swipeGesture={false} menuId="chat-menu" side="end" contentId="main-content" ref={menuRef}>
      <IonContent className="ion-padding">
        <div className="chat__drawer__content-content">
          {dispo && (
            <>
              <CardChatDispo classname="chat__drawer__dispo-card" dispo={dispo} />
              <IonRow>
                <IonButton onClick={goToDispo} className="chat__drawer__custom-ion-button" mode="ios">
                  Voir la dispo
                </IonButton>
              </IonRow>
            </>
          )}
          {dispo && participants?.length > 0 && (
            <IonRow className="chat__drawer__participants-row">
              <span className="chat__drawer__participants-row__content">
                {participants.length} {participantText}
              </span>
            </IonRow>
          )}
          <IonItem lines="none" className="chat__drawer__setting-label">
            <IonLabel className="chat__drawer__silent-label">Paramètres</IonLabel>
          </IonItem>
          <IonItem className="chat__drawer__silent-container" onClick={handleOpenNotificationModal}>
            <IonLabel className="chat__drawer__silent-label">
              {muteData ? 'Réactiver les notifications' : 'Mettre en sourdine'}
            </IonLabel>
            <IonButton fill="clear">
              {loading ? <IonSpinner name="crescent" /> : <IonIcon icon={bellIcon} slot="end"></IonIcon>}
            </IonButton>
          </IonItem>
          <IonItem lines="none" onClick={handleSearch}>
            <IonLabel className="chat__drawer__silent-label">Rechercher</IonLabel>
            <IonButton fill="clear">
              <IonIcon icon={chatSearchIcon} slot="end"></IonIcon>
            </IonButton>
          </IonItem>
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default ChatHeader;
