import { CardDispo, CardLoader, SegmentCustom } from '../../../../components';
import { GET_EVENTS } from '../../../../graphql/queries/events.graphql';
import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from '@ionic/react';
import { useAppStore } from '../../../../store';
import { useHistory, useLocation } from 'react-router';

interface IParams {
  id: string;
}
enum Enum_filterType {
  future = 'future',
  past = 'past',
}
interface IEventData {
  future?: {
    page?: number;
    nbPages?: number;
    data: any[];
  };
  past?: {
    page?: number;
    nbPages?: number;
    data: any[];
  };
}
const PAGE_SIZE = 10;
const InitialValue: IEventData = {
  future: {
    page: 1,
    nbPages: 0,
    data: [],
  },
  past: {
    page: 1,
    nbPages: 0,
    data: [],
  },
};
const Dispos: React.FC<IParams> = ({ id }) => {
  const {
    RootStore: { getUserData, weatherInfo, loadWeatherQuery },
  } = useAppStore();
  const location = useLocation();
  const history = useHistory();
  const [getEvents, { loading, refetch }] = useLazyQuery(GET_EVENTS);
  const [dispos, setDispos] = useState<IEventData>(InitialValue);
  const [userData, setUserData] = useState<any>();
  const [segmentValue, setSegmentValue] = useState<Enum_filterType>(Enum_filterType.future);
  const segmentData: IdNameEntity[] = [
    {
      id: Enum_filterType.future,
      name: 'A venir',
    },
    {
      id: Enum_filterType.past,
      name: 'Passés',
    },
  ];
  const loadEvents = async (page: number, expired: boolean) => {
    const options: any = {
      variables: {
        pagination: {
          page,
          pageSize: PAGE_SIZE,
        },
        filters: {
          creator: {
            id: {
              eq: id,
            },
          },
        },
        sort: ['eventDate:desc'],
      },
    };
    if (expired) {
      options.variables.filters.expired = { eq: true };
    } else {
      options.variables.filters.or = [{ expired: { null: true } }, { expired: { eq: false } }];
    }
    const { data } = await getEvents(options);
    if (data?.events?.data?.length) {
      setDispos((d: any) => {
        if (expired) {
          return {
            ...d,
            past: {
              page: data?.events?.meta?.pagination?.page,
              nbPages: data?.events?.meta?.pagination?.pageCount,
              data: page <= 1 ? data?.events?.data : [...d?.past?.data!, ...data?.events?.data!],
            },
          };
        }
        loadWeatherQuery({
          variables: {
            filters: {
              dispo_id: { in: data.events?.data.map((i: any) => parseInt(i.id, 10)) },
            },
          },
        });
        return {
          ...d,
          future: {
            page: data?.events?.meta?.pagination?.page,
            nbPages: data?.events?.meta?.pagination?.pageCount,
            data: page <= 1 ? data?.events?.data : [...d.future?.data!, ...data?.events?.data!],
          },
        };
      });
    }
  };

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    setDispos((d: any) => {
      d[segmentValue] = {
        page: 1,
        nbPages: 0,
        data: [],
      };
      return d;
    });
    await loadEvents(1, segmentValue === 'past');
    await refetch();
    if (event.detail) {
      event.detail.complete();
    }
  };
  const showMore = useCallback(
    async (ev: any) => {
      const currentPage = dispos[segmentValue]?.page;
      setDispos((d: any) => {
        return {
          ...d,
          [segmentValue]: {
            ...d[segmentValue],
            page: (currentPage || 0) + 1,
          },
        };
      });
      await loadEvents((currentPage || 0) + 1, segmentValue === 'past');
      ev.target.complete();
    },
    [dispos, segmentValue]
  );

  const loadUserData = async () => {
    const ud = await getUserData();
    setUserData(ud);
  };
  useEffect(() => {
    loadEvents(1, segmentValue === 'past');
    loadUserData();
  }, [segmentValue]);

  const refresh = async () => {
    await handleRefresh({} as CustomEvent<RefresherEventDetail>);
    history.replace('/main/dispos');
  };
  useEffect(() => {
    if (location.search) {
      const fromDelete = new URLSearchParams(location.search).get('fromDelete');
      if (fromDelete) {
        refresh();
      }
    }
  }, [location]);

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <SegmentCustom
        values={segmentData}
        defaultValue={segmentValue}
        onChange={(value) => setSegmentValue(value.id as Enum_filterType)}
      ></SegmentCustom>
      {segmentValue === Enum_filterType.future && (
        <>
          {loading && dispos?.future?.page! <= 1 ? (
            <CardLoader />
          ) : (
            dispos.future?.data?.map((event, index) => (
              <CardDispo
                weatherInfo={weatherInfo ? weatherInfo[event?.id] : ''}
                key={index}
                dispo={event}
                pending={userData?.stripeCapabilitiesStatus !== 'active'}
              ></CardDispo>
            ))
          )}
        </>
      )}
      {segmentValue === Enum_filterType.past && (
        <>
          {loading && dispos?.past?.page! <= 1 ? (
            <CardLoader />
          ) : (
            dispos.past?.data?.map((event, index) => (
              <CardDispo
                key={index}
                dispo={event}
                pending={userData?.stripeCapabilitiesStatus !== 'active'}
              ></CardDispo>
            ))
          )}
        </>
      )}

      <IonInfiniteScroll
        onIonInfinite={(ev) => {
          showMore(ev);
        }}
      >
        <IonInfiniteScrollContent></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </>
  );
};

export default Dispos;
