import { graphql } from '../../models/gql';

export const SIGNUP = graphql(/* GraphQL */ `
  mutation AuthRegister($input: AuthRegisterInput!) {
    AuthRegister(input: $input) {
      success
      message
    }
  }
`);

export const LOGIN_QUERY = graphql(/* GraphQL */ `
  mutation AuthWithPhone($data: AuthPhoneInput!) {
    AuthWithPhone(data: $data) {
      success
      message
    }
  }
`);

export const VERIFY_SMS_CODE_QUERY = graphql(/* GraphQL */ `
  mutation AuthVerifySmsCode($data: AuthVerifyCodeInput!) {
    AuthVerifySmsCode(data: $data) {
      jwt
      user {
        id
        username
        email
        phone
        confirmed
        blocked
        publishedAt
        interests {
          id
        }
        profilePicture {
          id
        }
        avatar {
          id
        }
      }
    }
  }
`);
export const UPDATE_ME = graphql(/* GraphQL */ `
  mutation updateMe($data: UsersPermissionsUserInput!) {
    updateMe(data: $data) {
      data {
        id
        attributes {
          phone
        }
      }
    }
  }
`);
export const VERIFYPHONE = graphql(/* GraphQL */ `
  mutation VerifyPhone($data: AuthPhoneInput!) {
    VerifyPhone(data: $data) {
      success
      message
    }
  }
`);
