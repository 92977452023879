import { IonButton, IonIcon, IonSkeletonText } from '@ionic/react';
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
import { pauseOutline, playOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useVideo } from '../../hooks/useVideo';
import { AppReducerContext } from '../../store';
import './style.css';

interface IVideoView {
  url: string;
  previewUrl?: string;
  className?: string;
  autoPlay?: boolean;
  videoClassName?: string;
  from?: string;
  index?: number;
  playIcon?: any;
  pauseIcon?: any;
  file?: any;
  hideIcon?: boolean;
}

const VideoView: React.FC<IVideoView> = ({
  url,
  className,
  videoClassName = '',
  autoPlay = true,
  from,
  index,
  playIcon,
  pauseIcon,
  previewUrl,
  file,
  hideIcon = false,
}) => {
  const {
    state: { fullSlider },
  } = useContext(AppReducerContext);
  const { videoPlayed, setVideoPlayed } = useVideo();

  const [isPlay, setIsPlay] = useState<boolean>(autoPlay);
  const [loadPlayer, setLoadPlayer] = useState<boolean>(false);
  const [posterImage, setPosterImage] = useState<string>('');

  const generateVideoThumbnail = async () => {
    let backendUrl = (process.env.REACT_APP_GRAPHQL_ENDPOINT as string).replace('graphql', '');

    try {
      let base64Image = '';
      const response = await fetch(`${backendUrl}api/thumbnail/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url }),
      });
      if (response.ok) {
        base64Image = await response.text();
      } else {
        console.error('La requête a échoué avec le statut :', response.status);
      }

      if (!!base64Image) {
        const dataUrl = `data:image/png;base64,${base64Image}`;
        setPosterImage(dataUrl);
      } else {
        setPosterImage('');
      }
    } catch (e: any) {
      setPosterImage('');
      console.log('error :', e.message);
    }
  };

  const generateBase = async (file: any) => {
    if (!file.attributes) {
      const thumbnailResponse = await generateVideoThumbnails(file, 0, 'jpeg');
      if (thumbnailResponse && thumbnailResponse[0]) {
        setPosterImage(thumbnailResponse[0]);
      } else {
        generateVideoThumbnail();
      }
    } else {
      setPosterImage(file.attributes.previewUrl);
    }
  };

  useEffect(() => {
    if (fullSlider) {
      setIsPlay(false);
    }
  }, [fullSlider]);

  useEffect(() => {
    if (videoPlayed && videoPlayed !== url) {
      setIsPlay(false);
    }
  }, [videoPlayed]);

  useEffect(() => {
    if (!!previewUrl) {
      setPosterImage(previewUrl);
    } else if (!!file) {
      generateBase(file);
    } else {
      generateVideoThumbnail();
    }
  }, [url, previewUrl, file]);

  const onPlayPause = (isFirst: boolean = false) => {
    setVideoPlayed(url);
    if (isFirst) {
      setLoadPlayer(true);
    }
    setIsPlay((prevIsPlay) => !prevIsPlay);
  };

  return (
    <>
      {!!!loadPlayer && (
        <div className={`video-view ${className}`}>
          {posterImage && (
            <img
              src={`${posterImage}`}
              width="100%"
              height="100%"
              className={`video-view oject-fit-cover ${videoClassName} ${from === 'media1' ? 'h-150' : ''}`}
              alt="poster"
            />
          )}
          {!!posterImage && from !== 'message' && (
            <IonButton
              id={index ? 'btn-video-' + index : ''}
              fill="clear"
              className={'btn-video'}
              onClick={() => onPlayPause(true)}
              disabled={from === 'uploadmessage'}
            >
              {!hideIcon && <IonIcon color={'dark'} className="icon-btn" icon={playOutline}></IonIcon>}
            </IonButton>
          )}
        </div>
      )}

      {!posterImage && !loadPlayer && from !== 'uploadmessage' && (
        <div className={`video-view oject-fit-cover ${from === 'media1' ? ' h-300' : ''}`}>
          <IonSkeletonText
            animated
            style={{ width: '100%', height: '100%', minHeight: '100px', minWidth: '150px' }}
            className={`video-view oject-fit-cover ${videoClassName}`}
          />
        </div>
      )}

      {!!loadPlayer && (
        <ReactPlayer
          url={url}
          width="100%"
          className={`${
            from === 'media1' ? 'h-150 h-fixed-150 reactplayer-video' : 'video-view'
          } oject-fit-cover ${videoClassName}`}
          playing={isPlay}
          controls={true}
          playsinline
          config={{
            file: {
              attributes: {
                poster: posterImage,
              },
            },
          }}
          onPause={() => {}}
          muted={true}
          playIcon={
            <IonIcon
              color={'dark'}
              className="icon-btn"
              icon={isPlay ? pauseIcon || pauseOutline : playIcon || playOutline}
            ></IonIcon>
          }
        />
      )}
    </>
  );
};

export default VideoView;
