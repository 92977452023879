import { useLazyQuery, useQuery } from '@apollo/client';
import { chevronDownOutline } from 'ionicons/icons';
import { useMemo } from 'react';
import editSvg from '../assets/icons/editProfil.svg';
import waitSvg from '../assets/icons/wait.svg';
import { GET_RELATION_USER_FOLLOWER } from '../graphql/queries/userFollower.graphql';
import {
  Enum_Userfollower_Status,
  Enum_Userspermissionsuser_Feedtype,
  UserFollowerEntity,
  UsersPermissionsMe,
} from '../models/gql/graphql';
import { getUserId } from '../utils';

interface IFilter {
  follower: any;
  user?: any;
}

export const getUserFollower = (
  relations: UserFollowerEntity[],
  feedType?: Maybe<Enum_Userspermissionsuser_Feedtype>,
  idUser?: string
) => {
  const RELATION_TYPES = {
    ME: 'me',
    LOCKED: 'locked',
    WAITING: 'waiting',
    FRIEND: 'friend',
    UNFRIEND: 'unfriend',
    NEUTRE: 'neutre',
    ABONNE: 'abonne',
  };

  const determinePrivateRelationType = (relations: UserFollowerEntity[]): string => {
    for (const relation of relations) {
      switch (relation.attributes?.status) {
        case 'accepted':
          return RELATION_TYPES.FRIEND;
        case 'asked':
          return RELATION_TYPES.WAITING;
        case 'refused':
          return RELATION_TYPES.UNFRIEND;
        default:
          return RELATION_TYPES.LOCKED;
      }
    }
    return RELATION_TYPES.LOCKED;
  };

  const detailButton = {
    locked: {
      text: "Demander à s'abonner",
      hasMessageIcon: false,
      action: 'followRequest',
    },
    waiting: {
      text: 'Demande en attente',
      icon: waitSvg,
      hasMessageIcon: false,
    },
    friend: {
      text: 'Suivi',
      icon: chevronDownOutline,
      hasMessageIcon: true,
      action: 'openUnfollowModal',
    },
    unfriend: {
      text: "Demander à s'abonner",
      hasMessageIcon: false,
      action: 'followRequest',
    },
    neutre: {
      text: 'Suivre',
      hasMessageIcon: true,
      action: 'follow',
    },
    abonne: {
      text: 'Suivi',
      hasMessageIcon: true,
      icon: chevronDownOutline,
      action: 'openUnfriendModal',
    },
    me: {
      text: 'Modifier mes infos.',
      icon: editSvg,
      hasMessageIcon: false,
      action: 'goToMesInfos',
    },
  };

  let relationInfo = {} as IRelation;

  let key = RELATION_TYPES.LOCKED;
  if (!idUser || idUser === getUserId()?.toString()) {
    key = RELATION_TYPES.ME;
  } else {
    if (!relations?.length) {
      key = feedType === 'PRIVATE' ? RELATION_TYPES.LOCKED : RELATION_TYPES.NEUTRE;
    } else {
      key = feedType === 'PRIVATE' ? determinePrivateRelationType(relations) : RELATION_TYPES.ABONNE;
    }
  }
  relationInfo = (detailButton as any)[key];
  return {
    relations,
    relationInfo,
  };
};

export const useSubscriptions = (idUser?: string, username?: string) => {
  const lowercaseUsername = username?.trim().toLowerCase();
  let subscriptionsFilters: any = {
    follower: {
      id: {
        eq: idUser || '0',
      },
    },
    status: {
      eq: Enum_Userfollower_Status.Accepted,
    },
  };
  if (lowercaseUsername) {
    subscriptionsFilters = {
      ...subscriptionsFilters,
      user: {
        username: {
          containsi: lowercaseUsername,
        },
      },
    };
  }
  const { data: mySubscriptionData, loading: loadingSubscription } = useQuery(GET_RELATION_USER_FOLLOWER, {
    variables: {
      filters: subscriptionsFilters,
      pagination: { limit: 500 },
    },
  });

  const mySubscription = useMemo(() => {
    const uniqueSubscriptionUserName = new Set(
      mySubscriptionData?.userFollowers?.data.map((sub) => sub?.attributes?.user?.data?.attributes?.username)
    );
    return mySubscriptionData && !loadingSubscription
      ? Array.from(uniqueSubscriptionUserName).map((username) =>
          mySubscriptionData.userFollowers?.data.find(
            (sub) => sub.attributes?.user?.data?.attributes?.username === username
          )
        )
      : [];
  }, [mySubscriptionData, loadingSubscription, username]);

  let userFollowerFilters: any = {
    user: {
      id: {
        eq: idUser || '0',
      },
    },
    status: {
      eq: Enum_Userfollower_Status.Accepted,
    },
  };
  if (lowercaseUsername) {
    userFollowerFilters = {
      ...userFollowerFilters,
      follower: {
        username: {
          containsi: lowercaseUsername,
        },
      },
    };
  }

  const [loadMySubscribersData, { data: mySubscribersData, loading: loadingSubscribers }] = useLazyQuery(
    GET_RELATION_USER_FOLLOWER,
    {
      variables: {
        filters: userFollowerFilters,
        pagination: { limit: 500 },
      },
    }
  );

  const mySubscribers = useMemo(() => {
    const uniqueSubscriberUserName = new Set(
      mySubscribersData?.userFollowers?.data.map((sub) => sub?.attributes?.follower?.data?.attributes?.username)
    );
    return mySubscribersData && !loadingSubscribers
      ? Array.from(uniqueSubscriberUserName).map((username) =>
          mySubscribersData.userFollowers?.data.find(
            (sub) => sub.attributes?.follower?.data?.attributes?.username === username
          )
        )
      : [];
  }, [mySubscribersData, loadingSubscribers]);

  return {
    loadMySubscribersData,
    isLoading: loadingSubscribers || loadingSubscription,
    mySubscribers: mySubscribers as UserFollowerEntity[],
    mySubscription: mySubscription as UserFollowerEntity[],
  };
};

export const useUsersIdSubscription = (idUser: string) => {
  const { data: mySubscriptionData, loading: loadingSubscription } = useQuery(GET_RELATION_USER_FOLLOWER, {
    variables: {
      filters: {
        follower: {
          id: {
            eq: idUser,
          },
        },
        status: {
          eq: Enum_Userfollower_Status.Accepted,
        },
      },
      pagination: { limit: 500 },
    },
  });

  const myFollowIds = useMemo(() => {
    let ids = [] as any;

    if (mySubscriptionData && !loadingSubscription) {
      (mySubscriptionData.userFollowers?.data as UserFollowerEntity[]).map((item: UserFollowerEntity) => {
        if (item.attributes?.user?.data) {
          ids.push(item.attributes?.user?.data.id);
        }
      });
    }
    return ids;
  }, [mySubscriptionData, loadingSubscription]);

  return myFollowIds;
};

export const getUserSubscription = async (userConnected: UsersPermissionsMe) => {
  const subscriptions = userConnected.followups?.data
    .filter(
      (follower) => follower.attributes?.status === Enum_Userfollower_Status.Accepted && follower.attributes.user?.data
    ) // remove user already removed
    .map((i) => {
      if (i.attributes?.user?.data?.attributes?.isPro) {
        i.attributes.user.data.attributes.username = i.attributes?.user?.data?.attributes?.companyName;
      }
      return i;
    })
    .sort(
      (a, b) =>
        a?.attributes?.user?.data?.attributes?.username?.localeCompare(
          b?.attributes?.user?.data?.attributes?.username || ''
        ) || 1
    );
  const uniqueSubscriptionIds = new Set(subscriptions?.map((follower) => follower?.attributes?.user?.data?.id));
  const subscribers = userConnected.followers?.data
    .filter(
      (follower) =>
        follower.attributes?.status === Enum_Userfollower_Status.Accepted && follower.attributes.follower?.data
    )
    .map((i) => {
      if (i.attributes?.follower?.data?.attributes?.isPro) {
        i.attributes.follower.data.attributes.username = i.attributes?.follower?.data?.attributes?.companyName;
      }
      return i;
    }) // remove user already removed
    .sort(
      (a, b) =>
        a?.attributes?.follower?.data?.attributes?.username?.localeCompare(
          b?.attributes?.follower?.data?.attributes?.username || ''
        ) || 1
    );
  const uniqueSubscriberIds = new Set(subscribers?.map((sub) => sub?.attributes?.follower?.data?.id));

  return [
    {
      label: `Mes abonnés (${uniqueSubscriberIds?.size || 0})`,
      path: `/main/myprofil/followers`,
      showIcon: true,
      data: subscribers,
    },
    {
      label: `Mes abonnements (${uniqueSubscriptionIds?.size || 0})`,
      path: `/main/myprofil/followups`,
      showIcon: true,
      data: subscriptions,
    },
  ] as IlistItem[];
};
