import { useMutation } from '@apollo/client';
import { IonCol, IonContent, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { useHistory } from 'react-router';
import logoSvg from '../../../assets/icons/logo.svg';
import { LOGIN_QUERY } from '../../../graphql/mutations/auth.graphql';
import { CustomButton } from '../../DispoDetail/components/Buttons/CustomButton';

interface IVerifyAccount {
  phone: string;
  closeModal: () => void;
}
const VerifyAccount: React.FC<IVerifyAccount> = ({ phone, closeModal }) => {
  const history = useHistory();
  const [AuthWithPhone, { loading }] = useMutation(LOGIN_QUERY);

  const reactivate = async () => {
    const { data } = await AuthWithPhone({
      variables: { data: { phone: phone, reactivate: true } as any },
    });
    closeModal();
    history.push(`/signup/verification/${phone}/${data?.AuthWithPhone?.message}/login`);
  };

  return (
    <IonContent>
      <IonRow className="full">
        <IonRow className="bg-white border-radius-t-20">
          <IonGrid>
            <IonRow className="text-center font-outfit">
              <IonCol>
                <IonRow className="ion-justify-content-center">
                  <IonIcon src={logoSvg} className="width-300 height-72"></IonIcon>
                </IonRow>
                <h4>Vous avez mis en pause votre compte.</h4>
                <p>
                  Pour utiliser de nouveau l'application Tedispo, vous devez réactiver votre compte. Souhaitez-vous le
                  réactiver ?
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <CustomButton
                  action={reactivate}
                  class="ion-no-border text-white min-width-p-80 min-height-p-100 ion-border-radius-10 button-bg-green no-uppercase margin-b-10"
                  text="Je réactive mon compte"
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonRow>
      </IonRow>
    </IonContent>
  );
};

export default VerifyAccount;
