import { graphql } from '../../models/gql';

export const CREATE_MESSAGE_ITEM = graphql(/* GraphQL */ `
  mutation createMessageItem($data: MessageItemInput!) {
    createMessageItem(data: $data) {
      data {
        id
        attributes {
          body
        }
      }
    }
  }
`);

export const CHECK_MESSAGE = graphql(/* GraphQL */ `
  mutation checkMessage($firebaseDocId: String!) {
    CheckMessage(firebaseDocId: $firebaseDocId) {
      success
    }
  }
`);
