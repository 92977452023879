import { graphql } from '../../models/gql';
export const GET_EVENTS = graphql(/* GraphQL */ `
  query events($filters: EventFiltersInput, $sort: [String], $pagination: PaginationArg) {
    events(filters: $filters, sort: $sort, pagination: $pagination) {
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
      data {
        id
        attributes {
          name
          eventDate
          eventEndDate
          type
          paymentType
          addressCity
          addressLine
          addressLat
          addressLng
          price
          maxInvited
          deleted
          startHour
          endHour
          beginningDateTime
          expirationDateTime
          expired
          pending
          creator {
            data {
              id
              attributes {
                username
                isPro
                companyName
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          attributes {
                            name
                            url
                            mime
                            hash
                            size
                            provider
                          }
                        }
                      }
                    }
                  }
                }
                profilePicture {
                  data {
                    id
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
              }
            }
          }
          participations {
            data {
              id
            }
          }
          coverCustomImage {
            data {
              attributes {
                name
                url
                mime
                hash
                size
                provider
              }
            }
          }
          coverTedispoImage {
            data {
              attributes {
                image {
                  data {
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);
