import { graphql } from '../../models/gql';

export const CREATE_EVENT = graphql(/* GraphQL */ `
  mutation createEvent($data: EventInput!) {
    createEvent(data: $data) {
      data {
        id
        attributes {
          name
          type
          eventDate
          eventEndDate
          addressLine
          startHour
          endHour
          expired
          coverCustomImage {
            data {
              attributes {
                name
                url
                mime
                hash
                size
                provider
              }
            }
          }
          coverTedispoImage {
            data {
              attributes {
                image {
                  data {
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);
export const INVITE_USERS = graphql(/* GraphQL */ `
  mutation InviteToEvent($input: ParticipationInput!) {
    InviteToEvent(input: $input) {
      success
    }
  }
`);
