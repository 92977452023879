import { Contacts, PermissionStatus } from '@capacitor-community/contacts';
import { IonContent, IonGrid, IonImg, IonPage, IonRow, IonText, IonToast } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import friends from '../../../../assets/icons/friends.jpg';
import { ButtonGroup, CardCustom, Header, StepperCount } from '../../../../components';
import UserContactList from '../../../../components/UserContactList';
import { useUserContact } from '../../../../hooks/useUserContact';
import { __ } from '../../../../utils/traduction';
import './style.css';

const ConfigContact: React.FC = () => {
  const [showList, setShowList] = useState<boolean>(false);
  const history = useHistory();
  const { syncContact, contactsName, userContacts, follow, loading, loadingFollow, mySubscriptionData, refetch } =
    useUserContact();
  const [messageError, setMessageError] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const goToHome = () => {
    history.replace('/main/home');
  };

  return (
    <IonPage>
      <Header title="Configuration de compte" noButtonBack={false} />
      <StepperCount length={4} numberStep={4}></StepperCount>
      <IonContent className="ion-text-left ion-padding">
        <CardCustom>
          <IonText color="dark" className="font-outfit">
            <h3>Retrouve tes contacts</h3>
            <p>
              {!showList
                ? "Nous pouvons t'aider à retrouver tes amis déjà inscrits sur l'application"
                : 'Voici tes contacts déjà présents sur Tedispo :'}
            </p>
          </IonText>
          {!showList ? (
            <>
              <IonGrid>
                <IonRow>
                  <IonImg className="full border-radius-10" src={friends} alt="image pdp"></IonImg>
                </IonRow>
              </IonGrid>
              <ButtonGroup
                textBottom="Passer"
                disabled={loading}
                onClickTop={async () => {
                  let permissionState: PermissionStatus = await Contacts.requestPermissions();
                  if (permissionState.contacts !== 'granted') {
                    setIsOpen(true);
                    setMessageError(
                      "Pour accéder aux contacts, veuillez autoriser l'accès dans les paramètres de l'application"
                    );
                  } else if (permissionState.contacts === 'granted') {
                    syncContact();
                    setShowList(true);
                  }
                }}
                onClickBottom={() => {
                  goToHome();
                }}
                textTop="Synchroniser mes contacts"
                fillBottom="outline"
                classNameTop="ion-margin-top"
              />
            </>
          ) : (
            <>
              <UserContactList
                virtuosoStyle={{ height: '38vh' }}
                userContacts={userContacts}
                contactsName={contactsName}
                follow={follow}
                mySubscriptionData={mySubscriptionData}
                refetch={refetch}
                loadingFollow={loadingFollow}
                type="inscrit"
              />
              <ButtonGroup
                textBottom="Suivre tout le monde"
                onClickTop={() => {
                  goToHome();
                }}
                disabled={loadingFollow}
                onClickBottom={async () => {
                  await follow();
                  refetch();
                }}
                textTop="Continuer"
                fillBottom="outline"
                classNameTop="ion-margin-top"
              />
            </>
          )}
        </CardCustom>
      </IonContent>
      <IonToast
        isOpen={isOpen}
        message={__(messageError)}
        onDidDismiss={() => setIsOpen(false)}
        duration={5000}
        color="danger"
      ></IonToast>
    </IonPage>
  );
};

export default ConfigContact;
