import React, { memo } from 'react';
import { getUserId } from '../../utils';
import useMessengerStore, { IMessengerStore } from './messenger.store';
import useNotificationStore, { INotificationStore } from './notification.store';
import useRootStore, { IRootStore } from './root.store';

// App store interface definition, always update this to avoid conflict
export interface IAppContext {
  parentProps: any;
  RootStore: IRootStore;
  MessengerStore: IMessengerStore;
  notificationStore: INotificationStore;
}

// create a global store
export const appStore = React.createContext<IAppContext>({} as any);

/**
 * Store Provider component
 * Use this component at top level of the app to make sur all children components can consume the store
 * @param { any } props         Component props, available in the context store as `parentProps`
 * @returns
 */
export const AppMemoProvider: React.FC<any> = memo(({ children, ...rest }) => {
  // this part is really important, to split code that handle different type of data and logics, create a custom hook for them and call them over here
  // this way, all your data will be available in the app through the store
  const userId = getUserId();
  const RootStore = useRootStore();
  const notificationStore = useNotificationStore();
  const user = { id: `${userId}` };
  const MessengerStore = useMessengerStore(false);

  // create store value, app your hooks value here
  const value = {
    parentProps: rest,
    RootStore,
    MessengerStore,
    notificationStore,
    user,
  } as IAppContext;

  return <appStore.Provider value={value}>{children}</appStore.Provider>;
});

/**
 * Store consumer,
 * @returns
 */
export const useAppStore = () => {
  return React.useContext(appStore);
};
