import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { useHistory } from 'react-router';
import { AvatarUser } from '../../../components';
import { UsersPermissionsUser } from '../../../models/gql/graphql';

interface IMemberProps {
  member?: Maybe<UsersPermissionsUser>;
  name: string;
  avatarStyle?: string;
  nameStyle?: string;
  icon?: any;
  idMember?: string | null;
  iconClass?: string;
}

export const UserDetail: React.FC<IMemberProps> = ({
  name,
  member,
  avatarStyle,
  nameStyle,
  icon,
  idMember,
  iconClass,
}) => {
  const history = useHistory();
  const goTo = (path: string) => {
    history.push(path);
  };
  return (
    <IonGrid className="ion-no-margin ion-no-padding">
      <IonRow className={`ion-align-items-center font-outfit`}>
        <IonCol size="auto" onClick={() => goTo(`/main/profil/${idMember}`)}>
          <AvatarUser user={member} size="size-md" classCustom={avatarStyle}></AvatarUser>
        </IonCol>
        <IonCol
          onClick={() => goTo(`/main/profil/${idMember}`)}
          style={{ paddingLeft: '10px' }}
          className="ion-text-capitalize"
        >
          <span className={nameStyle}>{name}</span>
        </IonCol>
        {icon && (
          <IonCol size="1.5" className="margin-r-5">
            <IonIcon
              icon={icon}
              className={`text-size-x margin-t-5 ${iconClass ? iconClass : ''}`}
              size={iconClass ? `small` : 'medium'}
            />
          </IonCol>
        )}
      </IonRow>
    </IonGrid>
  );
};
