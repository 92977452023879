import { GenericResponse, VoiceRecorder } from 'capacitor-voice-recorder';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import useMessengerStore from '../store/AppMemoProvider/messenger.store';
import { map } from 'lodash';
import { base64ToFile, compressImage, compressVideo } from '../utils/file';
import { useMutation, useQuery } from '@apollo/client';
import { UPLOAD } from '../graphql/mutations/upload.graphql';
import { CREATE_MESSAGE } from '../graphql/mutations/message.graphql';
import { CREATE_MESSAGE_ITEM, CHECK_MESSAGE } from '../graphql/mutations/messageItem.graphql';
import { MessageInput } from '../models/gql/graphql';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { Unsubscribe } from 'firebase/firestore';
import { GET_EVENT } from '../graphql/queries/event.graphql';
import { GET_EVENT_USER_PARTICIPATIONS } from '../graphql/queries/eventUserParticipation.graphql';
import { useMessageItems } from './useMessageItems';
import { createGesture, useIonToast } from '@ionic/react';
import uniqBy from 'lodash/uniqBy';
import { UPDATE_USER } from '../graphql/mutations/updateUser..graphql';
import { GET_USER_CHAT_NOTIFICATION_SETTING } from '../graphql/queries/getUser.graphql';
import { GET_MESSAGE } from '../graphql/queries/message.graphql';
import { useAppStore } from '../store';
import { checkForbiddenWordInputted, checkForbiddenWordPasted, stripTag } from '../utils/word';

export const useChat = () => {
  const {
    RootStore: { forbiddenWords },
  } = useAppStore();
  const [loading, setLoading] = useState(false);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [_, setInfiniteScrollDisabled] = useState<boolean>(false);
  const [selectedMsgItem, setSelectedMsgItem] = useState<any>(undefined);
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const [isMuteNotificationModalOpen, setIsMuteNotificationModalOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<any>(undefined);
  const [muteLoading, setMuteLoading] = useState<boolean>(false);

  // CHAT ACTIONS STATES
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [isResponding, setIsResponding] = useState(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [body, setBody] = useState<string>('');

  // FILE UPLOAD STATES
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [selectedImages, setSelectedImages] = useState<Array<any>>([]);

  //VOICE RECORDING STATES
  const [isRecordingVoice, setIsRecordingVoice] = useState(false);
  const [isCancellingRecordingVoice, setIsCancellingRecordingVoice] = useState(false);
  const [voiceRecordTimer, setVoiceRecordTimer] = useState(0);

  // SEARCH STATES
  const [openSearchBox, setOpenSearchBox] = useState<boolean>(false);
  const [chatSearchNumberOfResults, setChatSearchNumberOfResults] = useState<number | undefined>(undefined);
  const [chatSearchResultsIds, setChatSearchResultsIds] = useState<string[]>([]);
  const [chatSearchKey, setChatSearchKey] = useState('');
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);
  const [userMutedChats, setUserMutedChats] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);

  const { dispoId, chatId }: any = useParams<IVerifParams>();

  const isDispo = dispoId && !chatId;
  const storeChatId = isDispo ? undefined : chatId;

  const history = useHistory();

  // REFS
  const chatRef = useRef<any>();
  const previousMsgItem = useRef<any>();
  const scrollRef = useRef<any>();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const micRef = useRef<HTMLIonCardElement | null>(null);
  const formRef = useRef<HTMLIonCardElement | null>(null);
  const waveRef = useRef<HTMLIonCardElement | null>(null);
  const lastMessageRef = useRef<any>(null);
  const [presentToast] = useIonToast();

  const {
    setParticipants,
    setMsgItem,
    loadSelectedChat,
    msgItem,
    participants,
    creator,
    dispo,
    peer,
    setDispo,
    user,
    sendMessageToGroup,
    deleteMessage,
    selected,
    setSelected,
    loadMore,
    respondToMessage,
    editMessage,
    respondToGroupMessage,
    selectedMsg,
    setSelectedMsg,
    sendMessage,
    createId,
    setPeer,
    loadAllMessages,
    isMicroAuthorized,
    isOpenError,
    setIsOpenError,
  } = useMessengerStore(isDispo, storeChatId);

  let filters: any = {
    event: {
      id: {
        eq: dispoId,
      },
    },
  };

  // QUERIES
  const {
    data,
    loading: loadingParticipant,
    refetch: refetchParticipant,
  } = useQuery(GET_EVENT_USER_PARTICIPATIONS, {
    variables: {
      filters: filters,
      pagination: { limit: 1000 },
    },
    skip: !dispoId,
    fetchPolicy: 'cache-first',
  });
  const { data: dispoData, loading: loadingEvent } = useQuery(GET_EVENT, {
    variables: { id: dispoId ? dispoId : '0' },
    skip: !dispoId,
  });

  const {
    data: userChatParameterData,
    loading: loadingUserChatParameter,
    refetch: refetchUserChatParameterData,
  } = useQuery(GET_USER_CHAT_NOTIFICATION_SETTING, {
    variables: { id: user?.id || 0 },
    skip: !user,
    fetchPolicy: 'cache-only',
  });

  const {
    data: messageData,
    loading: loadingGetMessage,
    refetch: refetchGetMessage,
  } = useQuery(GET_MESSAGE, {
    variables: {
      filters: {
        firebaseId: {
          eq: dispoId ? `dispo_${dispoId}` : selected,
        },
      },
    },
    skip: !dispoId && !selected,
    fetchPolicy: 'cache-first',
  });

  //MUTATIONS
  const [upload, { loading: loadingUpload }] = useMutation(UPLOAD);
  const [createMessage, { loading: loadingMessage }] = useMutation(CREATE_MESSAGE);
  const [checkMessage, { loading: loadingCheckMessage }] = useMutation(CHECK_MESSAGE);
  const [createMessageItem, { loading: loadingMessageItem }] = useMutation(CREATE_MESSAGE_ITEM);
  const [updateUser, { loading: loadingUser }] = useMutation(UPDATE_USER);

  const { getMessageItems } = useMessageItems();

  const handleInputMessageChange = (e: any) => {
    setBody(e?.detail?.value || '');
  };

  const handleBodyChange = (e: any) => {
    const value = e.target.value;
    console.log({ value });
    let text = value;
    if (text) {
      const forbidden = checkForbiddenWordInputted(forbiddenWords, text);
      if (forbidden) {
        // remove
        var lastIndex = stripTag(text).trim().lastIndexOf(' ');
        text = stripTag(text).substring(0, lastIndex) + ' ';
        // set error msg
        presentToast('Un mot interdit a été détécté et a été supprimé de votre entrée', 1000);
      }
    }
    setBody(text);
  };
  const handlePastedEvent = useCallback(
    (e: any) => {
      let pasted: string;
      if (!e?.clipboardData) {
        if (e.target.value) {
          pasted = e.target.value; // whole text
        } else {
          pasted = e.target.textContent;
        }
      } else {
        pasted = stripTag(e?.clipboardData?.getData('text'));
      }
      const newPastedArr = stripTag(pasted)
        .split(' ')
        .filter((text: string) => {
          return !checkForbiddenWordPasted(forbiddenWords, text);
        });
      if (stripTag(pasted).split(' ').length !== newPastedArr.length) {
        const changed = newPastedArr.join(' ');
        setTimeout(() => {
          setBody(`${body} ${changed}`);
        }, 100);
      }
    },
    [forbiddenWords, body]
  );

  const goToMessagesList = () => {
    history.goBack();
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (sendMessageLoading) {
      return;
    }

    setSendMessageLoading(true);
    e.preventDefault();
    const msgId = dispoId || selected;
    if (!selectedImages.length && body?.trim()?.length) {
      if (isEdit) {
        editMessage(
          {
            msgId,
            msgItemId: selectedMsgItem.id,
            lastMsgItemId: selectedMsg?.lastMsgItemId,
            body,
          },
          () => {
            setBody('');
            setIsEdit(false);
            setSendMessageLoading(false);
          }
        );
        setIsEdit(false);
      } else if (isResponding) {
        sendMessage(body, { msgId, parentId: selectedMsgItem.id }, () => {
          setBody('');
          setIsResponding(false);
          setSendMessageLoading(false);
        });
      } else {
        if (dispoId) {
          sendMessage(body, { msgId }, async (result: any) => {
            setBody('');
            setSendMessageLoading(false);
            if (result.create) {
              const messageData = {
                participants: result?.participantsIds,
                publishedAt: new Date(),
                content: result?.body,
                firebaseId: result?.chatId,
                dispoName: dispo?.attributes?.name,
              };
              await createMessage({ variables: { data: messageData as MessageInput } });
              refetchGetMessage();
            }
            const messageItemData = {
              publishedAt: new Date(),
              body: result?.body,
              firebaseDocId: result?.chatId,
              firebaseItemId: result?.messageId,
            };
            await createMessageItem({ variables: { data: messageItemData } });
          });
        } else {
          sendMessage(body, { msgId }, async (result: any) => {
            setBody('');
            setSendMessageLoading(false);
            if (result.create) {
              const messageData = {
                participants: result?.participantsIds,
                publishedAt: new Date(),
                content: result?.body,
                firebaseId: result?.chatId,
              };
              await createMessage({ variables: { data: messageData as MessageInput } });
              refetchGetMessage();
            }
            const messageItemData = {
              publishedAt: new Date(),
              body: result?.body,
              firebaseDocId: result?.chatId,
              firebaseItemId: result?.messageId,
            };
            await createMessageItem({ variables: { data: messageItemData } });
          });
        }
      }
      if (selectedMsgItem) {
        setSelectedMsgItem(undefined);
      }
    }
    if (selectedImages.length) {
      setIsSendingMessage(true);
      // first, upload images
      let imagesUploaded: any = [];
      for (const img of selectedImages) {
        try {
          console.log({ selectedImages });
          const { data: dataUpload } = await upload({
            variables: {
              file: img.file,
            },
          });
          imagesUploaded.push({ ...dataUpload?.upload?.data, size: img?.file?.size });
        } catch (e) {
          console.log(e);
        }
      }
      if (dispoId) {
        sendMessage(
          body,
          {
            msgId,
            type: 'file',
            files: imagesUploaded.map((img: any) => ({
              id: img?.id,
              size: img?.size,
              ...img.attributes,
            })),
          },
          (result: any) => {
            setIsSendingMessage(false);
            setSelectedImages([]);
            setIsUploadingFile(false);
            setBody('');
            setSendMessageLoading(false);
            checkMessage({ variables: { firebaseDocId: result.chatId } }).then();
          }
        );
      } else {
        sendMessage(
          body,
          {
            msgId,
            type: 'file',
            files: imagesUploaded.map((img: any) => ({
              id: img?.id,
              size: img?.size,
              ...img.attributes,
            })),
          },
          (result: any) => {
            setIsSendingMessage(false);
            setSelectedImages([]);
            setIsUploadingFile(false);
            setBody('');
            setSendMessageLoading(false);
            checkMessage({ variables: { firebaseDocId: result.chatId } }).then();
          }
        );
      }
    }
  };

  //ACTION MODAL START
  const handleOpenActionModal = (msgItem: any) => (e: any) => {
    console.log('SELECTED MSG ITEM ', msgItem);
    setIsActionModalOpen(true);
    setSelectedMsgItem(msgItem);
  };

  const handleMessageDelete = () => {
    console.log('Delete action ');
    setConfirmDelete(true);
  };

  const handleMessageEdit = () => {
    console.log('Edit action ', selectedMsgItem);
    setIsEdit(true);
    setIsActionModalOpen(false);
    setBody(selectedMsgItem?.body);
  };

  const handleMessageCopy = () => {
    console.log('Copy action ');
    navigator.clipboard.writeText(selectedMsgItem?.body);
  };

  const handleMessageResponse = () => {
    console.log('Respond action ');
    setIsResponding(true);
    setIsActionModalOpen(false);
  };

  const handleActionModalCancel = () => {
    setIsActionModalOpen(false);
    if (!isResponding && !isEdit) {
      setSelectedMsgItem(undefined);
    }
  };

  const cancelDelete = () => {
    setConfirmDelete(false);
    setIsActionModalOpen(false);
    setSelectedMsgItem(undefined);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setSelectedMsgItem(undefined);
    setBody('');
  };

  const cancelRespond = () => {
    setIsResponding(false);
    setSelectedMsgItem(undefined);
    setBody('');
  };

  const confirmMessageDelete = () => {
    deleteMessage({
      userId: user?.id,
      msgId: dispoId || selected,
      msgItemId: selectedMsgItem.id,
      lastMsgItemId: selectedMsg?.lastMsgItemId,
    });
    setConfirmDelete(false);
    setIsActionModalOpen(false);
    setSelectedMsgItem(undefined);
  };

  const openFileUploadModal = () => {
    setIsFileUploadModalOpen(true);
  };

  const closeUploadFileModal = () => {
    setSelectedImages([]);
    setIsUploadingFile(false);
  };

  const closeFileUploadModal = () => {
    setIsFileUploadModalOpen(false);
    if (!selectedImages.length) {
      setIsUploadingFile(false);
    }
  };

  const changeFile = async (files: any) => {
    setIsUploadingFile(true);
    const promises = await Promise.all(
      map(files, async (i) => {
        if (i?.type?.substring(0, 5) === 'image') {
          return await compressImage(i);
        } else {
          return i;
        }
      })
    );
    const imageMapped = map(promises, (image: any) => {
      const img: any = {
        file: image,
        url: URL.createObjectURL(image),
      };
      return img;
    });
    setSelectedImages([...selectedImages, ...imageMapped]);
    setIsFileUploadModalOpen(false);
    // handleChange([...selectedImages, ...imageMapped]);
    // closeFileUploadModal();
  };

  const onSelectFile = ({ target: { validity, files } }: any) => {
    if (validity.valid) {
      changeFile(files);
    }
  };

  const handleFileDelete = (index: number) => {
    const filteredImages = selectedImages.filter((img: any, i: number) => i !== index);
    setSelectedImages(filteredImages);
  };

  // FILESYSTEM START
  const clickFile = () => {
    if (inputFileRef.current) {
      setIsUploadingFile(true);
      inputFileRef.current.click();
    }
  };

  const openCamera = async () => {
    const photo = await Camera.getPhoto({
      allowEditing: true,
      quality: 80,
      width: 400,
      height: 400,
      source: CameraSource.Prompt,
      resultType: CameraResultType.Uri,
      promptLabelCancel: 'Annuler',
      promptLabelHeader: 'Ajouter des photo ',
      promptLabelPhoto: 'A partir de la bibliothèque',
      promptLabelPicture: 'Prendre une photo',
    });
    const files = [];
    // eslint-disable-next-line no-plusplus
    const { path } = photo;
    if (path) {
      // eslint-disable-next-line no-await-in-loop
      const { data } = await Filesystem.readFile({ path });
      const f = base64ToFile(data as string, 'image/jpg', `${new Date().getTime()}`);
      files.push(f);
    }
    changeFile(files);
  };

  const transformFilesToBase64 = async (files: any) => {
    const transformedFiles = [];
    for (const file of files) {
      if (file.data) {
        const f = base64ToFile(file.data, file.mimeType, file.name);
        transformedFiles.push(f);
      }
    }
    return transformedFiles;
  };

  const handlePickPhotos = async () => {
    if (Capacitor.isNativePlatform()) {
      setIsUploadingFile(true);
      // native
      // const res = await FilePicker.pickImages({
      //   multiple: true,
      //   readData: true,
      // });
      const { photos } = await Camera.pickImages({ quality: 80, width: 1024, height: 1024 });

      // if (photos[0] && photos[0].path) {
      //   const transformedFiles = transformFilesToBase64(photos);
      //   changeFile(transformedFiles);
      // }
      if (photos[0] && photos[0].path) {
        const files = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < photos.length; ++i) {
          console.log({ i });
          const photo: any = photos[i];
          const { path } = photo;
          // eslint-disable-next-line no-await-in-loop
          const { data } = await Filesystem.readFile({ path });
          const f = base64ToFile(data as string, 'image/jpg', 'name');
          files.push(f);
        }
        changeFile(files);
      }
    } else {
      clickFile();
    }
  };

  const handlePickVideos = async () => {
    if (Capacitor.isNativePlatform()) {
      setIsUploadingFile(true);
      // native
      // const res = await FilePicker.pickVideos({
      //   multiple: true,
      //   readData: true,
      // });

      // if (res.files[0] && res.files[0].path) {
      //   const transformedFiles = transformFilesToBase64(res.files);
      //   changeFile(transformedFiles);
      // }
      const result = await FilePicker.pickVideos({
        multiple: true,
        readData: true,
      });
      setIsLoading(true);

      if (result.files[0] && result.files[0].path) {
        const files = [];

        for (let i = 0; i < result.files.length; ++i) {
          const video: any = result.files[i];
          console.log('video step 1: ', video);
          if (video.path) {
            // mean ios or android
            // need resize
            try {
              const { data: dataFirst } = await Filesystem.readFile({ path: video.path });
              const { uri } = await Filesystem.writeFile({
                data: dataFirst,
                path: 'temp.mp4',
                directory: Directory.Data,
              });

              const newPath = await compressVideo(uri);
              if (newPath) {
                const { data } = await Filesystem.readFile({ path: newPath });
                console.log('file readed', data);
                const f = base64ToFile(data as string, 'video/mp4', 'name');
                files.push(f);
              }
            } catch (e) {
              console.log('error on video compressing');
              console.error(e);
            }
          } else if (video.data) {
            const f = base64ToFile(video.data, 'video/mp4', 'name');
            files.push(f);
          }
        }
        changeFile(files as any);
        setIsLoading(false);
      }
    } else {
      clickFile();
    }
  };

  const handlePickFiles = async () => {
    if (Capacitor.isNativePlatform()) {
      setIsUploadingFile(true);
      // native
      const res = await FilePicker.pickFiles({
        multiple: true,
        types: ['application/pdf'],
        readData: true,
      });

      if (res.files[0] && res.files[0].path) {
        const files = [];

        for (let i = 0; i < res.files.length; ++i) {
          const filePick: any = res.files[i];
          if (filePick.data) {
            const f = base64ToFile(filePick.data, 'application/pdf', filePick.name);
            files.push(f);
          }
        }
        changeFile(files);
      }
    } else {
      clickFile();
    }
  };

  // FILESYSTEM END

  // VOICE RECORDING START
  const startRecordingVoice = () => {
    if (!selectedImages.length) {
      setIsRecordingVoice(true);
      // voice recording
      VoiceRecorder.startRecording()
        .then((result: GenericResponse) => console.log(result.value))
        .catch((error) => console.log(error));
    }
  };

  const stopRecordingVoice = async (duration: number) => {
    // voice recording
    VoiceRecorder.stopRecording().then(async (result) => {
      if (result.value && result.value.recordDataBase64) {
        const filename = `${user?.username}_${new Date().getTime()}.mp3`;
        const f = base64ToFile(result.value.recordDataBase64 as string, 'audio/mp3', filename);
        const { data: dataUpload } = await upload({
          variables: {
            file: f,
          },
        });
        sendMessage(
          body,
          {
            msgId: dispoId || selected,
            type: 'file',
            files: [
              {
                id: dataUpload?.upload?.data?.id,
                duration,
                ...dataUpload?.upload?.data?.attributes,
              },
            ],
          },
          (result: any) => {
            checkMessage({ variables: { firebaseDocId: result.chatId } }).then();
            // setIsSendingMessage(false);
            // setSelectedImages([]);
            // setIsUploadingFile(false);
            // setBody('');
          }
        );
      }
    });
  };

  const resetRecordingVoiceState = async () => {
    setIsRecordingVoice(false);
    await setIsCancellingRecordingVoice(false);
    setVoiceRecordTimer(0);
    VoiceRecorder.stopRecording().then();
  };

  const handlePressStart = (e: any) => {
    e.stopPropagation();
    setIsRecordingVoice(true);
    startRecordingVoice();
  };

  const handlePressEnd = () => {
    if (!isCancellingRecordingVoice) {
      stopRecordingVoice(voiceRecordTimer);
    }
    resetRecordingVoiceState();
  };

  const handlePressCancel = () => {
    resetRecordingVoiceState();
  };

  const gestureInit = async (el: any) => {
    const gesture = createGesture({
      el: el,
      threshold: 15,
      gestureName: 'swipe',
      onMove: (details) => {
        console.log('MOVE ');
        if (waveRef.current) {
          waveRef.current.style.background = 'red !important';
        }
        formRef?.current?.classList.add('chat__audio-red-graph-container');
        setIsCancellingRecordingVoice(true);
      },
      onEnd: () => {
        // end
        if (formRef.current) {
          formRef?.current?.classList.remove('chat__audio-red-graph-container');
          formRef.current.style.background = 'white !important';
        }

        if (Capacitor.getPlatform() === 'ios') {
          setIsCancellingRecordingVoice(false);
        }
        console.log('END ');
      },
    });
    gesture.enable();
  };
  // VOICE RECORDING END

  //ACTION MODAL END

  //SEARCH START
  const handleOpenSearchBox = () => {
    setOpenSearchBox(true);
  };

  const handleChatSearch = (e: any) => {
    const keywords = e?.detail?.value;
    if (keywords.trim().length) {
      setSearchLoading(true);
      const lowercaseKeywords = keywords.toLowerCase();
      getMessageItems({
        variables: {
          filters: {
            and: [
              {
                body: {
                  containsi: lowercaseKeywords,
                },
              },
              {
                firebaseDocId: {
                  eq: dispoId ? `dispo_${dispoId}` : selected,
                },
              },
            ],
          },
          sort: ['id:asc'],
          pagination: { limit: 1000 },
        },
      })
        .then((result: any) => {
          setSearchLoading(false);
          setChatSearchNumberOfResults(result?.data?.messageItems?.data?.length);
          const firebaseItemIds = (result?.data?.messageItems?.data || []).map(
            (item: any) => item?.attributes?.firebaseItemId
          );
          console.log('CHAT SEARCH RESULT IDS ', firebaseItemIds);
          setChatSearchResultsIds(firebaseItemIds);
          setChatSearchKey(keywords);
          setHighlightedMessageId(firebaseItemIds[firebaseItemIds.length - 1]);
        })
        .catch((error: any) => {
          setSearchLoading(false);
          console.error('Error while searching messages ', error);
        });
    }
  };

  const handleSearchArrowUp = () => {
    const highlightedIndex = chatSearchResultsIds.findIndex((id) => id === highlightedMessageId);
    console.log('HIGHLIGHTED INDEX ', highlightedIndex);
    const newIndex = highlightedIndex - 1;
    const newHighlightedMessageId = newIndex !== -1 ? chatSearchResultsIds[newIndex] : null;
    console.log('NEW HIGHLIGHTED INDEX UP', newHighlightedMessageId);
    if (newHighlightedMessageId) {
      setHighlightedMessageId(newHighlightedMessageId as any);
    }
  };

  const handleSearchArrowDown = () => {
    const highlightedIndex = chatSearchResultsIds.findIndex((id) => id === highlightedMessageId);
    console.log('HIGHLIGHTED INDEX ', highlightedIndex);
    const newIndex = highlightedIndex + 1;
    const newHighlightedMessageId = newIndex < chatSearchResultsIds.length ? chatSearchResultsIds[newIndex] : null;
    console.log('NEW HIGHLIGHTED INDEX DOWN', newHighlightedMessageId);
    if (newHighlightedMessageId) {
      setHighlightedMessageId(newHighlightedMessageId as any);
    }
  };

  const handleSearchClose = () => {
    setChatSearchKey('');
    setChatSearchResultsIds([]);
    setChatSearchNumberOfResults(undefined);
    setHighlightedMessageId(null);
    setOpenSearchBox(false);
  };

  //SEARCH END

  const more = async (ev: any) => {
    try {
      const channelId = chatId || `dispo_${dispoId}`;
      const docs = await loadMore(channelId);
      if (!docs) {
        setInfiniteScrollDisabled(true);
      } else {
        setMsgItem((items: any) => [...docs, ...items!]);
      }
      ev.target.complete();
    } catch (e) {
      console.error(e);
      ev.target.complete();
    }
  };

  //NOTIFICATION MODAL START
  const openMuteNotificationModal = () => {
    setIsMuteNotificationModalOpen(true);
  };

  const closeMuteNotificationModal = () => {
    setIsMuteNotificationModalOpen(false);
  };

  const handleMuteChoice = async (duration: string) => {
    setMuteLoading(true);
    const idChat = isDispo ? `dispo_${dispoId}` : selected;
    // Find chat to muted and update it in the array of mutedChats
    const mutedChatIndex = userMutedChats?.findIndex(
      (item: any) => item?.message?.data?.attributes?.firebaseId === idChat
    );

    if (mutedChatIndex > -1) {
      userMutedChats[mutedChatIndex].duration = duration;
      userMutedChats[mutedChatIndex].startDatetime = new Date();
      userMutedChats[mutedChatIndex].message = message.id;

      return updateUser({
        variables: {
          id: user?.id,
          data: {
            chatNotificationMutes: userMutedChats,
          },
        },
      })
        .then(() => {
          refetchUserChatParameterData();
        })
        .finally(() => {
          setMuteLoading(false);
        });
    } else {
      if (message?.id) {
        return updateUser({
          variables: {
            id: user?.id,
            data: {
              chatNotificationMutes: [
                ...(userMutedChats || []).map((e: any) => ({
                  duration: e?.duration,
                  startDatetime: e?.startDatetime,
                  message: e?.message?.data?.id,
                })),
                {
                  duration: duration as any,
                  startDatetime: new Date(),
                  message: message?.id,
                },
              ],
            },
          },
        })
          .then(() => {
            refetchUserChatParameterData();
          })
          .finally(() => {
            setMuteLoading(false);
          });
      }
      setMuteLoading(false);
    }
  };

  const reactiveNotification = async () => {
    const idChat = isDispo ? `dispo_${dispoId}` : selected;
    const updatedChats = userMutedChats?.filter((item: any) => item?.message?.data?.attributes?.firebaseId !== idChat);
    return updateUser({
      variables: {
        id: user?.id,
        data: {
          chatNotificationMutes: updatedChats.map((c: any) => ({
            duration: c?.duration,
            startDatetime: c?.startDatetime,
            message: c?.message?.data?.id,
          })),
        },
      },
    }).then(() => {
      refetchUserChatParameterData();
    });
  };

  //NOTIFICATION MODAL END

  //EFFECTS START
  useEffect(() => {
    const micButton = micRef.current;
    if (micButton) {
      gestureInit(micButton);
    }
  });

  useEffect(() => {
    const mappedUsers = (data?.eventUserParticipations?.data || []).map((d: any) => d?.attributes?.user?.data);
    if (mappedUsers.length > 0) {
      setParticipants((prevParticipants: any) => {
        const newParticipants = [...prevParticipants, ...mappedUsers];
        return uniqBy(newParticipants, 'id');
      });
    }
  }, [data?.eventUserParticipations?.data]);

  useEffect(() => {
    if (dispoData?.event?.data) {
      if (!dispoData?.event?.data?.attributes?.useChat) {
        history.push(`/main/dispos/${dispoId}`);
        return;
      }
      setParticipants((prevParticipants: any) => {
        const newParticipants = [...prevParticipants, dispoData?.event?.data?.attributes?.creator?.data];
        return uniqBy(newParticipants, 'id');
      });
      setDispo(dispoData?.event?.data);
    }
  }, [dispoData?.event?.data]);

  useEffect(() => {
    if (user?.id) {
      const selectedId = createId(user.id, chatId);
      if (!peer?.id) {
        setPeer({ id: chatId });
      }
      setSelected(selectedId);
    }
  }, [chatId, user?.id]);

  useEffect(() => {
    setLoading(true);
    setMsgItem([]);
    let unsub: Unsubscribe | null = null;
    if (selected || dispoId) {
      const idToFetch = dispoId ? `dispo_${dispoId}` : selected;
      unsub = loadSelectedChat(idToFetch, (d: any[]) => {
        setMsgItem(d);
        setLoading(false);
      });
    }
    return () => {
      if (typeof unsub === 'function') unsub();
    };
  }, [selected, dispoId]);

  // useEffect(() => {
  //   VoiceRecorder.hasAudioRecordingPermission()
  //     .then((result) => {
  //       console.log({result})
  //       if (!result.value) {
  //         VoiceRecorder.requestAudioRecordingPermission().then((res: any) => console.log(res.value));
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('HAS PERMISSION ', error);
  //     });
  //   // (async () => {
  //   //   await VoiceRecorder.requestAudioRecordingPermission();
  //   // })();
  // }, []);

  const sendRequestAudio = () => {
    // VoiceRecorder.hasAudioRecordingPermission()
    //   .then((result) => {
    //     console.log({ result });
    //     if (!result.value) {
    //       VoiceRecorder.requestAudioRecordingPermission().then((res: any) => console.log(res.value));
    //     }
    //   })
    //   .catch((error) => {
    //     console.error('HAS PERMISSION ', error);
    //   });
    VoiceRecorder.requestAudioRecordingPermission().then((res) => console.log(res.value));
  };

  useEffect(() => {
    if ((!previousMsgItem.current || !previousMsgItem.current.length) && msgItem && msgItem.length) {
      setInfiniteScrollDisabled(false);
      if (scrollRef?.current) {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          scrollRef?.current?.scrollToBottom();
        }, 3000);
      }
    }
    previousMsgItem.current = msgItem;
  }, [msgItem]);

  useEffect(() => {
    let interval: any = null;
    if (isRecordingVoice) {
      interval = setInterval(() => {
        setVoiceRecordTimer((seconds) => seconds + 1);
      }, 1000);
    } else if (!isRecordingVoice && voiceRecordTimer !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRecordingVoice, voiceRecordTimer]);

  useEffect(() => {
    if (chatSearchResultsIds.length) {
      setLoading(true);
      setMsgItem([]);
      if (dispoId || selected) {
        const id = dispoId ? `dispo_${dispoId}` : selected;
        loadAllMessages(id, (d: any[]) => {
          setMsgItem(d);
          setLoading(false);
        });
      }
    }
  }, [chatSearchResultsIds]);

  useEffect(() => {
    if (highlightedMessageId) {
      const highlightedMessage = document.getElementById(highlightedMessageId!);
      if (highlightedMessage) {
        highlightedMessage.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [highlightedMessageId]);

  useEffect(() => {
    if (userChatParameterData) {
      const mutedChats = userChatParameterData?.usersPermissionsUser?.data?.attributes?.chatNotificationMutes;
      setUserMutedChats(mutedChats);
    }
  }, [userChatParameterData]);

  useEffect(() => {
    if (messageData) {
      console.log('MESSAGE DATA ', messageData?.messages?.data[0]);
      setMessage(messageData?.messages?.data[0]);
    }
  }, [messageData]);

  useEffect(() => {
    if (!isSendingMessage) {
      scrollRef?.current?.scrollToBottom();
    }
  }, [isSendingMessage]);

  //EFFECTS END

  const chatParticipants = isDispo ? participants : [user, peer];

  return {
    loading,
    muteLoading,
    sendMessageLoading,
    searchLoading,
    isSendingMessage,
    isCopy,
    isResponding,
    isEdit,
    isUploadingFile,
    isActionModalOpen,
    confirmDelete,
    isFileUploadModalOpen,
    isMuteNotificationModalOpen,
    isRecordingVoice,
    isCancellingRecordingVoice,
    openSearchBox,
    chatSearchNumberOfResults,
    chatSearchResultsIds,
    chatSearchKey,
    highlightedMessageId,
    chatRef,
    previousMsgItem,
    scrollRef,
    inputFileRef,
    micRef,
    formRef,
    waveRef,
    lastMessageRef,
    isDispo,
    dispo,
    currentUser: user,
    peer,
    participants: chatParticipants,
    dispoId,
    chatId,
    selected,
    body,
    selectedMsgItem,
    messages: msgItem,
    selectedImages,
    voiceRecordTimer,
    userMutedChats,
    message,
    setIsCopy,
    setIsResponding,
    setIsActionModalOpen,
    setConfirmDelete,
    setIsFileUploadModalOpen,
    setIsRecordingVoice,
    setIsCancellingRecordingVoice,
    setOpenSearchBox,
    setChatSearchNumberOfResults,
    setChatSearchResultsIds,
    setChatSearchKey,
    setHighlightedMessageId,
    handleInputMessageChange,
    handlePastedEvent,
    handleBodyChange,
    goToMessagesList,
    onSubmit,
    handleOpenActionModal,
    handleMessageDelete,
    handleMessageEdit,
    handleMessageCopy,
    handleMessageResponse,
    handleActionModalCancel,
    closeFileUploadModal,
    startRecordingVoice,
    openFileUploadModal,
    closeUploadFileModal,
    closeMuteNotificationModal,
    openMuteNotificationModal,
    handleFileDelete,
    cancelEdit,
    cancelDelete,
    cancelRespond,
    confirmMessageDelete,
    onSelectFile,
    openCamera,
    handlePickPhotos,
    handlePickVideos,
    handlePickFiles,
    handleOpenSearchBox,
    handlePressStart,
    handlePressEnd,
    handlePressCancel,
    handleChatSearch,
    handleSearchArrowUp,
    handleSearchArrowDown,
    handleSearchClose,
    handleMuteChoice,
    more,
    reactiveNotification,
    loadingUserChatParameter,
    loadingGetMessage,
    sendRequestAudio,
    loadingUpload,
    isLoading,
    isMicroAuthorized,
    isOpenError,
    setIsOpenError,
  };
};
