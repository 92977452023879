import { Plugins } from '@capacitor/core';
import { IonCol, IonIcon, IonRow, IonText, IonToast } from '@ionic/react';
import { useRef, useState } from 'react';
import { getIcon } from '../../../utils/weatherkit';
const { Clipboard } = Plugins;

interface ListWithImage {
  description?: Maybe<string>;
  description2?: Maybe<string>;
  image: any;
  action?: () => void;
  type?: string;
  weatherInfo?: string;
}

export const ListWithImage: React.FC<ListWithImage> = ({
  description,
  image,
  description2,
  action,
  type,
  weatherInfo,
}) => {
  const adresseRef = useRef<HTMLIonRowElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  let touchStartTimestamp = 0;
  const handleTouchStart = (e: any) => {
    e.stopPropagation();
    touchStartTimestamp = 1;
    setTimeout(() => {
      if (touchStartTimestamp !== 0 && type === 'adresse') {
        copyToClipboard();
      }
    }, 1000);
  };

  const copyToClipboard = async () => {
    const textToCopy = adresseRef?.current?.innerText;
    await Clipboard.write({
      string: textToCopy,
    });
    setIsOpen(true);
  };
  return (
    <IonRow className="ion-justify-content-center ion-align-items-center width-90 bg-blue-light border-radius-10 margin-bottom-5 text-black padding-y-5 margin-y-5">
      <IonCol size="1.5">
        <IonIcon icon={image} size="large" className="ion-icon-blue padding-left-10"></IonIcon>
      </IonCol>
      <IonCol onClick={action} style={action ? { cursor: 'pointer' } : undefined} className="margin-l-10 padding-x-10">
        <IonRow className="blue-on-hover text-medium text-bold ion-no-padding">
          <IonText className={`${description2 ? 'blue-text ' : ''}  text-capitalize`}>{description}</IonText>
        </IonRow>
        {description2 && (
          <IonRow
            className={`blue-on-hover ion-no-padding`}
            ref={adresseRef}
            onTouchStart={(e) => handleTouchStart(e)}
            onTouchEnd={() => {
              touchStartTimestamp = 0;
            }}
          >
            <IonCol className="ion-no-padding ion-no-margin">
              <IonText className="text-size-xs  text-capitalize">{description2}</IonText>
            </IonCol>
          </IonRow>
        )}
      </IonCol>
      {weatherInfo && (
        <div className="weather-container ion-padding-horizontal">
          <IonIcon src={getIcon(weatherInfo)} />
        </div>
      )}
      <IonToast
        isOpen={isOpen}
        message={"L'adresse est copiée dans le presse-papier"}
        onDidDismiss={() => setIsOpen(false)}
        duration={5000}
        color="success"
      ></IonToast>
    </IonRow>
  );
};
