import { IonContent, IonPage, IonButton, IonText, IonInput } from '@ionic/react';
import { star, checkmarkCircle, close, closeCircle } from 'ionicons/icons';
import { Header, InputCustom, StepperCount, CardCustom, InputMessage } from '../../../../components';
import { UPDATE_ME } from '../../../../graphql/mutations/auth.graphql';
import { useMutation } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';

type MessageType = {
  value: string;
  icon: any;
  color: string;
};

const ConfigCompte: React.FC = () => {
  const [updateMe, { loading }] = useMutation(UPDATE_ME);
  const [message, setMessage] = useState<MessageType>();
  const [username, setUsername] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const methods = useForm();

  const history = useHistory();

  const onSubmit = async (e: any) => {
    try {
      const { data, errors } = await updateMe({
        variables: {
          data: {
            username,
          },
        },
      });
      setMessage({
        value: 'Ce compte est bon',
        icon: checkmarkCircle,
        color: 'primary',
      });
      history.push(`/signup/config-interest`);
    } catch (err: any) {
      setIsOpen(true);
      setMessage({
        value: "Ce pseudo n'est pas disponible",
        icon: closeCircle,
        color: 'warning',
      });
    }
  };

  return (
    <IonPage className="bg-white">
      <Header title="Configuration de compte" noButtonBack={true} />
      <StepperCount length={3} numberStep={1}></StepperCount>
      <IonContent className="ion-text-left ion-padding">
        <CardCustom>
          <IonText color="dark">
            <h3 className="ion-color-dark font-outfit">Défini ton pseudo</h3>
            <p className="ion-text-left font-description font-outfit">
              Chaque pseudo est unique chez Tedispo. Avant de créer ta 1ère dispo, choisi ton pseudo en fonction de ta
              personnalité !
            </p>
          </IonText>
          <div className="ion-margin-bottom">
            <p className="custom-label font-outfit">Pseudo</p>
            <IonInput
              className={`custom-input font-outfit bold-input`}
              placeholder="Choisissez votre pseudo"
              type="text"
              value={username}
              onIonInput={(e: any) => setUsername(e.target.value)}
              inputmode="text"
            ></IonInput>
          </div>

          {message && <InputMessage message={message.value} icon={message.icon} color={message.color}></InputMessage>}
          <IonButton
            type="button"
            onClick={onSubmit}
            expand="block"
            fill="solid"
            color="primary"
            disabled={loading || !username}
            className="ion-margin-top"
          >
            Continuer
          </IonButton>
        </CardCustom>
      </IonContent>
    </IonPage>
  );
};

export default ConfigCompte;
