import React from 'react';
import { IonCard } from '@ionic/react';
import './style.css';

export interface ICardCustomProps {
  children: React.ReactNode;
  padding?: boolean;
  margin?: boolean;
}

const CardCustom: React.FC<ICardCustomProps> = ({ children, padding = true, margin = false }) => {
  return (
    <IonCard className={`${padding && 'ion-padding'} card-custom-white ${!margin && 'no-margin-card'}`}>
      {children}
    </IonCard>
  );
};
export default CardCustom;
