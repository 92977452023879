import { IonIcon, IonModal, IonRow } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import { FC, useContext } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { UploadFileEntity } from '../../models/gql/graphql';
import VideoView from '../VideoView';
import './style.css';
import { AppReducerContext } from '../../store';

interface IFullSlider {
  files: any;
  setFiles: (value: UploadFileEntity[]) => void;
}
const FullSlider: FC<IFullSlider> = ({ files, setFiles }) => {
  const {
    state: { fullSlider },
    dispatch,
  } = useContext(AppReducerContext);

  return (
    <IonModal isOpen={!!files} mode="md" className="full-slider">
      <IonRow className="ion-justify-content-end ion-padding padding-t-50">
        <IonIcon
          icon={closeCircleOutline}
          size="large"
          className="text-white"
          onClick={() => {
            dispatch({ type: 'SET_FULL_SLIDER', payload: false });
            setFiles([] as UploadFileEntity[]);
          }}
        />
      </IonRow>
      <IonRow className="ion-justify-content-center ion-align-items-center full-height">
        <Swiper
          modules={[Pagination, Navigation]}
          spaceBetween={20}
          centeredSlidesBounds={true}
          centeredSlides={true}
          setWrapperSize={true}
          pagination={{
            clickable: true,
            type: 'bullets',
            renderBullet(index, className) {
              return `<span class="${className} pagination-custom "></span>`;
            },
          }}
          navigation={true}
        >
          {!!files.length && (
            <div>
              {files.map((file: any, index: number) => (
                <SwiperSlide style={{ height: '100%' }} key={index}>
                  <PostMedia
                    url={'attributes' in file && file.attributes?.url ? file.attributes.url : file?.url}
                    previewUrl={
                      'attributes' in file && file.attributes?.previewUrl
                        ? file.attributes.previewUrl
                        : file?.previewUrl
                    }
                    mime={'attributes' in file && file.attributes?.mime ? file.attributes?.mime : file?.mime}
                  />
                </SwiperSlide>
              ))}
            </div>
          )}
        </Swiper>
      </IonRow>
    </IonModal>
  );
};

export default FullSlider;

interface IPostMedia {
  url: string;
  previewUrl: string;
  mime: string;
}
const PostMedia: FC<IPostMedia> = ({ url, mime, previewUrl }) => {
  return (
    <>
      {mime.substring(0, 5) === 'image' ? (
        <img alt="slide" src={url} style={{ minHeight: '400px', width: '90%' }} />
      ) : (
        <VideoView url={url} previewUrl={previewUrl} autoPlay={false} from="fullSlider"></VideoView>
      )}
    </>
  );
};
