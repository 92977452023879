import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonProgressBar,
  IonText,
  useIonViewDidEnter,
} from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';
import { caretForwardOutline } from 'ionicons/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { CardLoader, MenuList } from '../../components';
import DispoDetailContainer from '../../components/DispoDetailContainer';
import DispoHeader from '../../components/Headers/DispoHeader';
import { GetAllParticipations, useDispoDetail } from '../../hooks/useDispoDetail';
import { Enum_Event_Type } from '../../models/gql/graphql';
import { DeleteEvent } from '../DispoDetail/components/DeleteEvent';
import { getEventBg } from '../DispoDetail/utils';
import { filter, pullAllBy } from 'lodash';

const EditDispo: React.FC = () => {
  const { id } = useParams<IVerifParams>();
  const dispoData = useDispoDetail();
  const { eventType } = dispoData;
  const [backgroundColor, setBackgroundColor] = useState('white');
  const history = useHistory();
  const [scrollTop, setScrollTop] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  let bkColor = eventType ? (eventType === Enum_Event_Type.Private ? 'rgba(61, 39, 235)' : 'rgba(4, 177, 113)') : '';
  const textColor = eventType ? (eventType === Enum_Event_Type.Private ? 'text-blue' : 'text-green') : 'text-green';
  const ionContentRef = useRef<HTMLIonContentElement | null>(null);
  const { maxInvited } = dispoData;
  const { participations: eventParticipations, refetchParticipation } = GetAllParticipations(dispoData.id, [
    'payment_failed',
    'payment_created',
    'payment_canceled',
    'waiting',
  ]);

  const accepted = useMemo(() => {
    return eventParticipations.filter((i) => i.attributes?.status === 'accepted');
  }, [eventParticipations]);

  const invited = useMemo(() => {
    return eventParticipations.filter(
      (i) =>
        i.attributes?.status === 'accepted' ||
        i.attributes?.status === 'invited' ||
        i.attributes?.status === 'refused' ||
        i.attributes?.status === 'canceled'
    );
  }, [eventParticipations]);

  const refused = useMemo(() => {
    return eventParticipations.filter((i) => i.attributes?.status === 'refused');
  }, [eventParticipations]);

  const modifierItems: IlistItem[] = [
    { label: 'Informations', path: `/main/dispos/${id}/edit-infos`, showIcon: true },
    { label: 'Photos', path: `/main/dispos/${id}/edit-photo`, showIcon: true },
    { label: 'Options', path: `/main/dispos/${id}/edit-options`, showIcon: true },
    { label: "Centre d'intérêts", path: `/main/dispos/${id}/edit/interests`, showIcon: true },
  ];

  const [gererItems, setGererItems] = useState<IlistItem[]>([]);

  const openPage = (item: IlistItem) => {
    history.push(item.path);
  };

  const handleScroll = (event: any) => {
    const currentScroll = event.detail.scrollTop > 0 ? event.detail.scrollTop : 0;

    if (currentScroll >= 300) {
      setBackgroundColor('white');
    } else {
      setBackgroundColor(getEventBg(dispoData));
    }

    setScrollTop(event.detail.scrollTop);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!scrollTop && dispoData) {
      setBackgroundColor(getEventBg(dispoData));
    }
  }, [dispoData, scrollTop]);

  useEffect(() => {
    // reinitialize
    refetchParticipation();
    dispoData.refetchParticipations();
    const gestion: IlistItem[] = [];
    //PARTICIPANTS
    const Participants = {
      label: 'Participants',
      description: `${
        !!maxInvited && (!!accepted.length || accepted.length == 0)
          ? accepted.length + ' inscrites. encore ' + Number(maxInvited - accepted.length) + ' places disponibles.'
          : !maxInvited
          ? "Le nombre de place maximal n'est pas défini"
          : ''
      }`,
      path: `/main/dispos/${id}/participants`,
      showIcon: true,
      children: (
        <IonProgressBar
          mode="ios"
          color="success"
          value={maxInvited && accepted.length ? accepted.length / maxInvited : 0}
          className="height-10 border-radius"
        ></IonProgressBar>
      ),
    };
    //CONTRIBUTIONS
    const progressValue =
      (dispoData.event?.attributes?.moneyRaised || 0) / dispoData?.event?.attributes?.moneyToRaise || 0;
    let contributions = {
      label: 'Contributions',
      description: `${dispoData.event?.attributes?.moneyRaised || 0}€ collectés sur ${
        dispoData.event?.attributes?.moneyToRaise || 0
      }€`,
      path: `/main/dispos/${id}/edit/contributions`,
      showIcon: true,
      children: <IonProgressBar mode="ios" color="success" className="height-10 border-radius" value={progressValue} />,
    };

    //PAYMENT
    let payments = {
      label: 'Paiements',
      description: `${dispoData.event?.attributes?.moneyRaised || 0}€  reçus`,
      path: `/main/dispos/${id}/edit/payments`,
      showIcon: true,
    };
    //INVITATIONS
    let invitations = {
      label: 'Invitations',
      description: `(${refused.length + accepted.length}/${invited.length}) réponses reçues`,
      path: `/main/dispos/${id}/invitations`,
      showIcon: true,
    };

    if (dispoData.paymentType === 'Contributive') {
      gestion.push(contributions);
    }
    if (dispoData.paymentType === 'Payante') {
      gestion.push(payments);
    }

    if (dispoData.eventType === Enum_Event_Type.Private) {
      gestion.push(invitations);
    }
    if (dispoData.eventType === Enum_Event_Type.Public) {
      gestion.push(Participants);
    }

    setGererItems(gestion);
  }, [dispoData.event, eventParticipations]);

  useEffect(() => {
    if ((!scrollTop || scrollTop < 300) && dispoData) {
      setBackgroundColor(getEventBg(dispoData));
    }
  }, [dispoData]);

  useEffect(() => {
    console.log('refresh');
    dispoData.refresh();
  }, []);

  const hasPayment = useMemo(() => {
    return (
      dispoData.eventParticipations.some((i) => i.attributes.status === 'accepted') &&
      dispoData.paymentType === 'Payante'
    );
  }, [dispoData]);

  return (
    <IonPage style={{ backgroundColor: backgroundColor }}>
      <IonHeader class="ion-no-border">
        {dispoData.loadingEvent ? (
          <>
            <CardLoader />
          </>
        ) : (
          <DispoHeader
            dispoData={dispoData}
            noButtonBack={false}
            rightButton={'edit'}
            action={() => console.log('todo')}
            scrollTop={scrollTop}
            showDispoDetail={true}
            resetBackgroundColor={() => setBackgroundColor('white')}
          ></DispoHeader>
        )}
      </IonHeader>
      <IonContent
        scrollEvents={true}
        fullscreen
        onIonScroll={handleScroll}
        ref={(ref) => (ionContentRef.current = ref)}
        style={{ maxHeight: '100vh', overflowY: 'auto' }}
      >
        {dispoData.loadingEvent ? (
          <>
            <CardLoader />
          </>
        ) : (
          <div className="bg-white">
            <DispoDetailContainer bkColor={bkColor} scrollTop={scrollTop} scrollFix={112} isEdit={true}>
              <MenuList
                listItems={gererItems}
                icon={caretForwardOutline}
                action={(item: IlistItem) => {
                  openPage(item);
                }}
                titre="Gérer ta dispo"
                color={textColor}
              />
              <MenuList
                listItems={
                  dispoData.eventType === Enum_Event_Type.Private
                    ? modifierItems
                    : pullAllBy(modifierItems, [{ label: 'Options' } as any], 'label')
                }
                icon={caretForwardOutline}
                action={(item: IlistItem) => {
                  openPage(item);
                }}
                titre="Modifier ta dispo"
                color={textColor}
              />
            </DispoDetailContainer>
          </div>
        )}
      </IonContent>
      {!hasPayment && !dispoData.loadingEvent && (
        <IonFooter className="ion-no-border bg-white">
          <IonButton
            expand="block"
            className="no-uppercase ion-no-border button-no-border"
            onClick={() => setShowModal(true)}
          >
            <IonText className="text-underligne text-black">Supprimer la dispo</IonText>
          </IonButton>
        </IonFooter>
      )}
      <DeleteEvent showModal={showModal} handleCancel={handleCancel} />
    </IonPage>
  );
};

export default EditDispo;
