import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { AppUrlListener } from './components';
import * as Pages from './pages';
import MainTabs from './Tabs';
import { isAuthenticated, StorageUtils } from './utils';

const Routes: React.FC = () => {
  // use to redirect user if not logued
  const renderAuth = (Component: JSX.Element, location?: any): JSX.Element => {
    if (!isAuthenticated() && Component.type.name !== 'MainTabs') {
      if (location && location.pathname !== '/send-phone') StorageUtils.set('backUrl', location);
      return <Redirect to="/on-boarding" />;
    }
    return Component;
  };

  // use to redirect use if logued
  const renderGuest = (Component: JSX.Element): JSX.Element => {
    /* #TODO to implement */
    /* if (isAuthenticated()) {
      return <Redirect to="/home" />;
    }*/
    return Component;
  };

  return (
    <IonReactRouter>
      <AppUrlListener />
      <IonRouterOutlet id="main">
        <Route exact path="/guest-home" render={({ location }) => renderGuest(<Pages.GuestHome />)} />
        <Route exact path="/splash" component={Pages.SplashScreen} />
        <Route exact path="/signup/choose" render={() => <Pages.ChooseType wording={Pages.ChooseTypeWording} />} />
        <Route exact path="/signup/type/pro" render={({ location }) => renderGuest(<Pages.Formulaire />)} />
        <Route
          exact
          path="/signup/type/particulier"
          render={({ location }) => renderGuest(<Pages.FormulaireParticulier />)}
        />
        <Route
          exact
          path="/signup/verification/:phone/:pro?/:type?"
          render={({ location }) => renderGuest(<Pages.Verification />)}
        />
        <Route exact path="/signup/config" render={({ location }) => renderAuth(<Pages.ConfigCompte />)} />
        <Route exact path="/signup/config-interest" render={({ location }) => renderAuth(<Pages.ConfigInterest />)} />
        <Route exact path="/signup/config-pdp" render={({ location }) => renderAuth(<Pages.ConfigPDP />)} />
        <Route exact path="/signup/config-avatar" render={({ location }) => renderAuth(<Pages.ConfigAvatar />)} />
        <Route exact path="/signup/config-contact" render={({ location }) => renderAuth(<Pages.ConfigContact />)} />
        <Route exact path="/signup/confirmation-pro" render={({ location }) => <Pages.ConfirmationPro />} />
        <Route exact path="/login/send-phone" render={({ location }) => renderGuest(<Pages.SendPhone />)} />
        <Route exact path="/login/no-compte" render={({ location }) => renderGuest(<Pages.NoCompte />)} />
        <Route exact path="/login/no-phone" render={({ location }) => renderGuest(<Pages.NoPhone />)} />
        <Route exact path="/create-dispo/step-info" render={({ location }) => renderAuth(<Pages.StepInfo />)} />
        <Route
          exact
          path="/create-dispo/step-invitation"
          render={({ location }) => renderAuth(<Pages.StepInvitation />)}
        />
        <Route exact path="/create-dispo/step-photo" render={({ location }) => renderAuth(<Pages.StepPhoto />)} />
        <Route exact path="/create-dispo/step-tarif" render={({ location }) => renderAuth(<Pages.StepTarif />)} />
        <Route exact path="/create-dispo/step-options" render={({ location }) => renderAuth(<Pages.StepOptions />)} />
        <Route exact path="/create-dispo/preview" render={({ location }) => renderAuth(<Pages.Preview />)} />
        <Route exact path="/create-dispo/confirmed" render={({ location }) => renderAuth(<Pages.Confirmed />)} />
        <Route exact path="/on-boarding" render={({ location }) => renderGuest(<Pages.OnBoarding />)} />

        <Route exact path="/my-account/infos" render={({ location }) => renderAuth(<Pages.Informations />)} />
        <Route exact path="/my-dispos" render={({ location }) => renderAuth(<Pages.Dispos />)} />
        <Route exact path="/">
          <Redirect to="/splash" />
        </Route>
        <Route exact path="/signup/verify-config" render={({ location }) => renderAuth(<Pages.VerifyConfig />)} />
        <Route path="/main" render={() => renderAuth(<MainTabs />)} />

        <Route
          exact
          path="/stripe/account/refresh"
          render={({ location }) => renderAuth(<Pages.StripeRefreshAccount />)}
        />
        <Route exact path="/stripe/account/done" render={({ location }) => renderAuth(<Pages.StripeAccountDone />)} />
        <Redirect exact path="/home" to="/main/home" />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default Routes;
