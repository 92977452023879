import { IonButton, IonCol, IonGrid, IonIcon, IonModal, IonRow } from '@ionic/react';
import { arrowBackCircleOutline, arrowForwardCircleOutline, closeCircleOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { UploadFile } from '../../models/gql/graphql';
import VideoView from '../VideoView';
import './style.css';
interface ItemSlider {
  items?: Array<UploadFile>;
  itemsAny?: any;
}

const SliderMedia: React.FC<ItemSlider> = ({ items, itemsAny }) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [numberSwiper, setNumberSwiper] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const initSwiper = (swiper: any) => {
    setSwiperRef(swiper);
  };

  const scrollContainerRef = useRef(null);

  const [indexInTheMiddle, setIndexInTheMiddle] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        const { scrollLeft, clientWidth, scrollWidth } = scrollContainer;
        // Check if scrolled to the end
        const isEnd = 0 <= scrollWidth - (scrollLeft + clientWidth) && scrollWidth - (scrollLeft + clientWidth) <= 0.5;
        if (isEnd) {
          // PUT FUNCTION HERE
        }
      }
    };

    // Attach the scroll event listener
    const scrollContainer = scrollContainerRef.current as any;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    // Cleanup function
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [items, itemsAny]);

  return (
    // <div ref={scrollContainerRefAround} style={{ touchAction: 'pan-y' }}>
    //   <Swiper
    //     modules={[Pagination]}
    //     spaceBetween={20}
    //     centeredSlidesBounds={true}
    //     centeredSlides={true}
    //     setWrapperSize={true}
    //     pagination={{
    //       clickable: true,
    //       type: 'bullets',
    //       renderBullet(index, className) {
    //         return `<span class="${className} ${items && items?.length <= 3 ? 'pagination-custom' : ''}"></span>`;
    //       },
    //     }}
    //     onSlideChange={(swiper) => setNumberSwiper(swiper.activeIndex)}
    //     onClick={() => setIsShow(true)}
    //     initialSlide={numberSwiper}
    //     onTouchStart={(swiper, event) => {
    //       event.preventDefault();
    //     }}
    //   >
    //     {items &&
    //       items?.map((item: UploadFile, index: number) => {
    //         return (
    //           <SwiperSlide key={index}>
    //             <div className="ion-margin-top">
    //               {item && item?.mime.substring(0, 5) === 'image' && (
    //                 <img alt="Silhouette of mountains" style={{ borderRadius: '7px' }} src={item.url as string} />
    //               )}
    //               {item && item.mime.substring(0, 5) === 'video' && (
    //                 <VideoView
    //                   className="ion-border-radius-5"
    //                   url={item?.url as string}
    //                   autoPlay={true}
    //                   previewUrl={item?.previewUrl || ''}
    //                 ></VideoView>
    //               )}
    //             </div>
    //           </SwiperSlide>
    //         );
    //       })}
    //     {itemsAny &&
    //       itemsAny?.map((item: any, index: number) => {
    //         return (
    //           <SwiperSlide key={index}>
    //             <div className="ion-margin-top">
    //               {item && item?.file?.type?.substring(0, 5) === 'image' && (
    //                 <img alt="Silhouette of mountains" style={{ borderRadius: '7px' }} src={item.url as string} />
    //               )}
    //               {item && item?.file?.type?.substring(0, 5) === 'video' && (
    //                 <VideoView
    //                   className="ion-border-radius-5"
    //                   url={item?.url as string}
    //                   autoPlay={true}
    //                   previewUrl={item?.previewUrl || ''}
    //                   file={item?.file}
    //                 ></VideoView>
    //               )}
    //             </div>
    //           </SwiperSlide>
    //         );
    //       })}
    //   </Swiper>
    // </div>
    <>
      <IonGrid
        ref={scrollContainerRef}
        className="hide-scrollBar"
        style={{ overflow: 'auto', padding: 0, paddingBottom: 10 }}
      >
        <IonRow
          onClick={() => setIsShow(true)}
          className="col-card"
          style={{ flexWrap: 'nowrap', padding: 0, alignItems: 'center' }}
        >
          {/* Vos éléments à faire défiler horizontalement */}
          {items &&
            items?.map((item: UploadFile, index: number) => {
              return (
                <IonCol size="12" key={index}>
                  <CardDi
                    item={item}
                    index={index}
                    handleIndexMiddle={(index) => {
                      setIndexInTheMiddle(index);
                      setNumberSwiper(index);
                    }}
                    scrollContainer={scrollContainerRef}
                    items={items}
                  />
                </IonCol>
              );
            })}
          {itemsAny &&
            itemsAny?.map((item: any, index: number) => {
              return (
                <IonCol size="12" key={index}>
                  <CardDi
                    item={item}
                    index={index}
                    handleIndexMiddle={(index) => {
                      setIndexInTheMiddle(index);
                      setNumberSwiper(index);
                    }}
                    scrollContainer={scrollContainerRef}
                    isAny={true}
                    items={items}
                  />
                </IonCol>
              );
            })}
        </IonRow>
        <IonModal isOpen={isShow} className="full-media padding-safearea">
          <IonButton
            fill="clear"
            color="light"
            className="btn-media"
            onClick={() => {
              setIsShow(false);
              console.log(false);
            }}
          >
            <IonIcon color="light" size="lg" className="icon-media" icon={closeCircleOutline}></IonIcon>
          </IonButton>
          <IonButton
            fill="clear"
            color="light"
            className="btn-prev"
            onClick={() => {
              swiperRef?.slidePrev();
            }}
          >
            <IonIcon color="light" className="icon-media" icon={arrowBackCircleOutline}></IonIcon>
          </IonButton>
          <IonButton
            fill="clear"
            color="light"
            className="btn-next"
            onClick={() => {
              swiperRef?.slideNext();
            }}
          >
            <IonIcon color="light" className="icon-media" icon={arrowForwardCircleOutline}></IonIcon>
          </IonButton>
          <Swiper
            modules={[Pagination]}
            zoom={true}
            spaceBetween={20}
            centeredSlidesBounds={true}
            centeredSlides={true}
            setWrapperSize={true}
            pagination={{
              clickable: true,
              type: 'bullets',
              renderBullet(index, className) {
                return `<span class="${className} ${items && items?.length <= 3 ? 'pagination-custom' : ''}"></span>`;
              },
            }}
            initialSlide={numberSwiper}
            onSlideChange={(swiper) => setNumberSwiper(swiper.activeIndex)}
            onSwiper={initSwiper}
          >
            {items &&
              items?.map((item: UploadFile, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <div>
                      {item && item?.mime.substring(0, 5) === 'image' && (
                        <img alt="Silhouette of mountains" style={{ borderRadius: '7px' }} src={item.url as string} />
                      )}
                      {item && item.mime.substring(0, 5) === 'video' && (
                        <VideoView
                          className="ion-border-radius-5"
                          url={item?.url as string}
                          autoPlay={true}
                          previewUrl={item?.previewUrl || ''}
                        ></VideoView>
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
            {itemsAny &&
              itemsAny?.map((item: any, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="ion-margin-top">
                      {item && item?.file?.type?.substring(0, 5) === 'image' && (
                        <img alt="Silhouette of mountains" style={{ borderRadius: '7px' }} src={item.url as string} />
                      )}
                      {item && item?.file?.type?.substring(0, 5) === 'video' && (
                        <VideoView
                          className="ion-border-radius-5"
                          url={item?.url as string}
                          autoPlay={true}
                          previewUrl={item?.previewUrl || ''}
                          file={item?.file}
                        ></VideoView>
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </IonModal>
      </IonGrid>
      <IonGrid>
        <IonRow style={{ justifyContent: 'center' }}>
          {items &&
            items.map((hit: any, index: number) => {
              return (
                <IonCol
                  size="auto"
                  key={index}
                  className={`trait-round ${items?.length > 5 ? 'trait-round-small' : ''}  ${
                    indexInTheMiddle === index ? 'filled' : 'outline'
                  }`}
                ></IonCol>
              );
            })}
          {itemsAny &&
            itemsAny.map((hit: any, index: number) => {
              return (
                <IonCol
                  size="auto"
                  key={index}
                  className={`trait-round ${itemsAny?.length > 5 ? 'trait-round-small' : ''}  ${
                    indexInTheMiddle === index ? 'filled' : 'outline'
                  }`}
                ></IonCol>
              );
            })}
        </IonRow>
      </IonGrid>
    </>
  );
};

export default SliderMedia;

interface ICard {
  index: number;
  handleIndexMiddle?: (index: number) => void;
  scrollContainer: any;
  item?: any;
  itemAny?: any;
  isAny?: boolean;
  items?: any;
}

const CardDi: React.FC<ICard> = ({ index, scrollContainer, handleIndexMiddle, item, isAny, items }) => {
  const elementRef: any = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const scrollContainerRef = scrollContainer.current;
      if (elementRef.current && scrollContainerRef) {
        const { scrollLeft, clientWidth } = scrollContainerRef;
        const elementRect = elementRef.current.getBoundingClientRect();

        const elementLeft = elementRect.left + scrollLeft;
        const elementRight = elementRect.right + scrollLeft;

        // Calculate the middle of the screen
        const middleOfScreen = scrollLeft + clientWidth / 2;

        // Check if the element is in the middle of the screen

        if (elementLeft <= middleOfScreen && elementRight >= middleOfScreen) {
          if (handleIndexMiddle) handleIndexMiddle(index);
        }
      }
    };

    const scrollContainerRef = scrollContainer.current;
    if (scrollContainerRef) {
      scrollContainerRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainerRef) {
        scrollContainerRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [items]);

  return (
    <>
      {!isAny ? (
        <div ref={elementRef} className="ion-margin-top">
          {item && item?.mime.substring(0, 5) === 'image' && (
            <img alt="Silhouette of mountains" style={{ borderRadius: '7px' }} src={item.url as string} />
          )}
          {item && item.mime.substring(0, 5) === 'video' && (
            <VideoView
              className="ion-border-radius-5"
              url={item?.url as string}
              autoPlay={true}
              previewUrl={item?.previewUrl || ''}
            ></VideoView>
          )}
        </div>
      ) : (
        <div className="ion-margin-top">
          {item && item?.file?.type?.substring(0, 5) === 'image' && (
            <img alt="Silhouette of mountains" style={{ borderRadius: '7px' }} src={item.url as string} />
          )}
          {item && item?.file?.type?.substring(0, 5) === 'video' && (
            <VideoView
              className="ion-border-radius-5"
              url={item?.url as string}
              autoPlay={true}
              previewUrl={item?.previewUrl || ''}
              file={item?.file}
            ></VideoView>
          )}
        </div>
      )}
    </>
  );
};
