import { useLazyQuery, useQuery } from '@apollo/client';
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonProgressBar,
  IonRow,
  IonText,
  IonChip,
  IonCol,
  IonSkeletonText,
  IonListHeader,
} from '@ionic/react';
import { format } from 'date-fns';
import styled from 'styled-components';
import SimpleHeader from '../../../components/SimpleHeader';
import { GET_EVENT_SALES } from '../../../graphql/queries/sale.graphql';
import { useDispoDetail } from '../../../hooks/useDispoDetail';
import { getEventBg } from '../../DispoDetail/utils';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

export const DispoContributions: React.FC = () => {
  const dispoData = useDispoDetail();
  const history = useHistory();
  let backgroundColor = getEventBg(dispoData);
  const progressValue = (dispoData.event?.attributes?.moneyRaised || 0) / dispoData?.event?.attributes?.moneyToRaise;

  const [load, { loading, data, refetch }] = useLazyQuery(GET_EVENT_SALES, {
    variables: {
      id: dispoData.id,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    load();
  }, []);

  return (
    <DispoContributionsWrapper>
      <IonContent>
        <div className="bg-primary full-height">
          <SimpleHeader
            title="Gestion des contributions"
            noButtonBack={false}
            showMenuButton={true}
            backButtonColor="text-black"
          ></SimpleHeader>

          <IonCard className="total" style={{ borderColor: backgroundColor }}>
            <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonText>
                      <h4>Total recolté</h4>
                    </IonText>
                    {loading ? (
                      <>
                        <IonSkeletonText animated style={{ width: '60%' }} />
                        <IonSkeletonText animated />
                      </>
                    ) : (
                      <h2>
                        {dispoData?.event?.attributes?.moneyRaised || 0}€/
                        {dispoData?.event?.attributes?.moneyToRaise || 0}€
                      </h2>
                    )}
                    <IonProgressBar value={progressValue} />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
          {loading ? (
            <div className="ion-padding custom-skeleton">
              <IonSkeletonText animated style={{ width: '60%' }} />
              <IonSkeletonText animated />
              <IonSkeletonText animated style={{ width: '88%' }} />
              <IonSkeletonText animated style={{ width: '70%' }} />
              <IonSkeletonText animated style={{ width: '30%' }} />
              <IonSkeletonText animated style={{ width: '80%' }} />
              <IonSkeletonText animated style={{ width: '40%' }} />
            </div>
          ) : (
            <IonList inset={true}>
              <IonListHeader>
                <IonLabel>Dernières contributions</IonLabel>
              </IonListHeader>
              {data?.DispoSales?.data.length ? (
                data?.DispoSales?.data?.map((item) => (
                  <IonItem key={item.id} button={false} detail={false}>
                    <IonLabel>
                      <div className="list__label">
                        <IonText onClick={() => history.push(`/main/profil/${item?.attributes?.buyer?.data?.id}`)}>
                          {item?.attributes?.buyer?.data?.attributes?.username}
                        </IonText>
                        <IonChip slot="end" style={{ color: backgroundColor }}>
                          +{(item?.attributes?.salePrice || 0) / 100} €
                        </IonChip>
                      </div>
                      <IonNote slot="end">{format(new Date(item?.attributes?.saleDate), 'dd/MM/yyyy')} </IonNote>
                    </IonLabel>
                  </IonItem>
                ))
              ) : (
                <IonItem className="ion-text-center empty-state">
                  <IonLabel color="dark">
                    Actuellement, ta dispo n'a reçu aucune
                    <br /> contribution. Pas de panique, cela va venir!
                  </IonLabel>
                </IonItem>
              )}
            </IonList>
          )}
        </div>
      </IonContent>
    </DispoContributionsWrapper>
  );
};
const DispoContributionsWrapper = styled(IonPage)`
  ion-card {
    &.total {
      border-radius: var(--Corner-16, 16px);
      border: 2px solid var(--Bleu, #3d3beb);
      background: var(--background-bleu, #ecebfd);
      h4 {
        color: var(--Text, #313333);
        margin: 5px 0;
      }
      h2 {
        margin: 7px 0;
        color: var(--Bleu, #3d3beb);
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 80% */
      }
    }
    ion-progress-bar {
      border-radius: 10px;
      height: 10px;
    }
  }
  ion-list {
    ion-list-header {
      /* H4 */
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 25.2px */
    }
    ion-item {
      border-bottom: 1px solid var(--gris-placeholder, #d9d9d9);
      ion-label {
        div.list__label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          ion-text {
            color: var(--Text, #313333);
            leading-trim: both;
            text-edge: cap;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
          }
          ion-chip {
            color: var(--Bleu, #3d3beb);
            leading-trim: both;
            text-edge: cap;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
          }
        }
        ion-note {
          float: right;
          color: var(--gris-light-grey, #727272);
          text-align: right;
          leading-trim: both;
          text-edge: cap;

          /* Lil text/12px Reg. */
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 116.667% */
        }
      }
      &.empty-state {
        ion-label {
          color: var(--gris-light-grey, #727272);
          text-align: center;
          leading-trim: both;

          text-edge: cap;
          /* P/16px Reg. */
          font-family: Outfit;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
        }
      }
    }
  }
`;
export default DispoContributions;
