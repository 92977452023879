import { useMutation } from '@apollo/client';
import { IonCard, IonCardContent, IonGrid, IonIcon, IonRow, IonText, IonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import fillStarSvg from '../../assets/icons/fillStar.svg';
import starSvg from '../../assets/icons/star.svg';
import { NOTE_EVENT } from '../../graphql/mutations/updateEventUserParticipation.graphql';
import { Enum_Eventuserparticipation_Status, EventUserParticipationEntity } from '../../models/gql/graphql';
import { __ } from '../../utils/traduction';

interface IDispoDetailContainer {
  scrollTop: number;
  bkColor: string;
  children: React.ReactNode;
  dateHasPassed?: Maybe<boolean>;
  eventUserParticipations?: EventUserParticipationEntity[];
  scrollFix: number;
  refetchParticipation?: () => void;
  isDeleted?: boolean;
  isEdit?: boolean;
}

const DispoDetailContainer: React.FC<IDispoDetailContainer> = ({
  bkColor,
  scrollTop,
  children,
  dateHasPassed,
  eventUserParticipations,
  scrollFix,
  refetchParticipation,
  isDeleted = false,
  isEdit = false,
}) => {
  const [stars, setStars] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>('');
  const [NoteEvent] = useMutation(NOTE_EVENT);
  const { id } = useParams<IVerifParams>();
  const location = useLocation();
  const addNoteToDispo = async (note: number) => {
    if (!stars) {
      setStars(note);

      if (
        eventUserParticipations &&
        eventUserParticipations.length &&
        eventUserParticipations[0].attributes?.status === Enum_Eventuserparticipation_Status.Accepted
      ) {
        try {
          await NoteEvent({
            variables: {
              id: eventUserParticipations[0].id,
              note: note,
            },
          });
        } catch (err: any) {
          setIsOpen(true);
          setMessageError(err.message);
        }
      }
    }
  };

  const allowNote = !!(
    !!dateHasPassed &&
    eventUserParticipations &&
    eventUserParticipations.length &&
    eventUserParticipations[0].attributes?.status === Enum_Eventuserparticipation_Status.Accepted
  );

  useEffect(() => {
    if (eventUserParticipations && eventUserParticipations.length && eventUserParticipations[0].attributes?.note) {
      setStars(eventUserParticipations[0].attributes.note);
    }
  }, [eventUserParticipations]);

  useEffect(() => {
    refetchParticipation && refetchParticipation();
  }, [location]);

  return (
    <>
      <IonRow
        style={{
          marginTop: isEdit
            ? `265px`
            : `${(scrollTop < 130 && scrollTop > 0) || !scrollTop ? Math.max(265 - scrollTop) : ''}px`,
          // marginTop: `265px`,
          zIndex: '50',
          background: `linear-gradient(180deg, ${bkColor} 0%, ${bkColor} ${
            (scrollTop && scrollTop < scrollFix) || !scrollTop ? 50 : 0
          }%, rgba(255, 255, 255, 1) ${(scrollTop && scrollTop < scrollFix) || !scrollTop ? 50 : 0}%)`,
          border: 'none',
          height: `${!isDeleted && allowNote ? '400px' : '200px'}`,
        }}
      >
        {!isDeleted && allowNote && (
          <IonGrid className="ion-no-margin ion-no-padding width-90">
            <IonCard className="border-radius box-shadow-custom max-height-90">
              <IonRow>
                <IonText className="text-size-lg text-blue font-outfit text-bold padding-10">
                  Comment était cette dispo ?
                </IonText>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                {[...Array(5)].map((_, index) => (
                  <IonIcon
                    icon={index < stars ? fillStarSvg : starSvg}
                    key={index}
                    className="startStyle text-yellow ion-no-padding"
                    style={{ color: 'yellow' }}
                    onClick={() => addNoteToDispo(index + 1)}
                  ></IonIcon>
                ))}
              </IonRow>
            </IonCard>
          </IonGrid>
        )}
      </IonRow>
      <IonRow
        className="margin-auto width-90"
        // style={{ marginTop: -Math.max((!scrollTop && allowNote ? 300 : 200) - scrollTop, 60) }}
        style={{ marginTop: -Math.max(!isDeleted && allowNote ? 300 : 200, 60) }}
      >
        <IonCard className="border-radius font-outfit box-shadow-custom">
          <IonCardContent>{children}</IonCardContent>
        </IonCard>
      </IonRow>
      <IonToast
        isOpen={isOpen}
        message={__(messageError)}
        onDidDismiss={() => setIsOpen(false)}
        duration={5000}
        color="danger"
      ></IonToast>
    </>
  );
};

export default DispoDetailContainer;
