import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonText,
  IonToast,
} from '@ionic/react';
import { arrowForwardOutline, ellipsisVerticalOutline } from 'ionicons/icons';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LikeComment, MenuPopover, Reporting } from '../../../components';
import { REPORTING_LIST } from '../../../constants';
import { useLikePost } from '../../../hooks/usePosts';
import { Enum_Report_Type, PostLike } from '../../../models/gql/graphql';
import { AppReducerContext, useAppStore } from '../../../store';
import { getUserId } from '../../../utils';
import { __ } from '../../../utils/traduction';
import { ComponentModal } from '../../Modals';
import PostComment from '../../PostComment';
import VideoView from '../../VideoView';
import { AlgoliaUserAvatar } from '../AlgoliaUser';
import { StringToHtml } from './StringToHtml';
import { useDispo } from '../../../hooks';
import { Enum_segment_home } from '../../../store/AppReducerProvider/type';
interface CardPostProps {
  id: string;
  medias: { mime: string; url: string }[];
  description: string;
  creatorId: string;
  creatorFirstname: string;
  creatorLastname: string;
  commentsCount: number;
  creatorUsername: string;
  isPro: boolean;
  creatorCompanyName: string;
  creatorPicture: string;
  nbComments?: number;
  nbLikes?: number;
  myLikes: PostLike[];
  setFiles?: (value: any) => void;
  setSelectedPost: (value: string) => void;
  refetchLike: () => void;
  refreshList?: () => void;
  margin?: boolean;
}
const CardPost: React.FC<any> = ({
  id,
  medias,
  description,
  creatorId,
  creatorFirstname,
  creatorLastname,
  creatorUsername,
  isPro,
  creatorCompanyName,
  nbComments,
  nbLikes,
  creatorPicture,
  myLikes,
  setFiles,
  setSelectedPost,
  refetchLike,
  refreshList,
  margin,
}: CardPostProps) => {
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);
  const [likes, setLikes] = useState(0);
  const [comments, setComments] = useState(0);
  const [isComment, setIsComment] = useState<boolean>(false);
  const [actualPost, setOpenPost] = useState<string | null>(null);
  const { dispatch } = useContext(AppReducerContext);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const activeSlideIndexRef = useRef(0);
  const { setRedirectHome } = useDispo();
  const {
    RootStore: { unfollowUser },
  } = useAppStore();

  useEffect(() => {
    if (nbLikes) setLikes(nbLikes);
    if (nbComments) setComments(nbComments);
  }, [nbLikes, nbComments]);

  const getUserName = () => {
    return creatorUsername ? creatorUsername : `${creatorFirstname} ${creatorLastname}`;
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();

  const { like, messageError, loadingLike, loadingUnlike } = useLikePost(id, refetchLike);

  const userLikePost: PostLike = (myLikes ? myLikes.find((like) => like.idPost === id.toString()) : null) as PostLike;

  useEffect(() => {
    if (messageError !== '') {
      setIsOpen(true);
    }
  }, [messageError]);

  const [openModal, setOpenModal] = useState(false);

  const [openReporting, setOpenReporting] = useState(false);

  const listItems = useMemo(
    () => [
      {
        label: 'Signaler cette publication',
        action: () => {
          setOpenReporting(true);
          setOpenModal(false);
        },
      },
      {
        label: 'Se désabonner de cet utilisateur',
        action: async () => {
          await unfollowUser(creatorId, getUserId()!);
          history.push('/main/profil/' + creatorId);
          // if (refreshList) setTimeout(() => refreshList(), 500)
        },
      },
      {
        label: 'Consulter le profil',
        action: () => history.push('/main/profil/' + creatorId),
      },
    ],
    [unfollowUser, creatorId, history]
  );

  const handleVideoClick = (e: any) => {
    const isVideoControl = e.target.closest('.video-view') !== null;
    const isImage = e.target.closest('.post-container-image') !== null;

    if (!isVideoControl && isImage) {
      dispatch({ type: 'SET_FULL_SLIDER', payload: true });
      setFiles && setFiles(medias);
    }
  };

  useEffect(() => {
    const openPost = new URLSearchParams(location.search).get('open');
    if (openPost && Number(id) === Number(openPost)) {
      setOpenPost(openPost);
      setIsComment(true);
    }
  }, [location.search]);
  const scrollContainerRef = useRef(null);

  const [indexInTheMiddle, setIndexInTheMiddle] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        const { scrollLeft, clientWidth, scrollWidth } = scrollContainer;
        // Check if scrolled to the end
        const isEnd = 0 <= scrollWidth - (scrollLeft + clientWidth) && scrollWidth - (scrollLeft + clientWidth) <= 0.5;
        if (isEnd) {
          // PUT FUNCTION HERE
        }
      }
    };

    // Attach the scroll event listener
    const scrollContainer = scrollContainerRef.current as any;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    // Cleanup function
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [medias]);

  const activeSlideIsImage = (medias && medias[activeSlideIndex]?.mime?.startsWith('image')) || false;
  return (
    <CardPostWrapper>
      <IonRow className={`ion-margin-bottom ${margin ? 'ion-margin-horizontal' : ''}`} style={{ position: 'relative' }}>
        {creatorId === getUserId()?.toString() && (
          <IonButton
            fill="clear"
            onClick={() => {
              setSelectedPost(id as string);
            }}
            id={`post-context-menu-${id}`}
            className="float-right text-white"
            style={{
              position: 'absolute',
              top: activeSlideIsImage ? '0' : '20px',
              right: '0',
              zIndex: 50,
            }}
          >
            <IonIcon icon={ellipsisVerticalOutline}></IonIcon>
          </IonButton>
        )}
        <IonCard className="ion-no-margin full card-no-border border-radius-20" style={{ position: 'relative' }}>
          {!(creatorId === getUserId()) && (
            <IonButton
              fill="clear"
              onClick={() => setOpenModal(true)}
              className="relative-block"
              style={{
                top: activeSlideIsImage ? '0' : '20px',
              }}
            >
              <IonIcon icon={ellipsisVerticalOutline} color="dark"></IonIcon>
            </IonButton>
          )}
          <ComponentModal onCancel={() => setOpenModal(false)} isOpen={openModal} height="300px">
            <IonContent className="ion-padding">
              <IonList lines="none">
                {listItems.map((item, index) => (
                  <IonItem
                    key={index}
                    style={
                      index !== listItems.length - 1
                        ? {
                            borderBottom: '2px solid #D9D9D9',
                          }
                        : {}
                    }
                    onClick={item.action}
                  >
                    <IonLabel text-wrap>
                      <IonRow>
                        <IonCol>
                          <IonRow className="label-reporting font-outfit">{item.label}</IonRow>
                        </IonCol>

                        <IonCol className={`blue-icon margin-r-5 text-size-16`} size="1">
                          <IonIcon icon={arrowForwardOutline}></IonIcon>
                        </IonCol>
                      </IonRow>
                    </IonLabel>
                  </IonItem>
                ))}
              </IonList>
              <IonButton
                expand="block"
                fill="clear"
                className="d-absolute-bottom ion-padding-horizontal text-underligne text-black"
                onClick={() => setOpenModal(false)}
              >
                Annuler
              </IonButton>
            </IonContent>
          </ComponentModal>
          <Reporting
            actionCancel={() => setOpenReporting(false)}
            listItems={REPORTING_LIST}
            isOpen={openReporting}
            contentTypeReported={Enum_Report_Type.Publication}
            title="Pour quel motif veux-tu effectuer ce signalement ?"
            relatedId={id}
            userId={creatorId}
          ></Reporting>
          <IonCardContent className="ion-no-padding">
            <div style={{ touchAction: 'pan-y' }}>
              {/* <Swiper
                ref={scrollContainerRefAround}
                modules={[Pagination]}
                spaceBetween={20}
                centeredSlidesBounds={true}
                centeredSlides={true}
                setWrapperSize={true}
                pagination={{
                  clickable: true,
                  type: 'bullets',
                  renderBullet(index, className) {
                    return `<span class="${className} ${
                      medias && medias?.length <= 3 ? 'pagination-custom' : ''
                    }"></span>`;
                  },
                }}
                onClick={(swiper, event) => {
                  handleVideoClick(event);
                }}
                onSlideChange={(swiper) => {
                  activeSlideIndexRef.current = swiper.activeIndex;
                  setActiveSlideIndex(swiper.activeIndex);
                }}
                onTouchStart={(swiper, event) => {
                  event.preventDefault();
                }}
                className="full"
              >
                {medias?.length && (
                  <>
                    {medias.map((file: any, index: any) => (
                      <SwiperSlide key={index}>
                        {file?.url && (
                          <div className="post-container-image">
                            {file?.mime.substring(0, 5) === 'image' ? (
                              <img alt="slide" src={file?.url} className="full-width h-300 image-card-custom" />
                            ) : (
                              <>
                                {activeSlideIndex === index && (
                                  <VideoView
                                    url={file.url}
                                    previewUrl={file.previewUrl}
                                    autoPlay={false}
                                    index={Number(id)}
                                    className="full h-300"
                                    videoClassName="full h-300 image-card-custom"
                                  ></VideoView>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </SwiperSlide>
                    ))}
                  </>
                )}
              </Swiper> */}
              <IonGrid
                ref={scrollContainerRef}
                className="hide-scrollBar"
                style={{ overflow: 'auto', padding: 0, paddingBottom: 10 }}
              >
                <IonRow
                  onClick={handleVideoClick}
                  className="col-card"
                  style={{ flexWrap: 'nowrap', padding: 0, alignItems: 'center' }}
                >
                  {/* Vos éléments à faire défiler horizontalement */}
                  {medias?.length &&
                    medias?.map((file: any, index: any) => {
                      return (
                        <IonCol size="12" key={index}>
                          <CardDi
                            file={file}
                            index={index}
                            handleIndexMiddle={(index) => setIndexInTheMiddle(index)}
                            scrollContainer={scrollContainerRef}
                            id={id}
                            activeSlideIndex={activeSlideIndex}
                            files={medias}
                          />
                        </IonCol>
                      );
                    })}
                </IonRow>
              </IonGrid>
              <IonGrid>
                <IonRow style={{ justifyContent: 'center', display: medias?.length <= 1 ? 'none' : '' }}>
                  {medias?.length &&
                    medias?.map((hit: any, index: number) => {
                      return (
                        <IonCol
                          size="auto"
                          key={index}
                          className={`trait-round ${medias?.length > 5 ? 'trait-round-small' : ''}  ${
                            indexInTheMiddle === index ? 'filled' : 'outline'
                          }`}
                        ></IonCol>
                      );
                    })}
                </IonRow>
              </IonGrid>
            </div>
            <IonRow className="ion-align-items-center margin-l-5">
              <IonCol size="8.5">
                {/*<UserDetail
              avatarStyle="width-30 height-30 ion-margin-end"
              user={post.attributes?.author?.data?.attributes} 
            />*/}
                <div
                  className="d-flex"
                  onClick={async () => {
                    await setRedirectHome(Enum_segment_home.feed);
                    history.push('/main/profil/' + creatorId);
                  }}
                >
                  <AlgoliaUserAvatar
                    style={{ height: '30px', width: '30px' }}
                    imgUrl={creatorPicture}
                    slot="start"
                  ></AlgoliaUserAvatar>
                  <IonLabel className="ion-padding">{!isPro ? getUserName() : creatorCompanyName}</IonLabel>
                </div>
              </IonCol>
              <IonCol size="3.5" className="ion-justify-content-end">
                <LikeComment
                  actionComment={() => {
                    setOpenPost(id);
                  }}
                  nbComment={comments || 0}
                  nbLike={likes || 0}
                  likeLoading={loadingLike || loadingUnlike}
                  userLikePost={userLikePost}
                  actionLike={async () => {
                    try {
                      await like(userLikePost);
                      if (userLikePost) {
                        if (likes) setLikes((n) => n - 1);
                      } else {
                        setLikes((n) => n + 1);
                      }
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow
              className="text-gray-400 font-outfit full"
              onClick={() => {
                setOpenPost(id);
              }}
            >
              <IonText className="ion-padding  text-capitalize">
                {StringToHtml(description || '', showMore, setShowMore)}
              </IonText>
            </IonRow>
            <IonToast
              isOpen={isOpen}
              message={__(messageError)}
              onDidDismiss={() => setIsOpen(false)}
              duration={5000}
              color="danger"
            ></IonToast>
          </IonCardContent>
        </IonCard>
      </IonRow>
      <ComponentModal
        isOpen={!!actualPost}
        onCancel={() => {
          setOpenPost(null);
        }}
        height="100%"
        noBorderRadius={true}
        useBackgroundGrey={true}
      >
        {!!actualPost && (
          <PostComment
            id={actualPost}
            refetchLike={refetchLike}
            cancel={(nbComments, nbLikes = 0) => {
              // here should get the
              if (isComment) {
                history.replace('/main/post/' + id);
              }
              setComments(nbComments || 0);
              setLikes(nbLikes);
              if (likes !== nbLikes) {
                // here refetch
                refetchLike();
              }
              setOpenPost(null);
              setIsComment(false);
            }}
            userLikePost={userLikePost}
          />
        )}
      </ComponentModal>
    </CardPostWrapper>
  );
};

const CardPostWrapper = styled('div')`
  ion-button.reactions {
    --padding-start: 0;
    --padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
  }
`;
export default CardPost;

interface ICard {
  index: number;
  handleIndexMiddle?: (index: number) => void;
  scrollContainer: any;
  file?: any;
  activeSlideIndex: any;
  id: any;
  files?: any;
}

const CardDi: React.FC<ICard> = ({ index, scrollContainer, handleIndexMiddle, file, id, activeSlideIndex, files }) => {
  const elementRef: any = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const scrollContainerRef = scrollContainer.current;
      if (elementRef.current && scrollContainerRef) {
        const { scrollLeft, clientWidth } = scrollContainerRef;
        const elementRect = elementRef.current.getBoundingClientRect();

        const elementLeft = elementRect.left + scrollLeft;
        const elementRight = elementRect.right + scrollLeft;

        // Calculate the middle of the screen
        const middleOfScreen = scrollLeft + clientWidth / 2;

        // Check if the element is in the middle of the screen

        if (elementLeft <= middleOfScreen && elementRight >= middleOfScreen) {
          if (handleIndexMiddle) handleIndexMiddle(index);
        }
      }
    };

    const scrollContainerRef = scrollContainer.current;
    if (scrollContainerRef) {
      scrollContainerRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainerRef) {
        scrollContainerRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [files]);

  return (
    <>
      {file?.url && (
        <div ref={elementRef} className="post-container-image">
          {file?.mime.substring(0, 5) === 'image' ? (
            <img alt="slide" src={file?.url} className="full-width h-300 image-card-custom" />
          ) : (
            <>
              {activeSlideIndex === index && (
                <VideoView
                  url={file.url}
                  previewUrl={file.previewUrl}
                  autoPlay={false}
                  index={Number(id)}
                  className="full h-300"
                  videoClassName="full h-300 image-card-custom"
                ></VideoView>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
