import { IonCol, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import * as Pages from './pages';
import { Redirect, Route, useHistory, useLocation } from 'react-router';
import React, { useEffect, useState } from 'react';
import { isAuthenticated, StorageUtils } from './utils';
import { appTabsItems } from './constants';
import { useAppStore } from './store';
import { useNotifications } from './hooks/useNotifications';
import { useDispo } from './hooks';

const MainTabs = () => {
  const { unseenNotificationsLength, unseenMessagesLength, newMessage } = useNotifications();
  const history = useHistory();
  const location = useLocation();
  const { setReloadHome } = useDispo();
  const renderAuth = (Component: JSX.Element, location?: any): JSX.Element => {
    if (!isAuthenticated() && Component.type.name !== 'MainTabs' && Component.type.name !== 'Menu') {
      if (location && location.pathname !== '/send-phone') StorageUtils.set('backUrl', location);
      return <Redirect to="/on-boarding" />;
    }
    return Component;
  };

  // use to redirect use if logued
  const renderGuest = (Component: JSX.Element): JSX.Element => {
    /* #TODO to implement */
    /* if (isAuthenticated()) {
      return <Redirect to="/home" />;
    }*/
    return Component;
  };
  const handleClick = (url: string): void => {
    history.replace(`/main/${url}`);
  };

  const clickToRefresh = (id: string) => {
    if (id === 'home') {
      setReloadHome(true);
    }
  };
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/main/main" to="/home" />
        <Route exact path="/main/home" render={({ location }) => <Pages.Home />} />
        <Route
          exact
          path="/main/chat"
          render={({ location }) => renderAuth(<Pages.Messenger wording={Pages.MessengerWording} />)}
        />
        <Route exact path="/main/chat/:chatId" render={({ location }) => renderAuth(<Pages.Chat />)} />
        <Route exact path="/main/group/:dispoId" render={({ location }) => renderAuth(<Pages.Chat />)} />

        <Route exact path="/main/notifications/:id" render={({ location }) => renderAuth(<Pages.Notifications />)} />
        {/** Account */}
        <Route exact path="/main/my-account/menu" render={({ location }) => renderAuth(<Pages.Menu />)} />
        <Route exact path="/main/my-account/menu/infos" render={({ location }) => renderAuth(<Pages.Informations />)} />
        <Route
          exact
          path="/main/my-account/menu/user-bloqued"
          render={({ location }) => renderAuth(<Pages.UserBloqued />)}
        />
        <Route exact path="/main/my-account/menu/interest" render={({ location }) => renderAuth(<Pages.Interest />)} />
        <Route exact path="/main/my-account/menu/contact" render={({ location }) => renderAuth(<Pages.Contact />)} />
        <Route
          exact
          path="/main/my-account/menu/contact/new"
          render={({ location }) => renderAuth(<Pages.NewContact />)}
        />

        {/**Dispos */}
        <Route exact path="/main/dispos" render={({ location }) => renderAuth(<Pages.Dispos />)} />
        <Route exact path="/main/dispos/:id" render={({ location }) => renderAuth(<Pages.DispoDetail />)} />
        <Route exact path="/main/post/:id" render={({ location }) => renderAuth(<Pages.PostDetail />)} />
        <Route
          exact
          path="/main/dispos/:id/participants"
          render={({ location }) => renderAuth(<Pages.DispoParticipants />)}
        />
        <Route exact path="/main/dispos/:id/inviteds" render={({ location }) => renderAuth(<Pages.DispoInviteds />)} />
        <Route
          exact
          path="/main/dispos/:id/inscription-success"
          render={({ location }) => renderAuth(<Pages.InscriptionSuccess />)}
        />
        <Route
          exact
          path="/main/dispos/:id/inscription-failed"
          render={({ location }) => renderAuth(<Pages.InscriptionFailed />)}
        />
        <Route exact path="/main/dispos/:id/pre-order" render={({ location }) => renderAuth(<Pages.PreOrder />)} />
        <Route exact path="/main/dispos/:id/contribute" render={({ location }) => renderAuth(<Pages.Contribute />)} />
        <Route
          exact
          path="/main/dispos/:id/payment-success"
          render={({ location }) => renderAuth(<Pages.PaymentSuccess />)}
        />
        <Route exact path="/main/dispos/:id/edit" render={({ location }) => renderAuth(<Pages.EditDispo />)} />
        <Route
          exact
          path="/main/dispos/:id/edit/payments"
          render={({ location }) => renderAuth(<Pages.DispoPayments />)}
        />
        <Route
          exact
          path="/main/dispos/:id/edit/contributions"
          render={({ location }) => renderAuth(<Pages.DispoContributions />)}
        />
        <Route
          exact
          path="/main/dispos/:id/edit/interests"
          render={({ location }) => renderAuth(<Pages.EditDispoInterests />)}
        />
        <Route
          exact
          path="/main/dispos/:id/edit-options"
          render={({ location }) => renderAuth(<Pages.EditDispoOptions />)}
        />
        <Route
          exact
          path="/main/dispos/:id/edit-infos"
          render={({ location }) => renderAuth(<Pages.EditDispoInfos />)}
        />
        <Route
          exact
          path="/main/dispos/:id/edit-photo"
          render={({ location }) => renderAuth(<Pages.EditDispoPhotos />)}
        />
        <Route
          exact
          path="/main/dispos/:id/invitations"
          render={({ location }) => renderAuth(<Pages.GestionInvitations />)}
        />
        <Route
          exact
          path="/main/dispos/:id/new-invitations"
          render={({ location }) => renderAuth(<Pages.GestionNewInvitations />)}
        />
        <Route
          exact
          path="/main/dispos/:id/duplicate"
          render={({ location }) => renderAuth(<Pages.DuplicateDispo />)}
        />

        <Route
          exact
          path="/main/home/create-dispo/step-info"
          render={({ location }) => renderAuth(<Pages.StepInfo />)}
        />
        <Route
          exact
          path="/main/home/create-dispo/step-invitation"
          render={({ location }) => renderAuth(<Pages.StepInvitation />)}
        />
        <Route
          exact
          path="/main/home/create-dispo/step-photo"
          render={({ location }) => renderAuth(<Pages.StepPhoto />)}
        />
        <Route
          exact
          path="/main/home/create-dispo/step-tarif"
          render={({ location }) => renderAuth(<Pages.StepTarif />)}
        />
        <Route
          exact
          path="/main/home/create-dispo/step-options"
          render={({ location }) => renderAuth(<Pages.StepOptions />)}
        />
        <Route exact path="/main/home/create-dispo/preview" render={({ location }) => renderAuth(<Pages.Preview />)} />
        <Route
          exact
          path="/main/home/create-dispo/confirmed"
          render={({ location }) => renderAuth(<Pages.Confirmed />)}
        />
        <Route exact path="/main/my-account/pdp/:edit?" render={({ location }) => renderAuth(<Pages.ConfigPDP />)} />
        <Route exact path="/main/profil/:id?" render={({ location }) => renderAuth(<Pages.Profil />)} />
        <Route
          exact
          path="/main/myprofil/followers"
          render={({ location }) => renderAuth(<Pages.SubscriptionsFollowers />)}
        />
        <Route
          exact
          path="/main/myprofil/followups"
          render={({ location }) => renderAuth(<Pages.SubscriptionsFollowups />)}
        />

        <Route exact path="/main/posts/:id/:postId?" render={({ location }) => renderAuth(<Pages.UserPosts />)} />
        <Route
          exact
          path="/main/reporting/:categorie?/:type?/:relatedId?/:userId?/:postCommentId?/:postUserId?"
          render={({ location }) => renderAuth(<Pages.Reporting />)}
        />
      </IonRouterOutlet>

      {/* This render the tabs menu */}
      <IonTabBar slot="bottom" id="main-tab" mode="md" style={{ contain: 'size layout' }}>
        {appTabsItems.map((tab) => (
          <IonTabButton
            mode="md"
            style={{ position: 'relative', width: '20%' }}
            key={tab.id}
            tab={tab.id}
            href={tab.url}
            onClick={() => clickToRefresh(tab.id)}
          >
            <div
              className="tab-button-container"
              style={
                location.pathname === tab.url
                  ? {
                      paddingTop: '10px',
                      borderBottom: '3px solid blue',
                      paddingBottom: '5px',
                    }
                  : {}
              }
            >
              {tab.id === 'notifications' && unseenNotificationsLength() > 0 && (
                <div className="bullet-container-notif">
                  <span>{unseenNotificationsLength()}</span>
                </div>
              )}
              {tab.id === 'messages' && newMessage && newMessage > 0 ? (
                <div className="bullet-container-notif">
                  <span>{newMessage}</span>
                </div>
              ) : (
                ''
              )}
              {tab.iconUrl && (
                <div>
                  <img src={location.pathname === tab.url ? tab.selectedIconUrl : tab.iconUrl} alt="" />
                </div>
              )}
              {tab.title && (
                <IonLabel className={`ion-text-menu font-nunito ${location.pathname === tab.url ? 'text-blue' : ''}`}>
                  {tab.title}
                </IonLabel>
              )}
            </div>
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  );
};
export default MainTabs;
