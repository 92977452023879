import { graphql } from '../../models/gql';
export const GET_USER = graphql(/* GraphQL */ `
  query getUser($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          phone
          username
          isPro
          email
          firstname
          lastname
          birthDate
          companyName
          siret
          job
          feedType
          blockedUsers {
            data {
              id
            }
          }
          blockedByUsers {
            data {
              id
            }
          }
          avatar {
            data {
              id
              attributes {
                image {
                  data {
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
              }
            }
          }
          profilePicture {
            data {
              id
              attributes {
                name
                url
                mime
                hash
                size
                provider
              }
            }
          }
          interests {
            data {
              id
              attributes {
                name
                picto {
                  data {
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const GET_MY_INTEREST = graphql(/* GraphQL */ `
  query getMyInterest {
    myInterest {
      myInterests {
        id
        attributes {
          name
          picto {
            data {
              id
              attributes {
                name
                url
                mime
                hash
                size
                provider
              }
            }
          }
        }
      }
      otherInterests {
        id
        attributes {
          name
          picto {
            data {
              id
              attributes {
                name
                url
                mime
                hash
                size
                provider
              }
            }
          }
        }
      }
    }
  }
`);

export const GET_USER_MAIN_INFO = graphql(/* GraphQL */ `
  query getUserMainInfo($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          username
          isPro
          email
          firstname
          lastname
          companyName
          avatar {
            data {
              id
              attributes {
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          profilePicture {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`);

export const GET_USER_CHAT_NOTIFICATION_SETTING = graphql(/* GraphQL */ `
  query getUserChatNotificationSetting($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          chatNotificationMutes {
            duration
            startDatetime
            message {
              data {
                id
                attributes {
                  content
                  firebaseId
                }
              }
            }
          }
        }
      }
    }
  }
`);
