/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */
import {
  IonAvatar,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSpinner,
} from '@ionic/react';
import { useAppStore } from '../../store';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { ParseMayaCode } from './ParseMayaCode';
import { Header } from '../../components';
import { useMessages } from '../../hooks/useMessages';
import { cloneDeep } from 'lodash';
import { getUserId } from '../../utils';

export interface IMessengerWording {
  title: string;
  noMessages: string;
  placeholder: string;
  acceptedOffer: string;
  refusedOffer: string;
}

export interface IMessengerProps {
  className?: string;
  wording: IMessengerWording;
}

const Messenger: React.FC<IMessengerProps> = ({ className, wording }) => {
  const history = useHistory();
  const {
    MessengerStore: { setMsg, msg, handleSelectConversation, user, setRefetchMsgList, dataFixed },
  } = useAppStore();

  const { getMessagesFirebaseIds, messages }: any = useMessages();

  const [searchLoading, setSearchLoading] = useState(false);

  const filterMessages = (msgs: any[]) => {
    const msgsFirebaseIds = msgs.map((msg: any) => msg?.attributes?.firebaseId);
    const filteredMessages = dataFixed?.filter((m: any) => {
      return msgsFirebaseIds.includes(m.id);
    });
    setMsg(filteredMessages);
  };
  useEffect(() => {
    console.log({ dataFixed });
  }, [dataFixed]);

  const handleSearchDirect = (event: any) => {
    setSearchLoading(true);
    const keywords = event?.detail?.value;
    if (keywords.trim().length) {
      const lowercaseKeywords = keywords.toLowerCase();
      const filteredMessages = dataFixed?.filter((m: any) => {
        if (m.dispoName) {
          return m.dispoName.toLowerCase().includes(lowercaseKeywords);
        }
        const idUserNotMe = m.participantsIds.filter((id: any) => id !== getUserId()?.toString());
        return m[idUserNotMe]?.username?.toLowerCase().includes(lowercaseKeywords);
      });
      setMsg(filteredMessages);
    } else {
      setMsg(dataFixed);
    }
    setSearchLoading(false);
  };

  const handleSearch = async (event: any) => {
    setSearchLoading(true);
    const keywords = event?.detail?.value;
    if (keywords.trim().length) {
      const lowercaseKeywords = keywords.toLowerCase();
      console.log({ lowercaseKeywords, keywords });
      getMessagesFirebaseIds({
        variables: {
          filters: {
            or: [
              {
                participants: {
                  username: {
                    containsi: lowercaseKeywords,
                  },
                },
              },
              {
                dispoName: {
                  containsi: lowercaseKeywords,
                },
              },
              {
                content: {
                  containsi: lowercaseKeywords,
                },
              },
            ],
          },
        },
      })
        .then((result: any) => {
          filterMessages(result?.data?.messages?.data || []);
          setSearchLoading(false);
        })
        .catch((err: any) => {
          console.error('Error while fetching searched message', err);
          setSearchLoading(false);
        });
    } else {
      setRefetchMsgList(true);
      setSearchLoading(false);
    }
  };

  const handleMsgClick = (msgObj: IMsg) => {
    handleSelectConversation(msgObj, (peerId: string) => {
      const id = msgObj.isDispo ? msgObj?.id?.split('_')[1] : peerId;
      const redirectUrl = msgObj.isDispo ? `/main/group/${id}` : `/main/chat/${id}`;
      history.push(redirectUrl);
    });
  };

  useEffect(() => {
    if (messages?.length) {
      filterMessages(messages);
    }
  }, [messages]);

  return (
    <MessengerWrapper className={`messenger ${className || ''}`}>
      <Header title="Mes Messages" noButtonBack={true} />
      {
        <IonContent className="messenger__container">
          <div className="custom-margin-container">
            <div className="cust-search-bar">
              <IonSearchbar debounce={700} mode="md" placeholder="Recherche..." onIonInput={handleSearchDirect} />
              {searchLoading && <IonSpinner className="search-bar_loading-spinner" name="crescent" />}
            </div>
            {!!msg?.length &&
              msg.map((m: any) => {
                const isMeLastSender = user?.id === m?.lastSender?.id;
                const peerId = (m.participantsIds || []).find((_id: string) =>
                  isMeLastSender ? _id !== m?.lastSender?.id : _id === m?.lastSender?.id
                );
                const isDispo = m?.isDispo || false;
                const imgUrl =
                  (isDispo ? m?.dispoImageUrl : !isMeLastSender ? m?.lastSender?.photoUrl : m[peerId]?.photoUrl) ||
                  null;
                return (
                  <div
                    role="button"
                    className={`messenger__item ${
                      Array.isArray(m?.seens) && !m?.seens.includes(user.id) && m.lastSender !== user.id
                        ? 'messenger__item-unseen'
                        : ''
                    }`}
                    onClick={() => handleMsgClick(m)}
                    key={m.id}
                  >
                    {/* <span className="messenger__time">{m.last}</span> */}
                    <div className="messenger__avatar">
                      {imgUrl && (
                        <IonAvatar style={{ width: '48px', height: '48px' }}>
                          <IonImg src={imgUrl} alt="avatar" />
                        </IonAvatar>
                      )}
                    </div>
                    <div
                      className={`messenger__item-content ${
                        Array.isArray(m?.seens) && !m?.seens?.includes(user?.id) && m?.lastSender?.id !== user?.id
                          ? 'messenger__item--bold'
                          : ''
                      }`}
                    >
                      <div className="messenger__username">
                        {isDispo
                          ? m?.dispoName
                          : !isMeLastSender
                          ? m?.lastSender?.username || m?.lastSender?.attributes?.username || ''
                          : m[peerId]?.username || m[peerId]?.attributes?.username || ''}
                      </div>
                      <div className="messenger__lastbody">
                        {ParseMayaCode(m.lastbody || '', user?.id)[0].replace(/<[^>]*>?/gm, '')}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </IonContent>
      }
      {!msg.length && (
        <IonGrid className="messenger--nomsg">
          <IonRow>
            <IonCol className="messenger__no-msg-text">{wording?.noMessages}</IonCol>
          </IonRow>
        </IonGrid>
      )}
    </MessengerWrapper>
  );
};

export const MessengerWrapper = styled(IonPage)`
  &.messenger {
    .custom-margin-container {
      margin: 5px;
      padding: 3px;
      background-color: #fff;
      padding-top: 0px;
      position: relative;
    }
    .cust-search-bar {
      background-color: white;
      position: sticky;
      z-index: 50;
      top: -20px;
    }
    .messenger__container {
      --padding-top: 15px;
      --padding-bottom: 15px;
      --padding-start: 15px;
      --padding-end: 15px;
    }
    .search-bar_loading-spinner {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15%;
    }
    .messenger--nomsg {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .messenger__no-msg-text {
      text-align: center;
    }

    .messenger__item {
      height: 65px;
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 10px;
      padding: 8px;
      display: flex;
      align-items: center;
      position: relative;
    }

    .messenger__item-unseen {
      background-color: rgba(3, 176, 112, 0.1);
    }

    .messenger__avatar {
      width: 48px;
      height: 48px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ccc;
    }

    .messenger__item-content div:first-child {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
    }

    .messenger__item-content div:last-child {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
    }

    .messenger__item--bold div {
      font-weight: 700 !important;
    }

    .messenger__time {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: #9c9f9f;
      position: absolute;
      right: 11px;
      top: 11px;
    }
    .messenger__lastbody {
      width: 60vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .messenger__username {
      width: 50vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (min-width: 992px) {
    &.messenger {
    }
  }
`;

export default Messenger;
