const words = {
  buyer: {
    'ad-sold': [
      "🚀 <b>Votre paiement a été effectué</b><br>Vous venez d’acheter l’article “{adTitle}“ à <b>{adPrice}</b>. <br><span class='text-whine'>Échangez dès maintenant avec le vendeur pour convenir de la livraison</span>",
    ],
    'ad-favorited': ['💪 Vous venez de mettre en favori l\'annonce "<b>{adTitle}</b>"'],
    'ad-reception': [
      "✅ <b>Vous venez de confirmer la réception de votre achat</b><br><span class='text-whine'>Merci à vous, {ownerName} aura très bientôt le paiement de votre achat.<br></span>",
      '/tabs/home',
      "Voir d'autres annonces",
    ],
    'reminder-reception': [
      "🧐 Avez-vous récupéré votre achat <b>“{adTitle}“</b> ?<br><span  class='text-whine'>Si oui, merci de l’indiquer l’onglet “Historique de mes achats” dans “Mon compte“. </span>",
      '/user/history/purchase',
      'En savoir plus',
    ],
  },
  seller: {
    'ad-sold': [
      "💸 <b>Paiement réalisé par {buyerName} ! </b><br/>L’acheteur {buyerName} a acheté votre annonce d’un montant de {adPrice}. <br><span class='text-whine'>Pour recevoir le paiement, vous devez renseigner votre IBAN, nous transmettre les documents légaux. Le paiement sera déclenché une fois que l’acheteur aura réceptionné son produit.</span>",
    ],
    'ad-favorited': ['💪 {byName} vient de mettre en favori votre annonce  "<b>{adTitle}</b>"'],
    'ad-reception': [''],
    'reminder-reception': [
      "💪 Si vous avez déjà livré votre produit, vous allez recevoir votre paiement une fois l'acheteur l'indiquera",
    ],
  },
  reception: {
    yes: [
      "✅ <b>Le paiement est déclenché.</b><br/><span class='text-whine'>{buyerName} vient de réceptionner son produit “{adTitle}“.</span><br>Le paiement est déclenché. Vous allez recevoir dans moins de 72h l’argent sur votre compte bancaire.",
      '/ad/step1/new',
      'Créer une nouvelle annonce',
    ],
    nobank: [
      "❌ <b>IBAN manquant, votre paiement est en attente</b><br/><span class='text-whine'>Le paiement est en attente car vous n’avez pas renseigné votre IBAN.</span>",
      '/user/payments-methods',
      'Ajouter mon IBAN',
    ],
    nomangopay: [
      "❌ <b>Documents légaux manquants, votre paiement est en attente</b><br/><span class='text-whine'>Le paiement est en attente car vous n’avez pas renseigné les documents légaux.</span>",
      '/user/documents',
      'Ajouter mes documents',
    ],
    any: [
      "❌ <b>IBAN  et documents légaux manquants, votre paiement est en attente</b><br/><span class='text-whine'>Le paiement est en attente car vous n’avez pas renseigné votre IBAN et les documents légaux.</span>",
      '/user/payments-methods',
      'Ajouter mon IBAN',
      '/user/documents',
      'Ajouter mes documents',
    ],
  },
};

export const ParseMayaCode = (code: string, userId: string): string[] => {
  const matches = code.match(/\[.*?\]/g);
  if (!matches) return [code];
  if (matches && matches.length) {
    const regex = / (\w+)=/g;
    const attrArr = code.match(regex);
    const regexVals = /={(.*?)}/g;
    const valueArr = code.match(regexVals);
    if (!valueArr || !attrArr) {
      return [''];
    }
    const val: any = {};
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < attrArr?.length; i++) {
      val[attrArr[i].substring(1, attrArr[i].length - 1)] = valueArr[i]
        ?.substring(2, valueArr[i].length - 1)
        .replace(/'"/g, '');
    }
    /**
     *  Ad sold
     */
    if (matches[0].startsWith('[ad-sold')) {
      if (userId === val.buyerId) {
        return [
          words.buyer['ad-sold'][0].replace('{adTitle}', val.adTitle).replace('{adPrice}', `${val.adPrice} €`),
          words.buyer['ad-sold'][1],
        ];
      }
      return [
        words.seller['ad-sold'][0].replace(/\{buyerName\}/g, val.buyerName).replace('{adPrice}', `${val.adPrice} €`),
        words.seller['ad-sold'][1],
      ];
    }
    if (matches[0].startsWith('[ad-favorited')) {
      /**
       *  ad favorited
       */
      if (userId === val.byId) {
        return [
          words.buyer['ad-favorited'][0].replace('{adTitle}', val.adTitle).replace('{adTitle}', `${val.adTitle}`),
        ];
      }
      return [words.seller['ad-favorited'][0].replace('{byName}', val.byName).replace('{adTitle}', `${val.adTitle}`)];
    }
    if (matches[0].startsWith('[ad-reception')) {
      /**
       *  ad reception
       */
      if (userId === val.buyerId) {
        return [
          words.buyer['ad-reception'][0]
            .replace(/\{buyerName\}/g, val.buyerName)
            .replace('{ownerName}', val.ownerName)
            .replace('{adTitle}', val.adTitle),
          words.buyer['ad-reception'][1],
          words.buyer['ad-reception'][2],
        ];
      }
      return [
        (words.reception as any)[val.success][0]
          .replace('{adPrice}', `${val.adPrice} €`)
          .replace('{adTitle}', val.adTitle)
          .replace(/\{buyerName\}/g, val.buyerName)
          .replace('{ownerName}', val.ownerName),
        ...(words.reception as any)[val.success].slice(1),
      ];
    }
    if (matches[0].startsWith('[reminder-reception')) {
      /**
       *  reminter reception
       */
      if (userId === val.buyerId) {
        return [
          words.buyer['reminder-reception'][0].replace('{adTitle}', `${val.adTitle}`),
          words.buyer['reminder-reception'][1],
          words.buyer['reminder-reception'][2],
        ];
      }
      return [
        words.seller['reminder-reception'][0].replace('{adTitle}', `${val.adTitle}`),
        words.seller['reminder-reception'][1],
        words.seller['reminder-reception'][2],
      ];
    }
  }
  return [''];
};
