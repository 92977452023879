import { useQuery, useMutation } from '@apollo/client';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonRow, IonCol, IonText, IonButton } from '@ionic/react';
import { useState, useEffect } from 'react';
import { ComponentModal } from '../../../components/Modals';
import { UPDATE_FEED } from '../../../graphql/mutations/updateUser..graphql';
import { GET_ME } from '../../../graphql/queries/user.graphql';
import { Enum_Userspermissionsuser_Feedtype } from '../../../models/gql/graphql';
import { getUserId } from '../../../utils';

interface IConfidentialityContent {
  close: () => void;
  isOpen: boolean;
}
export const ConfidentialityContent: React.FC<IConfidentialityContent> = ({ close, isOpen }) => {
  const { data: dataMe } = useQuery(GET_ME);
  const [updateFeedType, { loading: loadingFeed }] = useMutation(UPDATE_FEED);

  const [isPrivate, setIsPrivate] = useState(false);
  const changeFeedType = (from: string = 'private') => {
    if (from == 'private' && !isPrivate) {
      setIsPrivate(true);
    }
    if (from == 'public' && isPrivate) {
      setIsPrivate(false);
    }
  };
  const userId = getUserId();

  useEffect(() => {
    if (dataMe?.me?.feedType == 'PRIVATE') {
      setIsPrivate(true);
    } else {
      setIsPrivate(false);
    }
  }, [dataMe]);

  const saveFeedType = async () => {
    try {
      const { data: dataUser } = await updateFeedType({
        variables: {
          data: {
            feedType: isPrivate
              ? Enum_Userspermissionsuser_Feedtype.Private
              : Enum_Userspermissionsuser_Feedtype.Public,
          },
        },
      });
      close();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ComponentModal onCancel={() => close()} isOpen={isOpen} height="400px">
      <IonHeader className="ion-no-border padding-t-25" mode="ios">
        <IonToolbar mode="ios">
          <IonTitle className="text-center font-outfit">Confidentialité de mon feed</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form>
          <IonRow className="ion-justify-content-center ion-align-items-center font-outfit font-weight-450">
            <IonRow className="ion-margin-bottom">
              Privé : ton feed n'est pas visible par les autres utilisateurs
            </IonRow>
            <IonRow className="ion-margin-bottom">Publique : ton feed est visible par les autres utilisateurs</IonRow>
            <IonRow className="ion-margin-bottom full-width height-50 ion-padding-horizontal">
              <IonRow
                className="width-90 full-height bg-white border-radius-20"
                style={{ border: '1px solid #FFB800' }}
              >
                <IonCol
                  size="6"
                  className={`full-width full-height ${
                    isPrivate ? 'border-radius-20 bg-yellow text-white' : 'bg-white border-radius-20'
                  }`}
                  onClick={() => changeFeedType('private')}
                >
                  <IonRow className="ion-justify-content-center ion-align-items-center full-height">
                    <IonText>Feed privé</IonText>
                  </IonRow>
                </IonCol>
                <IonCol
                  size="6"
                  className={isPrivate ? 'bg-white border-radius-20' : 'border-radius-20 text-white bg-yellow'}
                  onClick={() => changeFeedType('public')}
                >
                  <IonRow className="ion-justify-content-center ion-align-items-center full-height">
                    <IonText>Feed publique</IonText>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonRow>
          </IonRow>
          <IonButton
            expand="block"
            fill="solid"
            color="primary"
            disabled={loadingFeed}
            className="d-absolute-bottom ion-padding-horizontal border-radius-20"
            onClick={saveFeedType}
          >
            Enregistrer
          </IonButton>
          <IonButton
            expand="block"
            fill="clear"
            disabled={loadingFeed}
            className="d-absolute-bottom ion-padding-horizontal text-underligne text-black"
            onClick={() => close()}
          >
            Annuler
          </IonButton>
        </form>
      </IonContent>
    </ComponentModal>
  );
};
