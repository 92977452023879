import { IonContent, IonRow } from '@ionic/react';
import Lottie from 'react-lottie';
import loading from '../../assets/json/lotties/loading.json';

import './style.css';

interface ILoader {
  width?: number;
  height?: number;
  label?: string;
}

const Loader: React.FC<ILoader> = ({ width = 300, height = 300, label }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <IonContent className="full-loader font-outfit">
      <IonRow className="full flex-container ion-align-items-center ion-justify-content-center">
        <div>
          <Lottie options={defaultOptions} height={height} width={width} />
        </div>
        {label && <div className="text-white text-bolder">{label}</div>}
      </IonRow>
    </IonContent>
  );
};

export default Loader;
