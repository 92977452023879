import { IonItem, IonList, IonPopover } from '@ionic/react';
import { FC } from 'react';

interface IMenuPopover {
  showPopover: boolean;
  closePopover: any;
  trigger: string;
  items: IPopoverItem[];
}

const MenuPopover: FC<IMenuPopover> = ({ showPopover, closePopover, items, trigger }) => {
  return (
    <IonPopover mode="ios" isOpen={showPopover} trigger={trigger} triggerAction="click" onDidDismiss={closePopover}>
      <IonList lines="none" className="margin-l-5">
        {items &&
          items.length &&
          items.map((item, i) => (
            <IonItem key={item.class + i} className={item.class} onClick={item.action}>
              {item.text}
            </IonItem>
          ))}
      </IonList>
    </IonPopover>
  );
};

export default MenuPopover;
