import { useLazyQuery } from '@apollo/client';
import { GET_MESSAGES_FIREBASE_IDS } from '../graphql/queries/message.graphql';

export const useMessages = () => {
  const [getMessagesFirebaseIds, { data, loading, refetch }] = useLazyQuery(GET_MESSAGES_FIREBASE_IDS);
  return {
    getMessagesFirebaseIds,
    messages: data?.messages?.data,
    refetch,
    loadMessages: loading,
  };
};
