import { useMutation } from '@apollo/client';
import { ApplePayEventsEnum, GooglePayEventsEnum, PaymentFlowEventsEnum, Stripe } from '@capacitor-community/stripe';
import { Capacitor } from '@capacitor/core';
import {
  IonActionSheet,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonIcon,
  IonInput,
  IonPage,
  IonRow,
  IonText,
  useIonToast,
} from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';
import { logoAndroid, logoApple } from 'ionicons/icons';
import styled from 'styled-components';
import { HeaderTypeTwo, PriceBox } from '../../../components';
import { CREATE_PAYMENT_INTENT } from '../../../graphql/mutations/stripe.graphql';
import { useDispoDetail } from '../../../hooks/useDispoDetail';
import { useHistory } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import { defineCustomElements } from 'stripe-pwa-elements/loader';
import { ACCEPT_INVITATION, PARTICIPE_TO_EVENT } from '../../../graphql/mutations/updateEventUserParticipation.graphql';

const Contribute: React.FC = () => {
  const [createPaymentIntent, { loading }] = useMutation(CREATE_PAYMENT_INTENT);
  const [disabling, setDisabling] = useState(false);
  const [isContributive, setIsContributive] = useState(false);
  const [AcceptInvitation, { loading: loadingAccept }] = useMutation(ACCEPT_INVITATION);
  const [ParticipeToEvent, { loading: loadingParticipe }] = useMutation(PARTICIPE_TO_EVENT);
  const [stripe, setStripe] = useState<any>();
  const [isApplePayAvailable, setApplePayAvailableStatus] = useState(false);
  const [isGooglePayAvailable, setGooglePayAvailableStatus] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const dispoData = useDispoDetail();
  const { loadingEvent } = dispoData;
  const [amount, setAmount] = useState<number>();
  const [amountRequiredError, setAmountRequiredError] = useState(false);
  const [presentToast] = useIonToast();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const history = useHistory();

  const getPaymentIntent = async () => {
    const { data } = await createPaymentIntent({
      variables: {
        input: {
          eventId: dispoData.id,
          amount: amount || 0,
          stripeAccount: dispoData?.creator?.attributes?.stripeAccount || undefined,
        },
      },
    });
    return {
      paymentIntentClientSecret: data?.CreatePaymentIntent?.data?.paymentIntent,
      customerId: data?.CreatePaymentIntent?.data?.customerId,
      customerEphemeralKeySecret: data?.CreatePaymentIntent?.data?.ephemeralKey.secret,
    };
  };
  var watch = useCallback(
    async function () {
      console.log('watch');
      const i: any = await dispoData.refetchParticipation();
      if (i.data?.eventUserParticipations.data && i.data?.eventUserParticipations.data.length) {
        if (i.data?.eventUserParticipations.data[0].attributes.status === 'accepted') {
          //  success
          setDisabling(false);
          history.push(`/main/dispos/${dispoData.id}/inscription-success?contribution=${amount}`);
        } else if (
          ['payment_failed', 'payment_canceled'].indexOf(i.data?.eventUserParticipations.data[0].attributes.status) > -1
        ) {
          // error
          setDisabling(false);
          history.push(`/main/dispos/${dispoData.id}/inscription-failed`);
        } else {
          setTimeout(async () => {
            await watch();
          }, 2000);
        }
      } else {
        console.log('nonono');
        setTimeout(async () => {
          await watch();
        }, 2000);
      }
    },
    [dispoData]
  );
  const processCard = async () => {
    try {
      if (!amount) {
        console.log('Amount is mandatory');
        presentToast('Veuillez ajouter le montant de votre contribution', 3000);
        return;
      }
      if (amount > 9999) {
        presentToast('Le montant de votre contribution ne doit pas depasser 9999Eur', 3000);
        return;
      }
      const { paymentIntentClientSecret, customerId, customerEphemeralKeySecret } = await getPaymentIntent();
      setDisabling(true);
      await stripe
        .createPaymentSheet({
          paymentIntentClientSecret,
          customerId,
          customerEphemeralKeySecret,
          merchantDisplayName: 'Tedispo', // or creator?
        })
        .then(() => console.log('1 success'))
        .catch((e: any) => {
          console.log('2 error', e);
          presentToast("Une erreur s'est produite pendant la création de la fenêtre du paiement", 1000);
          setDisabling(false);
          throw e;
        });
      try {
        const presentResult = await stripe.presentPaymentSheet();
        if (presentResult.paymentResult === 'paymentSheetCompleted') {
          setDisabling(true);
          // success
          console.log('success', presentResult);
          // watch partiicpations

          await watch();
        } else {
          presentToast("Une erreur s'est produite pendant le paiement , veuillez réessayer ultérieurement", 3000);
          console.log({ presentResult });
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    } catch (error) {
      presentToast(
        "Une erreur s'est produite pendant la création de votre tunnel de paiement, veuillez nous contacter s'il vous plaît.",
        3000
      );
      console.log({ error });
    }
  };
  const processApple = async () => {
    try {
      if (!amount) {
        presentToast('Veuillez ajouter le montant de votre contribution', 3000);
        console.log('Amount is mandatory');
        return;
      }
      if (amount > 9999) {
        presentToast('Le montant de votre contribution ne doit pas depasser 9999Eur', 3000);
        return;
      }
      const { paymentIntentClientSecret } = await getPaymentIntent();
      await stripe.createApplePay({
        paymentIntentClientSecret,
        paymentSummaryItems: [
          {
            label: 'Tedispo SAS',
            amount,
          },
        ],
        merchantIdentifier: 'merchant.com.tedispo',
        countryCode: 'FR',
        currency: 'EUR',
      });
      const result = await stripe.presentApplePay();
      console.log({ result });
      if (result.paymentResult === ApplePayEventsEnum.Completed) {
        // Happy path
        console.log('success');
        await watch();
      } else {
        presentToast("Une erreur s'est produite pendant le paiement , veuillez réessayer ultérieurement", 3000);
      }
    } catch (e) {
      presentToast(
        "Une erreur s'est produite pendant la création de votre tunnel de paiement, veuillez nous contacter.",
        3000
      );
      console.error(e);
    }
  };

  const processAndroid = async () => {
    try {
      if (!amount) {
        presentToast('Veuillez ajouter le montant de votre contribution', 3000);
        console.log('Amount is mandatory');
        return;
      }
      if (amount > 9999) {
        presentToast('Le montant de votre contribution ne doit pas depasser 9999Eur', 3000);
        return;
      }
      const { paymentIntentClientSecret } = await getPaymentIntent();
      await stripe.createGooglePay({
        paymentIntentClientSecret,
        paymentSummaryItems: [
          {
            label: 'Tedispo SAS',
            amount: dispoData?.event?.attributes?.price,
          },
        ],
        merchantIdentifier: 'com.tedispo',
        countryCode: 'FR',
        currency: 'EUR',
      });
      const result = await Stripe.presentGooglePay();
      if (result.paymentResult === GooglePayEventsEnum.Completed) {
        // Happy path
        await watch();
      } else {
        console.log({ result });
        presentToast("Une erreur s'est produite pendant le paiement , veuillez réessayer ultérieurement", 3000);
      }
    } catch (e) {
      presentToast(
        "Une erreur s'est produite pendant la création de votre tunnel de paiement, veuillez nous contacter.",
        3000
      );
      console.error(e);
    }
  };

  useEffect(() => {
    if (dispoData?.creator?.attributes?.stripeAccount) {
      const prom = Capacitor.isNativePlatform() ? Promise.resolve() : defineCustomElements();
      prom.then(() => {
        console.log({
          stripeAccount: dispoData?.creator?.attributes?.stripeAccount,
          publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
        });
        if (!stripe) {
          Stripe.initialize({
            stripeAccount: dispoData?.creator?.attributes?.stripeAccount,
            publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
          })
            .then(() => {
              return Stripe.isApplePayAvailable()
                .then(() => {
                  setApplePayAvailableStatus(true);
                })
                .catch(() => {
                  setApplePayAvailableStatus(false);
                });
            })
            .then(() => {
              return Stripe.isGooglePayAvailable()
                .then(() => {
                  setGooglePayAvailableStatus(true);
                })
                .catch(() => {
                  setGooglePayAvailableStatus(false);
                });
            })
            .then(() => {
              setStripe(Stripe);
            })
            .catch((err) => {
              console.log('error on initializing stripe client: ', err);
            });
        }
      });
    } else {
      console.log('The author doesnt have stripe yet!! contact admin');
    }
    // set disabled if
    if (dispoData?.event?.attributes?.creator?.data?.attributes?.stripeCapabilitiesStatus !== 'active') {
      setDisabling(true);
    } else {
      setDisabling(false);
    }

    if (dispoData?.event?.attributes?.paymentType === 'Contributive') {
      setIsContributive(true);
    }
  }, [dispoData, stripe]);

  const accept = async () => {
    try {
      console.log('dispoData.event?.attributes?.typ', dispoData.event?.attributes?.type);
      if (dispoData.event?.attributes?.type === 'public') {
        await ParticipeToEvent({
          variables: {
            id: dispoData?.id,
          },
        });
      } else {
        await AcceptInvitation({
          variables: {
            id: dispoData.id,
          },
        });
      }

      history.push(`/main/dispos/${dispoData.id}/inscription-success`);
    } catch (err: any) {
      // setIsOpen(true);
      console.error(err);
      //setMessageError(err.message);
    }
  };
  return (
    <ContributeWrapper>
      {!loadingEvent && dispoData && (
        <div>
          <IonContent scrollEvents={true} fullscreen>
            <div className="bg-primary full-height">
              <HeaderTypeTwo title="Contribuer à la dispo">
                <IonRow className="margin-y-20">
                  <IonText className="text-bolder text-size-md margin-l-20 text-white">{dispoData.eventName}</IonText>
                </IonRow>
              </HeaderTypeTwo>
              <IonRow
                style={{
                  marginTop: 'calc(80px + var(--ion-safe-area-top, 0))',
                  zIndex: '50',
                  border: 'none',
                  width: '100%',
                }}
              >
                <div className="scroll">
                  <IonCard className="border-radius font-outfit box-shadow-custom">
                    <IonCardContent>
                      <IonCard>
                        <IonCardContent>
                          <div className="subtitle">
                            L’utilisateur de cette dispo a indiqué que son fonctionnement était contributif.
                          </div>
                          <div className="prices-container">
                            <div className="prices">
                              <div className="price-box">
                                <div className="text">Montant à atteindre</div>
                                <div className="price">{dispoData.event?.attributes?.moneyToRaise}€</div>
                              </div>
                              <div className="price-box current">
                                <div className="text">Montant actuel</div>
                                <div className="price">{dispoData.event?.attributes?.moneyRaised || 0}€</div>
                              </div>
                            </div>
                          </div>
                          <div className="message-title">Message de l'organisateur</div>
                          <div
                            className="message-text"
                            dangerouslySetInnerHTML={{ __html: dispoData.event?.attributes?.paymentMessage || '' }}
                          ></div>
                        </IonCardContent>
                      </IonCard>
                      <IonCard>
                        <IonCardContent>
                          <div className="subtitle">Montant de ta contribution</div>
                          <div className="price-input">
                            <IonInput
                              disabled={loading}
                              className={`${!amountRequiredError && 'ion-valid'} ${
                                amountRequiredError === true && 'ion-invalid'
                              }`}
                              errorText={amountRequiredError ? 'Veuillez remplir le montant' : undefined}
                              inputMode="decimal"
                              label="€"
                              value={amount}
                              onIonInput={(e) => {
                                console.log(e);
                                setAmount(parseFloat(e.detail?.value || '0'));
                              }}
                              labelPlacement="end"
                              type="text"
                              onIonFocus={() => setIsFocused(true)}
                              onIonBlur={() => setIsFocused(false)}
                            />
                          </div>
                        </IonCardContent>
                      </IonCard>
                    </IonCardContent>
                  </IonCard>

                  <IonCard className="footer" style={{ position: !isFocused ? 'fixed' : 'relative' }}>
                    <IonCardContent className="ion-no-padding">
                      {!isReady ? (
                        <>
                          <IonButton
                            disabled={loading || disabling}
                            expand="block"
                            color="primary"
                            onClick={() => {
                              if (!amount) {
                                presentToast('Veuillez ajouter un montant', 10000);
                                setAmountRequiredError(true);
                                return;
                              }
                              setIsReady(true);
                            }}
                          >
                            Contribuer
                          </IonButton>

                          <IonButton
                            className="btn-skip"
                            disabled={(loading || disabling) && !isContributive}
                            expand="block"
                            fill="clear"
                            color="dark"
                            onClick={() => accept()}
                          >
                            Passer
                          </IonButton>
                        </>
                      ) : (
                        <>
                          <IonButton
                            disabled={loading || disabling}
                            expand="block"
                            color="primary"
                            onClick={() => processCard()}
                          >
                            Carte bancaire
                          </IonButton>
                          {isApplePayAvailable && (
                            <IonButton
                              disabled={loading || disabling}
                              expand="block"
                              fill="outline"
                              color="dark"
                              onClick={() => processApple()}
                            >
                              <IonIcon icon={logoApple} /> pay
                            </IonButton>
                          )}
                          {isGooglePayAvailable && (
                            <IonButton
                              disabled={loading || disabling}
                              expand="block"
                              fill="outline"
                              color="dark"
                              onClick={() => processAndroid()}
                            >
                              <IonIcon icon={logoAndroid} /> pay
                            </IonButton>
                          )}
                        </>
                      )}
                    </IonCardContent>
                  </IonCard>
                </div>
              </IonRow>
            </div>
          </IonContent>
        </div>
      )}
    </ContributeWrapper>
  );
};

export default Contribute;
const ContributeWrapper = styled(IonPage)`
  ion-button {
    text-transform: capitalize;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  ion-button {
    &.btn-skip {
      font-weight: 600;
      text-decoration: underline;
    }
  }
  ion-input {
    --border-width: 1px;
    --border-style: dashed;
    --border-color: #e3e2e2;
    --border-radius: 6px;
    --color: black;
    --background: var(--gris-background-2, #f7f5f4);
    font-size: 40px;
    text-align: right;
    max-width: 160px;
    &.ion-invalid {
      --border-color: red;
    }
  }
  div {
    &.scroll {
      overflow: auto;
      height: calc(100vh + 100px);
    }
    ion-card.box-shadow-custom {
      height: 100vh;
      background-color: #f7f5f4;
      margin: 0;
      ion-card-content {
        padding: 0;
        display: flex;
        flex-direction: column;
        div.prices-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ion-card {
          border-radius: 20px;
          padding: 10px;
          margin-bottom: 0px;
          margin-top: 20px;
        }
        div {
          padding: 5px;
          color: var(--Text, #313333);
          &.subtitle {
            text-align: center;
            leading-trim: both;
            text-edge: cap;

            font-family: Outfit;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 27px */
          }
          &.message-title {
            color: var(--Text, #313333);
            leading-trim: both;
            text-edge: cap;
            font-weight: 600;
          }
          &.message-text {
            font-weight: 400;
          }
          &.price-input {
            display: flex;
            justify-content: center;
          }

          &.prices {
            display: flex;
            div.price-box {
              margin: 10px;
              min-width: 150px;
              div.text {
                align-self: stretch;
                color: var(--Text, #313333);
                leading-trim: both;
                text-edge: cap;

                /* Lil text/12px SB */
                font-family: Outfit;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px; /* 116.667% */
              }
              div.price {
                color: var(--Text, #313333);
                /* H2 */
                font-family: Outfit;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px; /* 133.333% */
              }
              &.current {
                .price {
                  color: blue;
                }
              }
              padding: var(--margin, 12px);
              flex-direction: column;
              align-items: flex-start;
              gap: var(--margin, 12px);
              flex: 1 0 0;
              border-radius: 6px;
              border: 1px solid var(--Text, #313333);
              background: var(--gris-background-2, #f7f5f4);
            }
          }
        }
      }
    }
  }
`;
