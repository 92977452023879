import { useLazyQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { GET_NOTIFICATIONS } from '../graphql/queries/notification.graphql';
import { AppReducerContext } from '../store';
import { getUserId } from '../utils';

const PAGE_SIZE = 30;

export const useNotifications = () => {
  const {
    state: { notifications, newMessage },
    dispatch,
  } = useContext(AppReducerContext);

  const [meta, setMeta] = useState<IPaginationMeta>();
  const setNotifications = (data: any) => {
    dispatch({ type: 'SET_NOTIFICATIONS', payload: data });
  };
  const pushNotifications = (data: any) => {
    dispatch({ type: 'PUSH_NOTIFICATIONS', payload: data });
  };
  const incrementNewMessage = () => {
    dispatch({ type: 'INCREMENT_NEW_MESSAGE' });
  };
  const decrementNewMessage = () => {
    dispatch({ type: 'DECREMENT_NEW_MESSAGE' });
  };
  const setNbNewMessage = (count: number) => {
    dispatch({ type: 'SET_NB_NEW_MESSAGE', payload: count });
  };
  const [getNotifications, { loading }] = useLazyQuery(GET_NOTIFICATIONS, {
    fetchPolicy: 'no-cache',
  });
  const fetch = async (page?: number) => {
    const { data } = await getNotifications({
      variables: {
        pagination: {
          page: page || 0,
          pageSize: PAGE_SIZE,
        },
        filters: {
          actionTo: {
            id: {
              eq: getUserId(),
            },
          },
        },
        sort: ['createdAt:desc'],
      },
    });
    console.log(data?.notifications?.meta);
    setMeta(data?.notifications?.meta?.pagination);

    if (page && page > 1) {
      pushNotifications(data?.notifications?.data);
    } else {
      setNotifications(data?.notifications?.data);
    }
  };
  const more = async () => {
    if (meta?.pageCount! < meta?.page!) {
      console.log('nothing more');
      return;
    }
    if (meta && meta.page) {
      await fetch(meta?.page + 1);
    }
  };

  const unseenNotificationsLength = () => {
    return (notifications?.filter((i) => !i?.attributes?.seen) || []).length;
  };
  const unseenMessagesLength = () => {
    return (notifications?.filter((i) => !i?.attributes?.seen) || []).length;
  };
  return {
    meta,
    notifications,
    unseenNotificationsLength,
    unseenMessagesLength,
    setNotifications,
    fetch,
    loading,
    more,
    newMessage,
    incrementNewMessage,
    decrementNewMessage,
    setNbNewMessage,
  };
};
