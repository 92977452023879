import React, { useEffect, useReducer } from 'react';
import { reducer } from './reducer';
import { initialstate } from './state';
import { AppReducerContext } from './AppReducerContext';
import StorageUtils from '../../utils/storage';
import ToastsContainer from '../../components/Toast/ToastsContainer';

interface Props {
  children: React.ReactNode;
}

export const AppReducerProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialstate as never);

  useEffect(() => {
    StorageUtils.set('dispoDraft', state.dispo);
  }, [state.dispo]);
  return (
    <AppReducerContext.Provider value={{ state, dispatch }}>
      <ToastsContainer toasts={state.toasts} position={'bottom-center'} />
      {children}
    </AppReducerContext.Provider>
  );
};
