import React from 'react';
import { IonCard } from '@ionic/react';
import styled from 'styled-components';

export interface ICardCustomProps {
  children: React.ReactNode;
  padding?: boolean;
}

const PriceBox: React.FC<ICardCustomProps> = ({ children, padding = true }) => {
  return <PriceBoxWrapper>{children}</PriceBoxWrapper>;
};
export default PriceBox;
const PriceBoxWrapper = styled(IonCard)`
  border-radius: 6px;
  border: 1px dashed var(--jaune, #ffb800);
  background: var(--background-jaune, #fff1cc);
  color: var(--jaune, #ffb800);
  text-align: center;
  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  display: flex;
  width: auto;
  min-width: 109px;
  padding: var(--corner-16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--corner-8, 8px);
`;
