import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { IonButton, IonCol, IonRow, IonSpinner } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { base64ToFile, compressVideo } from '../../../utils/file';
import ComponentModal from '../ComponentModal/ComponentModal';
import './style.css';

export interface IChooseFileModalProps {
  isOpen: boolean;
  textThirdBtn?: string;
  onCancel?: () => void;
  onChangeFile?: (e: any) => void;
  onClickThirdBtn?: (data: boolean) => void;
  isCancelThirdBtn?: boolean;
  useVideo?: boolean;
  title?: string;
  limit?: boolean;
}

const ChooseFileModal: React.FC<IChooseFileModalProps> = ({
  isOpen,
  textThirdBtn,
  onCancel,
  onChangeFile,
  onClickThirdBtn,
  isCancelThirdBtn = false,
  useVideo = false,
  title,
  limit = false,
}) => {
  const [showModal, setShowModal] = useState(isOpen);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen, setShowModal]);

  const handleCancel = () => {
    setShowModal(false);
    if (onCancel) onCancel();
  };
  const openCamera = async () => {
    const photo = await Camera.getPhoto({
      allowEditing: true,
      quality: 80,
      width: 400,
      height: 400,
      source: CameraSource.Prompt,
      resultType: CameraResultType.Uri,
      promptLabelCancel: 'Annuler',
      promptLabelHeader: 'Ajouter des photo ',
      promptLabelPhoto: 'A partir de la bibliothèque',
      promptLabelPicture: 'Prendre une photo',
    });
    const files = [];
    // eslint-disable-next-line no-plusplus
    const { path } = photo;
    if (path) {
      // eslint-disable-next-line no-await-in-loop
      const { data } = await Filesystem.readFile({ path });
      const f = base64ToFile(data as string, 'image/jpg', 'name');
      files.push(f);
      if (onChangeFile) onChangeFile(files);
    }
  };
  const openFile = async () => {
    const { photos } = await Camera.pickImages({ limit: limit ? 1 : 10, quality: 80, width: 1024, height: 1024 });

    if (photos[0] && photos[0].path) {
      const files = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < photos.length; ++i) {
        const photo: any = photos[i];
        const { path } = photo;
        // eslint-disable-next-line no-await-in-loop
        const { data } = await Filesystem.readFile({ path });
        const f = base64ToFile(data as string, 'image/jpg', 'name');
        files.push(f);
      }
      if (onChangeFile) onChangeFile(files);
    }
  };

  const onClickBtn = () => {
    if (onClickThirdBtn) onClickThirdBtn(true);
  };
  const pickVideos = async () => {
    const result = await FilePicker.pickVideos({
      multiple: true,
    });
    setIsLoading(true);

    if (result.files[0] && result.files[0].path) {
      const files: any = [];

      for (let video of result.files) {
        if (video.path) {
          // mean ios or android
          // need resize
          const { data: dataFirst } = await Filesystem.readFile({ path: video.path });
          const { uri } = await Filesystem.writeFile({ data: dataFirst, path: 'temp.mp4', directory: Directory.Data });

          const newPath = await compressVideo(uri);

          if (newPath) {
            const { data } = await Filesystem.readFile({ path: newPath });
            console.log('file readed', data);
            const f = base64ToFile(data as string, 'video/mp4', 'name');
            files.push(f);
          }
        } else if (video.data) {
          const f = base64ToFile(video.data, 'video/mp4', 'name');
          files.push(f);
        }
      }

      if (onChangeFile) onChangeFile(files);
      setIsLoading(false);
    }
  };

  return (
    <>
      <ComponentModal
        isOpen={showModal}
        onCancel={() => handleCancel()}
        height={textThirdBtn ? (useVideo ? '380px' : '330px') : useVideo ? '330px' : '280px'}
      >
        <IonRow>
          <IonCol className="ion-padding-top">
            {title && <h4 className="title-modal">{title}</h4>}
            <IonButton expand="block" color="primary" onClick={openFile} disabled={isLoading}>
              Acceder à ma galerie
            </IonButton>
            {useVideo && (
              <IonButton expand="block" color="primary" onClick={pickVideos} disabled={isLoading}>
                Acceder à ma galerie video
              </IonButton>
            )}
            <IonButton expand="block" fill="outline" color="primary" onClick={openCamera} disabled={isLoading}>
              Prendre une photo
            </IonButton>
            {textThirdBtn && (
              <IonButton
                expand="block"
                className={isCancelThirdBtn ? 'text-underligne text-black ion-margin-top' : ''}
                fill={isCancelThirdBtn ? 'clear' : 'outline'}
                color={isCancelThirdBtn ? 'dark' : 'primary'}
                onClick={onClickBtn}
                disabled={isLoading}
              >
                {textThirdBtn}
              </IonButton>
            )}
            <IonButton
              expand="block"
              className="text-underligne text-black"
              fill={'clear'}
              color="dark"
              onClick={onCancel}
            >
              Annuler
            </IonButton>
          </IonCol>
        </IonRow>
        {isLoading && <IonSpinner color="dark" className={'custom-loader'}></IonSpinner>}
      </ComponentModal>
    </>
  );
};

export default ChooseFileModal;
