import { graphql } from '../../models/gql';
export const GET_ME = graphql(/* GraphQL */ `
  query me {
    me {
      id
      phone
      publishedAt
      username
      isPro
      email
      firstname
      lastname
      birthDate
      companyName
      feedType
      siret
      job
      legalStatus
      blockedUsers {
        id
      }
      blockedByUsers {
        id
      }
      stripeAccount
      stripeCapabilitiesStatus
      interests {
        id
        attributes {
          name
          picto {
            data {
              id
              attributes {
                url
                name
                hash
                mime
                size
                provider
              }
            }
          }
        }
      }
      avatar {
        id
        attributes {
          image {
            data {
              attributes {
                name
                url
                mime
                hash
                size
                provider
              }
            }
          }
        }
      }
      profilePicture {
        id
        attributes {
          name
          url
          mime
          hash
          size
          provider
        }
      }
      followups {
        data {
          attributes {
            status
            user {
              data {
                id
                attributes {
                  username
                  isPro
                  companyName
                  profilePicture {
                    data {
                      attributes {
                        name
                        url
                        mime
                        hash
                        size
                        provider
                      }
                    }
                  }
                  avatar {
                    data {
                      attributes {
                        image {
                          data {
                            attributes {
                              name
                              url
                              mime
                              hash
                              size
                              provider
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      followers {
        data {
          attributes {
            status
            follower {
              data {
                id
                attributes {
                  isPro
                  companyName
                  username
                  phone
                  firstname
                  lastname
                  deleted
                  profilePicture {
                    data {
                      attributes {
                        name
                        url
                        mime
                        hash
                        size
                        provider
                      }
                    }
                  }
                  avatar {
                    data {
                      attributes {
                        image {
                          data {
                            attributes {
                              name
                              url
                              mime
                              hash
                              size
                              provider
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const GET_ME_MAIN_INFO = graphql(/* GraphQL */ `
  query meMainInfo {
    me {
      id
      username
      isPro
      email
      firstname
      lastname
      companyName
      avatar {
        id
        attributes {
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
      profilePicture {
        id
        attributes {
          url
        }
      }
      blockedUsers {
        id
      }
    }
  }
`);

export const GET_MY_LIKE = graphql(/* GraphQL */ `
  query myLikes {
    myLikes {
      posts {
        idPost
        idReaction
      }
    }
  }
`);
