import { graphql } from '../../models/gql';
export const GET_FORBIDDEN_WORDS = graphql(/* GraphQL */ `
  query forbiddenWord {
    forbiddenWord {
      data {
        id
        attributes {
          name
          formatted
        }
      }
    }
  }
`);
