import { IonBackdrop, IonGrid, IonIcon, IonRow } from '@ionic/react';
import './style.css';

interface IRoundIcon {
  icon: any;
  bgColor?: string;
  iconColor?: string;
}

const RoundIcon: React.FC<IRoundIcon> = ({ icon, bgColor, iconColor }) => {
  return (
    <>
      <IonRow className="ion-justify-content-center ion-align-items-center">
        <IonIcon src={icon} size="large"></IonIcon>
      </IonRow>
    </>
  );
};

export default RoundIcon;
