import { IonCol, IonIcon, IonRow, IonText } from '@ionic/react';
import { shareOutline, linkOutline } from 'ionicons/icons';

interface IListWithImage {
  description?: Maybe<string>;
  description2?: Maybe<string>;
  image: any;
  action?: () => void;
}

const ListWithImage: React.FC<IListWithImage> = ({ description, image, description2, action }) => {
  return (
    <IonRow
      className="ion-justify-content-center ion-align-items-center bg-blue-light border-radius-10 margin-bottom-5 text-black"
      style={{
        border: !description2 ? '2px solid var(--ion-color-secondary)' : '',
        padding: !description2 ? '5px 0' : '',
      }}
    >
      <IonCol size="auto">
        <IonIcon icon={description2 ? image : linkOutline} size="large" className="ion-icon-blue"></IonIcon>
      </IonCol>
      <IonCol onClick={action} style={action ? { cursor: 'pointer' } : undefined}>
        <IonRow className="blue-on-hover text-bold text-size-lg font-outfit">
          <IonText className="blue-text  text-capitalize">{description}</IonText>
        </IonRow>
        {description2 && (
          <IonRow className="blue-on-hover text-capitalize font-outfit">
            <IonText className="text-size-xs">{description2}</IonText>
          </IonRow>
        )}
      </IonCol>
    </IonRow>
  );
};

export default ListWithImage;
