import { IonContent, IonPage, IonButton, IonText, IonToast } from '@ionic/react';
import { Header, StepperCount, InterestChip, CardCustom } from '../../../../components';
import { UPDATE_ME } from '../../../../graphql/mutations/auth.graphql';
import { GET_INTERESTS } from '../../../../graphql/queries/interest.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import { __ } from '../../../../utils/traduction';

const ConfigInterest: React.FC = () => {
  const [updateMe, { data, loading }] = useMutation(UPDATE_ME);
  const { data: interestsData, loading: loadingInterest } = useQuery(GET_INTERESTS);
  const [interestsChecked, setInterestsChecked] = useState([]);
  const [errorsMessage, setErrorsMessage] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const history = useHistory();

  const onSubmit = async () => {
    const dataToPost = {
      interests: interestsChecked,
    };

    try {
      if (interestsChecked.length >= 3) {
        const { data } = await updateMe({ variables: { data: dataToPost } });
        history.push('/signup/config-pdp');
      } else {
        setIsOpen(true);
        setErrorsMessage('Vous devez au moins en selectionner trois !');
      }
    } catch (error: any) {
      setIsOpen(true);
      setErrorsMessage(error.message);
    }
  };

  const interests = useMemo(() => {
    return !loadingInterest ? interestsData?.interests?.data : [];
  }, [loadingInterest, interestsData]);

  const handleIDChange = (idInterest: never) => {
    if (interestsChecked.includes(idInterest)) {
      setInterestsChecked(interestsChecked.filter((value) => value !== idInterest));
    } else {
      setInterestsChecked([...interestsChecked, idInterest]);
    }
  };

  useEffect(() => {}, [interestsChecked]);

  return (
    <IonPage>
      <Header title="Configuration de compte" noButtonBack={false} />
      <StepperCount length={3} numberStep={2}></StepperCount>
      <IonContent className="ion-text-left ion-padding">
        <CardCustom>
          <IonText color="dark" className="font-outfit">
            <h3>Tes centres d'intérêts</h3>
            <p className="ion-text-left font-description">
              Nous aspirons à te recommander des évènements selon tes goûts, donne-nous quelques indices sur ce que tu
              aimes :)
            </p>
          </IonText>

          {interests?.map((interest, index) => (
            <InterestChip
              key={index}
              handleChange={handleIDChange}
              interest={interest}
              isSelected={interestsChecked.includes(interest?.id as never)}
              isOther={false}
            ></InterestChip>
          ))}
          <IonButton
            expand="block"
            fill="solid"
            color="primary"
            disabled={loading}
            className="ion-margin-top"
            onClick={onSubmit}
          >
            Continuer
          </IonButton>
        </CardCustom>
      </IonContent>
      <IonToast
        isOpen={isOpen}
        message={__(errorsMessage)}
        duration={5000}
        onDidDismiss={() => setIsOpen(false)}
        color="danger"
      ></IonToast>
    </IonPage>
  );
};

export default ConfigInterest;
