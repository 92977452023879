import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { chatbubbleEllipsesSharp, heart, navigate, notifications, personAdd, trash } from 'ionicons/icons';
import Mustache from 'mustache';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import SendRequest from '../../assets/icons/request_send.png';
import { Enum_Notification_Type, EventEntity, NotificationEntity } from '../../models/gql/graphql';
import { relativeTime } from '../../utils/dateFormat';
import CardDispoMinimal from '../CardDispoMinimal';
import CardPostMinimal from '../CardPostMinimal';
import ConfirmRequestCard from '../ConfirmRequestCard';
import enum_type from './enum_type.json';
import './style.css';

const icons: any = {
  heart,
  trash,
  chatbubbleEllipsesSharp,
  personAdd,
};

interface INotif {
  notification: NotificationEntity;
  refetch?: () => void;
}

const Notification: React.FC<INotif> = ({ notification, refetch }) => {
  const getMessageNotification = () => {
    const data = {
      name: notification.attributes?.actionFrom?.data?.attributes?.isPro
        ? notification.attributes?.actionFrom?.data?.attributes?.companyName
        : notification.attributes?.actionFrom?.data?.attributes?.username,
      additionalData: notification.attributes?.additionalData || 'Les champs',
    };
    const template = Mustache.render(JSON.stringify(enum_type), data);
    const result = JSON.parse(template);

    if (notification.attributes!.type === Enum_Notification_Type.FollowRequest) {
      const userFrom = notification.attributes?.actionFrom?.data?.attributes?.isPro
        ? notification.attributes?.actionFrom?.data?.attributes?.companyName
        : notification.attributes?.actionFrom?.data?.attributes?.username;
      result[notification.attributes!.type as any].message = `${userFrom} a demandé à vous suivre`;
    }
    result[notification.attributes!.type as any].icon =
      icons[result[notification.attributes!.type as any].icon] || null;
    return result[notification.attributes!.type as any];
  };
  //console.log(getMessageNotification(), notification);
  const messageNotification = useMemo(getMessageNotification, [notification]);

  const history = useHistory();
  /*const updateSeen = async () => {
    try {
      const { data } = await updateNotification({ variables: { id: notification.id!, data: { seen: true } } });
      if (refetch) refetch();
    } catch (error) {
      console.log({ error });
    }
  };*/

  return (
    <IonGrid className={notification?.attributes?.seen ? 'seen' : 'unseen'}>
      <IonRow>
        <IonCol size="auto">
          {!messageNotification?.icon ? (
            <img
              className={`${
                notification.attributes!.type !== Enum_Notification_Type.FollowRequest ? 'round-img' : 'square'
              } ${!notification.attributes?.seen ? 'bubble' : ''}`}
              alt={
                notification.attributes?.actionFrom?.data?.attributes?.profilePicture?.data
                  ? (notification.attributes?.actionFrom?.data?.attributes?.profilePicture.data?.attributes
                      ?.alternativeText as string)
                  : notification.attributes?.actionFrom?.data?.attributes?.avatar?.data
                  ? (notification.attributes?.actionFrom?.data?.attributes.avatar.data?.attributes?.image.data
                      ?.attributes?.alternativeText as string)
                  : 'pdp'
              }
              src={
                notification.attributes!.type !== Enum_Notification_Type.FollowRequest
                  ? notification.attributes?.actionFrom?.data?.attributes?.profilePicture?.data
                    ? notification.attributes?.actionFrom?.data?.attributes?.profilePicture.data?.attributes?.url
                    : notification.attributes?.actionFrom?.data?.attributes?.avatar?.data
                    ? notification.attributes?.actionFrom?.data?.attributes.avatar.data?.attributes?.image.data
                        ?.attributes?.url
                    : 'https://ionicframework.com/docs/img/demos/avatar.svg'
                  : SendRequest
              }
              onClick={() => {
                history.push(`/main/profil/${notification.attributes?.actionFrom?.data?.id}`);
              }}
            />
          ) : (
            <IonIcon className="notification_icon" icon={messageNotification.icon} />
          )}
        </IonCol>
        <IonCol
          className="no-padding-top"
          onClick={() => {
            if (
              notification.attributes!.type === Enum_Notification_Type.NewFollower ||
              notification.attributes!.type === Enum_Notification_Type.FollowAccepted ||
              notification.attributes!.type === Enum_Notification_Type.FollowRefused
            ) {
              history.push('/main/profil/' + notification.attributes?.actionFrom?.data?.id);
            }
          }}
        >
          <div>
            <p className="font-notif-title font-outfit ion-no-padding ion-no-margin text-capitalize">
              {messageNotification.message}.
            </p>
            {notification?.attributes?.publication?.data && (
              <CardPostMinimal
                type={notification.attributes.type}
                post={notification?.attributes?.publication?.data}
              ></CardPostMinimal>
            )}
            {notification?.attributes?.event?.data && (
              <CardDispoMinimal
                userId={notification.attributes?.actionFrom?.data?.id}
                type={notification.attributes.type}
                dispo={notification?.attributes?.event?.data as EventEntity}
              ></CardDispoMinimal>
            )}
            {notification.attributes!.type === Enum_Notification_Type.FollowRequest && (
              <ConfirmRequestCard
                idUserFollower={notification.attributes?.actionFrom?.data?.id || '0'}
                refetchNotification={refetch}
              />
            )}
            <p className="custom-label-time ion-float-right ion-margin-top font-outfit">
              {relativeTime(notification.attributes?.createdAt)}
            </p>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Notification;
