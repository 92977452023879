import { ReactNode, createElement } from 'react';
import { IonText } from '@ionic/react';

export const StringToHtml = (params: string, showMore: boolean, setShowMore: any): ReactNode => {
  if (params.length > 150) {
    const truncatedText = createElement('div', {
      dangerouslySetInnerHTML: { __html: params.substring(0, 150) + '...' },
    });

    return (
      <div>
        {!showMore ? (
          <div>
            {truncatedText}
            <IonText
              className={'cursor-pointer text-underline text-size-14'}
              onClick={(e) => {
                e.stopPropagation();
                setShowMore(true);
              }}
            >
              voir plus
            </IonText>
          </div>
        ) : (
          <div>
            {createElement('div', { dangerouslySetInnerHTML: { __html: params } })}
            <div className="text-underligne text-center">
              <IonText
                className={'cursor-pointer text-underline text-size-14'}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMore(false);
                }}
              >
                voir moins
              </IonText>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return createElement('div', { dangerouslySetInnerHTML: { __html: params } });
  }
};
