import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react';
import { EventEntity } from '../../../models/gql/graphql';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { useEffect, useRef, useState } from 'react';
import { CardDispo, CardLoader } from '../../../components';
import { CardDispoPublic } from '../../../components/Algolia';
import { transformEventDate } from '../../DispoDetail/utils';

interface ISectionDispo {
  loading?: boolean;
  events?: any[];
  title: string;
  loadNext: () => any;
  usePagination?: boolean;
  numberView?: number;
  noWrap?: boolean;
  weatherData?: any;
}

const SectionDispoAround: React.FC<ISectionDispo> = ({
  loading,
  events,
  title,
  usePagination = true,
  loadNext,
  numberView = 1,
  noWrap = false,
  weatherData = {},
}) => {
  const [numberSwiper, setNumberSwiper] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const initSwiper = (swiper: any) => {
    setSwiperRef(swiper);
  };
  const scrollContainerRefAround = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRefAround.current;
      if (scrollContainer) {
        const { scrollLeft, clientWidth, scrollWidth } = scrollContainer;
        // Check if scrolled to the end
        const isEnd =
          -0.5 <= scrollWidth - (scrollLeft + clientWidth) && scrollWidth - (scrollLeft + clientWidth) <= 0.5;
        if (isEnd) {
          loadNext();
        }
      }
    };

    // Attach the scroll event listener
    const scrollContainerRef = scrollContainerRefAround.current as any;
    if (scrollContainerRef) {
      scrollContainerRef.addEventListener('scroll', handleScroll);
    }

    // Cleanup function
    return () => {
      if (scrollContainerRef) {
        scrollContainerRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [events]);

  return (
    <div className="ion-margin-top">
      <IonText color="dark" className="font-outfit">
        <h4>{title}</h4>
      </IonText>
      {!loading && events?.length === 0 ? (
        <IonText>Aucune donnée pour le moment</IonText>
      ) : (
        <>
          <IonGrid
            ref={scrollContainerRefAround}
            className="hide-scrollBar"
            style={{ overflow: 'auto', padding: 0, paddingBottom: 10 }}
          >
            <IonRow className="col-card" style={{ flexWrap: 'nowrap', padding: 0 }}>
              {loading ? (
                <IonCol size="12" className="col-card">
                  <CardLoader />
                </IonCol>
              ) : (
                <>
                  {events &&
                    events?.map((hit, index: number) => {
                      return (
                        <IonCol size="8" key={index}>
                          <div className="col-card-no-margin" key={index} style={{ width: '100%', textAlign: 'left' }}>
                            <CardDispoPublic
                              key={hit.objectID}
                              name={hit.name}
                              start={hit.start}
                              end={hit.end}
                              addressCity={hit.addressCity}
                              addressLine={hit.addressLine}
                              cover={hit.cover}
                              creatorPicture={hit.creatorPicture}
                              creator={hit.creator}
                              id={hit.id}
                              creatorFirstname={hit.creatorFirstname}
                              creatorLastname={hit.creatorLastname}
                              creatorUsername={hit.creatorUsername}
                              status={hit.status}
                              dispoDate={transformEventDate(hit.start, hit.end) as IDate}
                              type={hit.type}
                              pending={hit.pending}
                              distance={
                                hit._rankingInfo && hit._rankingInfo.geoDistance ? hit._rankingInfo.geoDistance : null
                              }
                              noWrap={noWrap}
                              showCreator={false}
                              weatherInfo={weatherData[hit.id]}
                            />
                          </div>
                        </IonCol>
                      );
                    })}
                </>
              )}
            </IonRow>
          </IonGrid>
        </>
      )}
    </div>
  );
};

export default SectionDispoAround;
