import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonTitle,
  IonCol,
  IonText,
  IonFooter,
  IonToolbar,
  IonButton,
} from '@ionic/react';
import { Header, InterestChip } from '../../../components';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_INTERESTS } from '../../../graphql/queries/interest.graphql';
import { ADD_INTEREST_TO_USER, REMOVE_INTEREST_TO_USER } from '../../../graphql/mutations/updateUser..graphql';
import { useDispoDetail } from '../../../hooks/useDispoDetail';
import { UPDATE_EVENT } from '../../../graphql/mutations/update-event.graphql';
import { useHistory } from 'react-router';

const EditDispoInterests: React.FC = () => {
  const { data: interestsData, loading: loadingInterest } = useQuery(GET_INTERESTS);
  const [saveEvent, { loading: loadingSaveEvent }] = useMutation(UPDATE_EVENT);

  const [interestsChecked, setInterestsChecked] = useState([]);
  const dispoData = useDispoDetail();
  const history = useHistory();
  const interests = useMemo(() => {
    return !loadingInterest ? interestsData?.interests?.data : [];
  }, [loadingInterest, interestsData]);

  useEffect(() => {
    console.log(dispoData.event, 'aza');
    if (dispoData.event && dispoData.event.attributes?.interests?.data?.length) {
      const ids = dispoData.event.attributes?.interests?.data.map((i: any) => i.id);
      console.log({ ids });
      setInterestsChecked(ids);
    }
  }, [dispoData.event]);

  const handleInterestChange = (idInterest: never) => {
    let checked = [];
    if (interestsChecked.includes(idInterest)) {
      checked = interestsChecked.filter((value) => value !== idInterest);
      setInterestsChecked(checked);
    } else {
      checked = [...interestsChecked, idInterest];
      setInterestsChecked(checked);
    }
    // setValue('interests', checked);
  };
  const updateEvent = async () => {
    try {
      await saveEvent({
        variables: {
          id: dispoData.id,
          data: {
            interests: interestsChecked,
          },
        },
      });

      history.goBack();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <IonPage>
      <Header title="Centres d'intérêts"></Header>
      <IonContent className="ion-padding">
        <IonGrid className="ion-justify-content-center ion-align-items-center width-p-95 min-height-200 ion-padding-bottom bg-white border-radius height-auto">
          <IonRow>
            <IonCol>
              <IonText color="dark" className="font-outfit">
                <p>
                  Sélectionne les centres d’intérêts liés à ta dispo ! Cela nous permettra de l’affichage aux membres
                  intéressés{' '}
                </p>
              </IonText>
              {interests?.map((interest, index) => (
                <InterestChip
                  handleChange={handleInterestChange}
                  interest={interest}
                  isSelected={interestsChecked.includes(interest?.id as never)}
                  isOther={false}
                  key={index}
                ></InterestChip>
              ))}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton
            type="submit"
            size="small"
            onClick={updateEvent}
            disabled={loadingSaveEvent || loadingInterest}
            className="card-bottom-button"
            expand="block"
            fill="solid"
          >
            Sauvegarder les changement
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default EditDispoInterests;
