/* Use this component as reference and guide line when creating components or pages */
import React, { useEffect, useState } from 'react';
import { IonContent, IonLabel } from '@ionic/react';
import styled from 'styled-components';
import { RefinementList, Configure, useInstantSearch } from 'react-instantsearch';
import { CustomRefresh, SearchInfiniteHits, SearchInfiniteUserHits } from '../../../components/Algolia';
import { getUserData, getUserId, isAuthenticated } from '../../../utils';
import { buildMainFilter } from '../SearchResult/utils';
import { useAppStore } from '../../../store';
import NoResultsBoundary from '../../../components/Algolia/NoResultBoundary';

const HITS_PER_PAGE = 150;

/**
 * SearchUserResult page component
 * @param {string} className            (Optional) Override className internal configuration
 * @returns                             SearchResult react component
 */
const SearchUserResult: React.FC<any> = ({ className, filterData: initialFilterData, position, radius, query }) => {
  const [aroundLatLng, setAroundLatLng] = useState<string>();
  const {
    RootStore: { algoliaCache },
  } = useAppStore();
  const { status } = useInstantSearch();
  const [mainFilters, setMainFilters] = useState<any>(() => {
    const filterData: any = {
      blocked: false,
      deleted: false,
    };
    return buildMainFilter(filterData);
  });

  useEffect(() => {
    const data: any = {
      ...initialFilterData,
      // expired: false,
      blocked: false,
      deleted: false,
      // custom: `(type:public OR  targets:${getUserId()})`,
    };
    let blocked;
    if (isAuthenticated() && getUserData() && getUserData().blockedUsers && getUserData().blockedUsers.length) {
      blocked = getUserData().blockedUsers.map((i: any) => i.id);
    }
    if (isAuthenticated() && getUserData() && getUserData().blockedByUsers && getUserData().blockedByUsers.length) {
      if (!blocked) {
        blocked = getUserData().blockedByUsers.map((i: any) => i.id);
      } else {
        blocked = [...blocked, ...getUserData().blockedByUsers.map((i: any) => i.id)];
      }
    }
    if (blocked && blocked.length) {
      data.blockedUser = blocked;
    }
    console.log('buildMainFilter(data))', buildMainFilter(data));
    setMainFilters(buildMainFilter(data));
  }, [initialFilterData]);

  useEffect(() => {
    if (position && position.lat) {
      setAroundLatLng(`${position.lat}, ${position.lng}`);
    }
  }, [position]);

  return (
    <SearchResultWrapper className={`SearchResult ${className || ''}`}>
      <CustomRefresh />
      <Configure
        filters={mainFilters}
        aroundLatLng={aroundLatLng}
        aroundRadius={radius}
        hitsPerPage={HITS_PER_PAGE}
        query={query}
        analytics
        getRankingInfo
        distinct
      />
      <NoResultsBoundary fallback={<IonLabel>Aucun utilisateur ne correspond à ta recherche.</IonLabel>}>
        <SearchInfiniteUserHits cache={algoliaCache} />
      </NoResultsBoundary>
    </SearchResultWrapper>
  );
};

export const SearchResultWrapper = styled('div')``;

export default SearchUserResult;
