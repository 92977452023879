import { useQuery } from '@apollo/client';
import { IonText } from '@ionic/react';
import { useEffect, useMemo, useState } from 'react';
import { InputCustom, InterestChip } from '../../../components';
import { GET_INTERESTS } from '../../../graphql/queries/interest.graphql';
import { EventEntity } from '../../../models/gql/graphql';

interface IProps {
  handleChangeInterest: (data: Array<string>) => void;
  handleChangeLimit: (data: number) => void;
  userIsPro?: boolean | null;
  dispoDefault: EventEntity;
  maxInvitedProps?: number;
  interestsCheckedReset?: [];
}

const PublicView: React.FC<IProps> = ({
  handleChangeInterest,
  userIsPro,
  handleChangeLimit,
  dispoDefault,
  maxInvitedProps,
  interestsCheckedReset,
}) => {
  const [maxInvited, setMaxInvited] = useState<number | undefined>();

  useEffect(() => {
    if (dispoDefault && dispoDefault.attributes && dispoDefault.attributes.maxInvited) {
      setMaxInvited(dispoDefault.attributes.maxInvited);
      handleChangeLimit(dispoDefault.attributes.maxInvited);
    }
  }, [dispoDefault]);

  const handleLimitChange = (value: any) => {
    setMaxInvited(value.detail ? value.detail.value : value);
    handleChangeLimit(parseInt(value.detail ? value.detail.value : value));
  };
  useEffect(() => {
    if (maxInvitedProps === null) {
      setMaxInvited(maxInvitedProps);
    }
  }, [maxInvitedProps]);

  return (
    <>
      <IonText color="dark" className="font-outfit">
        <h4>Seuil de participants</h4>
        <p>
          Ta dispo est publique, tout le monde sera en mesure de s’y inscrire, dans la limite des places disponibles ! A
          toi de définir ce seuil.
        </p>
      </IonText>

      <InputCustom
        handleChange={handleLimitChange}
        placeholder="40"
        label="limite d'inscription"
        attr="maxInvited"
        type="number"
        inputmode="numeric"
        value={maxInvited}
        validation={{ max: { value: 10000, message: 'La valeur doit être inférieur à 10000' } }}
      />
    </>
  );
};

export default PublicView;
