import React from 'react';

import { IonSkeletonText } from '@ionic/react';

const CardLoader: React.FC<any> = () => {
  return (
    <div className="ion-padding custom-skeleton">
      <IonSkeletonText animated style={{ width: '60%' }} />
      <IonSkeletonText animated />
      <IonSkeletonText animated style={{ width: '88%' }} />
      <IonSkeletonText animated style={{ width: '70%' }} />
      <IonSkeletonText animated style={{ width: '30%' }} />
      <IonSkeletonText animated style={{ width: '80%' }} />
      <IonSkeletonText animated style={{ width: '40%' }} />
    </div>
  );
};

export default CardLoader;
