import { IonCol, IonRow } from '@ionic/react';
import { useMemo } from 'react';
import './style.css';

interface IStepperCount {
  length: number;
  numberStep: number;
}

const StepperCount: React.FC<IStepperCount> = ({ length, numberStep }) => {
  const filledArray = () => {
    let array = [];
    for (let i = 1; i <= length; i++) {
      array.push(numberStep >= i);
    }
    return array;
  };

  const arrayStepper = useMemo(filledArray, [length, numberStep]);

  return (
    <>
      <IonRow className="row">
        {arrayStepper.map((elem, index) => (
          <IonCol key={index} className={`trait ${elem ? 'filled' : 'outline'}`}></IonCol>
        ))}
      </IonRow>
    </>
  );
};

export default StepperCount;
