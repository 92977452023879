export const buildMainFilter = (data: any) => {
  const operators: any = {
    blockedCreator: '!=',
    blockedUser: '!=',
  };
  const booleans: any[] = ['disabled', 'expired', 'deleted'];
  const subfilterJoinOperator: any = {
    blockedCreator: 'AND',
    blockedUser: 'AND',
  };
  return Object.keys(data)
    .map((key) => {
      if (key === 'custom') {
        return data[key];
      }
      if (booleans.indexOf(key) > -1) {
        return `NOT ${key}:${data[key] ? 'false' : 'true'}`;
      }
      if (Array.isArray(data[key])) {
        if (key === 'start') {
          return `${key}>${data[key][0]} AND ${key}<${data[key][1]}`;
        }
        if (key === 'blockedCreator') {
          const subfilter = data[key]
            .map((i: string) => `creator${operators[key] || ':'}${i}`)
            .join(` ${subfilterJoinOperator[key] ? subfilterJoinOperator[key] : 'OR'} `);
          return `(${subfilter})`;
        }
        if (key === 'blockedUser') {
          const subfilter = data[key]
            .map((i: string) => `id${operators[key] || ':'}${i}`)
            .join(` ${subfilterJoinOperator[key] ? subfilterJoinOperator[key] : 'OR'} `);
          return `(${subfilter})`;
        }
        if (key === 'followups') {
          const subfilter = data[key]
            .map((i: string) => `creator${operators[key] || ':'}${i}`)
            .join(` ${subfilterJoinOperator[key] ? subfilterJoinOperator[key] : 'OR'} `);
          return `(${subfilter})`;
        }
        const subfilter = data[key]
          .map((i: string) => `${key}${operators[key] || ':'}${i}`)
          .join(` ${subfilterJoinOperator[key] ? subfilterJoinOperator[key] : 'OR'} `);
        return `(${subfilter})`;
      }
      return key ? `${key}${operators[key] || ':'}${data[key]}` : '';
    })
    .join(' AND ');
};

export const countFilterAttributes = (filters: any): number => {
  if (!filters) return 0;
  return Object.keys(filters).filter((k) => k !== 'query' && k !== 'q' && filters[k] && filters[k] !== '').length;
};
