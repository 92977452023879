import { IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow, IonText } from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Loader, PriceBox } from '../../../components';
import { insertEventToCalendar } from '../../../hooks/synchroniseCalendar';
import { useDispoDetail } from '../../../hooks/useDispoDetail';
import { Enum_Event_Type } from '../../../models/gql/graphql';
import { CustomButton } from '../components/Buttons/CustomButton';
import { getEventDateData, getFullAddress } from '../utils';
import { DownloadTicket } from './downloadTicket';
import './style.css';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const InscriptionSuccess: React.FC = () => {
  const { id: idDispo } = useParams<IVerifParams>();
  const history = useHistory();
  const { search } = useLocation();

  const { eventUserParticipations, event, refetchParticipation } = useDispoDetail();
  const emplacement = getFullAddress(event);
  const eventDateData = getEventDateData(event);
  const [price, setPrice] = useState('0');
  const [loadingGeneratePass, setLoadingGeneratePass] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.get('contribution')) {
      setPrice(query.get('contribution') || '0');
    }
  }, [search]);

  let image =
    event && event?.attributes?.coverCustomImage?.data && event?.attributes?.coverCustomImage?.data
      ? event.attributes.coverCustomImage.data.attributes?.url
      : event && event.attributes?.coverTedispoImage && event.attributes?.coverTedispoImage?.data
      ? event.attributes.coverTedispoImage.data.attributes?.image?.data?.attributes?.url
      : 'https://ionicframework.com/docs/img/demos/avatar.svg';

  const setStyle = (color: string) => {
    return {
      body: {
        backgroundColor: color,
        zIndex: '50',
      },
    };
  };

  let backgroundColor =
    event && event?.attributes
      ? event?.attributes?.paymentType && event?.attributes?.paymentType == 'Payante'
        ? setStyle('#FF99CF').body
        : setStyle('#FFB800').body
      : {};

  let textColor =
    event && event?.attributes
      ? event?.attributes?.paymentType && event?.attributes?.paymentType == 'Payante'
        ? '#FF99CF'
        : '#FFB800'
      : '';
  let bodyBgColor =
    event && event?.attributes ? (event?.attributes?.type == Enum_Event_Type.Public ? 'bg-green' : 'bg-blue') : '';

  useEffect(() => {
    if (event) {
      refetchParticipation();
      //Add event to calendar
      insertEventToCalendar(event);
    }
  }, [event]);

  return (
    <IonPage>
      {loadingGeneratePass && <Loader />}
      <IonContent fullscreen>
        <IonRow className={`full-width ${bodyBgColor} ion-justify-content-center font-outfit`}>
          <IonRow className="padding-t-50 text-white text-size-x text-bold">
            <IonText className="text-center">Tu es inscrit.e à cette dispo !</IonText>
          </IonRow>
          <IonRow className="bg-white width-p-90 ion-justify-content-center border-radius-20 margin-t-50 margin-b-300 shadow-bottom">
            <IonRow style={backgroundColor} className="border-radius-20">
              <IonGrid>
                <IonRow className="full ion-justify-content-center">
                  <IonRow className="full-width padding-3">
                    <img src={image} className="full border-radius-20 height-300 max-height-300" />
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-align-items-center padding-10">
                    <IonText className="text-title">{event?.attributes?.name}</IonText>
                  </IonRow>
                </IonRow>
              </IonGrid>
            </IonRow>
            <IonRow className="width-p-90" style={{ zIndex: '50' }}>
              <IonGrid>
                <IonRow className="margin-b-15">
                  <IonCol>
                    <DescriptionDetail title={'Emplacement'} value={emplacement} textColor={textColor} />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="6" className="padding-5 margin-b-15 ">
                    <DescriptionDetail title={'date'} value={eventDateData.debut} textColor={textColor} />
                  </IonCol>
                  <IonCol size="6">
                    <DescriptionDetail title={'Heure'} value={eventDateData.fin} textColor={textColor} />
                  </IonCol>
                </IonRow>
                {event?.attributes?.paymentType === 'Contributive' && price && price !== '0' && (
                  <>
                    <IonRow>
                      <IonCol className="d-flex ion-justify-content-center ion-align-ites-ms-center">
                        <div className="price-box">
                          <PriceBox>{price}€</PriceBox>
                        </div>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonText className="ion-text-center">
                          <h4>Nous avons reçu ta contribution</h4>
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </>
                )}
                <br />
                <DownloadTicket
                  userParticipation={eventUserParticipations}
                  event={event}
                  setLoadingGeneratePass={setLoadingGeneratePass}
                />
              </IonGrid>
            </IonRow>
          </IonRow>
        </IonRow>
        <IonRow
          style={{
            position: 'fixed',
            bottom: 0,
            height: '400px',
            width: '100%',
            backgroundColor: 'white',
          }}
          className="border-radius-top-20 ion-justify-content-center font-outfit"
        ></IonRow>
      </IonContent>
      <IonFooter className={`ion-no-border`} mode="ios">
        <IonRow className="ion-justify-content-center font-outfit">
          <CustomButton
            action={() => {
              history.push(`/main/dispos/${idDispo}?noBackButton=true`);
            }}
            style={{
              position: 'fixed',
              bottom: 0,
              zIndex: '70',
            }}
            class="ion-no-border text-white min-width-p-80 min-height-p-100 ion-border-radius-10 button-bg-green no-uppercase margin-b-10"
            text="Retourner à la dispo"
          />
        </IonRow>
      </IonFooter>
    </IonPage>
  );
};

export default InscriptionSuccess;

interface IDescriptionDetail {
  title: string;
  value?: string;
  textColor: string;
}

const DescriptionDetail: React.FC<IDescriptionDetail> = ({ title, value, textColor }) => {
  return (
    <div>
      <IonRow className="margin-b-15">
        <IonText style={{ color: textColor }} className="text-bolder text-capitalize">
          {title}
        </IonText>
      </IonRow>
      <IonRow>
        <IonText className="text-capitalize">{value || ''}</IonText>
      </IonRow>
    </div>
  );
};
