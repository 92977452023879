import { IonContent, IonPage, IonButton, IonFooter, IonCheckbox, IonLabel, IonToast } from '@ionic/react';
import { Header, InputCustom, RichEditor, StepperCount, CardCustom, SelectCustom } from '../../components';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import { useEffect, useMemo, useState } from 'react';
import './style.css';
import { useQuery, useMutation } from '@apollo/client';
import { Enum_Report_Category, Enum_Report_Status, Enum_Report_Type, ReportInput } from '../../models/gql/graphql';
import { BLOCKED_USER } from '../../graphql/mutations/updateUser..graphql';
import { CREATE_REPORT } from '../../graphql/mutations/report.graphql';
import { getUserId } from '../../utils';
import flagSvg from '../../assets/icons/flag.svg';
import flagSvgBlue from '../../assets/icons/flag_blue.svg';
import { REPORTING_LIST } from '../../constants';
import { useDispo } from '../../hooks';
import { Enum_segment_home } from '../../store/AppReducerProvider/type';
interface IParamsReporting {
  categorie: Enum_Report_Category;
  relatedId: string;
  type: Enum_Report_Type;
  userId?: string;
  postCommentId?: string;
  postUserId?: string;
}

const Reporting: React.FC = () => {
  const history = useHistory();
  const { categorie, relatedId, type, userId, postCommentId, postUserId } = useParams<IParamsReporting>();
  const methods = useForm<ReportInput>();
  const [category, setCategory] = useState(categorie);
  const { setValue, handleSubmit, reset } = methods;
  const [description, setDescription] = useState<string>('');
  const [blockUser, setBlockUser] = useState<boolean>(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [blockedUser, { loading: loadingBlocked }] = useMutation(BLOCKED_USER);
  const [createReport, { loading: loadingReport }] = useMutation(CREATE_REPORT);
  const idUser = getUserId();
  const labelToShow: any = {
    Utilisateur: 'Bloquer cet utilisateur.',
    Commentaire: 'Bloquer l’auteur de ce commentaire.',
    Publication: 'Bloquer l’auteur de ce post.',
  };
  const { setRedirectHome } = useDispo();

  const onSubmit = async (data: ReportInput) => {
    data.related_id = relatedId;
    data.reportedBy = idUser;
    data.description = description;
    data.status = Enum_Report_Status.ToDo;
    data.type = type;

    const { data: dataReport } = await createReport({ variables: { data } });

    if (blockUser) {
      await blockedUser({
        variables: {
          id: userId ? userId : relatedId,
        },
      });
    }
    setOpenToast(true);
    let pathToRedirect: any = '/main/profil/' + relatedId;

    if (type === Enum_Report_Type.Evenement) {
      pathToRedirect = '/main/dispos/' + relatedId;
    }

    if (type === Enum_Report_Type.Commentaire) {
      // pathToRedirect = '/main/' + postUserId + '/posts/' + postCommentId + '/comment';
      pathToRedirect = '/main/home';
      setRedirectHome(Enum_segment_home.feed);
    }

    if (type === Enum_Report_Type.Publication) {
      pathToRedirect = '/main/home';
      setRedirectHome(Enum_segment_home.feed);
    }

    setDescription('');
    history.push(pathToRedirect);
  };
  useEffect(() => {
    setCategory(categorie);
    setValue('category', categorie);
    setOpenToast(false);
  }, [categorie]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonPage>
          <Header title="Signalement" noButtonBack={false} />
          <IonContent>
            <CardCustom>
              <SelectCustom
                defaultValue={category}
                options={REPORTING_LIST}
                attr="category"
                label="Que souhaitez vous signaler ?"
                handleChange={(e) => setCategory(e.detail.value)}
              ></SelectCustom>
              <div className="ion-margin-top">
                <RichEditor
                  handleChange={(value: string) => setDescription(value)}
                  value={description}
                  label="Description"
                  placeholder="Fournis-nous plus d’information à propos de ton signalement."
                />
              </div>
              {Enum_Report_Type.Evenement !== type && (
                <div className="check-box-custom ion-margin-top">
                  <IonCheckbox
                    mode="md"
                    className="ion-margin-top"
                    labelPlacement="end"
                    checked={blockUser}
                    onIonChange={(e) => setBlockUser(e.detail.checked)}
                  >
                    <IonLabel className="font-outfit label-checkbox" text-wrap>
                      {labelToShow[type]}
                    </IonLabel>
                  </IonCheckbox>
                </div>
              )}
            </CardCustom>
          </IonContent>
          <IonFooter className="ion-no-border ion-padding">
            <IonButton
              type="submit"
              className=""
              expand="block"
              color="primary"
              disabled={loadingReport || loadingBlocked}
            >
              Confirmer
            </IonButton>
            <IonButton
              expand="block"
              fill="clear"
              className="d-absolute-bottom ion-padding-horizontal text-underligne text-black"
              disabled={loadingReport || loadingBlocked}
              onClick={() => history.goBack()}
            >
              Annuler
            </IonButton>
          </IonFooter>
          <IonToast
            isOpen={openToast}
            message="Signalement transmis avec succès."
            duration={5000}
            icon={flagSvgBlue}
          ></IonToast>
        </IonPage>
      </form>
    </FormProvider>
  );
};

export default Reporting;
