import { cloneDeep } from 'lodash';
import { CODE_COUNTRY } from '../constants';

export const checkForbiddenWordInputted = (words: any, value: string) => {
  const formattedForbiddenWords = words?.forbiddenWord?.data?.attributes?.formatted;
  // get last word
  const lastWord = stripTag(value).split(' ').slice(-1)[0];
  if (!lastWord) {
    const prevWord = stripTag(value).split(' ').slice(-2)[0];
    console.log(prevWord);
    // here tabbed space
    const initial = prevWord[0];
    if (formattedForbiddenWords[initial] && formattedForbiddenWords[initial].length) {
      return formattedForbiddenWords[initial].includes(prevWord);
    }
  }
  return false;
  // return values.includes(content);
};
export const checkForbiddenWordPasted = (words: any, word: string) => {
  const formattedForbiddenWords = words?.forbiddenWord?.data?.attributes?.formatted;
  if (word) {
    const initial = word[0];
    if (formattedForbiddenWords[initial] && formattedForbiddenWords[initial].length) {
      return formattedForbiddenWords[initial].includes(word);
    }
  }
  return false;
  // return values.includes(content);
};

export const resetObject = (objectToUnset: any) => {
  const objectCopy = cloneDeep(objectToUnset);
  for (const key in objectCopy) {
    if (objectCopy.hasOwnProperty(key)) {
      objectCopy[key] = null;
    }
  }
  return objectCopy;
};

export const removeLastColon = (inputString: string) => {
  const lastIndex = inputString.lastIndexOf(':');
  if (lastIndex !== -1) {
    return inputString.slice(0, lastIndex) + inputString.slice(lastIndex + 1);
  }
  return inputString;
};

export const stripTag = (text: string) => {
  if (!text) return '';
  return text.replace(/<[^>]*>?/gm, '');
};

export const extractPhoneNumberAndCode = (numberWithCode: string) => {
  let numberWithoutCode: string = numberWithCode;
  let codeCountry: string = '';

  CODE_COUNTRY.forEach((country) => {
    if (numberWithCode.startsWith(country)) {
      numberWithoutCode = `0${numberWithCode.slice(country.length)}`;
      codeCountry = country;
    }
  });
  return {
    number: numberWithoutCode,
    code: codeCountry,
  };
};
export const addCodeCountry = (numberWithoutCode: string) => {
  const numberWithCode: Array<string> = [];

  if (numberWithoutCode.startsWith('+')) {
    return [numberWithoutCode];
  }

  CODE_COUNTRY.forEach((country) => {
    if (numberWithoutCode.startsWith('0')) {
      numberWithCode.push(`${country}${numberWithoutCode.slice(1)}`);
    }
  });

  return numberWithCode;
};

export const compareIfSimilar = (numberWithCode: string, numberWithoutCode: string) => {
  console.log({ numberWithCode, numberWithoutCode, extract: extractPhoneNumberAndCode(numberWithCode).number });
  return numberWithoutCode === numberWithCode && numberWithoutCode === extractPhoneNumberAndCode(numberWithCode).number;
};

export const checkingIfNumIsSimilar = (contactNameToTest: any, phoneNumberFromDB: any) => {
  let checkMessage = 'notDelete';
  for (const key in contactNameToTest) {
    if (key === phoneNumberFromDB) {
      checkMessage = 'deleteDirect';
      break;
    }
    if (key === extractPhoneNumberAndCode(phoneNumberFromDB).number) {
      checkMessage = 'deleteNotDirect';
      break;
    }
  }
  return checkMessage;
};
