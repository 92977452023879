import { IonCard, IonCardContent, IonCol, IonItem, IonLabel, IonRow, IonText, IonThumbnail } from '@ionic/react';
import './style.css';
import { getEventDateData } from '../../pages/DispoDetail/utils';
import { FC, useEffect } from 'react';

interface IProps {
  classname?: string;
  dispo: any;
}

const CardChatDispo: FC<IProps> = ({ dispo, classname }) => {
  const eventDateData = getEventDateData(dispo);
  // const splittedDebutDate = eventDateData.debut.split('à');
  return (
    <IonCard className={`card-dispo-with-border ${classname}`}>
      <IonCardContent className="ion-no-padding">
        <IonRow>
          <IonCol className="ion-no-padding" size="4">
            <img
              className="image-card-custom"
              alt={
                dispo?.attributes?.coverCustomImage?.data
                  ? (dispo?.attributes?.coverCustomImage?.data?.attributes?.alternativeText as string)
                  : dispo?.attributes?.coverTedispoImage?.data
                  ? (dispo?.attributes?.coverTedispoImage?.data?.attributes?.image?.data?.attributes
                      ?.alternativeText as string)
                  : 'avatar'
              }
              src={
                dispo?.attributes?.coverCustomImage?.data
                  ? dispo?.attributes?.coverCustomImage?.data?.attributes?.url
                  : dispo?.attributes?.coverTedispoImage?.data
                  ? dispo?.attributes?.coverTedispoImage?.data?.attributes?.image?.data?.attributes?.url
                  : 'https://ionicframework.com/docs/img/demos/avatar.svg'
              }
            />
          </IonCol>
          <IonCol size="8" className="card-dispo-content-with-border-left">
            <IonRow>
              <IonText>
                <h3 className="title-card-no-message">{dispo?.attributes?.name}</h3>
              </IonText>
            </IonRow>
            <IonRow>
              <IonText>
                <h3 className="subtitle-card-emplacement">Emplacement</h3>
              </IonText>
            </IonRow>
            <IonRow>
              <h3 className="subtitle-card-emplacement-value">{dispo?.attributes?.addressLine}</h3>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonRow>
                  <h3 className="subtitle-card-date">Date</h3>
                </IonRow>
                <IonRow>
                  <h3>
                    {eventDateData && eventDateData.debut} {eventDateData && eventDateData.fin}
                  </h3>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

export default CardChatDispo;
