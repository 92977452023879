export const getDisplayName = (user: any): any => {
  if (user.isPro) {
    return user.companyName;
  }

  return user.username || `${user.firstname} ${user.lastname}`;
};

export const getChatDisplayName = (user: any): string => {
  if (user?.attributes) {
    return (user?.attributes?.isPro ? user?.attributes?.companyName : user?.attributes?.username) || '';
  }
  return (user?.isPro ? user?.companyName : user?.username) || '';
};
