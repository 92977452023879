import React, { useEffect, useState } from 'react';
import { IonButton, IonCol, IonGrid, IonIcon, IonModal, IonRow, IonText } from '@ionic/react';
import './ConfirmModal.css';
import { CustomButton } from '../../../pages/DispoDetail/components/Buttons/CustomButton';
import styled from 'styled-components';

export interface IConfirmModalProps {
  isOpen: boolean;
  title?: string;
  text?: string;
  okText?: string;
  cancelText?: string;
  icon?: any;
  iconPerso?: any;
  iconLogo?: any;
  onCancel: () => void;
  onConfirm: () => void;
  height?: string;
  confirmText?: string;
  loading?: boolean;
}

const ConfirmModal: React.FC<IConfirmModalProps> = ({
  isOpen,
  text,
  title,
  okText,
  cancelText,
  onCancel,
  icon,
  iconPerso,
  iconLogo,
  onConfirm,
  confirmText,
  height,
  loading = false,
}) => {
  const [showModal, setShowModal] = useState(isOpen);
  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen, setShowModal]);
  const handleCancel = () => {
    setShowModal(false);
    onCancel();
  };
  return (
    <IonModal
      className="confirm_modal"
      mode="ios"
      isOpen={showModal}
      style={{
        '--height': height || '47%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        margin: 0,
        padding: 0,
        overflowY: 'hidden',
      }}
      onDidDismiss={() => handleCancel()}
      initialBreakpoint={1}
      breakpoints={[0, 0.33, 0.66, 1]}
    >
      <ModalContentWrapper>
        <IonGrid className="ion-padding">
          <IonRow className="font-outfit">
            <IonCol>
              {!!iconPerso && !iconLogo && (
                <IonRow className="ion-justify-content-center ion-align-items-center">
                  <IonRow className="border-round ion-justify-content-center ion-align-items-center bg-pink-light">
                    <IonIcon icon={iconPerso} className="text-yellow text-size-xxl"></IonIcon>
                  </IonRow>
                </IonRow>
              )}
              <IonRow className="ion-justify-content-center">
                {!!iconLogo && <IonIcon src={iconLogo} className="width-300 height-72"></IonIcon>}
              </IonRow>
              {icon}
              {title && (
                <IonText className="font-outfit">
                  <h4>{title}</h4>
                </IonText>
              )}
              {text && (
                <IonText className="font-outfit">
                  <p>{text}</p>
                </IonText>
              )}
            </IonCol>
          </IonRow>
          <CustomButton
            action={() => onConfirm()}
            class="ion-no-border text-white min-width-p-80 min-height-p-100 ion-border-radius-10 button-bg-green no-uppercase margin-b-10"
            text={okText || 'Oui'}
            loading={loading}
          />
          {cancelText && (
            <IonButton className="cancel text-black" fill="clear" expand="block" onClick={() => handleCancel()}>
              {cancelText || 'Annuler'}
            </IonButton>
          )}
        </IonGrid>
      </ModalContentWrapper>
    </IonModal>
  );
};

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export default ConfirmModal;
