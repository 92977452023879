import { IonContent, IonGrid } from '@ionic/react';
import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Virtuoso } from 'react-virtuoso';
import { useMyLike } from '../../hooks/usePosts';
import { PostEntity, PostLike } from '../../models/gql/graphql';
import DeletePost from './DeletePost';
import FeedListItem from './FeedListItem';

interface IProps {
  setFiles?: (value: any) => void;
  posts: PostEntity[];
  refreshPost?: () => void;
  loading: boolean;
}

const FeedList: FC<IProps> = ({ setFiles, posts, refreshPost, loading }) => {
  const [selectedPost, setSelectedPost] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const { refetchLike, myLikes } = useMyLike();
  const { postId } = useParams<{ postId?: string }>();

  const virtuosoRef = useRef<any>(null);
  const location = useLocation();

  const scrollToPost = () => {
    const postIndex = posts.findIndex((post) => post.id === postId);

    if (postIndex !== -1 && virtuosoRef.current) {
      virtuosoRef.current.scrollToIndex({
        index: postIndex,
        align: 'start',
        behavior: 'auto',
      });
    }
  };
  const goToPost = () => {
    if (postId && virtuosoRef.current) {
      const timeoutId = setTimeout(scrollToPost, 500);
      return () => clearTimeout(timeoutId);
    }
  };

  useEffect(() => {
    if (!loading) {
      goToPost();
    }
  }, [loading]);

  return (
    <IonContent scrollY={true}>
      <IonGrid>
        <Virtuoso
          ref={virtuosoRef}
          style={{ height: '100vh', overscrollBehavior: 'none' }}
          data={posts}
          itemContent={(index, post) => (
            <div className={`${posts && Boolean(posts.length) && posts.length - 1 === index ? ' padding-b-300' : ''} `}>
              <FeedListItem
                selectedPost={selectedPost}
                onDelete={() => setShowModal(true)}
                setFiles={setFiles}
                post={post}
                setSelectedPost={setSelectedPost}
                refetchLike={refetchLike}
                myLikes={myLikes as PostLike[]}
                refreshPost={refreshPost}
              />
            </div>
          )}
        />
        <DeletePost
          showModal={showModal}
          handleCancel={() => {
            setShowModal(false);
            setSelectedPost('');
            refreshPost && refreshPost();
          }}
          selectedPost={selectedPost}
        />
      </IonGrid>
    </IonContent>
  );
};

export default FeedList;
