import {
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLoading,
  IonMenuToggle,
  IonRow,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import { useLongPress } from 'use-long-press';

import { arrowBackOutline, ellipsisVerticalOutline } from 'ionicons/icons';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CardChatDispo, Loader, UploadedFilesPreview } from '../../components';
import ChatHeader from '../../components/Headers/ChatHeader';
import MessageItem from '../../components/Messenger/MessageItem';
import { useChat } from '../../hooks/useChat';
import { getChatDisplayName } from '../../utils/user';
import { ChatWrapper } from './ChatWrapper';

import { useQuery } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { addDays } from 'date-fns';
import format from 'date-fns/format';
import { useParams } from 'react-router';
import attachFileIcon from '../../assets/icons/attachFile.svg';
import closeIcon from '../../assets/icons/close.svg';
import deleteIcon from '../../assets/icons/deleteIcon.svg';
import messageRespondIcon from '../../assets/icons/messageRespond.svg';
import micIcon from '../../assets/icons/mic.svg';
import recordingMic from '../../assets/icons/recordingMic.svg';
import redDeleteIcon from '../../assets/icons/redDeleteIcon.svg';
import redMicIcon from '../../assets/icons/redMic.svg';
import sendIcon from '../../assets/icons/sendIcon.svg';
import warningIcon from '../../assets/icons/warning.svg';
import whiteMicIcon from '../../assets/icons/whiteMic.svg';
import AudioWaveAnimation from '../../components/Messenger/FakeAudioWave';
import MuteNotificationModal from '../../components/Messenger/MuteNotificationModal';
import SearchBox from '../../components/Messenger/SearchBox';
import { ComponentModal, ConfirmModal, MessageActionModal } from '../../components/Modals';
import { GET_USER } from '../../graphql/queries/getUser.graphql';
import { UsersPermissionsUser } from '../../models/gql/graphql';
import { getUserId } from '../../utils';

interface IChatParams {
  chatId?: string;
}

const Chat = () => {
  const {
    loading,
    muteLoading,
    searchLoading,
    sendMessageLoading,
    isSendingMessage,
    isResponding,
    isEdit,
    isActionModalOpen,
    confirmDelete,
    isUploadingFile,
    openFileUploadModal,
    isFileUploadModalOpen,
    isMuteNotificationModalOpen,
    openMuteNotificationModal,
    isRecordingVoice,
    isCancellingRecordingVoice,
    openSearchBox,
    chatSearchNumberOfResults,
    chatSearchResultsIds,
    chatSearchKey,
    highlightedMessageId,
    scrollRef,
    chatRef,
    inputFileRef,
    formRef,
    micRef,
    waveRef,
    lastMessageRef,
    more,
    isDispo,
    dispo,
    dispoId,
    selected,
    currentUser,
    peer,
    participants,
    body,
    selectedMsgItem,
    messages,
    selectedImages,
    voiceRecordTimer,
    userMutedChats,
    message,
    handleInputMessageChange,
    goToMessagesList,
    onSubmit,
    handleOpenActionModal,
    handleMessageDelete,
    handleMessageEdit,
    handleMessageCopy,
    handleMessageResponse,
    handleActionModalCancel,
    closeFileUploadModal,
    handleFileDelete,
    closeUploadFileModal,
    closeMuteNotificationModal,
    cancelEdit,
    cancelRespond,
    cancelDelete,
    confirmMessageDelete,
    handleOpenSearchBox,
    onSelectFile,
    openCamera,
    handlePickPhotos,
    handlePickVideos,
    handlePickFiles,
    handlePressStart,
    handlePressEnd,
    handlePressCancel,
    handleChatSearch,
    handleSearchArrowUp,
    handleSearchArrowDown,
    handleSearchClose,
    handleMuteChoice,
    handlePastedEvent,
    handleBodyChange,
    reactiveNotification,
    loadingUserChatParameter,
    loadingGetMessage,
    sendRequestAudio,
    loadingUpload,
    isLoading,
    isMicroAuthorized,
    isOpenError,
    setIsOpenError,
  } = useChat();

  const textareaRef = useRef<HTMLIonTextareaElement>(null);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const callback = useCallback((event: any) => {
    console.log('Long pressed!');
  }, []);

  const bind = useLongPress(callback, {
    onStart: (event) => handlePressStart(event),
    onFinish: (event) => handlePressEnd(),
    onCancel: (event) => handlePressCancel(),
    filterEvents: (event) => true,
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: 100,
    cancelOutsideElement: false,
  });

  const scrollToBottom = () => {
    if (!isSendingMessage) {
      scrollRef?.current?.scrollToBottom(1);
    }
  };
  const chatName = dispoId ? dispo?.attributes?.name : getChatDisplayName(peer);

  const selectedMsgItemFromUser = participants.find((p: any) => p?.id === selectedMsgItem?.fromId);

  const isSelectedMsgMine = currentUser?.id === selectedMsgItem?.fromId;

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const disableInfiniteScroll = !!chatSearchResultsIds.length && !!chatSearchKey.trim().length;

  const { chatId: idUser } = useParams<IChatParams>();

  const { data: dataUser, loading: loadingUser } = useQuery(GET_USER, {
    variables: { id: idUser || '0' },
  });

  const userData = useMemo(() => {
    return (dataUser && !loadingUser ? dataUser?.usersPermissionsUser?.data?.attributes : []) as UsersPermissionsUser;
  }, [dataUser, loadingUser]);

  const isUserBlockedByME =
    userData?.blockedByUsers &&
    userData.blockedByUsers.data?.findIndex((i: any) => i.id?.toString() === getUserId()?.toString()) > -1
      ? true
      : false;

  const isMeBlockedByUser =
    userData?.blockedUsers &&
    userData.blockedUsers.data?.findIndex((i: any) => i.id?.toString() === getUserId()?.toString()) > -1
      ? true
      : false;

  useEffect(() => {
    sendRequestAudio();
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardDidShow', () => {
        setTimeout(() => {
          scrollRef?.current?.scrollToBottom(200);
        }, 500);
      });
    }

    if (textareaRef.current) {
      textareaRef.current.addEventListener('ionBlur', handleTextareaBlur);
      textareaRef.current.addEventListener('ionFocus', handleTextareaFocus);
    }
    return () => {
      if (Capacitor.isNativePlatform()) {
        Keyboard.removeAllListeners();
      }
      if (textareaRef.current) {
        textareaRef.current.removeEventListener('ionBlur', handleTextareaBlur);
        textareaRef.current.removeEventListener('ionFocus', handleTextareaFocus);
      }
    };
  });

  const handleTextareaBlur = (e: any) => {
    setIsKeyboardVisible(false);
  };

  const handleTextareaFocus = () => {
    setIsKeyboardVisible(true);
  };

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.setResizeMode({ mode: KeyboardResize.Ionic });
    }
  }, []);

  return (
    <>
      <ChatHeader
        userId={currentUser?.id}
        openSearchBox={handleOpenSearchBox}
        dispo={dispo}
        chatId={dispoId ? `dispo_${dispoId}` : selected}
        openNotificationModal={openMuteNotificationModal}
        mutedChats={userMutedChats}
        reactiveNotification={reactiveNotification}
        participants={participants}
      />
      <ChatWrapper id="main-content" className="chat">
        {loadingUpload && <Loader width={100} height={100} label="Les medias sont en cours de traitement" />}
        <IonHeader mode="ios">
          <IonToolbar>
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol size="1.5">
                <IonButton routerDirection="forward" fill="clear" onClick={goToMessagesList}>
                  <IonIcon slot="start" color={'dark'} icon={arrowBackOutline} />
                </IonButton>
              </IonCol>
              <IonCol>
                <IonTitle className="text-center" color="dark">
                  <span className="title-only font-outfit">{chatName}</span>
                </IonTitle>
              </IonCol>
              {message ? (
                <IonCol size="1.5">
                  <IonMenuToggle className="menu-container">
                    <IonIcon icon={ellipsisVerticalOutline} />
                  </IonMenuToggle>
                </IonCol>
              ) : null}
            </IonRow>
          </IonToolbar>
        </IonHeader>

        {openSearchBox && (
          <SearchBox
            handleSearch={handleChatSearch}
            numberOfResults={chatSearchNumberOfResults}
            searchKey={chatSearchKey}
            handleArrowUp={handleSearchArrowUp}
            handleArrowDown={handleSearchArrowDown}
            closeSearch={handleSearchClose}
            loading={searchLoading}
          />
        )}

        <IonContent ref={scrollRef} className="chat__msg-container">
          {dispo && dispo?.attributes?.expired && (
            <IonChip className="chat_label-expired">
              La dispo est terminée, ce chat disparaîtra le{' '}
              {format(addDays(new Date(dispo?.attributes?.expirationDateTime), 7), 'dd/MM/yyyy')}
            </IonChip>
          )}
          {dispo && dispo?.attributes?.deleted && (
            <IonChip className="chat_label-expired margin-auto" style={{ textAlign: 'center' }}>
              Le dispo a été supprimé{' '}
            </IonChip>
          )}
          <IonLoading cssClass="my-custom-class" isOpen={loadingGetMessage} />
          <IonInfiniteScroll disabled={disableInfiniteScroll} position="top" onIonInfinite={more} threshold="100px">
            <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Chargement en cours..." />
          </IonInfiniteScroll>
          {Array.isArray(messages) && Boolean(messages.length) ? (
            <div ref={chatRef}>
              {messages.map((m: any, index: number) => {
                const showAvatar = index === 0 || m?.fromId !== messages[index - 1]?.fromId;

                // Check if the time is different from the next message
                const isDifferentTimeFromNext =
                  index === messages.length - 1 || m.createdAt !== messages[index + 1].createdAt;

                const idMessage = m.id;

                return (
                  <Fragment key={m.id}>
                    {!(
                      currentUser &&
                      currentUser.blockedUsers &&
                      m.blocked &&
                      currentUser.blockedUsers.findIndex((i: any) => i.id.toString() === m.blocked.toString()) > -1
                    ) && (
                      <MessageItem
                        item={m}
                        id={idMessage}
                        showAvatar={showAvatar}
                        showTime={isDifferentTimeFromNext}
                        participants={participants}
                        currentUser={currentUser}
                        handleOpenActionModal={handleOpenActionModal}
                        searchKey={chatSearchKey}
                        isHighlighted={idMessage === highlightedMessageId}
                        ref={index === messages.length - 1 ? lastMessageRef : null}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
          ) : !loading ? (
            <div className="chat_no-message">
              {isDispo && <CardChatDispo dispo={dispo} />}
              {isDispo ? (
                <p className="chat_dispo_no-message__text">
                  Aucun message n’a été posté pour l’instant, tu peux saluer les autres participants dès à présent.
                </p>
              ) : (
                <p className="chat_no-message__text">
                  C’est le début de votre conversation. Envoie un message à cet utilisateur !
                </p>
              )}
            </div>
          ) : (
            ''
          )}
        </IonContent>

        <IonToast
          isOpen={isOpenError}
          message={"Veuillez autoriser l'utilisation du microphone dans les paramètres de l'application."}
          onDidDismiss={() => setIsOpenError(false)}
          duration={5000}
          color="danger"
        ></IonToast>
        <IonFooter>
          {isEdit && (
            <div className="chat__edit-indicator">
              <IonButton onClick={cancelEdit} fill="clear">
                <IonIcon size="medium" icon={closeIcon} />
              </IonButton>
              <span>Modification du message</span>
            </div>
          )}
          {isUploadingFile && (
            <UploadedFilesPreview
              cancelUpload={closeUploadFileModal}
              handleFileDelete={handleFileDelete}
              files={selectedImages}
            />
          )}
          {isResponding && (
            <div className="chat__edit-indicator">
              <IonButton onClick={cancelRespond} fill="clear">
                <IonIcon size="medium" icon={messageRespondIcon} />
              </IonButton>
              <span>
                Répondre à{' '}
                {(selectedMsgItemFromUser?.isPro
                  ? selectedMsgItemFromUser?.companyName
                  : selectedMsgItemFromUser?.username) ||
                  (selectedMsgItemFromUser?.attributes?.isPro
                    ? selectedMsgItemFromUser?.attributes?.companyName
                    : selectedMsgItemFromUser?.attributes?.username)}
              </span>
            </div>
          )}
          {isRecordingVoice && (
            <div className="chat__edit-indicator">
              <IonIcon size="large" icon={recordingMic} />
              {isCancellingRecordingVoice ? (
                <div>Relâche pour annuler</div>
              ) : (
                <div>Glisse vers la gauche pour annuler, ou relâche pour envoyer.</div>
              )}
            </div>
          )}
          <input type="file" ref={inputFileRef} onChange={onSelectFile} className="ion-hide" multiple={true} />
          {!(isMeBlockedByUser || isUserBlockedByME) && (
            <IonItem>
              {isRecordingVoice ? (
                isCancellingRecordingVoice ? (
                  <IonIcon size="large" icon={redDeleteIcon} />
                ) : (
                  <IonIcon size="large" icon={deleteIcon} />
                )
              ) : (
                <button type="button" className="chat__attach" onClick={openFileUploadModal}>
                  <IonIcon size="large" icon={attachFileIcon}></IonIcon>
                </button>
              )}
              <form
                ref={formRef as any}
                onSubmit={(e) => {
                  if (isKeyboardVisible && textareaRef.current) {
                    textareaRef.current.setFocus();
                  }
                  onSubmit(e);
                }}
                className={`chat-form ${isRecordingVoice ? 'chat__audio-graph-container' : ''}`}
              >
                {isRecordingVoice ? (
                  <div className="chat__audio-graph-animation-container">
                    <div className="chat__audio-graph-timer">{formatTime(voiceRecordTimer)}</div>
                    <div className="chat__audio-graph-animation">
                      <AudioWaveAnimation
                        parentContainer={formRef}
                        isCancelling={isCancellingRecordingVoice}
                        ref={waveRef}
                      />
                    </div>
                  </div>
                ) : (
                  <IonTextarea
                    onPaste={handlePastedEvent}
                    onInput={handleBodyChange}
                    rows={1}
                    className="custom"
                    value={body}
                    onIonInput={handleInputMessageChange}
                    name="body"
                    id="body"
                    placeholder="Aa"
                    ref={textareaRef}
                    onFocus={scrollToBottom}
                    autoFocus
                  />
                )}
                {Boolean(body.trim().length || selectedImages.length) ? (
                  <button type="submit" className="chat__submit" disabled={sendMessageLoading}>
                    {isSendingMessage ? <IonSpinner name="crescent" /> : <IonIcon size="large" icon={sendIcon} />}
                  </button>
                ) : (
                  <>
                    {isMicroAuthorized && (
                      <div ref={micRef as any}>
                        <IonButton
                          {...bind()}
                          fill="clear"
                          className="chat__submit ion-no-padding"
                          disabled={!isMicroAuthorized}
                        >
                          <IonIcon
                            size="large"
                            icon={isRecordingVoice ? (isCancellingRecordingVoice ? redMicIcon : whiteMicIcon) : micIcon}
                          />
                        </IonButton>
                      </div>
                    )}
                  </>
                )}
              </form>
            </IonItem>
          )}
        </IonFooter>
        <MessageActionModal
          height="120px"
          isOpen={isActionModalOpen}
          isMine={isSelectedMsgMine}
          onDelete={handleMessageDelete}
          onEdit={handleMessageEdit}
          onCopy={handleMessageCopy}
          onRespond={handleMessageResponse}
          onCancel={handleActionModalCancel}
          isFile={selectedMsgItem?.type === 'file'}
        />
        <ConfirmModal
          isOpen={confirmDelete}
          title="Es-tu sûr de vouloir supprimer ce message ?"
          text="Cette action est définitive."
          okText="Confirmer"
          cancelText="Annuler"
          onCancel={cancelDelete}
          onConfirm={confirmMessageDelete}
          iconPerso={warningIcon}
        />
        <MuteNotificationModal
          handleClick={handleMuteChoice}
          isOpen={isMuteNotificationModalOpen}
          onCancel={closeMuteNotificationModal}
          loading={muteLoading}
        />
        <ComponentModal height="80%" isOpen={isFileUploadModalOpen} onCancel={closeFileUploadModal}>
          <IonHeader className="ion-no-border padding-t-25" mode="ios">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonTitle className="text-center">
                    <h5 className="chat__cmp-modal__title">Ajouter une pièce jointe</h5>
                  </IonTitle>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonText className="text-center">
                    <h5 className="chat__cmp-modal__subtitle">Avertissement</h5>
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol className="ion-padding-top">
                  <IonButton
                    className="chat__file-upload-modal_button"
                    onClick={openCamera}
                    expand="block"
                    color="primary"
                    disabled={isLoading}
                  >
                    Prendre une photo
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    className="chat__file-upload-modal_button"
                    onClick={handlePickPhotos}
                    expand="block"
                    fill="outline"
                    color="primary"
                    disabled={isLoading}
                  >
                    Galerie photo
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    className="chat__file-upload-modal_button"
                    onClick={handlePickVideos}
                    expand="block"
                    fill="outline"
                    color="primary"
                    disabled={isLoading}
                  >
                    Galerie vidéo
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    className="chat__file-upload-modal_button"
                    onClick={handlePickFiles}
                    expand="block"
                    fill="outline"
                    color="primary"
                    disabled={isLoading}
                  >
                    Fichiers
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton className="cancel text-black" fill="clear" expand="block" onClick={closeFileUploadModal}>
                    Annuler
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
          {isLoading && <IonSpinner color="dark" className={'custom-loader'}></IonSpinner>}
        </ComponentModal>
      </ChatWrapper>
    </>
  );
};

export default Chat;
