import { graphql } from '../../models/gql';
export const GET_EVENT_SALES = graphql(/* GraphQL */ `
  query dispoSales($id: ID) {
    DispoSales(id: $id) {
      data {
        id
        attributes {
          saleDate
          salePrice
          status
          buyer {
            data {
              id
              attributes {
                username
                lastname
                firstname
                isPro
                companyName
              }
            }
          }
        }
      }
    }
  }
`);
