import { IonIcon, IonRow } from '@ionic/react';
import React from 'react';

interface IDispoLabel {
  label: string;
  bgClass?: string;
  borderClass?: string;
  uppercase?: boolean;
  icon?: any;
}

const DispoLabel: React.FC<IDispoLabel> = ({ label, bgClass, borderClass, uppercase, icon }) => {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      className={`text-size-xxs font-outfit ${uppercase ? 'ion-text-uppercase' : ''} text-white ${
        bgClass ? bgClass : 'bg-green'
      } ${borderClass ? borderClass : ''} border-radius-10 padding-5`}
    >
      {icon && (
        <div style={{ paddingRight: '5px', paddingTop: '2px' }}>
          <IonIcon icon={icon} />
        </div>
      )}
      <div>{label}</div>
    </div>
  );
};

export default DispoLabel;
