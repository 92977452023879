import { IonGrid, IonLabel, IonRow, IonSegment, IonSegmentButton, IonToolbar } from '@ionic/react';
import { UserFollowerEntity, UsersPermissionsMe, UsersPermissionsUser } from '../../../models/gql/graphql';
import ProfilAction from '../ProfilAction';
import '../style.css';

enum Enum_segment_profil {
  dispo = 'dispo',
  publication = 'publication',
}

interface IProfilHeader {
  id: string;
  setSegmentValue: (value: Enum_segment_profil) => void;
  segmentValue: Enum_segment_profil;
  scrollTop: number;
  isBloked?: boolean;
  refetchUser: () => void;
  dataUser: UsersPermissionsUser;
  isBlockedByMe?: boolean;
  relationInfo: IRelation;
  relations: UserFollowerEntity[];
  loadingRelation: boolean;
  setButtonActionLoading: (id: boolean) => void;
  loadingUserData: boolean;
  isMyProfil: boolean;
  userConnected: UsersPermissionsMe;
}

const ProfilHeader: React.FC<IProfilHeader> = ({
  dataUser,
  id,
  setSegmentValue,
  segmentValue,
  scrollTop,
  isBloked,
  refetchUser,
  isBlockedByMe,
  relationInfo,
  relations,
  loadingRelation,
  setButtonActionLoading,
  loadingUserData,
  isMyProfil,
  userConnected,
}) => {
  return (
    <IonRow>
      <IonRow className="full-width bg-blue border-radius-bottom">
        <IonToolbar mode="ios" color="translucent">
          <IonRow className="full-width">
            <IonGrid>
              <ProfilAction
                id={id}
                scrollTop={scrollTop}
                isBloked={isBloked}
                refetchUser={refetchUser}
                dataUser={dataUser}
                isBlockedByMe={isBlockedByMe}
                relations={relations}
                loadingRelation={loadingRelation}
                relationInfo={relationInfo}
                setButtonActionLoading={setButtonActionLoading}
                loadingUserData={loadingUserData}
                isMyProfil={isMyProfil}
                userConnected={userConnected}
              />
              <IonRow>
                <IonSegment
                  value={segmentValue}
                  mode="ios"
                  onIonChange={(value) => setSegmentValue(value.detail.value as Enum_segment_profil)}
                  className="profil-segment full-width"
                  style={{ '--background': 'red' }}
                >
                  <IonSegmentButton value="dispo">
                    <IonLabel className="text-white">Dispo</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="publication">
                    <IonLabel className="text-white">Publication</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </IonRow>
            </IonGrid>
          </IonRow>
        </IonToolbar>
      </IonRow>
    </IonRow>
  );
};

export default ProfilHeader;
