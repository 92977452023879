import { useMutation } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { IonContent, IonPage } from '@ionic/react';
import LogRocket from 'logrocket';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../../../components';
import { VERIFY_SMS_CODE_QUERY } from '../../../../graphql/mutations/auth.graphql';
import { usePushNotification } from '../../../../hooks';
import { StorageUtils } from '../../../../utils';
import OtpInputCode from './OtpInputCode';
import './style.css';
import { useForm } from 'react-hook-form';
import { AuthVerifyCodeInput } from '../../../../models/gql/graphql';
import { useState } from 'react';

interface IVerifParams {
  phone: string;
  pro?: string;
  type?: string;
}

const Verification: React.FC = () => {
  const { phone, pro, type } = useParams<IVerifParams>();
  const [AuthVerifySmsCode, { loading }] = useMutation(VERIFY_SMS_CODE_QUERY);
  const { saveLateToken } = usePushNotification();
  const methods = useForm<AuthVerifyCodeInput>();
  const history = useHistory();
  const [noBackButton, setNoBackButton] = useState(true);
  const {
    formState: { errors },
    setError,
    clearErrors,
  } = methods;

  const onSubmit = async (dataToPost: any) => {
    if (phone) {
      dataToPost.phone = phone;
    }
    const { data } = await AuthVerifySmsCode({ variables: { data: dataToPost } });
    clearErrors('smsCode');
    await StorageUtils.set('authToken', data?.AuthVerifySmsCode?.jwt);
    saveLateToken();
    if (Capacitor.getPlatform() === 'ios') {
      // logrocket for all ios
      if (data?.AuthVerifySmsCode?.user?.id) {
        LogRocket.identify(data?.AuthVerifySmsCode?.user?.id);
      }
    }
    /*HyperDX.setGlobalAttributes({
      userId: data?.AuthVerifySmsCode?.user?.id!,
      userPhone: data?.AuthVerifySmsCode?.user?.phone!,
      userName: data?.AuthVerifySmsCode?.user?.username!,
    });*/
    // we should check if user already
    // for particulier user
    if (pro !== 'pro') {
      // first step if no username
      if (
        !data?.AuthVerifySmsCode?.user?.username ||
        data?.AuthVerifySmsCode?.user?.username === data?.AuthVerifySmsCode?.user?.phone
      ) {
        history.push('/signup/config');
        return;
      }
      if (!data?.AuthVerifySmsCode?.user?.interests?.length) {
        history.push('/signup/config-interest');
        return;
      }
      if (!data?.AuthVerifySmsCode?.user?.avatar?.id && !data?.AuthVerifySmsCode?.user?.profilePicture?.id) {
        history.push('/signup/config-pdp');
        return;
      }
      const event = new CustomEvent('reloadTedispotNotifications', { detail: '' });
      document.dispatchEvent(event);
      history.replace('/main/home');
      // for pro user
    } else {
      if (!data?.AuthVerifySmsCode?.user?.publishedAt) {
        history.replace('/signup/confirmation-pro');
      } else {
        const event = new CustomEvent('reloadTedispotNotifications', { detail: '' });
        document.dispatchEvent(event);
        history.replace('/main/home');
      }
    }
    //history.push(type ? '/signup/verifyAccount' : pro ? '/signup/confirmation-pro' : '/signup/config');
  };

  return (
    <IonPage>
      <Header title={type ? 'Login' : 'Inscription'} noButtonBack={false} />
      <IonContent className="ion-no-padding">
        <OtpInputCode
          onTimeoutReseted={(val) => setNoBackButton(!val)}
          phone={phone}
          onSubmit={onSubmit}
          type={type}
          loading={loading}
        />
      </IonContent>
    </IonPage>
  );
};

export default Verification;
