import { useMutation } from '@apollo/client';
import { IonButton, IonCheckbox, IonContent, IonPage, IonToast } from '@ionic/react';
import { calendarOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import { useHistory } from 'react-router-dom';
import { AppDatepicker, CardCustom, Header, InputCustom, PhoneInputCustom } from '../../../../components';
import { SIGNUP } from '../../../../graphql/mutations/auth.graphql';
import { AuthRegisterInput } from '../../../../models/gql/graphql';
import { __ } from '../../../../utils/traduction';
import './style.css';

const FormulaireParticulier: React.FC = () => {
  const history = useHistory();
  const [AuthRegister, { loading }] = useMutation(SIGNUP);
  const methods = useForm<AuthRegisterInput>();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;
  const [phone, setPhone] = useState();
  const [errorsMessage, setErrorsMessage] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const refCheckBoxPart = useRef(null);
  const [valueData, setValueData] = useState<AuthRegisterInput>();

  register('birthDate', { required: true });
  register('phone', { required: true });
  register('firstname', { required: true });
  register('lastname', { required: true });
  register('acceptNewsletter', { value: false });

  const onSubmit = async (dataToPost: any) => {
    dataToPost['isPro'] = false;
    try {
      const { data } = await AuthRegister({ variables: { input: dataToPost } });
      history.push(`/signup/verification/${dataToPost.phone}`);
    } catch (err: any) {
      setIsOpen(true);
      setErrorsMessage(__(err.message));
    }
  };

  // To override shadow-root (open)
  useEffect(() => {
    if (refCheckBoxPart && refCheckBoxPart.current) {
      const current: any = refCheckBoxPart?.current;
      setTimeout(() => {
        current?.shadowRoot?.querySelector('.label-text-wrapper')?.setAttribute('style', 'white-space:normal');
      }, 500);
    }
  }, []);

  const handleChangeData = (attr: string, val: any) => {
    setValueData({ ...valueData, [attr]: val.detail.value });
    setValue(attr as any, val.detail.value);
  };

  return (
    <FormProvider {...methods}>
      <IonPage>
        <Header title="Inscription" noButtonBack={false} />
        <IonContent className="ion-padding">
          <div style={{ marginBottom: 350 }}>
            <CardCustom>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <PhoneInputCustom
                    label="Numero de telephone"
                    handleChange={(data: string) => setValue('phone', data)}
                  ></PhoneInputCustom>
                  {errors.phone && <p className="errorDescription">Le numero de téléphone est requis</p>}
                </div>
                <div className="ion-margin-bottom">
                  <AppDatepicker
                    displayFormat="dd/MM/yyyy"
                    label="Date de naissance"
                    presentation="date"
                    name="dateBirth"
                    onChange={(value) => {
                      setValue('birthDate', value.detail.value);
                    }}
                    icon={{ icon: calendarOutline }}
                    showValidateButton={true}
                    max={new Date()}
                  />
                  {errors.birthDate && <p className="errorDescription">La date de naissance est requise</p>}
                </div>
                <InputCustom
                  handleChange={(e) => handleChangeData('lastname', e)}
                  label="Nom"
                  placeholder="Doe"
                  attr="lastname"
                  isName={true}
                  value={valueData?.lastname}
                />
                <InputCustom
                  handleChange={(e) => handleChangeData('firstname', e)}
                  label="Prenom"
                  placeholder="John"
                  attr="firstname"
                  isName={true}
                  value={valueData?.firstname}
                />

                <div className="ion-margin-bottom">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IonCheckbox
                      {...register('acceptCGU', { required: true, setValueAs: (v) => (v === 'on' ? false : v) })}
                      defaultChecked={true}
                      mode="md"
                      className="font-outfit"
                      color="dark"
                      labelPlacement="end"
                      onIonChange={(e) => setValue('acceptCGU', e.detail.checked ? e.detail.checked : null)}
                    >
                      J’accepte les
                    </IonCheckbox>
                    <a
                      href="https://www.tedispo.com/mention-legale-confidentialite"
                      onClick={() => console.log('click')}
                      style={{ textDecoration: 'none', marginLeft: 5 }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      CGU
                    </a>
                  </div>
                  {errors.acceptCGU && (
                    <p className="errorDescription">Vous devez accepter les Conditions Générales d'Utilisation</p>
                  )}
                </div>
                <div>
                  <IonCheckbox
                    className="ion-margin-bottom font-outfit wrap-text"
                    labelPlacement="end"
                    color="dark"
                    mode="md"
                    onIonChange={(e) => setValue('acceptNewsletter', e.detail.checked)}
                    slot="label-wrap-simple"
                    justify="end"
                    ref={refCheckBoxPart}
                  >
                    Je souhaite m’abonner à la newsletter et à l’offre commerciale
                  </IonCheckbox>
                </div>

                <IonButton disabled={loading} type="submit" className="ion-margin-top" expand="block" color="primary">
                  Continuer
                </IonButton>
              </form>
            </CardCustom>
          </div>
        </IonContent>
        <IonToast
          isOpen={isOpen}
          message={__(errorsMessage)}
          onDidDismiss={() => setIsOpen(false)}
          duration={5000}
          color="danger"
        ></IonToast>
      </IonPage>
    </FormProvider>
  );
};

export default FormulaireParticulier;
