/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import {
  IonItem,
  IonLabel,
  IonIcon,
  IonInput,
  IonText,
  IonList,
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonSearchbar,
} from '@ionic/react';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, filter, switchMap, catchError, tap } from 'rxjs/operators';
import './AppSearchAddressInput.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';
import { closeOutline } from 'ionicons/icons';
import AppSearchAddressInputWording, { IAppSearchAddressWording } from './AppSearchAddressInput.wording';

export const getPostcodeByLatLng = async (lat: number, lng: number, apiKey: string) => {
  if (!lat || !lng) return null;

  const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`);
  if (!res.ok) {
    return null;
  }
  const data = await res.json();
  const haspostalcodeItem = data?.results?.find(({ address_components }: any) => {
    return address_components.find(({ types }: any) => types.includes('postal_code'));
  });
  if (!haspostalcodeItem) return null;
  return haspostalcodeItem.address_components.find(({ types }: any) => types.includes('postal_code')).short_name;
};

export interface GooglePlacesAutocompleteProps {
  wording?: IAppSearchAddressWording;
  initialAddress?: IAddress;
  label: string;
  icon?: any;
  apiKey?: string;
  apiOptions?: Partial<LoaderOptions>;
  autocompletionRequest?: AutocompletionRequest;
  debounce?: number;
  name?: string;
  minLengthAutocomplete?: number;
  onLoadFailed?: (error: Error) => void;
  withSessionToken?: boolean;
  placeholder?: string;
  className?: string;
  onSelect?: (address: IAddress) => void;
  address?: IAddress;
}

export interface AutocompletionRequest {
  bounds?: [LatLng, LatLng];
  componentRestrictions?: { country: string | string[] };
  location?: LatLng;
  offset?: number;
  radius?: number;
  types?: string[];
}

const AppSearchAddressInput: React.FC<GooglePlacesAutocompleteProps> = (
  {
    wording,
    initialAddress,
    label,
    icon,
    name,
    apiKey = process.env.REACT_APP_GOOGLE_API_KEY || '',
    apiOptions = {},
    autocompletionRequest = {},
    debounce = 300,
    minLengthAutocomplete = 2,
    onLoadFailed = console.error,
    withSessionToken = false,
    className = '',
    onSelect,
    placeholder,
    address,
  }: GooglePlacesAutocompleteProps,
  ref
): React.ReactElement => {
  const { title, cancel } = wording || AppSearchAddressInputWording;
  const [addressTextResult, setAddressTextResult] = useState<string | undefined>('');
  const [addressTextSearch, setAddressTextSearch] = useState<string | undefined>('');
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | undefined>(
    undefined
  );
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | undefined>(undefined);
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken | undefined>(undefined);
  const [suggestions, setSuggestions] = useState<Array<any> | undefined>([]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const [state, setState] = useState({
    data: [],
    loading: false,
    errorMessage: '',
    noResults: false,
  });
  const [subject, setSubject] = useState<BehaviorSubject<any> | null>(new BehaviorSubject(''));

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (subject === null) {
      setSubject(new BehaviorSubject(''));
    } else {
      if (!autocompleteService) {
        return;
      }

      const observable = subject
        .pipe(
          map((s) => s.trim()),
          distinctUntilChanged(),
          filter((s) => s.length >= 2),
          debounceTime(debounce),
          switchMap(async (term) => {
            // setAddressTextSearch(term)
            return autocompleteService.getPlacePredictions(
              autocompletionRequestBuilder(autocompletionRequest, term, withSessionToken && sessionToken)
            );
          }),
          catchError(async (e) => ({
            loading: false,
            errorMessage: 'An application error occured',
          }))
        )
        .subscribe((response: any) => {
          if (!response) {
            setState({ data: [], loading: false, noResults: true, errorMessage: '' });
          }
          const data = (response.predictions || []).map((suggestion: { description: any }) => ({
            label: suggestion.description,
            value: suggestion,
          }));
          setState({ data, loading: false, noResults: data.length === 0, errorMessage: '' });
        });

      // eslint-disable-next-line consistent-return
      return () => {
        observable.unsubscribe();
        subject.unsubscribe();
      };
    }
  }, [subject, autocompleteService]);

  const autocompletionRequestBuilder = (
    autocompletionRequestArg: AutocompletionRequest,
    input: string,
    sessionTokenArg?: google.maps.places.AutocompleteSessionToken
  ): google.maps.places.AutocompletionRequest => {
    const { bounds, location, ...rest } = autocompletionRequestArg;

    const res: google.maps.places.AutocompletionRequest = {
      input,
      ...rest,
    };

    if (sessionTokenArg) {
      res.sessionToken = sessionTokenArg;
    }

    if (bounds) {
      res.bounds = new google.maps.LatLngBounds(...bounds);
    }

    if (location) {
      res.location = new google.maps.LatLng(location);
    }
    return res;
  };

  const getAddressDetails = (place: any): any => {
    const addressComponent: Array<any> | undefined = place.address_components;
    const streetNumber = addressComponent?.find((g) => g.types.find((t: string) => t === 'street_number'))?.long_name;
    const streetName = addressComponent?.find((g) => g.types.find((t: string) => t === 'route'))?.long_name;
    const cityName = addressComponent?.find(
      (g) => g.types.find((t: string) => t === 'locality') && g.types.find((t: string) => t === 'political')
    )?.long_name;
    const stateName = addressComponent?.find(
      (g) =>
        g.types.find((t: string) => t === 'administrative_area_level_1') &&
        g.types.find((t: string) => t === 'political')
    )?.long_name;
    const region = addressComponent?.find(
      (g) =>
        g.types.find((t: string) => t === 'administrative_area_level_2') &&
        g.types.find((t: string) => t === 'political')
    )?.long_name;
    const countryName = addressComponent?.find(
      (g) => g.types.find((t: string) => t === 'country') && g.types.find((t: string) => t === 'political')
    )?.long_name;
    const countryCode = addressComponent?.find(
      (g) => g.types.find((t: string) => t === 'country') && g.types.find((t: string) => t === 'political')
    )?.short_name;
    const zip = addressComponent?.find((g) => g.types.find((t: string) => t === 'postal_code'))?.long_name;

    const latLng = place?.geometry?.location?.toJSON();

    const address: any = {};
    if (streetNumber && streetNumber) {
      address.addressLine = `${streetNumber} ${streetName}`;
    } else {
      address.addressLine = place.name || place.formatted_address;
    }
    address.addressLine = place.name || place.formatted_address;
    if (cityName) address.addressCity = cityName;
    // if (region) address.region = region;
    if (countryName) address.addressCountry = countryName;
    // if (countryCode) address.countryCode = countryCode;
    if (zip) address.addressZipcode = zip;
    if (latLng) {
      const { lat, lng } = latLng;
      address.addressLat = lat;
      address.addressLng = lng;
    }

    return address;
  };

  // eslint-disable-next-line consistent-return
  const handleOnChange = (inputValue: any) => {
    if (subject) {
      return subject.next(inputValue);
    }
    // setAddressTextSearch(inputValue);
  };

  const handleSelect = (placeObject: any | undefined) => {
    const placeId = placeObject.value.place_id;
    if (placesService) {
      placesService.getDetails(
        {
          placeId,
        },
        async (place, status) => {
          if (place && status === google.maps.places.PlacesServiceStatus.OK) {
            const address = getAddressDetails({ ...place, name: placeObject.label });
            if (!address.postalCode && address.location) {
              const { lng, lat } = address.location;
              try {
                const postalCode = await getPostcodeByLatLng(lat, lng, apiKey);
                address.postalCode = postalCode;
              } catch (e) {
                console.log(e);
              }
            }
            setAddressTextSearch(placeObject.label);
            setAddressTextResult(placeObject.label);
            if (onSelect) onSelect(address);
            closeModal();
          } else {
            console.error('PlacesService has error when retrieving place details');
          }
        }
      );
    } else {
      console.error('google.maps.places.PlacesService is not loaded');
    }
  };

  const openModal = () => {
    setState({
      data: [],
      loading: false,
      errorMessage: '',
      noResults: true,
    });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  useEffect(() => {
    const init = async () => {
      try {
        if (!window.google || !window.google.maps || !window.google.maps.places) {
          await new Loader({ apiKey, ...{ libraries: ['places'], ...apiOptions } }).load();
        }
        setAutocompleteService(new window.google.maps.places.AutocompleteService());
        setPlacesService(new google.maps.places.PlacesService(document.createElement('input')));
        setSessionToken(new google.maps.places.AutocompleteSessionToken());
      } catch (error: Error | any) {
        onLoadFailed(error);
      }
    };

    if (apiKey) init();
    else {
      setAutocompleteService(new window.google.maps.places.AutocompleteService());
      setPlacesService(new google.maps.places.PlacesService(document.createElement('input')));
      setSessionToken(new google.maps.places.AutocompleteSessionToken());
    }
  }, [apiKey]);

  useEffect(() => {
    setAddressTextSearch(initialAddress?.addressLine as string);
    setAddressTextResult(initialAddress?.addressLine as string);

    if (initialAddress && onSelect && address !== initialAddress) {
      onSelect(initialAddress);
    }
  }, [initialAddress]);

  const renderIcon = (iconItem: any) => {
    return iconItem.iconType && iconItem.iconType === 'feather' ? (
      <IonText
        className="ion_text_icon"
        slot={iconItem?.slot}
        onClick={(e) => (iconItem?.onClick ? iconItem?.onClick(e) : null)}
      >
        <div className="feathericon_container">{iconItem.icon}</div>
      </IonText>
    ) : (
      <IonIcon
        slot={iconItem?.slot}
        icon={iconItem?.icon as string}
        onClick={(e) => (iconItem?.onClick ? iconItem?.onClick(e) : null)}
        className="icon-search-position"
      />
    );
  };
  return (
    <div className="position-relative">
      <div className="ion-margin-bottom">
        <p className="custom-label font-inter">{label}</p>
        <IonInput
          mode="md"
          className="custom-input"
          type="text"
          placeholder={placeholder || ''}
          value={addressTextResult}
          onIonFocus={() => openModal()}
        />
      </div>

      {icon && renderIcon(icon)}
      <IonModal isOpen={modalIsOpen} mode="ios">
        <IonHeader translucent>
          <IonToolbar>
            <IonSearchbar
              className="in-modal ion-margin-top"
              mode="ios"
              cancelButtonIcon={closeOutline}
              placeholder={label}
              cancelButtonText={cancel}
              onIonInput={(e: any) => handleOnChange(e.target.value)}
              onIonCancel={() => closeModal()}
              showCancelButton="always"
            />
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="ion-padding">
          {state.data && state.data.length > 0 && (
            <IonList>
              {state.data.map((suggestion: any) => (
                <IonItem key={suggestion.value.place_id} onClick={() => handleSelect(suggestion)}>
                  <IonLabel>{suggestion.label}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          )}
        </IonContent>
      </IonModal>
    </div>
  );
};
export default AppSearchAddressInput;
