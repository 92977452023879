import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonProgressBar,
  IonRow,
  IonSkeletonText,
  IonText,
} from '@ionic/react';
import { useHistory } from 'react-router';
import ArrowSvg from '../../../assets/icons/Arrow.svg';
import { GetAllParticipations } from '../../../hooks/useDispoDetail';
import { useMemo } from 'react';

interface IMemberProgressBar {
  type?: string;
  dispoData: IDispoDetail;
  title?: string;
  showIcon?: boolean;
  noBorder?: boolean;
}

export const MemberProgressBar: React.FC<IMemberProgressBar> = ({
  type = 'detail',
  dispoData,
  title,
  showIcon,
  noBorder = false,
}) => {
  const history = useHistory();
  const { id, maxInvited, userEventStatus } = dispoData;

  const { participations: eventParticipations, loading } = GetAllParticipations(dispoData.id, [
    'payment_failed',
    'payment_created',
    'payment_canceled',
    'waiting',
  ]);

  const accepted = useMemo(() => {
    return eventParticipations.filter((i) => i.attributes?.status === 'accepted');
  }, [eventParticipations]);

  const invited = useMemo(() => {
    return eventParticipations.filter(
      (i) =>
        i.attributes?.status === 'accepted' ||
        i.attributes?.status === 'invited' ||
        i.attributes?.status === 'refused' ||
        i.attributes?.status === 'canceled'
    );
  }, [eventParticipations]);

  return (
    <IonRow style={{ zIndex: 1 }} className="bg-white">
      {userEventStatus != 'refused' && !loading && (
        <IonRow className="margin-auto width-90 " onClick={() => history.push(`/main/dispos/${id}/participants`)}>
          {!!accepted.length ||
            (accepted.length == 0 && type != 'detail' && (
              <IonGrid>
                <IonRow>
                  {accepted.length ? (
                    <IonText className="text-bolder  padding-2 text-size-md margin-l-20">{`${accepted.length} ${
                      accepted.length === 1 ? 'participant(e)' : 'participant(e)s'
                    }`}</IonText>
                  ) : (
                    <IonText className="text-bolder padding-2  text-size-md margin-l-20">Aucun inscrit</IonText>
                  )}
                </IonRow>
              </IonGrid>
            ))}
          <IonCard
            className={`border-radius box-shadow-custom font-outfit ion-no-padding text-black ${
              noBorder ? 'card-no-border' : ''
            }`}
            style={{
              boxShadow: 'none',
              marginTop: '10px',
            }}
          >
            <IonCardContent>
              {!!title && (
                <IonRow className="text-size-md text-bold">
                  <span>{title}</span>
                </IonRow>
              )}
              <IonRow>
                <IonCol className="full-width">
                  {dispoData.event?.attributes?.type === 'public' ? (
                    <div className="width-p-90 text-size-xs">
                      {!!maxInvited && (!!accepted.length || accepted.length == 0) && (
                        <>
                          {maxInvited - accepted.length > 0 ? (
                            <IonText>{`${accepted.length} inscrites. encore ${
                              maxInvited - accepted.length
                            } places disponibles.`}</IonText>
                          ) : (
                            <div className=" text-center">
                              <IonText>Toutes les places sont prises!</IonText>
                            </div>
                          )}
                        </>
                      )}
                      {!maxInvited && <IonText>Le nombre de place maximal n'est pas défini</IonText>}
                      <IonProgressBar
                        mode="ios"
                        color="success"
                        value={maxInvited && accepted.length ? accepted.length / maxInvited : 0}
                        className="height-10 border-radius"
                      ></IonProgressBar>
                    </div>
                  ) : (
                    <div className="width-p-90 text-size-xs">
                      {(!!accepted.length || accepted.length == 0) && (
                        <>
                          <IonText>{`${accepted.length} acceptés sur ${invited.length} invités.`}</IonText>
                        </>
                      )}
                      {!maxInvited && <IonText>Le nombre de place maximal n'est pas défini</IonText>}
                      <IonProgressBar
                        mode="ios"
                        color="success"
                        value={maxInvited && accepted.length ? accepted.length / maxInvited : 0}
                        className="height-10 border-radius"
                      ></IonProgressBar>
                    </div>
                  )}
                </IonCol>
                {!!showIcon && (
                  <IonCol size="1">
                    <IonIcon icon={ArrowSvg}></IonIcon>
                  </IonCol>
                )}
              </IonRow>
            </IonCardContent>
          </IonCard>
        </IonRow>
      )}
    </IonRow>
  );
};
