import { useMutation } from '@apollo/client';
import {
  IonButton,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { close, closeOutline } from 'ionicons/icons';
import { cloneDeep } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useHistory } from 'react-router-dom';
import imageSvg from '../../assets/icons/image.svg';
import { CREATE_POST } from '../../graphql/mutations/post.graphql';
import { UPLOAD } from '../../graphql/mutations/upload.graphql';
import { useToast } from '../../hooks';
import { PostEntity, PostInput } from '../../models/gql/graphql';
import {
  clearImage,
  handleDescriptionChange,
  handlePickPhotos,
  onSelectFile,
  pickVideos,
  uploadFiles,
} from '../../pages/Post/utils';
import { useAppStore } from '../../store';
import { getUserId } from '../../utils';
import { __ } from '../../utils/traduction';
import { checkForbiddenWordPasted, stripTag } from '../../utils/word';
import RichEditor from '../RichEditor';
import VideoView from '../VideoView';
import './style.css';
import Loader from '../Loader';
import { usePosts } from '../../hooks/usePosts';

interface IPostForm {
  selectedMedia: IImage[];
  setSelectedMedia: (image: IImage[]) => void;
  closeModal: (isSuccess: boolean) => void;
  post?: PostEntity;
}

const PostForm: React.FC<IPostForm> = ({ selectedMedia, setSelectedMedia, closeModal }) => {
  const history = useHistory();
  const [description, setDescription] = useState<string>('');
  const [loadSpinner, setLoading] = useState<boolean>(false);
  const [loadPub, setPub] = useState<boolean>(false);
  const [createPost, { loading: loadingPost }] = useMutation(CREATE_POST);
  const refBtnPick = useRef<HTMLInputElement>(null);
  const [upload, { loading: loadingUpload }] = useMutation(UPLOAD);
  const [messageError, setMessageError] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    RootStore: { algoliaCache, forbiddenWords },
  } = useAppStore();
  const { refresh, setUiState } = useInstantSearch();
  const toast = useToast();
  const [presentToast] = useIonToast();
  const { refreshPost } = usePosts(getUserId(), 'myPost');

  const post = async () => {
    setPub(true);
    const uploadedFile = uploadFiles(selectedMedia, upload);
    const postData = {
      medias: await Promise.all(uploadedFile),
      description: description,
      author: getUserId(),
      publishedAt: new Date(),
    };
    const { data: postResponse } = await createPost({ variables: { data: postData as PostInput } });
    setSelectedMedia([]);
    closeModal(true);
    setPub(false);
    setUiState((state) => {
      Object.keys(state).forEach((algoliaIndex) => {
        console.log(state[algoliaIndex], algoliaIndex);
        state[algoliaIndex].page = 0;
      });
      return state;
    });
    await algoliaCache.invalidate();
    await refreshPost();
    console.log('post refreh');
    setTimeout(async () => {
      await refresh();
    }, 300);
    if (postResponse) {
      toast.success('Nouvelle publication publiée avec succès!', 7000);
      history.push('/main/posts/' + getUserId() + '/' + postResponse.createPost?.data?.id);
      return;
    }
  };

  const handlePastedEvent = useCallback(
    (field: any, e: any) => {
      let pasted: string;
      if (!e?.clipboardData) {
        if (e.target.value) {
          pasted = e.target.value; // whole text
        } else {
          pasted = e.target.textContent;
        }
      } else {
        pasted = stripTag(e?.clipboardData?.getData('text'));
      }
      const newPastedArr = stripTag(pasted)
        .split(' ')
        .filter((text: string) => {
          return !checkForbiddenWordPasted(forbiddenWords, text);
        });
      if (stripTag(pasted).split(' ').length !== newPastedArr.length) {
        const changed = newPastedArr.join(' ');
        setTimeout(() => {
          setDescription(`${description} ${changed}`);
          presentToast('Des mots interdits ont été détéctés et a été supprimé de votre entrée', 1000);
        }, 100);
      }
    },
    [forbiddenWords, description]
  );
  return (
    <div className={'gallery-container'}>
      {loadPub && <Loader label="Nous préparons ta publication ..." width={150} height={150} />}
      <IonHeader className="bg-light-shade">
        <IonToolbar className={'relative margin-t-15'}>
          <IonTitle className="text-center margin-t-15 ion-text-capitalize font-outfit">Nouvelle publication</IonTitle>
          <IonLabel
            onClick={() => {
              setSelectedMedia([]);
              closeModal(false);
            }}
            className={'ion-btn-clear ion-center margin-t-25'}
          >
            <IonIcon icon={closeOutline} className="text-black text-size-23 text-bolder"></IonIcon>
          </IonLabel>
        </IonToolbar>
      </IonHeader>
      <IonContent className="bg-light-shade gallery-container">
        <IonGrid className="ion-justify-content-center ion-align-items-center width-p-95 min-height-200 ion-padding bg-white border-radius height-auto ion-margin">
          <MutliImageForm
            selectedMedia={selectedMedia}
            clearImage={(value: number) => clearImage(value, selectedMedia, setSelectedMedia)}
          />
        </IonGrid>
        <IonGrid className="post-form ion-justify-content-center ion-align-items-center width-p-95 min-height-200 bg-white border-radius height-auto ion-margin">
          <RichEditor
            onPaste={(e: any) => handlePastedEvent('description', e)}
            handleChange={(value: string) =>
              handleDescriptionChange(value, forbiddenWords, setDescription, presentToast)
            }
            value={description}
            label=""
            placeholder="Rédige ta publication"
          />
        </IonGrid>
        <div style={{ display: 'none' }}>
          <input
            type="file"
            accept="image/*"
            ref={refBtnPick}
            onChange={(f: any) => onSelectFile(f, selectedMedia, setSelectedMedia, setIsOpen, setMessageError)}
            className="ion-hide"
            multiple={true}
          />
        </div>
        <IonToast
          isOpen={isOpen}
          message={__(messageError)}
          onDidDismiss={() => setIsOpen(false)}
          duration={5000}
          color="danger"
        ></IonToast>
      </IonContent>
      <IonRow className="relative ion-justify-content-center gallery-btn-action">
        <IonButton
          expand="block"
          color="primary"
          onClick={() => pickVideos(selectedMedia, setSelectedMedia, setIsOpen, setMessageError, setLoading)}
          className="width-p-90"
          disabled={loadingPost || loadingUpload || loadSpinner}
        >
          Acceder à ma galerie video
        </IonButton>
        <IonButton
          expand="block"
          color="primary"
          onClick={() => handlePickPhotos(refBtnPick, selectedMedia, setSelectedMedia, setIsOpen, setMessageError)}
          className="width-p-90"
          disabled={loadingPost || loadingUpload || loadSpinner}
        >
          Acceder à ma galerie photo
        </IonButton>
        <IonButton
          expand="block"
          fill="solid"
          color="primary"
          className="width-p-90 "
          onClick={() => {
            post();
          }}
          disabled={loadingPost || !selectedMedia.length || loadingUpload || loadSpinner}
        >
          Publier
        </IonButton>
        {loadSpinner && <IonSpinner color="dark" className={'custom-loader'}></IonSpinner>}
      </IonRow>
    </div>
  );
};
interface IMutliImageForm {
  selectedMedia: IImage[];
  clearImage: (value: number) => void;
}

export const MutliImageForm: React.FC<IMutliImageForm> = ({ selectedMedia, clearImage }) => {
  const cloneMediaArray = cloneDeep(selectedMedia);
  delete cloneMediaArray[0];
  return (
    <>
      <IonRow>
        <IonIcon icon={imageSvg} size="small" />
        <IonText className="font-outfit margin-l-10 text-bold">{`${selectedMedia.length} photo${
          selectedMedia.length ? 's' : ''
        } sélectionnée${selectedMedia.length ? 's' : ''}`}</IonText>
      </IonRow>
      {!!selectedMedia.length && (
        <div className="galery-post">
          <div className={`galery-post-item galery-post-main`}>
            <div className="img-container full">
              {selectedMedia[0]?.file?.type?.includes('image') ||
              selectedMedia[0]?.file?.attributes?.mime?.includes('image') ? (
                <img src={selectedMedia[0].url} alt="image pdp" className="full" />
              ) : (
                <VideoView
                  url={selectedMedia[0].url || ''}
                  className="full"
                  autoPlay={true}
                  file={selectedMedia[0]?.file}
                  previewUrl={selectedMedia[0]?.file?.previewUrl || ''}
                ></VideoView>
              )}
              <CloseImage number={0} clearImage={clearImage} />
              <div className="number-container">1</div>
            </div>
          </div>
          {cloneMediaArray.map((imageData, index: number) => {
            return (
              <div key={index}>
                <div className={`galery-post-item galery-post-${index} full`}>
                  <div className="img-container full">
                    {imageData?.file?.type?.includes('image') ||
                    imageData?.file?.attributes?.mime?.includes('image') ? (
                      <img src={imageData.url} alt="image pdp" className="full" />
                    ) : (
                      <VideoView
                        url={imageData.url || ''}
                        className="full"
                        autoPlay={true}
                        file={imageData.file}
                        previewUrl={imageData?.file?.previewUrl || ''}
                      ></VideoView>
                    )}
                    <CloseImage number={index} clearImage={clearImage} />
                    <div className="number-container">{index + 1}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default PostForm;

interface ICloseImage {
  clearImage: (value: number) => void;
  number: number;
}

const CloseImage: React.FC<ICloseImage> = ({ number, clearImage }) => {
  return (
    <IonIcon
      slot="icon-only"
      className="close-img text-blue"
      icon={close}
      onClick={(e) => clearImage(number)}
    ></IonIcon>
  );
};
