import { useMutation, useQuery } from '@apollo/client';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, UseFormSetValue, useForm } from 'react-hook-form';
import editSvg from '../../../assets/icons/edit2.svg';
import { AppDatepicker, InputCustom, Loader, PhoneInputCustom, SelectCustom } from '../../../components';
import { ComponentModal } from '../../../components/Modals';
import SimpleHeader from '../../../components/SimpleHeader';
import { UPDATE_ME, VERIFYPHONE, VERIFY_SMS_CODE_QUERY } from '../../../graphql/mutations/auth.graphql';
import { GET_ME } from '../../../graphql/queries/user.graphql';
import { UsersPermissionsUserInput } from '../../../models/gql/graphql';
import { convertDateToSimple, convertDateWithYear, getUserId } from '../../../utils';
import legalStatusOptions from '../../Auth/Signup/Formulaire/options';
import OtpInputCode from '../../Auth/Signup/Verification/OtpInputCode';
import { NotificationSection } from '../../DispoDetail/components/NotificationSection';
import { Keyboard } from '@capacitor/keyboard';
import { useAppStore } from '../../../store';

const Informations: React.FC = () => {
  const { data, loading: loadingDataMe, refetch: refetchMeA } = useQuery(GET_ME);
  const [updateMe, { loading: loadIngUpdate }] = useMutation(UPDATE_ME);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<ItemSelected>();
  const [showVerification, setShowVerification] = useState<boolean>(false);
  const methods = useForm<UsersPermissionsUserInput>();
  const { handleSubmit, setValue, getValues, formState, watch, register, reset } = methods;
  const [AuthVerifySmsCode, { loading: loadingVerification }] = useMutation(VERIFY_SMS_CODE_QUERY);
  const [VerifyPhone, { loading: loadingPhone }] = useMutation(VERIFYPHONE);
  const [messageError, setMessageError] = useState<string>();
  const [isValid, setValidate] = useState<boolean>(false);
  const [valueDataD, setValueDataD] = useState<UsersPermissionsUserInput>();
  const {
    RootStore: { getUserData },
  } = useAppStore();
  const userId = getUserId();

  const verify = async (dataToPost: any) => {
    try {
      await AuthVerifySmsCode({
        variables: { data: { ...dataToPost, userId: userId ? userId.toString() : '0' } },
      });
      await updateMe({ variables: { data: { phone: getValues('phone') } } });
      refetchMeA();
      await getUserData();
      setShowVerification(false);
      setMessageError('');
      setIsOpenModal(false);
    } catch (e: any) {
      setMessageError(e?.message);
    }
  };

  useEffect(() => {
    if (showVerification || isOpenModal) {
      Keyboard.show();
      Keyboard.setResizeMode({ mode: 'ionic' as any });
    }
  }, [showVerification, isOpenModal]);

  const listItems = useMemo(() => {
    if (!loadIngUpdate && data) {
      const itemsParticulier: Array<ItemSelected> = [
        {
          label: 'Numéro de téléphone',
          data: data.me?.phone,
          showIcon: true,
          attr: 'phone',
          text: 'Modification de ton numéro de téléphone',
        },
        // { label: 'Date de naissance', data: data.me?.birthDate, showIcon: false, attr: 'birthDate' },
        {
          label: 'Date de naissance',
          data: data.me?.birthDate,
          showIcon: true,
          attr: 'birthDate',
          text: 'Modification de ta Date de Naissance',
        },
        {
          label: 'Prénom',
          data: data.me?.firstname,
          showIcon: true,
          attr: 'firstname',
          text: 'Modification du Prénom',
          isName: true,
        },
        {
          label: 'Nom',
          data: data.me?.lastname,
          showIcon: true,
          attr: 'lastname',
          placeholder: 'Fnac',
          text: 'Modification du Nom',
          isName: true,
        },
      ];
      return !data.me?.isPro
        ? itemsParticulier
        : [
            {
              label: 'Numéro de téléphone',
              data: data.me?.phone,
              showIcon: true,
              attr: 'phone',
              text: 'Modification de ton numéro de téléphone',
            },
            {
              label: 'Adresse email',
              data: data.me?.email,
              showIcon: true,
              attr: 'email',
              placeholder: 'marie@gmail.com',
              text: 'Modification de ton adresse mail',
            },
            {
              label: 'Date de naissance',
              data: data.me?.birthDate,
              showIcon: true,
              attr: 'birthDate',
              text: 'Modification de ta Date de Naissance',
            },
            {
              label: 'Prénom',
              data: data.me?.firstname,
              showIcon: true,
              attr: 'firstname',
              text: 'Modification du Prénom',
              isName: true,
            },
            {
              label: 'Nom',
              data: data.me?.lastname,
              showIcon: true,
              attr: 'lastname',
              placeholder: 'Fnac',
              text: 'Modification du Nom',
              isName: true,
            },
            { label: 'Entreprise', data: data.me?.companyName, showIcon: false, attr: 'companyName' },
            { label: 'SIRET', data: data.me?.siret, showIcon: false, attr: 'siret' },
            { label: 'Poste', data: data.me.job, showIcon: true, attr: 'job' },
            { label: 'Forme juridique', data: data.me.legalStatus, showIcon: true, attr: 'legalStatus' },
          ];
    } else {
      return [];
    }
  }, [data, loadIngUpdate]);

  const selectItem = (item: ItemSelected) => {
    reset();
    setMessageError('');
    setShowVerification(false);
    setItemSelected(item);
    setIsOpenModal(true);
  };
  const onSubmit = async (dataToPost: any) => {
    console.log({ dataToPost });
    if (!dataToPost.phone) {
      try {
        await updateMe({ variables: { data: dataToPost } });
        await getUserData();
        if (refetchMeA) {
          refetchMeA();
          setIsOpenModal(false);
          setValueDataD({
            lastname: '',
            firstname: '',
          });
          setValue('firstname', '');
          setValue('lastname', '');
        }
      } catch (err: any) {
        console.log(err);
      }
    } else {
      try {
        setMessageError('');
        await VerifyPhone({ variables: { data: dataToPost } });
        setIsOpenModal(false);
        setShowVerification(true);
      } catch (err: any) {
        setMessageError(err.message);
      }
    }
  };

  useEffect(() => {
    if (itemSelected?.attr === 'phone') {
      setValidate(false);
    } else {
      setValidate(true);
    }
  }, [itemSelected]);

  const handleChangeData = (attr: string, val: any) => {
    setValueDataD({ ...valueDataD, [attr]: val.detail.value });
    setValue(attr as any, val.detail.value);
  };

  return (
    <FormProvider {...methods}>
      <IonPage>
        <SimpleHeader
          title="Mes Informations"
          noButtonBack={false}
          showMenuButton={false}
          backButtonColor="text-black"
        ></SimpleHeader>
        {(loadingDataMe || loadIngUpdate) && <Loader width={100} height={100} label="Traitement en cours..." />}
        <IonContent className="ion-padding">
          <IonGrid className="ion-justify-content-center ion-align-items-center width-p-95 bg-white border-radius">
            <IonList lines="none" className="border-radius">
              {listItems.map((item: ItemSelected, index) => (
                <IonItem
                  key={index}
                  onClick={() => {
                    if (item.showIcon && item.attr) selectItem(item);
                  }}
                  style={
                    index !== listItems.length - 1
                      ? {
                          borderBottom: '2px solid #3D3BEB',
                        }
                      : {}
                  }
                >
                  <IonLabel text-wrap>
                    <IonRow>
                      <IonCol className="text-gray-400 font-outfit">
                        <span className="text-bold">{item.label}</span>
                        <p>{item.attr === 'birthDate' ? convertDateWithYear(item.data as string) : item.data}</p>
                      </IonCol>
                      {item.showIcon && (
                        <IonCol className="yellow-icon margin-r-5 text-size-xl" size="2">
                          <IonIcon src={editSvg} size="large"></IonIcon>
                        </IonCol>
                      )}
                    </IonRow>
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonGrid>
        </IonContent>
        <ComponentModal
          onCancel={() => {
            setIsOpenModal(false);
          }}
          isOpen={isOpenModal}
          height="300px"
        >
          <>
            <IonHeader className="ion-no-border padding-t-25" mode="ios">
              <IonToolbar mode="ios">
                <IonTitle className="text-center font-outfit">
                  {itemSelected?.text ? itemSelected.text : 'Modification du ' + itemSelected?.label}{' '}
                </IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-no-padding ion-padding-horizontal">
              <form onSubmit={handleSubmit(onSubmit)}>
                <DynamicInputComponent
                  handleChangeInputC={handleChangeData}
                  itemSelected={itemSelected}
                  setValue={setValue}
                  setValidate={setValidate}
                  value={valueDataD}
                />
                {messageError && (
                  <NotificationSection message={messageError} icon={closeCircleOutline} textColor="red" />
                )}
                <IonButton
                  type="submit"
                  expand="block"
                  fill="solid"
                  color="primary"
                  disabled={loadIngUpdate || loadingPhone || !isValid}
                  className="d-absolute-bottom ion-padding-horizontal border-radius-20"
                >
                  <span>Sauvegarder</span>
                </IonButton>
                <IonButton
                  expand="block"
                  fill="clear"
                  disabled={loadIngUpdate}
                  className="d-absolute-bottom ion-padding-horizontal text-underligne text-black"
                  onClick={() => setIsOpenModal(false)}
                >
                  Annuler
                </IonButton>
              </form>
            </IonContent>
          </>
        </ComponentModal>
        <ComponentModal
          onCancel={() => {
            setIsOpenModal(false);
            setMessageError('');
          }}
          isOpen={showVerification}
          height="260px"
        >
          <OtpInputCode
            phone={getValues('phone') || ''}
            onSubmit={verify}
            showIcon={false}
            loading={loadingVerification}
            messageError={messageError}
          />
        </ComponentModal>
      </IonPage>
    </FormProvider>
  );
};

export default Informations;

interface IInput {
  itemSelected?: ItemSelected;
  setValidate?: (isValid: boolean) => void;
  setValue: UseFormSetValue<UsersPermissionsUserInput>;
  handleChangeInputC?: (attr: string, e: any) => void;
  value?: any;
}
const DynamicInputComponent: React.FC<IInput> = ({
  itemSelected,
  setValue,
  setValidate,
  handleChangeInputC,
  value,
}) => {
  if (itemSelected?.attr === 'birthDate') {
    return <BirthDatePicker setValue={setValue} />;
  } else if (itemSelected?.attr === 'legalStatus') {
    return <LegalStatusSelect setValue={setValue} />;
  } else if (itemSelected?.attr === 'phone') {
    return <PhoneInput setValue={setValue} setValidate={setValidate} />;
  } else {
    return (
      <InputCustom
        value={value && value[itemSelected?.attr as string]}
        handleChange={(e) => handleChangeInputC?.(itemSelected?.attr as string, e)}
        label={itemSelected?.label}
        placeholder={itemSelected?.placeholder || ''}
        attr={itemSelected?.attr}
        isName={itemSelected?.isName}
      />
    );
  }
};

const BirthDatePicker: React.FC<IInput> = ({ setValue }) => (
  <AppDatepicker
    displayFormat="dd/MM/yyyy"
    label=""
    min={new Date(1940, 1, 1)}
    presentation="date"
    name="dateDisponibility"
    onChange={(value) => {
      setValue('birthDate', convertDateToSimple(new Date(value.detail.value)));
    }}
    showValidateButton={true}
    max={new Date()}
  />
);

const LegalStatusSelect: React.FC<IInput> = ({ setValue }) => (
  <SelectCustom
    label=""
    placeholder="Sélectionnez une forme juridique"
    options={legalStatusOptions}
    attr="legalStatus"
  />
);

const PhoneInput: React.FC<IInput> = ({ setValue, setValidate }) => (
  <div>
    <PhoneInputCustom
      setValidate={setValidate}
      handleChange={(data: string) => {
        setValue('phone', data);
      }}
    ></PhoneInputCustom>
  </div>
);
