import { useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router';
import { DELETE_EVENT } from '../../../graphql/mutations/delete-event.graphql';
import { ConfirmModal } from '../../../components/Modals';
import warningSvg from '../../../assets/icons/warning.svg';
import { useToast } from '../../../hooks';
import { deleteEventToCalendar } from '../../../hooks/synchroniseCalendar';
import { db } from '../../../config';
import { doc, deleteDoc } from 'firebase/firestore';

interface IDeleteEvent {
  showModal: boolean;
  handleCancel: () => void;
}
export const DeleteEvent: React.FC<IDeleteEvent> = ({ showModal, handleCancel }) => {
  const { id } = useParams<IVerifParams>();
  const history = useHistory();
  const [deleteEvent, { loading: loadingEvent }] = useMutation(DELETE_EVENT);
  const toast = useToast();

  const handleDeleteEvent = async () => {
    try {
      const { data: dataEvent } = await deleteEvent({ variables: { id: id } });
      if (dataEvent?.deleteEvent?.data) {
        await deleteEventToCalendar(dataEvent.deleteEvent.data);
        const docRef = doc(db, 'messages', `dispo_${dataEvent.deleteEvent.data.id}`);
        await deleteDoc(docRef);
      }
      handleCancel();
      toast.success('Dispo supprimée avec succès.', 5000);
      history.push('/main/dispos?fromDelete=true');
    } catch (e) {
      toast.error('Erreur lors de la supprission du dispo.', 5000);
    }
    return;
  };

  return (
    <ConfirmModal
      isOpen={showModal}
      title="Es-tu sûr de vouloir supprimer cette dispo?"
      text="Cette action est définitive. L'ensemble des participants recevront une notification d'alerte"
      okText="Confirmer"
      cancelText="Annuler"
      iconPerso={warningSvg}
      onConfirm={handleDeleteEvent}
      onCancel={handleCancel}
      height="425px"
    />
  );
};
