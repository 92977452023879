export const conditionCodes = [
  {
    code: 'BlowingDust',
    description: 'Blowing dust or sandstorm',
    type: 'visibility',
    icon: 'assets/weather/fog.svg',
  },
  {
    code: 'Clear',
    description: 'Clear',
    type: 'visibility',
    icon: 'assets/weather/sunny.svg',
  },
  {
    code: 'Cloudy',
    description: 'Cloudy, overcast conditions',
    type: 'visibility',
    icon: 'assets/weather/cloudy.svg',
  },
  {
    code: 'Foggy',
    description: 'Fog',
    type: 'visibility',
    icon: 'assets/weather/fog.svg',
  },
  {
    code: 'Haze',
    description: 'Haze',
    type: 'visibility',
    icon: 'assets/weather/fog.svg',
  },
  {
    code: 'MostlyClear',
    description: 'Mostly clear',
    type: 'visibility',
    icon: 'assets/weather/sunny.svg',
  },
  {
    code: 'MostlyCloudy',
    description: 'Mostly cloudy',
    type: 'visibility',
    icon: 'assets/weather/cloudy.svg',
  },
  {
    code: 'PartlyCloudy',
    description: 'Partly cloudy',
    type: 'visibility',
    icon: 'assets/weather/partly_cloudy.svg',
  },
  {
    code: 'Smoky',
    description: 'Smoky',
    type: 'visibility',
    icon: 'assets/weather/fog.svg',
  },
  {
    code: 'Breezy',
    description: 'Breezy, light wind',
    type: 'wind',
    icon: 'assets/weather/fog.svg',
  },
  {
    code: 'Windy',
    description: 'Windy',
    type: 'wind',
    icon: 'assets/weather/fog.svg',
  },
  {
    code: 'Drizzle',
    description: 'Drizzle or light rain',
    type: 'precipitation',
    icon: 'assets/weather/heavy_rain.svg',
  },
  {
    code: 'HeavyRain',
    description: 'Heavy rain',
    type: 'precipitation',
    icon: 'assets/weather/heavy_rain.svg',
  },
  {
    code: 'IsolatedThunderstorms',
    description: 'Thunderstorms covering less than 1/8 of the forecast area',
    type: 'precipitation',
    icon: 'assets/weather/moderate_or_heavy_rain_with_thunder.svg',
  },
  {
    code: 'Rain',
    description: 'Rain',
    type: 'precipitation',
    icon: 'assets/weather/heavy_rain.svg',
  },
  {
    code: 'SunShowers',
    description: 'Rain with visible sun',
    type: 'precipitation',
    icon: 'assets/weather/patchy_light_rain.svg',
  },
  {
    code: 'ScatteredThunderstorms',
    description: 'Numerous thunderstorms spread across up to 50% of the forecast area',
    type: 'precipitation',
    icon: 'assets/weather/moderate_or_heavy_rain_with_thunder.svg',
  },
  {
    code: 'StrongStorms',
    description: 'Notably strong thunderstorms',
    type: 'precipitation',
    icon: 'assets/weather/moderate_or_heavy_rain_with_thunder.svg',
  },
  {
    code: 'Thunderstorms',
    description: 'Thunderstorms',
    type: 'precipitation',
    icon: 'assets/weather/moderate_or_heavy_rain_with_thunder.svg',
  },
  {
    code: 'Frigid',
    description: 'Frigid conditions, low temperatures, or ice crystals',
    type: 'hazardous',
    icon: 'assets/weather/patchy_freezing_drizzle_possible.svg',
  },
  {
    code: 'Hail',
    description: 'Hail',
    type: 'hazardous',
    icon: 'assets/weather/heavy_rain.svg',
  },
  {
    code: 'Hot',
    description: 'High temperatures',
    type: 'hazardous',
    icon: 'assets/weather/sunny.svg',
  },
  {
    code: 'Flurries',
    description: 'Flurries or light snow',
    type: 'winter-precipitation',
    icon: 'assets/weather/patchy_light_snow.svg',
  },
  {
    code: 'Sleet',
    description: 'Sleet',
    type: 'winter-precipitation',
    icon: 'assets/weather/patchy_sleet_possible.svg',
  },
  {
    code: 'Snow',
    description: 'Snow',
    type: 'winter-precipitation',
    icon: 'assets/weather/heavy_snow.svg',
  },
  {
    code: 'SunFlurries',
    description: 'Snow flurries with visible sun',
    type: 'winter-precipitation',
    icon: 'assets/weather/light_snow_showers.svg',
  },
  {
    code: 'WintryMix',
    description: 'Wintry mix',
    type: 'winter-precipitation',
    icon: 'assets/weather/heavy_snow.svg',
  },
  {
    code: 'Blizzard',
    description: 'Blizzard',
    type: 'hazardous-winter',
    icon: 'assets/weather/blizzard.svg',
  },
  {
    code: 'BlowingSnow',
    description: 'Blowing or drifting snow',
    type: 'hazardous-winter',
    icon: 'assets/weather/blowing_now.svg',
  },
  {
    code: 'FreezingDrizzle',
    description: 'Freezing drizzle or light rain',
    type: 'hazardous-winter',
    icon: 'assets/weather/freezing_drizzle.svg',
  },
  {
    code: 'FreezingRain',
    description: 'Freezing rain',
    type: 'hazardous-winter',
    icon: 'assets/weather/light_freezing_rain.svg',
  },
  {
    code: 'HeavySnow',
    description: 'Heavy snow',
    type: 'hazardous-winter',
    icon: 'assets/weather/heavy_snow.svg',
  },
  {
    code: 'Hurricane',
    description: 'Hurricane',
    type: 'tropical-hazard',
    icon: 'assets/weather/moderate_or_heavy_rain_with_thunder.svg',
  },
  {
    code: 'TropicalStorm',
    description: 'Tropical storm',
    type: 'tropical-hazard',
    icon: 'assets/weather/moderate_or_heavy_rain_with_thunder.svg',
  },
];

export const getIcon = (code: any): string => {
  if (code) {
    const i = conditionCodes.find((i) => i.code === code);
    if (i?.icon) {
      return i.icon;
    }
  }
  // return `../../public/assets/weather/${icon}.svg`;
  return '';
};
