import { useQuery } from '@apollo/client';
import DeclineNotifSvg from '../../assets/icons/DeclineNotif.svg';
import InvitedNotifSvg from '../../assets/icons/InvitedNotif.svg';
import ParticipeNotifSvg from '../../assets/icons/ParticipeNotif.svg';
import WaitingNotifSvg from '../../assets/icons/WaitingNotif.svg';
import AlertNotifSvg from '../../assets/icons/alertNotif.svg';
import { GET_EVENT_USER_PARTICIPATIONS } from '../../graphql/queries/eventUserParticipation.graphql';
import { Enum_Event_Type, EventEntity, EventInput, StringFilterInput } from '../../models/gql/graphql';
import { getUserId } from '../../utils';
import { convertLongTimeToShort, splitDateTime } from '../../utils/dateFormat';
import { getUnixTime } from 'date-fns';

export const getFullAddress = (event: EventEntity) => {
  /*
        Format event full address
    */
  let fullAdress = event?.attributes
    ? (event?.attributes.addressLine ? event?.attributes.addressLine : '') +
      ', ' +
      (event?.attributes.addressCountry ? event?.attributes.addressCountry : '') +
      ' ' +
      (event?.attributes.addressZipcode ? event?.attributes.addressZipcode : '')
    : '';

  if (
    event?.attributes &&
    !event?.attributes.addressLine &&
    !event?.attributes.addressCountry &&
    !event?.attributes.addressZipcode
  ) {
    fullAdress = 'Adresse non renseigné';
  }

  return fullAdress;
};

export const getPaymentInfo = (event: EventEntity) => {
  /*
        format event payment info by payment type
    */
  return event?.attributes?.paymentType
    ? 'Dispo ' +
        event?.attributes?.paymentType +
        (event?.attributes?.paymentType == 'Payante' ? ' (' + event?.attributes?.price + ' €)' : '')
    : 'Prix non renseigné';
};

export const getEventDateData = (event: EventEntity | EventInput) => {
  let eventData: EventInput = event && 'attributes' in event ? (event.attributes as EventInput) : (event as EventInput);
  let eventDebutDate = eventData?.eventDate ? splitDateTime(eventData?.eventDate) : null;
  let eventFinDate = eventData?.eventEndDate ? splitDateTime(eventData?.eventEndDate) : null;
  let timeStart = eventData?.startHour ? convertLongTimeToShort(eventData?.startHour) : null;
  let timeEnd = eventData?.endHour ? convertLongTimeToShort(eventData?.endHour) : null;
  // force use transformEventDate
  if (eventData?.beginningDateTime && eventData?.expirationDateTime) {
    return transformEventDate(
      getUnixTime(new Date(eventData?.beginningDateTime)),
      getUnixTime(new Date(eventData?.expirationDateTime))
    );
  }
  if (eventDebutDate?.date && eventFinDate?.date && eventDebutDate.date !== eventFinDate.date) {
    return {
      debut: eventDebutDate.date + (timeStart ? ' à ' + timeStart : ''),
      fin: "jusqu'au " + eventFinDate.date + (timeEnd ? ' à ' + timeEnd : ''),
    };
  }
  return {
    debut: eventDebutDate ? eventDebutDate.date : 'Date non défini',
    fin: timeStart && timeEnd ? timeStart + ' à ' + timeEnd : 'Heure non défini',
  };
};

export const transformEventDate = (start?: number | null, end?: number | null) => {
  const dateStart = start ? new Date(start * 1000) : null;
  const dateEnd = end ? new Date(end * 1000) : null;
  const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };

  if (!dateEnd && dateStart) {
    const formattedDate = dateStart.toLocaleDateString('fr-FR', options as Intl.DateTimeFormatOptions);
    const formattedTime = `${dateStart.getHours()}:${String(dateStart.getMinutes()).padStart(2, '0')}`;
    return {
      debut: `${formattedDate} à ${formattedTime}`,
      fin: ``,
    };
  }

  if (dateStart && dateEnd) {
    const formattedStartDate = dateStart.toLocaleDateString('fr-FR', options as Intl.DateTimeFormatOptions);
    const formattedStartTime = `${dateStart.getHours()}:${String(dateStart.getMinutes()).padStart(2, '0')}`;
    const formattedEndDate = dateEnd.toLocaleDateString('fr-FR', options as Intl.DateTimeFormatOptions);
    const formattedEndTime = `${dateEnd.getHours()}:${String(dateEnd.getMinutes()).padStart(2, '0')}`;

    if (dateStart.toDateString() === dateEnd.toDateString()) {
      return {
        debut: `${formattedStartDate}`,
        fin: `${formattedStartTime} à ${formattedEndTime}`,
      };
    }
    return {
      debut: `${formattedStartDate} à ${formattedStartTime}`,
      fin: `jusqu'au ${formattedEndDate} à ${formattedEndTime}`,
    };
  }

  return {
    debut: 'Date non défini',
    fin: 'Heure non défini',
  };
};

export const verifyIfDispoIsMine = (event: EventEntity) => {
  let userId = getUserId();
  let isMyDispo = false;
  let creator = event?.attributes?.creator?.data;

  //Verify if dispo is created by connected user
  if (creator && creator.id && userId == creator.id) {
    isMyDispo = true;
  }
  return isMyDispo;
};

export const GetDispoMember = (idDispo: string, filters: StringFilterInput) => {
  const { data: eventUserParticipant, loading: loadingParticipation } = useQuery(GET_EVENT_USER_PARTICIPATIONS, {
    variables: {
      filters: {
        event: {
          id: {
            eq: idDispo,
          },
        },
        status: filters,
      },
    },
  });

  let members =
    eventUserParticipant && !loadingParticipation && eventUserParticipant?.eventUserParticipations?.data
      ? eventUserParticipant?.eventUserParticipations?.data
      : [];
  return members;
};

export const verifyIfEventDateHasPassed = (event: EventEntity) => {
  if (event?.attributes?.expired) {
    return true;
  } else if (event.attributes?.expirationDateTime) {
    return new Date().getTime() > new Date(event.attributes.expirationDateTime).getTime();
  }
  return false;
};

export const getNotificationData = (status: string): INotificationDetail => {
  const notifData = {
    refused: {
      bgColor: '#D9D9D9',
      textColor: '#313333',
      text: "Vous avez refusé l'invitation à cette dispo",
      icon: DeclineNotifSvg,
    },
    accepted: {
      bgColor: '#04B171',
      textColor: 'white',
      text: 'Vous participez à cette dispo',
      icon: ParticipeNotifSvg,
    },
    canceled: {
      bgColor: '#D9D9D9',
      textColor: '313333',
      text: 'vous avez annulé ta participation à cette dispo',
      icon: DeclineNotifSvg,
    },
    waiting: {
      bgColor: '#04B171',
      textColor: 'white',
      text: "vous êtes inscrit(e) sur la liste d'attente",
      icon: WaitingNotifSvg,
    },
    invited: {
      bgColor: '#04B171',
      textColor: 'white',
      text: 'vous êtes invité(e) à cette dispo',
      icon: InvitedNotifSvg,
    },
    full: {
      bgColor: '#FB7F26',
      textColor: 'white',
      text: 'plus aucune place disponible pour cette dispo',
      icon: AlertNotifSvg,
    },
  };
  return (
    (notifData as any)[status] || {
      bgColor: '#D9D9D9',
      textColor: '#313333',
      text: '',
    }
  );
};

export const getEventBg = (dispoData: IDispoDetail) => {
  return dispoData.eventType
    ? dispoData.eventType === Enum_Event_Type.Private
      ? 'rgba(61, 39, 235, 1)'
      : 'rgba(4, 177, 113, 1)'
    : '';
};
