import { Enum_Report_Category } from '../models/gql/graphql';

export const REPORTING_LIST: ILabeling[] = [
  {
    label: 'Spam',
    value: Enum_Report_Category.Spam,
  },
  {
    label: 'Fausses informations',
    value: Enum_Report_Category.FaussesInformations,
  },
  {
    label: 'Arnaques ou fraude',
    value: Enum_Report_Category.ArnaqueOuFraude,
  },
  {
    label: 'Vente de produits illégaux ou réglementés',
    value: Enum_Report_Category.VenteDeProduitsIllegauxOuReglementes,
  },
  {
    label: ' Nudité ou actes sexuels',
    value: Enum_Report_Category.NuditeOuActesSexuels,
  },
  {
    label: 'Discours ou symbole haineux',
    value: Enum_Report_Category.DiscoursOuSymbolesHaineux,
  },
  {
    label: 'Violence ou organisations dangereuses',
    value: Enum_Report_Category.ViolenceOuOrganisationsDangereuses,
  },
  {
    label: 'Autre',
    value: Enum_Report_Category.Autre,
  },
];
