import { IonCol, IonRow } from '@ionic/react';
import { calendarOutline } from 'ionicons/icons';
import { FC } from 'react';
import { FieldErrors } from 'react-hook-form';
import AppDatepicker from '../AppDatepicker';

interface IProps {
  onChange1: (value: any) => void;
  onChange2: (value: any) => void;
  errors: FieldErrors<TypeBetweenDate>;
  label1?: string;
  label2?: string;
  icon: any;
}
const DoubleDatePicker: FC<IProps> = ({ onChange1, onChange2, errors, label1, label2, icon }) => {
  return (
    <IonRow>
      <IonCol>
        <AppDatepicker
          displayFormat="dd/MM/yyyy"
          label={label1 || ''}
          presentation="date"
          name="startDate"
          onChange={(value) => {
            onChange1(value);
          }}
          type="first"
        />
        {errors.startDate && <p className="errorDescription">{errors.startDate.message}</p>}
      </IonCol>
      <IonCol>
        <AppDatepicker
          displayFormat="dd/MM/yyyy"
          label={label2 || ''}
          presentation="date"
          name="endDate"
          onChange={(value) => {
            onChange2(value);
          }}
          icon={{ icon: icon }}
          type="second"
        />
        {errors.endDate && <p className="errorDescription">{errors.endDate.message}</p>}
      </IonCol>
    </IonRow>
  );
};

export default DoubleDatePicker;
