import { ApolloClient, InMemoryCache, split, from } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { createClient } from 'graphql-ws';
import { createUploadLink } from 'apollo-upload-client';
import { StorageUtils } from '../utils';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT as string,
});

const authLink = setContext((_, { headers }) => {
  const token = StorageUtils.get('authToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const wsLink =
  typeof window !== 'undefined'
    ? new GraphQLWsLink(
        createClient({
          url: process.env.REACT_APP_PUBLIC_SUBSCRIPTION_URL as string,
        })
      )
    : null;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) {
    console.log(networkError.message, networkError.name);
    console.log(networkError.stack);
    if (networkError.message.endsWith('401')) {
      // redirect to login
      StorageUtils.clear();
      window.location.replace('/guest-home?redirected=401');
    }
    console.log(`[Network error]: ${networkError}`);
  }
});

const splitLink =
  typeof window !== 'undefined' && wsLink != null
    ? split(
        ({ query }) => {
          const def = getMainDefinition(query);
          return def.kind === 'OperationDefinition' && def.operation === 'subscription';
        },
        wsLink,
        authLink.concat(httpLink)
      )
    : httpLink;

export const clientGraphQL = new ApolloClient({
  link: from([errorLink, splitLink]),
  cache: new InMemoryCache(),
});
