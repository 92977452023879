import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSpinner,
  IonTitle,
} from '@ionic/react';
import { ComponentModal } from '../../Modals';
import { FC, useState } from 'react';

import arrowRightIcon from '../../../assets/icons/arrowRight.svg';
import './style.css';

interface IProps {
  isOpen: boolean;
  loading: boolean;
  onCancel: () => void;
  handleClick: (duration: string) => any;
}

const MuteNotificationModal: FC<IProps> = ({ isOpen, onCancel, handleClick, loading }) => {
  const [duration, setDuration] = useState<any>(null);
  const handleMuteClick = (duration: string) => {
    setDuration(duration);
    handleClick(duration).then(() => {
      onCancel();
    });
  };

  return (
    <ComponentModal isOpen={isOpen} onCancel={onCancel}>
      <IonHeader className="ion-no-border padding-t-25 chat__mute_modal__header" mode="ios">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonTitle className="text-center">
                <h5 className="chat__cmp-modal__title">Rendre cette conversation muette</h5>
              </IonTitle>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonContent className="chat__mute-modal_item-container">
        <IonItem onClick={() => handleMuteClick('day')}>
          <IonLabel>Une journée</IonLabel>
          {loading && duration === 'day' ? (
            <IonSpinner className="chat__mute-modal__loading-spinner" name="crescent" color="dark" />
          ) : (
            <IonButton fill="clear">
              <IonIcon icon={arrowRightIcon} slot="end"></IonIcon>
            </IonButton>
          )}
        </IonItem>
        <IonItem onClick={() => handleMuteClick('week')}>
          <IonLabel>Une semaine</IonLabel>
          {loading && duration === 'week' ? (
            <IonSpinner className="chat__mute-modal__loading-spinner" name="crescent" color="dark" />
          ) : (
            <IonButton fill="clear">
              <IonIcon icon={arrowRightIcon} slot="end"></IonIcon>
            </IonButton>
          )}
        </IonItem>
        <IonItem onClick={() => handleMuteClick('forever')} lines="none">
          <IonLabel>Toujours</IonLabel>
          {loading && duration === 'forever' ? (
            <IonSpinner className="chat__mute-modal__loading-spinner" name="crescent" color="dark" />
          ) : (
            <IonButton fill="clear">
              <IonIcon icon={arrowRightIcon} slot="end"></IonIcon>
            </IonButton>
          )}
        </IonItem>
        <IonRow>
          <IonCol>
            <IonButton className="cancel text-black" fill="clear" expand="block" onClick={onCancel}>
              Annuler
            </IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
    </ComponentModal>
  );
};

export default MuteNotificationModal;
