import { useMutation } from '@apollo/client';
import { Browser } from '@capacitor/browser';
import { useState } from 'react';
import { GENERATE_STRIPE_LINKS } from '../graphql/queries/stripe.graphql';

export const useStripeOnBoarding = () => {
  const [generateStripeLink, { loading, error }] = useMutation(GENERATE_STRIPE_LINKS);
  const [navigationDone, setNavigationDone] = useState(false);

  const openBrowser = async () => {
    const { data } = await generateStripeLink();
    setNavigationDone(false);
    await Browser.open({ url: data?.CreateStripeAccountLinks?.data?.url });
    await Browser.addListener('browserFinished', () => {
      setNavigationDone(true);
      Browser.removeAllListeners();
    });
  };

  const closeBrowser = async () => {
    await Browser.close();
  };

  return {
    openBrowser,
    loading,
    navigationDone,
    closeBrowser,
  };
};
