import { graphql } from '../../models/gql';

export const UPDATE_EVENT = graphql(/* GraphQL */ `
  mutation UpdateEvent($id: ID!, $data: EventInput!) {
    updateEvent(id: $id, data: $data) {
      data {
        id
        attributes {
          name
          type
          eventDate
          eventEndDate
          addressLine
          description
          startHour
          endHour
          expired
        }
      }
    }
  }
`);

export const UPDATE_EVENT_USER = graphql(/* GraphQL */ `
  mutation UpdateEventUser($id: ID!, $data: EventUserParticipationInput!) {
    updateEventUserParticipation(id: $id, data: $data) {
      data {
        id
        attributes {
          status
          event {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`);
