/* Use this component as reference and guide line when creating components or pages */
import React, { useEffect, useRef, useState } from 'react';
import { IonContent, IonLabel } from '@ionic/react';
import styled from 'styled-components';
import { Configure, useInstantSearch } from 'react-instantsearch';
import { CustomRefresh } from '../../../components/Algolia';
import { getUserId, isAuthenticated } from '../../../utils';
import { buildMainFilter } from '../SearchResult/utils';
import { useAppStore } from '../../../store';
import NoResultsBoundary from '../../../components/Algolia/NoResultBoundary';
import SearchInfinitePostHits from '../../../components/Algolia/SearchInfinitePostHits/SearchInfinitePostHits';

const HITS_PER_PAGE = 20;

/**
 * SearchResult page component
 * @param {string} className            (Optional) Override className internal configuration
 * @returns                             SearchResult react component
 */
const SearchPostResult: React.FC<any> = ({
  className,
  filterData: initialFilterData,
  query,
  noResultComponent,
  isProfil,
  locked,
}) => {
  const {
    RootStore: { algoliaCache, getUserData },
  } = useAppStore();
  const { status, refresh } = useInstantSearch();
  const [mainFilters, setMainFilters] = useState<any>(() => {
    const filterData: any = {};
    return buildMainFilter(filterData);
  });

  useEffect(() => {
    async function func() {
      const ud = await getUserData();
      const data: any = {};
      if (isAuthenticated() && ud) {
        const blocked = [
          ...(ud.blockedUsers?.map((i: any) => i?.id) || []),
          ...(ud.blockedByUsers?.map((i: any) => i?.id) || []),
        ];
        if (blocked.length) {
          data.blockedCreator = blocked;
        }
      }

      if (initialFilterData.creator && isProfil) {
        data.creator = initialFilterData.creator;
      }

      if (initialFilterData && initialFilterData.followups) {
        data.followups = [...initialFilterData.followups, ud.id];
      }
      if (query) {
        setMainFilters(`(${buildMainFilter(data)}) OR feedType:PUBLIC`);
      } else {
        setMainFilters(buildMainFilter(data));
      }
      await algoliaCache.invalidate();
      await refresh();
    }
    algoliaCache.invalidate();
    func();
  }, []);

  return (
    <SearchPostResultWrapper className={`${className || ''}`}>
      <CustomRefresh />
      <Configure filters={mainFilters} hitsPerPage={HITS_PER_PAGE} query={query} analytics getRankingInfo distinct />

      <NoResultsBoundary
        fallback={
          noResultComponent ? noResultComponent : <IonLabel>Aucune publication ne correspond à ta recherche.</IonLabel>
        }
      >
        <SearchInfinitePostHits cache={algoliaCache} isProfile={isProfil} locked={locked} />
      </NoResultsBoundary>
    </SearchPostResultWrapper>
  );
};

export const SearchPostResultWrapper = styled(IonContent)``;

export default SearchPostResult;
