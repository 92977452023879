import { IonContent, IonFooter, IonPage } from '@ionic/react';
import { Header, SegmentCustom } from '../../../components';
import { useHistory, useLocation } from 'react-router';
import MyDispos from './MyDispos';
import ListDispoAccepted from './ListDispoAccepted';
import { useEffect, useState } from 'react';
import { getUserId } from '../../../utils';
import ButtonCreate from '../../../components/ButtonCreate';

enum Enum_segment {
  list_accepted = 'list_dispo_accepted',
  my_dispo = 'my_dispo',
}
const Dispos: React.FC = () => {
  const id = getUserId() || '';
  const location = useLocation();
  const [segmentValue, setSegmentValue] = useState<Enum_segment>(Enum_segment.list_accepted);
  const segmentData: IdNameEntity[] = [
    {
      id: Enum_segment.list_accepted,
      name: 'Je participe',
    },
    {
      id: Enum_segment.my_dispo,
      name: "J'organise",
    },
  ];

  const history = useHistory();

  useEffect(() => {
    if (location.search) {
      const fromDelete = new URLSearchParams(location.search).get('fromDelete');
      if (fromDelete) {
        setSegmentValue(Enum_segment.my_dispo);
      }
    }
  }, [location]);

  return (
    <IonPage>
      <Header noButtonBack={true} title="Mes Dispos"></Header>
      <IonContent>
        <SegmentCustom
          typeSegment="out"
          values={segmentData}
          defaultValue={segmentValue}
          onChange={(value) => setSegmentValue(value.id as Enum_segment)}
        ></SegmentCustom>
        <div className="ion-padding">
          {segmentValue === Enum_segment.my_dispo && <MyDispos id={id}></MyDispos>}
          {segmentValue === Enum_segment.list_accepted && <ListDispoAccepted id={id}></ListDispoAccepted>}
        </div>
      </IonContent>
      <IonFooter className="ion-no-border ion-padding">
        <ButtonCreate action={() => history.push('/main/home/create-dispo/step-info')} text="Créer une dispo" />
      </IonFooter>
    </IonPage>
  );
};

export default Dispos;
