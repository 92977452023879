import { IonLabel, IonChip, IonIcon, IonAvatar } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import { InterestEntity } from '../../models/gql/graphql';
import './style.css';
import { useEffect, useMemo, useState } from 'react';
import xCircleSvg from '../../assets/icons/x-circle.svg';

interface IInterestProps {
  interest?: InterestEntity;
  handleChange: (id: never) => void;
  isSelected?: boolean;
  isOther?: boolean;
  otherColor?: string;
  canCancel?: boolean;
  disabled?: boolean;
}

const InterestChip: React.FC<IInterestProps> = ({
  interest,
  isSelected,
  handleChange,
  isOther = true,
  otherColor,
  canCancel = false,
  disabled = false,
}) => {
  const classColor = useMemo(() => {
    return !otherColor ? (isOther ? '-green' : '') : '-' + otherColor;
  }, [isOther]);
  return (
    <IonChip
      className={`${isSelected ? 'full-interest' + classColor : 'outline-interest' + classColor}`}
      onClick={(e) => handleChange(interest?.id as never)}
    >
      <IonAvatar>
        <img
          alt="picto"
          src={
            interest?.attributes?.picto?.data
              ? interest?.attributes?.picto?.data.attributes?.url
              : 'https://ionicframework.com/docs/img/demos/avatar.svg'
          }
          className={isSelected ? 'icon-selected' : 'icon-no-selected' + classColor}
        />
      </IonAvatar>
      <IonLabel className="font-interest font-outfit">{interest?.attributes?.name}</IonLabel>
      {!!canCancel && <IonIcon src={xCircleSvg} />}
    </IonChip>
  );
};

export default InterestChip;
