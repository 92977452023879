import { IonFooter, IonRow, IonText } from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import { EventEntity } from '../../../models/gql/graphql';
import { verifyIfDispoIsMine, verifyIfEventDateHasPassed } from '../utils';
import { CustomButton } from './Buttons/CustomButton';
import { ParticipeButton } from './ParticipeButton';

export const CustomDispoFooter: React.FC<IDispoData> = ({ dispoData, refresh }) => {
  const { event, userEventStatus: status, paymentType, eventParticipations, maxInvited } = dispoData;
  const dateHasPassed = verifyIfEventDateHasPassed(event as EventEntity);
  let isMyDispo: boolean = verifyIfDispoIsMine(event as EventEntity);
  const footerClass = getFooterClass(dispoData, isMyDispo);
  return (
    <IonFooter className={`ion-no-border `}>
      <div className={footerClass}>
        {event?.attributes?.deleted && (
          <>
            <div
              className=" border-radius-bottom full-width"
              style={{
                position: 'relative',
                bottom: '16px',
                boxShadow: 'rgb(255, 153, 207) 0px 10px 0px',
                padding: 10,
                zIndex: 0,
              }}
            ></div>
            <IonRow className="bg-pink text-uppercase ion-justify-content-center ion-align-items-center padding-b-10 text-bolder no-margin-t-8 text-white">
              <IonText>Dispo annulée</IonText>
            </IonRow>
          </>
        )}
        {!isMyDispo && status !== 'refused' && !event?.attributes?.deleted && (
          <ParticipeButton
            dispoData={dispoData}
            refresh={refresh}
            colorShadow={footerClass === 'bg-pink' ? '#FF99CF' : null}
          />
        )}
        {!!isMyDispo && !dateHasPassed && <EditButton />}
      </div>
    </IonFooter>
  );
};

const EditButton: React.FC = () => {
  const history = useHistory();
  const { id: idDispo } = useParams<IVerifParams>();

  const goToEditList = () => {
    history.push(`/main/dispos/${idDispo}/edit`);
  };
  return (
    <IonRow className="ion-justify-content-center height-90">
      <CustomButton
        action={goToEditList}
        class="ion-no-border text-white min-width-p-80 min-height-p-100 ion-border-radius-10 button-bg-green no-uppercase margin-b-10"
        text="Editer la dispo"
      />
    </IonRow>
  );
};

const getFooterClass = (dispoData: IDispoDetail, isMyDispo: boolean) => {
  const { paymentType, userEventStatus, eventParticipations, maxInvited, event } = dispoData;
  if (event?.attributes?.deleted) return 'bg-pink';
  if (!!isMyDispo) {
    return 'bg-white';
  }

  if (
    paymentType === 'Gratuite' ||
    paymentType === 'Contributive' ||
    userEventStatus === 'invited' ||
    userEventStatus === 'accepted' ||
    userEventStatus === 'waiting' ||
    (userEventStatus !== 'waiting' && maxInvited && maxInvited <= eventParticipations.length) ||
    (userEventStatus !== 'waiting' &&
      userEventStatus !== 'invited' &&
      ((maxInvited && maxInvited <= eventParticipations.length) || !maxInvited) &&
      userEventStatus !== 'accepted')
  ) {
    return 'bg-yellow';
  }

  return 'bg-pink';
};
