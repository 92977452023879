import { useQuery } from '@apollo/client';
import React, { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router';
import { GET_EVENT } from '../graphql/queries/event.graphql';
import { GET_EVENT_USER_PARTICIPATIONS } from '../graphql/queries/eventUserParticipation.graphql';
import { Enum_Eventuserparticipation_Status, EventEntity, EventUserParticipationEntity } from '../models/gql/graphql';
import { verifyIfDispoIsMine } from '../pages/DispoDetail/utils';
import { getUserId } from '../utils';

const GetEventParticipants = (idDispo: string, userId?: string | null) => {
  let filters: IFilter = {
    event: {
      id: {
        eq: idDispo,
      },
    },
  };
  if (userId) {
    filters.user = {
      id: {
        eq: userId,
      },
    };
  } else {
    filters.status = {
      eq: Enum_Eventuserparticipation_Status.Accepted,
    };
  }
  const {
    data,
    loading,
    refetch: refetchParticipation,
  } = useQuery(GET_EVENT_USER_PARTICIPATIONS, {
    variables: {
      filters: filters,
      pagination: { limit: 1000 },
      sort: ['createdAt:desc'],
    },
  });

  const participations = useMemo<EventUserParticipationEntity[]>(() => {
    return (!loading ? data?.eventUserParticipations?.data : []) as EventUserParticipationEntity[];
  }, [data, loading]);

  if (userId) {
    // console.log({ participations });
  }

  return {
    participations,
    refetchParticipation,
    loading,
  };
};

export const useDispoDetail = () => {
  const { id } = useParams<IVerifParams>();
  let userId = getUserId();

  const {
    data: eventData,
    loading: loadingEvent,
    refetch,
  } = useQuery(GET_EVENT, {
    variables: { id: id || '' },
    skip: !id,
  });
  const event = useMemo<EventEntity>(() => {
    return (!loadingEvent ? eventData?.event?.data : []) as EventEntity;
  }, [loadingEvent, eventData]);

  const {
    participations: eventUserParticipations,
    refetchParticipation,
    loading: loadingParticipation,
  } = GetEventParticipants(id, userId);

  const userEventStatus =
    eventUserParticipations.length && eventUserParticipations[0].attributes
      ? eventUserParticipations[0].attributes.status
      : 'aucun';

  const { participations: eventParticipations, refetchParticipation: refetchParticipations } = GetEventParticipants(id);

  const eventType = event && event?.attributes ? event.attributes.type : '';

  let image =
    event && event?.attributes?.coverCustomImage?.data && event?.attributes?.coverCustomImage?.data
      ? event.attributes.coverCustomImage.data.attributes?.url
      : event && event.attributes?.coverTedispoImage && event.attributes?.coverTedispoImage?.data
      ? event.attributes.coverTedispoImage.data.attributes?.image?.data?.attributes?.url
      : 'https://ionicframework.com/docs/img/demos/avatar.svg';

  let eventName = event && event?.attributes ? event?.attributes.name : '';

  let creator =
    event && event.attributes && event?.attributes?.creator && event?.attributes.creator.data
      ? event?.attributes.creator.data
      : null;

  const creatorName =
    (creator?.attributes?.isPro ? creator?.attributes?.companyName : creator?.attributes?.username) || '';

  const maxInvited = event && event.attributes && event.attributes.maxInvited ? event.attributes.maxInvited : 0;

  const stringToHtml = (params: string): ReactNode => {
    return React.createElement('div', { dangerouslySetInnerHTML: { __html: params } });
  };

  let description = stringToHtml(
    event?.attributes?.description ? event?.attributes?.description : 'Pas de description'
  );

  const paymentType = event?.attributes ? event.attributes.paymentType : '';

  const allUrlImage = [];

  if (event && event?.attributes?.coverCustomImage?.data && event?.attributes?.coverCustomImage?.data) {
    allUrlImage.push(event?.attributes?.coverCustomImage?.data.attributes);
  }
  if (event && event.attributes?.coverTedispoImage && event.attributes?.coverTedispoImage?.data) {
    allUrlImage.push(event.attributes.coverTedispoImage.data.attributes?.image?.data?.attributes);
    // console.log({ allUrlImage });
  }
  if (
    event &&
    event.attributes?.secondaryImages &&
    event.attributes?.secondaryImages &&
    event.attributes?.secondaryImages.data.length
  ) {
    event.attributes?.secondaryImages.data.map((imgData) => {
      allUrlImage.push(imgData.attributes);
    });
  }

  const isMyDispo = verifyIfDispoIsMine(event);
  return {
    id,
    event,
    eventUserParticipations,
    eventParticipations,
    eventData,
    userEventStatus,
    eventType,
    image,
    eventName,
    creator,
    creatorName: creatorName.charAt(0).toUpperCase() + creatorName.slice(1),
    maxInvited,
    description,
    paymentType,
    allUrlImage,
    isMyDispo,
    loadingEvent,
    refresh: refetch,
    refetchParticipation: refetchParticipation,
    refetchParticipations: refetchParticipations,
    loadingParticipation,
  } as IDispoDetail;
};

export const GetAllParticipations = (idDispo: string, statusFilter: string[] = []) => {
  let filters: IFilter = {
    event: {
      id: {
        eq: idDispo,
      },
    },
  };
  if (statusFilter.length) {
    filters.status = {
      notIn: statusFilter,
    };
  }

  const {
    data,
    loading,
    refetch: refetchParticipation,
  } = useQuery(GET_EVENT_USER_PARTICIPATIONS, {
    variables: {
      filters: filters,
      pagination: { limit: 1000 },
      sort: ['createdAt:desc'],
    },
    skip: !idDispo,
    fetchPolicy: 'no-cache',
    nextFetchPolicy(currentFetchPolicy, context) {
      return 'no-cache';
    },
  });

  const participations = useMemo<EventUserParticipationEntity[]>(() => {
    return (!loading ? data?.eventUserParticipations?.data : []) as EventUserParticipationEntity[];
  }, [data, loading]);

  return { participations, loading, refetchParticipation };
};
