import { IonButton, IonCol, IonGrid, IonIcon, IonModal, IonRow, IonText } from '@ionic/react';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import './style.css';
import { CustomButton } from '../../../pages/DispoDetail/components/Buttons/CustomButton';
import respondIcon from '../../../assets/icons/respond.svg';
import copyIcon from '../../../assets/icons/copy.svg';
import modifyIcon from '../../../assets/icons/modify.svg';
import trashIcon from '../../../assets/icons/trash.svg';

export interface IMessageActionModalProps {
  isOpen: boolean;
  isMine: boolean;
  isFile: boolean;
  title?: string;
  text?: string;
  okText?: string;
  cancelText?: string;
  onCancel: () => void;
  onDelete: () => void;
  onEdit: () => void;
  onRespond: () => void;
  onCopy: () => void;
  height?: string;
  loading?: boolean;
}

const MessageActionModal: FC<IMessageActionModalProps> = ({
  isOpen,
  isMine,
  isFile,
  title,
  text,
  okText,
  cancelText,
  onCancel,
  onDelete,
  onCopy,
  onEdit,
  onRespond,
  height,
  loading,
}) => {
  const [showModal, setShowModal] = useState(isOpen);

  const handleCancel = () => {
    setShowModal(false);
    onCancel();
  };

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen, setShowModal]);

  return (
    <IonModal
      className="confirm_modal"
      mode="ios"
      isOpen={showModal}
      style={{
        '--height': height || '47%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        margin: 0,
        padding: 0,
        paddingBottom: 25,
        overflowY: 'hidden',
      }}
      onDidDismiss={() => handleCancel()}
      initialBreakpoint={1}
      breakpoints={[0, 0.33, 0.66, 1]}
      showBackdrop={false}
    >
      <ModalContentWrapper>
        <IonRow>
          <IonCol>
            <div className="action-button-flex-container">
              <IonButton onClick={onRespond} fill="clear">
                <IonIcon size="large" icon={respondIcon} />
              </IonButton>
              <span className="action-button-label">Répondre</span>
            </div>
          </IonCol>
          <IonCol>
            <div className="action-button-flex-container">
              <IonButton disabled={isFile} onClick={onCopy} fill="clear">
                <IonIcon size="large" icon={copyIcon} />
              </IonButton>
              <span className="action-button-label">Copier</span>
            </div>
          </IonCol>
          {isMine && (
            <IonCol>
              <div className="action-button-flex-container">
                <IonButton disabled={isFile} onClick={onEdit} fill="clear">
                  <IonIcon size="large" icon={modifyIcon} />
                </IonButton>
                <span className="action-button-label">Modifier</span>
              </div>
            </IonCol>
          )}
          {isMine && (
            <IonCol>
              <div className="action-button-flex-container">
                <IonButton onClick={onDelete} fill="clear">
                  <IonIcon size="large" icon={trashIcon} />
                </IonButton>
                <span className="action-button-label">Supprimer</span>
              </div>
            </IonCol>
          )}
        </IonRow>
      </ModalContentWrapper>
    </IonModal>
  );
};

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export default MessageActionModal;
