import { ReactNode, useContext } from 'react';
import { AppReducerContext } from '../store/';

export const useToast = () => {
  const { dispatch } = useContext(AppReducerContext);

  const addToast = (type: string, message: string, duration = 5000, customIcon?: ReactNode) => {
    const id = Math.floor(Math.random() * 10000000);
    dispatch({ type: 'ADD_TOAST', payload: { id, message, type, duration, customIcon } });
  };

  const success = (message: string, duration?: number, customIcon?: ReactNode) => {
    addToast('success', message, duration, customIcon);
  };

  const warning = (message: string, duration?: number, customIcon?: ReactNode) => {
    addToast('warning', message, duration, customIcon);
  };

  const info = (message: string, duration?: number, customIcon?: ReactNode) => {
    addToast('info', message, duration, customIcon);
  };

  const error = (message: string, duration?: number, customIcon?: ReactNode) => {
    addToast('error', message, duration, customIcon);
  };

  const remove = (id: string) => {
    dispatch({ type: 'DELETE_TOAST', payload: id });
  };

  return {
    success,
    warning,
    info,
    error,
    remove,
  };
};
