import { IonButton, IonRange } from '@ionic/react';
import { locationOutline } from 'ionicons/icons';
import { forEach } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AppSearchAddressInput } from '../../../components';
import FilterHeader from '../../../components/FilterHeader';
import './style.css';

const FilterAdress: React.FC<IFilterProps> = ({ onChange, onCloseModal }) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddress>();
  const [distance, setDistance] = useState<number>(1);
  register('addressLat', { required: true });
  register('addressLng', { required: true });
  register('addressCity', { required: true });
  register('addressCountry', { required: true });
  register('addressLine', { required: true });

  const handleSelectAddress = (val: IAddress) => {
    forEach(val, (value, key: any) => {
      setValue(key, value);
    });
  };
  const saveFilter = (data: IAddress) => {
    const positionSelected: TypePosition = {
      latitude: data.addressLat as number,
      longitude: data.addressLng as number,
    };
    const positionAround: TypeAddressAround = {
      position: positionSelected,
      addressCity: data.addressCity as string,
      distance,
    };
    onChange(positionAround);
    onCloseModal(false);
  };

  return (
    <>
      <FilterHeader title="Filtrer par distance" close={() => onCloseModal(false)} />
      <div className="margin-20 font-outfit">
        <form onSubmit={handleSubmit(saveFilter)}>
          <AppSearchAddressInput
            autocompletionRequest={{ types: [], componentRestrictions: { country: ['fr'] } }}
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            label="Ville"
            name="address"
            onSelect={(selectedAddress: IAddress) => handleSelectAddress(selectedAddress)}
            icon={{ icon: locationOutline }}
            placeholder="Lyon"
          />
          {errors.addressLine && <p className="errorDescription">L'adresse est requise</p>}
          Afficher des dispo dans un rayon de...
          <div style={{ position: 'relative' }}>
            <IonRange
              pin={true}
              onIonChange={(e) => {
                setDistance(e.detail.value as number);
              }}
            ></IonRange>
          </div>
          <IonButton
            expand="block"
            fill="solid"
            color="primary"
            className="d-absolute-bottom ion-padding-horizontal"
            type="submit"
          >
            Sauvegarder
          </IonButton>
          <IonButton
            expand="block"
            fill="clear"
            className="d-absolute-bottom ion-padding-horizontal text-underligne text-black"
            onClick={() => onCloseModal(false)}
          >
            Annuler
          </IonButton>
        </form>
      </div>
    </>
  );
};

export default FilterAdress;
