import React, { forwardRef, useEffect, useRef } from 'react';
import './style.css';

const FakeAudioWave = forwardRef((props: { isCancelling: boolean; parentContainer: any }, ref) => {
  const containerRef: any = useRef(null);
  const cancel = props.isCancelling;
  const parentContainer = props.parentContainer;

  useEffect(() => {
    const containerHeight = containerRef.current.clientHeight;
    const containerWidth = containerRef.current.clientWidth;
    const numberOfBars = Math.floor(containerWidth / 4);

    const bars = document.querySelectorAll('.bar');

    bars.forEach((bar: any, index) => {
      const maxHeight = containerHeight * 0.5;
      const newHeight = Math.random() * maxHeight + containerHeight * 0.2;
      bar.style.height = `${newHeight}px`;
      bar.style.animationDuration = `${Math.random() * (0.7 - 0.2) + 0.2}s`;
      if (index < numberOfBars) {
        bar.style.display = 'block';
      } else {
        bar.style.display = 'none';
      }
    });
  }, []);

  const barNumber = parentContainer?.current?.clientWidth ? Math.floor(parentContainer?.current?.clientWidth / 5) : 50;

  return (
    <div className="sound-wave" ref={containerRef}>
      {[...Array(barNumber - 10)].map((_, index) => (
        <div key={index} className={`bar ${cancel ? 'chat__audio-red-graph' : ''}`}></div>
      ))}
    </div>
  );
});

export default FakeAudioWave;
