import { IonContent, IonImg, IonPage, IonRow, IonText } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import mailSvg from '../../../../assets/icons/Mail.svg';
import { CardCustom, Header } from '../../../../components';
import { StorageUtils } from '../../../../utils';
import '../style.css';

const ConfirmationPro: React.FC = () => {
  const history = useHistory();
  return (
    <IonPage>
      <Header
        title="Inscription"
        noButtonBack={false}
        action={async () => {
          await StorageUtils.clear();
          history.replace('/on-boarding');
        }}
      />
      <IonContent className="ion-padding">
        <CardCustom>
          <IonRow className="ion-align-items-center ion-justify-content-center">
            <IonImg src={mailSvg} className="width-100" />
          </IonRow>
          <IonText color="dark">
            <p className="ion-text-center margin-auto font-outfit font-description">
              Ta demande d’inscription a été envoyé aux administrateurs de Tedispo.
            </p>
            <p className="ion-text-center margin-auto font-outfit font-description">Nous revenons vers toi sous 24h.</p>
          </IonText>
        </CardCustom>
      </IonContent>
    </IonPage>
  );
};

export default ConfirmationPro;
