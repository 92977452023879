import { IonButton, IonContent, IonFooter, IonGrid, IonImg, IonPage, IonRow, useIonToast } from '@ionic/react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';

const GuestHome: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [present] = useIonToast();

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.get('redirected') === '401') {
      present({
        message: "Vous êtes déconnecté de l'application",
        duration: 1500,
        position: 'top',
      });
      history.replace('/on-boarding');
    }
  }, [search]);
  return (
    <HomeWrapper>
      <IonContent fullscreen>
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonImg className="logo" src="images/logo.svg" alt="Logo tedispo" />
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter className="ion-no-border ion-padding">
        <IonButton expand="block" color="primary" onClick={() => history.push('/signup/choose')}>
          Inscription
        </IonButton>
        <IonButton expand="block" fill="outline" color="primary" onClick={() => history.push('/login/send-phone')}>
          Connexion
        </IonButton>
      </IonFooter>
    </HomeWrapper>
  );
};

const HomeWrapper = styled(IonPage)`
  ion-row {
    height: 100vh;
    background-color: var(--ion-color-secondary);
  }
`;
export default GuestHome;
