import { graphql } from '../../models/gql';

export const UPDATE_NOTIFICATION = graphql(/* GraphQL */ `
  mutation updateNotification($id: ID!, $data: NotificationInput!) {
    updateNotification(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`);

export const MARK_AS_SEEN = graphql(/* GraphQL */ `
  mutation MarkAsSeen {
    MarkAsSeen {
      success
      message
    }
  }
`);
