import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_MESSAGE_ITEMS } from '../graphql/queries/messageItem.graphql';

export const useMessageItems = () => {
  const [getMessageItems, { data, loading, refetch }] = useLazyQuery(GET_MESSAGE_ITEMS);

  return {
    getMessageItems,
    messageItems: data?.messageItems?.data,
    refetch,
    loadMessageItems: loading,
  };
};
