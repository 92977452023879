import { useMutation } from '@apollo/client';
import {
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  IonTextarea,
  IonToast,
  useIonToast,
} from '@ionic/react';
import { arrowUndoOutline, closeOutline, flagOutline } from 'ionicons/icons';
import { ReactNode, createElement, useCallback, useEffect, useState } from 'react';
import sendSvg from '../../assets/icons/send.svg';
import warningSvg from '../../assets/icons/warning.svg';
import { REPORTING_LIST } from '../../constants';
import {
  CREATE_COMMENT,
  CREATE_SUB_COMMENT,
  DELETE_COMMENT,
  DELETE_SUBCOMMENT,
} from '../../graphql/mutations/comment.graphql';
import { useComments } from '../../hooks/useComments';
import { useLikePost, usePost } from '../../hooks/usePosts';
import { UserComment } from '../../pages/Profil/UserComment';
import { getNbComments, getUserId } from '../../utils';
import { ConfirmModal } from '../Modals';
import Reporting from '../Reporting';
import SimpleHeader from '../SimpleHeader';
import UserDetail from '../UserDetail';
import './style.css';

import { IonSkeletonText } from '@ionic/react';
import { CommentEntity, CommentInput, Enum_Report_Type, PostLike, SubcommentEntity } from '../../models/gql/graphql';
import { useAppStore } from '../../store';
import { useUserData } from '../../utils/auth';
import { checkForbiddenWordInputted, checkForbiddenWordPasted } from '../../utils/word';
import LikeComment from '../LikeComment';
import { appendChild, appendClasses, createCommentElement, createIonElement, removeElement } from './utils';

const stringToHtml = (params: string): ReactNode => {
  return createElement('div', { dangerouslySetInnerHTML: { __html: params } });
};

interface IPostComment {
  id: string | null;
  cancel: (nbComments?: number, nbLikes?: number) => void;
  refetchLike?: any;
  userLikePost: PostLike;
}
const PostComment: React.FC<IPostComment> = ({ id, cancel, refetchLike, userLikePost }) => {
  const { post, refreshPost, loading: loadingPost } = usePost(id || '0');
  const [comment, setComment] = useState('');
  const [nbComments, setNbComments] = useState<number>(0);
  const [newComments, setNewComments] = useState<number>(0);
  const [likes, setLikes] = useState(0);
  const [selectedComment, setSelectedComment] = useState<CommentEntity | SubcommentEntity>(
    {} as CommentEntity | SubcommentEntity
  );
  const [commentToDelete, setCommentToDelete] = useState<CommentEntity | SubcommentEntity>(
    {} as CommentEntity | SubcommentEntity
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>('');
  const { userData: userConnected } = useUserData();

  const [toReport, setToReport] = useState<CommentEntity | SubcommentEntity | null>(null);
  const [openReporting, setOpenReporting] = useState<boolean>(false);
  const [createComment, { loading: loadingComment }] = useMutation(CREATE_COMMENT);
  const [createSubComment, { loading: loadingSubComment }] = useMutation(CREATE_SUB_COMMENT);
  const [presentToast] = useIonToast();

  const {
    RootStore: { forbiddenWords },
  } = useAppStore();
  const { comments, refreshComment } = useComments(id || '0');

  const { like, loadingLike, loadingUnlike } = useLikePost(post.id || '', refetchLike);

  const showError = () => {
    setSelectedComment({});
    removeElement('tmpComment');
    setIsOpen(true);
    setMessageError("Erreur lors de l'envoi du commentaire.");
  };

  const commentPost = async () => {
    if (!comment) {
      return;
    }
    setComment('');

    let commentElement: any = document.getElementById(selectedComment.id || '');
    if (!commentElement) {
      const ionLabels = document.querySelectorAll('ion-label.user-comment');
      if (ionLabels.length > 0) {
        commentElement = ionLabels[ionLabels.length - 1];
      } else {
        commentElement = document.getElementById('post-el');
      }
    }

    if (commentElement) {
      const tmpComment = createIonElement('ion-label', { id: 'tmpComment', 'text-wrap': 'true' });
      appendClasses(tmpComment, ['full-width', 'user-comment']);

      const div = document.createElement('div');
      div.style.marginBottom = selectedComment.id ? '0' : '5px';
      if (selectedComment.id) {
        appendClasses(div, ['ion-margin-start', 'ion-padding-start']);
      }
      const avatarUrl =
        userConnected?.profilePicture?.attributes?.url ||
        (userConnected?.avatar?.attributes?.image.data?.attributes?.url
          ? userConnected?.avatar?.attributes?.image.data?.attributes?.url
          : 'https://ionicframework.com/docs/img/demos/avatar.svg');

      const username = !userConnected.isPro ? userConnected.username : userConnected.companyName;
      const ionRow = createCommentElement(comment, !!selectedComment.id, username || '', avatarUrl);

      appendChild(div, ionRow);
      appendChild(tmpComment, div);
      commentElement.parentNode?.insertBefore(tmpComment, commentElement.nextSibling);
    }

    if (selectedComment && 'id' in selectedComment) {
      const subcommentData = {
        comment: selectedComment.id,
        content: comment,
        publishedAt: new Date(),
        author: getUserId(),
      };
      try {
        await createSubComment({ variables: { data: subcommentData as CommentInput } });
        setNbComments((c) => c + 1);
        setNewComments((c) => c + 1);
      } catch (e) {
        console.error(e);
        showError();
        throw e;
      }
    } else {
      const commentData = {
        author: getUserId(),
        post: id,
        publishedAt: new Date(),
        content: comment,
      };
      try {
        await createComment({ variables: { data: commentData as CommentInput } });
        setNbComments((c) => c + 1);
        setNewComments((c) => c + 1);
      } catch (e) {
        console.error(e);
        showError();
        throw e;
      }
    }
    setSelectedComment({});
    await refreshComment();

    removeElement('tmpComment');
  };
  const handleCommentChange = (value: any) => {
    let text = value.detail.value;
    const forbidden = checkForbiddenWordInputted(forbiddenWords, text);
    if (forbidden) {
      // remove
      var lastIndex = text.trim().lastIndexOf(' ');
      text = text.trim().substring(0, lastIndex) + ' ';
      // set error msg
      presentToast('Un mot interdit a été détécté et a été supprimé de votre entrée', 1000);
    }
    setComment(text);
  };
  const handlePasteEvent = useCallback(
    (e: any) => {
      const pasted = e?.clipboardData?.getData('text');
      const newPastedArr = pasted.split(' ').filter((text: string) => {
        return !checkForbiddenWordPasted(forbiddenWords, text);
      });
      if (pasted.split(' ').length !== newPastedArr.length) {
        const changed = newPastedArr.join(' ');
        setTimeout(() => {
          setComment(`${comment} ${changed}`);
          presentToast('Des mots interdits ont été détéctés et a été supprimé de votre entrée', 1000);
        }, 100);
      }
    },
    [forbiddenWords, comment]
  );

  useEffect(() => {
    if (post?.attributes?.comments?.data) {
      setNbComments(getNbComments(post?.attributes?.comments?.data));
    }
    if (post?.attributes?.reactions?.data) {
      setLikes(post?.attributes?.reactions?.data.length);
    }
  }, [post]);

  return (
    <>
      <SimpleHeader
        title={`Commentaires`}
        noButtonBack={false}
        backButtonColor="text-black"
        backAction={() => {
          refreshPost();
          cancel(nbComments, likes);
          setOpenReporting(false);
        }}
        addMarginTop={true}
      ></SimpleHeader>
      <IonContent className="full overflow-auto ">
        <IonGrid
          className="ion-justify-content-center ion-align-items-center width-p-95 bg-white border-radius margin-b-300 border-shadow-comment"
          style={{ padding: 10 }}
        >
          {toReport && (
            <IonItem button={false} className="background-comment" lines="none">
              <IonIcon
                onClick={() => setToReport(null)}
                slot="start"
                className="color-comment"
                icon={closeOutline}
                size="sm"
              ></IonIcon>
              <IonLabel className="color-comment">1 commentaire sélectionné</IonLabel>
              <IonIcon
                onClick={() => setOpenReporting(true)}
                className="color-comment"
                slot="end"
                icon={flagOutline}
                size="sm"
              ></IonIcon>
            </IonItem>
          )}
          <Reporting
            actionCancel={() => setOpenReporting(false)}
            listItems={REPORTING_LIST}
            isOpen={openReporting}
            contentTypeReported={Enum_Report_Type.Commentaire}
            title="Pour quel motif veux-tu effectuer ce signalement ?"
            relatedId={toReport?.id}
            userId={toReport?.attributes?.author?.data?.id as string}
            callBack={() => {
              setToReport(null);
              cancel();
            }}
            postCommentId={post.id as string}
            postUserId={post.attributes?.author?.data?.id as string}
          ></Reporting>
          <IonRow className="ion-align-items-center ion-justify-content-center">
            <IonCol className="ion-no-padding">
              {loadingPost ? (
                <>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                  <IonSkeletonText animated style={{ width: '40%' }} />
                </>
              ) : (
                <UserDetail
                  avatarStyle="width-30 height-30 ion-margin-end"
                  user={post.attributes?.author?.data?.attributes}
                  isComment={true}
                />
              )}
            </IonCol>
            <IonCol className="ion-align-items-center ion-justify-content-center" size="auto">
              <LikeComment
                actionComment={() => {}}
                nbComment={nbComments || 0}
                nbLike={likes || 0}
                likeLoading={loadingLike || loadingUnlike}
                userLikePost={userLikePost}
                actionLike={async () => {
                  try {
                    await like(userLikePost);
                    if (userLikePost) {
                      if (likes) setLikes((n) => n - 1);
                    } else {
                      setLikes((n) => n + 1);
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }}
                insideComment={true}
              />
            </IonCol>
          </IonRow>
          <IonRow className="padding-x-10 font-outfit margin-post-comment" id="post-el">
            <IonText className="text-capitalize text-with-p" style={{ fontSize: 16 }}>
              {stringToHtml(post.attributes?.description || '')}
            </IonText>
          </IonRow>
          {loadingPost && (
            <div className="ion-padding custom-skeleton">
              <IonSkeletonText animated style={{ width: '60%' }} />
              <IonSkeletonText animated />
              <IonSkeletonText animated style={{ width: '70%' }} />
            </div>
          )}
          {!!(comments && comments.length) &&
            comments
              .filter((c) => !c.attributes?.deleted)
              .map((comment, index) => (
                <UserComment
                  comment={comment}
                  setSelectedComment={setSelectedComment}
                  key={index}
                  setCommentToDelete={setCommentToDelete}
                  setToReport={setToReport}
                  onClick={() => {
                    setCommentToDelete(comment);
                  }}
                  onClickReporting={() => {
                    setToReport(comment);
                  }}
                />
              ))}
        </IonGrid>
        <IonToast
          isOpen={isOpen}
          message={messageError}
          onDidDismiss={() => setIsOpen(false)}
          duration={5000}
          color="danger"
        ></IonToast>
      </IonContent>
      <IonFooter className="modal-footer ion-no-border">
        <div className="flex-container full-width">
          {selectedComment && 'id' in selectedComment && (
            <IonRow className="full-width bg-gray-400 text-white padding-5 border-radius-top-10">
              {
                <IonIcon
                  icon={arrowUndoOutline}
                  className="ion-icon-white"
                  onClick={() => {
                    setSelectedComment({});
                  }}
                />
              }{' '}
              <span className="padding-l-5">
                Répondre à {selectedComment.attributes?.author?.data?.attributes?.username}
              </span>
              .
            </IonRow>
          )}
          <IonRow style={{ width: '100%' }}>
            <form className="reply-comment-form">
              <IonTextarea
                rows={1}
                className="custom"
                value={comment}
                onPaste={handlePasteEvent}
                onIonInput={handleCommentChange}
                name="body"
                maxlength={2000}
                id="body"
                placeholder="Rédige un commentaire..."
              />
              <button
                type="button"
                className="reply-comment-button"
                onClick={commentPost}
                disabled={loadingComment || loadingSubComment}
              >
                <IonIcon size="large" icon={sendSvg}></IonIcon>
              </button>
            </form>
          </IonRow>
        </div>
      </IonFooter>
      <DeleteComment
        showModal={!!('id' in commentToDelete)}
        handleCancel={() => {
          setCommentToDelete({});
        }}
        selectedComment={commentToDelete.id}
        type={commentToDelete.__typename}
        refreshComment={() => {
          refreshComment();
          refreshPost();
          setNewComments((c) => c - 1);
        }}
      />
    </>
  );
};

export default PostComment;

interface IDeleteComment {
  showModal: boolean;
  handleCancel: () => void;
  selectedComment?: Maybe<string>;
  type?: string;
  refreshComment: () => void;
}
const DeleteComment: React.FC<IDeleteComment> = ({
  showModal,
  handleCancel,
  selectedComment,
  type,
  refreshComment,
}) => {
  const [deleteComment, { loading: loadingDeleteC }] = useMutation(DELETE_COMMENT);
  const [deleteSubComment, { loading: loadingDeleteSC }] = useMutation(DELETE_SUBCOMMENT);
  const handleDeleteComment = async () => {
    if (type === 'CommentEntity') {
      await deleteComment({ variables: { id: selectedComment || '0' } });
    } else if (type === 'SubcommentEntity') {
      await deleteSubComment({ variables: { id: selectedComment || '0' } });
    }
    handleCancel();
    refreshComment();
  };

  return (
    <ConfirmModal
      isOpen={showModal}
      title="Veux-tu supprimer ton commentaire ?"
      text="Cette action est définitive."
      okText="Confirmer"
      cancelText="Annuler"
      iconPerso={warningSvg}
      onConfirm={handleDeleteComment}
      onCancel={handleCancel}
      height="345px"
      loading={loadingDeleteC || loadingDeleteSC}
    />
  );
};
