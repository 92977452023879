export const appTabsItems: IAppTabsItem[] = [
  {
    id: 'my-dispos',
    title: 'Mes dispos',
    url: '/main/dispos',
    iconSize: 24,
    iconUrl: './assets/icon/calendar.svg',
    selectedIconUrl: './assets/icon/blueCalendar.svg',
  },
  {
    id: 'messages',
    title: 'Messages',
    url: '/main/chat',
    iconSize: 24,
    iconUrl: './assets/icon/message.svg',
    selectedIconUrl: './assets/icon/blueMessage.svg',
  },
  {
    id: 'home',
    title: 'Accueil',
    url: '/main/home',
    iconSize: 24,
    iconUrl: './assets/icon/home.svg',
    selectedIconUrl: './assets/icon/blueHome.svg',
  },
  {
    id: 'notifications',
    title: 'Notifications',
    url: '/main/notifications/mine',
    iconSize: 24,
    iconUrl: './assets/icon/bell.svg',
    selectedIconUrl: './assets/icon/blueBell.svg',
  },
  {
    id: 'account',
    title: 'Compte',
    url: '/main/my-account/menu',
    iconSize: 24,
    iconUrl: './assets/icon/settings.svg',
    selectedIconUrl: './assets/icon/blueAccount.svg',
  },
];
