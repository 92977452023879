import { useMutation } from '@apollo/client';
import { Keyboard } from '@capacitor/keyboard';
import { InputCustomEvent, IonButton, IonInput, useIonToast } from '@ionic/react';
import { phonePortraitOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import OtpInput from 'react18-input-otp';
import { CardCustom, ValidatedIcon } from '../../../../components';
import { LOGIN_QUERY } from '../../../../graphql/mutations/auth.graphql';
import { AuthVerifyCodeInput } from '../../../../models/gql/graphql';
import { getUserId } from '../../../../utils';
import { __ } from '../../../../utils/traduction';
import './style.css';

interface IOtpInputCode {
  phone: string;
  onSubmit: any;
  type?: string;
  loading?: boolean;
  messageError?: string;
  setMessageError?: (value: string) => void;
  errorCode?: any;
  showIcon?: boolean;
  onTimeoutReseted?: (val: boolean) => any;
}

const OtpInputCode: React.FC<IOtpInputCode> = ({
  phone,
  onSubmit,
  type,
  loading,
  messageError,
  setMessageError,
  errorCode,
  showIcon = true,
  onTimeoutReseted,
}) => {
  const [AuthWithPhone, { data: dataLogin, loading: loadingLogin }] = useMutation(LOGIN_QUERY);
  const [timeoutReseted, setTimeoutReseted] = useState<boolean>(false);
  const methods = useForm<AuthVerifyCodeInput>();
  const TIME_MAX = 30;
  const [timeMax, setTimeMax] = useState<number>(TIME_MAX);
  const userId = getUserId();
  const location = useLocation();
  const [present] = useIonToast();
  const otpInputRef = useRef<OtpInput>(null);
  const {
    register,
    setValue,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = methods;
  const [code, setCode] = useState<string>('');

  if (phone) register('phone', { value: phone });
  register('smsCode', { required: 'Le code sms est requis' });
  let timeHandle: any = 0;
  const timeOut = {
    start: () => {
      let countdown = TIME_MAX;
      timeHandle = setInterval(() => {
        countdown = countdown - 1;
        setTimeMax(countdown);
      }, 1000);
    },
  };

  useEffect(() => {
    if (timeMax === 0) {
      setTimeoutReseted(true);
      if (onTimeoutReseted) onTimeoutReseted(true);
      setTimeMax(TIME_MAX);
    }
  }, [timeMax]);

  useEffect(() => {
    if (!timeoutReseted) {
      timeOut.start();
    }
    return () => {
      clearInterval(timeHandle);
    };
  }, [timeoutReseted]);

  const sendNewCode = async () => {
    phone = phone ? phone : getValues('phone');
    const idUser: string | null | undefined = showIcon ? '0' : userId?.toString();

    try {
      setMessageError && setMessageError('');
      const resp = await AuthWithPhone({ variables: { data: { phone, idUser } } as any });
      setTimeoutReseted(false);
      if (onTimeoutReseted) onTimeoutReseted(false);
      setTimeMax(TIME_MAX);
      methods.reset();
      setCode('');
      present({
        message: 'Un nouveau code a été envoyé vers votre numéro de téléphone',
        duration: 1500,
        position: 'top',
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeOtp = (code: string) => {
    setCode(code);
    setValue('smsCode', code);
  };
  const onSubmitInside = async (data: any) => {
    try {
      if (phone) {
        data.phone = phone;
      }
      if (onSubmit) await onSubmit(data);
      setTimeMax(0);
      methods.reset();
      setValue('smsCode', '');
      setCode('');
    } catch (e) {
      setError('smsCode', { type: 'custom', message: 'Le code renseigné est incorrect.' });
    }
  };

  const oneTimeChanged = (e: InputCustomEvent) => {
    console.log('one time changed', e.detail.value);
    if (e.detail.value) {
      setCode(e.detail.value);
    }
  };

  const inputOtp = document.querySelector('.input-otp');

  inputOtp?.addEventListener('contextmenu', (event) => {
    console.log({ eventContext: event });
    event.preventDefault();
  });

  const listenChange = async (e: any) => {
    const type = e.type;

    if (e.keyCode === 8) {
      e.stopPropagation();
      setCode(code.slice(0, -1));
      setValue('smsCode', code.slice(0, -1));
    }
    if (type === 'touchstart') {
      console.log('touchstart touchstart', /\d/.test(e.target.value));
      if (/\d/.test(e.target.value)) {
        e.stopPropagation();
        e.preventDefault();
        return;
      }
    } else if (type === 'paste') {
      e.stopPropagation();
      const pastedValue = e.clipboardData.getData('text');
      const cleanedValue = pastedValue.replace(/[^0-9]/g, '');

      await setCode(cleanedValue);
      await setValue('smsCode', cleanedValue);
      e.preventDefault();
    }
  };

  const addEventListeners = () => {
    const otpInput = document.querySelector('.custom-otp-input');

    if (otpInput) {
      otpInput.addEventListener('keydown', listenChange);
      otpInput.addEventListener('paste', listenChange);
      otpInput.addEventListener('touchstart', listenChange);
    }
  };

  const removeEventListeners = () => {
    const otpInput = document.querySelector('.custom-otp-input');

    if (otpInput) {
      otpInput.removeEventListener('keydown', listenChange);
      otpInput.removeEventListener('paste', listenChange);
      otpInput.removeEventListener('touchstart', listenChange);
    }
  };

  useEffect(() => {
    addEventListeners();
    return () => {
      removeEventListeners();
    };
  }, [code]);

  useEffect(() => {
    if (otpInputRef.current) {
      otpInputRef.current.focusInput(0);
    }
    methods.reset();
    setCode('');
  }, []);

  useEffect(() => {
    setTimeMax(TIME_MAX);
  }, [location.pathname]);

  return (
    <FormProvider {...methods}>
      <CardCustom>
        <form onSubmit={handleSubmit(onSubmitInside)}>
          {showIcon && (
            <>
              <ValidatedIcon icon={phonePortraitOutline}></ValidatedIcon>
              <p className="ion-text-center margin-auto font-outfit font-description">
                {type
                  ? 'Renseigne le code reçu par SMS.'
                  : 'Afin de valider votre inscription, indique le code reçu par SMS.'}
              </p>
            </>
          )}
          <div className={'ion-center'}>
            <div className={'max-width-250'}>
              <OtpInput
                ref={otpInputRef}
                shouldAutoFocus={true}
                value={code}
                onChange={handleChangeOtp}
                containerStyle={`container-otp border-radius-10 ${
                  !!errorCode || errors.smsCode ? 'code-invalide' : ''
                }`}
                numInputs={6}
                inputStyle="input-otp text-bolder text-size-31 text-black"
                isInputNum={true}
                focusStyle="custom-otp-input"
              />
            </div>
          </div>

          {errors.smsCode && (
            <p className={`errorDescription font-outfit ${errors.smsCode ? 'ion-text-center' : ''}`}>
              {errors.smsCode.message}
            </p>
          )}
          {!!messageError && <p className="errorDescription font-outfit ion-text-center">{__(messageError)}</p>}
          <div style={{ display: 'none' }}>
            <IonInput
              inputmode="numeric"
              maxlength={6}
              max={6}
              pattern="\d{6}"
              type="text"
              autocomplete="one-time-code"
              onIonInput={oneTimeChanged}
            />
          </div>
          <div className={' margin-y-20'}>
            <IonButton
              type="submit"
              disabled={code.toString().length < 6 || loading}
              className="ion-margin-top "
              expand="block"
              color="primary"
            >
              Valider
            </IonButton>
          </div>
        </form>
        {/*!timeoutReseted && <p className="custom-label ion-text-center text-size-xl"> {timeMax}s</p>*/}
        {!!timeoutReseted && (
          <div className="text-center">
            <div className="text-size-xs padding-b-10">Aucun code reçu ?</div>
            <div>
              <IonButton
                style={{ padding: 0 }}
                disabled={loadingLogin}
                onClick={sendNewCode}
                className="text-black text-size-xs text-underline"
                color="dark"
                fill="clear"
              >
                Recevoir un autre SMS
              </IonButton>
            </div>
          </div>
        )}
      </CardCustom>
    </FormProvider>
  );
};

export default OtpInputCode;
