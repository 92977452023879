import { IonButton, IonIcon, IonRow, IonText } from '@ionic/react';
import { FC } from 'react';
import commentSvg from '../../assets/icons/comment.svg';
import fullLikeSvg from '../../assets/icons/fullLike.svg';
import likeSvg from '../../assets/icons/like.svg';
import { PostLike } from '../../models/gql/graphql';

interface ILikeComment {
  actionComment: any;
  nbComment: number | null;
  nbLike: number | null;
  likeLoading: any;
  actionLike: any;
  userLikePost: PostLike;
  commentOnly?: boolean;
  insideComment?: boolean;
}
const LikeComment: FC<ILikeComment> = ({
  actionComment,
  nbComment,
  nbLike,
  actionLike,
  likeLoading,
  userLikePost,
  commentOnly = false,
  insideComment = false,
}) => {
  return (
    <IonRow className="ion-align-items-center">
      <IonText className={insideComment ? 'text-black-comment' : 'text-blue'}>{nbComment}</IonText>
      <IonIcon
        icon={commentSvg}
        className="padding-l-3 text-size-xl"
        onClick={() => {
          actionComment();
        }}
        style={{ fontSize: insideComment ? 22 : '' }}
      />
      {!commentOnly && (
        <>
          <IonText className={`${insideComment ? 'text-black-comment' : 'text-blue'} padding-l-10`}>{nbLike}</IonText>
          <IonButton
            fill="clear"
            disabled={likeLoading}
            className="ion-no-padding button-no-border-radius"
            onClick={actionLike}
            style={{ height: 'auto' }}
          >
            <IonIcon
              className={`${userLikePost ? 'text-size-xl' : 'text-size-xs padding-l-3'}`}
              style={{ fontSize: insideComment ? 22 : '' }}
              icon={userLikePost ? fullLikeSvg : likeSvg}
              size="medium"
            />
          </IonButton>
        </>
      )}
    </IonRow>
  );
};

export default LikeComment;
