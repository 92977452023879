import { useLazyQuery, useQuery } from '@apollo/client';
import { IonContent, IonHeader, IonLabel, IonPage } from '@ionic/react';
import { useEffect, useMemo, useState } from 'react';
import { Index, InstantSearch } from 'react-instantsearch';
import { useLocation, useParams } from 'react-router';
import userCloseSvg from '../../assets/icons/user-close-yellow.svg';
import { ProfilHeader, ProfilLock } from '../../components';
import { GET_USER } from '../../graphql/queries/getUser.graphql';
import { GET_RELATION_USER_FOLLOWER } from '../../graphql/queries/userFollower.graphql';
import { getUserFollower } from '../../hooks/useUserFollower';
import { Enum_Userfollower_Status, UserFollowerEntity, UsersPermissionsUser } from '../../models/gql/graphql';
import { useAppStore } from '../../store';
import { getUserId } from '../../utils';
import { useUserData } from '../../utils/auth';
import { NotificationSection } from '../DispoDetail/components/NotificationSection';
import SearchPostResult from '../Home/SearchPostResult';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import SearchResult from '../Home/SearchResult';

enum Enum_segment_profil {
  dispo = 'dispo',
  publication = 'publication',
}

interface IFilter {
  follower: any;
  user?: any;
}

const Profil: React.FC = () => {
  const [segmentValue, setSegmentValue] = useState<Enum_segment_profil>(Enum_segment_profil.dispo);
  const [userId, setUserId] = useState<string>('0');
  const [scrollTop, setScrollTop] = useState(0);
  const { userData: userConnected, refetch: refreshUserData } = useUserData();
  const [relations, setRelations] = useState<UserFollowerEntity[]>([]);
  const [relationInfo, setRelationInfo] = useState<IRelation>({} as IRelation);
  const [bouttonActionLoading, setButtonActionLoading] = useState<boolean>(false);
  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const {
    RootStore: { weatherInfo },
  } = useAppStore();
  const location = useLocation();
  const connectedId = getUserId()?.toString();
  let isMyProfil = !!userId && userId.toString() === connectedId;

  const {
    RootStore: { getUserData, getAlgoliaClient },
  } = useAppStore();
  const searchClient = getAlgoliaClient();

  const [getUserRelation] = useLazyQuery(GET_RELATION_USER_FOLLOWER, {
    fetchPolicy: 'network-only',
  });

  const {
    data: dataUser,
    loading: loadingUser,
    refetch: refetchUser,
  } = useQuery(GET_USER, {
    variables: { id: userId || connectedId || '0' },
    skip: !userId && !connectedId,
  });

  const userData = useMemo(() => {
    return (dataUser && !loadingUser ? dataUser?.usersPermissionsUser?.data?.attributes : []) as UsersPermissionsUser;
  }, [dataUser, loadingUser]);

  const isUserProfilBlocked = useMemo(() => {
    const blockedUsers = userData?.blockedByUsers?.data || [];
    return blockedUsers.findIndex((user: any) => user.id?.toString() === userId?.toString()) > -1;
  }, [userData, userId]);

  const isMeBlocked = useMemo(() => {
    const blockedUsers = userData?.blockedUsers?.data || [];
    return blockedUsers.findIndex((user: any) => user.id?.toString() === connectedId) > -1;
  }, [userData, connectedId]);

  const isBlockedByMe = useMemo(() => {
    const blockedByUsers = userData?.blockedByUsers?.data || [];
    return blockedByUsers.findIndex((user: any) => user.id?.toString() === userConnected.id?.toString()) > -1;
  }, [userData, userConnected]);

  const isUserFollowMe = useMemo(() => {
    const followers = userConnected?.followers?.data || [];
    return (
      followers.findIndex(
        (follower: UserFollowerEntity) => follower.attributes?.follower?.data?.id?.toString() === userId?.toString()
      ) > -1
    );
  }, [userConnected, userId]);

  const isPrivateFeedBlocked = useMemo(() => {
    const isPrivate = userData?.feedType === 'PRIVATE';
    const noRelationsOrNotAccepted =
      !relations?.length || relations[0].attributes?.status !== Enum_Userfollower_Status.Accepted;
    return isPrivate && noRelationsOrNotAccepted;
  }, [userData, relations]);

  const locked = useMemo(() => {
    return (
      (isPrivateFeedBlocked || isUserProfilBlocked || isMeBlocked) &&
      userId &&
      userId.toString() !== connectedId &&
      !isUserFollowMe
    );
  }, [isPrivateFeedBlocked, isUserProfilBlocked, isMeBlocked, userId, connectedId, isUserFollowMe]);

  const getRelation = async (userId?: string) => {
    if (userId && userId !== '0' && userId !== connectedId) {
      let filters: IFilter = {
        follower: {
          id: {
            eq: connectedId,
          },
        },
      };
      filters.user = {
        id: {
          eq: parseInt(userId, 10),
        },
      };
      const { data } = await getUserRelation({
        variables: {
          filters: filters,
          pagination: { limit: 500 },
        },
      });
      let { relationInfo, relations } = getUserFollower(
        (data?.userFollowers?.data || []) as UserFollowerEntity[],
        userData?.feedType,
        userId
      );
      setRelations(relations);
      if (
        userConnected?.blockedUsers &&
        userConnected?.blockedUsers.length &&
        userId &&
        userConnected?.blockedUsers.some((item: any) => item.id.toString() === userId.toString())
      ) {
        setRelationInfo({
          text: 'Débloquer',
          action: 'unblocked',
        });
      } else {
        setRelationInfo(relationInfo);
      }
    } else {
      let { relationInfo, relations } = getUserFollower([] as UserFollowerEntity[], userData.feedType, connectedId);
      setRelations(relations);
      setRelationInfo(relationInfo);
    }
    setButtonActionLoading(false);
  };

  const handleScroll = (event: any) => {
    const currentScroll = event.detail.scrollTop > 0 ? event.detail.scrollTop : 0;
    setScrollTop(currentScroll);
  };

  const refetch = async () => {
    await refreshUserData();
    await getUserData(true);
    await refetchUser();
  };

  useEffect(() => {
    if (userId !== '0' && !!userId && !!userConnected && !!userData) {
      setButtonActionLoading(true);
      getRelation(userId === connectedId || !userId ? '0' : userId);
    }
  }, [userId, userConnected, userData]);

  useEffect(() => {
    const updateUserId = async () => {
      // await FirebaseCrashlytics.crash({ message: 'Test' });
      let pathName = location.pathname;
      if (pathName.includes('/main/profil/')) {
        const pathnameArray = pathName.split('/');
        const newUserId = pathnameArray[pathnameArray.length - 1];
        await setUserId(newUserId);
        setUserLoaded(false);
        setTimeout(() => {
          setUserLoaded(true);
        }, 5);
      }
    };
    updateUserId();
    //force reload content
    if (segmentValue === 'dispo') {
      setSegmentValue(Enum_segment_profil.publication);
      setTimeout(() => {
        setSegmentValue(Enum_segment_profil.dispo);
      }, 2);
    } else {
      setSegmentValue(Enum_segment_profil.dispo);
      setTimeout(() => {
        setSegmentValue(Enum_segment_profil.publication);
      }, 2);
    }
  }, [location]);
  return (
    <IonPage>
      {userLoaded && (
        <InstantSearch indexName={process.env.REACT_APP_ALGOLIA_INDEX as string} searchClient={searchClient}>
          <IonHeader mode="ios" className="full-width ion-no-border">
            <ProfilHeader
              id={userId}
              setSegmentValue={setSegmentValue}
              segmentValue={segmentValue}
              scrollTop={scrollTop}
              isBloked={isMeBlocked || isUserProfilBlocked}
              refetchUser={refetch}
              dataUser={userData}
              userConnected={userConnected}
              isBlockedByMe={isBlockedByMe}
              relations={relations}
              loadingRelation={bouttonActionLoading}
              setButtonActionLoading={setButtonActionLoading}
              relationInfo={relationInfo}
              loadingUserData={loadingUser}
              isMyProfil={isMyProfil}
            />
          </IonHeader>
          <IonContent scrollEvents={true} fullscreen onIonScroll={handleScroll} className="bg-white">
            {isBlockedByMe && (
              <NotificationSection
                message={'Tu as bloqué cet utilisateur'}
                icon={userCloseSvg}
                textColor={'#FFB800'}
                isTextBolder
                notificationPadding={'5px 10px'}
                textMarginTop={'7px'}
                marginBottom={''}
                paddingLeft="0px"
                textSize={'15px'}
                iconSize={'large'}
                bgColor={'#FFF1CC'}
              />
            )}
            {segmentValue === 'dispo' && (
              <div>
                {locked ? (
                  <ProfilLock />
                ) : (
                  <Index indexName={process.env.REACT_APP_ALGOLIA_INDEX as string}>
                    <SearchResult
                      noResultComponent={<IonLabel>Cet utilisateur n'a pas encore de dispo</IonLabel>}
                      filterData={{ creator: userId ? userId : connectedId }}
                      isProfil={true}
                      from="profil"
                    />
                  </Index>
                )}
              </div>
            )}
            {segmentValue === Enum_segment_profil.publication && (
              <Index indexName={(process.env.REACT_APP_ALGOLIA_INDEX as string).replace('event', 'post')}>
                <SearchPostResult
                  noResultComponent={<IonLabel>Cet utilisateur n'a pas encore de publication</IonLabel>}
                  filterData={{ creator: userId ? userId : connectedId }}
                  isProfil={true}
                  locked={locked}
                />
              </Index>
            )}
          </IonContent>
        </InstantSearch>
      )}
    </IonPage>
  );
};

export default Profil;
