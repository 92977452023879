import { useMutation, useQuery } from '@apollo/client';
import {
  IonButton,
  IonContent,
  IonFooter,
  IonPage,
  IonText,
  IonToast,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CardCustom, Header, Loader, StepperCount } from '../../../components';
import { UPDATE_EVENT } from '../../../graphql/mutations/update-event.graphql';
import { UPLOAD } from '../../../graphql/mutations/upload.graphql';
import { GET_EVENT } from '../../../graphql/queries/event.graphql';
import { useDispo } from '../../../hooks';
import { EventEntity, UploadFile } from '../../../models/gql/graphql';
import { handleType } from '../../../store/AppReducerProvider/type';
import SelectImage from './selectImage';
import './style.css';
import succesIcon from '../../../assets/icons/circle-check.svg';

const StepPhoto: React.FC<IStepProps> = ({ id, duplicate }) => {
  const [image, setImage] = useState<any>();
  const [type, setType] = useState<string | undefined>('');
  const [typeArray, setTypeArray] = useState<any>([]);
  const [listDeleted, setListDeletedUrl] = useState([] as any);
  const { dispo, setDispo, setImageUrl, imageUrl, setImageSelecteds, imageSelecteds } = useDispo();
  const page = useRef(null);
  const history = useHistory();
  const [saveEvent, { loading: loadingUpdateEvent }] = useMutation(UPDATE_EVENT);
  const [upload, { loading: loadingUpload }] = useMutation(UPLOAD);
  const [openToast, setOpenToast] = useState<boolean>(false);

  useEffect(() => {
    if ((imageUrl === undefined || imageUrl === null || imageUrl === '') && !image) {
      console.log('reset 0');
      setTimeout(() => {
        console.log('reset');
        setImage(null);
      }, 1000);
    }
  }, [imageUrl]);

  const handleUploadFile = async () => {
    const dataUploaded: any = {};
    if (image && image?.file && image.file.name) {
      const { data: dataUpload } = await upload({ variables: { file: image.file } });
      dataUploaded['coverCustomImage'] = dataUpload?.upload?.data?.id;
      dataUploaded['coverTedispoImage'] = null;
    }

    if (image && image?.idImage) {
      dataUploaded['coverTedispoImage'] = image.idImage;
      dataUploaded['coverCustomImage'] = null;
      // setDispo({ ...dispo, coverTedispoImage: image.idImage });
    }
    if ((image && !image?.idImage && image?.file && !image.file.name) || !image) {
      if (dispoDefault?.attributes?.coverCustomImage && dispoDefault.attributes.coverCustomImage.data?.id) {
        dataUploaded['coverCustomImage'] = dispoDefault.attributes.coverCustomImage.data?.id;
        dataUploaded['coverTedispoImage'] = null;
      } else if (dispoDefault?.attributes?.coverTedispoImage) {
        dataUploaded['coverTedispoImage'] = dispoDefault.attributes.coverTedispoImage.data?.id;
        dataUploaded['coverCustomImage'] = null;
      }
    }

    console.log('dataUploaded', dataUploaded);
    console.log({ Uploadsecondary: imageSelecteds });
    const secondaryImages = uploadFiles(imageSelecteds);
    const secondaryImagesTmp = await Promise.all(secondaryImages);
    let filteredSecondaryImages;

    if (id) {
      if (dispoDefault?.attributes?.secondaryImages && dispoDefault?.attributes?.secondaryImages.data.length) {
        dispoDefault?.attributes?.secondaryImages.data.map((data) => {
          if (
            secondaryImages &&
            data.id &&
            !secondaryImagesTmp.includes(data.id) &&
            !listDeleted.includes(data.attributes?.url)
          ) {
            secondaryImagesTmp.push(data.id);
          }
        });
      }
      filteredSecondaryImages = secondaryImagesTmp.filter(function (item) {
        return item !== undefined;
      });
      setDispo({ ...dispo, ...dataUploaded, secondaryImages: (await Promise.all(filteredSecondaryImages)) as any });
    } else {
      setDispo({ ...dispo, ...dataUploaded, secondaryImages: (await Promise.all(secondaryImages)) as any });
    }

    setImageUrl(image && 'url' in image ? image.url : image);

    if (duplicate) {
      history.push({
        pathname: `/main/dispos/${id}/duplicate`,
        search: `?${new URLSearchParams({ page: 'step-tarif' }).toString()}`,
      });
    } else {
      if (!id) {
        // setImage(null);
        history.push('/main/home/create-dispo/step-tarif');
        // setImage(null)
      } else {
        updateEvent(id, dataUploaded, await Promise.all(filteredSecondaryImages as any));
      }
    }
  };

  const { data: dispoData, loading: loadingEvent } = useQuery(GET_EVENT, {
    variables: { id: id ? id : '0' },
  });

  const dispoDefault = useMemo(() => {
    return !loadingEvent ? dispoData?.event?.data : ([] as EventEntity);
  }, [loadingEvent, dispoData]);

  const updateEvent = async (dispoId: string, dataUploaded: any, secondaryImages?: any) => {
    try {
      if (dispoDefault?.attributes?.secondaryImages && dispoDefault?.attributes?.secondaryImages.data.length) {
        dispoDefault?.attributes?.secondaryImages.data.map((data) => {
          if (
            secondaryImages &&
            data.id &&
            !secondaryImages.includes(data.id) &&
            !listDeleted.includes(data.attributes?.url)
          ) {
            secondaryImages.push(data.id);
          }
        });
      }
      const { data: dataEvent } = await saveEvent({
        variables: {
          id: dispoId,
          data: {
            coverCustomImage: dataUploaded['coverCustomImage'],
            secondaryImages: secondaryImages.filter((value: string) => value !== undefined),
            coverTedispoImage: dataUploaded['coverTedispoImage'],
          },
        },
      });
      setOpenToast(true);
      history.push(`/main/dispos/${dispoId}/edit`);
    } catch (e) {
      console.log(e);
    }
  };

  const uploadFiles = (imagesToUpload: any[]) => {
    let filteredImage = [] as handleType[];
    imagesToUpload.map((img, index) => {
      if (img) filteredImage.push(img);
    });
    return filteredImage.map(async (img: any) => {
      if (img && img?.file && img.file.name) {
        try {
          const { data: dataUpload } = await upload({ variables: { file: img.file } });
          return dataUpload?.upload.data?.id;
        } catch (e) {
          console.log(e);
        }
      }
      return;
    });
  };

  const defaultSecondaryImg = useMemo(() => {
    let defaultSecondaryImg = [] as any;
    if (dispoDefault && dispoDefault.attributes) {
      const { secondaryImages, coverTedispoImage, coverCustomImage } = dispoDefault.attributes;

      if (secondaryImages && secondaryImages.data.length) {
        secondaryImages.data.map((data) => {
          defaultSecondaryImg.push({
            file: { type: data.attributes?.mime },
            url: data.attributes?.url,
            idImage: null,
            previewUrl: data.attributes?.previewUrl,
          });
        });
      }

      if (coverTedispoImage && coverTedispoImage.data) {
        setImageUrl(coverTedispoImage.data?.attributes?.image?.data?.attributes?.url || '');
        setImage({
          file: { type: coverTedispoImage.data?.attributes?.image?.data?.attributes?.mime },
          url: coverTedispoImage.data?.attributes?.image?.data?.attributes?.url,
          idImage: null,
        });
        setType(coverTedispoImage.data?.attributes?.image?.data?.attributes?.mime);
      } else if (coverCustomImage && coverCustomImage.data) {
        setImageUrl(coverCustomImage.data.attributes?.url || '');
        setImage({
          file: {
            type: coverCustomImage.data?.attributes?.mime,
          },
          url: coverCustomImage.data?.attributes?.url,
          idImage: null,
        });
        setType(coverCustomImage.data?.attributes?.mime);
      }
    }
    setTypeArray(defaultSecondaryImg.map((elem: any) => elem?.file.type));
    setImageSelecteds(defaultSecondaryImg);
    return defaultSecondaryImg as UploadFile[];
  }, [dispoDefault]);

  const handleChangeFiles = (imgs: handleType[], idToChange: number) => {
    const imagesClone = cloneDeep(imageSelecteds);
    if (imgs && imgs.length) {
      imgs.forEach((img, index) => {
        const updatedIndex = idToChange + index;

        //save replaced img
        if (updatedIndex in imagesClone) {
          setListDeletedUrl([...listDeleted, imagesClone[updatedIndex].url]);
        }
        if (updatedIndex in defaultSecondaryImg) {
          setListDeletedUrl([...listDeleted, defaultSecondaryImg[updatedIndex].url]);
        }
        if (updatedIndex === 0 && image) {
          setListDeletedUrl([...listDeleted, image.url]);
        }

        imagesClone[updatedIndex] = img;
      });
    }
    setImageSelecteds(imagesClone);
    setTypeArray(imagesClone.map((elem) => elem?.file?.type));
  };

  const resetImg = (index: number, img: handleType) => {
    const imagesClone = cloneDeep(imageSelecteds);
    let imgDeletedUrl = img.url;
    setListDeletedUrl([...listDeleted, imgDeletedUrl]);
    imagesClone.splice(index, 1);
    setImageSelecteds(imagesClone);
    setTypeArray(imagesClone.map((elem: any) => elem?.file.type));
  };

  const headerTitle = duplicate ? 'Duplication de ta dispo' : !id ? "Création d'une dispo" : 'Editer les photos';

  const renderSelectImage = (index: number) => {
    return (
      <div className={`galery-item galery-${index + 1}`} key={index + 1}>
        <SelectImage
          handleChangeMulti={(imageData, indexImg) => {
            handleChangeFiles(imageData, indexImg !== undefined ? indexImg : index);
          }}
          defaultImgUrl={
            imageSelecteds[index] ? imageSelecteds[index].url : defaultSecondaryImg && defaultSecondaryImg[index]?.url
          }
          file={imageSelecteds[index] ? imageSelecteds[index].file : null}
          previewUrl={defaultSecondaryImg ? defaultSecondaryImg[index]?.previewUrl || '' : ''}
          resetImg={(idx: number, img: handleType) => resetImg(index, img)}
          type={typeArray[index]}
        />
      </div>
    );
  };
  useEffect(() => {
    console.log('// load page');
    console.log(' --> ', dispo);
    console.log('---->', dispoDefault);
    console.log('---->', image);
  }, []);

  useIonViewWillEnter(() => {
    if (!id) {
      if (!dispo || !dispo.coverCustomImage) {
        // remove
        setImage(null);
      }
      if (!dispo || !dispo.secondaryImages) {
        // remove
        setImageSelecteds([]);
      }
    }
  });

  return (
    <IonPage ref={page}>
      {(loadingUpload || loadingUpdateEvent) && (
        <Loader width={100} height={100} label="Les medias sont en cours de traitement" />
      )}
      <Header title={headerTitle} noButtonBack={false} />
      {(!id || duplicate) && <StepperCount length={5} numberStep={3}></StepperCount>}
      <IonContent>
        <CardCustom margin={true}>
          <IonText color="dark" className="font-outfit">
            {!id && <h3>Photos</h3>}
            <p style={{ fontSize: '16px' }}>Choisis les images que les utilisateurs verront sur ta dispo.</p>
          </IonText>
          <div className="galery">
            <div className="galery-item galery-main">
              <SelectImage
                handleChangeMulti={(imageData) => {
                  setImage(imageData[0]);
                  setType(imageData[0].file?.type);
                  const newImageData = imageData.slice(1, imageData.length);
                  handleChangeFiles(newImageData, 0);
                }}
                defaultImgUrl={
                  image
                    ? image.url
                    : dispoDefault &&
                      dispoDefault?.attributes &&
                      dispoDefault?.attributes.coverCustomImage?.data?.attributes?.url
                }
                file={null}
                previewUrl={''}
                isMain={true}
                type={type}
              />
            </div>
            {Array.from({ length: 5 }, (_, index) => renderSelectImage(index))}
          </div>
        </CardCustom>
      </IonContent>
      <IonFooter className="ion-no-border ion-padding">
        <IonButton
          onClick={handleUploadFile}
          disabled={!image || imageSelecteds.length === 0 || loadingUpload || loadingUpdateEvent}
          className="ion-margin-top not-transorm-text text-vw-4"
          expand="block"
          color="primary"
        >
          {!id || duplicate ? 'Continuer' : 'Sauvegarder les changements'}
        </IonButton>
      </IonFooter>
      <IonToast
        isOpen={openToast}
        message="Modifications effectuées avec succès"
        duration={5000}
        icon={succesIcon}
      ></IonToast>
    </IonPage>
  );
};

export default StepPhoto;
