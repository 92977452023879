import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonTitle,
  IonCol,
  useIonViewWillLeave,
  IonFooter,
  IonButton,
  IonSpinner,
} from '@ionic/react';
import { Header, InterestChip } from '../../../components';
import { useEffect, useMemo, useState } from 'react';
import { getUserId } from '../../../utils';
import { InterestEntity, InterestsMe } from '../../../models/gql/graphql';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_MY_INTEREST } from '../../../graphql/queries/getUser.graphql';
import {
  ADD_INTEREST_TO_USER,
  REMOVE_INTEREST_TO_USER,
  SET_USER_INTERESTS,
} from '../../../graphql/mutations/updateUser..graphql';
import { NotificationSection } from '../../DispoDetail/components/NotificationSection';
import { closeCircleOutline, save } from 'ionicons/icons';
import { useToast } from '../../../hooks';

const MyInterest: React.FC = () => {
  const [myInterests, setMyInterest] = useState<InterestEntity[]>([]);
  const [myPrimaryInterests, setMyPrimaryInterest] = useState<InterestEntity[]>([]);
  const [otherInterests, setOtherInterest] = useState<InterestEntity[]>([]);
  const [setInterests, { loading: loadingSet }] = useMutation(SET_USER_INTERESTS);
  const { success } = useToast();

  const [loadInterestsFunc, { data: interests, loading: loadingInterests, refetch }] = useLazyQuery(GET_MY_INTEREST);

  /*useIonViewWillLeave(async () => {
   
  }, [myInterests, myPrimaryInterests]);*/

  const save = async () => {
    if (myInterests.length < 3) {
      return;
    }
    try {
      await setInterests({
        variables: {
          ids: myInterests.map((i) => i.id!.toString()) || [],
        },
      });
      await load(true);
      success("Vos centres d'intérets sont à jour");
    } catch (e) {}
  };

  const load = async (reload?: boolean) => {
    const { data: interests } = await (reload ? refetch() : loadInterestsFunc());
    if (interests && interests.myInterest) {
      setMyInterest(interests.myInterest.myInterests as InterestEntity[]);
      setMyPrimaryInterest(interests.myInterest.myInterests as InterestEntity[]);
      setOtherInterest(interests.myInterest.otherInterests as InterestEntity[]);
    }
  };

  useEffect(() => {
    load();
  }, []);
  return (
    <IonPage>
      <Header title="Mes centres d'intérêts"></Header>
      <IonContent className="ion-padding">
        <IonGrid className="ion-justify-content-center ion-align-items-center width-p-95 min-height-200 ion-padding-bottom bg-white border-radius height-auto">
          <IonRow className="ion-justify-content-center ion-align-items-center ">
            <MyInterestList
              interests={myInterests}
              refetch={load}
              onAddedInterest={(interest) => {
                setOtherInterest((i) => {
                  return [...i, interest];
                });
                setMyInterest((i) => {
                  const index = i.findIndex((j) => j.id === interest.id);
                  const arr = [...i];
                  if (index > -1) arr.splice(index, 1);
                  return arr;
                });
              }}
            />
          </IonRow>
          <IonRow>
            {((myInterests && myInterests.length < 3) || !myInterests) && (
              <NotificationSection
                message={'Attention, tu ne peux pas avoir moins de trois intérêts.'}
                icon={closeCircleOutline}
                textColor="#F47E3C"
                paddingLeft="0"
              />
            )}
          </IonRow>
          <IonRow className="ion-justify-content-center ion-align-items-center ">
            <OtherInterestList
              interests={otherInterests}
              refetch={load}
              onAddedInterest={(interest) => {
                setMyInterest((i) => {
                  return [...i, interest];
                });
                setOtherInterest((i) => {
                  const index = i.findIndex((j) => j.id === interest.id);
                  const arr = [...i];
                  if (index > -1) arr.splice(index, 1);
                  return arr;
                });
              }}
            />
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonRow>
          <IonCol>
            <IonButton expand="block" color="primary" onClick={save} disabled={loadingSet}>
              Enregistrer
              {loadingSet && <IonSpinner className="ion-padding-horizontal" name="lines"></IonSpinner>}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonFooter>
    </IonPage>
  );
};

export default MyInterest;

interface IInterestList {
  interests: InterestEntity[];
  refetch: () => void;
  onAddedInterest?: (interest: InterestEntity) => void;
}
const MyInterestList: React.FC<IInterestList> = ({ interests, refetch, onAddedInterest }) => {
  const [removeInterestToUser, { loading: loadingUpdate }] = useMutation(REMOVE_INTEREST_TO_USER);

  const removeInterest = async (interest: InterestEntity) => {
    if (onAddedInterest) onAddedInterest(interest);
    /*const { data: dataInterest } = await removeInterestToUser({
      variables: {
        id: interest.id!,
      },
    });*/

    //refetch();
  };
  return (
    <IonGrid>
      <h4 className="font-outfit">Mes intérêts</h4>
      <IonRow>
        {!!interests.length &&
          interests?.map((interest, index) => (
            <InterestChip
              disabled={loadingUpdate}
              handleChange={() => {
                if (interest) removeInterest(interest);
              }}
              interest={interest}
              isSelected={true}
              key={index}
              otherColor="yellow"
              canCancel={true}
            ></InterestChip>
          ))}
      </IonRow>
    </IonGrid>
  );
};

const OtherInterestList: React.FC<IInterestList> = ({ interests, refetch, onAddedInterest }) => {
  const [addInterestToUser, { loading: loadingUpdate }] = useMutation(ADD_INTEREST_TO_USER);

  const addInterest = async (interest: InterestEntity) => {
    if (onAddedInterest) onAddedInterest(interest);
    /*const { data: dataInterests } = await addInterestToUser({
      variables: {
        id: interest.id!,
      },
    });*/
    //refetch();
  };

  return (
    <IonGrid>
      <h4 className="font-outfit">Sélectionner d'autres intérêts</h4>
      <IonRow>
        {interests?.map((interest, index) => (
          <div key={index}>
            <InterestChip
              disabled={loadingUpdate}
              handleChange={() => {
                if (interest) addInterest(interest);
              }}
              interest={interest}
              isSelected={false}
            ></InterestChip>
          </div>
        ))}
      </IonRow>
    </IonGrid>
  );
};
