import { IonLabel } from '@ionic/react';
import { IonIcon } from '@ionic/react';
import './style.css';

interface IInputMessageProps {
  message?: string;
  icon?: any;
  color?: string;
}

const InputMessage: React.FC<IInputMessageProps> = ({ message, icon, color }) => {
  return (
    <div className="container-message ion-margin-bottom">
      <IonIcon icon={icon} color={color} slot="start"></IonIcon>
      <IonLabel className="margin-left-xs font-outfit" color={color}>
        {message}
      </IonLabel>
    </div>
  );
};

export default InputMessage;
