import { graphql } from '../../models/gql';
export const GET_USERS = graphql(/* GraphQL */ `
  query getUsersPermissionsUsers($filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg) {
    usersPermissionsUsers(filters: $filters, pagination: $pagination) {
      data {
        id
        attributes {
          username
          email
          firstname
          lastname
          phone
          isPro
          companyName
          avatar {
            data {
              id
              attributes {
                image {
                  data {
                    attributes {
                      name
                      url
                      mime
                      hash
                      size
                      provider
                    }
                  }
                }
              }
            }
          }
          profilePicture {
            data {
              id
              attributes {
                name
                url
                mime
                hash
                size
                provider
              }
            }
          }
        }
      }
    }
  }
`);
