import { IonButton, IonCol, IonGrid, IonIcon, IonLabel, IonRow, IonText } from '@ionic/react';
import clockSvg from '../../../assets/icons/clock.svg';
import { informationCircle } from 'ionicons/icons';

export const DispoParticipationFooter: React.FC<IDispoParticipationFooter> = ({
  type,
  price,
  textButton1,
  textButton2,
  actionButton1,
  actionButton2,
  loading,
  isDispoFull,
  dateHasPassed,
  colorShadow,
}) => {
  let text = '';
  let colorClass = '#F7F7F7';
  let color = 'text-primary';
  let priceText = '0€';

  if (type === 'Gratuite' || !!textButton2) {
    text = 'Dispo Gratuite';
    colorClass = 'text-yellow';
    color = '#FFB800';
  } else if (type === 'Payante' && !textButton2) {
    text = 'Prix';
    colorClass = 'text-pink';
    color = '#FF99CF';
    priceText = `${price ? price : 0}€`;
  } else if (type === 'Contributive' && !textButton2) {
    text = 'Dispo Contributive';
    colorClass = 'text-yellow';
    color = '#FFB800';
  }

  if (textButton1 === "Quitter la liste d'attente") {
    colorClass = 'text-yellow';
    color = '#FFB800';
  }

  if (isDispoFull) {
    colorClass = 'text-yellow';
    color = '#FFB800';
  }

  if (dateHasPassed) {
    color = '#FFB800';
  }

  return (
    <>
      <div className={`full-width ion-no-padding`}>
        <div
          className=" border-radius-bottom full-width"
          style={{
            position: 'relative',
            bottom: `16px`,
            boxShadow: `0px 10px 0px ${!colorShadow ? color : colorShadow}`,
            padding: '10px',
            zIndex: 0,
          }}
        ></div>
        {!dateHasPassed ? (
          <>
            {!!!textButton2 && (
              <IonRow className="ion-justify-content-center ion-align-items-center ion-no-padding no-margin-t-15 font-outfit">
                <IonCol size="3" className="text-uppercase text-bolder text-size-lg text-center">
                  <IonRow className="ion-justify-content-center ion-align-items-center">
                    {type !== 'Payante' ? (
                      <IonRow className="margin-l-10">
                        <IonText className="ion-padding text-white text-size-xs">{text}</IonText>
                      </IonRow>
                    ) : (
                      <IonGrid>
                        <IonRow className="ion-justify-content-center ion-align-items-center text-size-xs">
                          <IonText>{text}</IonText>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-align-items-center text-size-30 margin-l-5">
                          <IonText>{priceText}</IonText>
                        </IonRow>
                      </IonGrid>
                    )}
                  </IonRow>
                </IonCol>
                <IonCol size="9">
                  <IonButton
                    className={`width-p-96 ion-no-border button-footer ${colorClass} text-bold text-size-xs not-transorm-text`}
                    onClick={actionButton1}
                    disabled={loading}
                    text-wrap
                    mode="ios"
                  >
                    <IonLabel text-wrap className="ion-padding">
                      <IonRow
                        className="ion-justify-content-center ion-align-items-center"
                        style={{ flexWrap: 'nowrap' }}
                      >
                        <IonCol size="auto">{textButton1}</IonCol>
                        {type === 'waitting' && (
                          <IonCol size="1">
                            <IonIcon icon={clockSvg} size="large"></IonIcon>
                          </IonCol>
                        )}
                        {isDispoFull && (
                          <IonCol size="auto" className="ion-flex-container">
                            <IonIcon icon={informationCircle} style={{ fontSize: '22px' }}></IonIcon>
                          </IonCol>
                        )}
                      </IonRow>
                    </IonLabel>
                  </IonButton>
                </IonCol>
              </IonRow>
            )}
            {!!textButton2 && (
              <div className="ion-padding-horizontal ion-padding-bottom">
                <IonButton
                  mode="ios"
                  expand="block"
                  className={`accepted-dispo`}
                  onClick={actionButton1}
                  disabled={loading}
                >
                  {textButton1}
                </IonButton>
                <IonButton
                  mode="ios"
                  expand="block"
                  className={`ion-margin-top refused-dispo`}
                  onClick={actionButton2}
                  disabled={loading}
                >
                  {textButton2}
                </IonButton>
              </div>
            )}
          </>
        ) : (
          <IonRow className="text-uppercase ion-justify-content-center ion-align-items-center padding-b-10 text-bolder no-margin-t-8 text-white">
            <IonText>Dispo terminée</IonText>
          </IonRow>
        )}
      </div>
    </>
  );
};
