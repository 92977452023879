import { graphql } from '../../models/gql';
export const GET_IMAGES = graphql(/* GraphQL */ `
  query images {
    images {
      data {
        id
        attributes {
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`);
