import { IonPage, useIonViewDidEnter } from '@ionic/react';
import { useEffect } from 'react';
import { useStripeOnBoarding } from '../../../hooks/useStripeOnBoarding';

export const RefreshAccount: React.FC<any> = (props) => {
  const { openBrowser } = useStripeOnBoarding();
  useIonViewDidEnter(() => {
    console.log('openBrowser');
    openBrowser();
  });
  return <IonPage />;
};

export default RefreshAccount;
