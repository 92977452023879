import { graphql } from '../../models/gql';

export const DELETE_EVENT = graphql(/* GraphQL */ `
  mutation deleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`);
