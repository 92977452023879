import { graphql } from '../../models/gql';

export const CREATE_POST = graphql(/* GraphQL */ `
  mutation createPost($data: PostInput!) {
    createPost(data: $data) {
      data {
        id
        attributes {
          medias {
            data {
              id
              attributes {
                name
                size
                previewUrl
              }
            }
          }
          description
        }
      }
    }
  }
`);

export const UPDATE_POST = graphql(/* GraphQL */ `
  mutation updatePost($id: ID!, $data: PostInput!) {
    updatePost(id: $id, data: $data) {
      data {
        id
        attributes {
          medias {
            data {
              id
              attributes {
                name
                size
                previewUrl
              }
            }
          }
          description
        }
      }
    }
  }
`);

export const LIKE_POST = graphql(/* GraphQL */ `
  mutation createReaction($data: ReactionInput!) {
    createReaction(data: $data) {
      data {
        id
      }
    }
  }
`);

export const UN_LIKE_POST = graphql(/* GraphQL */ `
  mutation deleteReaction($id: ID!) {
    deleteReaction(id: $id) {
      data {
        id
      }
    }
  }
`);
