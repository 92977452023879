import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { pauseOutline } from 'ionicons/icons';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import playAudioIcon from '../../../../assets/icons/playAudio.svg';
import './style.css';

interface IProps {
  media: any;
}

const AudioWaveAndroid: React.FC<IProps> = ({ media }) => {
  const [url, setUrl] = useState('');
  const [playing, setPlaying] = useState(false);
  const [durationSeconds, setDurationSeconds] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const playerRef = useRef<any>(null);

  const seek = (newPosition: number) => {
    playerRef.current?.seekTo(newPosition, 'seconds');
    setPlayedSeconds(newPosition);
  };

  useEffect(() => {
    if (loaded) {
      const parts = media.url.split('/');
      const lastPart = parts[parts.length - 1];
      const fileUrl = `${process.env.REACT_APP_CDN_URL}/${lastPart}`;
      setUrl(fileUrl);
    }
  }, [loaded]);

  const handleReset = () => {
    setPlaying(false);
  };

  const handlePlayPause = () => {
    setPlaying(!playing);
    if (!loaded) {
      console.log('loaded');
      setLoaded(true);
    }
    if (playing) {
      playerRef.current?.getInternalPlayer()?.play();
    } else {
      playerRef.current?.getInternalPlayer()?.pause();
    }
  };

  return (
    <div className="chat_audio-container">
      <IonRow className="full-width ion-align-items-center ion-justify-content-center">
        <ReactPlayer
          ref={playerRef}
          controls={false}
          playing={playing}
          url={loaded ? url : ''}
          onProgress={({ playedSeconds }) => setPlayedSeconds(playedSeconds)}
          onSeek={setPlayedSeconds}
          onDuration={setDurationSeconds}
          height={'0px'}
          width={'100%'}
          onEnded={handleReset}
        />
        <Controls
          playerRef={playerRef}
          playing={playing}
          setPlaying={setPlaying}
          playedSeconds={playedSeconds}
          duration={durationSeconds}
          seek={seek}
          handlePlayPause={handlePlayPause}
          url={url}
        />
      </IonRow>
    </div>
  );
};

export default AudioWaveAndroid;

interface ControlsProps {
  playing: boolean;
  setPlaying: Dispatch<SetStateAction<boolean>>;
  playedSeconds: number;
  duration: number;
  playerRef: MutableRefObject<ReactPlayerProps>;
  seek: (e: number) => void;
  url: string;
  handlePlayPause: () => void;
}

const Controls: React.FC<ControlsProps> = ({
  setPlaying,
  playing,
  playedSeconds,
  duration,
  seek,
  handlePlayPause,
  url,
}) => {
  const progressBarStyle = {
    height: '5px',
    backgroundColor: '#d3d3d3',
    borderRadius: '10px',
    width: '90%',
    margin: '0 auto',
  };

  const progressBarFilledStyle = {
    height: '100%',
    width: `${(playedSeconds / duration) * 100}%`,
    backgroundColor: url ? '#03b070' : '#dfeeeb',
    borderRadius: '10px',
  };

  return (
    <IonGrid className="full-width ion-no-padding ion-no-margin ion-align-items-center ion-justify-content-center">
      <IonRow className="full-width ion-align-items-center ion-justify-content-center ion-no-padding ion-no-margin">
        <IonCol size="2" className="ion-padding-right">
          <IonButton fill="clear" onClick={handlePlayPause}>
            <IonIcon size="medium" src={playing ? pauseOutline : playAudioIcon} />
          </IonButton>
        </IonCol>
        <IonCol size="8.5">
          <div
            style={progressBarStyle}
            onClick={(e) => {
              const clickPosition = e.pageX - e.currentTarget.getBoundingClientRect().left;
              const newPosition = (clickPosition / e.currentTarget.offsetWidth) * duration;
              seek(newPosition);
            }}
          >
            <div style={progressBarFilledStyle}></div>
          </div>
        </IonCol>
        <IonCol size="1.5" className="ion-text-right">
          {!!duration && <div className="chat_audio-container__duration">{duration.toFixed(2)}s</div>}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
