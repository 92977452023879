import { IonCol, IonGrid, IonIcon, IonRow, IonText } from '@ionic/react';
import { __ } from '../../../utils/traduction';

interface INotifProps {
  message: string;
  textColor: string;
  bgColor?: string;
  icon: any;
  borderClass?: string;
  paddingLeft?: string;
  marginBottom?: string;
  iconSize?: string;
  textMarginTop?: string;
  textSize?: string;
  isTextBolder?: boolean;
  notificationPadding?: string;
}

export const NotificationSection: React.FC<INotifProps> = ({
  message,
  textColor,
  bgColor,
  icon,
  borderClass,
  paddingLeft,
  iconSize,
  isTextBolder = false,
  textMarginTop,
  textSize,
  notificationPadding = '10px 15px',
  marginBottom = 'ion-margin-bottom',
}) => {
  return (
    <IonGrid className={marginBottom}>
      <IonRow
        className={`border-radius-10 ion-justify-content-center ion-align-text-center text-size-xs ${
          borderClass ? borderClass : ''
        }`}
        style={{
          backgroundColor: bgColor,
          padding: notificationPadding,
        }}
      >
        <IonCol
          size="1"
          style={{ display: 'flex' }}
          className="ion-no-padding ion-justify-content-center ion-align-items-center"
        >
          <IonIcon size={iconSize} icon={icon} className="text-size-lg" style={{ color: textColor }}></IonIcon>
        </IonCol>
        <IonCol
          style={{
            marginTop: textMarginTop,
          }}
          className="text-capitalize ion-no-padding"
        >
          <IonText
            style={{
              color: textColor,
              fontWeight: isTextBolder ? 'bolder' : '',
              fontSize: textSize,
              paddingLeft: `${paddingLeft ? paddingLeft : '10px'}`,
              display: 'block',
            }}
            className="text-capitalize font-outfit"
          >
            {__(message)}
          </IonText>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
