import { IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { ProfilLock, VideoView } from '../../components';
import '../../';
import CardPost from '../../components/Algolia/CardPost/CardPost';
import { PostEntity } from '../../models/gql/graphql';
import { useAppStore } from '../../store';
import SimpleHeader from '../../components/SimpleHeader';

const PostDetail: React.FC = () => {
  const { id } = useParams<IVerifParams>();
  const location = useLocation();
  const {
    RootStore: { userData, getAlgoliaClient },
  } = useAppStore();
  const [post, setPost] = useState<any>();
  const searchClient = getAlgoliaClient();
  useEffect(() => {
    const run = async () => {
      const index = searchClient.initIndex((process.env.REACT_APP_ALGOLIA_INDEX as string).replace('event', 'post'));
      const prefix = (process.env.REACT_APP_ALGOLIA_INDEX as string).replace('event', '');
      console.log(prefix, id);
      console.log((prefix || '_') + id);
      const a = await index.getObject((prefix || '_') + id);
      console.log(a);
      setPost(a);
    };
    if (searchClient && id) {
      console.log({ id });
      run();
    }
  }, []);

  return (
    <IonPage>
      <IonContent>
        <SimpleHeader title="" noButtonBack={false} showMenuButton={true} backButtonColor="text-black"></SimpleHeader>

        <IonGrid>
          <CardPost
            id={id}
            medias={post?.medias}
            description={post?.description}
            creatorId={post?.creatorId}
            creatorFirstname={post?.creatorFirstname}
            creatorLastname={post?.creatorLastname}
            commentsCount={post?.commentsCount}
            creatorUsername={post?.creatorUsername}
            isPro={post?.isPro}
            creatorCompanyName={post?.creatorCompanyName}
            creatorPicture={post?.creatorPicture}
            nbComments={post?.nbComments}
            nbLikes={post?.nbLikes}
            myLikes={post?.myLikes}
            setFiles={post?.setFiles}
            refetchLike={post?.refetchLike}
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PostDetail;
