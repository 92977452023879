import { IonContent, IonPage, IonText, IonGrid, IonRow, IonCol } from '@ionic/react';
import { Header, ButtonLarge } from '../../../../components';
import { useHistory } from 'react-router-dom';
import '../style.css';
export interface IWording {
  buttons: IIdNamebOject[];
}
export interface IChooseTypeProps {
  wording: IWording;
}
const ChooseType: React.FC<IChooseTypeProps> = ({ wording }) => {
  const history = useHistory();
  const goTo = (id: string) => {
    history.push(`/signup/type/${id}`);
  };

  return (
    <IonPage>
      <Header
        title="Inscription"
        noButtonBack={false}
        action={async () => {
          history.replace('/on-boarding');
        }}
      />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText>
                <h2 className="font-outfit">Vous êtes...</h2>
              </IonText>
            </IonCol>
          </IonRow>
          {wording?.buttons.map((button) => (
            <IonRow key={button.id}>
              <IonCol>
                <ButtonLarge
                  className="btn-height-choose"
                  fill="outline"
                  color="secondary"
                  text={button.name}
                  onClick={() => goTo(button.id)}
                ></ButtonLarge>
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ChooseType;
