import { IonCol, IonLabel, IonRow } from '@ionic/react';
import { useInstantSearch } from 'react-instantsearch';

const NoResultsBoundary = ({ children, fallback }: any) => {
  const { results, status } = useInstantSearch();

  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned.
  if (!results.__isArtificial && results.nbHits === 0 && status === 'idle') {
    return (
      <>
        <IonRow>
          <IonCol className="ion-text-center ion-padding-top">
            {fallback ? fallback : <IonLabel>Aucune items ne correspond à ta recherche.</IonLabel>}
          </IonCol>
        </IonRow>

        <div hidden>{children}</div>
      </>
    );
  }

  return children;
};
export default NoResultsBoundary;
