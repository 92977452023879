import { IonText } from '@ionic/react';
import { InterestEntity } from '../../models/gql/graphql';
import './style.css';

interface IInterestProps {
  interest?: InterestEntity;
}

const InterestChip: React.FC<IInterestProps> = ({ interest }) => {
  return (
    <div className="container-interest">
      <div className="container-picto-interest">
        <img
          alt="picto"
          src={
            interest?.attributes?.picto?.data
              ? interest?.attributes?.picto?.data.attributes?.url
              : 'https://ionicframework.com/docs/img/demos/avatar.svg'
          }
          className="img-picto"
        />
      </div>
      <IonText className="font-interest-picto font-outfit">{interest?.attributes?.name}</IonText>
    </div>
  );
};

export default InterestChip;
