import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonRow,
  IonProgressBar,
  IonText,
} from '@ionic/react';
import { link, mailOutline } from 'ionicons/icons';
import ListWithImage from '../ListWithImage';
import ListWithImageTime from '../ListWithImageTime';
import UserDetail from '../UserDetail';
import DispoHeaderProps from '../DispoHeaderProps';
import VideoView from '../VideoView';
import SliderMedia from '../SliderMedia';
import calendarSvg from '../../assets/icons/calendar.svg';
import shareSvg from '../../assets/icons/share.svg';
import pinSvg from '../../assets/icons/pin.svg';
import { Enum_Event_Type, EventInput, UsersPermissionsMe } from '../../models/gql/graphql';
import { convertDate, convertTime, convertTimeForGraphql } from '../../utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { handleType } from '../../store/AppReducerProvider/type';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import '@ionic/react/css/ionic-swiper.css';
import './style.css';

interface IEventDetails {
  creator: Maybe<UsersPermissionsMe>;
  event: EventInput;
  imageSelecteds: any;
  imageUrl: any;
  maxInvited?: number;
  numberInside?: number;
}

const CardEventDetail: React.FC<IEventDetails> = ({
  creator,
  event,
  imageSelecteds,
  imageUrl,
  maxInvited,
  numberInside,
}) => {
  let bkColor = event ? (event.type === Enum_Event_Type.Private ? 'rgba(61, 39, 235)' : 'rgba(4, 177, 113)') : '';

  const checkIfForOneDay = () => {
    if (event.eventDate && event.eventEndDate) {
      const start = new Date(event.eventDate);
      const end = new Date(event.eventEndDate);
      return (
        start.getDate() === end.getDate() &&
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear()
      );
    }
    return false;
  };

  return (
    <IonContent>
      <div className="container-view">
        <DispoHeaderProps
          imageUrl={imageUrl}
          eventTypeProps={event.type as Enum_Event_Type}
          eventNameProps={event.name || ''}
        ></DispoHeaderProps>

        <div style={{ background: bkColor, height: '200px' }} className="width-100-percent"></div>
        <div className="ion-no-margin width-100-percent" style={{ padding: '10px', marginTop: '-190px' }}>
          <IonCard className="border-radius box-shadow-custom ion-no-margin padding-card-detail">
            <IonCardContent className="ion-no-padding">
              {!!creator && (
                <UserDetail
                  user={creator}
                  avatarStyle="width-40 height-40"
                  nameStyle="text-gray-400 text-size-lg padding-2"
                />
              )}
              {!event.eventEndDate && (
                <ListWithImage
                  description={convertDate(event.eventDate)}
                  description2={`${convertTime(event.startHour)} à ${convertTime(event.endHour)}`}
                  image={calendarSvg}
                />
              )}
              {event.eventEndDate && (
                <ListWithImageTime
                  isOneDay={checkIfForOneDay()}
                  date={convertDate(event.eventDate)}
                  dateEnd={convertDate(event.eventEndDate)}
                  time={convertTime(event.startHour)}
                  timeEnd={convertTime(event.endHour)}
                  image={calendarSvg}
                />
              )}

              <ListWithImage description={event.addressCity} description2={event.addressLine} image={pinSvg} />
              <ListWithImage description="Partager l'événement" image={shareSvg} />
            </IonCardContent>
          </IonCard>
          <IonCard className="border-radius box-shadow-custom ion-no-margin ion-margin-top padding-card-detail">
            <IonCardContent className="ion-no-padding">
              <IonText color="dark" className="font-outfit">
                <h3 className="subtitle-card-detail">A propos</h3>
                <h3 className="title-card-detail">Les mots de l'organisateur</h3>
                <p dangerouslySetInnerHTML={{ __html: event?.description as string }}></p>
              </IonText>
              {imageSelecteds && <SliderMedia itemsAny={imageSelecteds}></SliderMedia>}
            </IonCardContent>
          </IonCard>
          {maxInvited && numberInside && (
            <IonCard className="border-radius box-shadow-custom ion-no-margin ion-margin-top padding-card-detail">
              <IonCardContent className="ion-no-padding">
                <IonText color="dark" className="font-outfit">
                  <h3 className="title-card-detail">Les mots de l'organisateur</h3>
                  <p></p>
                  <IonProgressBar className="progress-bar-custom" value={numberInside / maxInvited}></IonProgressBar>
                </IonText>
              </IonCardContent>
            </IonCard>
          )}
        </div>
      </div>
    </IonContent>
  );
};

export default CardEventDetail;
