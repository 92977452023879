import { FC } from 'react';
import './style.css';
import { IonButton, IonIcon } from '@ionic/react';
import fileIcon from '../../../../assets/icons/fileIcon.svg';
import yellowCloseIcon from '../../../../assets/icons/yellowClose.svg';

interface IProps {
  file: any;
  onDeleteFile: any;
  index: number;
}

const FileItem: FC<IProps> = ({ file, onDeleteFile, index }) => {
  const handleDelete = () => {
    onDeleteFile(index);
  };

  const fileSize = Math.floor(file?.file?.size / 1024);

  return (
    <div className="chat__message-box__file-item-container">
      <div>
        <IonIcon icon={fileIcon} size="large" />
      </div>
      <div className="chat__message-box__file-item-content-container">
        <div className="chat__message-box__fileinfo chat__message-box__fileinfo__main">{file?.file?.name}</div>
        <div className="chat__message-box__fileinfo chat__message-box__fileinfo__meta">PDF - {fileSize} Ko.</div>
      </div>
      <IonButton
        className="chat__msg-file__file-close-btn-container ion-no-padding"
        onClick={handleDelete}
        fill="clear"
      >
        <IonIcon size="small" icon={yellowCloseIcon} />
      </IonButton>
    </div>
  );
};

export default FileItem;
