import { IonIcon, IonImg } from '@ionic/react';
import styled from 'styled-components';

export interface IValidatedIconProps {
  path?: string;
  icon?: any;
  height?: number;
  warningColor?: boolean;
}

const ValidatedIcon: React.FC<IValidatedIconProps> = ({ path, height = 50, icon, warningColor }) => {
  return (
    <ValidatedIconWrapper>
      <div className={warningColor ? 'warn' : ''}>
        {path && <IonImg src={path} style={{ height }} />}
        {icon && <IonIcon icon={icon} />}
      </div>
    </ValidatedIconWrapper>
  );
};

export default ValidatedIcon;
export const ValidatedIconWrapper = styled('div')`
  display: flex;
  width: auto;
  text-align: center;
  justify-content: center;
  > div {
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 104px;
    height: 104px;

    /* Charte/Bleu Light */

    background: #fae4f5;
    border-radius: 500px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    ion-icon {
      width: 30px;
      height: 30px;
      color: #ff99cf;
    }
    &.warn {
      background: #feefee !important;
      ion-icon {
        color: #e87563;
      }
    }
  }
`;
