import { useEffect, useMemo, useState } from 'react';
import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { useMessenger } from '../../hooks/useMessenger';
import { useQuery } from '@apollo/client';
import { GET_USER, GET_USER_MAIN_INFO } from '../../graphql/queries/getUser.graphql';
import { GET_ME_MAIN_INFO } from '../../graphql/queries/user.graphql';
import { db } from '../../config';
import { UsersPermissionsUser } from '../../models/gql/graphql';
import { getUserId } from '../../utils';
import { createLastSenderData, getChatUserData, getDispoImageUrl } from '../../utils/chat';
import { useNotifications } from '../../hooks/useNotifications';
import uniq from 'lodash/uniq';
import { VoiceRecorder } from 'capacitor-voice-recorder';

export interface IMessengerStore {
  msg: IMsg[];
  selected: string | null;
  msgItem: any[];
  setMsgItem: (val: any) => void;
  handleSelectConversation: (msgObj: IMsg, cb?: any) => Promise<void>;
  loadMore: (id: any) => Promise<any[]>;
  resetSelection: (cb?: any) => Promise<void>;
  sendMessage: (body: string, options?: any, cb?: any) => Promise<void>;
  sendMessageToGroup: (body: string, options?: any, cb?: any) => Promise<void>;
  sendDirectMessage: (msgObj: IMsg, body: string, options?: IMsgOptionsInfo) => Promise<void>;
  markUnseenFromUser: (messageId: string, userId: string) => Promise<void>;
  peer: any;
  user: any;
  createId: (id1: string, id2: string) => string;
  loadSelectedChat: (sel: any, callback: (ar: any[]) => void) => any;
  setLastItem: (item: any) => void;
  setPeer: (item: any) => void;
  setSelected: (item: any) => void;
  setMsg: (item: any) => void;
  SearchMessageByKeyword: (options: any) => Promise<void>;
  editMessage: (options: any) => Promise<void>;
  deleteMessage: (options: any) => Promise<void>;
  setParticipants: (data: any) => void;
  setCreator: (data: any) => void;
  setDispo: (data: any) => void;
  participants: any;
  creator: any;
  dispo: any;
  respondToMessage: any;
  setSelectedMsg: any;
  respondToGroupMessage: any;
  isLoading: boolean;
  refetchPeerUser: any;
  selectedMsg: any;
  refetchMsgList: boolean;
  setRefetchMsgList: (refetch: boolean) => void;
  loadAllMessages: any;
  createChat: (data: any) => void;
  dataFixed: any;
  markAsSeen: (options: any) => void;
}

/**
 * Messenger hooks handler
 * @param auth            Authentication data
 * @returns
 */
const useMessengerStore = (isDispo: boolean, chatId?: string) => {
  const { data: dataMe, loading: loadingMe, refetch: refetchMe } = useQuery(GET_ME_MAIN_INFO);
  const { decrementNewMessage } = useNotifications();
  const userData = dataMe?.me;

  // Message array container
  const [msg, setMsg] = useState<IMsg[]>([]);
  // Selected Chat (an array that contains current user id and the id of the other side >_<)
  const [selected, setSelected] = useState<string | null>(null);
  const [selectedMsg, setSelectedMsg] = useState<any>(undefined);
  // Contains the receiver data info
  const [peer, setPeer] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [participants, setParticipants] = useState<any>([]);
  const [creator, setCreator] = useState<any>(null);
  const [dispo, setDispo] = useState<any>(null);
  const [isMicroAuthorized, setMicro] = useState<boolean>(false);
  const [isOpenError, setIsOpenError] = useState<boolean>(false);

  // Current chat message body array
  const [msgItem, setMsgItem] = useState<IMsgOptions[]>([]);
  const [lastItem, setLastItem] = useState<IMsgOptions>();
  const [refetchMsgList, setRefetchMsgList] = useState(false);
  const [dataFixed, setDataFixed] = useState<IMsg[]>([]);

  const {
    data: userPeer,
    loading: loadingUserData,
    refetch,
  } = useQuery(GET_USER, {
    variables: { id: peer?.id || '0' },
    skip: !peer,
  });

  const peerData = useMemo(() => {
    return (
      userPeer && !loadingUserData ? userPeer?.usersPermissionsUser?.data?.attributes : []
    ) as UsersPermissionsUser;
  }, [userPeer, loadingUserData]);

  const {
    MarkAsSeen,
    Send,
    ReadMsgList,
    GetId,
    MarkAsUnseenFromUser,
    LoadMore,
    ReadSelectedMsg,
    EditMessage,
    DeleteMessage,
    GetAllMessages,
    GenerateMessageId,
    CreateChat,
  } = useMessenger();

  const {
    data: dataPeerUser,
    loading: loadingPeerUser,
    refetch: refetchPeerUser,
  } = useQuery(GET_USER_MAIN_INFO, {
    variables: { id: chatId || '0' },
    skip: !chatId,
  });

  const peerUser = dataPeerUser?.usersPermissionsUser?.data;

  /**
   * handle Selection on click
   * @param msgObj              Object msg from selection or created on 'Contacter'
   * @param cb                  callback function if succeed
   */
  const handleSelectConversation = async (msgObj: IMsg, cb?: any) => {
    if (msgObj?.isDispo) {
      setDispo(msgObj);
      if (
        msgObj.lastSender &&
        Array.isArray(msgObj?.seens) &&
        !msgObj?.seens?.includes(user?.id) &&
        msgObj?.lastSender?.id !== user?.id
      ) {
        MarkAsSeen({
          msgId: msgObj.id,
          userId: user?.id,
          seens: uniq([...msgObj.seens, user.id]),
        });
      }
      if (cb) cb(msgObj.id);
    } else {
      if (user) {
        const peerId = msgObj.participantsIds.find((_id: string) => _id !== user?.id);

        if (peerId) {
          setPeer(msgObj[peerId]);
          setSelected(msgObj.id);
          setSelectedMsg(msgObj);
          if (
            msgObj.lastSender &&
            Array.isArray(msgObj?.seens) &&
            !msgObj?.seens?.includes(user?.id) &&
            msgObj?.lastSender?.id !== user?.id
          ) {
            MarkAsSeen({
              msgId: msgObj.id,
              userId: user?.id,
              seens: uniq([...msgObj.seens, user.id]),
            });
          }
          if (cb) cb(peerId);
        }
        decrementNewMessage();
        if (cb) cb(peerId);
      }
    }
  };

  /**
   * Reset selection
   * @param cb                callback function after reset
   */
  const resetSelection = async (cb?: any) => {
    setPeer(null);
    setSelected('');
    setSelectedMsg(undefined);
    if (cb) cb();
  };

  // send a message
  /**
   * Send new message into the current conversation
   * @param body                    Message body
   * @param cb                      callback function if succeed
   */
  const sendMessage = async (body: string, options?: any, cb?: any) => {
    const participantsIds = dispo
      ? participants.map((p: any) => p?.id)?.filter((e: any) => e)
      : [user?.id, peer?.id]?.filter((e) => e);

    if (!options?.msgId || participantsIds < 2) {
      throw new Error('participants should be more than 2');
    }

    const id = dispo ? `dispo_${options?.msgId}` : GenerateMessageId([peer.id, user.id]);
    const fromId = user?.id;
    const msgId = id;
    const lastSender = createLastSenderData(user);

    let baseMsgData: any = {
      id,
      body,
      lastbody: body,
      fromId,
      msgId,
      participantsIds,
      lastSender,
    };

    if (options?.files?.length > 0) {
      baseMsgData = {
        ...baseMsgData,
        files: options?.files,
        type: 'file',
      };
    }

    if (options.parentId) {
      baseMsgData = {
        ...baseMsgData,
        parentId: options.parentId,
        isResponse: true,
      };
    }

    if (dispo) {
      // chat type is dispo
      const dispoImageUrl = getDispoImageUrl(dispo);

      let msgObj: any = {
        ...baseMsgData,
        [user.id]: getChatUserData(user),
        dispoName: dispo?.attributes?.name,
        dispoImageUrl,
        msgId,
        type: options?.type,
        seen: false,
        isDispo: true,
        seens: [user.id],
      };

      Send(msgObj)
        .then((result) => {
          if (cb) cb(result);
        })
        .catch((err: any) => {
          console.error('Error on sending message ', err);
        });
    } else {
      // chat type is one to one
      let msgObj = {
        ...baseMsgData,
        toId: peer?.id,
        [user.id]: getChatUserData(user),
        [peer.id]: getChatUserData(peer),
        blocked: peerData?.blockedUserIds && peerData?.blockedUserIds.includes(getUserId()) ? getUserId() || '0' : '0',
        seens: [user.id],
      };

      Send(msgObj)
        .then((result) => {
          if (cb) cb(result);
        })
        .catch((err: any) => {
          console.error('Error on sending message ', err);
        });
    }
  };

  const createChat = async (data: any) => {
    CreateChat(data);
  };

  // create id
  const createId = (id1: any, id2: any) => GetId(id1, id2);

  const markUnseenFromUser = (messageId: string, userId: string) => {
    MarkAsUnseenFromUser(messageId, userId);
  };

  const editMessage = (options: any, cb: any) => {
    EditMessage({ ...options, msgId: isDispo ? `dispo_${options?.msgId}` : options?.msgId })
      .then((result: any) => {
        if (cb) cb(result);
      })
      .catch((err: any) => {
        console.error('Error on editing message ', err);
      });
  };

  const deleteMessage = (options: any) => {
    DeleteMessage({
      ...options,
      msgId: isDispo ? `dispo_${options?.msgId}` : options?.msgId,
      lastMsgItemId: selectedMsg?.lastMsgItemId,
      msgs: msgItem,
    });
  };

  const loadSelectedChat = (sel: any, callback: (ar: any) => void, size: number) => {
    return ReadSelectedMsg(
      {
        msgId: sel,
        onResolve: (d: any, li: any) => {
          setLastItem(li);
          callback(d);
        },
      },
      size
    );
  };

  const loadAllMessages = (sel: any, callback: (ar: any) => void) => {
    return GetAllMessages({
      msgId: sel,
      onResolve: (d: any, li: any) => {
        setLastItem(li);
        callback(d);
      },
    });
  };

  const loadMore = (id: any) => {
    const selId = createId(user?.id, id);
    return new Promise((resolve, reject) => {
      if (!selId) return;
      LoadMore(
        {
          msgId: selId,
          onResolve: (d, li) => {
            if (!d || !d.length) {
              setLastItem(undefined);
            } else {
              setLastItem(li);
            }
            if (!d || !d.length) {
              resolve(null);
            }
            resolve(d.reverse());
          },
          onError: (e) => {
            reject(e);
          },
        },
        25,
        lastItem
      );
    });
  };

  const fetchCurrentMessage = () => {
    const msgRef = doc(db, 'messages', selected as any);

    const unsub = onSnapshot(msgRef, (doc: any) => {
      setSelectedMsg(doc.data());
    });

    return unsub;
  };

  // listen to auth state change and start or stop messaging listening;
  useEffect(() => {
    if (user || refetchMsgList) {
      let unsub: Unsubscribe | null = null;
      if (user) {
        unsub = ReadMsgList({
          user: user.id as string,
          onResolve: (data) => {
            setMsg(data);
          },
          onError: (err) => console.log('error read', err),
        });
        if (refetchMsgList) {
          setRefetchMsgList(false);
        }
      }
      return () => {
        if (unsub) unsub();
      };
    }
  }, [user, refetchMsgList]);

  useEffect(() => {
    if (user) {
      let unsub: Unsubscribe | null = null;
      if (user) {
        unsub = ReadMsgList({
          user: user.id as string,
          onResolve: (data) => {
            setDataFixed(data);
          },
          onError: (err) => console.log('error read', err),
        });
      }
      return () => {
        if (unsub) unsub();
      };
    }
  }, [user]);

  useEffect(() => {
    setPeer(peerUser);
  }, [peerUser]);

  useEffect(() => {
    setUser(userData);
  }, [userData]);

  useEffect(() => {
    if (chatId) {
      refetchPeerUser();
    }
  }, [user?.id, chatId]);

  useEffect(() => {
    let unsub: any;
    if (selected && !isDispo) {
      const fetchMessage = async () => {
        return await fetchCurrentMessage();
      };
      unsub = fetchMessage();
    }
    return () => {
      if (typeof unsub === 'function') unsub();
    };
  }, [selected, isDispo]);

  const requestMicrophonePermission = async () => {
    const result = await VoiceRecorder.hasAudioRecordingPermission();
    if (!result.value) {
      const permissionRequest = await VoiceRecorder.requestAudioRecordingPermission();
      setMicro(permissionRequest.value);
      if (!permissionRequest.value) {
        setIsOpenError(true);
      }
    } else {
      setMicro(true);
    }
  };

  useEffect(() => {
    requestMicrophonePermission();
  }, []);

  return {
    msg,
    msgItem,
    setMsgItem,
    selected,
    resetSelection,
    handleSelectConversation,
    loadMore,
    sendMessage,
    peer,
    user,
    createId,
    markUnseenFromUser,
    loadSelectedChat,
    setLastItem,
    setPeer,
    setSelected,
    setMsg,
    editMessage,
    deleteMessage,
    setParticipants,
    setCreator,
    setDispo,
    participants,
    creator,
    dispo,
    setSelectedMsg,
    selectedMsg,
    isLoading: loadingMe || loadingPeerUser,
    refetchPeerUser,
    refetchMsgList,
    setRefetchMsgList,
    loadAllMessages,
    createChat,
    dataFixed,
    setMicro,
    isMicroAuthorized,
    isOpenError,
    setIsOpenError,
    markAsSeen: MarkAsSeen,
  } as any;
};

export default useMessengerStore;
