import { useMutation, useQuery } from '@apollo/client';
import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow, IonText, IonButton } from '@ionic/react';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonGroup, CardCustom, Header, StepperCount } from '../../../../components';
import { UPDATE_ME } from '../../../../graphql/mutations/auth.graphql';
import { GET_AVATARS } from '../../../../graphql/queries/avatars.graphql';
import { UsersPermissionsUserInput } from '../../../../models/gql/graphql';
import './style.css';

const ConfigAvatar: React.FC = () => {
  const { data: avatarsData, loading: loadingAvatars } = useQuery(GET_AVATARS);

  const [updateMe, { loading }] = useMutation(UPDATE_ME);

  const [avatarSelected, setAvatarSelected] = useState<string>();

  const history = useHistory();

  const selectImage = (id: string) => {
    setAvatarSelected(id);
  };
  const onSubmit = async () => {
    const data: UsersPermissionsUserInput = {
      avatar: avatarSelected,
      profilePicture: null,
    };
    const resp = await updateMe({ variables: { data: data } });
    history.push('/signup/config-contact');
  };

  const avatars = useMemo(() => {
    return !loadingAvatars ? avatarsData?.avatars?.data : [];
  }, [loadingAvatars, avatarsData]);

  const goToPDP = () => {
    history.push('/signup/config-pdp');
  };

  return (
    <IonPage>
      <Header title="Configuration de compte" noButtonBack={false} />
      <StepperCount length={3} numberStep={3}></StepperCount>
      <IonContent className="ion-text-left ion-padding">
        <CardCustom>
          <IonText color="dark" className="font-outfit">
            <h3>Selectionne ton avatar</h3>
          </IonText>
          <IonGrid>
            <IonRow>
              {avatars?.map((avatar) => (
                <IonCol
                  key={avatar.id}
                  size="4"
                  onClick={() => selectImage(avatar.id as string)}
                  className={`container-row ${avatarSelected === avatar.id ? 'border-image' : ''}`}
                >
                  <IonImg
                    className="fullContent"
                    src={avatar?.attributes?.image?.data?.attributes?.url}
                    alt="image pdp"
                  ></IonImg>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
          <IonButton expand="block" fill="outline" onClick={goToPDP} className="ion-margin-top">
            Utiliser une photo
          </IonButton>
          <IonButton className="ion-margin-top" expand="block" onClick={onSubmit} disabled={loading || !avatarSelected}>
            Continuer
          </IonButton>
        </CardCustom>
      </IonContent>
    </IonPage>
  );
};

export default ConfigAvatar;
