import { useLazyQuery, useQuery } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import {
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { add, closeCircleOutline } from 'ionicons/icons';
import { map } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { VideoView } from '../../../components';
import { ChooseFileModal, ComponentModal } from '../../../components/Modals';
import { GET_EVENT } from '../../../graphql/queries/event.graphql';
import { GET_IMAGES } from '../../../graphql/queries/image.graphql';
import { useDispo } from '../../../hooks';
import { Enum_Event_Type, EventQuery } from '../../../models/gql/graphql';
import { compressImage } from '../../../utils/file';
import './style.css';

type handleType = {
  file: HTMLInputElement | null;
  idImage: string | null;
  url: string | null;
};
interface IProps {
  handleChangeMulti: (images: handleType[], index?: number) => void;
  defaultImgUrl?: string | null;
  isMain?: boolean;
  resetImg?: any;
  type?: string;
  file: any;
  previewUrl: string;
}

const SelectImage: React.FC<IProps> = ({
  handleChangeMulti,
  defaultImgUrl,
  isMain = false,
  resetImg,
  type,
  file,
  previewUrl,
}) => {
  const { id } = useParams<IVerifParams>();
  const { data: imagesData, loading: loadingImages } = useQuery(GET_IMAGES);
  const [image, setImage] = useState<any>(null);
  const [imageSelected, setImageSelected] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openFileModal, setOpenFileModal] = useState<boolean>(false);
  const [openComponentModal, setOpenComponentModal] = useState<boolean>(false);
  const [dispoData, setDispoData] = useState<EventQuery>({});
  const refBtnPick = useRef<HTMLInputElement>(null);
  const page = useRef(null);
  let { dispo } = useDispo();
  const [getEvent, { loading: loadingEvent }] = useLazyQuery(GET_EVENT);

  const images = useMemo(() => {
    return !loadingImages ? imagesData?.images?.data : [];
  }, [loadingImages, imagesData]);

  const clickFile = () => {
    if (refBtnPick.current) {
      refBtnPick.current.click();
    }
  };

  const changeFile = async (files: FileList) => {
    const promises = await Promise.all(
      map(files, async (i) => {
        if (i?.type?.substring(0, 5) === 'image') {
          return await compressImage(i);
        } else {
          return i;
        }
      })
    );
    const imageMapped = map(promises, (image: any) => {
      return {
        file: image,
        url: URL.createObjectURL(image),
        idImage: null,
      };
    });

    if (!imageMapped) return;

    // setImage({ url: imageMapped[0].url, idImage: null });
    handleChangeMulti(imageMapped);

    dismiss();
  };

  const handleSelectImage = (img: any) => {
    setImageSelected({ url: img?.attributes?.image?.data?.attributes?.url, idImage: img.id, file: { type: 'image' } });
  };

  const chooseImage = () => {
    setImage(imageSelected);
    handleChangeMulti([imageSelected]);
    dismiss();
  };

  const cancel = () => {
    setIsOpen(false);
  };

  const openFileUpload = (e: any) => {
    if (image !== null) {
      if (!!image && 'url' in image && !!image.url) return;
    }
    if (Capacitor.isNativePlatform()) {
      setOpenFileModal(true);
    } else {
      setOpenComponentModal(true);
    }
  };

  const onSelectFile = ({ target: { validity, files } }: any) => {
    if (validity.valid) {
      if (changeFile) changeFile(files);
    }
  };
  const dismiss = () => {
    setOpenComponentModal(false);
    setOpenFileModal(false);
    setIsOpen(false);
  };

  const resetImage = (e: any, image: handleType) => {
    e.stopPropagation();
    setImage(null);
    if (resetImg) resetImg(0, image);
  };

  useEffect(() => {
    setImage({ url: defaultImgUrl, idImage: null });
  }, [defaultImgUrl]);

  const getEventDetail = async (id: string) => {
    const { data: eventDetail } = await getEvent({ variables: { id: id } });
    return eventDetail;
  };

  useEffect(() => {
    if (id) {
      const consteventDetail = getEventDetail(id);
      setDispoData((consteventDetail || {}) as EventQuery);
    }
  }, [id]);

  return (
    <>
      <div className="imgPDP-container" onClick={openFileUpload}>
        {image?.url ? (
          type?.substring(0, 5) === 'image' ? (
            <IonImg src={image?.url || defaultImgUrl} alt="image pdp"></IonImg>
          ) : (
            <VideoView url={image?.url} autoPlay={true} file={file} previewUrl={previewUrl}></VideoView>
          )
        ) : (
          <IonIcon icon={add} size="large"></IonIcon>
        )}
        {image && image?.url && (
          <IonIcon
            slot="icon-only"
            className="icon-close"
            icon={closeCircleOutline}
            onClick={(e) => resetImage(e, image)}
          ></IonIcon>
        )}
        {isMain && (
          <IonChip className="tag-position tag-color-main font-outfit" color="primary">
            Image principale {image?.file?.type.substring(0, 5)}
          </IonChip>
        )}
      </div>
      <ChooseFileModal
        isOpen={openFileModal}
        onCancel={() => setOpenFileModal(false)}
        onChangeFile={changeFile}
        textThirdBtn={dispo.type === Enum_Event_Type.Private && isMain ? 'Utiliser une image Tedispo' : undefined}
        onClickThirdBtn={(data: boolean) => setIsOpen(data)}
        useVideo={!isMain}
        title={isMain ? 'Ajoute une image de couverture à ta dispo' : 'Ajoute un média'}
      />
      <input
        type="file"
        accept={isMain ? 'image/*' : '*'}
        ref={refBtnPick}
        onChange={onSelectFile}
        className="ion-hide"
        multiple={true}
      />
      <ComponentModal
        isOpen={openComponentModal}
        onCancel={() => setOpenComponentModal(false)}
        height={
          (dispo.type === Enum_Event_Type.Private ||
            (dispoData && dispoData.event?.data?.attributes?.type === Enum_Event_Type.Private)) &&
          isMain
            ? '350px'
            : '280px'
        }
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle class="ion-text-center">Ajoute une image de couverture à ta dispo</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonButton onClick={clickFile} className="custom-button ion-margin-top" expand="block" color="primary">
            Accéder à ma galerie photo
          </IonButton>
          <IonButton fill="outline" className="custom-button ion-margin-top" expand="block" color="primary">
            Prendre une photo
          </IonButton>
          {(dispo.type === Enum_Event_Type.Private ||
            (dispoData && dispoData.event?.data?.attributes?.type === Enum_Event_Type.Private)) &&
            isMain && (
              <IonButton
                onClick={() => setIsOpen(true)}
                fill="outline"
                className="custom-button ion-margin-top"
                expand="block"
                color="primary"
              >
                Utiliser une image Tedispo
              </IonButton>
            )}
          <IonButton
            onClick={() => setOpenComponentModal(false)}
            fill="clear"
            className="custom-button ion-margin-top"
            expand="block"
            color="primary"
          >
            Annuler
          </IonButton>
        </IonContent>
      </ComponentModal>
      <ComponentModal isOpen={isOpen} onCancel={() => setIsOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle className="ion-text-center">Selectionner une image Tedispo</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow className="overflow-auto max-height-300 padding-b-200">
              {images?.map((img) => (
                <IonCol
                  key={img.id}
                  size="4"
                  onClick={() => handleSelectImage(img)}
                  className={`container-row ${imageSelected?.idImage === img.id ? 'border-image' : ''}`}
                >
                  <IonImg
                    className="fullContent"
                    src={img.attributes?.image?.data?.attributes?.url}
                    alt="image pdp"
                  ></IonImg>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter className="modal-footer">
          <IonButton
            onClick={chooseImage}
            fill="solid"
            className="custom-button ion-margin-top"
            expand="block"
            color="primary"
          >
            Utiliser cette image
          </IonButton>
          <IonButton
            onClick={cancel}
            fill="clear"
            className="custom-button ion-margin-top"
            expand="block"
            color="primary"
          >
            Annuler
          </IonButton>
        </IonFooter>
      </ComponentModal>
    </>
  );
};

export default SelectImage;
