import { IonList, IonItem } from '@ionic/react';
import { EventUserParticipationEntity } from '../../../models/gql/graphql';
import { UserDetail } from './UserDetail';
import InviteGoodSvg from '../../../assets/icons/inviteGood.svg';
import InviteWaitSvg from '../../../assets/icons/inviteWait.svg';
import InviteWrongSvg from '../../../assets/icons/inviteWrong.svg';

interface IMemberProps {
  participation: EventUserParticipationEntity;
  eventType: string;
  from?: string;
}

export const CardMember: React.FC<IMemberProps> = ({ participation, eventType, from }) => {
  const member = participation.attributes?.user?.data?.attributes;
  const idMember = participation.attributes?.user?.data?.id;
  const status = participation.attributes?.status || 'accepted';

  const dataByStatus = {
    waiting: {
      icon: InviteWaitSvg,
    },
    invited: {
      icon: InviteWaitSvg,
    },
    accepted: {
      icon: InviteGoodSvg,
    },
    refused: {
      icon: InviteWrongSvg,
    },
  };
  const icon = eventType === 'private' ? (dataByStatus as any)[status].icon : null;
  const name = (member?.isPro ? member?.companyName : member?.username) || '';
  return (
    <IonList lines="none" className={`${from === 'invitation' ? 'full' : ''} ion-no-padding`}>
      <IonItem>
        <UserDetail name={name} member={member} avatarStyle="width-30 height-30" icon={icon} idMember={idMember} />
      </IonItem>
    </IonList>
  );
};
