import { useQuery } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { CalendarEventOptions, CapacitorCalendar } from '@safimaya/capacitor-calendar';
import { useMemo } from 'react';
import { GET_EVENTS } from '../graphql/queries/events.graphql';
import { Enum_Eventuserparticipation_Status, Event, EventEntity } from '../models/gql/graphql';
import { getUserId } from '../utils';
import { syncEvent } from './syncEvent';

export const useQueryEvents = () => {
  let userId = getUserId();

  const { data, loading } = useQuery(GET_EVENTS, {
    variables: {
      filters: {
        or: [
          {
            creator: {
              id: {
                eq: userId,
              },
            },
          },
          {
            participations: {
              user: {
                id: {
                  eq: userId,
                },
              },
              status: {
                eq: Enum_Eventuserparticipation_Status.Accepted,
              },
            },
          },
        ],
        expired: {
          eq: false,
        },
      },
      pagination: { limit: 300 },
      sort: ['eventDate:desc'],
    },
  });

  const myEvent: EventEntity[] = useMemo(() => {
    return (!loading && data ? data.events?.data : []) as EventEntity[];
  }, [data, loading]);

  return { data: myEvent, loading };
};

const getCalendarId = (calendars: any) => {
  if (typeof calendars === 'string') {
    calendars = JSON.parse(calendars);
  }

  let defaultCalendarId: string = '';

  for (const calendar of calendars) {
    if ((calendar as any)?.defaultCalendar) {
      defaultCalendarId = calendar.id;
      break;
    }
  }

  if (!defaultCalendarId) {
    defaultCalendarId = calendars[0]?.id;
  }

  return defaultCalendarId;
};

export const BackgroundService = {
  runSynchronisation: async (events: EventEntity[]) => {
    try {
      if (Capacitor.isNativePlatform()) {
        let result: { availableCalendars: { id: string; name: string }[] } = {} as {
          availableCalendars: { id: string; name: string }[];
        };
        try {
          result = await CapacitorCalendar.getAvailableCalendars();
        } catch (e) {}

        if (result?.availableCalendars?.length) {
          let calendarId = getCalendarId(result.availableCalendars);
          if (calendarId) {
            syncEventCalendar(events, calendarId);
          }
        }
      } else {
        console.log('calendar not found');
      }
    } catch (e) {
      console.log('erreur execution');
    }
  },
};

const syncEventCalendar = async (events: EventEntity[], calendarId: string) => {
  const syncPromises = events.map(async (event: EventEntity) => {
    await writeInCalendar(event, calendarId);
  });

  await Promise.all(syncPromises);
};

const writeInCalendar = async (event: EventEntity, calendarId: string) => {
  if (!!!event || !event?.id || !event.attributes) return;
  let {
    name: title,
    description: notes,
    startHour,
    endHour,
    eventDate,
    eventEndDate,
    expired,
  } = event.attributes as Event;

  if (eventDate && startHour && endHour && !!title) {
    if (!eventEndDate) eventEndDate = eventDate;

    const userTimezoneOffset = new Date().getTimezoneOffset();
    const startDate = new Date(`${eventDate}T${startHour}`);
    const endDate = new Date(`${eventEndDate}T${endHour}`);
    startDate.setMinutes(startDate.getMinutes() - userTimezoneOffset);
    endDate.setMinutes(endDate.getMinutes() - userTimezoneOffset);

    const startTimestamp = startDate.getTime();
    const endTimestamp = endDate.getTime();

    if (!isNaN(startTimestamp) && !isNaN(endTimestamp) && startTimestamp < endTimestamp) {
      await syncEvent({
        id: event.id,
        title: title,
        notes: notes,
        startDate: startTimestamp,
        endDate: endTimestamp,
        expired: expired,
        calendar_id: calendarId,
      } as ICreateEvent);
    }
  }
};

export const insertEventToCalendar = async (event: EventEntity) => {
  if (Capacitor.isNativePlatform()) {
    let result: { availableCalendars: { id: string; name: string }[] } = {} as {
      availableCalendars: { id: string; name: string }[];
    };
    try {
      result = await CapacitorCalendar.getAvailableCalendars();
    } catch (e) {}

    if (result?.availableCalendars?.length) {
      if (event) {
        let calendarId = getCalendarId(result.availableCalendars);
        if (calendarId) {
          writeInCalendar(event, calendarId);
        }
      }
    }
  }
};

export const deleteEventToCalendar = async (event: EventEntity) => {
  if (Capacitor.isNativePlatform()) {
    let result: { availableCalendars: { id: string; name: string }[] } = {} as {
      availableCalendars: { id: string; name: string }[];
    };
    try {
      result = await CapacitorCalendar.getAvailableCalendars();
    } catch (e) {}

    if (result?.availableCalendars?.length) {
      let calendarId = getCalendarId(result.availableCalendars);
      if (calendarId) {
        if (!!!event || !event?.id || !event.attributes) return;
        let { name: title } = event.attributes as Event;

        let eventData = {
          id: 'tedispo_' + event.id,
          title: title,
          calendarId: calendarId,
        } as CalendarEventOptions;

        let eventToDelete = await CapacitorCalendar.findEvent(eventData);

        if (eventToDelete && eventToDelete.events && eventToDelete.events.length) {
          eventToDelete.events.forEach(async (eventData: any) => {
            try {
              await CapacitorCalendar.deleteEvent({ id: eventData.id, calendarId: calendarId });
            } catch (e) {
              console.log('Erreur suppression', e);
            }
          });
        }
      }
    }
  }
};

export const editEventToCalendar = async (event: EventEntity) => {
  if (Capacitor.isNativePlatform()) {
    let result: { availableCalendars: { id: string; name: string }[] } = {} as {
      availableCalendars: { id: string; name: string }[];
    };
    try {
      result = await CapacitorCalendar.getAvailableCalendars();
    } catch (e) {}

    if (result?.availableCalendars?.length) {
      let calendarId = getCalendarId(result.availableCalendars);
      if (calendarId) {
        if (!!!event || !event?.id || !event.attributes) return;
        await writeInCalendar(event, calendarId);
      }
    }
  }
};
