import { useMutation, useQuery } from '@apollo/client';
import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonIcon,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonToast,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { InstantSearch } from 'react-instantsearch';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import editBlack from '../../assets/icons/editBlack.svg';
import { ButtonCreate, FullSlider, Header, InterestEdition, PostForm } from '../../components';
import { CustomSearchBox } from '../../components/Algolia';
import { AlgoliaUserAvatar } from '../../components/Algolia/AlgoliaUser';
import { ComponentModal } from '../../components/Modals';
import { UPDATE_ME } from '../../graphql/mutations/auth.graphql';
import { GET_EVENTS } from '../../graphql/queries/events.graphql';
import { GET_ME } from '../../graphql/queries/user.graphql';
import { useDispo } from '../../hooks';
import { BackgroundService, useQueryEvents } from '../../hooks/synchroniseCalendar';
import { EventEntity, InterestEntity, UploadFileEntity } from '../../models/gql/graphql';
import { AppReducerContext } from '../../store';
import { useAppStore } from '../../store/AppMemoProvider';
import { Enum_segment_home } from '../../store/AppReducerProvider/type';
import { getUserId, useUserData } from '../../utils/auth';
import { __ } from '../../utils/traduction';
import FilterModal, { IFilterData } from './FilterModal';
import SearchHistory from './SearchHistory/SearchHistory';
import SearchPostResult from './SearchPostResult';
import SearchResult from './SearchResult';
import { buildMainFilter } from './SearchResult/utils';
import SearchUserResult from './SearchUserResult';
import { SectionDispoAround, SectionDispoFriends } from './SectionDispo';
import SectionInterest from './SectionInterest';
interface IPagination {
  around: {
    page: number;
    nbPages: number;
  };
}

const Home: React.FC = () => {
  const history = useHistory();
  const {
    RootStore: { getAlgoliaClient, getMyPosition, weatherInfo, loadWeatherQuery, setShouldRefreshNow },
  } = useAppStore();

  const { userData } = useUserData();
  const [pagination, setPagination] = useState<IPagination>({
    around: {
      page: 0,
      nbPages: 0,
    },
  });

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isHome, setIsHome] = useState<boolean>(true);
  const searchClient = useMemo(() => getAlgoliaClient(), [getAlgoliaClient]);
  const [filterData, setFilterData] = useState<IFilterData>({});
  const [position, setPosition] = useState<ILocation | undefined>({});
  const [radius, setRadius] = useState<number>();
  const [hasFilter, setHasFilter] = useState<boolean>(false);
  const [segmentValue, setSegmentValue] = useState<Enum_segment_home>(Enum_segment_home.dispos);
  const [homeSegmentValue, setHomeSegmentValue] = useState<Enum_segment_home>(Enum_segment_home.dispos);
  const [isOpenModalFeed, setOpenModalFeed] = useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] = useState<IImage[]>([]);
  const [files, setFiles] = useState<UploadFileEntity[]>();
  const { redirectHome, reloadHome, setReloadHome, setRedirectHome } = useDispo();
  const { data: dataMe, loading: loadingInterest, refetch: refetchMe } = useQuery(GET_ME);
  const [updateMe, { data: dataUpdated, loading: loadingUpdated }] = useMutation(UPDATE_ME);
  const [aroundEvents, setAroundEvents] = useState<any[]>([]);
  const [latLng, setLatLng] = useState<any>();
  const [errorsMessage, setErrorsMessage] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [openModalInterest, setOpenModalInterest] = useState<boolean>(false);

  const interestsMe = useMemo<Array<InterestEntity | undefined | null> | undefined | null>(() => {
    return dataMe?.me?.interests;
  }, [dataMe, loadingInterest, loadingUpdated]);

  const myFollowers: string[] = useMemo(() => {
    return Array.from(
      new Set(
        dataMe?.me?.followers?.data
          ?.filter((i) => i.attributes?.status === 'accepted')
          .map((fol) => fol.attributes?.follower?.data?.id) as string[]
      )
    );
  }, [dataMe, loadingInterest, loadingUpdated]);

  const myFollowups: string[] = useMemo(() => {
    return Array.from(
      new Set(
        dataMe?.me?.followups?.data
          ?.filter((i) => i.attributes?.status === 'accepted')
          .map((fol) => fol.attributes?.user?.data?.id) as string[]
      )
    );
  }, [dataMe, loadingInterest, loadingUpdated]);

  const refContent = useRef<HTMLIonContentElement | null>(null);

  const {
    state: { searchQuery },
    dispatch,
  } = useContext(AppReducerContext);

  const setSearchQuery = (data: string) => {
    dispatch({ type: 'SET_SEARCH_QUERY', payload: data });
  };

  useIonViewWillLeave(() => {
    // reinitialize
    console.log('+a');
    /*setIsSearching(false);
    setIsHome(true);
    setSearchQuery('');
    setFilterData({});
    setPosition(undefined);*/
  });

  useIonViewDidEnter(() => {
    if (!searchQuery && !hasFilter) {
      setIsSearching(false);
      setIsHome(true);
      setSearchQuery('');
      setFilterData({});
      setPosition(undefined);
      if (redirectHome) {
        setSegmentValue(redirectHome);
        setHomeSegmentValue(redirectHome);
      }
      // reinitialize
      setTimeout(() => {
        console.log('reload');
        setShouldRefreshNow(true);
      }, 400);
    }
  }, [redirectHome, searchQuery, hasFilter]);

  const { data: dataEvent } = useQueryEvents();

  useEffect(() => {
    if (dataEvent && dataEvent.length) {
      BackgroundService.runSynchronisation(dataEvent);
    }
  }, [dataEvent]);

  useEffect(() => {
    if (dataMe?.me?.id) {
      loadPosition();
    }
  }, [dataMe?.me]);

  const updateInterest = async (dataInterest: any) => {
    const dataToPost = {
      interests: dataInterest,
    };
    try {
      if (dataInterest.length >= 3) {
        const { data } = await updateMe({ variables: { data: dataToPost } });
        setOpenModalInterest(false);
        refetchMe();
      } else {
        setIsOpen(true);
        setErrorsMessage('Vous devez au moins en selectionner trois !');
      }
    } catch (error: any) {
      setOpenModalInterest(false);
    }
    setTimeout(() => {
      setReloadHome(true);
    });
  };
  const {
    data: dataEventsFriend,
    loading: loadingEventsFriend,
    refetch: refetchEventsFriend,
  } = useQuery(GET_EVENTS, {
    variables: {
      filters: {
        deleted: { ne: true },
        expired: { ne: true },
        type: { eq: 'public' },
        creator: {
          id: {
            in: Array.from(new Set([...(myFollowers || []), ...(myFollowups || [])])).length
              ? Array.from(new Set([...(myFollowers || []), ...(myFollowups || [])]))
              : [null],
          },
        },
      },
      sort: ['eventDate:desc'],
      pagination: {
        page: 0,
        pageSize: 5,
      },
    },
  });
  useEffect(() => {
    if (dataEventsFriend && dataEventsFriend.events?.data?.length) {
      // weather
      loadWeatherQuery({
        variables: {
          filters: {
            dispo_id: { in: dataEventsFriend.events?.data.map((i: any) => parseInt(i.id, 10)) },
          },
        },
      });
    }
  }, [dataEventsFriend]);

  const loadPosition = async () => {
    try {
      const coords = await getMyPosition();
      if (coords && coords.lng) {
        setLatLng(`${coords.lat}, ${coords.lng}`);
        loadAround(`${coords.lat}, ${coords.lng}`);
      } else {
        loadAround();
      }
    } catch (error) {
      console.error('error on getting geolocation', error);
      loadAround();
    }
  };
  const loadAround = async (coords?: string, page = 0) => {
    const index = searchClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX);
    const prefix = process.env.REACT_APP_ALGOLIA_INDEX as string;
    const { hits, nbPages } = await index.search('', {
      aroundLatLng: coords,
      aroundRadius: 'all',
      getRankingInfo: true,
      analytics: true,
      aroundLatLngViaIP: true,
      hitsPerPage: 10,
      page,
      filters: buildMainFilter({
        interests: interestsMe?.map((interest) => interest?.id),
        creator: myFollowups.length ? myFollowups : null,
        deleted: false,
        expired: false,
        //type: 'public'
        custom: `(type:public OR  targets:${getUserId()})`,
      }),
    });
    if (hits && hits.length) {
      if (!aroundEvents.length || aroundEvents[aroundEvents.length - 1].id !== hits[hits.length - 1].id) {
        setAroundEvents((i) => {
          if (page === 0) {
            return hits;
          }
          return [...i, ...hits];
        });
        // weather
        loadWeatherQuery({
          variables: {
            filters: {
              dispo_id: { in: hits.map((i: any) => i.id) },
            },
          },
        });
        // pagination
        setPagination((i: IPagination) => ({ ...i, around: { ...i.around, nbPages } }));
      }
    }
  };

  const loadNextAround = useCallback(() => {
    if (pagination.around.page < pagination.around.nbPages - 1) {
      loadAround(latLng, pagination.around.page + 1);
      setPagination((i: IPagination) => ({ ...i, around: { ...i.around, page: i.around.page + 1 } }));
    }
  }, [pagination, latLng, aroundEvents, interestsMe]);

  const eventsFriend = useMemo(() => {
    return dataEventsFriend?.events?.data;
  }, [dataEventsFriend, loadingEventsFriend, loadingInterest]);

  const scrollToTopContent = (duration: number) => {
    refContent.current?.scrollToTop(duration);
  };
  useEffect(() => {
    console.log('hgh', reloadHome);
    if (reloadHome) {
      console.log('-*++++++++++++++++');
      setTimeout(() => {
        scrollToTopContent(1000);
      }, 100);
      refetchMe();
      refetchEventsFriend();
      loadPosition();
      setReloadHome(false);
    }
  }, [reloadHome]);

  useEffect(() => {
    setRedirectHome(homeSegmentValue);
  }, [homeSegmentValue]);

  return (
    <HomeWrapper>
      <Header
        background="white"
        noButtonBack={true}
        children={
          <>
            <IonToolbar mode="ios">
              <IonRow class="searchboxes ion-align-items-center ion-justify-content-center">
                <IonCol size="11">
                  <div className="d-flex-all-center">
                    {!isHome && (
                      <IonButton
                        color="dark"
                        className="ion-no-padding"
                        fill="clear"
                        onClick={async () => {
                          let tmphomeSegmentValue = homeSegmentValue;
                          await setSearchQuery('');
                          await setFilterData({});
                          await setPosition({});
                          await setRadius(undefined);
                          await setSegmentValue(Enum_segment_home.users);
                          setSegmentValue(tmphomeSegmentValue);
                          setIsHome(true);
                          setIsSearching(false);
                        }}
                      >
                        <IonIcon icon={arrowBackOutline} />
                      </IonButton>
                    )}
                    <InstantSearch
                      indexName={process.env.REACT_APP_ALGOLIA_INDEX as string}
                      searchClient={searchClient}
                    >
                      <CustomSearchBox
                        onDone={() => {
                          setIsSearching(false);
                        }}
                        onClear={(e: any) => {
                          setSegmentValue(homeSegmentValue);
                          setIsHome(true);
                          setIsSearching(false);
                        }}
                        onFocus={(e: any) => {
                          setIsSearching(true);
                          setIsHome(false);
                        }}
                      />
                    </InstantSearch>
                  </div>
                </IonCol>
                <IonCol size="1">
                  {!isHome ? (
                    <FilterModal
                      onRadiusChanged={(e) => {
                        setRadius(e);
                        setHasFilter(true);
                        setIsSearching(false);
                      }}
                      onFilterChanged={(e) => {
                        setFilterData(e);
                        setHasFilter(true);
                        setIsSearching(false);
                      }}
                      onPositionChanged={(e) => {
                        setPosition(e);
                        setHasFilter(true);
                        setIsSearching(false);
                      }}
                    />
                  ) : (
                    <AlgoliaUserAvatar
                      onClick={async () => {
                        await setRedirectHome(homeSegmentValue);
                        history.push(`/main/profil/${getUserId()}`);
                      }}
                      style={{ width: '30px', height: '30px' }}
                      imgUrl={
                        userData?.avatar
                          ? (userData?.avatar?.attributes?.image.data?.attributes?.url as string)
                          : userData?.profilePicture
                          ? (userData?.profilePicture?.attributes?.url as string)
                          : undefined
                      }
                    />
                  )}
                </IonCol>
              </IonRow>
            </IonToolbar>
            {!isHome ? (
              <IonSegment
                mode="md"
                value={segmentValue}
                onIonChange={(value) => setSegmentValue(value.detail.value as Enum_segment_home)}
              >
                {homeSegmentValue === Enum_segment_home.dispos && (
                  <IonSegmentButton value="dispos">
                    <IonLabel>Dispos</IonLabel>
                  </IonSegmentButton>
                )}
                {homeSegmentValue === Enum_segment_home.feed && (
                  <IonSegmentButton value="feed">
                    <IonLabel>Fil d'actu</IonLabel>
                  </IonSegmentButton>
                )}
                <IonSegmentButton value="users">
                  <IonLabel>Utilisateurs</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            ) : (
              <IonSegment
                mode="md"
                value={segmentValue}
                onIonChange={(value) => {
                  setSegmentValue(value.detail.value as Enum_segment_home);
                  setHomeSegmentValue(value.detail.value as Enum_segment_home);
                }}
              >
                <IonSegmentButton value="dispos">
                  <IonLabel>Dispos</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="feed">
                  <IonLabel>Fil d'actu</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            )}
          </>
        }
      ></Header>
      <IonContent
        ref={refContent}
        className={`SearchResult ${segmentValue !== Enum_segment_home.feed ? 'ion-padding' : ''}`}
        scrollY={segmentValue !== Enum_segment_home.feed}
      >
        {segmentValue === Enum_segment_home.dispos && !isSearching && (
          <InstantSearch indexName={process.env.REACT_APP_ALGOLIA_INDEX as string} searchClient={searchClient}>
            {isHome ? (
              <>
                <SectionInterest
                  loading={loadingInterest || loadingUpdated}
                  interestsMe={interestsMe}
                  onOpenModal={() => setOpenModalInterest(true)}
                  editBlack={editBlack}
                />
                <SectionDispoFriends
                  title="Dernières dispo de tes amis"
                  events={eventsFriend as EventEntity[]}
                  loading={loadingEventsFriend}
                  noWrap={true}
                  weatherData={weatherInfo}
                />
                <SectionDispoAround
                  loadNext={loadNextAround}
                  title="Autour de chez toi"
                  usePagination={false}
                  numberView={1.5}
                  events={aroundEvents}
                  loading={loadingEventsFriend}
                  noWrap={true}
                  weatherData={weatherInfo}
                />
                <div className="ion-margin-top">
                  <IonText color="dark" className="font-outfit">
                    <h4>Et encore plus !</h4>
                  </IonText>
                  {dataMe && dataMe.me && interestsMe && interestsMe?.length && (
                    <SearchResult
                      filterData={{
                        interests: interestsMe?.map((interest) => interest?.id),
                      }}
                      query={searchQuery}
                      position={position}
                      radius={radius}
                      followups={myFollowups}
                      weatherData={weatherInfo}
                    />
                  )}
                </div>
              </>
            ) : (
              <SearchResult filterData={filterData} query={searchQuery} position={position} radius={radius} />
            )}
            <ComponentModal isOpen={openModalInterest} onCancel={() => setOpenModalInterest(false)} height="550">
              <InterestEdition
                onChange={(data) => updateInterest(data)}
                onCloseModal={(data) => setOpenModalInterest(data)}
                filterInterestIds={interestsMe?.map((interest) => interest?.id)}
              ></InterestEdition>
            </ComponentModal>
            <IonToast
              isOpen={isOpen}
              message={__(errorsMessage)}
              duration={5000}
              onDidDismiss={() => setIsOpen(false)}
              color="danger"
            ></IonToast>
          </InstantSearch>
        )}
        {segmentValue === Enum_segment_home.feed && !isSearching && (
          <InstantSearch
            indexName={(process.env.REACT_APP_ALGOLIA_INDEX as string).replace('event', 'post')}
            searchClient={searchClient}
          >
            <SearchPostResult
              query={searchQuery}
              filterData={{
                followups: userData?.followups?.data
                  ?.filter((i) => i.attributes?.status === 'accepted')
                  .map((i) => i.attributes?.user?.data?.id),
              }}
            />
            <ComponentModal isOpen={isOpenModalFeed} onCancel={() => setOpenModalFeed(false)} height="100%">
              <PostForm
                selectedMedia={selectedMedia}
                setSelectedMedia={setSelectedMedia}
                closeModal={async (isSuccess) => {
                  setOpenModalFeed(false);
                }}
              />
            </ComponentModal>
          </InstantSearch>
        )}
        {isSearching && (
          <div>
            <SearchHistory
              onSelect={(e: string) => {
                setSearchQuery(e);
                setIsSearching(false);
                // setSegmentValue(Enum_segment_home.dispo_filtered);
              }}
            />
          </div>
        )}
        {segmentValue === Enum_segment_home.users && !isSearching && (
          <InstantSearch
            indexName={(process.env.REACT_APP_ALGOLIA_INDEX as string).replace('event', 'user')}
            searchClient={searchClient}
          >
            <SearchUserResult filterData={filterData} query={searchQuery} position={position} radius={radius} />
          </InstantSearch>
        )}
      </IonContent>
      {!isSearching && isHome && (
        <IonFooter className="ion-no-border ion-padding">
          {segmentValue === Enum_segment_home.dispos ? (
            <ButtonCreate action={() => history.push('/main/home/create-dispo/step-info')} text="Créer une dispo" />
          ) : (
            <ButtonCreate action={() => setOpenModalFeed(true)} text="Créer une publication" />
          )}
        </IonFooter>
      )}

      {!!(files && files.length) && <FullSlider files={files || []} setFiles={setFiles} />}
    </HomeWrapper>
  );
};

const HomeWrapper = styled(IonPage)`
  .searchboxes {
    ion-col {
      padding-left: 0;
      padding-right: 0;
      ion-icon {
        font-size: 22px;
      }
    }
  }
  ,
  ion-segment-button {
    --indicator-color: var(--ion-color-secondary);
  }
  ion-segment-button.md::part(native) {
    color: var(--ion-color-dark-tint);
  }
  .segment-button-checked.md::part(native) {
    color: var(--ion-color-secondary);
  }

  .other-segment {
    display: none;
  }
  .d-flex ion-icon {
    font-size: 20px;
  }
`;
export default Home;
