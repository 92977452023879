import { graphql } from '../../models/gql';

export const DOWNLOAD_TICKET = graphql(/* GraphQL */ `
  mutation DownloadTicket($id: ID!, $qrCodeImage: String, $timezone: String) {
    DownloadTicket(id: $id, qrCodeImage: $qrCodeImage, timezone: $timezone) {
      filePath
    }
  }
`);

export const CREATE_GOOGLE_PASS = graphql(/* GraphQL */ `
  mutation CreateGooglePass(
    $id: ID!
    $name: String!
    $description: String
    $date: String!
    $endDate: String
    $startHour: String!
    $endHour: String!
    $address: String!
    $lat: Float
    $lng: Float
    $zip_code: String
    $beginningDateTime: String
    $coverCustomImage: String
    $qrCode: String!
  ) {
    CreateGooglePass(
      id: $id
      name: $name
      description: $description
      date: $date
      endDate: $endDate
      startHour: $startHour
      endHour: $endHour
      address: $address
      lat: $lat
      lng: $lng
      zip_code: $zip_code
      beginningDateTime: $beginningDateTime
      coverCustomImage: $coverCustomImage
      qrCode: $qrCode
    ) {
      token
    }
  }
`);

export const GENERATE_PASS = graphql(/* GraphQL */ `
  mutation GeneratePass(
    $name: String
    $date: String
    $startHour: String
    $image: String
    $qrCode: String
    $userName: String
    $type: String
  ) {
    GeneratePass(
      name: $name
      date: $date
      startHour: $startHour
      image: $image
      qrCode: $qrCode
      userName: $userName
      type: $type
    ) {
      data
    }
  }
`);
