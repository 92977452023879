import { IonButton, IonCard, IonCardContent, IonCol, IonIcon, IonRow, IonText } from '@ionic/react';
import { ellipsisVerticalOutline } from 'ionicons/icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLikePost } from '../../hooks/usePosts';
import { PostEntity, PostLike, UploadFileEntity } from '../../models/gql/graphql';
import { AppReducerContext } from '../../store';
import { getNbComments, getUserId } from '../../utils';
import { StringToHtml } from '../Algolia/CardPost/StringToHtml';
import LikeComment from '../LikeComment';
import MenuPopover from '../MenuPopover';
import { ComponentModal } from '../Modals';
import PostComment from '../PostComment';
import UserDetail from '../UserDetail';
import VideoView from '../VideoView';
import { useHistory } from 'react-router';
import EditPost from '../../pages/Post/EditPost';

interface IFeedListItem {
  post: PostEntity;
  selectedPost: string;
  setSelectedPost: (value: string) => void;
  onDelete: () => void;
  setFiles?: (value: any) => void;
  refetchLike: () => void;
  myLikes: PostLike[];
  refreshPost?: () => void;
}

const FeedListItem: React.FC<IFeedListItem> = ({
  post,
  selectedPost,
  setSelectedPost,
  onDelete,
  setFiles,
  refetchLike,
  myLikes,
  refreshPost,
}) => {
  const { dispatch } = useContext(AppReducerContext);
  const userId = getUserId();
  const { like, messageError, loadingLike, loadingUnlike } = useLikePost(post.id || '', refetchLike);
  const [likes, setLikes] = useState(0);
  const [nbComments, setNbComments] = useState(0);
  const [actualPost, setActualPost] = useState<string | null>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const userLikePost: PostLike = (
    myLikes ? myLikes.find((like) => like.idPost === post.id?.toString()) : null
  ) as PostLike;
  const [showMore, setShowMore] = useState(false);
  const activeSlideIndexRef = useRef(0);
  const history = useHistory();
  const [isOpenModalFeed, setOpenModalFeed] = useState<boolean>(false);

  useEffect(() => {
    if (post?.attributes?.comments?.data) {
      setNbComments(getNbComments(post?.attributes?.comments?.data));
    }
    if (post?.attributes?.reactions?.data) {
      setLikes(post?.attributes?.reactions?.data.length);
    }
  }, [post]);

  const activeSlideIsImage =
    post.attributes?.medias?.data[activeSlideIndex]?.attributes?.mime?.startsWith('image') || false;

  return (
    <>
      <IonRow className="ion-margin-bottom" style={{ position: 'relative' }}>
        {post.attributes?.author?.data?.id === userId?.toString() && (
          <IonButton
            fill="clear"
            onClick={() => {
              setSelectedPost(post.id as string);
            }}
            id={`post-context-menu-${post.id}`}
            className="float-right text-white"
            style={{
              position: 'absolute',
              top: activeSlideIsImage ? '0' : '20px',
              right: '0',
              zIndex: 50,
            }}
          >
            <IonIcon icon={ellipsisVerticalOutline} className=""></IonIcon>
          </IonButton>
        )}
        <IonCard className="ion-no-margin full card-no-border border-radius-20" style={{ position: 'relative' }}>
          <MenuPopover
            showPopover={selectedPost !== ''}
            closePopover={() => setSelectedPost('')}
            trigger={`post-context-menu-${selectedPost}`}
            items={[
              {
                class: 'border-b-1 font-outfit',
                text: 'Supprimer',
                action: () => {
                  onDelete();
                },
              },
              {
                class: 'font-outfit',
                text: 'Modifier',
                action: async () => {
                  await setActualPost(selectedPost);
                  setOpenModalFeed(true);
                },
              },
            ]}
          />
          <IonCardContent className="ion-no-padding">
            <Swiper
              modules={[Pagination]}
              spaceBetween={20}
              centeredSlidesBounds={true}
              centeredSlides={true}
              setWrapperSize={true}
              pagination={{
                clickable: true,
                type: 'bullets',
                renderBullet(index, className) {
                  return `<span class="${className} ${
                    post?.attributes?.medias?.data && post.attributes?.medias?.data?.length <= 3
                      ? 'pagination-custom'
                      : ''
                  }"></span>`;
                },
              }}
              onClick={async (swiper, e: any) => {
                const isVideoControl = e.target.closest('.video-view') !== null;
                const isImage = e.target.closest('.post-container-image') !== null;

                if (!isVideoControl && isImage) {
                  dispatch({ type: 'SET_FULL_SLIDER', payload: true });
                  setFiles && setFiles(post.attributes?.medias?.data);
                }
              }}
              onSlideChange={(swiper) => {
                activeSlideIndexRef.current = swiper.activeIndex;
                console.log('swiper.activeIndex', swiper.activeIndex);
                setActiveSlideIndex(swiper.activeIndex);
              }}
            >
              {post.attributes?.medias?.data.length && (
                <>
                  {post.attributes.medias.data.map((file: UploadFileEntity, index: any) => (
                    <SwiperSlide key={index}>
                      {file.attributes?.url && (
                        <div className="post-container-image">
                          {file.attributes?.mime.substring(0, 5) === 'image' ? (
                            <img
                              alt="slide"
                              src={file.attributes?.url}
                              className="full-width h-300 image-card-custom"
                            />
                          ) : (
                            <>
                              {activeSlideIndex === index && (
                                <VideoView
                                  url={file.attributes?.url || ''}
                                  previewUrl={file.attributes?.previewUrl || ''}
                                  className="full h-300"
                                  videoClassName="full h-300 image-card-custom"
                                  autoPlay={false}
                                  index={post ? Number(post.id) : 0}
                                ></VideoView>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </SwiperSlide>
                  ))}
                </>
              )}
            </Swiper>
            <IonRow className="ion-align-items-center  margin-l-5">
              <IonCol size="8.5">
                <UserDetail
                  avatarStyle="width-30 height-30 ion-margin-end"
                  user={post.attributes?.author?.data?.attributes} /* avatarStyle="width-30 height-30" */
                />
              </IonCol>
              <IonCol size="3.5" className="ion-justify-content-end">
                <LikeComment
                  actionComment={() => {
                    if (post.id) setActualPost(post?.id);
                  }}
                  nbComment={nbComments || 0}
                  nbLike={likes || 0}
                  likeLoading={loadingLike || loadingUnlike}
                  userLikePost={userLikePost}
                  actionLike={async () => {
                    try {
                      await like(userLikePost);
                      if (userLikePost) {
                        if (likes) setLikes((n) => n - 1);
                      } else {
                        setLikes((n) => n + 1);
                      }
                      refreshPost && refreshPost();
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow
              className="text-gray-400 font-outfit full"
              onClick={() => {
                post.id && setActualPost(post.id);
              }}
            >
              <IonText className="ion-padding  text-capitalize">
                {StringToHtml(post.attributes?.description || '', showMore, setShowMore)}
              </IonText>
            </IonRow>
          </IonCardContent>
        </IonCard>
      </IonRow>
      <ComponentModal isOpen={!!actualPost} onCancel={() => setActualPost(null)} height="100%">
        {!!actualPost && (
          <PostComment
            id={actualPost}
            cancel={(additionalComments) => {
              if (refreshPost) refreshPost();
              setActualPost(null);
            }}
            refetchLike={refetchLike}
            userLikePost={userLikePost}
          />
        )}
      </ComponentModal>
      <ComponentModal isOpen={!!isOpenModalFeed && !!actualPost} onCancel={() => setOpenModalFeed(false)} height="100%">
        <EditPost
          id={actualPost || ''}
          close={() => {
            setActualPost('');
            setOpenModalFeed(false);
            setSelectedPost('');
          }}
        />
      </ComponentModal>
    </>
  );
};

export default FeedListItem;
